import React from 'react';

export interface SwitchProps {
  then?: any;
  otherwise?: any;
}

export interface BaseSwitchProps {
  matches?: boolean;
}

export const BaseSwitch = (props: SwitchProps & BaseSwitchProps) => {
  return <>{props.matches ? props.then : props.otherwise}</>;
};
