import {CognitoUserSession} from 'amazon-cognito-identity-js';
import Cookies from 'js-cookie';
import React, {useEffect, useState} from 'react';
import {useUserAtomState} from '../atoms';
import {CustomerTypes, Lobs} from '../commonTypes';
import {BaseSwitch} from '../components/common/BaseSwitch/BaseSwitch';
import {AuthInsured} from '../components/PolicySearch/AuthInsured';
import {authInsuredUserAuthorization, PATH} from '../services';
import {setSessionCookieData} from './utils';
import {RefreshToken, SAAuth} from './utils/auth';
import {LogRocket} from '../services/logRocket';
import {navigate} from '@reach/router';
import {AUTH_INSURED_ROUTE, ROOT_ROUTE} from '../routes/paths';
import {clearAuthenticatedSessionData} from '../utils/utils';

export const AuthInsuredPage = () => {
  const [userAtomState, setUserAtomState] = useUserAtomState();
  const [isAuthInsured, setIsAuthInsured] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  useEffect(() => {
    const uuid = Cookies.get('uuid') || '';

    if (uuid) {
      SAAuth.currentSession()
        .then((session: CognitoUserSession & RefreshToken) => {
          setSessionCookieData({
            path: PATH.AUTHINSURED,
            userToken: session?.idToken?.jwtToken,
            userSession: JSON.stringify(session),
            refreshToken: session?.refreshToken?.token,
          });
          LogRocket.track('EID Login successful for authInsured');

          authInsuredUserAuthorization({uuid})
            .then(({data}) => {
              if (!data.hasErrors && data.data.is_user_authorized) {
                const customerType = CustomerTypes.SaCustomer;
                const policyNumber = data.data.policy_number;
                const lob = data.data.product_code?.toLowerCase().includes('auto')
                  ? Lobs.Auto
                  : Lobs.Homeowners;
                // Commented the below temporarily until Customer360 fix
                // data.data.product_code === 'PERSONAL AUTO' ||
                // data.data.product_code === 'COMMERCIAL AUTO' ||

                setFirstName(data.data.first_name);
                setLastName(data.data.last_name);
                Cookies.set('policyNumber', policyNumber);
                LogRocket.track('authInsuredUserAuthorization call passed');
                setUserAtomState({
                  ...userAtomState,
                  gettingStarted: {
                    customerType,
                    lob,
                    isAuthInsured: true,
                  },
                  policyNumber,
                });
                setIsAuthInsured(true);
              } else {
                LogRocket.track('authInsuredUserAuthorization call had errors');
                LogRocket.log('authInsuredUserAuthorization call had errors');
                setIsAuthInsured(false);
                clearAuthenticatedSessionData();
                navigate(ROOT_ROUTE);
              }
            })
            .catch(err => {
              LogRocket.track('authInsuredUserAuthorization call failed');
              LogRocket.log('authInsuredUserAuthorization call failed: ', err);
              setIsAuthInsured(false);
              clearAuthenticatedSessionData();
              navigate(ROOT_ROUTE);
            });
        })
        .catch(err => {
          Cookies.set('authenticated-redirect', AUTH_INSURED_ROUTE);
          LogRocket.log('No current EmpowerID session for auth insured:', err);

          SAAuth.signIn().catch(err => {
            LogRocket.track('EID Login failed for authInsured');
            LogRocket.log('Sign in err for auth insured:', err);
            setIsAuthInsured(false);
            navigate(ROOT_ROUTE);
          });
        });
    } else {
      LogRocket.track('Empty uuid - AuthInsured');
      setIsAuthInsured(false);
      clearAuthenticatedSessionData();
      navigate(ROOT_ROUTE);
    }
  }, []);

  return (
    <BaseSwitch
      matches={isAuthInsured}
      then={<AuthInsured firstName={firstName} lastName={lastName} />}
      otherwise={<div />}
    />
  );
};
