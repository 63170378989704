import {CognitoUserSession} from 'amazon-cognito-identity-js';
import {Auth} from 'aws-amplify';

export interface ICredentials {
  accessKeyId: string;
  sessionToken: string;
  secretAccessKey: string;
  identityId: string;
  authenticated: boolean;
}

export interface RefreshToken {
  refreshToken: {
    token: string;
  };
  idToken: {
    jwtToken: string;
  };
}

export class SAAuth {
  static currentSession = (): Promise<CognitoUserSession & RefreshToken> => {
    return Auth.currentSession() as Promise<CognitoUserSession & RefreshToken>;
  };

  static signIn = (): Promise<ICredentials> => {
    return Auth.federatedSignIn({
      customProvider: `${process.env.REACT_APP_COGNITO_CUSTOM_PROVIDER}`,
    });
  };
}
