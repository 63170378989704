export const ROOT_ROUTE = '/';
export const FNOL_AUTO_ROUTE = '/fnol-auto';
export const SUMMARY_PAGE_ROUTE = '/success';
export const QUICK_ESTIMATE_ROUTE = '/inspection';
export const MAINTENANCE_ROUTE = '/maintenance';
export const POLICY_SEARCH_ROUTE = '/policy-search';
export const COMMERCIAL_AUTO_VEHICLE_SELECTION_ROUTE = '/commercialautovehicleselection';
export const AGENT_POLICY_SEARCH_ROUTE = '/agentpolicysearch';
export const HOMEOWNERS_ROUTE = '/homeowners';
export const ASSOCIATE_POLICY_SEARCH_ROUTE = '/associatepolicysearch';
export const COPART_ROUTE = '/possibletotalloss';
export const STAFF_APPRAISER_ROUTE = '/staffappraiser';
export const AUTH_INSURED_ROUTE = '/authinsured';
