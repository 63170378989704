import React from 'react';
import {ContactTypes} from '../../../commonTypes';
import {DynamicRadioList, RadioOption} from '../DynamicRadioList/DynamicRadioList';

export interface PersonCompanyRadiosProps {
  defaultValue?: ContactTypes;
  name: string;
  onChange?: any;
  disabled?: boolean;
  personTestId?: string;
  companyTestId?: string;
  hasErrors?: boolean;
  errorMessage?: string;
}

export const PersonCompanyRadios = (props: PersonCompanyRadiosProps) => {
  const OPTIONS: RadioOption[] = [
    {
      label: 'Person',
      value: 'person',
      testId: props.personTestId,
    },
    {
      label: 'Company',
      value: 'company',
      testId: props.companyTestId,
    },
  ];

  const onChangeHandler = (value: string) => {
    props.onChange && props.onChange(value);
  };

  return (
    <DynamicRadioList
      name={props.name}
      options={OPTIONS}
      defaultValue={props.defaultValue}
      onChange={onChangeHandler}
      row
      hasErrors={props.hasErrors}
      errorMessage={props.errorMessage}
      disabled={props.disabled}
    />
  );
};
