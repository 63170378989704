import {Address, VehicleIncident} from '../../Summary/types';

export enum VehiclePreferences {
  AcceptCopart = 'acceptCopart',
  DeclineCopartNotes = 'declineCopartNotes',
  PickUpTarget = 'pickUpTarget',
  PickUpDate = 'pickUpDate',
  ReasonForDate = 'reasonForDate',
  ReleaseNotes = 'releaseNotes',
  CallSuccess = 'callSuccess',
  KeysWithVehicle = 'keysWithVehicle',
  KeyContactName = 'keyContactName',
  KeyContactPhone = 'keyContactPhoneNumber',
  KeyPickUpDate = 'keyPickUpDate',
  QERequested = 'quickEstimateRequested',
  ContactMethod = 'contactMethod',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  NormalLocation = 'normalLocation',
  InspectionContactName = 'inspectionContactName',
  InspectionContactPhone = 'inspectionContactPhoneNumber',
  DeclineInspection = 'declineInspection',
  ManualAddress = 'manualAddressEntry',
  VirtualAppraisalNotes = 'virtualAppraisalNotes',
  AcceptVirtualAppraisal = 'acceptVirtualAppraisal',
  IsLoanLease = 'isLoanLease',
  LoanOrLease = 'loanOrLease',
}

export interface CopartPreferences {
  acceptCopart?: string;
  declineCopartNotes?: string;
  pickUpTarget?: string;
  pickUpDate?: Date | null;
  reasonForDate?: string;
  releaseNotes?: string;
  callSuccess?: string;
  keysWithVehicle?: string;
  keyContactName?: string;
  keyContactPhoneNumber?: string;
  keyPickUpDate?: Date | null;
  isLoanLease?: string;
  loanOrLease?: string;
}

export interface VehicleCopartProps {
  copartPreferences: CopartPreferences;
  quickEstimateRequested?: string;
  contactMethod?: string;
  phoneNumber?: string;
  email?: string;
  normalLocation?: Address;
  inspectionContactName?: string;
  inspectionContactPhoneNumber?: string;
  declineInspection?: boolean;
  manualAddressEntry?: boolean;
  acceptVirtualAppraisal?: string;
  virtualAppraisalNotes?: string;
}

export interface CopartComponentProps {
  dispatch?: any;
  incident?: VehicleCopartProps & VehicleIncident;
}

export enum VehicleLocationTypes {
  AutoRepairShop = 'AutoRepairShop',
  Company = 'Company',
  Person = 'Person',
}

interface VehicleLocationMap {
  [key: string]: string;
}

export const VEHICLE_LOCATION_TYPES: VehicleLocationMap = {
  [VehicleLocationTypes.AutoRepairShop]: 'repair shop/tow yard',
  [VehicleLocationTypes.Company]: 'business',
  [VehicleLocationTypes.Person]: 'personal address',
};
