import React, {useContext, useEffect, useRef, useState} from 'react';
import {Box, Grid, MenuItem} from '@material-ui/core';
import {useFormContext, useWatch} from 'react-hook-form';
import {VehicleDetailsContext, VehicleTypes} from '../YourVehicleDetails/YourVehicleDetails';
import {SATextField} from '../../common/TextField/TextField';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {DraftClaimContext} from '../../common/Providers/Providers';
import {Vehicle} from '../../common/Summary/types';
import {VehicleTypeSwitch} from '../VehicleTypeSwitch/VehicleTypeSwitch';
import {SAController} from '../../common/Controller/Controller';
import {makePersonaCheck, scrollToRef} from '../../../utils/utils';
import {
  AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
  AGENT_AND_ASSOCIATE_PERSONAS,
  CustomerTypes,
} from '../../../commonTypes';
import {useUserAtomState, useVehicleOptionsAtomState} from '../../../atoms';

export interface VehicleDetailsFieldsProps {
  shouldFocusOnFirstField?: boolean;
  setYourVehicleCoverages?: React.Dispatch<React.SetStateAction<boolean[]>>;
  field?: any;
}

export const VehicleDetailsFields = (props: VehicleDetailsFieldsProps) => {
  const {register, errors, control} = useFormContext();
  const {fieldIndex, parentFieldName, setPolicyVehicleCoverage, prefillVehicle} =
    useContext(VehicleDetailsContext);

  const {draftClaimResponse} = useContext(DraftClaimContext);
  const [policyVehicle, setPolicyVehicle] = useState<Vehicle | undefined>();
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType as CustomerTypes;
  const isAuthInsuredFlag = userAtomState?.gettingStarted?.isAuthInsured;
  const [vehicleOptions, setVehicleOptions] = useVehicleOptionsAtomState();

  const vehicleSelectRef = useRef(null);

  const vehicleTypes: {[key: string]: string} = {
    passengercar: 'Car / SUV / Minivan',
    tractor_trailer: 'Heavy Equipment / Specialty',
    other: 'Other',
    pickup: 'Pickup Truck',
    trailer: 'Trailer',
    motorcycle: 'Motorcycle',
  };

  const isInsuredVehicle = parentFieldName === 'yourVehicles';

  const lob = draftClaimResponse?.lobs?.personalAuto || draftClaimResponse?.lobs?.commercialAuto;
  const policyLob =
    draftClaimResponse?.policy?.lobs?.personalAuto ||
    draftClaimResponse?.policy?.lobs?.commercialAuto;

  const policyVehicles =
    lob?.vehicles?.filter((x: Vehicle) => x.policyVehicle && x.year && x.make && x.model) || [];

  const hasOtherSelected =
    useWatch({control, name: `${parentFieldName}[${fieldIndex}].vehicleId`}) === 'Other';

  const determineShowingEpay = (vehicle: Vehicle | undefined) => {
    if (
      isInsuredVehicle &&
      makePersonaCheck(userPersona as CustomerTypes, [
        CustomerTypes.Associate,
        CustomerTypes.SaCustomer,
      ])
    ) {
      const hasComprehensiveOrCollisionCoverages = !!(
        vehicle?.simplifiedCoverages?.collision?.length ||
        vehicle?.simplifiedCoverages?.comprehensive?.length
      );

      props?.setYourVehicleCoverages &&
        props.setYourVehicleCoverages((prevState: boolean[]) => {
          let newState: boolean[] = [...prevState];
          newState[fieldIndex || 0] = hasComprehensiveOrCollisionCoverages;
          return newState;
        });
    }
  };

  useEffect(() => {
    if (prefillVehicle) {
      const event = {
        target: {
          value: policyVehicles[fieldIndex || 0]?.publicID,
        },
      };
      onSelectedVehicleChange(event);
    }
  }, []);

  const onSelectedVehicleChange = (e: any) => {
    const vehicleId = e.target.value;
    if (vehicleId && vehicleId !== 'Other') {
      const selectedPolicyVehicle = policyVehicles?.find((x: Vehicle) => x.publicID === vehicleId);
      const selectedPolicyVehicleWithCoverages = policyLob?.vehicles?.find(
        (x: Vehicle) => x.publicID === vehicleId
      );

      determineShowingEpay(selectedPolicyVehicleWithCoverages);
      setPolicyVehicle(selectedPolicyVehicle);
      setPolicyVehicleCoverage &&
        setPolicyVehicleCoverage(selectedPolicyVehicleWithCoverages?.coverages || []);
      selectedPolicyVehicle &&
        setVehicleOptions(vehicleOptions => {
          if (policyVehicle) {
            return [
              ...vehicleOptions.filter(vehicle => vehicleId !== vehicle.publicID),
              policyVehicle,
            ];
          } else {
            return [...vehicleOptions.filter(vehicle => vehicleId !== vehicle.publicID)];
          }
        });
    } else {
      determineShowingEpay(undefined);
      setPolicyVehicleCoverage && setPolicyVehicleCoverage(null);
      setVehicleOptions(vehicleOptions => {
        if (policyVehicle) {
          return [...vehicleOptions, policyVehicle];
        } else {
          return [...vehicleOptions];
        }
      });
      setPolicyVehicle(undefined);
    }
  };

  const onError = () => {
    scrollToRef(vehicleSelectRef);
  };

  const onVinBlur = (e: any) => {
    const vin = e?.target?.value;
    const matchingPolicyVehicle = policyLob?.vehicles?.find((x: Vehicle) => x.vIN === vin);

    determineShowingEpay(matchingPolicyVehicle);
  };

  return (
    <Grid container spacing={3}>
      <VehicleTypeSwitch
        ifVehicleTypes={[VehicleTypes.OWNER]}
        then={
          <UserPersonaSwitch
            ifPersonas={AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS}
            authFlagCheck={true}
            then={
              <>
                <Grid item xs={12} md={4}>
                  <Box>
                    <div ref={vehicleSelectRef} />
                    <SAController
                      name={`${parentFieldName}[${fieldIndex}].vehicleId`}
                      onChange={onSelectedVehicleChange}
                      defaultValue={props.field?.vehicleId || ''}
                      onFocus={onError}
                    >
                      <SATextField
                        label="Vehicle"
                        id={`vehicleId-${fieldIndex}`}
                        select
                        InputLabelProps={{
                          'aria-labelledby': `vehicleId-${fieldIndex}`,
                        }}
                        InputProps={
                          {'data-testid': `${parentFieldName}[${fieldIndex}].vehicleId`} as any
                        }
                        error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                          'vehicleId'
                        )}
                        helperText={
                          errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleId?.message
                        }
                      >
                        {(policyVehicle ? [policyVehicle, ...vehicleOptions] : vehicleOptions).map(
                          (vehicle: Vehicle) => {
                            return (
                              <MenuItem value={vehicle?.publicID}>
                                <Box display="flex" flexDirection="column">
                                  <Box
                                    pb={1}
                                  >{`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}</Box>
                                  <Box
                                    color="#666666"
                                    fontSize="12px"
                                  >{`VIN: ${vehicle?.vIN}`}</Box>
                                </Box>
                              </MenuItem>
                            );
                          }
                        )}
                        <MenuItem value="Other">Other</MenuItem>
                      </SATextField>
                    </SAController>
                  </Box>
                </Grid>
                {hasOtherSelected ? (
                  <>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <SATextField
                          autoFocus={props.shouldFocusOnFirstField}
                          label="Vehicle Year"
                          id={`vehicleYear-${parentFieldName}-${fieldIndex}`}
                          name={`${parentFieldName}[${fieldIndex}].vehicleYear`}
                          InputLabelProps={{
                            'aria-labelledby': `vehicleYear-${parentFieldName}-${fieldIndex}`,
                          }}
                          inputRef={register()}
                          error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                            'vehicleYear'
                          )}
                          helperText={
                            errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleYear?.message
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <SATextField
                          label="Vehicle Make"
                          id={`vehicleMake-${parentFieldName}-${fieldIndex}`}
                          name={`${parentFieldName}[${fieldIndex}].vehicleMake`}
                          inputRef={register()}
                          InputLabelProps={{
                            'aria-labelledby': `vehicleMake-${parentFieldName}-${fieldIndex}`,
                          }}
                          error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                            'vehicleMake'
                          )}
                          helperText={
                            errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleMake?.message
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <SATextField
                          label="Vehicle Model"
                          id={`vehicleModel-${parentFieldName}-${fieldIndex}`}
                          name={`${parentFieldName}[${fieldIndex}].vehicleModel`}
                          inputRef={register()}
                          InputLabelProps={{
                            'aria-labelledby': `vehicleModel-${parentFieldName}-${fieldIndex}`,
                          }}
                          error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                            'vehicleModel'
                          )}
                          helperText={
                            errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleModel
                              ?.message
                          }
                          characterLimit={40}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <SATextField
                          label="Vehicle Type"
                          id={`vehicleType-${parentFieldName}-${fieldIndex}`}
                          name={`${parentFieldName}[${fieldIndex}].vehicleType`}
                          inputRef={register()}
                          select
                          SelectProps={{native: true}}
                          InputLabelProps={{
                            'aria-labelledby': `vehicleType-${parentFieldName}-${fieldIndex}`,
                          }}
                          error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                            'vehicleType'
                          )}
                          helperText={
                            isInsuredVehicle
                              ? errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleType
                                  ?.message
                              : 'optional'
                          }
                        >
                          <option aria-label="None" value="" />
                          {Object.keys(vehicleTypes).map((key: string) => (
                            <option value={key}>{vehicleTypes[key]}</option>
                          ))}
                        </SATextField>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <SATextField
                          label="VIN"
                          id={`vehicleVIN-${parentFieldName}-${fieldIndex}`}
                          name={`${parentFieldName}[${fieldIndex}].vehicleVIN`}
                          inputRef={register()}
                          InputLabelProps={{
                            'aria-labelledby': `vehicleVIN-${parentFieldName}-${fieldIndex}`,
                          }}
                          onBlur={(e: any) => onVinBlur(e)}
                          error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                            'vehicleVIN'
                          )}
                          helperText={
                            errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleVIN
                              ?.message || 'optional'
                          }
                        />
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <>
                    {(policyVehicle && !policyVehicle?.style) ||
                    policyVehicle?.style === 'other' ||
                    policyVehicle?.style === '' ? (
                      <Grid item xs={12} md={4}>
                        <Box>
                          <SATextField
                            label="Vehicle Type"
                            id={`vehicleType-${parentFieldName}-${fieldIndex}`}
                            name={`${parentFieldName}[${fieldIndex}].vehicleType`}
                            inputRef={register()}
                            select
                            SelectProps={{native: true}}
                            InputLabelProps={{
                              'aria-labelledby': `vehicleType-${parentFieldName}-${fieldIndex}`,
                            }}
                            error={errors?.[parentFieldName || '']?.[
                              fieldIndex || 0
                            ]?.hasOwnProperty('vehicleType')}
                            helperText={
                              isInsuredVehicle
                                ? errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleType
                                    ?.message
                                : 'optional'
                            }
                          >
                            <option aria-label="None" value="" />
                            {Object.keys(vehicleTypes).map((key: string) => (
                              <option value={key}>{vehicleTypes[key]}</option>
                            ))}
                          </SATextField>
                        </Box>
                      </Grid>
                    ) : (
                      <input
                        type="hidden"
                        ref={register()}
                        name={`${parentFieldName}[${fieldIndex}].vehicleType`}
                        defaultValue={policyVehicle?.style || ''}
                        data-testid={`${parentFieldName}[${fieldIndex}].vehicleType`}
                      />
                    )}
                    <input
                      type="hidden"
                      ref={register()}
                      name={`${parentFieldName}[${fieldIndex}].vehicleYear`}
                      defaultValue={policyVehicle?.year || ''}
                    />
                    <input
                      type="hidden"
                      ref={register()}
                      name={`${parentFieldName}[${fieldIndex}].vehicleMake`}
                      defaultValue={policyVehicle?.make || ''}
                    />
                    <input
                      type="hidden"
                      ref={register()}
                      name={`${parentFieldName}[${fieldIndex}].vehicleModel`}
                      defaultValue={policyVehicle?.model || ''}
                    />
                    <input
                      type="hidden"
                      ref={register()}
                      name={`${parentFieldName}[${fieldIndex}].vehicleVIN`}
                      defaultValue={policyVehicle?.vIN || ''}
                    />
                  </>
                )}
                <Grid item xs={12} md={4}>
                  <Box>
                    <SATextField
                      label="Vehicle Color"
                      id={`vehicleColor-${parentFieldName}-${fieldIndex}`}
                      name={`${parentFieldName}[${fieldIndex}].vehicleColor`}
                      inputRef={register()}
                      InputLabelProps={{
                        'aria-labelledby': `vehicleColor-${parentFieldName}-${fieldIndex}`,
                      }}
                      error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                        'vehicleColor'
                      )}
                      helperText={
                        errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleColor?.message ||
                        (isAuthInsuredFlag ? '' : 'optional')
                      }
                      characterLimit={20}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <SATextField
                      label="Vehicle Mileage"
                      id={`vehicleMileage-${parentFieldName}-${(fieldIndex || 0) + 1}`}
                      name={`${parentFieldName}[${fieldIndex}].vehicleMileage`}
                      inputRef={register()}
                      InputLabelProps={{
                        'aria-labelledby': `vehicleMileage-${parentFieldName}-${
                          (fieldIndex || 0) + 1
                        }`,
                      }}
                      error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                        'vehicleMileage'
                      )}
                      helperText={
                        errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleMileage
                          ?.message || (isAuthInsuredFlag ? '' : 'optional')
                      }
                    />
                  </Box>
                </Grid>
              </>
            }
            otherwise={
              <>
                <Grid item xs={12} md={4}>
                  <Box>
                    <SATextField
                      autoFocus={props.shouldFocusOnFirstField}
                      label="Vehicle Year"
                      id={`vehicleYear-${parentFieldName}-${fieldIndex}`}
                      name={`${parentFieldName}[${fieldIndex}].vehicleYear`}
                      InputLabelProps={{
                        'aria-labelledby': `vehicleYear-${parentFieldName}-${fieldIndex}`,
                      }}
                      inputRef={register()}
                      error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                        'vehicleYear'
                      )}
                      helperText={
                        errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleYear?.message
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <SATextField
                      label="Vehicle Make"
                      id={`vehicleMake-${parentFieldName}-${fieldIndex}`}
                      name={`${parentFieldName}[${fieldIndex}].vehicleMake`}
                      inputRef={register()}
                      InputLabelProps={{
                        'aria-labelledby': `vehicleMake-${parentFieldName}-${fieldIndex}`,
                      }}
                      error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                        'vehicleMake'
                      )}
                      helperText={
                        errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleMake?.message
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <SATextField
                      label="Vehicle Model"
                      id={`vehicleModel-${parentFieldName}-${fieldIndex}`}
                      name={`${parentFieldName}[${fieldIndex}].vehicleModel`}
                      inputRef={register()}
                      InputLabelProps={{
                        'aria-labelledby': `vehicleModel-${parentFieldName}-${fieldIndex}`,
                      }}
                      error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                        'vehicleModel'
                      )}
                      helperText={
                        errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleModel?.message
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <SATextField
                      label="Vehicle Type"
                      id={`vehicleType-${parentFieldName}-${fieldIndex}`}
                      name={`${parentFieldName}[${fieldIndex}].vehicleType`}
                      inputRef={register()}
                      select
                      SelectProps={{native: true}}
                      InputLabelProps={{
                        'aria-labelledby': `vehicleType-${parentFieldName}-${fieldIndex}`,
                      }}
                      error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                        'vehicleType'
                      )}
                      helperText={
                        isInsuredVehicle
                          ? errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleType?.message
                          : 'optional'
                      }
                    >
                      <option aria-label="None" value="" />
                      {Object.keys(vehicleTypes).map((key: string) => (
                        <option value={key}>{vehicleTypes[key]}</option>
                      ))}
                    </SATextField>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <SATextField
                      label="VIN"
                      id={`vehicleVIN-${parentFieldName}-${fieldIndex}`}
                      name={`${parentFieldName}[${fieldIndex}].vehicleVIN`}
                      inputRef={register()}
                      InputLabelProps={{
                        'aria-labelledby': `vehicleVIN-${parentFieldName}-${fieldIndex}`,
                      }}
                      onBlur={(e: any) => onVinBlur(e)}
                      error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                        'vehicleVIN'
                      )}
                      helperText={
                        errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleVIN?.message ||
                        'optional'
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <SATextField
                      label="Vehicle Color"
                      id={`vehicleColor-${parentFieldName}-${fieldIndex}`}
                      name={`${parentFieldName}[${fieldIndex}].vehicleColor`}
                      inputRef={register()}
                      InputLabelProps={{
                        'aria-labelledby': `vehicleColor-${parentFieldName}-${fieldIndex}`,
                      }}
                      error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                        'vehicleColor'
                      )}
                      helperText={
                        isInsuredVehicle
                          ? errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleColor
                              ?.message || (
                              <UserPersonaSwitch
                                ifPersonas={[
                                  ...AGENT_AND_ASSOCIATE_PERSONAS,
                                  CustomerTypes.Guardian,
                                ]}
                                then={'optional'}
                              />
                            )
                          : 'optional'
                      }
                      characterLimit={20}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <SATextField
                      label="Vehicle Mileage"
                      id={`vehicleMileage-${parentFieldName}-${(fieldIndex || 0) + 1}`}
                      name={`${parentFieldName}[${fieldIndex}].vehicleMileage`}
                      inputRef={register()}
                      InputLabelProps={{
                        'aria-labelledby': `vehicleMileage-${parentFieldName}-${
                          (fieldIndex || 0) + 1
                        }`,
                      }}
                      error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                        'vehicleMileage'
                      )}
                      helperText={
                        isInsuredVehicle
                          ? errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleMileage
                              ?.message || (
                              <UserPersonaSwitch
                                ifPersonas={[
                                  ...AGENT_AND_ASSOCIATE_PERSONAS,
                                  CustomerTypes.Guardian,
                                ]}
                                then={'optional'}
                              />
                            )
                          : 'optional'
                      }
                    />
                  </Box>
                </Grid>
              </>
            }
          />
        }
        otherwise={
          <>
            <Grid item xs={12} md={4}>
              <Box>
                <SATextField
                  autoFocus={props.shouldFocusOnFirstField}
                  label="Vehicle Year"
                  id={`vehicleYear-${parentFieldName}-${fieldIndex}`}
                  name={`${parentFieldName}[${fieldIndex}].vehicleYear`}
                  InputLabelProps={{
                    'aria-labelledby': `vehicleYear-${parentFieldName}-${fieldIndex}`,
                  }}
                  inputRef={register()}
                  error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                    'vehicleYear'
                  )}
                  helperText={
                    errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleYear?.message ||
                    'optional'
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <SATextField
                  label="Vehicle Make"
                  id={`vehicleMake-${parentFieldName}-${fieldIndex}`}
                  name={`${parentFieldName}[${fieldIndex}].vehicleMake`}
                  inputRef={register()}
                  InputLabelProps={{
                    'aria-labelledby': `vehicleMake-${parentFieldName}-${fieldIndex}`,
                  }}
                  error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                    'vehicleMake'
                  )}
                  helperText={
                    errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleMake?.message
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <SATextField
                  label="Vehicle Model"
                  id={`vehicleModel-${parentFieldName}-${fieldIndex}`}
                  name={`${parentFieldName}[${fieldIndex}].vehicleModel`}
                  inputRef={register()}
                  InputLabelProps={{
                    'aria-labelledby': `vehicleModel-${parentFieldName}-${fieldIndex}`,
                  }}
                  error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                    'vehicleModel'
                  )}
                  helperText={
                    errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleModel?.message
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <SATextField
                  label="Vehicle Type"
                  id={`vehicleType-${parentFieldName}-${fieldIndex}`}
                  name={`${parentFieldName}[${fieldIndex}].vehicleType`}
                  inputRef={register()}
                  select
                  SelectProps={{native: true}}
                  InputLabelProps={{
                    'aria-labelledby': `vehicleType-${parentFieldName}-${fieldIndex}`,
                  }}
                  error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                    'vehicleType'
                  )}
                  helperText={
                    isInsuredVehicle
                      ? errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleType?.message
                      : 'optional'
                  }
                >
                  <option aria-label="None" value="" />
                  {Object.keys(vehicleTypes).map((key: string) => (
                    <option value={key}>{vehicleTypes[key]}</option>
                  ))}
                </SATextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <SATextField
                  label="VIN"
                  id={`vehicleVIN-${parentFieldName}-${fieldIndex}`}
                  name={`${parentFieldName}[${fieldIndex}].vehicleVIN`}
                  inputRef={register()}
                  InputLabelProps={{
                    'aria-labelledby': `vehicleVIN-${parentFieldName}-${fieldIndex}`,
                  }}
                  error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                    'vehicleVIN'
                  )}
                  helperText={
                    errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleVIN?.message ||
                    'optional'
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <SATextField
                  label="Vehicle Color"
                  id={`vehicleColor-${parentFieldName}-${fieldIndex}`}
                  name={`${parentFieldName}[${fieldIndex}].vehicleColor`}
                  inputRef={register()}
                  InputLabelProps={{
                    'aria-labelledby': `vehicleColor-${parentFieldName}-${fieldIndex}`,
                  }}
                  error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                    'vehicleColor'
                  )}
                  helperText={
                    isInsuredVehicle
                      ? errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleColor
                          ?.message || (
                          <UserPersonaSwitch
                            ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                            then={'optional'}
                          />
                        )
                      : 'optional'
                  }
                  characterLimit={20}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <SATextField
                  label="Vehicle Mileage"
                  id={`vehicleMileage-${parentFieldName}-${(fieldIndex || 0) + 1}`}
                  name={`${parentFieldName}[${fieldIndex}].vehicleMileage`}
                  inputRef={register()}
                  InputLabelProps={{
                    'aria-labelledby': `vehicleMileage-${parentFieldName}-${(fieldIndex || 0) + 1}`,
                  }}
                  error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                    'vehicleMileage'
                  )}
                  helperText={
                    isInsuredVehicle
                      ? errors?.[parentFieldName || '']?.[fieldIndex || 0]?.vehicleMileage
                          ?.message || (
                          <UserPersonaSwitch
                            ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                            then={'optional'}
                          />
                        )
                      : 'optional'
                  }
                />
              </Box>
            </Grid>
          </>
        }
      />
    </Grid>
  );
};
