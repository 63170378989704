import React, {ReactElement} from 'react';
import {Dialog} from '../Dialog/Dialog';
import {
  DialogProps,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Box,
  makeStyles,
  withStyles,
} from '@material-ui/core';

export interface SADialogProps {
  header: string | ReactElement;
  cta?: string | undefined;
  ctaOnClick?: () => void | undefined;
}

const BaseDialog = withStyles(theme => ({
  root: {
    margin: '0px',
    overflow: 'visible',
  },
  paper: {
    margin: '16px',
  },
}))(Dialog);

const useStyles = makeStyles(theme => ({
  dialogContainerClass: {
    maxWidth: '584px',
    padding: '10px 0',
    [theme.breakpoints.up('sm')]: {
      padding: '10px',
    },
  },
  paddingClass: {
    padding: '16px 24px',
    [theme.breakpoints.up('sm')]: {
      padding: '16px 24px',
    },
  },
  buttonClass: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
}));

export const SADialog = (props: DialogProps & SADialogProps) => {
  const {paddingClass, dialogContainerClass, buttonClass} = useStyles();
  return (
    <BaseDialog {...props}>
      <Box className={dialogContainerClass}>
        <DialogTitle className={paddingClass} id={props['aria-labelledby']}>
          {props.header}
        </DialogTitle>
        <DialogContent className={paddingClass} style={{paddingTop: 0}}>
          <Grid container>
            <Grid item xs={12}>
              {props.children}
            </Grid>
            {props.cta && (
              <Grid item xs={12}>
                <Box textAlign="right" marginTop="50px" marginBottom="5px">
                  <Button
                    className={buttonClass}
                    onClick={props.ctaOnClick}
                    data-testid="ErrorDialog"
                    variant="contained"
                    color="secondary"
                  >
                    {props.cta}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Box>
    </BaseDialog>
  );
};
