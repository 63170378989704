import {Box} from '@material-ui/core';
import React, {useState} from 'react';
import {DamageDetails, DamageDetailsSchema} from '../DamageDetails/DamageDetails';
import {FireDamageDetails, FireDamageDetailsSchema} from '../FireDamageDetails/FireDamageDetails';
import {WaterDamageDetails} from '../WatreDamageDetails/WaterDamageDetails';
import {
  PropertyIncidentDetails,
  PropertyIncidentDetailsSchema,
} from '../PropertyIncidentDetails/PropertyIncidentDetails';
import * as yup from 'yup';
import {OtherPropertyDetails} from '../OtherPropertyDetails/OtherPropertyDetails';
import {InjuredPersonDetails} from '../InjuredPersonDetails/InjuredPersonDetails';

const schema = yup.object().shape({
  ...PropertyIncidentDetailsSchema,
  ...DamageDetailsSchema,
  ...FireDamageDetailsSchema,
});

export const IncidentDetailsContainerSchema = {
  incidentDetails: yup.object().when('$acknowledgement', {
    is: (value: boolean | undefined) => value === true,
    then: schema,
  }),
};

export const IncidentDetailsContainerTestSchema = yup.object().shape({
  incidentDetails: schema,
});

export const IncidentDetailsContainer = () => {
  const [showDamageDetails, setShowDamageDetails] = useState(false);
  const [showFireDamageDetails, setShowFireDamageDetails] = useState(false);
  const [showWaterDamageDetails, setShowWaterDamageDetails] = useState(false);
  const [showOtherPropertyDetails, setShowOtherPropertyDetails] = useState(false);
  const [isWeatherDamage, setIsWeatherDamage] = useState(false);
  const [isYourHomeAndStructure, setIsYourHomeAndStructure] = useState(false);
  const [showInjuredDetails, setShowInjuredDetails] = useState(false);
  const [isTheft, setIsTheft] = useState(false);

  return (
    <>
      <Box pb={3}>
        <PropertyIncidentDetails
          setShowDamageDetails={setShowDamageDetails}
          setShowFireDamageDetails={setShowFireDamageDetails}
          setShowWaterDamageDetails={setShowWaterDamageDetails}
          setIsWeatherDamage={setIsWeatherDamage}
          setIsYourHomeAndStructure={setIsYourHomeAndStructure}
          setShowOtherPropertyDetails={setShowOtherPropertyDetails}
          setShowInjuredDetails={setShowInjuredDetails}
          setIsTheft={setIsTheft}
        />
      </Box>
      {showDamageDetails && (
        <Box pb={3}>
          <DamageDetails
            isYourHomeAndStructure={isYourHomeAndStructure}
            isWeatherDamage={isWeatherDamage}
            isTheft={isTheft}
          />
        </Box>
      )}
      {showFireDamageDetails && (
        <Box pb={3}>
          <FireDamageDetails />
        </Box>
      )}
      {showWaterDamageDetails && (
        <Box pb={3}>
          <WaterDamageDetails />
        </Box>
      )}
      {showOtherPropertyDetails && (
        <Box pb={3}>
          <OtherPropertyDetails />
        </Box>
      )}
      {showInjuredDetails && (
        <Box pb={3}>
          <InjuredPersonDetails />
        </Box>
      )}
    </>
  );
};
