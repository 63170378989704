import {useLocation} from '@reach/router';
import React from 'react';
import {
  CommercialAutoNavState,
  CommercialAutoSelection,
} from '../../components/common/CommercialAutoSelection/CommercialAutoSelection';

export const CommercialAutoSelectionPage = () => {
  const location = useLocation();
  const successState = location.state as CommercialAutoNavState;
  return <CommercialAutoSelection userData={successState} />;
};
