import React, {useEffect, useState} from 'react';
import {
  Dialog,
  Box,
  withStyles,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Slide,
  makeStyles,
  TextField,
} from '@material-ui/core';
import {Rating} from '@material-ui/lab';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {Star} from '@material-ui/icons';
import {TransitionProps} from '@material-ui/core/transitions';
import SmileyPng from '../../../../../assets/images/smiley.png';
import {Controller, useForm, useWatch} from 'react-hook-form';
import {userFeedback} from '../../../../../services';
import CancelIcon from '@material-ui/icons/Cancel';
import {trackEvent} from '../../../../../services/analytics';
import {Snowplow} from '../../../../../pages/utils/snowplow';
import {useUserAtomState} from '../../../../../atoms';
import {CustomerTypes} from '../../../../../commonTypes';

const FeedbackButton = withStyles(theme => ({
  root: {
    textTransform: 'none',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#189ECC',
    },
  },
  label: {
    [theme.breakpoints.down(280)]: {
      fontSize: '12px',
    },
  },
}))(Button);

const FeedbackDialog = withStyles(theme => ({
  root: {
    margin: '0px',
    overflow: 'visible',
    zIndex: 300,
  },
  paper: {
    margin: '0px',
    borderRadius: '10px',
    position: 'fixed',
    bottom: '10px',
    [theme.breakpoints.down(400)]: {
      bottom: '0px',
    },
    overflow: 'visible',
  },
}))(Dialog);

const RatingStar = withStyles(theme => ({
  root: {
    width: '52px',
    height: '52px',
    [theme.breakpoints.down(280)]: {
      width: '44px',
      height: '44px',
    },
  },
}))(Star);

const RatingStarBorderIcon = withStyles(theme => ({
  root: {
    width: '52px',
    height: '52px',
    [theme.breakpoints.down(280)]: {
      width: '44px',
      height: '44px',
    },
  },
}))(StarBorderIcon);

const useStyles = makeStyles(theme => ({
  backgroundRoot: {
    background: 'linear-gradient(rgba(0,0,0,0) 70%, rgba(1,115,198,0.4))',
    [theme.breakpoints.down(400)]: {
      background: 'linear-gradient(rgba(0,0,0,0) 13%, rgba(1,115,198,0.4))',
    },
  },
  icon: {
    width: '56px',
    marginLeft: '5px',
    marginRight: '5px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '2px',
      marginRight: '2px',
      width: '52px',
    },
    [theme.breakpoints.down(270)]: {
      marginLeft: '0px',
      marginRight: '0px',
      width: '48px',
    },
  },
  cancelIconRoot: {
    position: 'absolute',
    top: '-15px',
    color: '#575757',
    backgroundImage: 'radial-gradient(rgba(255,255,255) 60%, rgba(0,0,0,0) 60%)',
    'z-index': 100,
    left: '90%',
    [theme.breakpoints.down(400)]: {
      left: '85%',
    },
    [theme.breakpoints.down(315)]: {
      left: '80%',
    },
  },
}));

export interface UserFeedbackForm {
  rating: string;
  feedbackMessage: string;
}

export interface UserFeedbackDialogProps {
  data: any;
  isUploadModalOpen: boolean;
  hasModalBeenClosed: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement<any, any>},
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" mountOnEnter unmountOnExit ref={ref} {...props} timeout={400} />;
});

export const UserFeedbackDialog = ({
  data,
  isUploadModalOpen,
  hasModalBeenClosed,
}: UserFeedbackDialogProps) => {
  const {register, handleSubmit, control, getValues} = useForm<UserFeedbackForm>();
  const [userAtomState] = useUserAtomState();
  const logrocketurl = userAtomState.logrocketurl;
  const isAgent = userAtomState.gettingStarted?.customerType === CustomerTypes.SaAgent;

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isDelayOver, setIsDelayOver] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const feedbackRating = useWatch<string>({control, name: 'rating'});

  const closeDialog = () => {
    trackEvent({category: 'User Feedback', label: 'Cancelled'});
    setIsOpen(false);
  };

  useEffect(() => {
    if (isAgent) {
      setTimeout(() => {
        setIsDelayOver(true);
      }, 10000);
    } else {
      setTimeout(() => {
        openDialog();
      }, 3500);
    }
  }, []);

  useEffect(() => {
    if (isAgent && !hasBeenOpened) {
      if (hasModalBeenClosed || (isDelayOver && !isUploadModalOpen)) {
        openDialog();
      }
    }
  }, [isDelayOver, isUploadModalOpen, hasModalBeenClosed]);

  const openDialog = () => {
    setIsOpen(true);
    setHasBeenOpened(true);
    trackEvent({category: 'User Feedback', label: 'Popup Displayed'});
  };

  const onSubmit = () => {
    const feedbackData = {
      ...data,
      rating: getValues('rating').toString(),
      feedbackMessage: getValues('feedbackMessage'),
      systemOfRecord: 'FNOL',
      logrocketurl,
    };

    userFeedback({...feedbackData}).catch(error => console.log(error));

    trackEvent({category: 'User Feedback', label: 'Shared Feedback'});

    Snowplow.track.customerFeedback({
      customerRating: feedbackData.rating,
      customerFeedback: feedbackData.feedbackMessage,
      claimNumber: data.claimNumber,
      email: feedbackData.email,
      logrocketurl,
      persona: userAtomState.gettingStarted?.customerType,
    });

    setIsOpen(false);
  };

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const fourHundredPxDown = useMediaQuery(theme.breakpoints.down(400));
  const ThreeSeventyPxDown = useMediaQuery(theme.breakpoints.down(370));
  const ThreeFiftyPxDown = useMediaQuery(theme.breakpoints.down(350));
  const ThreeThirtyPxDown = useMediaQuery(theme.breakpoints.down(330));

  const getLabelSpacing = () => {
    if (fourHundredPxDown) {
      return 6;
    } else if (isMobile) {
      return 2;
    } else {
      return 3;
    }
  };

  const getLabelPadding = () => {
    if (ThreeThirtyPxDown) {
      return 4;
    } else if (ThreeFiftyPxDown) {
      return 3;
    } else if (ThreeSeventyPxDown) {
      return 2;
    } else {
      return 0;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FeedbackDialog
        open={isOpen}
        onClose={closeDialog}
        disableBackdropClick={true}
        TransitionComponent={Transition}
        BackdropProps={{
          classes: {root: classes.backgroundRoot},
          invisible: true,
        }}
        scroll="paper"
      >
        <CancelIcon
          fontSize="large"
          classes={{root: classes.cancelIconRoot}}
          onClick={closeDialog}
          data-testid="xButton"
        />
        <Grid container alignItems="center" direction="column">
          <Box textAlign="center" justifyContent="center" my={4} mx={{xs: 3, sm: 4}}>
            <Grid item>
              <Box
                textAlign="left"
                fontWeight={550}
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                alignItems="flex-start"
              >
                <Box mr={2} ml={1} fontSize={{xs: 30, sm: 32}}>
                  <img alt="Smiley emoji" src={SmileyPng} width="43px" height="43px" />
                </Box>
                <Box
                  flexWrap="wrap"
                  maxWidth="300px"
                  fontSize={{xs: 18, sm: 20}}
                  data-testid="feedbackDialogText"
                >
                  How easy was it to file your claim today?
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box mt={2}>
                <Controller
                  name="rating"
                  control={control}
                  defaultValue={'0'}
                  render={({onChange, value}) => {
                    return (
                      <Rating
                        size={isMobile ? 'medium' : 'large'}
                        icon={<RatingStar fontSize={isMobile ? 'small' : 'large'} />}
                        classes={{icon: classes.icon}}
                        emptyIcon={<RatingStarBorderIcon fontSize={isMobile ? 'small' : 'large'} />}
                        onChange={(event, value) => {
                          onChange(value);
                          if (value && value !== 0) {
                            setDisableSubmit(false);
                          } else {
                            setDisableSubmit(true);
                          }
                        }}
                        value={value}
                      />
                    );
                  }}
                />
                <Box fontSize={12} fontWeight={500} display="flex" justifyContent="center" mb={3}>
                  <Grid container spacing={getLabelSpacing()}>
                    <Grid item xs={5} sm={4}>
                      <Box component="span" textAlign="left" mr={getLabelPadding()}>
                        Difficult
                      </Box>
                    </Grid>
                    <Grid item xs={2} sm={4} />
                    <Grid item xs={5} sm={4}>
                      <Box component="span" textAlign="right" ml={getLabelPadding()}>
                        Easy
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            {feedbackRating && feedbackRating > '0' && (
              <Grid item>
                <Box pb={2} px={2}>
                  <Box pb={1} textAlign="left" fontSize="12pt">
                    {feedbackRating < '3'
                      ? 'What made it difficult for you?'
                      : 'Describe your feedback.'}
                  </Box>
                  <TextField
                    autoFocus
                    name="feedbackMessage"
                    inputRef={register}
                    inputProps={{
                      'aria-labelledby': 'feedback.feedbackMessage',
                      id: 'feedback.feedbackMessage',
                      maxLength: 250,
                      className: 'feedbackMessage',
                    }}
                    multiline
                    placeholder="Type something..."
                    fullWidth
                    variant="outlined"
                    defaultValue=""
                  />
                </Box>
              </Grid>
            )}
            <Grid container item justify="space-around">
              <Grid item xs={4} sm={5}>
                <FeedbackButton
                  size={isMobile ? 'medium' : 'large'}
                  fullWidth
                  onClick={closeDialog}
                >
                  Cancel
                </FeedbackButton>
              </Grid>
              <Grid item xs={6} sm={5}>
                <FeedbackButton
                  variant="contained"
                  color="secondary"
                  size={isMobile ? 'medium' : 'large'}
                  fullWidth
                  type="submit"
                  disabled={disableSubmit}
                  onClick={onSubmit}
                >
                  Share With Us!
                </FeedbackButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </FeedbackDialog>
    </form>
  );
};
