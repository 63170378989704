import Cookies from 'js-cookie';
import React, {ReactElement, useEffect, useState} from 'react';
import {BaseSwitch} from '../components/common/BaseSwitch/BaseSwitch';
import {RefreshToken, SAAuth} from './utils/auth';
import {getSearchParams, setSessionCookieData} from './utils';
import {userauthorization} from '../services';
import {CognitoUserSession} from 'amazon-cognito-identity-js';
import {format} from 'date-fns';

export interface AuthenticatedRouteProps {
  children: ReactElement;
  path: string;
  redirectUrl: string;
}

export const AuthenticatedRoute = ({children, path, redirectUrl}: AuthenticatedRouteProps) => {
  const [canRender, setCanRender] = useState<boolean>(false);
  const [isRegisteredUser, setIsRegisteredUser] = useState<boolean>(true);
  const isAssociate = path === 'associate';

  useEffect(() => {
    // try to get a cached user session
    SAAuth.currentSession()
      .then(async (session: CognitoUserSession & RefreshToken) => {
        setSessionCookieData({
          path,
          userToken: session?.idToken?.jwtToken,
          userSession: JSON.stringify(session),
          refreshToken: session?.refreshToken?.token,
        });

        if (isAssociate) {
          try {
            const response = await userauthorization();
            setIsRegisteredUser(response.data.data);
          } catch {
            setIsRegisteredUser(false);
          } finally {
            setCanRender(true);
            const tokenData: any = session?.idToken;
            const cognitoUsername = tokenData?.payload?.['cognito:username'];
            const email = tokenData?.payload?.email;
            const name = `${tokenData?.payload?.given_name} ${tokenData?.payload?.family_name}`;
            const lastLoggedIn = format(new Date(Date.now()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

            Cookies.set('associateCredentials', JSON.stringify({name, email}));

            pendo.initialize({
              visitor: {
                id: email,
                cognitoUsername,
                name,
                lastLoggedIn,
              },
            });
          }
        } else {
          setCanRender(true);
        }
      })
      .catch(() => {
        Cookies.set('authenticated-redirect', redirectUrl);
        SAAuth.signIn().catch(() => {
          // if there are any issues
          // during the sign in process
          // send the user to the root.
          setCanRender(true);
        });
      });
  }, []);

  return (
    <BaseSwitch
      matches={canRender}
      then={React.cloneElement(children, {
        urlPrams: {...getSearchParams()},
        isRegisteredUser: isRegisteredUser,
      })}
    />
  );
};
