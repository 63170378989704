import React from 'react';
import {Typography, makeStyles, Box, Paper, Grid, useTheme, useMediaQuery} from '@material-ui/core';
import {RoundCheckBox} from '../../../../Icon/assets/RoundCheckBox';
import {ModalStates, ModalTypes, useModalController} from '../../../../Providers/ModalController';
import {CustomerTypes} from '../../../../../../commonTypes';
import {SummaryTile} from '../../SummaryTile/SummaryTile';
import {UserPersonaSwitch} from '../../../../UserPersonaSwitch/UserPersonaSwitch';
import {CommentsDialog} from '../../../../CommentsDialog/CommentsDialog';
import {PdfSection} from '../../PdfSection/PdfSection';
import {SUMMARY_PDF_ID} from '../../../../../pdf/constants';
import {BaseSwitch} from '../../../../BaseSwitch/BaseSwitch';
import {useFeatureFlags} from '../../../../Providers/Providers';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../../../RegisterBreadcrumb/RegisterBreadcrumb';
import {useSetAddNotesModalAtomState} from '../../../../../../atoms';

export interface SuccessSummaryFileUploadProps {
  claimNumber: string;
  publicID?: string;
  downloadPDF?: () => void;
}

const useStyles = makeStyles(theme => ({
  parentElement: {
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '56px',
    paddingBottom: '56px',
  },
  greenText: {
    color: theme.palette.secondary.main,
    fontSize: '27.65px',
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainGrid: {
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'spaced-evenly',
    },
  },
  border: {
    [theme.breakpoints.up('md')]: {
      '&:nth-of-type(odd)': {
        borderRight: '1px solid #BFCBDC',
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
}));

export const associateDownloadText = 'Need to email a copy of the claim to an agent or insured?';
export const othersDownloadText = 'Download a copy of the claim for your own records.';

export const SuccessSummaryFileUpload = ({
  claimNumber,
  downloadPDF,
  publicID,
}: SuccessSummaryFileUploadProps) => {
  const {dispatchModal} = useModalController();
  const setAddNoteModalAtomState = useSetAddNotesModalAtomState();

  const classes = useStyles();
  const link = `${process.env.REACT_APP_CCC_URL}${claimNumber}`;
  const {featureFlags} = useFeatureFlags();

  const onClick = () => {
    dispatchModal &&
      dispatchModal({
        type: ModalStates.On,
        name: ModalTypes.Upload,
      });
  };
  const onCCClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    window.open(link);
  };

  const addNotesHandler = () => {
    setAddNoteModalAtomState({open: true});
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Paper elevation={3}>
      <RegisterBreadcrumb
        waypointName={WaypointNames.ClaimNumber}
        displayName={WAYPOINTS[WaypointNames.ClaimNumber].displayName}
      >
        <Box p={3} className={classes.parentElement}>
          <Box className={classes.centerBox}>
            <RoundCheckBox />
            <PdfSection className={SUMMARY_PDF_ID}>
              <Box p={3}>
                <Typography className={classes.greenText} data-testid="successHeader">
                  Success! <b>{`Claim #${claimNumber}`}</b> has been received.
                </Typography>
              </Box>
            </PdfSection>
          </Box>

          <Grid container xs={12} className={classes.mainGrid}>
            <UserPersonaSwitch
              ifPersonas={[CustomerTypes.SaAgent]}
              then={
                <>
                  <Grid item xs={12}>
                    <SummaryTile
                      headerText="If the insured has any photos or files related to their claim, upload them now to accelerate the process."
                      buttonText="ADD PHOTOS & FILES"
                      onClick={onClick}
                      bgColor
                    />
                  </Grid>
                </>
              }
            />

            <UserPersonaSwitch
              ifPersonas={[CustomerTypes.Associate]}
              then={
                <>
                  <BaseSwitch
                    matches={featureFlags?.FF_DCX_2627}
                    then={
                      <>
                        <Grid item xs={12} md={6} className={classes.border}>
                          <SummaryTile
                            headerText="Need to add any changes or additional info to the claim?"
                            buttonText="VIEW IN CLAIMS CENTER"
                            href={link}
                            onClick={onCCClick}
                            bgColor
                          />
                        </Grid>

                        <Grid item xs={12} md={6} className={classes.border}>
                          <SummaryTile
                            headerText="Need to add additional notes about the claim?"
                            buttonText="ADD NOTE"
                            onClick={addNotesHandler}
                            hideBorder
                            bgColor
                          />
                        </Grid>
                        <CommentsDialog claimNumber={claimNumber} publicID={publicID || ''} />
                      </>
                    }
                    otherwise={
                      <>
                        <Grid item xs={12}>
                          <SummaryTile
                            headerText="Need to add any changes or additional info to the claim?"
                            buttonText="VIEW IN CLAIMS CENTER"
                            href={link}
                            onClick={onCCClick}
                            hideBorder
                            bgColor
                            tileHeight="200px"
                            boxHeight="160px"
                          />
                        </Grid>
                      </>
                    }
                  />
                  <Grid item xs={12}>
                    <SummaryTile
                      headerText={associateDownloadText}
                      buttonText="DOWNLOAD CLAIM SUMMARY"
                      onClick={downloadPDF}
                      tileHeight="200px"
                      boxHeight="160px"
                      customStyle={{fontSize: isMobile ? '16px' : '19px'}}
                    />
                  </Grid>
                </>
              }
              otherwise={
                <>
                  <Grid item xs={12}>
                    <SummaryTile
                      headerText={othersDownloadText}
                      buttonText="DOWNLOAD CLAIM SUMMARY"
                      onClick={downloadPDF}
                      tileHeight="200px"
                      boxHeight="160px"
                      customStyle={{fontSize: '19px'}}
                    />
                  </Grid>
                </>
              }
            />
          </Grid>
        </Box>
      </RegisterBreadcrumb>
    </Paper>
  );
};
