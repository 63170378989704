import React, {useState, useContext} from 'react';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {Box, Grid} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import * as yup from 'yup';
import {SATextField} from '../../common/TextField/TextField';
import {DynamicCheckboxList, Option} from '../../common/DynamicCheckboxList/DynamicCheckboxList';
import {UserPersonaContext} from '../../common/Providers/Providers';
import {
  IncidentTypes,
  INCIDENT_MAPPING,
  INCIDENT_NATURE_OF_LOSS_MAPPING,
  NatureOfLoss,
} from '../../common/Incidents/Incidents';
import {SASelect} from '../../common/Select/Select';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {CustomerTypes, NOT_THIRD_PARTY} from '../../../commonTypes';
import {Catastrophe} from '../../auto/Catastrophe/Catastrophe';
import {useRemoveSectionOccurrences} from '../../../atoms';
import {ContactSections} from '../../common/ContactInfo/types';

export interface NatureOfLossOption {
  label: string;
  value: string;
  incidentTypes?: string[];
}

export interface PropertyIncidentDetailsProps {
  setShowDamageDetails?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFireDamageDetails?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowWaterDamageDetails?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWeatherDamage?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsYourHomeAndStructure?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOtherPropertyDetails?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowInjuredDetails?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTheft?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PropertyIncidentDetailsSchema = {
  incidents: yup.array().min(1, 'Select at least one incident'),
  natureOfLoss: yup.string().when('incidents', {
    is: (value: string[]) => value.length > 0,
    then: yup.string().required('Please select the nature of your loss'),
  }),
  otherDetails: yup.string().when('incidents', {
    is: (value: string[]) => {
      return (value || []).length >= 0;
    },
    then: yup.string().max(250, 'Character limit exceeded').required('This field is required'),
  }),
};

export const PropertyIncidentDetailsTestSchema = yup.object().shape({
  incidentDetails: yup.object().shape(PropertyIncidentDetailsSchema),
});

export const PropertyIncidentDetails = (props: PropertyIncidentDetailsProps) => {
  const {register, errors, trigger} = useFormContext();
  const {userPersona} = useContext(UserPersonaContext);
  const userIsSaCustomer = userPersona === CustomerTypes.SaCustomer;
  const [selectedIncidents, setSelectedIncidents] = useState<Option[]>([]);
  const [natureOfLossOptions, setNatureOfLossOptions] = useState<NatureOfLossOption[]>([]);
  const [lossCause, setLossCause] = useState('');
  const removeAdditionalInjuredOccurrences = useRemoveSectionOccurrences(
    ContactSections.AdditionalInjured
  );
  const removeOtherPropertyOccurrences = useRemoveSectionOccurrences(
    ContactSections.OtherPropertyOwner
  );

  const [incidentOptions, setIncidentOptions] = useState<Option[]>([
    {
      label: userIsSaCustomer
        ? IncidentTypes.YourHomeAndStructure
        : IncidentTypes.InsuredHomeAndStructure,
      value: INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure],
      checked: false,
      disabled: false,
      testID: INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure],
      personas: NOT_THIRD_PARTY,
    },
    {
      label: userIsSaCustomer ? IncidentTypes.YourBelongings : IncidentTypes.InsuredsBelongings,
      value: INCIDENT_MAPPING[IncidentTypes.YourBelongings],
      checked: false,
      disabled: false,
      testID: INCIDENT_MAPPING[IncidentTypes.YourBelongings],
      personas: NOT_THIRD_PARTY,
    },
    {
      label: IncidentTypes.OtherPersonsProperty,
      value: INCIDENT_MAPPING[IncidentTypes.OtherPersonsProperty],
      checked: false,
      disabled: false,
      testID: INCIDENT_MAPPING[IncidentTypes.OtherPersonsProperty],
    },
    {
      label: IncidentTypes.PersonalInjuries,
      value: INCIDENT_MAPPING[IncidentTypes.PersonalInjuries],
      checked: false,
      disabled: false,
      testID: INCIDENT_MAPPING[IncidentTypes.PersonalInjuries],
    },
  ]);

  const insuredPropertyIncidents = [
    INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure],
    INCIDENT_MAPPING[IncidentTypes.YourBelongings],
  ];

  const lossCauses: NatureOfLossOption[] = [
    {
      label: NatureOfLoss.Animal,
      value:
        INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Animal],
      incidentTypes: insuredPropertyIncidents,
    },
    {
      label: NatureOfLoss.FallingObject,
      value:
        INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][
          NatureOfLoss.FallingObject
        ],
      incidentTypes: insuredPropertyIncidents,
    },
    {
      label: NatureOfLoss.Fire,
      value: INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Fire],
      incidentTypes: insuredPropertyIncidents,
    },
    {
      label: NatureOfLoss.Vandalism,
      value:
        INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Vandalism],
      incidentTypes: insuredPropertyIncidents,
    },
    {
      label: NatureOfLoss.Water,
      value:
        INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Water],
      incidentTypes: insuredPropertyIncidents,
    },
    {
      label: NatureOfLoss.MoldRot,
      value:
        INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.MoldRot],
      incidentTypes: insuredPropertyIncidents,
    },
    {
      label: NatureOfLoss.Theft,
      value:
        INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Theft],
      incidentTypes: insuredPropertyIncidents,
    },
    {
      label: NatureOfLoss.WeatherRelated,
      value:
        INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][
          NatureOfLoss.WeatherRelated
        ],
      incidentTypes: [
        INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure],
        INCIDENT_MAPPING[IncidentTypes.YourBelongings],
        INCIDENT_MAPPING[IncidentTypes.OtherPersonsProperty],
      ],
    },
    {
      label: NatureOfLoss.NotWeatherRelated,
      value:
        INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.OtherPersonsProperty][
          NatureOfLoss.NotWeatherRelated
        ],
      incidentTypes: [INCIDENT_MAPPING[IncidentTypes.OtherPersonsProperty]],
    },
    {
      label: NatureOfLoss.FallSlipOrTrip,
      value:
        INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.PersonalInjuries][
          NatureOfLoss.FallSlipOrTrip
        ],
      incidentTypes: [INCIDENT_MAPPING[IncidentTypes.PersonalInjuries]],
    },
    {
      label: NatureOfLoss.Other,
      value:
        INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Other],
      incidentTypes: [
        INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure],
        INCIDENT_MAPPING[IncidentTypes.YourBelongings],
        INCIDENT_MAPPING[IncidentTypes.OtherPersonsProperty],
        INCIDENT_MAPPING[IncidentTypes.PersonalInjuries],
      ],
    },
  ];

  const handleIncidentChange = (incidentOption: Option, checked: boolean, index: number) => {
    let tmp = Object.create(incidentOptions) as Option[];
    tmp[index].checked = checked;

    const tmpSelectedIncidents = tmp.filter(option => option.checked);
    setIncidentOptions(tmp);
    setSelectedIncidents(tmpSelectedIncidents);

    const selectedIncidentValues = tmpSelectedIncidents.map(incident => incident.value);

    const includesSelectedIncidents = (lossCause: NatureOfLossOption) => {
      return selectedIncidentValues.some(
        incidentValue => lossCause.incidentTypes && lossCause.incidentTypes.includes(incidentValue)
      );
    };

    const tmpNatureOfLossOptions = lossCauses.filter(lossCause =>
      includesSelectedIncidents(lossCause)
    );

    setNatureOfLossOptions(tmpNatureOfLossOptions);

    props.setIsYourHomeAndStructure &&
      props.setIsYourHomeAndStructure(
        selectedIncidentValues.includes(INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure])
      );

    const showDamageDetailsOnIncidentChange = () => {
      // TODO Find a common mapping for conditional statements
      return (
        (selectedIncidentValues.includes(INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure]) &&
          lossCause !== '' &&
          lossCause !==
            INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][
              NatureOfLoss.Fire
            ] &&
          lossCause !== '' &&
          lossCause !==
            INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][
              NatureOfLoss.Water
            ]) ||
        (selectedIncidentValues.includes(INCIDENT_MAPPING[IncidentTypes.YourBelongings]) &&
          lossCause ===
            INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][
              NatureOfLoss.WeatherRelated
            ])
      );
    };

    props.setShowDamageDetails && props.setShowDamageDetails(showDamageDetailsOnIncidentChange());

    const showFireDamageDetailsOnIncidentChange = () => {
      return (
        selectedIncidentValues.includes(INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure]) &&
        lossCause ===
          INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Fire]
      );
    };

    props.setShowFireDamageDetails &&
      props.setShowFireDamageDetails(showFireDamageDetailsOnIncidentChange());

    const showWaterDamageDetailsOnIncidentChange = () => {
      return (
        selectedIncidentValues.includes(INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure]) &&
        lossCause ===
          INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Water]
      );
    };

    props.setShowWaterDamageDetails &&
      props.setShowWaterDamageDetails(showWaterDamageDetailsOnIncidentChange());

    if (tmpSelectedIncidents.length === 0) {
      setLossCause('');
      props.setIsWeatherDamage && props.setIsWeatherDamage(false);
    }

    if (
      props.setShowOtherPropertyDetails &&
      incidentOption.value === INCIDENT_MAPPING[IncidentTypes.OtherPersonsProperty]
    ) {
      props.setShowOtherPropertyDetails(checked);

      if (!checked) {
        removeOtherPropertyOccurrences();
      }
    }

    if (
      props.setShowInjuredDetails &&
      incidentOption.value === INCIDENT_MAPPING[IncidentTypes.PersonalInjuries]
    ) {
      props.setShowInjuredDetails(checked);

      if (!checked) {
        removeAdditionalInjuredOccurrences();
      }
    }

    trigger('incidentDetails.incidents');
  };

  const showDamageDetailsOnLossCauseChange = (lossCause: string) => {
    const selectedIncidentValues = selectedIncidents.map(incident => incident.value);

    if (lossCause && props.setIsWeatherDamage) {
      if (
        lossCause !==
        INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][
          NatureOfLoss.WeatherRelated
        ]
      ) {
        props.setIsWeatherDamage(false);
        return (
          selectedIncidentValues.includes(INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure]) &&
          lossCause !==
            INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][
              NatureOfLoss.Fire
            ] &&
          lossCause !==
            INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Water]
        );
      } else {
        props.setIsWeatherDamage(true);
        return selectedIncidentValues.some(incidentValue =>
          insuredPropertyIncidents.includes(incidentValue)
        );
      }
    } else {
      props.setIsWeatherDamage && props.setIsWeatherDamage(false);
      return false;
    }
  };

  const showFireDamageDetailsOnLossCauseChange = (lossCause: string) => {
    const selectedIncidentValues = selectedIncidents.map(incident => incident.value);

    if (lossCause) {
      return (
        selectedIncidentValues.includes(INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure]) &&
        lossCause ===
          INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Fire]
      );
    } else {
      return false;
    }
  };

  const showWaterDamageDetailsOnLossCauseChange = (lossCause: string) => {
    const selectedIncidentValues = selectedIncidents.map(incident => incident.value);

    if (lossCause) {
      return (
        selectedIncidentValues.includes(INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure]) &&
        lossCause ===
          INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Water]
      );
    } else {
      return false;
    }
  };

  const handleNatureOfLossChange = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const selectedLossCause = event.target.value;
    setLossCause(selectedLossCause);
    props.setIsTheft &&
      props.setIsTheft(
        selectedLossCause ===
          INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Theft]
      );

    props.setShowDamageDetails &&
      props.setShowDamageDetails(showDamageDetailsOnLossCauseChange(selectedLossCause));

    props.setShowFireDamageDetails &&
      props.setShowFireDamageDetails(showFireDamageDetailsOnLossCauseChange(selectedLossCause));

    props.setShowWaterDamageDetails &&
      props.setShowWaterDamageDetails(showWaterDamageDetailsOnLossCauseChange(selectedLossCause));
  };

  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.IncidentDetails}
      displayName={WAYPOINTS[WaypointNames.IncidentDetails].displayName}
    >
      <FormContainer header="Incident Details">
        <Box>
          <DynamicCheckboxList
            options={incidentOptions}
            name="incidentDetails.incidents"
            onOptionsChange={handleIncidentChange}
            errorMessage={errors?.incidentDetails?.incidents?.message}
          />
          {selectedIncidents.length > 0 && (
            <Box py={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8} md={5}>
                  <SASelect
                    name="incidentDetails.natureOfLoss"
                    inputRef={register}
                    label="What was the nature of the incident?"
                    id="natureOfLoss"
                    error={errors?.incidentDetails?.hasOwnProperty('natureOfLoss')}
                    helperText={errors?.incidentDetails?.natureOfLoss?.message}
                    value={lossCause}
                    onChange={handleNatureOfLossChange}
                  >
                    {natureOfLossOptions.map(option => (
                      <option key={option.value} value={option.value} data-testid={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </SASelect>
                </Grid>
              </Grid>
            </Box>
          )}
          {lossCause && (
            <Box pb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <SATextField
                    name="incidentDetails.otherDetails"
                    label="Please provide a brief description"
                    id="otherDetails"
                    inputRef={register()}
                    InputLabelProps={{'aria-labelledby': 'otherDetails'}}
                    error={errors?.incidentDetails?.hasOwnProperty('otherDetails')}
                    helperText={
                      errors?.incidentDetails?.otherDetails?.message || '250-character limit'
                    }
                    autoFocus
                    multiline
                    showCharacterCount
                    characterLimit={250}
                    inputProps={{className: 'incidentDetailsDescription'}}
                  />
                </Grid>
                {userPersona === CustomerTypes.Associate && (
                  <Catastrophe
                    hasDamageDueToWeather={
                      lossCause ===
                      INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][
                        NatureOfLoss.WeatherRelated
                      ]
                    }
                  />
                )}
              </Grid>
            </Box>
          )}
        </Box>
      </FormContainer>
    </RegisterBreadcrumb>
  );
};
