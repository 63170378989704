import {Box, Grid} from '@material-ui/core';
import React, {ChangeEvent, useState, useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {DynamicCheckboxList, Option} from '../../common/DynamicCheckboxList/DynamicCheckboxList';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {
  IncidentTypes,
  INCIDENT_MAPPING,
  INCIDENT_NATURE_OF_LOSS_MAPPING,
  NatureOfLoss,
  WhatWasDamaged,
} from '../../common/Incidents/Incidents';
import {SASelect} from '../../common/Select/Select';
import * as yup from 'yup';
import {
  WHERE_IS_THE_DAMAGE_TO_THE_HOME_OPTIONS,
  OPTIONS,
  WEATHER_RELATED_NATURE_OF_LOSS_OPTIONS,
  WhereTheHomeWasDamaged,
  WHAT_WAS_DAMAGED_TO_THE_EXTERIOR_OF_THE_HOME_OPTIONS,
  WHAT_WAS_DAMAGED_TO_THE_INTERIOR_OF_THE_HOME_OPTIONS,
  WHICH_ROOMS_WERE_DAMAGED_ON_THE_FIRST_FLOOR,
  WHICH_ROOMS_WERE_DAMAGED_ON_THE_SECOND_FLOOR,
  WHICH_ROOMS_WERE_DAMAGED_IN_THE_BASEMENT,
  DamageToExteriorHome,
  DamageToInteriorHome,
} from './DamageDetailOptions';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {YesNoUnk} from '../../common/YesNoUnk/YesNoUnk';
import {CustomerTypes} from '../../../commonTypes';
import {useFeatureFlags} from '../../common/Providers/Providers';
import {validationSchemaSwitch} from '../../../utils/utils';
import {
  cityValidation,
  emailValidation,
  phoneValidation,
  stateValidation,
  streetAddressValidation,
  zipCodeValidation,
} from '../../../validations';
import {Uninhabitable} from '../Uninhabitable/Uninhabitable';
import {Contractor} from './Contractor';
import {EmergencyServices} from '../EmergencyServices.tsx/EmergencyServices';

export interface DamageDetailsProps {
  isYourHomeAndStructure?: boolean;
  isWeatherDamage?: boolean;
  isTheft?: boolean;
}

export interface PropertyTypeProps {
  label: string;
  value: string;
  checked: boolean;
  disabled: boolean;
  testID: string;
}

export const Header = ({children}: {children: React.ReactChild}) => (
  <Box fontSize="16px" pb={3} pt={4}>
    {children}
  </Box>
);

export const DamageDetailsSchema = {
  lossCause: yup.string().when(['incidents', 'natureOfLoss'], {
    is: (incidents: string[], natureOfLoss: string) => {
      const parsedIncidents = incidents
        .map((v: string) => JSON.parse(v))
        .map((v: {label: string; value: string}) => {
          return v;
        });

      const parsedIncidentValues = parsedIncidents.map(x => x.value);

      return (
        (parsedIncidentValues.includes(INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure]) ||
          parsedIncidentValues.includes(INCIDENT_MAPPING[IncidentTypes.YourBelongings])) &&
        natureOfLoss ===
          INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][
            NatureOfLoss.WeatherRelated
          ]
      );
    },
    then: yup.string().required('Please select the nature of your loss').nullable(),
  }),
  propertyTypes: yup.array().when(['incidents', 'natureOfLoss'], {
    is: (incidents: string[], natureOfLoss: string) => {
      const parsedIncidents = incidents
        .map((v: string) => JSON.parse(v))
        .map((v: {label: string; value: string}) => {
          return v;
        });

      return (
        natureOfLoss &&
        parsedIncidents
          .map(x => x.value)
          .includes(INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure])
      );
    },
    then: yup.array().min(1, 'Select at least one property type').nullable(),
  }),
  whereDamageToHomeIs: yup.array().when('propertyTypes', {
    is: (value: Array<string>) => {
      const hasHomeSelected = value?.filter((v: string) => v.includes(WhatWasDamaged.Home));
      return hasHomeSelected?.length > 0;
    },
    then: yup.array().nullable(),
  }),
  contractor: validationSchemaSwitch({
    when: ['$featureFlags', 'hasAContractorBeenHired'],
    is: (ff: any, hasAContractorBeenHired: string) =>
      ff?.FF_DCX_2710 && hasAContractorBeenHired === 'yes',
    then: yup.object({
      contractorName: yup.string().required('Contractor Name is required'),
      email: emailValidation(true),
      phoneNumbers: yup.array().of(
        yup.object().shape({
          phoneNumber: yup.lazy((item?: any, options?: any) => {
            if (options.path === 'incidentDetails.contractor.phoneNumbers[0].phoneNumber') {
              return phoneValidation(true).required('Phone Number is required');
            } else {
              return phoneValidation(true).nullable();
            }
          }),
        })
      ),
      autocompleteAddress: yup.string().nullable(),
      manualAddressEntry: yup.string().nullable(),
      address: streetAddressValidation().nullable(),
      city: cityValidation(true),
      state: stateValidation().nullable(),
      zipCode: zipCodeValidation(true),
    }),
  }),
};

export const DamageDetails = ({
  isYourHomeAndStructure,
  isWeatherDamage,
  isTheft,
}: DamageDetailsProps) => {
  const {register, errors, trigger, getValues} = useFormContext();

  const [whereDamageToTheHomeIs, setWhereDamageToTheHomeIs] = useState<Set<string>>(new Set());
  const [whatWasDamagedToTheExteriorOfTheHome, setWhatWasDamagedToTheExteriorOfTheHome] = useState<
    Set<string>
  >(new Set<string>());
  const [wasDamageCausedByTreeOrLimb, setWasDamageCausedByTreeOrLimb] = useState<boolean | null>();
  const [hasTreeOrLimbOnTheRoof, setIsTreeLimbOnTheRoof] = useState<boolean | null>();
  const [whatWasDamagedToTheInteriorOfTheHome, setWhatWasDamagedToTheInteriorOfTheHome] = useState<
    Set<string>
  >(new Set<string>());
  const [whichRoomsWereDamagedOnTheFirstFloor, setWhichRoomsWereDamagedOnTheFirstFloor] = useState<
    Set<string>
  >(new Set<string>());
  const [whichRoomsWereDamagedOnTheSecondFloor, setWhichRoomsWereDamagedOnTheSecondFloor] =
    useState<Set<string>>(new Set<string>());
  const [whichRoomsWereDamagedInTheBasement, setWhichRoomsWereDamagedInTheBasement] = useState<
    Set<string>
  >(new Set<string>());
  const [lossCause, setLossCause] = useState<string | unknown>();
  const [propertyTypes, setPropertyTypes] = useState<Set<string>>(new Set<string>());
  const {featureFlags} = useFeatureFlags();

  const hasHomeSelected = propertyTypes.has(WhatWasDamaged.Home);

  const hasRoofSelected = whatWasDamagedToTheExteriorOfTheHome?.has(DamageToExteriorHome.Roof);

  const hasFirstFloorSelected = whatWasDamagedToTheInteriorOfTheHome?.has(
    DamageToInteriorHome.FirstFloor
  );

  const hasSecondFloorSelected = whatWasDamagedToTheInteriorOfTheHome?.has(
    DamageToInteriorHome.SecondFloor
  );

  const hasBasementSelected = whatWasDamagedToTheInteriorOfTheHome?.has(
    DamageToInteriorHome.Basement
  );

  const isWindDamage =
    lossCause ===
    INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Wind];

  const isHailDamage =
    lossCause ===
    INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Hail];

  const isWinterDamage =
    lossCause ===
    INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.WinterWeather];

  const shouldDisplayHomeDamageOptions =
    hasHomeSelected && isWeatherDamage && (isWindDamage || isHailDamage);

  const isWeatherRelatedAndWind = isWeatherDamage && isWindDamage;

  const showEmergencyServices =
    !isTheft &&
    isYourHomeAndStructure &&
    (propertyTypes.has(WhatWasDamaged.Home) ||
      propertyTypes.has(WhatWasDamaged.DetachedGarage) ||
      propertyTypes.has(WhatWasDamaged.OtherBuilding) ||
      propertyTypes.has(WhatWasDamaged.Other));

  const showIsHabitableQuestion =
    (isWeatherRelatedAndWind || (isWeatherDamage && isWinterDamage)) && hasHomeSelected;
  const hasExteriorSelected = whereDamageToTheHomeIs.has(WhereTheHomeWasDamaged.Exterior);
  const hasInteriorSelected = whereDamageToTheHomeIs.has(WhereTheHomeWasDamaged.Interior);

  useEffect(() => {
    const formValues = getValues();

    [
      [whereDamageToTheHomeIs, setWhereDamageToTheHomeIs, 'whereDamageToHomeIs'],
      [
        whatWasDamagedToTheExteriorOfTheHome,
        setWhatWasDamagedToTheExteriorOfTheHome,
        'whatWasDamagedToTheExteriorOfTheHome',
      ],
      [
        whatWasDamagedToTheInteriorOfTheHome,
        setWhatWasDamagedToTheInteriorOfTheHome,
        'whatWasDamagedToTheInteriorOfTheHome',
      ],
      [
        whichRoomsWereDamagedOnTheFirstFloor,
        setWhichRoomsWereDamagedOnTheFirstFloor,
        'whichRoomsWereDamagedOnTheFirstFloor',
      ],
      [
        whichRoomsWereDamagedOnTheSecondFloor,
        setWhichRoomsWereDamagedOnTheSecondFloor,
        'whichRoomsWereDamagedOnTheSecondFloor',
      ],
      [
        whichRoomsWereDamagedInTheBasement,
        setWhichRoomsWereDamagedInTheBasement,
        'whichRoomsWereDamagedOnTheSecondFloor',
      ],
      [propertyTypes, setPropertyTypes, 'propertyTypes'],
    ].forEach((task: any) => {
      const [set, setState, formProperty] = task;

      set.clear();
      setState(new Set(set));
      formValues['incidentDetails'][formProperty] = [];
    });
  }, [isYourHomeAndStructure, isWeatherDamage]);

  const handlePropertyTypesChange = (option: Option, checked: boolean) => {
    trigger('incidentDetails.propertyTypes');

    if (checked) {
      propertyTypes.add(option.label);
    } else {
      propertyTypes.delete(option.label);
    }

    setPropertyTypes(new Set(propertyTypes));
  };

  useEffect(() => {
    const formValues = getValues();
    formValues['incidentDetails']['emergencyServices'] = [];
  }, [isYourHomeAndStructure, isTheft]);

  const whereDamageToTheHomeIsHandler = (option: Option, checked: boolean) => {
    if (checked) {
      whereDamageToTheHomeIs.add(option.label);
    } else {
      whereDamageToTheHomeIs.delete(option.label);
    }

    setWhereDamageToTheHomeIs(new Set(whereDamageToTheHomeIs));
  };

  const whatWasDamagedToTheExteriorOfTheHomeHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whatWasDamagedToTheExteriorOfTheHome?.add(option.label);
    } else {
      whatWasDamagedToTheExteriorOfTheHome?.delete(option.label);
    }

    setWhatWasDamagedToTheExteriorOfTheHome(new Set(whatWasDamagedToTheExteriorOfTheHome));
  };

  const wasDamageCausedByTreeOrLimbHandler = (value: string) => {
    setWasDamageCausedByTreeOrLimb(value === 'yes');
  };

  const isTreeLimbOnTheRoofHandler = (value: string) => {
    setIsTreeLimbOnTheRoof(value === 'yes');
  };

  const whatWasDamagedToTheInteriorOfTheHomeHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whatWasDamagedToTheInteriorOfTheHome?.add(option.label);
    } else {
      whatWasDamagedToTheInteriorOfTheHome?.delete(option.label);
    }

    setWhatWasDamagedToTheInteriorOfTheHome(new Set(whatWasDamagedToTheInteriorOfTheHome));
  };

  const whichRoomsWereDamagedOnTheFirstFloorHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsWereDamagedOnTheFirstFloor?.add(option.label);
    } else {
      whichRoomsWereDamagedOnTheFirstFloor?.delete(option.label);
    }

    setWhichRoomsWereDamagedOnTheFirstFloor(new Set(whichRoomsWereDamagedOnTheFirstFloor));
  };

  const whichRoomsWereDamagedOnTheSecondFloorHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsWereDamagedOnTheSecondFloor?.add(option.label);
    } else {
      whichRoomsWereDamagedOnTheSecondFloor?.delete(option.label);
    }

    setWhichRoomsWereDamagedOnTheSecondFloor(new Set(whichRoomsWereDamagedOnTheSecondFloor));
  };

  const whichRoomsWereDamagedInTheBasementHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsWereDamagedInTheBasement?.add(option.label);
    } else {
      whichRoomsWereDamagedInTheBasement?.delete(option.label);
    }

    setWhichRoomsWereDamagedInTheBasement(new Set(whichRoomsWereDamagedInTheBasement));
  };

  const onLossCauseChangeHandler = (
    event: ChangeEvent<{name?: string | undefined; value: unknown}>
  ) => {
    setLossCause(event?.target?.value);
    whereDamageToTheHomeIs.clear();
  };

  return (
    <FormContainer header={isWeatherDamage ? 'Weather Damage Details' : 'Property Damage Details'}>
      <>
        {isWeatherDamage && (
          <Grid item xs={12} sm={8} md={4}>
            <SASelect
              name="incidentDetails.lossCause"
              inputRef={register}
              label="What type of weather?"
              id="weatherLossCause"
              error={errors?.incidentDetails?.hasOwnProperty('lossCause')}
              helperText={errors?.incidentDetails?.lossCause?.message}
              onChange={onLossCauseChangeHandler}
              defaultValue=""
              inputProps={{
                'data-testid': 'LossCausSelection',
              }}
            >
              {WEATHER_RELATED_NATURE_OF_LOSS_OPTIONS.map(option => (
                <option key={option.value} value={option.value} data-testid={option.value}>
                  {option.label}
                </option>
              ))}
            </SASelect>
          </Grid>
        )}

        {((isYourHomeAndStructure && isWeatherDamage && lossCause) || !isWeatherDamage) && (
          <>
            <Header>What was damaged?</Header>
            <Box>
              {isYourHomeAndStructure && isWeatherDamage && lossCause && (
                <DynamicCheckboxList
                  options={OPTIONS}
                  name="incidentDetails.propertyTypes"
                  errorMessage={errors?.incidentDetails?.propertyTypes?.message}
                  onOptionsChange={handlePropertyTypesChange}
                />
              )}
              {!isWeatherDamage && (
                <DynamicCheckboxList
                  options={OPTIONS}
                  name="incidentDetails.propertyTypes"
                  errorMessage={errors?.incidentDetails?.propertyTypes?.message}
                  onOptionsChange={handlePropertyTypesChange}
                />
              )}
            </Box>
            {shouldDisplayHomeDamageOptions && (
              <>
                <Box>
                  <Header>Where is the damage to the home?</Header>
                </Box>
                <Box>
                  <DynamicCheckboxList
                    options={WHERE_IS_THE_DAMAGE_TO_THE_HOME_OPTIONS}
                    name="incidentDetails.whereDamageToHomeIs"
                    onOptionsChange={whereDamageToTheHomeIsHandler}
                  />
                </Box>
                {hasExteriorSelected && (
                  <>
                    {isWindDamage && !isHailDamage && (
                      <>
                        <Box>
                          <Header>Was the damage caused by a tree or tree limb?</Header>
                        </Box>
                        <Box>
                          <UserPersonaSwitch
                            ifPersonas={[CustomerTypes.SaAgent]}
                            then={
                              <YesNoUnk
                                name="incidentDetails.wasDamageCausedByTreeOrLimb"
                                noTestId="NoDamageWasCausedByTreeOrLimb"
                                dontKnowTestId="UnkDamageWasCausedByTreeOrLimb"
                                yesTestId="YesDamageWasCausedByTreeOrLimb"
                                onChange={wasDamageCausedByTreeOrLimbHandler}
                              />
                            }
                            otherwise={
                              <YesNoToggle
                                name="incidentDetails.wasDamageCausedByTreeOrLimb"
                                yesButtonTestId="YesDamageWasCausedByTreeOrLimb"
                                testId="NoDamageWasCausedByTreeOrLimb"
                                inputRef={register}
                                handleChange={wasDamageCausedByTreeOrLimbHandler}
                              />
                            }
                          />
                        </Box>
                        {wasDamageCausedByTreeOrLimb && (
                          <>
                            <Box>
                              <Header>Is the tree/limb on the roof?</Header>
                            </Box>
                            <Box>
                              <UserPersonaSwitch
                                ifPersonas={[CustomerTypes.SaAgent]}
                                then={
                                  <YesNoUnk
                                    name="incidentDetails.isTreeLimbOnTheRoof"
                                    noTestId="NoTreeLimbIsOnTheRoof"
                                    yesTestId="YesTreeLimbIsOnTheRoof"
                                    dontKnowTestId="UnkTreeLimbIsOnTheRoof"
                                    onChange={isTreeLimbOnTheRoofHandler}
                                  />
                                }
                                otherwise={
                                  <YesNoToggle
                                    name="incidentDetails.isTreeLimbOnTheRoof"
                                    yesButtonTestId="YesTreeLimbIsOnTheRoof"
                                    testId="NoTreeLimbIsOnTheRoof"
                                    inputRef={register}
                                    handleChange={isTreeLimbOnTheRoofHandler}
                                  />
                                }
                              />
                            </Box>
                          </>
                        )}
                      </>
                    )}
                    <Box>
                      <Header>What was damaged on the exterior of the home?</Header>
                    </Box>
                    <Box>
                      <DynamicCheckboxList
                        options={WHAT_WAS_DAMAGED_TO_THE_EXTERIOR_OF_THE_HOME_OPTIONS}
                        defaultChecked={hasTreeOrLimbOnTheRoof ? 'roof' : undefined}
                        name="incidentDetails.whatWasDamagedToTheExteriorOfTheHome"
                        onOptionsChange={whatWasDamagedToTheExteriorOfTheHomeHandler}
                      />
                    </Box>
                    {hasRoofSelected && (
                      <>
                        <Box>
                          <Header>Have temporary repairs been made to the roof?</Header>
                        </Box>
                        <Box>
                          <UserPersonaSwitch
                            ifPersonas={[CustomerTypes.SaAgent]}
                            then={
                              <YesNoUnk
                                name="incidentDetails.haveTemporaryRepairsBeenMadeToTheRoof"
                                yesTestId="YesTemporaryRepairsHaveBeenMadeToTheRoof"
                                dontKnowTestId="UnkTemporaryRepairsHaveBeenMadeToTheRoof"
                              />
                            }
                            otherwise={
                              <YesNoToggle
                                name="incidentDetails.haveTemporaryRepairsBeenMadeToTheRoof"
                                yesButtonTestId="YesTemporaryRepairsHaveBeenMadeToTheRoof"
                                inputRef={register}
                              />
                            }
                          />
                        </Box>
                      </>
                    )}
                  </>
                )}
                {hasInteriorSelected && (
                  <>
                    <Box>
                      <Header>What was damaged in the interior of the home?</Header>
                    </Box>
                    <Box>
                      <DynamicCheckboxList
                        options={WHAT_WAS_DAMAGED_TO_THE_INTERIOR_OF_THE_HOME_OPTIONS}
                        name="incidentDetails.whatWasDamagedToTheInteriorOfTheHome"
                        onOptionsChange={whatWasDamagedToTheInteriorOfTheHomeHandler}
                      />
                    </Box>
                    {hasFirstFloorSelected && (
                      <>
                        <Box>
                          <Header>Which rooms were damaged on the 1st floor?</Header>
                        </Box>
                        <Box>
                          <DynamicCheckboxList
                            options={WHICH_ROOMS_WERE_DAMAGED_ON_THE_FIRST_FLOOR}
                            name="incidentDetails.whichRoomsWereDamagedOnTheFirstFloor"
                            onOptionsChange={whichRoomsWereDamagedOnTheFirstFloorHandler}
                          />
                        </Box>
                      </>
                    )}
                    {hasSecondFloorSelected && (
                      <>
                        <Box>
                          <Header>Which rooms were damaged on the 2nd floor?</Header>
                        </Box>
                        <Box>
                          <DynamicCheckboxList
                            options={WHICH_ROOMS_WERE_DAMAGED_ON_THE_SECOND_FLOOR}
                            name="incidentDetails.whichRoomsWereDamagedOnTheSecondFloor"
                            onOptionsChange={whichRoomsWereDamagedOnTheSecondFloorHandler}
                          />
                        </Box>
                      </>
                    )}
                    {hasBasementSelected && (
                      <>
                        <Box>
                          <Header>Which rooms were damaged in the basement?</Header>
                        </Box>
                        <Box>
                          <DynamicCheckboxList
                            options={WHICH_ROOMS_WERE_DAMAGED_IN_THE_BASEMENT}
                            name="incidentDetails.whichRoomsWereDamagedInTheBasement"
                            onOptionsChange={whichRoomsWereDamagedInTheBasementHandler}
                          />
                        </Box>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {showIsHabitableQuestion && <Uninhabitable />}
          </>
        )}
        {showEmergencyServices && <EmergencyServices />}
        {isYourHomeAndStructure && <Contractor />}
      </>
    </FormContainer>
  );
};
