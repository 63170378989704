import React, {useState, useRef, useEffect} from 'react';
import * as yup from 'yup';
import {formatISO, format} from 'date-fns';
import {
  Box,
  Grid,
  Paper,
  Container,
  Divider,
  useMediaQuery,
  FormHelperText,
  withStyles,
  Typography,
  DialogProps,
} from '@material-ui/core';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import {useForm, Controller, FormProvider, useWatch} from 'react-hook-form';
import {AlertPanel} from '../common/AlertPanel/AlertPanel';
import {Icon} from '../common/Icon/Icon';
import {navigate} from '@reach/router';
import {
  draftClaim,
  duplicateCheck,
  OtherOptions,
  verifyPolicy,
  VerifyPolicyPayload,
} from '../../services';
import {SADatePicker} from '../common/DatePicker/DatePicker';
import {SATimePicker} from '../common/TimePicker/TimePicker';
import {SATextField} from '../common/TextField/TextField';
import {LogRocket} from '../../services/logRocket';
import {
  featureFlagSchemaSwitch,
  getUserInfoFromToken,
  returnRecentDuplicateClaims,
  scrollToRef,
} from '../../utils/utils';
import {v4 as uuidv4} from 'uuid';
import Cookies from 'js-cookie';
import {yupResolver} from '@hookform/resolvers';
import {PhoneHyperlink} from '../common/PhoneHyperlink/PhoneHyperlink';
import {
  homeownersPolicyNumberValidation,
  LossDateAndTimeSchema,
  vinValidation,
  zipCodeValidation,
} from '../../validations';
import {Snowplow} from '../../pages/utils/snowplow';
import {SAButton} from '../common/Button/Button';
import {DuplicateClaimDialog} from '../common/DuplicateClaimDialog/DuplicateClaimDialog';
import {
  COMMERCIAL_AUTO_TYPES,
  ContactTypes,
  CustomerTypes,
  Lobs,
  UserData,
} from '../../commonTypes';
import {DynamicRadioList, RadioOption} from '../common/DynamicRadioList/DynamicRadioList';
import {PersonCompanyRadios} from '../common/PersonCompanyRadios/PersonCompanyRadios';
import {PolicySearchResult} from './components/PolicySearchResult';
import AlertIcon from '../../assets/images/Alert.svg';
import {getPolicyNumber, INVALID_POLICY_ERROR_MESSAGE} from '../../pages/utils';
import {ErrorDialog, ErrorDialogProps} from '../common/ErrorDialog/ErrorDialog';
import {SADialogProps} from '../common/SADialog/SADialog';
import {
  COMMERCIAL_AUTO_VEHICLE_SELECTION_ROUTE,
  FNOL_AUTO_ROUTE,
  HOMEOWNERS_ROUTE,
  MAINTENANCE_ROUTE,
} from '../../routes/paths';
import {
  useInitializeContactListAtomState,
  useSetVehicleOptionsAtomState,
  useUserAtomState,
} from '../../atoms';
import {useFeatureFlags} from '../common/Providers/Providers';
import {NoticeOnly} from '../common/NoticeOnly/NoticeOnly';

export enum SearchType {
  Policy = 'policyNumber',
  Address = 'address',
  VIN = 'vin',
}

const TimePickerHint = withStyles({
  root: {
    marginLeft: 10,
  },
})(FormHelperText);

export interface AssociatePolicySearchForm {
  policyNumber?: string;
  lossDate: number | Date;
  city?: string | undefined;
  address?: string | undefined;
  state?: string | undefined;
  zip?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  companyName?: string | undefined;
  vin?: string | undefined;
  policySearchType: string;
  policyholderType: string;
  lossTime: number | Date;
  lossTimezone: string;
}

export interface StyleProps {
  resultBoxHeight: string | number | undefined;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  policySearchBox: {
    width: '100%',
    margin: '0 auto',
  },
  errorMessageClass: {
    color: '#C74F32',
    fontWeight: 'bold',
  },
  root: {
    marginRight: '30px',
    '&:last-child': {
      marginRight: '0px',
    },
  },
  divider: {
    marginRight: '0',
    marginLeft: '0',
  },
  list: {
    width: '100%',
    overflow: 'auto',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      height: ({resultBoxHeight}) => resultBoxHeight,
    },
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(240, 240, 240, 25)',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(140, 140, 140, 100)',
      borderRadius: '5px',
      '&:hover': {
        background: 'rgba(120, 120, 120, 100)',
      },
    },
  },
}));

const BaseSearchSchema = yup.object().shape({
  policyNumber: yup.string().when('policySearchType', {
    is: (value: SearchType) => value === SearchType.Policy,
    then: homeownersPolicyNumberValidation(true),
    otherwise: yup.string().nullable(),
  }),
  address: yup.string().when('policySearchType', {
    is: (value: SearchType) => value === SearchType.Address,
    then: yup.string().nullable(),
  }),
  vin: yup.string().when('policySearchType', {
    is: (value: SearchType) => value === SearchType.VIN,
    then: vinValidation('Enter a valid VIN number', true).nullable(),
  }),
  firstName: yup.string().when(['policySearchType', 'policyholderType'], {
    is: (policySearchType: string, policyholderType: string) =>
      (policySearchType === SearchType.Address || policySearchType === SearchType.VIN) &&
      policyholderType === 'person',
    then: yup.string().required('Enter a valid first name'),
    otherwise: yup.string().nullable(),
  }),
  lastName: yup.string().when(['policySearchType', 'policyholderType'], {
    is: (policySearchType: string, policyholderType: string) =>
      (policySearchType === SearchType.Address || policySearchType === SearchType.VIN) &&
      policyholderType === 'person',
    then: yup.string().required('Enter a valid last name'),
    otherwise: yup.string().nullable(),
  }),
  companyName: yup.string().when(['policySearchType', 'policyholderType'], {
    is: (policySearchType: string, policyholderType: string) =>
      (policySearchType === SearchType.Address || policySearchType === SearchType.VIN) &&
      policyholderType === 'company',
    then: yup.string().required('Enter a valid company name'),
    otherwise: yup.string().nullable(),
  }),
  ...LossDateAndTimeSchema,
  lossTimezone: yup.string().required('Time zone is required'),
});

const CityZipSearchSchema = yup.object().shape({
  city: yup.lazy((item?: any, options?: {parent?: {zip: string}}) => {
    if (options?.parent?.zip === '') {
      return yup
        .string()
        .required('You must include at least one of the following City or ZIP Code.');
    } else {
      return yup.string().nullable();
    }
  }),
  zip: yup.lazy((item?: any, options?: {parent?: {city: string}}) => {
    if (options?.parent?.city === '') {
      return zipCodeValidation(true).required(
        'You must include at least one of the following City or ZIP Code.'
      );
    } else if (item !== '') {
      return zipCodeValidation(true);
    } else {
      return yup.string().nullable();
    }
  }),
});

export const PolicySearchSchema = featureFlagSchemaSwitch({
  is: (ff: any) => ff?.FF_DCX_2910,
  then: BaseSearchSchema.concat(CityZipSearchSchema),
  otherwise: BaseSearchSchema.concat(CityZipSearchSchema),
});

const uuid = uuidv4();

export const lossTimezones: string[] = ['AKST', 'CST', 'EST', 'MST', 'PST'];

export const AssociatePolicySearch = () => {
  const [userAtomState, setUserAtomState] = useUserAtomState();
  const policyNumberPrefill = userAtomState?.policyNumber;
  const [showDuplicateClaims, setShowDuplicateClaims] = useState(false);
  const [duplicateClaimData, setDuplicateClaimData] = useState<any>([]);
  const [policyNumber, setPolicyNumber] = useState(policyNumberPrefill);
  const initializeContactList = useInitializeContactListAtomState();
  const {featureFlags} = useFeatureFlags();
  const claimsOutage = featureFlags?.FF_DCX_2688;
  const policyCenterDown = featureFlags?.FF_DCARE_6280;

  const ASSOCIATE_FORM_NAVIGATION_MAPPING: {[key: string]: string} = {
    [Lobs.Auto]: FNOL_AUTO_ROUTE,
    [Lobs.Homeowners]: HOMEOWNERS_ROUTE,
  };

  const formMethods = useForm<AssociatePolicySearchForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    resolver: yupResolver(PolicySearchSchema),
    defaultValues: {
      lossDate: 0,
    },
  });

  const {register, errors, handleSubmit, formState, setValue, trigger, control, clearErrors} =
    formMethods;

  const [dateSelected, setDateSelected] = useState<Date | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isContinuing, setIsContinuing] = useState(false);
  const [policySearchError, setPolicySearchError] = useState<
    string | React.ReactElement | undefined | null
  >();
  const [searchType, setSearchType] = useState(SearchType.Policy);
  const {firstName, lastName} = getUserInfoFromToken();

  const setVehicleOptionsAtomState = useSetVehicleOptionsAtomState();
  const [selectedPolicy, setSelectedPolicy] = useState<UserData | null>(null);
  const [validSelectedPolicy, setValidSelectedPolicy] = useState<boolean>(false);
  const [selectedPolicyModal, setSelectedPolicyModal] = useState<boolean>(false);
  const [responseDataState, setResponseDataState] = useState<UserData[] | null>(null);
  const [moreThanTenResults, setMoreThanTenResults] = useState<boolean>(false);
  const [verifyPolicyHeaders, setVerifyPolicyHeaders] = useState();
  const [formData, setFormData] = useState<AssociatePolicySearchForm | undefined>();
  const errorMessage = (
    <span data-testid="errorMessage" id="genericPolicyError">
      Something went wrong. Please contact our claims team at <PhoneHyperlink />.
    </span>
  );

  const reset = () => {
    setIsContinuing(false);
    setResponseDataState(null);
    setSelectedPolicy(null);
    setValidSelectedPolicy(false);
  };

  const onSubmit = (data: AssociatePolicySearchForm) => {
    setIsSubmitting(true);
    setSelectedPolicy(null);
    setResponseDataState(null);
    setPolicySearchError(null);
    setFormData(data);
    setDuplicateClaimData([]);
    setShowDuplicateClaims(false);
    setValidSelectedPolicy(false);

    const payload: VerifyPolicyPayload = {};
    const lossDate = data.lossDate;
    const lossDateFormatted = formatISO(new Date(lossDate));
    const lossTimeFormatted = format(data.lossTime, 'hh:mm a');

    payload.policyNumber = data?.policyNumber?.replace(/\s+/g, '');
    payload.lossDate = lossDateFormatted;
    payload.lossTime = lossTimeFormatted;
    payload.lossTimezone = data.lossTimezone;

    if (data.firstName !== '') {
      payload.firstName = data.firstName;
    }

    if (data.lastName !== '') {
      payload.lastName = data.lastName;
    }

    if (data.companyName !== '') {
      payload.companyName = data.companyName;
    }

    if (data.city !== '') {
      payload.city = data.city;
    }

    if (data.state !== '') {
      payload.state = data.state;
    }

    if (data.zip !== '') {
      payload.zip = data.zip;
    }

    if (data.vin !== '') {
      payload.vin = data.vin;
    }

    payload.policyholderType = data.policyholderType;
    payload.associateSearch = true;

    if (payload.policyNumber) {
      setPolicyNumber(payload.policyNumber);
    }

    verifyPolicy(payload, {type: 'associate'} as OtherOptions)
      .then(response => {
        setIsSubmitting(false);
        const {data, headers} = response;
        setVerifyPolicyHeaders(headers);
        let responseData: UserData[];
        if (data.data instanceof Array) {
          setMoreThanTenResults(data.data.length > 10);
          responseData = (data.data as UserData[]).slice(0, 10);
        } else {
          responseData = [data.data as UserData];
        }

        if (data.data && responseData.length > 0) {
          responseData = sortResultsByAddress(responseData);
          scrollToResults();
          setResponseDataState(responseData);
          Snowplow.track.searchForPolicy({searchType: searchType, result: true});
          if (responseData.length === 1) {
            selectPolicy(responseData[0]);
          }
        } else {
          Snowplow.track.searchForPolicy({searchType: searchType, result: false});
          if (policyCenterDown) {
            navigate(MAINTENANCE_ROUTE);
          } else {
            scrollToResults();
            setPolicySearchError(
              <span data-testid="policyMatchError" id="policyMatchError">
                We couldn't match the policy information you entered. Please double-check your
                search criteria and try again, or contact our claims team at <PhoneHyperlink />.
              </span>
            );
          }
        }
      })
      .catch(_error => {
        scrollToResults();
        setPolicySearchError(errorMessage);
        setIsSubmitting(false);
        Snowplow.track.searchForPolicy({searchType: searchType, result: false});
      });
  };

  const sortResultsByAddress = (results: UserData[]) => {
    results.forEach(policy => {
      // Destructure the addresses for sorting
      let addressComponents: string[] = policy.address.split(/\s/g);
      policy.sorting = {
        number: Number.parseInt(addressComponents.shift() as string),
        street: addressComponents.join(' '),
        type: addressComponents.pop() as string,
      };
      return policy;
    });

    results.sort((result1: UserData, result2: UserData) => {
      if (result1.sorting && result2.sorting && result1?.sorting?.street > result2?.sorting?.street)
        return 1;

      return -1;
    });
    return results;
  };

  const draftClaimAndTracking = () => {
    if (selectedPolicy && formData) {
      const {lossDate, lossTime, companyName, lossTimezone} = formData;
      const lossDateFormatted = formatISO(new Date(lossDate));
      const lossTimeFormatted = format(lossTime, 'hh:mm a');
      const userToken = Cookies.get('userToken');
      const {insured, policyType, formPath} = selectedPolicy;
      setUserAtomState({
        ...userAtomState,
        gettingStarted: {customerType: CustomerTypes.Associate, lob: formPath},
        lossDate: lossDate,
        lossTime: lossTime,
        lossTimezone: lossTimezone,
        policyNumber: policyNumber?.replace(/\s+/g, ''),
        uuid: uuid,
      });

      if (
        featureFlags.FF_DCX_2945 &&
        COMMERCIAL_AUTO_TYPES.includes(selectedPolicy?.policyType) &&
        !selectedPolicy.policyNumber.toLowerCase().startsWith('bap') &&
        selectedPolicy?.vehicles?.length
      ) {
        navigate(COMMERCIAL_AUTO_VEHICLE_SELECTION_ROUTE, {
          state: {
            userData: {...selectedPolicy},
            dataForDraftClaim: {
              lossDate,
              lossTime,
              lossDateFormatted,
              lossTimeFormatted,
              policyNumber,
              uuid,
            },
            dataForDraftClaimAssociate: {
              userToken,
              companyName,
              verifyPolicyHeaders,
            },
          },
        });
      } else {
        draftClaim({
          lossDate: lossDateFormatted,
          lossTime: lossTimeFormatted,
          lossTimezone: lossTimezone,
          policyNumber,
          uuid,
        })
          .then(response => {
            const {data} = response;
            Snowplow.track.login({
              isCompanyPolicy: (policyType && COMMERCIAL_AUTO_TYPES.includes(policyType)) || false,
              payload: {
                firstName,
                lastName,
                lossDate: lossDateFormatted as string,
                lossTime: lossTimeFormatted as string,
                policyNumber,
                companyName: companyName as string,
              },
              data,
              isAuthedUser: true,
              logrocketurl: userAtomState?.logrocketurl,
              persona: CustomerTypes.Associate,
            });

            LogRocket.identify(
              policyNumber,
              typeof data.data !== 'boolean' ? {name: `${insured}`} : {}
            );

            if (!userToken) {
              Cookies.set(
                'userToken',
                verifyPolicyHeaders?.['x-amzn-remapped-authorization'] || data?.jwt
              );
            }

            setUserAtomState({
              ...userAtomState,
              gettingStarted: {customerType: CustomerTypes.Associate, lob: formPath},
              lossDate: lossDate,
              lossTime: lossTime,
              lossTimezone: lossTimezone,
              policyNumber: policyNumber?.replace(/\s+/g, ''),
              uuid: uuid,
              draftClaimResponse: data.data.draftClaimResponse,
            });
            setVehicleOptionsAtomState(
              data.data.draftClaimResponse.result.lobs.personalAuto?.vehicles ||
                data.data.draftClaimResponse.result.lobs.commercialAuto?.vehicles
            );
            initializeContactList(data.data.draftClaimResponse);
            navigate(ASSOCIATE_FORM_NAVIGATION_MAPPING[formPath]);
          })
          .catch(_ => {
            reset();
            setShowDuplicateClaims(false);
            scrollToResults();
            setPolicySearchError(
              <span>
                An issue occurred with the claim setup process. Please contact our CARE team at{' '}
                <PhoneHyperlink />.
              </span>
            );
          });
      }
    }
  };

  const onContinue = (isAfterDuplicateCheck: boolean) => {
    if (formData && policyNumber) {
      setIsContinuing(true);
      const lossDateFormatted = formatISO(new Date(formData.lossDate));

      if (policyNumber) {
        Cookies.set('policyNumber', policyNumber);
      }

      if (!isAfterDuplicateCheck) {
        if (duplicateClaimData.length > 0) {
          setShowDuplicateClaims(true);
        } else {
          duplicateCheck({
            policyNumber,
            lossDate: lossDateFormatted,
          })
            .then(({data}: any) => {
              if (data?.data instanceof Array && data.data.length > 0) {
                setDuplicateClaimData(returnRecentDuplicateClaims(data.data));
                setShowDuplicateClaims(true);
              } else {
                draftClaimAndTracking();
              }
            })
            .catch(error => {
              console.log(error);
              reset();
              scrollToResults();
              setPolicySearchError(errorMessage);
            });
        }
      } else {
        draftClaimAndTracking();
      }
    } else {
      reset();
      scrollToResults();
      setPolicySearchError(errorMessage);
    }
  };

  const dateTimeChangeHandler = (date: Date | null, _value?: string | null | undefined) => {
    if (date instanceof Date && date.toString() !== 'Invalid Date') {
      setValue('lossDate', date);
      setDateSelected(date);
      trigger('lossDate');
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onEscapeKeyDown = () => {
    setIsContinuing(false);
    setShowDuplicateClaims(false);
  };

  const POLICY_SEARCH_TYPES: RadioOption[] = [
    {
      label: 'Policy Number',
      value: SearchType.Policy,
      testId: 'policyNumber',
    },
    {
      label: 'Address',
      value: SearchType.Address,
      testId: 'address',
    },
    {
      label: 'VIN',
      value: SearchType.VIN,
      testId: 'vin',
    },
  ];

  const onChangeHandler = (value: string) => {
    setSearchType(value as SearchType);
  };

  const isCompanyPolicy = useWatch({control, name: 'policyholderType'}) === 'company';

  const incidentTimeRef = useRef(null);
  const onError = () => {
    scrollToRef(incidentTimeRef);
  };

  const formRef = useRef<HTMLFormElement>(null);
  const resultBoxHeight =
    formRef.current?.clientHeight &&
    `${(formRef?.current?.clientHeight - (moreThanTenResults ? 107 : 76)).toString()}px`;
  const resultRef = useRef<HTMLDivElement>(null);
  const classes = useStyles({resultBoxHeight: resultBoxHeight} as StyleProps);

  const scrollToResults = () => {
    if (resultRef.current) {
      scrollToRef(resultRef);
    }
  };

  const selectPolicy = (policy: UserData) => {
    if (policy !== selectedPolicy) {
      setSelectedPolicy(policy);
      setPolicyNumber(
        getPolicyNumber({
          policySymbol: policy?.policySymbol || '',
          policyNumberParam: policy?.policyNumber,
        }).replace(/\s+/g, '')
      );
      if (policy.isVerified) {
        setValidSelectedPolicy(true);
        setSelectedPolicyModal(false);
      } else {
        setValidSelectedPolicy(false);
        setSelectedPolicyModal(true);
      }
      setDuplicateClaimData([]);
    }
  };

  const closeInvalidPolicyModal = () => {
    setSelectedPolicyModal(false);
  };

  const invalidPolicyErrorModalProps: DialogProps & SADialogProps & ErrorDialogProps = {
    open: !!selectedPolicyModal,
    header: 'Policy is canceled or ineligible',
    cta: 'RETURN TO POLICY SEARCH',
    ctaOnClick: closeInvalidPolicyModal,
    errorMsg: INVALID_POLICY_ERROR_MESSAGE as unknown as JSX.Element,
    disableEscapeKeyDown: false,
    onEscapeKeyDown: closeInvalidPolicyModal,
  };

  useEffect(() => {
    LogRocket.getSessionURL((sessionURL: string) => {
      setUserAtomState({
        ...userAtomState,
        gettingStarted: {customerType: CustomerTypes.Associate, lob: '' as Lobs},
        logrocketurl: sessionURL,
      });
    });
  }, []);

  return (
    <Container>
      <Box className={classes.policySearchBox} mb={3}>
        <Paper elevation={3}>
          <Box
            bgcolor="primary.main"
            color="white"
            fontWeight="fontWeightMedium"
            fontSize={16}
            px={3}
            height="50px"
            display="flex"
            alignItems="center"
          >
            Policy Information
          </Box>
          <Box>
            <Grid container xs={12}>
              <Grid item xs={12} md={6}>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="on" ref={formRef}>
                  <FormProvider {...formMethods}>
                    <Box p={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="body1">
                              {"Enter the State Auto policyholder's Policy #, Address, or VIN"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <DynamicRadioList
                              name="policySearchType"
                              defaultValue={SearchType.Policy}
                              options={POLICY_SEARCH_TYPES}
                              onChange={onChangeHandler}
                              rootClassName={classes.root}
                              row={!isMobile}
                            />
                          </Box>
                        </Grid>
                        {searchType.includes(SearchType.Policy) && (
                          <Grid item xs={12}>
                            <Box>
                              <SATextField
                                autoComplete="on"
                                name="policyNumber"
                                label="Policy Number"
                                id="policyNumber"
                                InputLabelProps={{'aria-labelledby': 'policyNumber'}}
                                error={errors.hasOwnProperty('policyNumber')}
                                helperText={errors?.policyNumber?.message}
                                inputRef={register()}
                                defaultValue={policyNumberPrefill || ''}
                              />
                            </Box>
                          </Grid>
                        )}
                        {searchType.includes(SearchType.Address) &&
                          (!featureFlags?.FF_DCX_2190 ? (
                            <>
                              <Grid item xs={12}>
                                <Box>
                                  <SATextField
                                    label="City"
                                    name="city"
                                    inputRef={register()}
                                    id="city"
                                    InputLabelProps={{'aria-labelledby': 'city'}}
                                    onChange={() => clearErrors(['state', 'zip'])}
                                    error={
                                      errors.hasOwnProperty('city') ||
                                      errors.hasOwnProperty('state') ||
                                      errors.hasOwnProperty('zip')
                                    }
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box>
                                  <SATextField
                                    label="State"
                                    name="state"
                                    inputRef={register()}
                                    id="state"
                                    InputLabelProps={{'aria-labelledby': 'state'}}
                                    onChange={() => clearErrors(['city', 'zip'])}
                                    error={
                                      errors.hasOwnProperty('state') ||
                                      errors.hasOwnProperty('city') ||
                                      errors.hasOwnProperty('zip')
                                    }
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box pb={1}>
                                  <SATextField
                                    label="ZIP Code"
                                    name="zip"
                                    inputRef={register()}
                                    id="zip"
                                    InputLabelProps={{'aria-labelledby': 'zip'}}
                                    onChange={() => clearErrors(['city', 'state'])}
                                    error={
                                      errors.hasOwnProperty('zip') ||
                                      errors.hasOwnProperty('city') ||
                                      errors.hasOwnProperty('state')
                                    }
                                    helperText={
                                      errors?.zip?.message ||
                                      errors?.city?.message ||
                                      errors?.state?.message
                                    }
                                  />
                                </Box>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12}>
                                <Box>
                                  <SATextField
                                    label="City"
                                    name="city"
                                    inputRef={register()}
                                    id="city"
                                    InputLabelProps={{'aria-labelledby': 'city'}}
                                    onChange={() => clearErrors(['zip'])}
                                    error={
                                      errors.hasOwnProperty('city') || errors.hasOwnProperty('zip')
                                    }
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box pb={1}>
                                  <SATextField
                                    label="ZIP Code"
                                    name="zip"
                                    inputRef={register()}
                                    id="zip"
                                    InputLabelProps={{'aria-labelledby': 'zip'}}
                                    onChange={() => clearErrors(['city'])}
                                    error={
                                      errors.hasOwnProperty('zip') || errors.hasOwnProperty('city')
                                    }
                                    helperText={errors?.zip?.message || errors?.city?.message}
                                  />
                                </Box>
                              </Grid>
                            </>
                          ))}
                        {searchType.includes(SearchType.VIN) && (
                          <Grid item xs={12}>
                            <Box pb={1}>
                              <SATextField
                                label="VIN"
                                name="vin"
                                inputRef={register()}
                                error={errors.hasOwnProperty('vin')}
                                id="vin"
                                InputLabelProps={{'aria-labelledby': 'vin'}}
                                helperText={errors?.vin?.message || 'optional'}
                              />
                            </Box>
                          </Grid>
                        )}
                        {(searchType.includes(SearchType.Address) ||
                          searchType.includes(SearchType.VIN)) && (
                          <>
                            <Grid item xs={12}>
                              <Box>
                                <Typography variant="body1">
                                  Enter the State Auto policyholder's name or company name.
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box>
                                <FormProvider {...formMethods}>
                                  <PersonCompanyRadios
                                    name="policyholderType"
                                    defaultValue={ContactTypes.Person}
                                  />
                                </FormProvider>
                              </Box>
                            </Grid>
                            {!isCompanyPolicy ? (
                              <>
                                <Grid item xs={12}>
                                  <Box>
                                    <SATextField
                                      autoComplete="on"
                                      label="First Name"
                                      name="firstName"
                                      inputRef={register()}
                                      error={errors.hasOwnProperty('firstName')}
                                      id="firstName"
                                      InputLabelProps={{'aria-labelledby': 'firstName'}}
                                      helperText={errors?.firstName?.message}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={12}>
                                  <Box>
                                    <SATextField
                                      autoComplete="on"
                                      label="Last Name"
                                      name="lastName"
                                      inputRef={register()}
                                      error={errors.hasOwnProperty('lastName')}
                                      id="lastName"
                                      InputLabelProps={{'aria-labelledby': 'lastName'}}
                                      helperText={errors?.lastName?.message}
                                      onChange={() => trigger()}
                                    />
                                  </Box>
                                </Grid>
                              </>
                            ) : (
                              <Grid item xs={12}>
                                <Box>
                                  <SATextField
                                    autoComplete="on"
                                    label="Company Name"
                                    name="companyName"
                                    inputRef={register()}
                                    error={errors.hasOwnProperty('companyName')}
                                    helperText={errors?.companyName?.message}
                                    id="companyName"
                                    InputLabelProps={{'aria-labelledby': 'companyName'}}
                                    onChange={() => trigger()}
                                  />
                                </Box>
                              </Grid>
                            )}
                          </>
                        )}
                        <Grid item xs={12} sm={12}>
                          <Box>
                            <SADatePicker
                              id="lossDate"
                              label="Incident Date"
                              name="lossDate"
                              value={dateSelected}
                              InputLabelProps={{'aria-labelledby': 'lossDate'}}
                              error={errors.hasOwnProperty('lossDate')}
                              helperText={errors.lossDate?.message}
                              keyboardIcon={<Icon name="calendar" />}
                              onChange={dateTimeChangeHandler}
                              inputRef={register}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <Box pb={1}>
                            <div ref={incidentTimeRef} />
                            <Controller
                              name="lossTime"
                              defaultValue={null}
                              control={control}
                              onFocus={onError}
                              render={({value, onChange, onBlur}) => (
                                <SATimePicker
                                  label="Incident Time"
                                  disabled={false}
                                  onChange={(date: Date) => {
                                    onChange(date);
                                    trigger('lossTime');
                                  }}
                                  onBlur={() => {
                                    trigger('lossTime');
                                  }}
                                  value={value}
                                  keyboardIcon={<Icon name="clock" />}
                                  error={errors.hasOwnProperty('lossTime')}
                                  InputLabelProps={{
                                    'aria-labelledby': 'lossTime',
                                    for: 'lossTime',
                                  }}
                                  id="lossTime"
                                />
                              )}
                            />
                            <TimePickerHint error={errors.hasOwnProperty('lossTime')}>
                              {errors?.lossTime?.message || 'General Estimate'}
                            </TimePickerHint>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <Box>
                            <SATextField
                              label="Time Zone"
                              id={'lossTimezone'}
                              name="lossTimezone"
                              inputRef={register()}
                              select
                              SelectProps={{native: true}}
                              InputLabelProps={{
                                'aria-labelledby': 'lossTimezone',
                              }}
                              error={errors.hasOwnProperty('lossTimezone')}
                              helperText={errors.lossTimezone?.message}
                            >
                              <option aria-label="None" value="" />
                              {lossTimezones.map((key: string) => (
                                <option key={key} value={key}>
                                  {key}
                                </option>
                              ))}
                            </SATextField>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <NoticeOnly />
                        </Grid>
                        <Grid item xs={12}>
                          <Box display="flex" justifyContent="flex-end">
                            <SAButton
                              disabled={
                                !formState.isValid || isSubmitting || isContinuing || claimsOutage
                              }
                              data-testid="search-button"
                              variant="contained"
                              color="secondary"
                              type="submit"
                              loading={isSubmitting}
                            >
                              Search
                            </SAButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </FormProvider>
                </form>
              </Grid>
              <Grid item xs={12} md={6} ref={resultRef}>
                {policySearchError && (
                  <Box
                    display="flex"
                    flexDirection={isMobile ? 'column' : 'row'}
                    height="100%"
                    width="100%"
                  >
                    <Divider
                      variant={isMobile ? 'fullWidth' : 'middle'}
                      orientation={isMobile ? 'horizontal' : 'vertical'}
                      flexItem={!isMobile}
                      classes={{root: classes.divider}}
                    />
                    <Box p={{xs: 2, md: '24px 8px 0px 8px'}}>
                      <AlertPanel
                        alert={{
                          message: policySearchError,
                          showWarning: false,
                        }}
                        data-testid="error-panel"
                      />
                    </Box>
                  </Box>
                )}
                {responseDataState && (
                  <Box p={3}>
                    {moreThanTenResults ? (
                      <Box
                        textAlign="left"
                        display="flex"
                        flexDirection="row"
                        flexWrap="nowrap"
                        alignItems="top"
                        fontSize={12}
                      >
                        <Box mr={1}>
                          <img alt="Alert" src={AlertIcon} />
                        </Box>
                        <Box color="black" pb={1}>
                          We found 10+ matches. It may help to search by address in order to narrow
                          the results.
                        </Box>
                      </Box>
                    ) : (
                      <Box color={'#727477'} pb={1}>
                        {responseDataState.length}{' '}
                        {responseDataState.length === 1 ? 'match' : 'matches'}
                      </Box>
                    )}
                    <Box className={classes.list} data-testid="policy-search-results-container">
                      {responseDataState.map((policy, index) => (
                        <PolicySearchResult
                          policy={policy}
                          onClick={() => selectPolicy(policy)}
                          selected={policy === selectedPolicy}
                        />
                      ))}
                    </Box>
                  </Box>
                )}
              </Grid>
              <Box textAlign="right" width="100%" p={3} borderTop="2px solid #F0F3F8">
                <SAButton
                  onClick={() => onContinue(false)}
                  disabled={isContinuing || validSelectedPolicy === false}
                  data-testid="continue-button"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isContinuing}
                >
                  Continue
                </SAButton>
              </Box>
            </Grid>
          </Box>
        </Paper>
      </Box>
      <DuplicateClaimDialog
        onEscapeKeyDown={onEscapeKeyDown}
        open={showDuplicateClaims}
        continueToForm={() => onContinue(true)}
        authed
        isAssociate
        data={{
          insuredName: selectedPolicy?.insured || '',
          incidentDate: formData?.lossDate,
          policyNumber: policyNumber || '',
          policyType: selectedPolicy?.policyType || '',
          claims: [...duplicateClaimData],
        }}
      />
      <ErrorDialog {...invalidPolicyErrorModalProps} />
    </Container>
  );
};
