import {Box, Grid, withStyles, makeStyles, Link} from '@material-ui/core';
import {format} from 'date-fns';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {
  COMMERCIAL_AUTO_TYPES,
  CustomerTypes,
  Lobs,
  PERSONAL_AUTO_TYPES,
  PERSONAL_HOMEOWNERS_TYPES,
} from '../../../commonTypes';
import {SAButton} from '../Button/Button';
import {BlueContainer} from '../Containers/Containers';
import {ErrorDialog} from '../ErrorDialog/ErrorDialog';
import {GreenExpandMoreIcon, SAAccordion} from '../SAAccordion/SAAccordion';
import {Snowplow} from '../../../pages/utils/snowplow';
import {useUserAtomState} from '../../../atoms';
import {formatPropertyAddress} from '../../../pages/utils';
import {useFeatureFlags} from '../Providers/Providers';

export interface DuplicateClaimCCData {
  state: string;
  claimNumber: string;
  lossDate: string;
  description: string;
  reportedDate: string;
  assignedUser: string;
  vehicleDetails?: VehicleDetails;
  propertyDetails?: PropertyDetails;
}

interface VehicleDetails {
  vehicleIncidentNumber: string;
  vehicleIncidentDescription: string;
  vehicleYear: string;
  vehicleMake: string;
  vehicleModel: string;
  formattedVehicleInfo?: string;
}

interface PropertyDetails {
  propertyIncidentNumber: string;
  propertyIncidentDescription: string;
  propertyAddress: string;
  formattedAddress?: string;
}

interface VehicleAndPropertyInfoProps {
  claim: DuplicateClaimCCData;
}

export interface DuplicateClaimDialogProps {
  open: boolean;
  continueToForm?: () => void;
  data: {
    insuredName: string;
    incidentDate?: number | Date;
    policyNumber: string;
    policyType: string;
    claims: DuplicateClaimCCData[];
  };
  authed?: boolean | undefined;
  isAssociate?: boolean | undefined;
  onEscapeKeyDown: () => void;
}

const DuplicateClaimBlueContainer = withStyles({
  root: {
    margin: 0,
    padding: 0,
  },
})(BlueContainer);

const useStyles = makeStyles(theme => ({
  gridWidth: {
    maxWidth: '284px',
    padding: '0 16px !important',
    marginTop: '16px',
  },
  gridWidthAndBorder: {
    maxWidth: '226px',
    borderRight: '1px solid #D9DFEB',
    flexBasis: '100%',
    padding: '0 16px !important',
    marginTop: '16px',
  },
  expandIcon: {
    position: 'absolute',
    top: '22px',
    right: '12px',
  },
  iconExpanded: {
    transform: 'rotate(180deg)',
    cursor: 'pointer',
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  btnContinue: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '30px',
    },
  },
  responsiveButton: {
    width: '100%',
    textAlign: 'center',
    '&:first-of-type': {
      marginBottom: '20px',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
}));

export const DuplicateClaimDialog = ({
  open,
  data,
  continueToForm,
  authed,
  isAssociate,
  onEscapeKeyDown,
}: DuplicateClaimDialogProps) => {
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState.gettingStarted?.customerType;
  const logrocketurl = userAtomState.logrocketurl;
  const {featureFlags} = useFeatureFlags();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | boolean>('default');
  const claimNumbers = data.claims.map((ccData: DuplicateClaimCCData) => ccData.claimNumber);

  useEffect(() => {
    if (open && expanded === 'default' && data.claims.length > 0 && data.claims[0]?.claimNumber) {
      setExpanded(data.claims[0].claimNumber);

      // track that the duplicate modal was displayed
      Snowplow.track.duplicateClaims({
        policyNumber: data.policyNumber,
        selectedClaim: data.claims[0].claimNumber,
        expanedAccordion: true,
        claimNumbers,
        isAssociate,
        persona: userPersona,
        logrocketurl,
      });
    } else if (!open) {
      setIsSubmitting(false);
    }
  }, [open, data.claims]);

  const onClickHandler = () => {
    Snowplow.track.duplicateClaims({
      policyNumber: data.policyNumber,
      continuingToForm: true,
      claimNumbers,
      isAssociate,
      persona: userPersona,
      logrocketurl,
    });

    setIsSubmitting(true);
    continueToForm && continueToForm();
  };

  const onViewClaimHandler = (claimNumber: string) => (_: any) => {
    Snowplow.track.duplicateClaims({
      policyNumber: data.policyNumber,
      claimNumbers,
      selectedClaim: claimNumber,
      viewingClaim: true,
      isAssociate,
      expanedAccordion: true,
      persona: userPersona,
      logrocketurl,
    });
  };

  const onSearchAgainHandler = () => {
    Snowplow.track.duplicateClaims({
      policyNumber: data.policyNumber,
      searchingAgain: true,
      claimNumbers,
      isAssociate,
      persona: userPersona,
      logrocketurl,
    });

    onEscapeKeyDown();
  };

  const handleChange = (panel: string) => (_: ChangeEvent<{}>, isExpanded: boolean) => {
    Snowplow.track.duplicateClaims({
      policyNumber: data.policyNumber,
      claimNumbers,
      selectedClaim: panel,
      viewingClaim: false,
      isAssociate,
      expanedAccordion: isExpanded,
      persona: userPersona,
      logrocketurl,
    });

    setExpanded(isExpanded ? panel : false);
  };

  const getHeader = () => {
    if (authed) {
      return (
        <>
          It appears that {data.insuredName} might already have{' '}
          {data.claims.length === 1 ? `an open claim` : `open claims`} on or near the incident date
          of <b>{format(new Date(data.incidentDate || Date.now()), 'MM/dd/yyyy')}</b> for{' '}
          <b>Policy # {data.policyNumber}</b>. Please review the details below before continuing.
        </>
      );
    }

    if (data.claims.length > 1) {
      return (
        <>
          It appears that there might already be open claims on file with an incident date of{' '}
          <b>{format(new Date(data.incidentDate || Date.now()), 'MM/dd/yyyy')}</b> for{' '}
          <b>Policy # {data.policyNumber}</b>. Please contact your adjuster for more details.
        </>
      );
    } else {
      return (
        <>
          It appears that there might already have been a claim on file with an incident date of{' '}
          <b>{format(new Date(data.incidentDate || Date.now()), 'MM/dd/yyyy')}</b> for{' '}
          <b>Policy # {data.policyNumber}</b>. Please contact your adjuster for more details.
        </>
      );
    }
  };

  const VehicleInformation = ({claim}: VehicleAndPropertyInfoProps) => {
    if (featureFlags?.FF_DCX_1913) {
      return (PERSONAL_AUTO_TYPES.includes(data.policyType) ||
        COMMERCIAL_AUTO_TYPES.includes(data.policyType) ||
        data.policyType === Lobs.Auto) &&
        claim.vehicleDetails &&
        claim.vehicleDetails.vehicleMake &&
        claim.vehicleDetails.vehicleModel &&
        claim.vehicleDetails.vehicleYear ? (
        <Box pb={2} fontSize="14px" data-testid="vehicle">
          <b>Vehicle:</b>{' '}
          {`${claim.vehicleDetails?.vehicleYear} ${claim.vehicleDetails?.vehicleMake} ${claim.vehicleDetails?.vehicleModel}`}
        </Box>
      ) : null;
    } else {
      return null;
    }
  };

  const PropertyAddress = ({claim}: VehicleAndPropertyInfoProps) => {
    if (featureFlags?.FF_DCX_1913) {
      return (PERSONAL_HOMEOWNERS_TYPES.includes(data.policyType) ||
        data.policyType === Lobs.Homeowners) &&
        claim.propertyDetails &&
        claim.propertyDetails.propertyAddress ? (
        <Box pb={2} fontSize="14px" data-testid="propertyAddress">
          <b>Property:</b> {formatPropertyAddress(claim?.propertyDetails?.propertyAddress)}
        </Box>
      ) : null;
    } else {
      return null;
    }
  };

  return (
    <ErrorDialog
      header="Possible Duplicate Claim"
      open={open}
      disableEscapeKeyDown={false}
      onEscapeKeyDown={onEscapeKeyDown}
      errorMsg={
        <>
          <Box fontSize="16px" lineHeight="23px" data-testid="getHeaderText">
            {open && getHeader()}
          </Box>
          <Box mt={5} mb={6}>
            {data.claims.map((ccData: DuplicateClaimCCData, index: number) => (
              <Box mt={index > 0 ? 2 : 0}>
                <DuplicateClaimBlueContainer>
                  <SAAccordion
                    key={`${ccData.claimNumber}-${expanded}`}
                    ariaControls={`dupe-data-${index}`}
                    onChange={handleChange(ccData.claimNumber)}
                    expanded={expanded === ccData.claimNumber}
                    id={`dupe-${index}`}
                    summary={
                      <Grid container>
                        {authed && (
                          <Grid item xs={12} md={6} style={{padding: 0}}>
                            <Box fontSize="16px" lineHeight="23px">
                              <b>
                                Incident Date:{' '}
                                {format(new Date(ccData.lossDate || Date.now()), 'MM/dd/yyyy')}
                              </b>
                            </Box>
                          </Grid>
                        )}
                        <Grid item xs={12} md={authed ? 6 : 12} style={{padding: '0'}}>
                          <Box fontSize="16px" lineHeight="23px">
                            <b>Claim #: {ccData.claimNumber}</b>
                          </Box>
                        </Grid>
                      </Grid>
                    }
                    details={
                      <Box mt={3} mb={3} width={'100%'}>
                        <Grid container spacing={4}>
                          {authed && (
                            <>
                              <Grid item xs={12} md={6} className={classes.gridWidthAndBorder}>
                                <Box>
                                  <Box
                                    fontSize="16px"
                                    lineHeight="23px"
                                    pb={2}
                                    data-testid="incidentDate"
                                  >
                                    <b>
                                      Incident Date:{' '}
                                      {format(
                                        new Date(ccData.lossDate || Date.now()),
                                        'MM/dd/yyyy'
                                      )}
                                    </b>
                                  </Box>
                                  <Box pb={2} fontSize="14px" data-testid="insuredName">
                                    <b>Insured Name:</b> {data.insuredName}
                                  </Box>
                                  <VehicleInformation claim={ccData} />
                                  <PropertyAddress claim={ccData} />
                                  <Box pb={2} fontSize="14px" data-testid="claimDetails">
                                    <b>Claim Details:</b> {ccData.description}
                                  </Box>
                                  {isAssociate && (
                                    <Box pb={2} fontSize="14px" data-testid="claimStatus">
                                      <b>Claim Status:</b> {ccData.state}
                                    </Box>
                                  )}
                                </Box>
                              </Grid>
                            </>
                          )}
                          <Grid item xs={12} md={authed ? 6 : 12} className={classes.gridWidth}>
                            <Box fontSize="16px" lineHeight="23px" pb={2} data-testid="claimNumber">
                              <b>Claim #: {ccData.claimNumber}</b>
                            </Box>
                            <Box
                              className={classes.expandIcon}
                              onClick={() =>
                                handleChange(ccData.claimNumber)({} as ChangeEvent, false)
                              }
                            >
                              {expanded && <GreenExpandMoreIcon className={classes.iconExpanded} />}
                            </Box>
                            <Box fontSize="14px" pb={2} data-testid="adjusterContactInformation">
                              <b>Adjuster Contact Information</b>
                            </Box>
                            <Box fontSize="14px" pb={2} data-testid="adjusterName">
                              <b>Name:</b> {ccData.assignedUser}
                            </Box>
                            <Box fontSize="14px" pb={2} data-testid="stateAutoPhone">
                              <b>Phone:</b> <a href="tel:+18777225246">1-877-722-5246</a>
                            </Box>
                            {!authed && <VehicleInformation claim={ccData} />}
                            {!authed && <PropertyAddress claim={ccData} />}
                          </Grid>
                        </Grid>
                        {isAssociate && (
                          <Grid container>
                            <Grid item xs={12} style={{textAlign: 'right'}}>
                              <Box pt={4}>
                                <Link
                                  onClick={onViewClaimHandler(ccData.claimNumber)}
                                  href={`${process.env.REACT_APP_CCC_URL}${ccData.claimNumber}`}
                                  target="_blank"
                                >
                                  <SAButton
                                    data-testid="continue-to-form-button"
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                  >
                                    VIEW CLAIM
                                  </SAButton>
                                </Link>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    }
                  />
                </DuplicateClaimBlueContainer>
              </Box>
            ))}
          </Box>
          <Box className={classes.ctaContainer} justify-content="stretch">
            <Grid container direction="row" justify="flex-end">
              <Grid xs={12} sm={8} item style={{order: 1, textAlign: 'right'}}>
                <SAButton
                  data-testid="continue-to-form-button"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                  onClick={onClickHandler}
                  disabled={isSubmitting}
                  className={`${classes.responsiveButton} ${classes.btnContinue}`}
                >
                  <Box color="#000000">CONTINUE TO FILE CLAIM</Box>
                </SAButton>
              </Grid>
              <Grid item xs={12} sm={4} style={{order: 2, textAlign: 'right'}}>
                <SAButton
                  data-testid="return-to-search-button"
                  color="secondary"
                  variant="contained"
                  type="submit"
                  onClick={onSearchAgainHandler}
                  className={`${classes.responsiveButton}`}
                  style={{width: '100%'}}
                >
                  {[CustomerTypes.SaCustomer, CustomerTypes.Associate].includes(
                    userPersona as CustomerTypes
                  )
                    ? 'CANCEL'
                    : 'RETURN TO SEARCH'}
                </SAButton>
              </Grid>
            </Grid>
          </Box>
        </>
      }
    />
  );
};
