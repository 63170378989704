import React, {useState} from 'react';
import * as yup from 'yup';
import {Grid, Box, Container} from '@material-ui/core';
import {
  GeneralDetails,
  GeneralDetailsSchema,
} from '../components/common/GeneralDetails/GeneralDetails';
import {
  GettingStarted,
  GettingStartedSchema,
} from '../components/auto/GettingStarted/GettingStarted';
import {
  AnyoneElseInjured,
  AnyoneElseInjuredSchema,
} from '../components/auto/AnyoneElseInjured/AnyoneElseInjured';
import {
  VehicleDetails,
  YourVehicleDetailsSchema,
  VehicleTypes,
} from '../components/auto/YourVehicleDetails/YourVehicleDetails';
import {
  PropertyDamageDetails,
  PropertyDamageDetailsSchema,
} from '../components/auto/PropertyDamageDetails/PropertyDamageDetails';
import {OtherDetails, OtherDetailsSchema} from '../components/auto/OtherDetails/OtherDetails';
import {
  IncidentDetails,
  IncidentDetailsSchema,
} from '../components/auto/IncidentDetails/IncidentDetails';
import {AppFormContainer} from '../components/common/Containers/Containers';
import {
  submitClaim,
  appraiserAssignment,
  convertFormData,
  fraudModel,
  STPModelResponse,
  stpModel,
  stpUpdate,
  SubmitClaimResponse,
  initiateMOI,
  Response,
  updateDraftClaim,
} from '../services';
import {format, formatISO} from 'date-fns';
import {trackEvent} from '../services/analytics';
import {DraftClaimResponse, useFeatureFlags} from '../components/common/Providers/Providers';
import {BaseSwitch} from '../components/common/BaseSwitch/BaseSwitch';
import {UserPersonaSwitch} from '../components/common/UserPersonaSwitch/UserPersonaSwitch';
import {
  YourPropertyDetails,
  YourPropertyDetailsSchema,
} from '../components/auto/YourPropertyDetails/YourPropertyDetails';
import {
  isIntegrationEligible,
  trimDataValues,
  getVehicleIncidents,
  filterVehiclesByEligibility,
  makePersonaCheck,
} from '../utils/utils';
import {WaypointNames} from '../components/common/RegisterBreadcrumb/RegisterBreadcrumb';
import {WithUserPersonaContext} from '../components/common/WithUserPersonaContext/WithUserPersonaContext';
import {
  AdditionalNotes,
  AdditionalNotesSchema,
} from '../components/common/AdditionalNotes/AdditionalNotes';
import {ClaimPreferences} from '../components/common/ClaimPreferences/ClaimPreferences';
import {WithDraftClaimContext} from '../components/common/WithDraftClaimContext/WithDraftClaimContext';
import {
  setDefaultGeneralDetailsData,
  setDefaultGettingStartedData,
  setDefaultVehicleData,
  setIncidentDetailsData,
  CLAIM_ERROR_MESSAGE,
  navigateToPolicySearchOnError,
  mapVehiclePassengers,
  ConditionalWaypoint,
  insertConditionalBreadcrumbs,
  navigateDefaultReplace,
  setParsedIncidentLocationLatLng,
  setInsuredMainContactIds,
  setAdditionalInjuredIds,
  propertyDamageFormDefaultValues,
  getThirdPartyContacts,
} from './utils';
import {LogRocket} from '../services/logRocket';
import {Adjuster, SubmissionTypes, VehicleIncident} from '../components/common/Summary/types';
import {AutoIntegrationFlags, CustomerTypes, THIRD_PARTY_PERSONAS} from '../commonTypes';
import {HowReported} from '../components/auto/GettingStarted/GettingStarted';
import {Snowplow} from './utils/snowplow';
import {
  InsuredContactInformation,
  InsuredContactInformationSchema,
} from '../components/homeowners/InsuredContactInformation/InsuredContactInformation';
import {
  useContactListSnapshot,
  useResetAtomState,
  useResetUserAtomState,
  useUserAtomState,
} from '../atoms';
import Cookies from 'js-cookie';
import {
  COPART_ROUTE,
  QUICK_ESTIMATE_ROUTE,
  STAFF_APPRAISER_ROUTE,
  SUMMARY_PAGE_ROUTE,
} from '../routes/paths';
import {Memoize} from '../components/common/Memoize/Memoize';
import {mapData} from '../utils/dataMapper';
import {QuickEstimateInstance} from '../components/auto/QuickEstimate/QuickEstimate';
import {ContactInfoModalController} from '../components/common/ContactInfo/ContactInfoModal/ContactInfoModalController';
import {WithGooglePlacesContext} from '../components/common/WithGooglePlacesContext/WithGooglePlacesContext';

export const AutoFormValidationSchema = yup.object().shape({
  ...GeneralDetailsSchema,
  ...InsuredContactInformationSchema,
  ...GettingStartedSchema,
  ...IncidentDetailsSchema,
  ...YourPropertyDetailsSchema,
  ...YourVehicleDetailsSchema,
  ...AnyoneElseInjuredSchema,
  ...PropertyDamageDetailsSchema,
  ...OtherDetailsSchema,
  ...AdditionalNotesSchema,
});

export const AutoFormPage = () => {
  const {featureFlags} = useFeatureFlags();
  const [userAtomState] = useUserAtomState();
  const [acknowledgement, setAcknowledgementForFormContext] = useState<boolean | undefined>();
  const [displaySubmitDialog, setDisplaySubmitDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canContinue, setCanContinue] = useState(false);
  const [submitClaimErrorMessage, setSubmitClaimErrorMessage] = useState<null | JSX.Element>(null);
  const [displaySubmissionErrorDialog, setSubmissionErrorDialog] = useState(false);
  const [showClaimPreferencesWaypoint, setShowClaimPreferencesWaypoint] = useState(false);
  const [hasPropertyDamage, setHasPropertyDamage] = useState(false);
  const [rideshareOrDeliveryValue, setRideshareOrDeliveryValue] = useState<string | undefined>('');
  const [rideshareValue, setRideshareValue] = useState<string | undefined>('');
  const [deliveryValue, setDeliveryValue] = useState<string | undefined>('');
  const [downloadLink, setDownloadLink] = useState<string>('');
  const [isLawsuitClaim, setIsLawsuitClaim] = useState(false);
  const [hasCompOrColCovs, setHasCompOrColCovs] = useState(false);
  const isCommercialAuto = !!userAtomState?.draftClaimResponse?.result?.lobs?.commercialAuto;
  const isAuthInsured = userAtomState?.gettingStarted?.isAuthInsured;
  const showEpay = hasCompOrColCovs && !isLawsuitClaim;
  const lossDate = userAtomState.lossDate;
  const lossTime = userAtomState.lossTime;
  const lossTimezone = userAtomState.lossTimezone;
  const policyNumber = userAtomState.policyNumber;
  const uuid = userAtomState?.uuid;
  const draftClaimResponse = userAtomState?.draftClaimResponse?.result;
  const userPersona = userAtomState?.gettingStarted?.customerType;
  const policyType = draftClaimResponse?.policy?.policyType;
  const isCommAuto = draftClaimResponse?.lobs?.hasOwnProperty('commercialAuto');
  const isAgent = userPersona === CustomerTypes.SaAgent;
  const isAgentAndCommAuto = isAgent && isCommAuto;
  const [showInsuredContact, setShowInsuredContact] = useState<boolean>(isAgentAndCommAuto);
  const isAssociate = userPersona === CustomerTypes.Associate;
  const getContactList = useContactListSnapshot();
  const isAssociateOrAgent = isAssociate || isAgent;

  const resetUserAtomState = useResetUserAtomState();
  const resetAtomState = useResetAtomState();

  const callInitiateMOI = async (
    data: Response<SubmitClaimResponse>,
    formData: any,
    uuid: string | undefined
  ) => {
    let returnData;
    try {
      returnData = (await initiateMOI({data: {...data.data, formData, uuid}})).data;
    } catch {
      LogRocket.log('initiateMOI call to MT failed');
      returnData = data;
    }

    return returnData;
  };

  const onSubmit = async (data: any) => {
    const contactList = await getContactList();
    const thirdPartyList = getThirdPartyContacts(contactList);

    setIsSubmitting(true);
    setDisplaySubmitDialog(true);
    trackEvent({category: 'Auto Form', label: 'Submit Claim'});
    trimDataValues(data);

    const lossDateFormatted = lossDate && formatISO(new Date(lossDate));
    const lossTimeFormatted = lossTime && format(new Date(lossTime), 'hh:mm a');

    setIncidentDetailsData(data);
    setDefaultGeneralDetailsData(data);
    setParsedIncidentLocationLatLng(data);
    setDefaultGettingStartedData(data, contactList);
    setInsuredMainContactIds(data.insuredContactInformation, contactList);

    data.gettingStarted.customerType = userPersona;
    data.generalDetails.lossTime = lossTimeFormatted;
    data.generalDetails.lossTimezone = lossTimezone;
    data.otherDetails.wereThereAnyWitnesses = data.otherDetails.wereThereAnyWitnesses || 'no';

    mapVehiclePassengers(
      data.yourVehicles || [],
      userAtomState.draftClaimResponse.result.contacts,
      contactList
    );
    setDefaultVehicleData(data.yourVehicles || [], true, contactList);
    setDefaultVehicleData(data.otherVehicles || [], false, contactList);
    setRideshareOrDeliveryValue(data.incidentDetails?.rideshareOrDelivery || '');
    setRideshareValue(data.incidentDetails?.rideshare || '');
    setDeliveryValue(data.incidentDetails?.delivery || '');
    data.additionalVehicles = [...(data.yourVehicles || []), ...(data.otherVehicles || [])];
    delete data.yourVehicles;
    delete data.otherVehicles;

    propertyDamageFormDefaultValues(data, contactList);
    setAdditionalInjuredIds(data, contactList);

    const customerType = userPersona;
    const formData = data;
    const isNoticeOnly = !!formData.noticeOnly;

    try {
      if (thirdPartyList.length > 0 && featureFlags?.FF_DCX_2655) {
        await updateDraftClaim({
          publicID: draftClaimResponse.publicID,
          claimNumber: draftClaimResponse.claimNumber,
          isRetry: false,
          contacts: thirdPartyList,
        });
      }
    } catch (error) {
      LogRocket.log('Initial Update Draft Claim Error:', error);
      setTimeout(async () => {
        try {
          await updateDraftClaim({
            publicID: draftClaimResponse.publicID,
            claimNumber: draftClaimResponse.claimNumber,
            isRetry: true,
            contacts: thirdPartyList,
          });
        } catch (error) {
          LogRocket.log('Retry Update Draft Claim Error:', error);
        }
      }, 10000);
    } finally {
      submitClaim({
        ...data,
        lossDate: lossDateFormatted,
        policyNumber,
        uuid,
        isAuthInsured,
      })
        .then(async response => {
          const notEligibleForMOI =
            makePersonaCheck(userPersona as CustomerTypes, [
              CustomerTypes.SaAgent,
              CustomerTypes.Guardian,
            ]) ||
            (isAssociate && isNoticeOnly);
          const data = notEligibleForMOI
            ? response.data
            : await callInitiateMOI(response.data, formData, uuid);
          const vehicleIncidents = getVehicleIncidents(data.data);
          const qeEligible = isIntegrationEligible(
            vehicleIncidents,
            AutoIntegrationFlags.QuickEstimateEligible
          );
          const copartEligible =
            isIntegrationEligible(vehicleIncidents, AutoIntegrationFlags.CopartEligible) &&
            isAssociate &&
            featureFlags?.FF_DCX_2155;
          let navToStaffScreen: VehicleIncident | undefined | boolean = isIntegrationEligible(
            vehicleIncidents,
            AutoIntegrationFlags.StaffAppraiserEligible
          );
          let vehiclesToShowStaff: VehicleIncident[] = filterVehiclesByEligibility(
            vehicleIncidents,
            AutoIntegrationFlags.StaffAppraiserEligible
          );
          const howReported = formData.gettingStarted.howReported;
          const isAssociateAndNotReportedByPhone = isAssociate && howReported !== HowReported.Phone;
          let stpResult: STPModelResponse | undefined;
          let isStpUpdateCallFailed: boolean = true;
          const associateCookieData = Cookies.get('associateCredentials');
          const baseSubmitClaimSnowplowData = {
            claimNumber: data.data.claimNumber,
            persona: userPersona,
            rideshareOrDelivery: !isCommercialAuto
              ? rideshareOrDeliveryValue
              : !rideshareValue && !deliveryValue
              ? ''
              : rideshareValue === 'yes' || deliveryValue === 'yes'
              ? 'yes'
              : 'no',
            logrocketurl: userAtomState.logrocketurl,
            epay: formData.claimsPreference?.isEpayOptOut ? 'Opt Out' : 'Opt In',
          };
          let adjusterDetails: Adjuster | undefined = data.data.adjusterDetails;

          if (userPersona === CustomerTypes.Associate && associateCookieData) {
            const associateCredentials = JSON.parse(associateCookieData);
            Snowplow.track.submitClaimWithAssociateInfo({
              ...baseSubmitClaimSnowplowData,
              name: associateCredentials.name,
              email: associateCredentials.email,
            });
            Snowplow.track.noticeOnlyClaim({
              claimNumber: data.data.claimNumber,
              name: associateCredentials.name,
              email: associateCredentials.email,
              persona: userPersona,
              logrocketurl: userAtomState.logrocketurl,
              isNoticeOnly: isNoticeOnly,
            });
          } else {
            Snowplow.track.submitClaim(baseSubmitClaimSnowplowData);
          }

          LogRocket.track('Form Submitted');

          if (!qeEligible && navToStaffScreen && !isAssociateAndNotReportedByPhone) {
            const quickEstimates: QuickEstimateInstance[] = filterVehiclesByEligibility(
              vehicleIncidents,
              AutoIntegrationFlags.StaffAppraiserEligible
            ) as QuickEstimateInstance[];

            try {
              const response = await appraiserAssignment({
                data: {
                  customerType,
                  ...data.data,
                },
                quickEstimates,
              });
              if (response.data.hasErrors) {
                throw new Error(`${response.data.errors?.[0]}:${response.data.errors?.[1]}`);
              }
              vehiclesToShowStaff = vehiclesToShowStaff.filter(
                vehicle =>
                  !!response.data.data.appraiserAssigned.filter(
                    (assignedVehicle: {publicID: string; appraiserSelection: string}) =>
                      assignedVehicle.publicID === vehicle.publicID
                  ).length
              );
              navToStaffScreen = !!vehiclesToShowStaff.length;
            } catch (error) {
              navToStaffScreen = false;
              vehiclesToShowStaff = [];
              LogRocket.log('Appraiser Assignment Error:', error);
            }

            const stpEligible =
              isIntegrationEligible(vehicleIncidents, AutoIntegrationFlags.STPModelEligible) &&
              featureFlags?.FF_DCX_2564;

            if (stpEligible) {
              try {
                const claimNumber = data.data.claimNumber;
                const fraudResponse = await fraudModel({claimNumber});
                const postFraudCheck =
                  fraudResponse?.data?.data &&
                  typeof fraudResponse.data.data !== 'boolean' &&
                  fraudResponse.data.data.postFraudCheck;

                if (postFraudCheck) {
                  try {
                    const stpResponse = await stpModel({
                      data: {...data.data, customerType, formData: {...formData, lossDate}},
                      postFraudCheck,
                      quickEstimates,
                    });
                    stpResult = stpResponse?.data?.data;
                    const publicID = data.data.publicID;
                    const stpFlag =
                      stpResult?.AutoSTPClaimProcessEligibilityIndicator === 'Eligible';
                    const finalBusinessRuleScore = postFraudCheck.final_business_rule_score;
                    const finalModelScore = postFraudCheck.final_model_score;
                    const finalResult = postFraudCheck.final_result;
                    if (stpFlag) {
                      try {
                        const stpUpdateResponse = await stpUpdate({
                          publicID,
                          claimNumber,
                          stpFlag,
                          finalBusinessRuleScore,
                          finalModelScore,
                          finalResult,
                        });
                        const responseAdjusterDetails =
                          stpUpdateResponse?.data?.data?.result?.adjusterDetails;

                        adjusterDetails = responseAdjusterDetails || adjusterDetails;
                        isStpUpdateCallFailed = false;
                      } catch (error) {
                        LogRocket.log('STP Update Error:', error);
                        isStpUpdateCallFailed = true;
                      }
                    }
                  } catch (error) {
                    LogRocket.log('STP Error:', error);
                  }
                }
              } catch (error) {
                LogRocket.log('Fraud Model Error:', error);
              }
            }
          }

          let url: string;

          if (!isAssociateAndNotReportedByPhone && !isNoticeOnly) {
            if (qeEligible) {
              url = QUICK_ESTIMATE_ROUTE;
            } else if (navToStaffScreen) {
              url = STAFF_APPRAISER_ROUTE;
            } else if (copartEligible) {
              url = COPART_ROUTE;
            } else {
              url = SUMMARY_PAGE_ROUTE;
            }
          } else {
            url = SUMMARY_PAGE_ROUTE;
          }

          const navToSummary = url === SUMMARY_PAGE_ROUTE;
          const baseNavState = {
            ...data.data,
            customerType,
            submissionType: SubmissionTypes.Collision,
            formData: {...formData, lossDate},
            quickEstimateVehicles: [],
            vehiclesToShowStaff,
            stpResult,
            isStpUpdateCallFailed,
            adjusterDetails,
          };

          navigateDefaultReplace(url, {
            state: navToSummary
              ? mapData(baseNavState, baseNavState.formData, userAtomState.draftClaimResponse)
              : baseNavState,
          });
        })
        .catch(error => {
          const associateCookieData = Cookies.get('associateCredentials');
          if (error?.response?.status === 503) {
            const updatedFormData = {...formData, lossDate};

            trackEvent({category: 'PA Claim Submission', label: 'timeout'});
            navigateDefaultReplace(SUMMARY_PAGE_ROUTE, {
              state: {
                ...mapData(
                  {
                    customerType,
                    submissionType: SubmissionTypes.Collision,
                    formData: updatedFormData,
                  },
                  updatedFormData,
                  userAtomState.draftClaimResponse
                ),
                timeoutOccurred: true,
              },
            });
          } else if (userPersona === CustomerTypes.Associate && associateCookieData) {
            convertFormData(
              {policyNumber, lossDate: lossDateFormatted, lossTime: lossTimeFormatted, ...formData},
              policyNumber || 'ENTERED_FORM_DATA'
            )
              .then(async response => {
                const {data} = response;
                setDisplaySubmitDialog(false);
                if (data.data) {
                  const downloadFileLink = typeof data.data !== 'boolean' ? data.data : '';
                  setDownloadLink(downloadFileLink);
                  setSubmissionErrorDialog(true);
                } else {
                  setSubmitClaimErrorMessage(CLAIM_ERROR_MESSAGE);
                }
              })
              .catch(error => {
                setDisplaySubmitDialog(false);
                setSubmitClaimErrorMessage(CLAIM_ERROR_MESSAGE);
              });
          } else {
            window.scrollTo(0, 0);
            setDisplaySubmitDialog(false);
            setSubmitClaimErrorMessage(CLAIM_ERROR_MESSAGE);
          }
        });
    }
  };

  const baseWaypoints = [
    WaypointNames.GettingStarted,
    WaypointNames.GeneralDetails,
    WaypointNames.IncidentDetails,
    WaypointNames.YourVehicleDetails,
    WaypointNames.AnyoneElseInjured,
    WaypointNames.PropertyDamageDetails,
    WaypointNames.OtherDetails,
  ];

  const conditionalWaypoints: ConditionalWaypoint[] = [
    {
      condition: showInsuredContact,
      spliceStart: 1,
      waypoint: WaypointNames.InsuredContactInformation,
    },
    {
      condition: showClaimPreferencesWaypoint && showEpay,
      spliceStart: showInsuredContact ? 8 : 7,
      waypoint: WaypointNames.ClaimPreferences,
    },
    {
      condition: !isAssociateOrAgent,
      spliceStart: 'last',
      waypoint: WaypointNames.AdditionalNotes,
    },
  ];

  const waypoints = insertConditionalBreadcrumbs(baseWaypoints, conditionalWaypoints);

  return (
    <>
      <ContactInfoModalController />
      <AppFormContainer
        submitDisabled={!canContinue}
        submitControl={onSubmit}
        validationSchema={AutoFormValidationSchema}
        isSubmitting={isSubmitting}
        submissionEventTracking="Auto"
        context={{
          userPersona,
          acknowledgement,
          lossDate,
          policyType,
          showInsuredContact,
          featureFlags,
          isCommercialAuto,
        }}
        defaultValues={{
          generalDetails: {
            lossTime: null,
          },
        }}
        displaySubmitDialog={displaySubmitDialog}
        displaySubmissionErrorDialog={displaySubmissionErrorDialog}
        downloadLink={downloadLink}
        waypoints={waypoints}
        errorDialogProps={{
          open: !!submitClaimErrorMessage,
          header: 'Something went wrong.',
          cta: 'RETURN TO POLICY SEARCH',
          ctaOnClick: () => {
            resetAtomState();
            resetUserAtomState();
            navigateToPolicySearchOnError();
          },
          errorMsg: submitClaimErrorMessage || '',
        }}
        hideCoveragesButton={!isAssociate}
      >
        <WithUserPersonaContext userPersona={userPersona}>
          <WithDraftClaimContext
            draftClaimResponse={userAtomState.draftClaimResponse?.result as DraftClaimResponse}
          >
            <Grid container justifyContent="center" data-testid={`persona-${userPersona}`}>
              <Container>
                <Box mt={10}>
                  <Box pb={3}>
                    <GettingStarted setShowInsuredContact={setShowInsuredContact} />
                  </Box>
                  <BaseSwitch
                    matches={showInsuredContact}
                    then={
                      <Box pb={3}>
                        <InsuredContactInformation />
                      </Box>
                    }
                  />
                  <Box pb={3}>
                    <WithGooglePlacesContext>
                      <GeneralDetails
                        lossDate={(lossDate && new Date(lossDate)) || null}
                        lossTime={(lossTime && new Date(lossTime)) || null}
                        lossTimezone={lossTimezone}
                        setAcknowledgementForFormContext={setAcknowledgementForFormContext}
                        setCanContinue={setCanContinue}
                        setIsLawsuitClaim={setIsLawsuitClaim}
                      />
                    </WithGooglePlacesContext>
                  </Box>
                  {canContinue && (
                    <>
                      <Box pb={3}>
                        <IncidentDetails setHasPropertyDamage={setHasPropertyDamage} />
                      </Box>
                      <>
                        <Box pb={3}>
                          <UserPersonaSwitch
                            ifPersonas={THIRD_PARTY_PERSONAS}
                            then={
                              <BaseSwitch
                                matches={hasPropertyDamage}
                                then={<YourPropertyDetails />}
                                otherwise={
                                  <Memoize renderOn={[]}>
                                    <VehicleDetails ownerType={VehicleTypes.OWNER} />
                                  </Memoize>
                                }
                              />
                            }
                            otherwise={
                              <Memoize renderOn={[]}>
                                <VehicleDetails
                                  ownerType={VehicleTypes.OWNER}
                                  setHasCompOrColCovs={setHasCompOrColCovs}
                                />
                              </Memoize>
                            }
                          />
                        </Box>
                        <Box pb={3}>
                          <Memoize renderOn={[]}>
                            <VehicleDetails ownerType={VehicleTypes.OTHER} />
                          </Memoize>
                        </Box>
                        <Box pb={3}>
                          <AnyoneElseInjured />
                        </Box>
                        <Box pb={3}>
                          <PropertyDamageDetails isOtherProperty={hasPropertyDamage} />
                        </Box>
                        <Box pb={3}>
                          <OtherDetails />
                        </Box>
                        {showEpay && (
                          <Box pb={3}>
                            <ClaimPreferences
                              setShowClaimPreferencesWaypoint={setShowClaimPreferencesWaypoint}
                            />
                          </Box>
                        )}
                        <Box pb={3}>
                          <AdditionalNotes />
                        </Box>
                      </>
                    </>
                  )}
                </Box>
              </Container>
            </Grid>
          </WithDraftClaimContext>
        </WithUserPersonaContext>
      </AppFormContainer>
    </>
  );
};
