import {RadioOption} from '../../common/DynamicRadioList/DynamicRadioList';
import {Option} from '../../common/DynamicCheckboxList/DynamicCheckboxList';
import React from 'react';

export enum WaterDamageSourceTypes {
  IndoorSource = 'Indoor Source (Plumbing)',
  OutdoorSource = 'Outdoor Source (Exterior, Structure)',
}

export enum IndoorWaterDamageSources {
  HomeAppliance = 'Home Appliance',
  BasementSource = 'Basement Source',
  Sink = 'Sink',
  Drain = 'Drain',
  Toilet = 'Toilet',
  SupplyLine = 'Supply Line',
  BathtubShower = 'Bathtub/Shower',
  HoseSpigotSpout = 'Hose Spigot/Spout',
  OtherUnknown = 'Other/Unknown',
}

export enum OutdoorWaterDamageSources {
  Roof = 'Roof',
  FoundationLeaks = 'Foundation Leaks',
  IceDammingGutterOverflow = 'Ice Damming/Gutter Overflow',
  ExteriorWall = 'Exterior Wall',
  WindowsDoorsDeckPatio = 'Windows/Doors/Deck Patio',
  HoseSpigotSpout = 'Hose Spigot/Spout',
  SwimmingPool = 'Swimming Pool',
  OtherUnknown = 'Other/Unknown',
}

export enum IndoorWaterDamageHomeAppliances {
  Refrigerator = 'Refrigerator',
  Dishwasher = 'Dishwasher',
  SinkGarbageDisposal = 'Sink/Garbage Disposal',
  WashingMachine = 'Washing Machine',
  HotWaterTank = 'Hot Water Tank',
  WaterSoftener = 'Water Softener',
  HVACCondensationHumidifier = 'HVAC (A/C Condensation, Humidifier)',
  OtherUnknown = 'Other/Unknown',
}

export enum IndoorWaterDamageBasementSources {
  SumpPump = 'Sump Pump',
  DrainBackupOverflow = 'Drain Backup or Overflow',
  OtherUnknown = 'Other/Unknown',
}

export enum DamagedAreasForWaterDamage {
  MainLevel = 'Main Level',
  UpperLevel = 'Upper Level',
  Attic = 'Attic',
  Basement = 'Basement',
  DetachedStructure = 'Detached Structure',
  Other = 'Other',
}

export enum WaterDamageToMainLevel {
  Bathroom = 'Bathroom',
  Bedroom = 'Bedroom',
  Den = 'Den',
  DiningRoom = 'Dining Room',
  FamilyRoom = 'Family Room',
  Foyer = 'Foyer',
  Garage = 'Garage',
  Kitchen = 'Kitchen',
  Laundry = 'Laundry',
  LivingRoom = 'Living Room',
  UtilityRoom = 'Utility Room',
  Other = 'Other',
}

export enum WaterDamageToUpperLevel {
  Bathroom = 'Bathroom',
  Bedroom = 'Bedroom',
  Den = 'Den',
  DiningRoom = 'Dining Room',
  FamilyRoom = 'Family Room',
  Kitchen = 'Kitchen',
  Laundry = 'Laundry',
  LivingRoom = 'Living Room',
  UtilityRoom = 'Utility Room',
  Other = 'Other',
}

export enum WaterDamageToBasement {
  Bathroom = 'Bathroom',
  Bedroom = 'Bedroom',
  CrawlSpace = 'Crawlspace',
  FamilyRoom = 'Family Room',
  Laundry = 'Laundry',
  UtilityRoom = 'Utility Room',
  Other = 'Other',
}

export enum WaterDamageToDetachedStructure {
  DetachedGarage = 'Detached Garage',
  Shed = 'Shed',
  OutBuilding = 'Out Building',
  Fence = 'Fence',
  Other = 'Other',
}

export const WATER_DAMAGE_SOURCE_OPTIONS: RadioOption[] = [
  {
    label: WaterDamageSourceTypes.IndoorSource,
    value: WaterDamageSourceTypes.IndoorSource,
    testId: WaterDamageSourceTypes.IndoorSource,
  },
  {
    label: WaterDamageSourceTypes.OutdoorSource,
    value: WaterDamageSourceTypes.OutdoorSource,
    testId: WaterDamageSourceTypes.OutdoorSource,
  },
];

export const WHICH_INDOOR_SOURCE_WAS_WATER_DAMAGED_OPTIONS: RadioOption[] = [
  {
    label: IndoorWaterDamageSources.HomeAppliance,
    value: IndoorWaterDamageSources.HomeAppliance,
    testId: IndoorWaterDamageSources.HomeAppliance,
  },
  {
    label: IndoorWaterDamageSources.BasementSource,
    value: IndoorWaterDamageSources.BasementSource,
    testId: IndoorWaterDamageSources.BasementSource,
  },
  {
    label: IndoorWaterDamageSources.Sink,
    value: IndoorWaterDamageSources.Sink,
    testId: IndoorWaterDamageSources.Sink,
  },
  {
    label: IndoorWaterDamageSources.Drain,
    value: IndoorWaterDamageSources.Drain,
    testId: IndoorWaterDamageSources.Drain,
  },
  {
    label: IndoorWaterDamageSources.Toilet,
    value: IndoorWaterDamageSources.Toilet,
    testId: IndoorWaterDamageSources.Toilet,
  },
  {
    label: IndoorWaterDamageSources.SupplyLine,
    value: IndoorWaterDamageSources.SupplyLine,
    testId: IndoorWaterDamageSources.SupplyLine,
  },
  {
    label: IndoorWaterDamageSources.BathtubShower,
    value: IndoorWaterDamageSources.BathtubShower,
    testId: IndoorWaterDamageSources.BathtubShower,
  },
  {
    label: IndoorWaterDamageSources.HoseSpigotSpout,
    value: IndoorWaterDamageSources.HoseSpigotSpout,
    testId: IndoorWaterDamageSources.HoseSpigotSpout,
  },
  {
    label: IndoorWaterDamageSources.OtherUnknown,
    value: IndoorWaterDamageSources.OtherUnknown,
    testId: IndoorWaterDamageSources.OtherUnknown,
  },
];

export const WHICH_OUTDOOR_SOURCE_WAS_WATER_DAMAGED_OPTIONS: RadioOption[] = [
  {
    label: OutdoorWaterDamageSources.Roof,
    value: OutdoorWaterDamageSources.Roof,
    testId: OutdoorWaterDamageSources.Roof,
  },
  {
    label: OutdoorWaterDamageSources.FoundationLeaks,
    value: OutdoorWaterDamageSources.FoundationLeaks,
    testId: OutdoorWaterDamageSources.FoundationLeaks,
  },
  {
    label: OutdoorWaterDamageSources.IceDammingGutterOverflow,
    value: OutdoorWaterDamageSources.IceDammingGutterOverflow,
    testId: OutdoorWaterDamageSources.IceDammingGutterOverflow,
  },
  {
    label: OutdoorWaterDamageSources.ExteriorWall,
    value: OutdoorWaterDamageSources.ExteriorWall,
    testId: OutdoorWaterDamageSources.ExteriorWall,
  },
  {
    label: OutdoorWaterDamageSources.WindowsDoorsDeckPatio,
    value: OutdoorWaterDamageSources.WindowsDoorsDeckPatio,
    testId: OutdoorWaterDamageSources.WindowsDoorsDeckPatio,
  },
  {
    label: OutdoorWaterDamageSources.HoseSpigotSpout,
    value: OutdoorWaterDamageSources.HoseSpigotSpout,
    testId: OutdoorWaterDamageSources.HoseSpigotSpout,
  },
  {
    label: OutdoorWaterDamageSources.SwimmingPool,
    value: OutdoorWaterDamageSources.SwimmingPool,
    testId: OutdoorWaterDamageSources.SwimmingPool,
  },
  {
    label: IndoorWaterDamageSources.OtherUnknown,
    value: IndoorWaterDamageSources.OtherUnknown,
    testId: IndoorWaterDamageSources.OtherUnknown,
  },
];

export const WHICH_INDOOR_HOME_APPLIANCE_WAS_WATER_DAMAGED_OPTIONS: RadioOption[] = [
  {
    label: IndoorWaterDamageHomeAppliances.Refrigerator,
    value: IndoorWaterDamageHomeAppliances.Refrigerator,
    testId: IndoorWaterDamageHomeAppliances.Refrigerator,
  },
  {
    label: IndoorWaterDamageHomeAppliances.Dishwasher,
    value: IndoorWaterDamageHomeAppliances.Dishwasher,
    testId: IndoorWaterDamageHomeAppliances.Dishwasher,
  },
  {
    label: IndoorWaterDamageHomeAppliances.SinkGarbageDisposal,
    value: IndoorWaterDamageHomeAppliances.SinkGarbageDisposal,
    testId: IndoorWaterDamageHomeAppliances.SinkGarbageDisposal,
  },
  {
    label: IndoorWaterDamageHomeAppliances.WashingMachine,
    value: IndoorWaterDamageHomeAppliances.WashingMachine,
    testId: IndoorWaterDamageHomeAppliances.WashingMachine,
  },
  {
    label: IndoorWaterDamageHomeAppliances.HotWaterTank,
    value: IndoorWaterDamageHomeAppliances.HotWaterTank,
    testId: IndoorWaterDamageHomeAppliances.HotWaterTank,
  },
  {
    label: IndoorWaterDamageHomeAppliances.WaterSoftener,
    value: IndoorWaterDamageHomeAppliances.WaterSoftener,
    testId: IndoorWaterDamageHomeAppliances.WaterSoftener,
  },
  {
    label: IndoorWaterDamageHomeAppliances.HVACCondensationHumidifier,
    value: IndoorWaterDamageHomeAppliances.HVACCondensationHumidifier,
    testId: IndoorWaterDamageHomeAppliances.HVACCondensationHumidifier,
  },
  {
    label: IndoorWaterDamageHomeAppliances.OtherUnknown,
    value: IndoorWaterDamageHomeAppliances.OtherUnknown,
    testId: IndoorWaterDamageHomeAppliances.OtherUnknown,
  },
];

export const WHICH_INDOOR_BASEMENT_SOURCE_WAS_WATER_DAMAGED_OPTIONS: RadioOption[] = [
  {
    label: IndoorWaterDamageBasementSources.SumpPump,
    value: IndoorWaterDamageBasementSources.SumpPump,
    testId: IndoorWaterDamageBasementSources.SumpPump,
  },
  {
    label: IndoorWaterDamageBasementSources.DrainBackupOverflow,
    value: IndoorWaterDamageBasementSources.DrainBackupOverflow,
    testId: IndoorWaterDamageBasementSources.DrainBackupOverflow,
  },
  {
    label: IndoorWaterDamageBasementSources.OtherUnknown,
    value: IndoorWaterDamageBasementSources.OtherUnknown,
    testId: IndoorWaterDamageBasementSources.OtherUnknown,
  },
];

export const WHICH_DAMAGED_AREAS_WERE_WATER_DAMAGED: Array<Option> = [
  {
    label: DamagedAreasForWaterDamage.MainLevel,
    value: DamagedAreasForWaterDamage.MainLevel.toLowerCase(),
    checked: false,
    disabled: false,
    testID: DamagedAreasForWaterDamage.MainLevel,
  },
  {
    label: DamagedAreasForWaterDamage.UpperLevel,
    value: DamagedAreasForWaterDamage.UpperLevel.toLowerCase(),
    checked: false,
    disabled: false,
    testID: DamagedAreasForWaterDamage.UpperLevel,
  },
  {
    label: DamagedAreasForWaterDamage.Attic,
    value: DamagedAreasForWaterDamage.Attic.toLowerCase(),
    checked: false,
    disabled: false,
    testID: DamagedAreasForWaterDamage.Attic,
  },
  {
    label: DamagedAreasForWaterDamage.Basement,
    value: DamagedAreasForWaterDamage.Basement.toLowerCase(),
    checked: false,
    disabled: false,
    testID: DamagedAreasForWaterDamage.Basement,
  },
  {
    label: DamagedAreasForWaterDamage.DetachedStructure,
    value: DamagedAreasForWaterDamage.DetachedStructure.toLowerCase(),
    checked: false,
    disabled: false,
    testID: DamagedAreasForWaterDamage.DetachedStructure,
  },
  {
    label: DamagedAreasForWaterDamage.Other,
    value: DamagedAreasForWaterDamage.Other.toLowerCase(),
    checked: false,
    disabled: false,
    testID: DamagedAreasForWaterDamage.Other,
  },
];

export const WHICH_ROOMS_WERE_DAMAGED_ON_THE_MAIN_LEVEL: Array<Option> = [
  {
    label: WaterDamageToMainLevel.Bathroom,
    value: WaterDamageToMainLevel.Bathroom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToMainLevel.Bathroom,
  },
  {
    label: WaterDamageToMainLevel.Bedroom,
    value: WaterDamageToMainLevel.Bedroom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToMainLevel.Bedroom,
  },
  {
    label: WaterDamageToMainLevel.Den,
    value: WaterDamageToMainLevel.Den.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToMainLevel.Den,
  },
  {
    label: WaterDamageToMainLevel.DiningRoom,
    value: WaterDamageToMainLevel.DiningRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToMainLevel.DiningRoom,
  },
  {
    label: WaterDamageToMainLevel.FamilyRoom,
    value: WaterDamageToMainLevel.FamilyRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToMainLevel.FamilyRoom,
  },
  {
    label: WaterDamageToMainLevel.Foyer,
    value: WaterDamageToMainLevel.Foyer.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToMainLevel.Foyer,
  },
  {
    label: WaterDamageToMainLevel.Garage,
    value: WaterDamageToMainLevel.Garage.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToMainLevel.Garage,
  },
  {
    label: WaterDamageToMainLevel.Kitchen,
    value: WaterDamageToMainLevel.Kitchen.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToMainLevel.Kitchen,
  },
  {
    label: WaterDamageToMainLevel.Laundry,
    value: WaterDamageToMainLevel.Laundry.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToMainLevel.Laundry,
  },
  {
    label: WaterDamageToMainLevel.LivingRoom,
    value: WaterDamageToMainLevel.LivingRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToMainLevel.LivingRoom,
  },
  {
    label: WaterDamageToMainLevel.UtilityRoom,
    value: WaterDamageToMainLevel.UtilityRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToMainLevel.UtilityRoom,
  },
  {
    label: WaterDamageToMainLevel.Other,
    value: WaterDamageToMainLevel.Other.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToMainLevel.Other,
  },
];

export const WHICH_ROOMS_WERE_DAMAGED_ON_THE_UPPER_LEVEL: Array<Option> = [
  {
    label: WaterDamageToUpperLevel.Bathroom,
    value: WaterDamageToUpperLevel.Bathroom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToUpperLevel.Bathroom,
  },
  {
    label: WaterDamageToUpperLevel.Bedroom,
    value: WaterDamageToUpperLevel.Bedroom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToUpperLevel.Bedroom,
  },
  {
    label: WaterDamageToUpperLevel.Den,
    value: WaterDamageToUpperLevel.Den.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToUpperLevel.Den,
  },
  {
    label: WaterDamageToUpperLevel.DiningRoom,
    value: WaterDamageToUpperLevel.DiningRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToUpperLevel.DiningRoom,
  },
  {
    label: WaterDamageToUpperLevel.FamilyRoom,
    value: WaterDamageToUpperLevel.FamilyRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToUpperLevel.FamilyRoom,
  },
  {
    label: WaterDamageToUpperLevel.Kitchen,
    value: WaterDamageToUpperLevel.Kitchen.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToUpperLevel.Kitchen,
  },
  {
    label: WaterDamageToUpperLevel.Laundry,
    value: WaterDamageToUpperLevel.Laundry.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToUpperLevel.Laundry,
  },
  {
    label: WaterDamageToUpperLevel.LivingRoom,
    value: WaterDamageToUpperLevel.LivingRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToUpperLevel.LivingRoom,
  },
  {
    label: WaterDamageToUpperLevel.UtilityRoom,
    value: WaterDamageToUpperLevel.UtilityRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToUpperLevel.UtilityRoom,
  },
  {
    label: WaterDamageToUpperLevel.Other,
    value: WaterDamageToUpperLevel.Other.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToUpperLevel.Other,
  },
];

export const WHICH_ROOMS_WERE_DAMAGED_IN_THE_BASEMENT: Array<Option> = [
  {
    label: WaterDamageToBasement.Bathroom,
    value: WaterDamageToBasement.Bathroom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToBasement.Bathroom,
  },
  {
    label: WaterDamageToBasement.Bedroom,
    value: WaterDamageToBasement.Bedroom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToBasement.Bedroom,
  },
  {
    label: WaterDamageToBasement.CrawlSpace,
    value: WaterDamageToBasement.CrawlSpace.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToBasement.CrawlSpace,
  },
  {
    label: WaterDamageToBasement.FamilyRoom,
    value: WaterDamageToBasement.FamilyRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToBasement.FamilyRoom,
  },
  {
    label: WaterDamageToBasement.Laundry,
    value: WaterDamageToBasement.Laundry.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToBasement.Laundry,
  },
  {
    label: WaterDamageToBasement.UtilityRoom,
    value: WaterDamageToBasement.UtilityRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToBasement.UtilityRoom,
  },
  {
    label: WaterDamageToBasement.Other,
    value: WaterDamageToBasement.Other.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToBasement.Other,
  },
];

export const WHICH_DETACHED_STRUCTURES_WERE_DAMAGED: Array<Option> = [
  {
    label: WaterDamageToDetachedStructure.DetachedGarage,
    value: WaterDamageToDetachedStructure.DetachedGarage.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToDetachedStructure.DetachedGarage,
  },
  {
    label: WaterDamageToDetachedStructure.Shed,
    value: WaterDamageToDetachedStructure.Shed.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToDetachedStructure.Shed,
  },
  {
    label: WaterDamageToDetachedStructure.OutBuilding,
    value: WaterDamageToDetachedStructure.OutBuilding.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToDetachedStructure.OutBuilding,
  },
  {
    label: WaterDamageToDetachedStructure.Fence,
    value: WaterDamageToDetachedStructure.Fence.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToDetachedStructure.Fence,
  },
  {
    label: WaterDamageToBasement.Other,
    value: WaterDamageToBasement.Other.toLowerCase(),
    checked: false,
    disabled: false,
    testID: WaterDamageToBasement.Other,
  },
];

interface ServiceInfo {
  mainText: string;
  firstBullet: React.ReactElement;
  secondBullet: React.ReactElement;
}

export const serviceInfoTextOptions: ServiceInfo[] = [
  {
    mainText:
      'Your adjuster will need additional information on the appliance and on the recent repairs/service that were done.',
    firstBullet: (
      <span>
        <b>Begin collecting any receipts/invoices</b> for the purchase of, or repair/service to, the
        selected appliance.
      </span>
    ),
    secondBullet: (
      <span>
        <b>Do not discard the appliance or damaged components</b> until checking with your CARE
        professional.
      </span>
    ),
  },
  {
    mainText: 'Your adjuster will need additional information on the appliance.',
    firstBullet: (
      <span>
        <b>Begin collecting any receipts/invoices</b> for the purchase of the selected appliance.
      </span>
    ),
    secondBullet: (
      <span>
        <b>Do not discard the appliance</b> until checking with your CARE professional.
      </span>
    ),
  },
  {
    mainText:
      'Your adjuster will need additional information on the recent repairs/service that were done.',
    firstBullet: (
      <span>
        <b>Begin collecting any receipts/invoices</b> for the related repair/service.
      </span>
    ),
    secondBullet: (
      <span>
        <b>Do not discard the damaged components</b> until checking with your CARE professional.
      </span>
    ),
  },
];
