import React from 'react';

export const Warning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="21px"
    viewBox="0 0 21 20"
    version="1.1"
  >
    <g id="Error-Modal-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Vehicle-Dropdown-Selected-Copy-36"
        transform="translate(-499.000000, -376.000000)"
        fill="#D0021B"
      >
        <g id="Group-3" transform="translate(499.000000, 363.000000)">
          <g id="Warning-Glyph-Copy" transform="translate(0.000000, 13.000000)">
            <path
              d="M7.7086874,1.59662877 C8.57060115,0.0518259741 10.4517681,-0.460482702 11.9103913,0.452355312 C12.3002112,0.696313004 12.6335022,1.02818405 12.8870943,1.42342939 L12.9908295,1.59662877 L20.2728801,14.648204 C21.1347939,16.1930068 20.6510652,18.1853183 19.192442,19.0981563 C18.7788627,19.3569832 18.3145276,19.5088059 17.8371085,19.5427174 L17.6318091,19.55 L3.06770783,19.55 C1.37345958,19.55 0,18.0953926 0,16.3010434 C0,15.7922722 0.112804519,15.2916583 0.328115005,14.8390994 L0.426636778,14.648204 L7.7086874,1.59662877 Z M11.1300749,1.85091176 C10.4354924,1.41622699 9.5492801,1.62786725 9.0941772,2.31623851 L9.02922292,2.42304848 L1.7471723,15.4746237 C1.60752217,15.7249178 1.53385392,16.010317 1.53385392,16.3010434 C1.53385392,17.1574374 2.15957259,17.8590522 2.95323451,17.921066 L3.06770783,17.9255217 L17.6318091,17.9255217 C17.9063168,17.9255217 18.1757945,17.8475009 18.4121255,17.6995999 C19.106708,17.2649151 19.3591614,16.3406732 19.0093529,15.5864065 L18.9523446,15.4746237 L11.670294,2.42304848 C11.5386665,2.18713335 11.3528291,1.99031615 11.1300749,1.85091176 Z M10.3497584,13.864326 C10.7733205,13.864326 11.1166854,14.2279779 11.1166854,14.6765652 C11.1166854,15.1251525 10.7733205,15.4888043 10.3497584,15.4888043 C9.92619639,15.4888043 9.58283149,15.1251525 9.58283149,14.6765652 C9.58283149,14.2279779 9.92619639,13.864326 10.3497584,13.864326 Z M10.3497584,5.74193463 C10.7733205,5.74193463 11.1166854,6.10558648 11.1166854,6.55417377 L11.1166854,12.2398477 C11.1166854,12.688435 10.7733205,13.0520869 10.3497584,13.0520869 C9.92619639,13.0520869 9.58283149,12.688435 9.58283149,12.2398477 L9.58283149,6.55417377 C9.58283149,6.10558648 9.92619639,5.74193463 10.3497584,5.74193463 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
