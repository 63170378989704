import React, {useContext} from 'react';
import {VehicleDetailsContext, VehicleTypes} from '../YourVehicleDetails/YourVehicleDetails';
import {SwitchProps, BaseSwitch} from '../../common/BaseSwitch/BaseSwitch';

export interface VehicleTypeSwitchProps {
  ifVehicleTypes: VehicleTypes[];
}

export const VehicleTypeSwitch = (props: SwitchProps & VehicleTypeSwitchProps) => {
  const {ownerType} = useContext(VehicleDetailsContext);

  const matchesVehicleType = () => {
    if (ownerType) {
      return props.ifVehicleTypes.includes(ownerType);
    }
  };

  return (
    <BaseSwitch matches={matchesVehicleType()} then={props.then} otherwise={props.otherwise} />
  );
};
