import React from 'react';
import {Box, makeStyles, Grid, Typography, Link} from '@material-ui/core';
import {Icon} from '../../../Icon/Icon';
import {formatPhone} from '../../../../../utils/utils';
import {Adjuster} from '../../types';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    justifyContent: 'center',
  },
  thinText: {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#666666',
  },
  userIcon: {
    position: 'relative',
    top: '-3px',
    fontSize: '18px',
  },
  userText: {
    position: 'relative',
    top: '2px',
    left: '19px',
    fontSize: '18px',
  },
  phoneIcon: {
    position: 'relative',
    fontSize: '20px',
  },
  phonetext: {
    position: 'relative',
    left: '12px',
    fontSize: '18px',
  },
  emailIcon: {
    position: 'relative',
    fontSize: '25px',
  },
  emailText: {
    position: 'relative',
    fontSize: '18px',
    top: '-1px',
    left: '12px',
  },
  containerClass: {
    border: '1px solid #E3E9F1',
    borderRadius: '10px',
    height: '100%',
    paddingTop: '10px',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
    },
  },
  adjusterInfoBox: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    margin: '5px 25px',
  },
}));

export interface NewAdjusterProps {
  claimNumber: string;
  adjusterInfo?: Adjuster;
}

export const NewAdjuster = (props: NewAdjusterProps) => {
  const classes = useStyles();

  return (
    <Box p={1} className={classes.containerClass}>
      <Box className={classes.thinText}>Adjuster Information</Box>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item className={classes.userIcon}>
          <Box className={classes.adjusterInfoBox}>
            <Icon name="userIcon" />
            <Typography className={classes.userText}>{props.adjusterInfo?.adjusterName}</Typography>
          </Box>
        </Grid>
        <Grid item className={classes.phoneIcon}>
          <Box className={classes.adjusterInfoBox}>
            <Icon name="newAdjusterPhone" />
            <Typography className={classes.phonetext}>
              <Link href={'tel:' + props.adjusterInfo?.adjusterPhoneNumber}>
                {formatPhone(props.adjusterInfo?.adjusterPhoneNumber)}
              </Link>
            </Typography>
          </Box>
        </Grid>
        {props.adjusterInfo?.userName !== 'SAI0005' && (
          <Grid item className={classes.emailIcon}>
            <Box className={classes.adjusterInfoBox}>
              <Icon name="newAdjusterMail" />
              <Typography className={classes.emailText}>
                {props?.adjusterInfo?.adjusterEmail && (
                  <Link
                    href={
                      'mailto:' +
                      props.adjusterInfo.adjusterEmail +
                      '?subject=Claim ' +
                      props.claimNumber
                    }
                  >
                    {props.adjusterInfo.adjusterEmail}
                  </Link>
                )}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
