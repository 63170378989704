import React from 'react';
import {ScriptBox} from '../../ScriptBox/ScriptBox';

interface CallBusinessScriptProps {
  businessType?: string;
}

export const CallBusinessScript = ({businessType}: CallBusinessScriptProps) => {
  const lang = businessType || 'business';

  return (
    <ScriptBox
      message={`I would like to go ahead and call the ${lang} to see what we need to do to get your vehicle released. (3 way call with ${lang} - note what was said during the call). You will need to remove all of your personal belongings from the vehicle, take your license plate and leave all sets of keys with the car.`}
    />
  );
};
