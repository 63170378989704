import {createMuiTheme} from '@material-ui/core/styles';

export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0173C6',
      light: '#286B86',
    },
    secondary: {
      main: '#4d840b',
    },
    background: {
      default: '#f4f6fa',
    },
    error: {
      main: '#ae001a',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      multiline: {
        backgroundColor: 'white',
      },
    },
  },
});
