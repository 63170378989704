import React from 'react';
import {BaseSwitch} from '../../BaseSwitch/BaseSwitch';
import {CopartComponentProps, VehicleLocationTypes} from '../types';
import {CallBusinessContainer} from './CallBusinessContainer';
import {PickUpContainer} from './PickUpContainer';

export const AcceptCopartContainer = ({dispatch, incident}: CopartComponentProps) => {
  return (
    <BaseSwitch
      matches={incident?.inPossession || incident?.locationType === VehicleLocationTypes.Person}
      then={<PickUpContainer dispatch={dispatch} incident={incident} />}
      otherwise={<CallBusinessContainer dispatch={dispatch} incident={incident} />}
    />
  );
};
