import React, {useEffect} from 'react';
import {FormControl, InputLabel, Select, FormHelperText, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  whiteTextField: {
    background: theme.palette.common.white,
  },
}));

export interface StateDropdownProps {
  inputRef?: any;
  name?: string;
  hasError?: boolean | undefined;
  helperText?: string;
  inputProps?: any;
  onChange?: (value: State | undefined) => void;
  onBlur?: (event: any) => void;
  value?: string;
  defaultValue?: string;
  key?: string | number;
  hasWhiteFields?: boolean | undefined;
  id?: string | undefined;
  autoComplete?: string;
  testId?: string;
}

export const StateDropdown = (props: StateDropdownProps) => {
  useEffect(() => {
    props.onChange &&
      props.onChange(states.find(x => x.code === (props.defaultValue || props.value)));
  }, [props.value, props.defaultValue, props]);

  const handleChange = (event: React.ChangeEvent<{name?: string; value: unknown}>) => {
    const value = event.target.value;
    props.onChange && props.onChange(states.find(x => x.code === value));
  };

  const handleBlur = (event: React.ChangeEvent<{name?: string; value: unknown}>) => {
    const value = event.target.value;
    props.onBlur && props.onBlur(states.find(x => x.code === value) || '');
  };

  const classes = useStyles();

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel
        htmlFor={(props.inputProps && props.inputProps.id) || 'state'}
        error={props.hasError}
      >
        State
      </InputLabel>
      <Select
        name={props.name}
        className={classes.whiteTextField}
        id={props.id || 'state'}
        onChange={handleChange}
        onBlur={handleBlur}
        native
        label="State"
        inputProps={{
          ...props.inputProps,
          'data-testid': props.testId,
        }}
        inputRef={props.inputRef}
        error={props.hasError}
        value={props.value}
        defaultValue={props.defaultValue}
        autoComplete={props.autoComplete || 'none'}
      >
        <option aria-label="None" value="" />
        {states.map(state => (
          <option value={state.code}>{state.code}</option>
        ))}
      </Select>
      <FormHelperText error={props.hasError}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export interface State {
  code?: string;
  label?: string;
  fraudLanguage?: string;
  hasFraudLanguage?: boolean;
}

export const sharedFraudLanguage =
  'Any person who knowingly and with intent to defraud any insurance company or another person, files a statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact, material thereto, commits a fraudulent insurance act, which is a crime, subject to criminal prosecution and [NY: substantial] civil penalties. In DC, LA, ME, TN, VA and WA, insurance benefits may also be denied.';

export const states: State[] = [
  {code: 'AL', label: 'Alabama', fraudLanguage: '', hasFraudLanguage: false},
  {code: 'AK', label: 'Alaska', fraudLanguage: '', hasFraudLanguage: false},
  {
    code: 'AZ',
    label: 'Arizona',
    fraudLanguage:
      'For your protection, Arizona law requires the following statement to appear on this form. Any person who knowingly presents a false or fraudulent claim for payment of a loss is subject to criminal and civil penalties.',
    hasFraudLanguage: true,
  },
  {
    code: 'AR',
    label: 'Arkansas',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'CA',
    label: 'California',
    fraudLanguage:
      'For your protection, California law requires the following to appear on this form: Any person who knowingly presents a false or fraudulent claim for payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.',
    hasFraudLanguage: true,
  },
  {
    code: 'CO',
    label: 'Colorado',
    fraudLanguage:
      'It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance, and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policy holder or claimant for the purpose of defrauding or attempting to defraud the policy holder or claimant with regard to a settlement or award payable from insurance proceeds shall be reported to the Colorado Division of Insurance within the Department of Regulatory Agencies.',
    hasFraudLanguage: true,
  },
  {
    code: 'CT',
    label: 'Connecticut',
    fraudLanguage: '',
    hasFraudLanguage: false,
  },
  {
    code: 'DE',
    label: 'Delaware',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'DC',
    label: 'District Of Columbia',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'FL',
    label: 'Florida',
    fraudLanguage:
      'Any person who knowingly and with the intent to injure, defraud, or deceive any insurance company files a statement of claim containing any false, incomplete or misleading information is guilty of a third degree felony.',
    hasFraudLanguage: true,
  },
  {code: 'GA', label: 'Georgia', fraudLanguage: '', hasFraudLanguage: false},
  {
    code: 'HI',
    label: 'Hawaii',
    fraudLanguage:
      'For your protection, Hawaii law requires you to be informed that presenting a fraudulent claim for payment of a loss or benefit is a crime punishable by fines or imprisonment, or both.',
    hasFraudLanguage: true,
  },
  {
    code: 'ID',
    label: 'Idaho',
    fraudLanguage:
      'Any person who knowingly and with the intent to injure, defraud, or deceive any insurance company files a statement of claim containing any false, incomplete or misleading information is guilty of a felony.',
    hasFraudLanguage: true,
  },
  {code: 'IL', label: 'Illinois', fraudLanguage: '', hasFraudLanguage: false},
  {
    code: 'IN',
    label: 'Indiana',
    fraudLanguage:
      'A person who knowingly and with intent to defraud an insurer files a statement of claim containing any false, incomplete, or misleading information commits a felony.',
    hasFraudLanguage: true,
  },
  {code: 'IA', label: 'Iowa', fraudLanguage: '', hasFraudLanguage: false},
  {code: 'KS', label: 'Kansas', fraudLanguage: '', hasFraudLanguage: false},
  {
    code: 'KY',
    label: 'Kentucky',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'LA',
    label: 'Louisiana',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'ME',
    label: 'Maine',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {code: 'MD', label: 'Maryland', fraudLanguage: '', hasFraudLanguage: false},
  {
    code: 'MA',
    label: 'Massachusetts',
    fraudLanguage: '',
    hasFraudLanguage: false,
  },
  {
    code: 'MI',
    label: 'Michigan',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'MN',
    label: 'Minnesota',
    fraudLanguage:
      'A person who files a claim with intent to defraud or helps commit a fraud against an insurer is guilty of a crime.',
    hasFraudLanguage: true,
  },
  {
    code: 'MS',
    label: 'Mississippi',
    fraudLanguage: '',
    hasFraudLanguage: false,
  },
  {code: 'MO', label: 'Missouri', fraudLanguage: '', hasFraudLanguage: false},
  {code: 'MT', label: 'Montana', fraudLanguage: '', hasFraudLanguage: false},
  {code: 'NE', label: 'Nebraska', fraudLanguage: '', hasFraudLanguage: false},
  {
    code: 'NV',
    label: 'Nevada',
    fraudLanguage:
      'Pursuant to NRS 686A.291, any person who knowingly and willfully files a statement of claim that contains any false, incomplete or misleading information concerning a material fact is guilty of a felony.',
    hasFraudLanguage: true,
  },
  {
    code: 'NH',
    label: 'New Hampshire',
    fraudLanguage:
      'Any person who, with purpose to injure, defraud or deceive any insurance company, files a statement of claim containing any false, incomplete or misleading information is subject to prosecution and punishment for insurance fraud, as provided in RSA 638:20.',
    hasFraudLanguage: true,
  },
  {
    code: 'NJ',
    label: 'New Jersey',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'NM',
    label: 'New Mexico',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'NY',
    label: 'New York',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'NC',
    label: 'North Carolina',
    fraudLanguage: '',
    hasFraudLanguage: false,
  },
  {
    code: 'ND',
    label: 'North Dakota',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'OH',
    label: 'Ohio',
    fraudLanguage:
      'Any person who, with intent to defraud or knowing that he/she is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.',
    hasFraudLanguage: true,
  },
  {
    code: 'OK',
    label: 'Oklahoma',
    fraudLanguage:
      'WARNING: Any person who knowingly and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.',
    hasFraudLanguage: true,
  },
  {code: 'OR', label: 'Oregon', fraudLanguage: '', hasFraudLanguage: false},
  {
    code: 'PA',
    label: 'Pennsylvania',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'RI',
    label: 'Rhode Island',
    fraudLanguage: '',
    hasFraudLanguage: false,
  },
  {
    code: 'SC',
    label: 'South Carolina',
    fraudLanguage: '',
    hasFraudLanguage: false,
  },
  {
    code: 'SD',
    label: 'South Dakota',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'TN',
    label: 'Tennessee',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'TX',
    label: 'Texas',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {code: 'UT', label: 'Utah', fraudLanguage: '', hasFraudLanguage: false},
  {code: 'VT', label: 'Vermont', fraudLanguage: '', hasFraudLanguage: false},
  {
    code: 'VA',
    label: 'Virginia',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'WA',
    label: 'Washington',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'WV',
    label: 'West Virginia',
    fraudLanguage: sharedFraudLanguage,
    hasFraudLanguage: true,
  },
  {
    code: 'WI',
    label: 'Wisconsin',
    fraudLanguage: '',
    hasFraudLanguage: false,
  },
  {code: 'WY', label: 'Wyoming', fraudLanguage: '', hasFraudLanguage: false},
];

export const statesForValidation: string[] = states.map((state: State) => state.code) as string[];

export const getStateBasedOnCode = (stateCode: string) => {
  const state = states.find(x => x.code === stateCode);
  return state;
};

export const getFraudLanguage = (stateCode: string) => {
  const state = getStateBasedOnCode(stateCode);
  return {
    hasFraudLanguage: state?.hasFraudLanguage,
    fraudLanguage: state?.fraudLanguage,
  };
};
