import {RadioOption} from '../../common/DynamicRadioList/DynamicRadioList';
import {Option} from '../../common/DynamicCheckboxList/DynamicCheckboxList';

export enum FireDamageDetailsTypes {
  HomeCompletelyBurned = 'Home completely burned',
  FireWaterDamageThroughout = 'Fire and water damage throughout',
  FireContainedOneOrTwoRooms = 'Fire contained to one or two rooms',
  FireContainedSmallAreaOrAppliance = 'Fire contained to a small area or appliance',
}

export enum FireDamageContainedAreas {
  MainLevel = 'Main Level',
  UpperLevel = 'Upper Level',
  Attic = 'Attic',
  Basement = 'Basement',
  DetachedStructure = 'Detached Structure',
  Other = 'Other',
}

export enum FireDamageToMainLevel {
  Bathroom = 'Bathroom',
  Bedroom = 'Bedroom',
  Den = 'Den',
  DiningRoom = 'Dining Room',
  FamilyRoom = 'Family Room',
  Foyer = 'Foyer',
  Garage = 'Garage',
  Kitchen = 'Kitchen',
  Laundry = 'Laundry',
  LivingRoom = 'Living Room',
  UtilityRoom = 'Utility Room',
  Other = 'Other',
}

export enum FireDamageToUpperLevel {
  Bathroom = 'Bathroom',
  Bedroom = 'Bedroom',
  Den = 'Den',
  DiningRoom = 'Dining Room',
  FamilyRoom = 'Family Room',
  Kitchen = 'Kitchen',
  Laundry = 'Laundry',
  LivingRoom = 'Living Room',
  UtilityRoom = 'Utility Room',
  Other = 'Other',
}

export enum FireDamageToBasement {
  Bathroom = 'Bathroom',
  Bedroom = 'Bedroom',
  CrawlSpace = 'Crawlspace',
  FamilyRoom = 'Family Room',
  Laundry = 'Laundry',
  UtilityRoom = 'Utility Room',
  Other = 'Other',
}

export enum FireDamageToDetachedStructure {
  DetachedGarage = 'Detached Garage',
  Shed = 'Shed',
  OutBuilding = 'Out Building',
  Fence = 'Fence',
  Other = 'Other',
}

export const FIRE_DAMAGE_DETAILS_OPTIONS: RadioOption[] = [
  {
    label: FireDamageDetailsTypes.HomeCompletelyBurned,
    value: FireDamageDetailsTypes.HomeCompletelyBurned,
    testId: FireDamageDetailsTypes.HomeCompletelyBurned,
  },
  {
    label: FireDamageDetailsTypes.FireWaterDamageThroughout,
    value: FireDamageDetailsTypes.FireWaterDamageThroughout,
    testId: FireDamageDetailsTypes.FireWaterDamageThroughout,
  },
  {
    label: FireDamageDetailsTypes.FireContainedOneOrTwoRooms,
    value: FireDamageDetailsTypes.FireContainedOneOrTwoRooms,
    testId: FireDamageDetailsTypes.FireContainedOneOrTwoRooms,
  },
  {
    label: FireDamageDetailsTypes.FireContainedSmallAreaOrAppliance,
    value: FireDamageDetailsTypes.FireContainedSmallAreaOrAppliance,
    testId: FireDamageDetailsTypes.FireContainedSmallAreaOrAppliance,
  },
];

export const ALLOWED_FIRE_DAMAGE_TYPES_AREA_DETAILS: Array<string> = [
  FireDamageDetailsTypes.FireContainedOneOrTwoRooms,
  FireDamageDetailsTypes.FireContainedSmallAreaOrAppliance,
];

export const WHICH_CONTAINED_AREAS_WERE_FIRE_DAMAGED: Array<Option> = [
  {
    label: FireDamageContainedAreas.MainLevel,
    value: FireDamageContainedAreas.MainLevel.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageContainedAreas.MainLevel,
  },
  {
    label: FireDamageContainedAreas.UpperLevel,
    value: FireDamageContainedAreas.UpperLevel.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageContainedAreas.UpperLevel,
  },
  {
    label: FireDamageContainedAreas.Attic,
    value: FireDamageContainedAreas.Attic.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageContainedAreas.Attic,
  },
  {
    label: FireDamageContainedAreas.Basement,
    value: FireDamageContainedAreas.Basement.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageContainedAreas.Basement,
  },
  {
    label: FireDamageContainedAreas.Other,
    value: FireDamageContainedAreas.Other.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageContainedAreas.Other,
  },
];

export const WHICH_ROOMS_WERE_DAMAGED_ON_THE_MAIN_LEVEL: Array<Option> = [
  {
    label: FireDamageToMainLevel.Bathroom,
    value: FireDamageToMainLevel.Bathroom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToMainLevel.Bathroom,
  },
  {
    label: FireDamageToMainLevel.Bedroom,
    value: FireDamageToMainLevel.Bedroom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToMainLevel.Bedroom,
  },
  {
    label: FireDamageToMainLevel.Den,
    value: FireDamageToMainLevel.Den.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToMainLevel.Den,
  },
  {
    label: FireDamageToMainLevel.DiningRoom,
    value: FireDamageToMainLevel.DiningRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToMainLevel.DiningRoom,
  },
  {
    label: FireDamageToMainLevel.FamilyRoom,
    value: FireDamageToMainLevel.FamilyRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToMainLevel.FamilyRoom,
  },
  {
    label: FireDamageToMainLevel.Foyer,
    value: FireDamageToMainLevel.Foyer.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToMainLevel.Foyer,
  },
  {
    label: FireDamageToMainLevel.Garage,
    value: FireDamageToMainLevel.Garage.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToMainLevel.Garage,
  },
  {
    label: FireDamageToMainLevel.Kitchen,
    value: FireDamageToMainLevel.Kitchen.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToMainLevel.Kitchen,
  },
  {
    label: FireDamageToMainLevel.Laundry,
    value: FireDamageToMainLevel.Laundry.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToMainLevel.Laundry,
  },
  {
    label: FireDamageToMainLevel.LivingRoom,
    value: FireDamageToMainLevel.LivingRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToMainLevel.LivingRoom,
  },
  {
    label: FireDamageToMainLevel.UtilityRoom,
    value: FireDamageToMainLevel.UtilityRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToMainLevel.UtilityRoom,
  },
  {
    label: FireDamageToMainLevel.Other,
    value: FireDamageToMainLevel.Other.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToMainLevel.Other,
  },
];

export const WHICH_ROOMS_WERE_DAMAGED_ON_THE_UPPER_LEVEL: Array<Option> = [
  {
    label: FireDamageToUpperLevel.Bathroom,
    value: FireDamageToUpperLevel.Bathroom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToUpperLevel.Bathroom,
  },
  {
    label: FireDamageToUpperLevel.Bedroom,
    value: FireDamageToUpperLevel.Bedroom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToUpperLevel.Bedroom,
  },
  {
    label: FireDamageToUpperLevel.Den,
    value: FireDamageToUpperLevel.Den.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToUpperLevel.Den,
  },
  {
    label: FireDamageToUpperLevel.DiningRoom,
    value: FireDamageToUpperLevel.DiningRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToUpperLevel.DiningRoom,
  },
  {
    label: FireDamageToUpperLevel.FamilyRoom,
    value: FireDamageToUpperLevel.FamilyRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToUpperLevel.FamilyRoom,
  },
  {
    label: FireDamageToUpperLevel.Kitchen,
    value: FireDamageToUpperLevel.Kitchen.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToUpperLevel.Kitchen,
  },
  {
    label: FireDamageToUpperLevel.Laundry,
    value: FireDamageToUpperLevel.Laundry.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToUpperLevel.Laundry,
  },
  {
    label: FireDamageToUpperLevel.LivingRoom,
    value: FireDamageToUpperLevel.LivingRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToUpperLevel.LivingRoom,
  },
  {
    label: FireDamageToUpperLevel.UtilityRoom,
    value: FireDamageToUpperLevel.UtilityRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToUpperLevel.UtilityRoom,
  },
  {
    label: FireDamageToUpperLevel.Other,
    value: FireDamageToUpperLevel.Other.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToUpperLevel.Other,
  },
];

export const WHICH_ROOMS_WERE_DAMAGED_IN_THE_BASEMENT: Array<Option> = [
  {
    label: FireDamageToBasement.Bathroom,
    value: FireDamageToBasement.Bathroom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToBasement.Bathroom,
  },
  {
    label: FireDamageToBasement.Bedroom,
    value: FireDamageToBasement.Bedroom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToBasement.Bedroom,
  },
  {
    label: FireDamageToBasement.CrawlSpace,
    value: FireDamageToBasement.CrawlSpace.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToBasement.CrawlSpace,
  },
  {
    label: FireDamageToBasement.FamilyRoom,
    value: FireDamageToBasement.FamilyRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToBasement.FamilyRoom,
  },
  {
    label: FireDamageToBasement.Laundry,
    value: FireDamageToBasement.Laundry.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToBasement.Laundry,
  },
  {
    label: FireDamageToBasement.UtilityRoom,
    value: FireDamageToBasement.UtilityRoom.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToBasement.UtilityRoom,
  },
  {
    label: FireDamageToBasement.Other,
    value: FireDamageToBasement.Other.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToBasement.Other,
  },
];

export const WHICH_DETACHED_STRUCTURES_WERE_DAMAGED: Array<Option> = [
  {
    label: FireDamageToDetachedStructure.DetachedGarage,
    value: FireDamageToDetachedStructure.DetachedGarage.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToDetachedStructure.DetachedGarage,
  },
  {
    label: FireDamageToDetachedStructure.Shed,
    value: FireDamageToDetachedStructure.Shed.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToDetachedStructure.Shed,
  },
  {
    label: FireDamageToDetachedStructure.OutBuilding,
    value: FireDamageToDetachedStructure.OutBuilding.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToDetachedStructure.OutBuilding,
  },
  {
    label: FireDamageToDetachedStructure.Fence,
    value: FireDamageToDetachedStructure.Fence.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToDetachedStructure.Fence,
  },
  {
    label: FireDamageToBasement.Other,
    value: FireDamageToBasement.Other.toLowerCase(),
    checked: false,
    disabled: false,
    testID: FireDamageToBasement.Other,
  },
];
