import {useMediaQuery, useTheme} from '@material-ui/core';
import {SABox} from '@saux/design-system-react';
import React, {useState, useReducer, useEffect} from 'react';
import styled from 'styled-components';
import {DropArea} from '.';
import {FileContent} from '../uploadFileContent';
import {
  ModalStates,
  ModalTypes,
  useModalController,
} from '../../../common/Providers/ModalController';
import {SelectFiles} from './DropArea';
import {getSignedUrl, SignedResponse, uploadToEDS, uploadToS3} from '../../../../services';
import CloseSmallIcon from '../uploadFileContent/CloseSmallIcon';
import {ButtonContainer} from '../uploadFileContent/FileContent';
import {SAButton} from '../../Button/Button';

const StagingModal = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  border: 1px, solid, #979797;
  box-shadow: 0px 8px 32px -8px #002038;
  background-color: #ffffff;
  border-radius: 3px;
  max-height: 90vh;
  padding: 30px;
  width: 800px;
  @media (max-width: 960px) {
    width: 80%;
  }
  @media (max-width: 750px) {
    width: 90%;
  }
  @media (max-width: 600px) {
    width: 100%;
    max-height: calc(100vh - 50px);
    margin-top: 50px;
  }
`;
const UploadModal = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px, solid, #979797;
  box-shadow: 0px 8px 32px -8px #002038;
  padding: 30px;
  background-color: #ffffff;
  height: 481px;
  width: 650px;
  @media (max-width: 960px) {
    width: 60vw;
    height: auto;
  }
  @media (max-width: 750px) {
    width: 85vw;
    height: auto;
  }
`;
const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const HeaderText = styled.div`
  color: #041e41;
  font-size: 24px;
  font-weight: 700;
`;
export const StagingBox = styled.div`
  border: 1px solid #80b8e2;
  border-radius: 4px;
  margin-top: 30px;
  overflow: auto;
  min-height: 40px;
`;
const MobileStagingBox = styled.div`
  margin-top: 30px;
  overflow: auto;
  min-height: 40px;
  &:not(:last-child) {
    border-bottom: 1px solid #d9dfeb;
  }
`;
const MobileFileRow = styled(SABox)`
  border-bottom: 1px solid #d9dfeb;
`;
const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0px;
  vertical-align: middle;
  @media (min-width: 960px) {
    padding: 30px 0px 0px 0px;
  }
`;
const LeftButtonSubGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 30px;
`;
const RightButtonSubGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 30px;
`;
const MobileFileControl = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
`;
const CancelAnchor = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-top: 7px;
  margin-left: 20px;
  margin-right: 30px;
  &:hover {
    cursor: pointer;
  }
`;
const SelectButton = styled.label`
  font-weight: 700;
  font-size: 14px;
  background-color: #0173c6;
  color: #ffffff;
  border-radius: 3px;
  border: 1px solid #0173c6;
  padding: 7px 20px 6px 20px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  &:hover {
    cursor: pointer;
  }
  margin: auto;
`;
const MobileSelectButton = styled(SelectButton)`
  margin-right: 0px;
`;
export const UploadButton = styled(SAButton)`
  font-weight: 700;
  font-size: 14px;
  padding: 7px 20px 6px 20px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  &:hover {
    cursor: pointer;
  }
  margin: auto;
`;
const MobileUploadButton = styled(UploadButton)`
  width: 100%;
  margin: 0;
`;
export const ErrorText = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #e02020;
`;
const HeaderContainer = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding 0px 12px;
  align-itmes: center;
`;

const AlignIcon = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const FILE_SIZE_LIMIT = 314572800;

export enum UploadStatus {
  Pending = 'pending',
  S3Success = 's3Success',
  EdsError = 'edsError',
  Complete = 'complete',
}

export interface FileAndStatus {
  file: File;
  status: string;
  signedResponse: SignedResponse | null;
  uploading: boolean;
}

interface UploadModalProps {
  claimNumber: string;
  setData: any;
  setHasBeenClosed: any;
}

interface UpdatedFilesOptions {
  fileList: FileAndStatus[];
  filesToUpdate: FileAndStatus[];
  status?: string;
  signedResponse?: SignedResponse;
  uploading?: boolean;
}

export default ({claimNumber, setData, setHasBeenClosed}: UploadModalProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [staging, setStaging] = useState(false);

  const {dispatchModal} = useModalController();

  const spliceFileList = (fileList: FileAndStatus[], files: FileAndStatus[]) => {
    for (let i = 0; i < fileList.length; i += 1) {
      if (files[0].file.name === fileList[i].file.name) {
        fileList.splice(i, 1);
        i -= 1;
      }
    }
    return fileList;
  };

  const updateFiles = ({
    fileList,
    filesToUpdate,
    status,
    signedResponse,
    uploading,
  }: UpdatedFilesOptions) => {
    const filesToUpdateNames = filesToUpdate.map((f: FileAndStatus) => f?.file?.name);

    return fileList.map((f: FileAndStatus) => {
      if (filesToUpdateNames.includes(f.file.name)) {
        if (status) {
          f.status = status;
        }
        if (signedResponse) {
          f.signedResponse = signedResponse;
        }
        if (uploading !== undefined) {
          f.uploading = uploading;
        }
      }

      return f;
    });
  };

  const reducer = (
    state: {fileList: FileAndStatus[]},
    action: {
      type: string;
      files: FileAndStatus[];
      status?: string;
      signedResponse?: SignedResponse;
      uploading?: boolean;
    }
  ) => {
    switch (action.type) {
      case 'ADD_FILE_TO_LIST':
        return {...state, fileList: state.fileList.concat(action.files)};
      case 'CLEAR_LIST':
        return {...state, fileList: action.files};
      case 'REMOVE_FILE':
        return {
          ...state,
          fileList: spliceFileList(state.fileList, action.files),
        };
      case 'SET_FILE_STATUS':
        return {
          ...state,
          fileList: updateFiles({
            fileList: state.fileList,
            filesToUpdate: action.files,
            status: action.status,
            signedResponse: action.signedResponse,
            uploading: action.uploading,
          }),
        };
      default:
        return state;
    }
  };

  const [data, dispatch] = useReducer(reducer, {
    fileList: [],
  });

  const chooseFiles = (e: FileList | null) => {
    let files: FileAndStatus[] = [];

    if (e) {
      for (let i = 0; i < e.length; i += 1) {
        files.push({
          file: e[i],
          status: UploadStatus.Pending,
          signedResponse: null,
          uploading: false,
        });
      }

      const existingFiles = data.fileList.map((f: FileAndStatus) => f.file.name);
      files = files.filter(f => !existingFiles.includes(f.file.name));

      if (files.length > 0) {
        dispatch({type: 'ADD_FILE_TO_LIST', files});
      }
    }
  };

  const reset: any[] = [];

  const removeFile = (f: FileAndStatus) => {
    if (data.fileList.length === 1) {
      dispatch({
        type: 'CLEAR_LIST',
        files: reset,
      });
    } else
      dispatch({
        type: 'REMOVE_FILE',
        files: [f],
      });
  };

  const [fileIndex, setFileIndex] = useState<number | undefined>();

  const imgTypes = /image\/gif|jpg|bmp|png|jpeg|jpe/;
  const {fileList} = data;
  const eligibleFiles = fileList.filter((f: FileAndStatus) => f.file.size < FILE_SIZE_LIMIT);
  const filesNotUploaded = eligibleFiles.filter(
    (f: FileAndStatus) => f.status !== UploadStatus.Complete
  );
  const imgFilesAndStatus = eligibleFiles.filter((f: FileAndStatus) => f.file.type.match(imgTypes));
  const nonImgFilesAndStatus = eligibleFiles.filter(
    (f: FileAndStatus) => !f.file.type.match(imgTypes)
  );
  const arrangedFilesAndStatus = [...imgFilesAndStatus, ...nonImgFilesAndStatus];
  const indexToUse = fileIndex !== undefined ? fileIndex : 0;
  const currentFileAndStatus = arrangedFilesAndStatus[indexToUse];
  const currentFile = currentFileAndStatus?.file;
  const filename = currentFile?.name;
  const signedResponse = currentFileAndStatus?.signedResponse;
  const isImg = currentFile?.type && currentFile?.type.match(imgTypes);
  const isLastImg = isImg && fileIndex === imgFilesAndStatus?.length - 1;
  const status = currentFileAndStatus?.status;
  const stopTheSwirl = eligibleFiles.some((f: FileAndStatus) => f.uploading);
  const uploadPending = stopTheSwirl || filesNotUploaded.length === 0;

  const [s3Open, setS3Open] = useState<boolean>(false);
  const [edsOpen, setEdsOpen] = useState<boolean>(false);

  const nextFile = () => {
    if (fileIndex === eligibleFiles.length - 1) {
      setS3Open(false);
      setEdsOpen(false);
      setFileIndex(undefined);
    } else {
      setS3Open(false);
      setEdsOpen(false);
      setFileIndex((i?: number) => {
        return i !== undefined ? i + 1 : undefined;
      });
    }
  };

  useEffect(() => {
    if (fileIndex !== undefined) {
      if (status !== UploadStatus.Complete) {
        if (!signedResponse) {
          tryGetSignedUrl();
        } else {
          setS3Open(true);
        }
      } else {
        nextFile();
      }
    }
  }, [fileIndex]);

  useEffect(() => {
    if (s3Open) {
      if (status !== UploadStatus.S3Success && status !== UploadStatus.EdsError && signedResponse) {
        tryS3Upload();
      } else if (
        isLastImg ||
        (!isImg &&
          signedResponse &&
          (status === UploadStatus.S3Success || status === UploadStatus.EdsError))
      ) {
        setEdsOpen(true);
      } else {
        if (!isImg) {
          dispatch({
            type: 'SET_FILE_STATUS',
            files: [currentFileAndStatus],
            uploading: false,
          });
        }
        nextFile();
      }
    }
  }, [s3Open]);

  useEffect(() => {
    if (edsOpen) {
      tryEdsUpload();
    }
  }, [edsOpen]);

  const tryGetSignedUrl = async () => {
    try {
      const {data} = await getSignedUrl({filename, claimnumber: claimNumber});

      dispatch({
        type: 'SET_FILE_STATUS',
        files: [currentFileAndStatus],
        signedResponse: data,
      });

      setS3Open(true);
    } catch (error) {
      console.warn(error);
      dispatch({
        type: 'SET_FILE_STATUS',
        files: [currentFileAndStatus],
        uploading: false,
      });

      if (isLastImg) {
        setEdsOpen(true);
      } else {
        nextFile();
      }
    }
  };

  const tryS3Upload = async () => {
    try {
      if (signedResponse) {
        await uploadToS3({
          signedUrl: signedResponse.url,
          dataFields: signedResponse.fields,
          file: currentFile,
        });
      }

      dispatch({
        type: 'SET_FILE_STATUS',
        files: [currentFileAndStatus],
        status: UploadStatus.S3Success,
      });

      if (isLastImg || !isImg) {
        setEdsOpen(true);
      } else {
        nextFile();
      }
    } catch (error) {
      console.warn(error);
      dispatch({
        type: 'SET_FILE_STATUS',
        files: [currentFileAndStatus],
        uploading: false,
      });

      if (isLastImg) {
        setEdsOpen(true);
      } else {
        nextFile();
      }
    }
  };

  const tryEdsUpload = () => {
    const imgFilesToUpload = imgFilesAndStatus.filter((f: FileAndStatus) =>
      [UploadStatus.S3Success, UploadStatus.EdsError].includes(f.status as UploadStatus)
    );
    const filesToUpdateStatus = isImg ? imgFilesToUpload : [currentFileAndStatus];
    let totalFileSize: number = 0;
    let addedFiles: FileAndStatus[] = [];

    for (let i = 0; i < filesToUpdateStatus.length; i++) {
      const currentFile = filesToUpdateStatus[i];
      const isLastFile = i === filesToUpdateStatus.length - 1;

      if (totalFileSize + currentFile.file.size < FILE_SIZE_LIMIT) {
        totalFileSize += currentFile.file.size;
        addedFiles.push(currentFile);

        if (isLastFile) {
          edsUploadLogic(addedFiles);
        }
      } else {
        edsUploadLogic(addedFiles);
        addedFiles = [currentFile];
        totalFileSize = currentFile.file.size;

        if (isLastFile) {
          edsUploadLogic(addedFiles);
        }
      }
    }

    nextFile();
  };

  const edsUploadLogic = async (addedFiles: FileAndStatus[]) => {
    const currentFileNames = addedFiles.map((f: FileAndStatus) => f.file.name);
    try {
      const edsResponse = await uploadToEDS({
        filename: currentFileNames,
        claimnumber: claimNumber,
      });

      if (edsResponse && !edsResponse.data.hasErrors) {
        dispatch({
          type: 'SET_FILE_STATUS',
          files: addedFiles,
          status: UploadStatus.Complete,
          uploading: false,
        });

        setData((prevState: any) => prevState.concat(currentFileNames));
      } else {
        dispatch({
          type: 'SET_FILE_STATUS',
          files: addedFiles,
          status: UploadStatus.EdsError,
          uploading: false,
        });
      }
    } catch (error) {
      console.warn(error);
      dispatch({
        type: 'SET_FILE_STATUS',
        files: addedFiles,
        status: UploadStatus.EdsError,
        uploading: false,
      });
    }
  };

  const closeModal = () => {
    if (dispatchModal) {
      dispatchModal({
        type: ModalStates.Off,
        name: ModalTypes.Upload,
      });
    }
    setHasBeenClosed(true);
  };

  return (
    <>
      {staging ? (
        <StagingModal>
          {isMobile ? (
            <>
              <HeaderContainer>
                <HeaderRow>
                  <HeaderText data-testid="dropArea">Upload Documents</HeaderText>
                </HeaderRow>
                <AlignIcon>
                  <ButtonContainer data-testid="mobileCloseIcon" onClick={() => closeModal()}>
                    <CloseSmallIcon />
                  </ButtonContainer>
                </AlignIcon>
              </HeaderContainer>
              <MobileStagingBox>
                {data.fileList.map((f: FileAndStatus, index: any) => {
                  return (
                    <MobileFileRow>
                      <FileContent
                        name={f.file.name}
                        file={f.file}
                        key={f.file.name}
                        removeFile={removeFile}
                        status={f.status}
                        signedResponse={f.signedResponse}
                        uploading={f.uploading}
                        uploadedFilesSection={false}
                      />
                    </MobileFileRow>
                  );
                })}
              </MobileStagingBox>
              <ButtonRow>
                <CancelAnchor
                  onClick={() => {
                    dispatch({
                      type: 'CLEAR_LIST',
                      files: reset,
                    });
                    closeModal();
                  }}
                >
                  CLEAR LIST
                </CancelAnchor>
                <MobileSelectButton htmlFor="file">
                  ADD MORE FILES
                  <SelectFiles
                    type="file"
                    name="file"
                    id="file"
                    multiple
                    onChange={e => {
                      chooseFiles(e.target.files);
                    }}
                  />
                </MobileSelectButton>
              </ButtonRow>
              <MobileFileControl>
                <MobileUploadButton
                  loading={stopTheSwirl}
                  disabled={uploadPending}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setFileIndex(0);
                    dispatch({
                      type: 'SET_FILE_STATUS',
                      files: filesNotUploaded,
                      uploading: true,
                    });
                  }}
                >
                  UPLOAD DOCUMENTS
                </MobileUploadButton>
              </MobileFileControl>
            </>
          ) : (
            <>
              <HeaderRow>
                <HeaderText data-testid="dropArea">Upload Documents</HeaderText>
                <AlignIcon>
                  <ButtonContainer data-testid="closeIcon" onClick={() => closeModal()}>
                    <CloseSmallIcon />
                  </ButtonContainer>
                </AlignIcon>
              </HeaderRow>
              <StagingBox>
                {data.fileList.map((f: FileAndStatus, index: any) => {
                  return (
                    <FileContent
                      name={f.file.name}
                      file={f.file}
                      key={f.file.name}
                      removeFile={removeFile}
                      signedResponse={f.signedResponse}
                      uploading={f.uploading}
                      status={f.status}
                      uploadedFilesSection={false}
                    />
                  );
                })}
              </StagingBox>
              <ButtonRow>
                <LeftButtonSubGroup>
                  <CancelAnchor
                    onClick={() => {
                      dispatch({
                        type: 'CLEAR_LIST',
                        files: reset,
                      });
                      closeModal();
                    }}
                  >
                    CLEAR LIST
                  </CancelAnchor>
                  <SelectButton htmlFor="file">
                    ADD MORE FILES
                    <SelectFiles
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      onChange={e => {
                        chooseFiles(e.target.files);
                      }}
                    />
                  </SelectButton>
                </LeftButtonSubGroup>
                <RightButtonSubGroup>
                  <UploadButton
                    loading={stopTheSwirl}
                    disabled={uploadPending}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setFileIndex(0);
                      dispatch({
                        type: 'SET_FILE_STATUS',
                        files: filesNotUploaded,
                        uploading: true,
                      });
                    }}
                  >
                    UPLOAD DOCUMENTS
                  </UploadButton>
                </RightButtonSubGroup>
              </ButtonRow>
            </>
          )}
        </StagingModal>
      ) : (
        <UploadModal>
          <HeaderRow>
            <HeaderText data-testid="dropArea">Upload Documents</HeaderText>
            <AlignIcon>
              <ButtonContainer data-testid="closeIcon" onClick={() => closeModal()}>
                <CloseSmallIcon />
              </ButtonContainer>
            </AlignIcon>
          </HeaderRow>
          <DropArea stagingHandler={setStaging} data={data} dispatch={dispatch} />
          <CancelAnchor id="CancelButton" onClick={() => closeModal()}>
            CANCEL
          </CancelAnchor>
        </UploadModal>
      )}
    </>
  );
};
