import {Box, Grid, makeStyles} from '@material-ui/core';
import React, {useEffect} from 'react';
import {IncidentSwitch} from '../../auto/IncidentDetails/IncidentSwitch/IncidentSwitch';
import {CheckboxError} from '../CheckboxError/CheckboxError';
import {CheckboxWithLabel} from '../CheckboxWithLabel/CheckboxWithLabel';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '4px 0px 4px 10px',
    margin: '0px',
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        backgroundColor: '#e9f3f7',
      },
    },
    alignItems: 'flex-start',
  },
  labelChecked: {
    fontWeight: 'bold',
    color: '#0173C6',
  },
  checkbox: {
    padding: '0px 9px 9px 0px',
  },
}));

export interface Option {
  label: string;
  value: string;
  checked: boolean;
  disabled: boolean;
  testID?: string;
  personas?: string[];
}

interface DynamicCheckboxListProps {
  errorMessage?: string;
  options: Option[];
  name: string;
  defaultChecked?: string | undefined;
  onOptionsChange?: (option: Option, checked: boolean, index: number) => void;
  errorTestId?: string;
}

export const DynamicCheckboxList = (props: DynamicCheckboxListProps) => {
  const {errorMessage, options, name, onOptionsChange, defaultChecked, errorTestId} = props;
  const classes = useStyles();

  useEffect(() => {
    if (defaultChecked) {
      const checkbox = document.getElementById(defaultChecked);
      const isChecked = checkbox?.querySelector('input')?.checked;

      if (!isChecked) {
        checkbox?.click();
      }
    }
  }, [defaultChecked]);

  return (
    <Box pb={5}>
      <CheckboxError errorMessage={errorMessage} testId={errorTestId} />
      <Grid container spacing={2}>
        {options.map((option, index) => (
          <IncidentSwitch
            incidentOption={option}
            then={
              <Grid item xs={12} sm={6} md={4} key={option.value}>
                <CheckboxWithLabel
                  name={name}
                  testId={option.testID}
                  formControlLabelProps={{
                    value: option.value,
                    label: option.label,
                    id: option.value,
                    classes: {
                      root: classes.root,
                      label: option.checked ? classes.labelChecked : '',
                    },
                    disabled: option.disabled,
                  }}
                  checkboxProps={{
                    value: JSON.stringify({
                      label: option.label,
                      value: option.value,
                    }),
                    defaultChecked: option.checked,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                      onOptionsChange && onOptionsChange(option, event.target.checked, index),
                  }}
                />
              </Grid>
            }
          />
        ))}
      </Grid>
    </Box>
  );
};
