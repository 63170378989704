import React, {useContext, useEffect, useState} from 'react';
import {Box, Grid, Divider, Hidden, Typography} from '@material-ui/core';
import {ArrayField, UseFieldArrayMethods, useFormContext} from 'react-hook-form';
import {SATextField} from '../TextField/TextField';
import {SASelect} from '../Select/Select';
import {BlueContainer} from '../Containers/Containers';
import {DynamicContainer, DynamicContainerProps} from '../DynamicContainer/DynamicContainer';
import {AddressSwitch} from '../AddressSwitch/AddressSwitch';
import {getAddressSwitchProps} from '../../../utils/utils';
import {CheckboxWithLabel} from '../CheckboxWithLabel/CheckboxWithLabel';
import {SACheckboxVariants} from '../Checkbox/Checkbox';
import {UserPersonaContext} from '../Providers/Providers';
import {CustomerTypes} from '../../../commonTypes';
import {ContactInfo} from '../ContactInfo/ContactInfo';
import {ContactSections, FnolFormContact} from '../ContactInfo/types';
import {useFormContactAtomState, useManageContactOccurrences} from '../../../atoms';
import {BaseSwitch} from '../BaseSwitch/BaseSwitch';

export enum PropertyForms {
  OtherProperty = 'otherProperty',
  YourProperty = 'yourProperty',
  AdditionalProperty = 'additionalProperty',
}

interface AdditionalPropertyProps extends DynamicContainerProps {
  formName: PropertyForms;
  fieldArray: UseFieldArrayMethods<Record<string, any>, 'id'>;
  field: Partial<ArrayField<Record<string, any>, 'id'>>;
  index: number;
}

export const AdditionalProperty = ({
  formName,
  fieldArray,
  index,
  addController,
}: AdditionalPropertyProps) => {
  const {register, errors} = useFormContext();
  const {remove, fields} = fieldArray;
  const {userPersona} = useContext(UserPersonaContext);
  const [otherSelected, setOtherSelected] = useState<boolean>(false);
  const [propertyAddressIsOwners, setPropertyAddressIsOwners] = useState<boolean>(false);
  const [ownerId, setOwnerId] = useState<string>('');
  const [formContactAtomState] = useFormContactAtomState(ownerId);
  const {removeContactOccurrences} = useManageContactOccurrences();
  const validOwnerAddress =
    ownerId &&
    formContactAtomState?.contactAddress?.address &&
    formContactAtomState?.contactAddress?.city &&
    formContactAtomState?.contactAddress?.state &&
    formContactAtomState?.contactAddress?.zipCode;
  const isThirdParty = userPersona === CustomerTypes.ThirdParty;
  const baseAddressSwitchArgs = {
    requiredStreetAddress: isThirdParty,
    requiredCity: isThirdParty,
    requiredState: isThirdParty,
    requiredZipCode: isThirdParty,
  };
  const isYourPropertyForm = formName === PropertyForms.YourProperty;
  const showOwnerDetailsSection =
    !isYourPropertyForm || (isYourPropertyForm && userPersona === CustomerTypes.Guardian);
  const section = isYourPropertyForm
    ? ContactSections.YourPropertyOwner
    : ContactSections.OtherPropertyOwner;

  const handlePropertyTypeChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    _child: React.ReactNode
  ) => {
    setOtherSelected(event.target.value === 'other');
  };

  const onOwnerChange = (contact: FnolFormContact) => {
    setOwnerId(contact.fnolId);
  };

  const onCheckboxChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setPropertyAddressIsOwners(checked);
  };

  const removeProperty = () => {
    remove(index);
    removeContactOccurrences([{id: ownerId, sections: [section]}]);
  };

  useEffect(() => {
    if (!validOwnerAddress) {
      setPropertyAddressIsOwners(false);
    }
  }, [validOwnerAddress, ownerId, formContactAtomState]);

  return (
    <DynamicContainer
      displayAddButton={index === fields.length - 1}
      alignAddButton="right"
      addButtonText="ADD PROPERTY"
      addController={addController}
      displayRemoveButton={fields.length !== 1}
      removeButtonText="REMOVE"
      removeController={removeProperty}
      removeDataTestId="test-remove-property"
    >
      <Box pb={3} pt={1}>
        <Grid container spacing={3} justify="flex-start">
          <Grid item xs={12} sm={4}>
            <SASelect
              data-testid="test-property-type-select"
              name={`${formName}[${index}].propertyTypeSelection`}
              label="Property Type"
              id={`${formName}-${index}-propertyTypeSelection`}
              error={errors[formName]?.[index]?.hasOwnProperty('propertyTypeSelection')}
              helperText={errors[formName]?.[index]?.propertyTypeSelection?.message}
              inputRef={register()}
              onChange={handlePropertyTypeChange}
              inputProps={{'aria-label': `${formName}-${(index || 0) + 1}-Property Type`}}
            >
              <option value="house">House</option>
              <option value="building">Building</option>
              <option value="guardrail">Guardrail</option>
              <option value="pole">Telephone/Light Pole</option>
              <option value="fence">Fence</option>
              <option value="other">Other</option>
            </SASelect>
          </Grid>
          {otherSelected && (
            <Grid item xs={12} sm={4}>
              <SATextField
                name={`${formName}[${index}].otherProperty`}
                label="Other Property Type"
                inputRef={register()}
                inputProps={{}}
                id={`${formName}-${index}-otherProperty`}
                InputLabelProps={{
                  'aria-labelledby': `${formName}-${index}-otherProperty`,
                }}
                error={errors[formName]?.[index]?.hasOwnProperty('otherProperty')}
                helperText={errors[formName]?.[index]?.otherProperty?.message}
                autoFocus
              />
            </Grid>
          )}
          <Hidden xsDown>
            <Grid item sm={otherSelected ? 4 : 8}>
              <Box />
            </Grid>
          </Hidden>
          <Grid item xs={10}>
            <SATextField
              name={`${formName}[${index}].damage`}
              label="Damage Description"
              variant="outlined"
              inputRef={register()}
              multiline
              id={`${formName}-${index}-damageDescription`}
              InputLabelProps={{
                'aria-labelledby': `${formName}-${index}-damageDescription`,
              }}
              error={errors[formName]?.[index]?.hasOwnProperty('damage')}
              helperText={errors[formName]?.[index]?.damage?.message || '280-character limit'}
              fullWidth
              showCharacterCount
              characterLimit={280}
              inputProps={{className: 'propertyDamageDescription'}}
            />
          </Grid>
          {showOwnerDetailsSection && (
            <>
              <Grid item xs={12}>
                <Box pt={3} pb={2}>
                  <Typography variant="body1">
                    <b>Owner Details</b>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1">Who is the owner of the property?</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <ContactInfo
                  section={section}
                  parentFieldName={`${formName}[${index}]`}
                  selectProps={{
                    name: 'ownerId',
                    label: 'Property Owner',
                    id: `${formName}${(index || 0) + 1}ownerId`,
                    inputProps: {'aria-label': `${formName}-${(index || 0) + 1}-Property Owner`},
                  }}
                  errorsObj={errors?.[formName]?.[index]}
                  onContactChange={onOwnerChange}
                  hiddenFields={{
                    contactType: {render: true, name: 'radioSelection'},
                    email: {render: true},
                    phoneNumbers: {render: true},
                    address: {render: true, name: 'ownerAddress.address'},
                    city: {render: true, name: 'ownerAddress.city'},
                    state: {render: true, name: 'ownerAddress.state'},
                    zipCode: {render: true, name: 'ownerAddress.zipCode'},
                  }}
                />
              </Grid>
              {validOwnerAddress && (
                <Grid item xs={12}>
                  <Box>
                    <CheckboxWithLabel
                      name={`${formName}[${index}].ownerAddressSameAsProperty`}
                      formControlLabelProps={{
                        label: 'Property address is the same as owner address',
                      }}
                      checkboxProps={{
                        id: 'ownerAddressSameAsProperty',
                        variant: SACheckboxVariants.CheckShadow,
                        onChange: onCheckboxChange,
                      }}
                    />
                  </Box>
                </Grid>
              )}
            </>
          )}
          <BaseSwitch
            matches={propertyAddressIsOwners}
            then={
              <>
                <input
                  type="hidden"
                  ref={register()}
                  name={`${formName}[${index}].propertyAddress.address`}
                  defaultValue={formContactAtomState?.contactAddress?.address || ''}
                  data-testid={`${index}-property-address`}
                />
                <input
                  type="hidden"
                  ref={register()}
                  name={`${formName}[${index}].propertyAddress.city`}
                  defaultValue={formContactAtomState?.contactAddress?.city || ''}
                  data-testid={`${index}-property-city`}
                />
                <input
                  type="hidden"
                  ref={register()}
                  name={`${formName}[${index}].propertyAddress.state`}
                  defaultValue={formContactAtomState?.contactAddress?.state || ''}
                  data-testid={`${index}-property-state`}
                />
                <input
                  type="hidden"
                  ref={register()}
                  name={`${formName}[${index}].propertyAddress.zipCode`}
                  defaultValue={formContactAtomState?.contactAddress?.zipCode || ''}
                  data-testid={`${index}-property-zip`}
                />
              </>
            }
            otherwise={
              <Grid item xs={12}>
                <Box pt={2} pb={1}>
                  <Typography variant="body1">Property Address</Typography>
                </Box>
                <AddressSwitch
                  manualAddressProps={
                    getAddressSwitchProps({
                      ...baseAddressSwitchArgs,
                      parentFieldName: `${formName}[${index || 0}].propertyAddress`,
                      errors: errors?.[formName]?.[index || 0]?.propertyAddress,
                    }).manualAddressProps
                  }
                  autocompleteAddressProps={
                    getAddressSwitchProps({
                      ...baseAddressSwitchArgs,
                      parentFieldName: `${formName}[${index || 0}].propertyAddress`,
                      errors: errors?.[formName]?.[index || 0]?.propertyAddress,
                    }).autocompleteAddressProps
                  }
                  parentFieldName={`${formName}[${index}].propertyAddress`}
                />
              </Grid>
            }
          />
        </Grid>
      </Box>
    </DynamicContainer>
  );
};

interface PropertyDamageFormProps {
  formName: PropertyForms;
  fieldArray: UseFieldArrayMethods<Record<string, any>, 'id'>;
  addAdditionalProperty: (props: any) => void;
}

export const PropertyDamageForm = ({
  formName,
  fieldArray,
  addAdditionalProperty,
}: PropertyDamageFormProps) => {
  return (
    <Box pt={1}>
      <BlueContainer>
        {fieldArray.fields.map(
          (field: Partial<ArrayField<Record<string, any>, 'id'>>, index: number) => (
            <Box key={field.id}>
              {index > 0 && (
                <Grid item xs={12}>
                  <Box pb={2}>
                    <Divider variant="middle" />
                  </Box>
                </Grid>
              )}
              <AdditionalProperty
                formName={formName}
                fieldArray={fieldArray}
                field={field}
                index={index}
                addController={addAdditionalProperty}
              />
            </Box>
          )
        )}
      </BlueContainer>
    </Box>
  );
};
