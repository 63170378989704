import {Typography, useMediaQuery} from '@material-ui/core';
import {SABox, SAButton, SACard, SAColumns, SAText} from '@saux/design-system-react';
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useUserAtomState} from '../../../atoms';
import {AutoIntegrationFlags, CustomerTypes} from '../../../commonTypes';
import {navigateDefaultReplace} from '../../../pages/utils';
import {COPART_ROUTE, SUMMARY_PAGE_ROUTE} from '../../../routes/paths';
import {mapData} from '../../../utils/dataMapper';
import {getVehicleIncidents, isIntegrationEligible} from '../../../utils/utils';
import {
  contentStyleTypes,
  InformationBubbleBox,
} from '../../common/InformationBubbleBox/InformationBubbleBox';
import {useFeatureFlags} from '../../common/Providers/Providers';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';
import {StateProps, Vehicle, VehicleIncident} from '../../common/Summary/types';

interface StaffAppraiserProps {
  successState: StateProps;
}

const List = styled.ul`
  margin: 0px;
`;

const LeftJustified = styled(SABox)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const StaffAppraiser = ({successState}: StaffAppraiserProps) => {
  const xsDown = useMediaQuery('(max-width:600px)');
  const [userAtomState] = useUserAtomState();
  const draftResponse = userAtomState.draftClaimResponse;
  const userPersona = userAtomState.gettingStarted?.customerType || '';
  const isAssociate = userPersona === CustomerTypes.Associate;
  const {featureFlags} = useFeatureFlags();
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const vehicleIncidents = getVehicleIncidents(successState);

  useEffect(() => {
    const vehiclesToShowStaff = successState.vehiclesToShowStaff || [];
    setVehicles(vehiclesToShowStaff?.map((vi: VehicleIncident) => vi.vehicle || {}) as Vehicle[]);
  }, []);

  const onContinue = () => {
    const copartEligible =
      isIntegrationEligible(vehicleIncidents, AutoIntegrationFlags.CopartEligible) &&
      featureFlags?.FF_DCX_2155 &&
      isAssociate;

    if (copartEligible) {
      navigateDefaultReplace(COPART_ROUTE, {
        state: successState,
      });
    } else {
      navigateDefaultReplace(SUMMARY_PAGE_ROUTE, {
        state: mapData(successState, successState.formData, draftResponse),
      });
    }
  };

  return (
    <SABox>
      <SABox
        mx={{sm: 'large', md: 'xxl'}}
        mt={{sm: 'large', md: 'xl'}}
        mb={{xs: 'small', sm: 'large'}}
      >
        <SACard variant="minimal">
          <SAColumns columns={[12]}>
            <SABox p={{xs: 'medium', sm: 'large'}}>
              <SABox pb="xl">
                <InformationBubbleBox
                  message="Good news! We have a staff appraiser in your area that can come out and inspect the vehicle(s) in-person. This appraiser will contact you to schedule a time and place to inspect the vehicle(s)."
                  contentStyle={contentStyleTypes.Italic}
                  name={isAssociate ? 'speechBubble' : 'infoBubble'}
                />
              </SABox>
              <SABox pb="large">
                <SummaryHeader headerText="Vehicles Selected for Staff Appraisal:" />
              </SABox>
              <SABox pb="xl">
                {vehicles.map((v: Vehicle, index: number) => (
                  <SABox pb={index === vehicles.length - 1 ? 'xs' : 'medium'}>
                    <SAText
                      text={`${v.year || ''} ${v.make || ''} ${v.model || ''}${
                        v.vIN ? ' - ' + v.vIN : ''
                      }`}
                      type="heading-3"
                      weight="bold"
                    />
                  </SABox>
                ))}
              </SABox>
              <SABox pb="large">
                <SummaryHeader headerText="What to expect:" />
              </SABox>
              <SABox pl="medium">
                <List>
                  <li>
                    <SABox pb="medium">
                      <Typography data-testid="time">
                        The <b>average time</b> for a State Auto Staff Appraiser to complete an
                        inspection from start to finish is about 2 business days for scheduling,
                        travel, on-site inspection, and assessment.
                      </Typography>
                    </SABox>
                  </li>
                  <li>
                    <SABox pb="medium">
                      <Typography data-testid="duration">
                        The <b>on-site inspection can last 15-30 minutes</b> for the Staff Appraiser
                        depending on the severity of the damage.
                      </Typography>
                    </SABox>
                  </li>
                  <li>
                    <SABox pb="medium">
                      <Typography data-testid="access">
                        You don’t need to be present during the inspection, but{' '}
                        <b>the Staff Appraiser does need to be able to get inside the vehicle</b> in
                        order to open the hood, deck lid, and check doors if necessary.
                      </Typography>
                    </SABox>
                  </li>
                  <li>
                    <SABox pb="medium">
                      <Typography data-testid="prior-damages">
                        The Staff Appraiser will be looking for any damages incurred during the
                        incident, any unrelated damage prior to the incident, and the overall
                        condition of the vehicle to determine if the vehicle is considered a total
                        loss.
                      </Typography>
                    </SABox>
                  </li>
                  <li>
                    <SABox pb="medium">
                      <Typography data-testid="prep">
                        <b>You don’t need to do anything special to prepare for the inspection</b>,
                        however keeping the exterior of the vehicle clean of debris will help the
                        Staff Appraiser see some of the damages such as scratches, minor dents, hail
                        damage, etc. The better the Staff Appraiser can see the vehicle the more
                        accurate the appraisal.
                      </Typography>
                    </SABox>
                  </li>
                </List>
              </SABox>
            </SABox>
          </SAColumns>
        </SACard>
      </SABox>
      <SABox>
        <SACard variant="minimal">
          <LeftJustified pr={{md: 'xl'}}>
            <SAButton
              fullWidth={xsDown}
              label="COMPLETE MY CLAIM"
              onClick={() => onContinue()}
              textTransform="uppercase"
              variant="primary"
            />
          </LeftJustified>
        </SACard>
      </SABox>
    </SABox>
  );
};
