import React, {useContext, useEffect, useState} from 'react';
import {Typography} from '@material-ui/core';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {SATextField} from '../../common/TextField/TextField';
import {useFormContext} from 'react-hook-form';
import * as yup from 'yup';
import {
  stateValidation,
  zipCodeValidation,
  emailValidation,
  cityValidation,
  streetAddressValidation,
  OptionalPhoneNumbersSchema,
  firstNameValidation,
  lastNameValidation,
} from '../../../validations';
import {
  VehicleContactKeys,
  VehicleDetailsContext,
  VehicleTypes,
} from '../YourVehicleDetails/YourVehicleDetails';
import {ContactTypes, CustomerTypes, THIRD_PARTY_PERSONAS} from '../../../commonTypes';
import {useGetReporterAtomState, useUserAtomState} from '../../../atoms';
import {SABox, SAColumns} from '@saux/design-system-react';
import {ContactInfo} from '../../common/ContactInfo/ContactInfo';
import {ContactSections, FnolFormContact} from '../../common/ContactInfo/types';
import {makePersonaCheck} from '../../../utils/utils';

export const VehicleOwnerSchema = {
  notOwner: yup.object().shape({
    ownerId: yup.string().required('Please select the vehicle owner'),
    areYouTheOwner: yup.string(),
    radioSelection: yup.string(),
    firstName: yup.string().when('radioSelection', {
      is: (radioSelection: string) => {
        return radioSelection === ContactTypes.Person;
      },
      then: firstNameValidation().required('First Name is required'),
    }),
    lastName: yup.string().when('radioSelection', {
      is: (radioSelection: string) => {
        return radioSelection === ContactTypes.Person;
      },
      then: lastNameValidation().required('Last Name is required'),
    }),
    companyName: yup.string().when('radioSelection', {
      is: (radioSelection: string) => {
        return radioSelection === ContactTypes.Company;
      },
      then: yup.string().required('Company Name is required'),
    }),
    isVehicleARental: yup.string().when('radioSelection', {
      is: (radioSelection: string) => radioSelection === ContactTypes.Company,
      then: yup.string().required('Please select if the vehicle is a rental'),
    }),
    insuranceProvider: yup.string(),
    policyOrClaimNumber: yup.string(),
    autocompleteAddress: yup.string().nullable(),
    manualAddressEntry: yup.string().nullable(),
    address: streetAddressValidation().nullable(),
    city: cityValidation(true),
    state: stateValidation().nullable(),
    zipcode: zipCodeValidation(true),
    email: emailValidation(true),
    ...OptionalPhoneNumbersSchema,
  }),
};

export const AreYouTheOwnerTestSchema = yup.object().shape({
  otherVehicles: yup.array().of(
    yup.object().shape({
      ...VehicleOwnerSchema,
    })
  ),
  yourVehicles: yup.array().of(
    yup.object().shape({
      ...VehicleOwnerSchema,
    })
  ),
});

interface AreYouTheOwnerProps {
  setVehicleOwnerId: React.Dispatch<React.SetStateAction<string>>;
}

export const AreYouTheOwner = ({setVehicleOwnerId}: AreYouTheOwnerProps) => {
  const {fieldIndex, parentFieldName, ownerType, setVehicleContact, removeVehicleContact} =
    useContext(VehicleDetailsContext);
  const reporter = useGetReporterAtomState();
  const {register, errors} = useFormContext();
  const [ownerId, setOwnerId] = useState<string>('');
  const [areYouTheOwner, setAreYouTheOwner] = useState<string>('');
  const [showInsuranceFields, setShowInsuranceFields] = useState<boolean>(false);
  const [ownerIsCompany, setOwnerIsCompany] = useState<boolean>(false);
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType;
  const isYourVehicle = ownerType === VehicleTypes.OWNER;

  const handleOwnerChange = (formContact: FnolFormContact) => {
    const id = formContact?.fnolId || '';

    if (id) {
      setVehicleContact && setVehicleContact(VehicleContactKeys.Owner, id);

      const isThirdPartyUser = makePersonaCheck(userPersona as CustomerTypes, THIRD_PARTY_PERSONAS);

      setShowInsuranceFields(
        !isYourVehicle ||
          isThirdPartyUser ||
          (!isThirdPartyUser && isYourVehicle && !formContact.policyInfo)
      );
    } else {
      if (ownerId) {
        removeVehicleContact && removeVehicleContact(VehicleContactKeys.Owner);
      }

      setShowInsuranceFields(false);
    }

    setOwnerId(id);
    setVehicleOwnerId(id);
    setOwnerIsCompany(formContact?.contactType === ContactTypes.Company);
  };

  useEffect(() => {
    setAreYouTheOwner(
      isYourVehicle &&
        reporter?.contact?.publicID &&
        ownerId &&
        reporter.contact.publicID === ownerId
        ? 'yes'
        : 'no'
    );
  }, [ownerId, reporter, isYourVehicle]);

  return (
    <SABox pb="medium">
      <input
        type="hidden"
        value={areYouTheOwner}
        name={`${parentFieldName}[${fieldIndex}].notOwner.areYouTheOwner`}
        ref={register()}
        data-testid="hiddenAreYouTheOwner"
      />
      <SABox pt="medium">
        <Typography variant="body1">Who is the owner of the vehicle?</Typography>
      </SABox>
      <SABox pt="medium">
        <ContactInfo
          section={
            isYourVehicle ? ContactSections.InsuredVehicleOwner : ContactSections.OtherVehicleOwner
          }
          parentFieldName={`${parentFieldName}[${fieldIndex}].notOwner`}
          selectProps={{
            name: 'ownerId',
            label: 'Vehicle Owner',
            id: `${parentFieldName}-${(fieldIndex || 0) + 1}-ownerId`,
            inputProps: {'aria-label': `${parentFieldName}-${(fieldIndex || 0) + 1}-Vehicle Owner`},
          }}
          errorsObj={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notOwner}
          onContactChange={handleOwnerChange}
          hiddenFields={{
            contactType: {render: true, name: 'radioSelection'},
            email: {render: true},
            phoneNumbers: {render: true},
            address: {render: true},
            city: {render: true},
            state: {render: true},
            zipCode: {render: true, name: 'zipcode'},
          }}
        />
      </SABox>
      {showInsuranceFields && (
        <SABox pt="large">
          <SAColumns columns={{xs: [12, 12], sm: [6, 6], md: [4, 4]}} spacing="medium">
            <SATextField
              hasWhiteFields
              inputRef={register()}
              id="insurance-owner"
              label="Insurance Provider"
              name={`${parentFieldName}[${fieldIndex}].notOwner.insuranceProvider`}
              InputLabelProps={{'aria-labelledby': 'insurance-owner'}}
              error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notOwner?.hasOwnProperty(
                'insuranceProvider'
              )}
              helperText={
                errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notOwner?.insuranceProvider
                  ?.message || 'optional'
              }
            />
            <SATextField
              hasWhiteFields
              inputRef={register()}
              id="policy-number-owner"
              label="Policy/Claim Number"
              name={`${parentFieldName}[${fieldIndex}].notOwner.policyOrClaimNumber`}
              InputLabelProps={{'aria-labelledby': 'policy-number-owner'}}
              error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notOwner?.hasOwnProperty(
                'policyOrClaimNumber'
              )}
              helperText={
                errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notOwner?.policyOrClaimNumber
                  ?.message || 'optional'
              }
            />
          </SAColumns>
        </SABox>
      )}
      {ownerIsCompany && (
        <>
          <SABox pt="large">
            <Typography variant="body1">Is this a rental vehicle?</Typography>
          </SABox>
          <SABox pt="medium">
            <YesNoToggle
              name={`${parentFieldName}[${fieldIndex}].notOwner.isVehicleARental`}
              hasError={errors?.[parentFieldName || '']?.[
                fieldIndex || 0
              ]?.notOwner?.hasOwnProperty('isVehicleARental')}
              errorMessage={
                errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notOwner?.isVehicleARental
                  ?.message
              }
              testId="test-not-a-rental"
              yesButtonTestId="test-yes-a-rental"
              defaultValue="no"
            />
          </SABox>
        </>
      )}
    </SABox>
  );
};
