import React, {useReducer, createContext, ReactElement, useContext} from 'react';
import {ContactInfoModal} from '../ContactInfo/ContactInfoModal/ContactInfoModal';
import UploadModal from '../UploadModal/UploadModal';

export enum ModalStates {
  On = 'MODAL_STATE_ON',
  Off = 'MODAL_STATE_OFF',
}

export enum ModalTypes {
  Upload = 'UPLOAD_MODAL',
  ContactInfoModal = 'CONTACT_INFO_MODAL',
  None = 'NO_MODAL',
}

const initialState = {
  open: false,
  Modal: <div />,
};

export const getModal = (name: ModalTypes): ((props: any) => JSX.Element) => {
  switch (name) {
    case ModalTypes.Upload:
      return UploadModal;
    case ModalTypes.ContactInfoModal:
      return ContactInfoModal;
    case ModalTypes.None:
      return () => <div />;
    default:
      return () => <div />;
  }
};

const reducer = (
  state: {open: boolean; Modal: ReactElement},
  action: {type: ModalStates; name: ModalTypes; props: any}
) => {
  switch (action.type) {
    case ModalStates.On:
      return {
        ...state,
        open: true,
        Modal: React.createElement(getModal(action.name), {...action.props}),
      };
    case ModalStates.Off:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export interface ModalControllerContextProps {
  open?: boolean;
  Modal: ReactElement;
  dispatchModal?: Function;
}

const ModalControllerContext = createContext<ModalControllerContextProps>(initialState);

export interface ModalControllerProviderProps {
  children: ReactElement;
}

export const ModalControllerProvider = ({children}: ModalControllerProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ModalControllerContext.Provider value={{...state, dispatchModal: dispatch}}>
      {children}
    </ModalControllerContext.Provider>
  );
};

export const useModalController = (): ModalControllerContextProps => {
  return useContext(ModalControllerContext);
};
