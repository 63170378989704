import React, {ReactChild} from 'react';
import {UserPersonaContext, UserPersonaContextProps} from '../Providers/Providers';

export interface WithUserPersonaContextProps {
  children: ReactChild;
}

export const WithUserPersonaContext = ({
  userPersona,
  children,
}: UserPersonaContextProps & WithUserPersonaContextProps) => (
  <UserPersonaContext.Provider value={{userPersona}}>{children}</UserPersonaContext.Provider>
);
