import React from 'react';
import styled from 'styled-components';
import {useModalAtomValue} from '../../../atoms';
import {BaseSwitch} from '../BaseSwitch/BaseSwitch';
import {getModal, ModalTypes} from '../Providers/ModalController';

const ModalBackdrop = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(4, 30, 65, 0.6);
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
`;

interface ModalAtomControllerProps {
  modalKey: ModalTypes;
}

export const ModalAtomController = ({modalKey}: ModalAtomControllerProps) => {
  const modalAtomState = useModalAtomValue(modalKey);
  const Modal = getModal(modalKey);

  return (
    <BaseSwitch
      matches={modalAtomState.open}
      then={
        <BaseSwitch
          matches={modalAtomState.useBackdrop}
          then={
            <ModalBackdrop data-testid="modalBackdrop">
              {React.createElement(Modal, {...modalAtomState.props})}
            </ModalBackdrop>
          }
          otherwise={React.createElement(Modal, {...modalAtomState.props})}
        />
      }
    />
  );
};
