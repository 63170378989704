import {makeStyles} from '@material-ui/core';
import React from 'react';
import {DynamicRadioList, RadioOption} from '../DynamicRadioList/DynamicRadioList';

export interface YesNoUnkProps {
  defaultValue?: any;
  name: string;
  onChange?: any;
  disabled?: boolean;
  dontKnowTestId?: string;
  hasErrors?: boolean;
  errorMessage?: string;
  yesTestId?: string;
  noTestId?: string;
}

const useStyles = makeStyles(theme => ({
  fieldset: {
    border: '0 none',
  },
}));

export const YesNoUnk = (props: YesNoUnkProps) => {
  const YES_NO_UNK_OPTIONS: RadioOption[] = [
    {
      label: 'Yes',
      value: 'yes',
      testId: props.yesTestId,
    },
    {
      label: 'No',
      value: 'no',
      testId: props.noTestId,
    },
    {
      label: "Don't know",
      value: '',
      testId: props.dontKnowTestId,
    },
  ];

  const onChangeHandler = (value: string) => {
    props.onChange && props.onChange(value);
  };

  const classes = useStyles();

  return (
    <fieldset className={classes.fieldset}>
      <legend></legend>
      <DynamicRadioList
        name={props.name || ''}
        options={YES_NO_UNK_OPTIONS}
        defaultValue={props.defaultValue}
        onChange={onChangeHandler}
        row
        hasErrors={props.hasErrors}
        errorMessage={props.errorMessage}
        disabled={props.disabled}
        width="420px"
        mobileWidth="175px"
      />
    </fieldset>
  );
};
