import {SABox} from '@saux/design-system-react';
import React from 'react';
import {useGetNoticeOnlyAtomState, useSetNoticeOnlyAtomState} from '../../../atoms';
import {CheckboxWithLabel} from '../CheckboxWithLabel/CheckboxWithLabel';

export const noticeOnlyText = 'This claim is being reported for Notice Only purposes';

export const NoticeOnly = () => {
  const isNoticeOnly = useGetNoticeOnlyAtomState();
  const setNoticeOnly = useSetNoticeOnlyAtomState();

  return (
    <SABox>
      <CheckboxWithLabel
        name="noticeOnly"
        formControlLabelProps={{
          disabled: false,
          label: noticeOnlyText,
        }}
        checkboxProps={{
          defaultChecked: isNoticeOnly,
          onChange: (e: any) => setNoticeOnly(e.target.checked),
          pointerEvent: 'stroke',
        }}
      />
    </SABox>
  );
};
