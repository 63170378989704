import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const Download = () => (
  <SvgIcon
    width="24px"
    height="22px"
    viewBox="0 0 24 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs xmlns="http://www.w3.org/2000/svg">
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.094118 0 0 0 0 0.619608 0 0 0 0 0.800000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Summary---final-2-Copy" transform="translate(-1148.000000, -199.000000)">
        <g id="Group-3" transform="translate(1048.000000, 201.000000)">
          <g id="DownloadIcon" transform="translate(100.000000, 0.000000)" filter="url(#filter-1)">
            <g>
              <path
                d="M24,12.72 L24,17.52 C24,18.8454834 22.9254834,19.92 21.6,19.92 L2.4,19.92 C1.0745166,19.92 -1.16994273e-15,18.8454834 0,17.52 L0,12.72 L2.4,12.72 L2.4,16.92 C2.4,17.2182338 2.61758961,17.4656468 2.90267688,17.512147 L3,17.52 L21,17.52 C21.2982338,17.52 21.5456468,17.3024104 21.592147,17.0173231 L21.6,16.92 L21.6,12.72 L24,12.72 Z"
                id="Combined-Shape"
                fill="#189ECC"
              />
              <line
                x1="12"
                y1="0"
                x2="12"
                y2="12"
                id="Line-5"
                stroke="#189ECC"
                strokeWidth="2.4"
                fill="#D8D8D8"
                strokeLinecap="square"
              />
              <line
                x1="14.16"
                y1="7.68"
                x2="14.16"
                y2="13.68"
                id="Line-5"
                stroke="#189ECC"
                strokeWidth="2.4"
                fill="#D8D8D8"
                strokeLinecap="square"
                transform="translate(14.160000, 10.680000) rotate(45.000000) translate(-14.160000, -10.680000) "
              />
              <line
                x1="7.83867966"
                y1="8.55867966"
                x2="12.0813203"
                y2="12.8013203"
                id="Line-5"
                stroke="#189ECC"
                strokeWidth="2.4"
                fill="#D8D8D8"
                strokeLinecap="square"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
