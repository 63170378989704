import React from 'react';
import {Box, Typography, makeStyles, Grid} from '@material-ui/core';
import {Car} from '../DamageSelector/Car';
import {ImpactPoint, VehicleIncident, Contact} from '../../common/Summary/types';
import {DriverDetails} from './DriverDetails';
import {OwnerDetails} from './OwnerDetails';
import {PassengerSummary} from './PassengerSummary';
import {AddressSummary} from './AddressSummary';
import {impactPointLabels} from '../DamageSelector/DamageSelector';
import {VehicleTypes} from '../YourVehicleDetails/YourVehicleDetails';
import {SummaryField} from '../../common/Summary/components/SummaryField/SummaryField';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';
import {VehicleCopartProps} from '../../common/Copart/types';
import {capitalize} from '../../../utils/utils';
import {useUserAtomState} from '../../../atoms';
import {CustomerTypes} from '../../../commonTypes';
import {PdfSection} from '../../common/Summary/components/PdfSection/PdfSection';
import {SUMMARY_PDF_ID} from '../../pdf/constants';

const useStyles = makeStyles(theme => ({
  vehicleSvg: {
    position: 'relative',
    left: '-120px',
    top: '-70px',
    transform: 'scale(.8)',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: '-260px',
      top: '-130px',
      transform: 'scale(.4)',
    },
  },
  svgContainer: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      height: '200px',
    },
  },
}));

export interface VehicleSummaryProps {
  vehicleIncident: VehicleIncident;
  index: number;
  ownerType: string;
  vehiclePreferences?: (VehicleCopartProps & VehicleIncident)[];
  quickEstimateVehicles: VehicleIncident[];
  staffAppraiserSent?: boolean;
}

const vehicleLocationFixer = (str: string) => {
  switch (str) {
    case 'repairShop':
      return 'Repair Shop';
    case 'towYard':
      return 'Tow Yard';
    case 'otherBusiness':
      return 'Other Business';
    case 'otherLocation':
      return 'Other Location';
  }
};
export const VehicleSummary = (props: VehicleSummaryProps) => {
  const DEFAULT_FALSE = {value: false};
  const classes = useStyles();
  const preferences =
    props.vehiclePreferences &&
    props.vehiclePreferences.find(
      (incident: VehicleCopartProps & VehicleIncident) =>
        incident.vehicle?.publicID === props.vehicleIncident.vehicle?.publicID
    );

  const didTheVehicleRollOver = props.vehicleIncident.impactPoints
    ? props.vehicleIncident.impactPoints.filter((impactPoint: ImpactPoint) => {
        return impactPoint.label === 'vehicleRolledOver';
      })[0] || DEFAULT_FALSE
    : DEFAULT_FALSE;

  const doesTheVehicleHaveWaterDamageUpToTheEngine = props.vehicleIncident.impactPoints
    ? props.vehicleIncident.impactPoints.filter((impactPoint: ImpactPoint) => {
        return impactPoint.label === 'vehicleHasEngineWaterDamage';
      })[0] || DEFAULT_FALSE
    : DEFAULT_FALSE;

  const wasTheUndercarriageDamaged = props.vehicleIncident.impactPoints
    ? props.vehicleIncident.impactPoints.filter((impactPoint: ImpactPoint) => {
        return impactPoint.label === 'undercarriage';
      })[0] || DEFAULT_FALSE
    : DEFAULT_FALSE;

  const isHailDamage = props.vehicleIncident.impactPoints
    ? props.vehicleIncident.impactPoints.filter((impactPoint: ImpactPoint) => {
        return impactPoint.label === 'hail';
      })[0] || DEFAULT_FALSE
    : DEFAULT_FALSE;

  const [userAtomState] = useUserAtomState();
  const persona = userAtomState?.gettingStarted?.customerType;
  const whosePossesion = persona === CustomerTypes.Associate ? "customer's" : 'your';
  const inYourPossesion = props.vehicleIncident?.vehicleInCustomerPossession;
  const driver = props.vehicleIncident?.driver;
  const displayDriverInfo =
    driver?.firstName || driver?.injured === true || driver?.injured === false;
  const vehicleLocationSelection = props.vehicleIncident?.vehicleLocationSelection;
  const quickEstimateVehicleData = props.quickEstimateVehicles?.find(
    (vehicle: VehicleIncident) => vehicle.publicID === props.vehicleIncident.publicID
  );
  const quickEstimateRequested = quickEstimateVehicleData?.quickEstimateRequested;

  return (
    <Box>
      <PdfSection className={SUMMARY_PDF_ID}>
        <SummaryHeader
          headerText={`${
            props.ownerType === VehicleTypes.OWNER ? 'Your' : 'Other'
          } Vehicle Details`}
        />
        <Box pt={3}>
          <Grid container spacing={3}>
            {props.vehicleIncident.vehicle && (
              <Grid item xs={12}>
                <SummaryField label="Vehicle Details">
                  <Typography>
                    {props.vehicleIncident?.vehicle.year +
                      ' ' +
                      props?.vehicleIncident?.vehicle.make +
                      ' ' +
                      props?.vehicleIncident?.vehicle.model}
                  </Typography>
                </SummaryField>
              </Grid>
            )}
            {quickEstimateRequested && props.vehicleIncident.quickEstimateEligible && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label="Quick Estimate Accepted">
                  <Typography data-testid="qeAccepted">
                    {capitalize(quickEstimateRequested)}
                  </Typography>
                </SummaryField>
              </Grid>
            )}
            {props.vehicleIncident.staffAppraiserEligible && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label="Staff Appraiser Sent">
                  <Typography data-testid="staffSent">
                    {props.staffAppraiserSent ? 'Yes' : 'No'}
                  </Typography>
                </SummaryField>
              </Grid>
            )}
            {preferences?.copartPreferences && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label="Copart (towing service) requested?">
                  <Typography>
                    {capitalize(preferences.copartPreferences.acceptCopart || 'no')}
                  </Typography>
                </SummaryField>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <SummaryField label="Did the airbags deploy?">
                <Typography data-testid="DidTheAirBagsDeploy">
                  {props?.vehicleIncident?.airbagsDeployed ? 'Yes' : 'No'}
                </Typography>
              </SummaryField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SummaryField label="Did the car rollover during the incident?">
                <Typography data-testid="DidTheCarRollOver">
                  {didTheVehicleRollOver?.value ? 'Yes' : 'No'}
                </Typography>
              </SummaryField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SummaryField label="Was there any water damage up to the engine?">
                <Typography data-testid="WasThereAnyWaterDamage">
                  {doesTheVehicleHaveWaterDamageUpToTheEngine?.value ? 'Yes' : 'No'}
                </Typography>
              </SummaryField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SummaryField label="Is the vehicle driveable?">
                <Typography data-testid="IsTheVehicleDriveable">
                  {props?.vehicleIncident?.vehicleDriveable ? 'Yes' : 'No'}
                </Typography>
              </SummaryField>
            </Grid>
            {inYourPossesion && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label={`Is the vehicle in ${whosePossesion} possession?`}>
                  <Typography data-testid="isInYourPossesion">
                    {capitalize(inYourPossesion)}
                  </Typography>
                </SummaryField>
              </Grid>
            )}
            {vehicleLocationSelection && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label={`Where is the vehicle located?`}>
                  <Typography data-testid="whereVehicle">
                    {vehicleLocationFixer(vehicleLocationSelection)}
                  </Typography>
                </SummaryField>
              </Grid>
            )}
            {props?.vehicleIncident?.companyName && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label={`Name of the business`}>
                  <Typography data-testid="businessName">
                    {props?.vehicleIncident?.companyName}
                  </Typography>
                </SummaryField>
              </Grid>
            )}
            {props?.vehicleIncident?.firstName && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label={`Vehicle location first name`}>
                  <Typography data-testid="whereVehicleFName">
                    {props?.vehicleIncident?.firstName}
                  </Typography>
                </SummaryField>
              </Grid>
            )}
            {props?.vehicleIncident?.lastName && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label={`Vehicle location last name`}>
                  <Typography data-testid="whereVehicleLName">
                    {props?.vehicleIncident?.lastName}
                  </Typography>
                </SummaryField>
              </Grid>
            )}
            {props?.vehicleIncident?.phoneNumber && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label={`Vehicle Location phone number`}>
                  <Typography data-testid="whereVehiclePhNo">
                    {props?.vehicleIncident?.phoneNumber}
                  </Typography>
                </SummaryField>
              </Grid>
            )}
            <AddressSummary
              label="Address of the vehicle location"
              addressLine1={props?.vehicleIncident?.locationAddress?.addressLine1}
              city={props?.vehicleIncident?.locationAddress?.city}
              state={props?.vehicleIncident?.locationAddress?.state}
              postalCode={props?.vehicleIncident?.locationAddress?.postalCode}
            />
          </Grid>
        </Box>
      </PdfSection>
      <Box pt={3}>
        <Grid container>
          {props.vehicleIncident.impactPoints && !isHailDamage?.value && (
            <Grid item xs={12} md={12}>
              <PdfSection className={SUMMARY_PDF_ID}>
                <SummaryField label="Vehicle Damaged Areas">
                  <>
                    {wasTheUndercarriageDamaged?.value && <Typography>Undercarriage</Typography>}
                    {props.vehicleIncident.primaryImpactPoints &&
                      impactPointLabels.hasOwnProperty(
                        props.vehicleIncident.primaryImpactPoints
                      ) && (
                        <Box py={3}>
                          <SummaryField label="Initial Impact Point">
                            <Typography>
                              {impactPointLabels[props.vehicleIncident.primaryImpactPoints]}
                            </Typography>
                          </SummaryField>
                        </Box>
                      )}
                    <Box className={classes.svgContainer}>
                      <Car
                        className={classes.vehicleSvg}
                        index={props.index}
                        leftFront={
                          props.vehicleIncident.impactPoints.find(
                            (x: any) => x.label === 'leftFront'
                          )?.value || false
                        }
                        leftFrontPillar={
                          props.vehicleIncident.impactPoints.find(
                            (x: any) => x.label === 'leftFrontPillar'
                          )?.value || false
                        }
                        leftTBone={
                          props.vehicleIncident.impactPoints.find(
                            (x: any) => x.label === 'leftTBone'
                          )?.value || false
                        }
                        leftQuarterPost={
                          props.vehicleIncident.impactPoints.find(
                            (x: any) => x.label === 'leftQuarterPost'
                          )?.value || false
                        }
                        leftRear={
                          props.vehicleIncident.impactPoints.find(
                            (x: any) => x.label === 'leftRear'
                          )?.value || false
                        }
                        front={
                          props.vehicleIncident.impactPoints.find((x: any) => x.label === 'front')
                            ?.value || false
                        }
                        rightFront={
                          props.vehicleIncident.impactPoints.find(
                            (x: any) => x.label === 'rightFront'
                          )?.value || false
                        }
                        rightFrontPillar={
                          props.vehicleIncident.impactPoints.find(
                            (x: any) => x.label === 'rightFrontPillar'
                          )?.value || false
                        }
                        rightTBone={
                          props.vehicleIncident.impactPoints.find(
                            (x: any) => x.label === 'rightTBone'
                          )?.value || false
                        }
                        rightQuarterPost={
                          props.vehicleIncident.impactPoints.find(
                            (x: any) => x.label === 'rightQuarterPost'
                          )?.value || false
                        }
                        rightRear={
                          props.vehicleIncident.impactPoints.find(
                            (x: any) => x.label === 'rightRear'
                          )?.value || false
                        }
                        rear={
                          props.vehicleIncident.impactPoints.find((x: any) => x.label === 'rear')
                            ?.value || false
                        }
                      ></Car>
                    </Box>
                  </>
                </SummaryField>
              </PdfSection>
            </Grid>
          )}
          {props?.vehicleIncident?.owner?.displayName && (
            <Grid item xs={12} md={12}>
              <PdfSection className={SUMMARY_PDF_ID}>
                <Box mt={3}>
                  <OwnerDetails owner={props.vehicleIncident.owner} />
                </Box>
              </PdfSection>
            </Grid>
          )}
          {driver && displayDriverInfo && (
            <Grid item xs={12} md={12}>
              <PdfSection className={SUMMARY_PDF_ID}>
                <Box mt={3}>
                  <DriverDetails driver={driver} />
                </Box>
              </PdfSection>
            </Grid>
          )}
          {props?.vehicleIncident?.passengers &&
            props.vehicleIncident.passengers.map((passenger: Contact, index: number) => (
              <Grid item xs={12}>
                <PdfSection className={SUMMARY_PDF_ID}>
                  <Box mt={3}>
                    <PassengerSummary passenger={passenger} index={index} />
                  </Box>
                </PdfSection>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};
