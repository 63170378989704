import React from 'react';
import {withStyles, RadioProps, Radio} from '@material-ui/core';

export const SARadio = withStyles({
  root: {
    color: '#189ecc',
    '&$checked': {
      color: '#189ecc',
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);
