import React from 'react';
import {Grid, Box, Typography} from '@material-ui/core';
import {useFormContext, useWatch} from 'react-hook-form';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {SABox, SAIcon, SAIcons, SAIconSize, SATooltip} from '@saux/design-system-react';
import {SATextField} from '../TextField/TextField';
import {useUserAtomState} from '../../../atoms';

export const RideShareOrDelivery = () => {
  const formMethods = useFormContext();
  const {register, errors, control} = formMethods;
  const isDelivery = useWatch({control, name: `incidentDetails.delivery`});
  const isInsured = useWatch({control, name: `incidentDetails.isInsuredBusiness`});
  const [userAtomState] = useUserAtomState();
  const isCommercialAuto = !!userAtomState?.draftClaimResponse?.result?.lobs?.commercialAuto;

  return (
    <>
      {!isCommercialAuto ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex">
              <Typography variant="body1">
                Was your vehicle being used for business, delivery or rideshare purposes?
              </Typography>
              <SATooltip
                title="Business, delivery, or rideshare purposes."
                wrapperTestId="title-test-wrapper"
                testId="title-test"
                icon="help"
                content={<SABox>Examples include Uber, Lyft, etc</SABox>}
                variant="dark"
                placement="bottom"
              >
                <SABox marginLeft="small">
                  <SAIcon icon={SAIcons.help} size={SAIconSize.medium} colorVariant="dark" />
                </SABox>
              </SATooltip>
            </Box>
            <Box>
              <YesNoToggle
                name="incidentDetails.rideshareOrDelivery"
                hasError={errors?.hasOwnProperty('rideshareOrDelivery')}
                errorMessage={errors?.incidentDetails?.rideshareOrDelivery?.message}
                testId="noRideshareOrDelivery"
                yesButtonTestId="yesRideshareOrDelivery"
                inputRef={register}
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex">
              <Typography variant="body1">Was the vehicle being used for Rideshare?</Typography>
              <SATooltip
                title="Whether the vehicle is used for Rideshare purposes or not"
                wrapperTestId="title-test-wrapper"
                testId="title-test"
                icon="help"
                content={<SABox>Examples include Uber, Lyft, etc</SABox>}
                variant="dark"
                placement="bottom"
              >
                <SABox marginLeft="small">
                  <SAIcon icon={SAIcons.help} size={SAIconSize.medium} colorVariant="dark" />
                </SABox>
              </SATooltip>
            </Box>
            <Box>
              <YesNoToggle
                name="incidentDetails.rideshare"
                hasError={errors?.hasOwnProperty('rideshare')}
                errorMessage={errors?.incidentDetails?.rideshare?.message}
                testId="noRideshare"
                yesButtonTestId="yesRideshare"
                inputRef={register}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex">
              <Typography variant="body1">Was the vehicle being used for Delivery?</Typography>
              <SATooltip
                title="Whether the vehicle is used for Delivery purposes or not"
                wrapperTestId="title-test-wrapper"
                testId="title-test"
                icon="help"
                content={<SABox>Examples include Uber, Lyft, etc</SABox>}
                variant="dark"
                placement="bottom"
              >
                <SABox marginLeft="small">
                  <SAIcon icon={SAIcons.help} size={SAIconSize.medium} colorVariant="dark" />
                </SABox>
              </SATooltip>
            </Box>
            <Box>
              <YesNoToggle
                name="incidentDetails.delivery"
                hasError={errors?.hasOwnProperty('delivery')}
                errorMessage={errors?.incidentDetails?.delivery?.message}
                testId="noDelivery"
                yesButtonTestId="yesDelivery"
                inputRef={register}
              />
            </Box>
          </Grid>
          {isDelivery === 'yes' && (
            <Grid item xs={12}>
              <Box display="flex">
                <Typography variant="body1">
                  Was the Delivery in the normal course of your Insured Business?
                </Typography>
                <SATooltip
                  title="Was the Delivery in the normal course of your Insured Business?"
                  wrapperTestId="title-test-wrapper"
                  testId="title-test"
                  icon="help"
                  content={<SABox>Yes/No</SABox>}
                  variant="dark"
                  placement="bottom"
                >
                  <SABox marginLeft="small">
                    <SAIcon icon={SAIcons.help} size={SAIconSize.medium} colorVariant="dark" />
                  </SABox>
                </SATooltip>
              </Box>
              <Box>
                <YesNoToggle
                  name="incidentDetails.isInsuredBusiness"
                  hasError={errors?.hasOwnProperty('isInsuredBusiness')}
                  errorMessage={errors?.incidentDetails?.isInsuredBusiness?.message}
                  testId="noInsuredBusiness"
                  yesButtonTestId="yesInsuredBusiness"
                  inputRef={register}
                />
              </Box>
            </Grid>
          )}
          {isInsured === 'no' && (
            <Grid item xs={12}>
              <Box display="flex">
                <Box pb={2}>
                  <Typography variant="body1">What company was the delivery made for?</Typography>
                </Box>
              </Box>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <SATextField
                      name="incidentDetails.deliveryBusinessName"
                      label="Please enter the Company name"
                      id="deliveryBusinessName"
                      inputRef={register()}
                      InputLabelProps={{'aria-labelledby': 'deliveryBusinessName'}}
                      error={errors?.incidentDetails?.hasOwnProperty('deliveryBusinessName')}
                      helperText={
                        errors?.incidentDetails?.deliveryBusinessName?.message ||
                        '32-character limit'
                      }
                      autoFocus
                      multiline
                      rowsMax={5}
                      showCharacterCount
                      characterLimit={32}
                      inputProps={{className: 'deliveryBusinessNameDescription'}}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};
