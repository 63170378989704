import React from 'react';

export const AlertIcon = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="50" cy="50" r="50" fill="#FFF6E9" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M46.9342 29.7564C47.8702 29.2294 48.9263 28.9525 50.0004 28.9525C51.0746 28.9525 52.1306 29.2294 53.0667 29.7564C54.0027 30.2833 54.7871 31.0427 55.3442 31.9611L55.3502 31.971L72.996 61.4293L73.0129 61.4581C73.5586 62.4032 73.8474 63.4747 73.8504 64.566C73.8535 65.6573 73.5708 66.7304 73.0303 67.6785C72.4899 68.6266 71.7106 69.4167 70.7701 69.9701C69.8295 70.5236 68.7604 70.8211 67.6692 70.8331L67.6463 70.8334L32.3317 70.8332C31.2404 70.8212 30.1713 70.5236 29.2308 69.9701C28.2902 69.4167 27.511 68.6266 26.9705 67.6785C26.4301 66.7304 26.1474 65.6573 26.1504 64.566C26.1535 63.4747 26.4422 62.4032 26.9879 61.4581L27.0049 61.4293L44.6507 31.971L46.4379 33.0415L44.6567 31.9611C45.2138 31.0427 45.9982 30.2833 46.9342 29.7564ZM48.2217 34.1179L30.5894 63.5537C30.4119 63.8658 30.3181 64.2185 30.3171 64.5776C30.316 64.9414 30.4103 65.2991 30.5904 65.6152C30.7706 65.9312 31.0303 66.1946 31.3439 66.379C31.6546 66.5619 32.0075 66.661 32.3679 66.6666H67.6329C67.9934 66.661 68.3462 66.5619 68.657 66.379C68.9705 66.1946 69.2303 65.9312 69.4104 65.6152C69.5906 65.2991 69.6848 64.9414 69.6838 64.5776C69.6828 64.2185 69.589 63.8659 69.4116 63.5538L51.7817 34.122C51.7808 34.1207 51.78 34.1193 51.7792 34.1179C51.5936 33.8136 51.3331 33.562 51.0225 33.3871C50.7105 33.2115 50.3585 33.1192 50.0004 33.1192C49.6424 33.1192 49.2904 33.2115 48.9784 33.3871C48.6678 33.562 48.4072 33.8136 48.2217 34.1179Z"
      fill="#F5A524"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M50.0003 41.6666C51.1509 41.6666 52.0837 42.5994 52.0837 43.75V52.0833C52.0837 53.2339 51.1509 54.1666 50.0003 54.1666C48.8497 54.1666 47.917 53.2339 47.917 52.0833V43.75C47.917 42.5994 48.8497 41.6666 50.0003 41.6666Z"
      fill="#F5A524"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M47.917 60.4167C47.917 59.2661 48.8497 58.3334 50.0003 58.3334H50.0212C51.1718 58.3334 52.1045 59.2661 52.1045 60.4167C52.1045 61.5673 51.1718 62.5 50.0212 62.5H50.0003C48.8497 62.5 47.917 61.5673 47.917 60.4167Z"
      fill="#F5A524"
    />
  </svg>
);
