import React from 'react';

export const NewAdjusterMail = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.73561 1H16.6205C17.5751 1 18.3561 1.78103 18.3561 2.73561V13.1493C18.3561 14.1039 17.5751 14.8849 16.6205 14.8849H2.73561C1.78103 14.8849 1 14.1039 1 13.1493V2.73561C1 1.78103 1.78103 1 2.73561 1Z"
        stroke="#0173C6"
        strokeWidth="1.73561"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3561 2.73438L15.4127 4.79479C11.9695 7.20503 7.38665 7.20503 3.94345 4.79479L1 2.73438"
        stroke="#0173C6"
        strokeWidth="1.73561"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
