import React, {ReactElement, useEffect} from 'react';
import {SADialog, SADialogProps} from '../SADialog/SADialog';
import {DialogProps, DialogContentText, Grid, Box, makeStyles} from '@material-ui/core';
import {Icon} from '../Icon/Icon';
import {trackEvent} from '../../../services/analytics';
import {LogRocket} from '../../../services/logRocket';

export interface ErrorDialogProps {
  errorMsg: Element | ReactElement | string;
  disableEscapeKeyDown?: boolean;
  onEscapeKeyDown?: () => void;
}

const useStyles = makeStyles(theme => ({
  dialogHeaderClass: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '15px',
    },
  },
  backgroundRootClass: {
    backgroundColor: 'rgb(4, 30, 65, 0.5)',
  },
  mobileContentClass: {
    color: '#000000',
    [theme.breakpoints.down('xs')]: {
      lineHeight: 1.3,
    },
  },
}));

export const ErrorDialog = (props: DialogProps & SADialogProps & ErrorDialogProps) => {
  const {dialogHeaderClass, backgroundRootClass, mobileContentClass} = useStyles();

  useEffect(() => {
    if (props.open) {
      trackEvent({
        category: 'Error Modal',
        label: 'Error Modal Displayed',
      });

      LogRocket.track('Error Modal Displayed');
    }
  }, []);

  const errorDialogOnClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    trackEvent({
      category: 'Error Modal CTA Clicked',
      label: 'Back To Policy Search Page',
    });

    props.onClick && props.onClick(event);
  };

  return (
    <SADialog
      {...props}
      onClick={errorDialogOnClickHandler}
      disableBackdropClick
      disableEscapeKeyDown={props.disableEscapeKeyDown}
      onEscapeKeyDown={props.onEscapeKeyDown}
      BackdropProps={{classes: {root: backgroundRootClass}}}
      header={
        <Grid container>
          <Grid item xs={1}>
            <Box position="relative" top="2px">
              <Icon name="warning" />
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Box className={dialogHeaderClass}>{props.header}</Box>
          </Grid>
        </Grid>
      }
    >
      <DialogContentText>
        <Box className={mobileContentClass}>{props.errorMsg}</Box>
      </DialogContentText>
    </SADialog>
  );
};
