import {yupResolver} from '@hookform/resolvers';
import {
  SAButton as SACButton,
  SABox,
  SACard,
  SAColumns,
  SASpinner,
  SAText,
  SAUX360Theme,
  BoxProps,
} from '@saux/design-system-react';
import {format, formatISO, isToday, startOfTomorrow} from 'date-fns';
import React, {useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styled, {css} from 'styled-components';
import * as yup from 'yup';
import {returnRecentDuplicateClaims, scrollToRef} from '../../utils/utils';
import {SADatePicker} from '../common/DatePicker/DatePicker';
import {SATimePicker} from '../common/TimePicker/TimePicker';
import {Icon} from '../common/Icon/Icon';
import {useMediaQuery, useTheme} from '@material-ui/core';
import {draftClaim, duplicateCheck, verifyPolicy, VerifyPolicyPayload} from '../../services';
import Cookies from 'js-cookie';
import {AlertPanel} from '../common/AlertPanel/AlertPanel';
import {
  useInitializeContactListAtomState,
  UserAtomStateValue,
  useSetVehicleOptionsAtomState,
  useUserAtomState,
} from '../../atoms';
import {LOB_PROPS} from '../auto/GettingStarted/GettingStartedEntry';
import {PhoneHyperlink} from '../common/PhoneHyperlink/PhoneHyperlink';
import {DuplicateClaimDialog} from '../common/DuplicateClaimDialog/DuplicateClaimDialog';
import {navigateDefaultReplace} from '../../pages/utils';
import {Snowplow} from '../../pages/utils/snowplow';
import {COMMERCIAL_AUTO_TYPES, CustomerTypes, UserData} from '../../commonTypes';
import {LogRocket} from '../../services/logRocket';
import {useFeatureFlags} from '../common/Providers/Providers';
import {COMMERCIAL_AUTO_VEHICLE_SELECTION_ROUTE, MAINTENANCE_ROUTE} from '../../routes/paths';
import {navigate} from '@reach/router';

const SAContainerCard = styled(SACard)`
  width: 100%;
  max-width: 1000px;
`;
const SAContainerBox = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10% 3%;
`;
const SATimeBox = styled(SABox)`
  max-width: 250px;
`;
const SADateBox = styled(SABox)`
  max-width: 400px;
`;
const SAInfoBox = styled(SABox)`
  display: flex;
  flex-direction: column;
`;
const SAButtonBox = styled(SABox)`
  display: flex;
  justify-content: flex-end;
`;
const SAPolicyNumberBox = styled(SABox)`
  display: flex;
  align-items: center;
`;
interface ISACBox {
  maxWidth?: string;
  height?: string;
  width?: string;
  justifyContent?: string;
  flexDirection?: string;
}
const SACBox = styled(SABox)<BoxProps & ISACBox>`
  ${(props: ISACBox) => {
    return css`
      max-width: ${props.maxWidth};
      height: ${props.height};
      width: ${props.width};
      flex-direction: ${props.flexDirection ? props.flexDirection : 'row'};
      justify-content: ${props.justifyContent ? props.justifyContent : 'flex-start'};
    `;
  }}
`;
const SpinnerContainer = styled.div`
  margin-left: 10px;
  svg:last-child {
    margin-left: 0;
  }
`;

const spinner = (
  <SpinnerContainer>
    <SASpinner variant="circular-continuous" color={SAUX360Theme.colors.blueGray50} size="20px" />
  </SpinnerContainer>
);
export interface AuthInsuredForm {
  lossDate: number | Date;
  lossTime: number | Date;
}

interface AuthInsuredProps {
  firstName?: string;
  lastName?: string;
}

const AuthInsuredFormSchema = yup.object().shape({
  lossDate: yup
    .date()
    .typeError('Must be a valid date')
    .max(startOfTomorrow(), "The Date of Loss can not be a later date than today's date")
    .required('Must be a valid date')
    .nullable(),
  lossTime: yup.date().when('lossDate', {
    is: (value: string | number | Date) => isToday(new Date(value)),
    then: yup
      .date()
      .typeError('Enter a valid time')
      .futureDate('Incident time cannot be later than current time')
      .required('Incident time is required')
      .nullable(),
    otherwise: yup
      .date()
      .typeError('Enter a valid time')
      .required('Incident time is required')
      .nullable(),
  }),
});

export const AuthInsured = (props: AuthInsuredProps) => {
  const uuid = Cookies.get('uuid');
  const policyName = props.firstName + ' ' + props.lastName;
  const [dateSelected, setDateSelected] = useState<Date | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasDuplicateClaims, setHasDuplicateClaims] = useState(false);
  const [duplicateClaimData, setDuplicateClaimData] = useState<any>([{}]);
  const [dataForCA, setDataForCA] = useState<UserData>();
  const [policySearchError, setPolicySearchError] = useState<
    string | React.ReactElement | undefined | null
  >();
  const [userAtomState, setUserAtomState] = useUserAtomState();
  const lob = userAtomState?.gettingStarted?.lob || '';
  const policyNumber = userAtomState?.policyNumber || '';
  const canceledPolicyMessage = 'Policy status is not allowed';
  const {featureFlags} = useFeatureFlags();
  const claimsOutage = featureFlags?.FF_DCX_2688;
  const policyCenterDown = featureFlags?.FF_DCARE_6280;
  const setVehicleOptionsAtomState = useSetVehicleOptionsAtomState();
  const initializeContactList = useInitializeContactListAtomState();

  const POLICY_TYPE_MATCHING_ICON: {[key: string]: string} = {
    auto: 'autoPolicy',
    homeowners: 'homePolicy',
  };
  const formMethods = useForm<AuthInsuredForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    resolver: yupResolver(AuthInsuredFormSchema),
    defaultValues: {
      lossDate: 0,
    },
  });

  const {register, errors, handleSubmit, formState, setValue, getValues, trigger, control} =
    formMethods;

  const continueToForm = (data?: UserData) => {
    const {lossDate, lossTime} = getValues();
    const lossDateFormatted = formatISO(new Date(lossDate));
    const lossTimeFormatted = format(lossTime, 'hh:mm a');
    setUserAtomState({
      ...userAtomState,
      lossDate: lossDate,
      lossTime: lossTime,
      policyNumber: policyNumber?.replace(/\s+/g, ''),
      uuid: uuid,
    });
    const forCA = data || dataForCA;
    if (
      featureFlags.FF_DCX_2945 &&
      forCA &&
      COMMERCIAL_AUTO_TYPES.includes(forCA['policyType']) &&
      !forCA.policyNumber.toLowerCase().startsWith('bap') &&
      forCA.vehicles?.length
    ) {
      navigate(COMMERCIAL_AUTO_VEHICLE_SELECTION_ROUTE, {
        state: {
          userData: {...forCA},
          dataForDraftClaim: {
            lossDate,
            lossTime,
            lossDateFormatted,
            lossTimeFormatted,
            policyNumber,
            uuid,
          },
        },
      });
    } else {
      draftClaim({
        lossDate: lossDateFormatted,
        lossTime: lossTimeFormatted,
        policyNumber,
        uuid,
      })
        .then(response => {
          const {data} = response;
          const policyType = data.data.draftClaimResponse.result.policy.policyType;
          Snowplow.track.login({
            isCompanyPolicy: (policyType && COMMERCIAL_AUTO_TYPES.includes(policyType)) || false,
            payload: {
              firstName: props.firstName,
              lastName: props.lastName,
              lossDate: lossDateFormatted as string,
              lossTime: lossTimeFormatted as string,
              policyNumber,
              companyName: '',
            },
            data,
            isAuthedUser: true,
            logrocketurl: userAtomState?.logrocketurl,
            persona: CustomerTypes.SaCustomer,
          });

          LogRocket.identify(
            policyNumber,
            typeof data.data !== 'boolean' ? {name: `${policyName}`} : {}
          );

          setUserAtomState((state: UserAtomStateValue) => {
            return {...state, draftClaimResponse: data.data.draftClaimResponse};
          });
          setVehicleOptionsAtomState(
            data.data?.draftClaimResponse?.result?.lobs?.personalAuto?.vehicles ||
              data.data?.draftClaimResponse?.result?.lobs?.commercialAuto?.vehicles
          );
          initializeContactList(data.data.draftClaimResponse, data.data.uuid);
          navigateDefaultReplace(LOB_PROPS[lob].formPath);
        })
        .catch(_ => {
          setPolicySearchError(
            <span>
              An issue occurred with the claim setup process. Please contact our CARE team at
              <PhoneHyperlink />
            </span>
          );
          setHasDuplicateClaims(false);
        });
    }
  };

  const onEscapeKeyDown = () => {
    setIsSubmitting(false);
    setHasDuplicateClaims(false);
  };

  const callDuplicateCheck = (lossDateFormatted: string, userData: UserData) => {
    setDataForCA(userData);
    duplicateCheck({policyNumber, lossDate: lossDateFormatted})
      .then(({data}: any) => {
        if (data?.data instanceof Array && data.data.length > 0) {
          setHasDuplicateClaims(true);
          setDuplicateClaimData(returnRecentDuplicateClaims(data.data));
        } else {
          continueToForm(userData);
        }
      })
      .catch(error => {
        setPolicySearchError(
          <span>
            An issue occurred with the claim setup process. Please contact our CARE team at
            <PhoneHyperlink />
          </span>
        );
        setHasDuplicateClaims(false);
        setIsSubmitting(false);
      });
  };

  const callVerifyPolicy = (verifyPolicyPayload: VerifyPolicyPayload) => {
    const userToken = Cookies.get('userToken');
    verifyPolicy(verifyPolicyPayload)
      .then(response => {
        const {data, headers} = response;

        if (data.data) {
          if (!userToken) {
            Cookies.set('userToken', headers?.['x-amzn-remapped-authorization'] || data?.jwt);
          }
          callDuplicateCheck(verifyPolicyPayload.lossDate || '', data.data as UserData);
        } else if (data.errors?.[0].includes(canceledPolicyMessage)) {
          setPolicySearchError(
            <span data-testid="errorMessage" id="genericPolicyError">
              Something went wrong. Please contact our claims team at <PhoneHyperlink />.
            </span>
          );
          setIsSubmitting(false);
        } else {
          if (policyCenterDown) {
            navigate(MAINTENANCE_ROUTE);
          } else {
            setPolicySearchError(
              <span data-testid="policyMatchError" id="PolicyMatchError">
                Please double-check that the incident date and time is within the active policy
                dates.
              </span>
            );
            setIsSubmitting(false);
          }
        }
      })
      .catch(error => {
        setPolicySearchError(
          <span data-testid="errorMessage" id="genericPolicyError">
            Something went wrong. Please contact our claims team at <PhoneHyperlink />.
          </span>
        );
        setIsSubmitting(false);
      });
  };

  const onSubmit = (data: AuthInsuredForm) => {
    setIsSubmitting(true);
    setPolicySearchError(undefined);
    const verifyPolicyPayload: VerifyPolicyPayload = {};
    const lossDateFormatted = formatISO(new Date(data.lossDate));
    const lossTimeFormatted = format(data.lossTime, 'hh:mm a');

    verifyPolicyPayload.policyNumber = policyNumber.replace(/\s+/g, '');
    verifyPolicyPayload.lossDate = lossDateFormatted;
    verifyPolicyPayload.lossTime = lossTimeFormatted;
    if (props.firstName !== '') {
      verifyPolicyPayload.firstName = props.firstName;
    }
    if (props.lastName !== '') {
      verifyPolicyPayload.lastName = props.lastName;
    }
    verifyPolicyPayload.policyholderType = 'person';
    verifyPolicyPayload.lineOfBusiness = lob;
    verifyPolicyPayload.agentSearch = false;

    callVerifyPolicy(verifyPolicyPayload);
  };

  const dateTimeChangeHandler = (date: Date | null, _value?: string | null | undefined) => {
    if (date instanceof Date && date.toString() !== 'Invalid Date') {
      setValue('lossDate', date);
      setDateSelected(date);
      trigger('lossDate');
    }
  };

  const incidentTimeRef = useRef(null);

  const onError = () => {
    scrollToRef(incidentTimeRef);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SABox>
      <SAContainerBox>
        <SAContainerCard variant="standard" title="Getting Started">
          <SABox>
            {policySearchError && (
              <SAColumns columns={{xs: [12]}}>
                <SACBox
                  display="flex"
                  flexDirection={isMobile ? 'column' : 'row'}
                  height="100%"
                  width="100%"
                  marginBottom="small"
                >
                  <SABox>
                    <AlertPanel
                      alert={{
                        message: policySearchError,
                        showWarning: false,
                      }}
                      data-testid="error-panel"
                    />
                  </SABox>
                </SACBox>
              </SAColumns>
            )}
            <SAInfoBox padding={'medium'}>
              <SAText type="standard" text="Policy Number:" weight="bold" />
              <SAPolicyNumberBox pt={'small'}>
                <Icon name={POLICY_TYPE_MATCHING_ICON[lob || '']} />
                <SABox pl={'small'}>
                  <SAText type="standard" text={policyNumber.toString()} />
                </SABox>
              </SAPolicyNumberBox>
            </SAInfoBox>
            <SAInfoBox padding={'medium'}>
              <SAText type="standard" text="Policy Name/Company:" weight="bold" />
              <SABox pt={'small'}>
                <SAText type="standard" text={policyName} />
              </SABox>
            </SAInfoBox>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
              <SADateBox padding={{xs: 'small', md: 'medium'}}>
                <SADatePicker
                  id="lossDate"
                  label="Incident Date"
                  name="lossDate"
                  value={dateSelected}
                  InputLabelProps={{'aria-labelledby': 'lossDate'}}
                  error={errors.hasOwnProperty('lossDate')}
                  helperText={errors.lossDate?.message || 'DD/MM/YYYY'}
                  keyboardIcon={<Icon name="calendarDisabled" />}
                  onChange={dateTimeChangeHandler}
                  inputRef={register}
                />
              </SADateBox>
              <SATimeBox padding={{xs: 'small', md: 'medium'}}>
                <div ref={incidentTimeRef} />
                <Controller
                  name="lossTime"
                  defaultValue={null}
                  control={control}
                  onFocus={onError}
                  render={({value, onChange}) => (
                    <SATimePicker
                      label="Incident Time"
                      disabled={false}
                      onChange={(date: Date) => {
                        onChange(date);
                        trigger('lossTime');
                      }}
                      onBlur={() => {
                        trigger('lossTime');
                      }}
                      value={value}
                      keyboardIcon={<Icon name="clockDisabled" />}
                      error={errors.hasOwnProperty('lossTime')}
                      InputLabelProps={{
                        'aria-labelledby': 'lossTime',
                        for: 'lossTime',
                      }}
                      id="lossTime"
                    />
                  )}
                />
                <SAText
                  colorVariant={errors.hasOwnProperty('lossTime') ? 'alert' : 'default'}
                  type="small"
                  text={errors?.lossTime?.message || 'General Estimate'}
                  weight="normal"
                />
              </SATimeBox>
              <SAButtonBox padding={{xs: 'medium', md: 'large'}}>
                <SACButton
                  disabled={!formState.isValid || isSubmitting || claimsOutage}
                  data-testid="continue-button"
                  startIcon={isSubmitting ? spinner : undefined}
                  label={'Continue'}
                  variant="medium"
                  color="secondary"
                  type="submit"
                />
              </SAButtonBox>
            </form>
          </SABox>
        </SAContainerCard>
      </SAContainerBox>
      <DuplicateClaimDialog
        open={hasDuplicateClaims}
        continueToForm={continueToForm}
        onEscapeKeyDown={onEscapeKeyDown}
        data={{
          insuredName: '',
          incidentDate: getValues()?.lossDate,
          policyNumber: policyNumber,
          policyType: lob,
          claims: [...duplicateClaimData],
        }}
      />
    </SABox>
  );
};
