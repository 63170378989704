import React from 'react';

export const PrivacyChoice = () => (
  <svg width="32" height="16" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 0H8C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16H24C28.42 16 32 12.42 32 8C32 3.58 28.42 0 24 0ZM26.44 10.3133C26.6333 10.5067 26.6333 10.8267 26.44 11.02C26.34 11.12 26.2133 11.1667 26.0867 11.1667C25.96 11.1667 25.8333 11.12 25.7333 11.02L23.42 8.70667L21.1067 11.02C21.0067 11.12 20.88 11.1667 20.7533 11.1667C20.6267 11.1667 20.5 11.12 20.4 11.02C20.2067 10.8267 20.2067 10.5067 20.4 10.3133L22.7133 8L20.4 5.68667C20.2067 5.49333 20.2067 5.17333 20.4 4.98C20.5933 4.78667 20.9133 4.78667 21.1067 4.98L23.42 7.29333L25.7333 4.98C25.9267 4.78667 26.2467 4.78667 26.44 4.98C26.6333 5.17333 26.6333 5.49333 26.44 5.68667L24.1267 8L26.44 10.3133ZM8 15C4.14 15 1 11.86 1 8C1 4.14 4.14 1 8 1H18.2533L12.42 15H8.00667H8ZM12.88 5.68667L7.19334 11.3733L4.52 8.7C4.32667 8.50667 4.32667 8.18667 4.52 7.99333C4.71334 7.8 5.03333 7.8 5.22667 7.99333L7.19334 9.96L12.1733 4.98C12.3667 4.78667 12.6867 4.78667 12.88 4.98C13.0733 5.17333 13.0733 5.49333 12.88 5.68667Z"
      fill="#0855A4"
    />
  </svg>
);
