import React, {useState} from 'react';
import {BlueContainer} from '../../common/Containers/Containers';
import {
  Grid,
  Container,
  Box,
  RadioGroup,
  FormControlLabel,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {SARadio} from '../../common/Radio/Radio';
import {useFormContext} from 'react-hook-form';
import {PhoneInput} from '../../common/PhoneInput/PhoneInput';
import * as yup from 'yup';
import mobileImg from '../../../assets/images/Illustration_MOI-Phone.png';
import {phoneValidation, emailValidation} from '../../../validations';
import {SAController} from '../../common/Controller/Controller';
import {CustomerTypes, PhoneTypes} from '../../../commonTypes';
import {IconHeader} from '../../common/IconHeader/IconHeader';
import {
  InformationBubbleBox,
  contentStyleTypes,
} from '../../common/InformationBubbleBox/InformationBubbleBox';
import {PhoneNumber, VehicleIncident} from '../../common/Summary/types';
import {SAEmail} from '../../common/SAEmail/SAEmail';

export const QuickEstimateSchema = yup.object().shape({
  quickEstimates: yup.array().of(
    yup.object().shape({
      phoneNumber: phoneValidation(true).when('contactMethod', {
        is: (value: string) => {
          return value === 'text';
        },
        then: yup.string().required('Please enter a phone number'),
      }),
      email: emailValidation(true).when('contactMethod', {
        is: (value: string) => {
          return value === 'email';
        },
        then: yup.string().required('Please enter an email address'),
      }),
    })
  ),
});

const useStyles = makeStyles(theme => ({
  introText: {
    fontSize: 19,
  },
  caption: {
    fontSize: 13,
    fontStyle: 'italic',
    lineHeight: '16px',
  },
  headerSpacing: {
    gap: '4px',
  },
}));

export interface QuickEstimates {
  quickEstimates: QuickEstimateInstance[];
}

export interface QuickEstimateInstance {
  quickEstimateRequested: string;
  contactMethod: string;
  phoneNumber: string;
  email: string;
  publicID: string;
}

interface QuickEstimateProps {
  userPersona?: string;
  reporterInfo?: any;
  vehicle: VehicleIncident;
  index: number;
}

export const DEFAULT_LANGUAGE = {
  yesOption: (
    <>
      <b>Yes, I would like to fast track</b> the claim with Quick Estimate for the
    </>
  ),
  needMobileDevice:
    'You will need a mobile device and a data connection to take pictures of the damage and submit them for an estimate.',
  contactMethod: 'Where can we send you a link to complete the photo estimate?',
  noOption: 'No, I do not want to expedite my claim with Quick Estimate',
};

export const ASSOCIATE_LANGUAGE = {
  yesOption: (
    <>
      <b>Yes, the customer would like to fast track</b> the claim with Quick Estimate for the
    </>
  ),
  needMobileDevice:
    'The customer will need a mobile device and a data connection to take pictures of the damage and submit them for an estimate.',
  contactMethod: 'Where can we send you a link to complete the photo estimate?',
  noOption: 'No, the customer does not want to expedite the claim with Quick Estimate',
};

export const QuickEstimate = ({userPersona, reporterInfo, vehicle, index}: QuickEstimateProps) => {
  const classes = useStyles();
  const formMethods = useFormContext();
  const {register, errors} = formMethods;
  const defaultPhoneNumber =
    reporterInfo?.phoneNumbers?.find(
      (phoneNumber: PhoneNumber) => phoneNumber.phoneType === PhoneTypes.Mobile
    )?.phoneNumber || '';
  const isAssociate = userPersona === CustomerTypes.Associate;
  const [quickEstimateRequested, setQuickEstimateRequested] = useState(true);
  const [displayedContactMethod, setDisplayedContactMethod] = useState('text');
  const formattedVehicleName = `${vehicle?.vehicle?.year} ${vehicle?.vehicle?.make} ${vehicle?.vehicle?.model}`;
  const formattedVehicleNameAndVin = `${formattedVehicleName}${
    vehicle?.vehicle?.vIN ? ' - ' + vehicle?.vehicle?.vIN : ''
  }`;
  const {contactMethod, needMobileDevice, noOption, yesOption} = isAssociate
    ? ASSOCIATE_LANGUAGE
    : DEFAULT_LANGUAGE;

  return (
    <Grid container justify="center">
      <Container>
        <Box mt={10} pb={4}>
          <FormContainer header={formattedVehicleNameAndVin} fontSize={19}>
            <SAController
              name={`quickEstimates[${index}].quickEstimateRequested`}
              defaultValue="yes"
              onChange={(e: any) => {
                setQuickEstimateRequested(e.target.value === 'yes');
                if (e.target.value === 'no') {
                  setDisplayedContactMethod('text');
                }
              }}
            >
              <RadioGroup>
                <Box pt={2} pb={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.introText}>
                        We can get you back on the road sooner with Quick Estimate - A guided
                        self-inspection using your phone!
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box pt={1} pb={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                      <IconHeader>
                        <Typography variant="body1" data-testid="quickEstimateHeader">
                          Did you know that the customers who choose to expedite their claim with
                          Quick Estimate may{' '}
                          <b>complete their claim and receive payment up to 3 days sooner</b> than
                          those that choose not to do Quick Estimate?
                        </Typography>
                      </IconHeader>
                    </Grid>
                  </Grid>
                </Box>

                <Box pb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Would you like to fast track this claim with Quick Estimate?
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                {/* Quick Estimate requested - yes*/}
                <Box>
                  <FormControlLabel
                    value="yes"
                    control={<SARadio />}
                    label={
                      <Typography>
                        {yesOption} {<b>{formattedVehicleName}</b> || 'vehicle'}
                      </Typography>
                    }
                  />
                </Box>

                {/* Quick Estimate Contact Section */}
                {quickEstimateRequested && (
                  <Box mt={3} mb={2} pb={1}>
                    <BlueContainer>
                      <Grid container spacing={3}>
                        {/* Quick Estimate Contact Header */}
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center">
                            <img width="45px" height="74px" src={mobileImg} alt="" />
                            <Box
                              pl={2}
                              display="flex"
                              flexDirection="column"
                              className={classes.headerSpacing}
                            >
                              <Typography variant="h6">
                                Quick Estimate Mobile Photo Inspection
                              </Typography>
                              <Typography variant="body1">{needMobileDevice}</Typography>
                            </Box>
                          </Box>
                        </Grid>

                        {/* Quick Estimate Text/Email Buttons */}
                        <Grid item xs={12} md={11}>
                          <Typography>{contactMethod}</Typography>
                          <SAController
                            name={`quickEstimates[${index}].contactMethod`}
                            defaultValue="text"
                            onChange={(e: any) => setDisplayedContactMethod(e.target.value)}
                          >
                            <RadioGroup row>
                              <FormControlLabel
                                value="text"
                                control={<SARadio />}
                                label={
                                  <Typography>
                                    SMS Text<sup>*</sup>
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                                value="email"
                                control={<SARadio />}
                                label={<Typography>Email</Typography>}
                              />
                            </RadioGroup>
                          </SAController>
                        </Grid>

                        {/* Quick Estimate Text/Email Inputs */}
                        <Grid item xs={12} md={6}>
                          {displayedContactMethod === 'email' ? (
                            <SAEmail
                              name={`quickEstimates[${index}].email`}
                              label="Email"
                              error={errors?.quickEstimates?.[index]?.hasOwnProperty('email')}
                              helperText={errors?.quickEstimates?.[index]?.email?.message || ''}
                              inputRef={register()}
                              id="email"
                              InputLabelProps={{'aria-labelledby': 'email'}}
                              defaultValue={reporterInfo?.email || ''}
                              data-testid={`quickEstimates-email-${index}`}
                            />
                          ) : (
                            <PhoneInput
                              name={`quickEstimates[${index}].phoneNumber`}
                              hasError={errors?.quickEstimates?.[index]?.hasOwnProperty(
                                'phoneNumber'
                              )}
                              helperText={
                                errors?.quickEstimates?.[index]?.phoneNumber?.message || ''
                              }
                              inputRef={register()}
                              id="phoneNumber"
                              InputLabelProps={{
                                'aria-labelledby': 'phoneNumber',
                              }}
                              defaultValue={defaultPhoneNumber}
                              data-testid={`quickEstimate-phoneNumber-${index}`}
                            />
                          )}
                        </Grid>

                        {isAssociate && (
                          <Grid item xs={12} md={8}>
                            <Typography variant="caption" className={classes.caption}>
                              This contact information will be used for just Quick Estimate,
                              previously entered contact information will be used for all other
                              claims communication.
                            </Typography>
                          </Grid>
                        )}

                        {displayedContactMethod === 'text' && (
                          <Grid item xs={12} md={8}>
                            <Typography variant="caption" className={classes.caption}>
                              <sup>*</sup>By providing your phone number you agree State Auto
                              Insurance can send you messages regarding the photo estimate. Estm. 4
                              msgs/month. View State Auto Terms and Privacy Policy
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </BlueContainer>
                  </Box>
                )}

                {/* Quick Estimate requested - no */}
                <Box>
                  <FormControlLabel
                    value="no"
                    control={<SARadio />}
                    label={<Typography>{noOption}</Typography>}
                  />
                </Box>

                {/* Non associate and declined qe */}
                {!isAssociate && !quickEstimateRequested && (
                  <Box pt={3} pb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <InformationBubbleBox
                          message="An appraiser will need open access to the vehicle to the day of the inspection, the vehicle will need to be left unlocked or it will need to be unlocked for the appraiser when they arrive."
                          contentStyle={contentStyleTypes.Italic}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* vehicle id hidden field */}
                <input
                  type="hidden"
                  ref={register()}
                  name={`quickEstimates[${index}].publicID`}
                  defaultValue={vehicle.publicID}
                />
              </RadioGroup>
            </SAController>
          </FormContainer>
        </Box>
      </Container>
    </Grid>
  );
};
