import React from 'react';
import {Box, Typography, Grid} from '@material-ui/core';
import {formatPhone} from '../../../utils/utils';
import {SummaryField} from '../../common/Summary/components/SummaryField/SummaryField';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';
import {Address, Contact, SubmissionTypes} from '../../common/Summary/types';
import {AddressSummary} from '../VehicleSummary/AddressSummary';
import {BaseSwitch} from '../../common/BaseSwitch/BaseSwitch';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {format, isValid} from 'date-fns';
import {CustomerTypes, CUSTOMER_TYPES} from '../../../commonTypes';
import {DOL_UNKNOWN_OPTION} from '../../common/GeneralDetails/GeneralDetails';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {HOW_REPORTED} from '../GettingStarted/GettingStarted';

export interface GeneralDetailsSummaryProps {
  policyNumber?: string;
  incidentTime?: string;
  customerType: string;
  mainContact?: Contact;
  lossLocation?: Address;
  submissionType?: SubmissionTypes;
  contacts?: Contact[];
  isDateOfLossKnown?: boolean;
  lossDate?: {
    dateOfLoss?: string;
    isDateOfLossKnown?: boolean;
  };
  howReported?: string;
  fraudLanguage?: string;
  lawsuitClaim?: boolean;
  claimNumber?: string;
  isNoticeOnly?: boolean;
  lossTimezone?: string;
}

export const GeneralDetailsSummary = (props: GeneralDetailsSummaryProps) => {
  const insuredAddress = props.contacts?.find(x =>
    x.contactRoles?.includes('insured')
  )?.primaryAddress;

  const dateOfLoss = new Date(props?.lossDate?.dateOfLoss || '');

  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.GeneralDetails}
      displayName={WAYPOINTS[WaypointNames.GeneralDetails].displayName}
    >
      <Box>
        <SummaryHeader headerText="General Details" />
        <Box pt={3}>
          <Grid container spacing={3}>
            {props.claimNumber && (
              <Grid item xs={12}>
                <SummaryField label="Claim Number">
                  <Typography>{props.claimNumber}</Typography>
                </SummaryField>
              </Grid>
            )}
            {props.isNoticeOnly && (
              <Grid item xs={12}>
                <SummaryField label="Purpose of Claim">
                  <Typography>{'This claim was reported for Notice Only purposes'}</Typography>
                </SummaryField>
              </Grid>
            )}
            {props.howReported && (
              <Grid item xs={12}>
                <SummaryField label="How was this claim reported?">
                  <Typography>{HOW_REPORTED[props.howReported]}</Typography>
                </SummaryField>
              </Grid>
            )}
            {props.customerType !== CustomerTypes.Associate && (
              <Grid item xs={12}>
                <SummaryField label="About You">
                  <Typography>{CUSTOMER_TYPES[props.customerType]}</Typography>
                </SummaryField>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <SummaryField label="Name" testId="generalDetailsNameLabel">
                <Typography data-testid="generalDetailsNameValue">
                  {props?.mainContact?.displayName}
                </Typography>
              </SummaryField>
            </Grid>
            {props?.mainContact?.cellNumber && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label="Mobile Number" testId="general-details-cell-number">
                  <Typography>{formatPhone(props?.mainContact?.cellNumber)}</Typography>
                </SummaryField>
              </Grid>
            )}
            {props?.mainContact?.workNumber && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label="Work Number" testId="general-details-work-number">
                  <Typography>{formatPhone(props?.mainContact?.workNumber)}</Typography>
                </SummaryField>
              </Grid>
            )}
            {props?.mainContact?.homeNumber && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label="Home Number" testId="general-details-home-number">
                  <Typography>{formatPhone(props?.mainContact?.homeNumber)}</Typography>
                </SummaryField>
              </Grid>
            )}
            {props?.mainContact?.emailAddress1 && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label="Email">
                  <Typography>{props?.mainContact?.emailAddress1}</Typography>
                </SummaryField>
              </Grid>
            )}
            {props?.mainContact?.primaryAddress && (
              <AddressSummary
                label="Address"
                addressLine1={props?.mainContact?.primaryAddress.addressLine1}
                city={props?.mainContact?.primaryAddress.city}
                state={props?.mainContact?.primaryAddress.state}
                postalCode={props?.mainContact?.primaryAddress.postalCode}
              />
            )}
            {props?.lossLocation && (
              <BaseSwitch
                matches={props.submissionType === SubmissionTypes.Property}
                then={
                  <UserPersonaSwitch
                    ifPersonas={[CustomerTypes.SaAgent, CustomerTypes.Associate]}
                    then={
                      <AddressSummary
                        label="Incident Location"
                        addressLine1={props?.lossLocation.addressLine1}
                        city={props?.lossLocation.city}
                        state={props?.lossLocation.state}
                        postalCode={props?.lossLocation.postalCode}
                      />
                    }
                    otherwise={
                      <BaseSwitch
                        matches={props.lossLocation.displayName === insuredAddress?.displayName}
                        then={
                          <Grid item xs={12} sm={6} md={4}>
                            <SummaryField label="Incident Location">
                              <Typography>Insured Location</Typography>
                            </SummaryField>
                          </Grid>
                        }
                        otherwise={
                          <AddressSummary
                            label="Incident Location"
                            addressLine1={props?.lossLocation.addressLine1}
                            city={props?.lossLocation.city}
                            state={props?.lossLocation.state}
                            postalCode={props?.lossLocation.postalCode}
                          />
                        }
                      />
                    }
                  />
                }
                otherwise={
                  <AddressSummary
                    label="Incident Location"
                    addressLine1={props?.lossLocation.addressLine1}
                    city={props?.lossLocation.city}
                    state={props?.lossLocation.state}
                    postalCode={props?.lossLocation.postalCode}
                  />
                }
              />
            )}
            {props.incidentTime && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label="Incident Time">
                  <Typography>{props.incidentTime}</Typography>
                </SummaryField>
              </Grid>
            )}
            {props.lossTimezone && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label="Incident Time Zone">
                  <Typography>{props.lossTimezone}</Typography>
                </SummaryField>
              </Grid>
            )}
            {props.fraudLanguage && (
              <Grid item xs={12}>
                <SummaryField label="Acknowledgement ">
                  <Typography>{props.fraudLanguage}</Typography>
                </SummaryField>
              </Grid>
            )}
            {props?.lossDate && isValid(dateOfLoss) && (
              <>
                <Grid item xs={12} sm={6} md={4}>
                  <SummaryField label="Date of Loss">
                    <>
                      <Typography data-testid="SummaryDateOfLoss">
                        {format(new Date(dateOfLoss), 'MM/dd/yyyy')}
                      </Typography>
                      {props?.lossDate?.isDateOfLossKnown && (
                        <Typography>({DOL_UNKNOWN_OPTION.label})</Typography>
                      )}
                    </>
                  </SummaryField>
                </Grid>
              </>
            )}
            {props.policyNumber && (
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label="Policy Number">
                  <Typography>{props.policyNumber}</Typography>
                </SummaryField>
              </Grid>
            )}
            {props?.lawsuitClaim && (
              <Grid item xs={12}>
                <SummaryField label="Lawsuit Claim">
                  <Typography data-testid="SummaryLawsuitClaim">
                    There is a lawsuit attached to this claim
                  </Typography>
                </SummaryField>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </RegisterBreadcrumb>
  );
};
