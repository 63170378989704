import React, {useEffect} from 'react';
import {VehicleSummary} from '../VehicleSummary/VehicleSummary';
import {GeneralDetailsSummary} from '../GeneralDetailsSummary/GeneralDetailsSummary';
import {OtherDetailsSummary} from '../OtherDetailsSummary/OtherDetailsSummary';
import {AnyoneElseInjuredSummary} from '../AnyoneElseInjuredSummary/AnyoneElseInjuredSummary';
import {PropertyDamageDetailsSummary} from '../PropertyDamageDetailsSummary/PropertyDamageDetailsSummary';
import {InsuredContactSummary} from '../../homeowners/InsuredContactSummary/InsuredContactSummary';
import {VehicleTypes} from '../YourVehicleDetails/YourVehicleDetails';
import {IncidentDetailsSummary} from '../IncidentDetailsSummary/IncidentDetailsSummary';
import {AdditionalNotesSummary} from '../AdditionalNotesSummary/AdditionalNotesSummary';
import {StateProps, SubmissionTypes} from '../../common/Summary/types';
import {GreyBorderedBox} from '../../common/Summary/components/GreyBorderedBox/GreyBorderedBox';
import Cookies from 'js-cookie';
import {PdfSection} from '../../common/Summary/components/PdfSection/PdfSection';
import {SUMMARY_PDF_ID} from '../../pdf/constants';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {ConditionalWaypoint, insertConditionalBreadcrumbs} from '../../../pages/utils';

interface AutoSuccessProps {
  successState: StateProps;
  setWaypoints?: any;
}

export const AutoSuccess = ({successState, setWaypoints}: AutoSuccessProps) => {
  const lob =
    successState?.lobs?.personalAuto ||
    successState?.lobs?.commercialAuto ||
    successState?.lobs?.homeowners;
  const vehicleIncidents = lob?.vehicleIncidents || [];
  const injuryIncidents = lob?.injuryIncidents || [];
  const fixedPropertyIncidents = lob?.fixedPropertyIncidents || lob?.propertyIncidents || [];
  const yourVehicleIncidents = vehicleIncidents.filter(vi => vi.isYours);
  const otherVehicleIncidents = vehicleIncidents.filter(vi => !vi.isYours);
  const userNote = successState?.adjusterNote?.find(x => x.subject === 'Additional Comments')?.body;
  const insuredContactInfo = successState?.formData?.insuredContactInformation;
  const vehiclePreferences = successState?.formData?.vehiclePreferences;
  const quickEstimateVehicles = successState?.quickEstimateVehicles || [];
  const policyNumber = Cookies.get('policyNumber');

  useEffect(() => {
    if (setWaypoints) {
      //Setting base waypoints for the auto page
      const baseWaypoints = [
        WaypointNames.GeneralDetails,
        WaypointNames.IncidentDetails,
        WaypointNames.AnyoneElseInjured,
        WaypointNames.PropertyDamageDetails,
      ];

      //getting the number of additions including current and previous conditional waypoints
      //this helps to calculate where to splice the waypoint
      const insuredContactInfoSpliceStart = !!insuredContactInfo ? 1 : 0;
      const YourVehicleDetailsSpliceStart = !!yourVehicleIncidents.length
        ? 1 + insuredContactInfoSpliceStart
        : insuredContactInfoSpliceStart;
      const OtherVehicleDetailsSpliceStart = !!otherVehicleIncidents.length
        ? 1 + YourVehicleDetailsSpliceStart
        : YourVehicleDetailsSpliceStart;

      //setting up the waypoints conditional on the page
      const conditionalWaypoints: ConditionalWaypoint[] = [
        {
          condition: !!insuredContactInfo,
          spliceStart: 1,
          waypoint: WaypointNames.InsuredContactDetails,
        },
        {
          condition: !!yourVehicleIncidents.length,
          spliceStart: 2 + insuredContactInfoSpliceStart,
          waypoint: WaypointNames.YourVehicleDetails,
        },
        {
          condition: !!otherVehicleIncidents.length,
          spliceStart: 2 + YourVehicleDetailsSpliceStart,
          waypoint: WaypointNames.OtherVehicleDetails,
        },
        {
          condition: !!(successState.officials || successState.witnesses),
          spliceStart: 3 + OtherVehicleDetailsSpliceStart,
          waypoint: WaypointNames.OtherDetails,
        },
        {
          condition: !!userNote,
          spliceStart: 'last',
          waypoint: WaypointNames.AdditionalNotes,
        },
      ];

      //setting the waypoints state so that they can be passed to BaseSummary to be rendered on sidebar
      setWaypoints(insertConditionalBreadcrumbs(baseWaypoints, conditionalWaypoints));
    }
  }, []);

  return (
    <>
      <PdfSection className={SUMMARY_PDF_ID}>
        <GreyBorderedBox hideBorderTop>
          <GeneralDetailsSummary
            mainContact={successState.mainContact}
            customerType={successState.customerType}
            lossLocation={successState.lossLocation}
            submissionType={successState.submissionType}
            contacts={successState.contacts}
            policyNumber={policyNumber}
            incidentTime={successState?.formData?.generalDetails?.lossTime}
            lossDate={{
              dateOfLoss: `${successState.lossDate}`,
              isDateOfLossKnown: successState?.formData?.generalDetails?.dateOfLossUnknown || false,
            }}
            howReported={successState?.formData?.gettingStarted?.howReported}
            fraudLanguage={successState?.formData?.generalDetails?.fraudLanguage}
            lawsuitClaim={successState?.formData?.generalDetails?.lawsuitClaim}
            claimNumber={successState?.claimNumber}
            isNoticeOnly={successState?.formData?.noticeOnly}
            lossTimezone={successState?.formData?.generalDetails?.lossTimezone}
          />
        </GreyBorderedBox>
      </PdfSection>
      {insuredContactInfo && (
        <PdfSection className={SUMMARY_PDF_ID}>
          <InsuredContactSummary insuredInformation={{...insuredContactInfo}} />
        </PdfSection>
      )}
      <PdfSection className={SUMMARY_PDF_ID}>
        <GreyBorderedBox>
          <IncidentDetailsSummary
            selectedIncidents={successState.selectedIncidents}
            incidentDescription={successState.incidentDescription}
            description={successState.description}
            rideshareOrDelivery={successState.formData?.incidentDetails?.rideshareOrDelivery || ''}
            rideshare={successState.formData?.incidentDetails?.rideshare || ''}
            delivery={successState.formData?.incidentDetails?.delivery || ''}
            isInsuredBusiness={successState.formData?.incidentDetails?.isInsuredBusiness || ''}
            deliveryBusinessName={
              successState.formData?.incidentDetails?.deliveryBusinessName || ''
            }
          />
        </GreyBorderedBox>
      </PdfSection>
      {yourVehicleIncidents.map((incident, index) => (
        <GreyBorderedBox>
          <RegisterBreadcrumb
            waypointName={WaypointNames.YourVehicleDetails}
            displayName={WAYPOINTS[WaypointNames.YourVehicleDetails].displayName}
          >
            <VehicleSummary
              vehicleIncident={incident}
              index={index}
              ownerType={VehicleTypes.OWNER}
              vehiclePreferences={vehiclePreferences}
              quickEstimateVehicles={quickEstimateVehicles}
              staffAppraiserSent={
                !!successState?.vehiclesToShowStaff?.filter(
                  staffAppraisedVehicle => staffAppraisedVehicle?.publicID === incident?.publicID
                ).length
              }
            />
          </RegisterBreadcrumb>
        </GreyBorderedBox>
      ))}
      {otherVehicleIncidents.map((incident, index) => (
        <GreyBorderedBox>
          <RegisterBreadcrumb
            waypointName={WaypointNames.OtherVehicleDetails}
            displayName={WAYPOINTS[WaypointNames.OtherVehicleDetails].displayName}
          >
            <VehicleSummary
              vehicleIncident={incident}
              index={index}
              ownerType={VehicleTypes.OTHER}
              quickEstimateVehicles={quickEstimateVehicles}
              staffAppraiserSent={
                !!successState?.vehiclesToShowStaff?.filter(
                  staffAppraisedVehicle => staffAppraisedVehicle?.publicID === incident?.publicID
                ).length
              }
            />
          </RegisterBreadcrumb>
        </GreyBorderedBox>
      ))}
      <GreyBorderedBox>
        <AnyoneElseInjuredSummary
          injuryIncidents={injuryIncidents}
          submissionType={successState.submissionType || SubmissionTypes.Collision}
        />
      </GreyBorderedBox>
      {(successState.officials || successState.witnesses) && (
        <GreyBorderedBox>
          <OtherDetailsSummary
            officials={successState.officials}
            witnesses={successState.witnesses}
          />
        </GreyBorderedBox>
      )}
      <GreyBorderedBox>
        <PropertyDamageDetailsSummary fixedPropertyIncidents={fixedPropertyIncidents} />
      </GreyBorderedBox>
      {userNote && (
        <PdfSection className={SUMMARY_PDF_ID}>
          <GreyBorderedBox>
            <AdditionalNotesSummary additionalNotes={userNote} />
          </GreyBorderedBox>
        </PdfSection>
      )}
    </>
  );
};
