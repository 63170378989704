import {useMediaQuery, useTheme} from '@material-ui/core';
import {SABox, SAIcon, SAIcons, SAIconSize, SAText} from '@saux/design-system-react';
import React from 'react';
import styled from 'styled-components';
import {SignedResponse} from '../../../../services/index';
import {BaseSwitch} from '../../BaseSwitch/BaseSwitch';
import {FileAndStatus, FILE_SIZE_LIMIT, UploadStatus} from '../UploadModal/UploadModal';
import CloseSmallIcon from './CloseSmallIcon';

const FileContent = styled.div`
  display: flex;
  flex-direction: row;
  &:not(:last-child) {
    border-bottom: 1px solid #d9dfeb;
  }
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
`;
export const DocumentSelector = styled.div`
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d9dfeb;
  border-radius: 3px;
  padding: 10px;
`;
export const DropDownContainer = styled.div`
  padding-right: 10px;
  display: flex;
  flex-direction: row;
`;
export const DropDown = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  gap: 10px;
  width: 150px;
`;
export const Options = styled.div`
  overflow: auto;
  max-height: 80px;
  z-index: 10;
  width: 180px;
  word-wrap: break-word;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -10px;
  font-size: 12px;
  position: absolute;
`;
export const Content = styled.div`
  padding: 10px;
  word-wrap: break-word;
  width: 180px;
  border: 1px solid #d9dfeb;
  background-color: #ffffff;
  z-index: 100;
`;
const IconSpacing = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  min-width: 100px;
  padding-right: 0.5em;
  text-align: left;
`;
const IconStyling = styled.div`
  height: 20px;
`;
const MobileFileText = styled(SABox)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;
const DisplayText = styled(SAText)`
  font-size: 14px;
`;
const ErrorText = styled(SAText)`
  font-size: 14px;
  color: #e02020;
`;
const FileTextContainer = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  min-width: 0;
`;
export const ButtonContainer = styled.div`
  line-height: 0;
  margin: auto;
  &:hover {
    cursor: pointer;
  }
`;
const RightSideContainer = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export interface UploadProps {
  name: any;
  file: File;
  status: string;
  signedResponse: SignedResponse | null;
  uploading: boolean;
  removeFile: (f: FileAndStatus) => void;
  uploadedFilesSection: boolean;
}

export default ({
  name,
  file,
  removeFile,
  status,
  signedResponse,
  uploading,
  uploadedFilesSection,
}: UploadProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const exceedsFileSizeLimit = file?.size >= FILE_SIZE_LIMIT;
  const colorVariant =
    exceedsFileSizeLimit || (!uploading && status === UploadStatus.EdsError) ? 'alert' : 'tertiary';

  return (
    <FileContent data-testid="fileContent">
      <IconSpacing>
        <IconStyling>
          <SAIcon
            icon={SAIcons.document}
            size={SAIconSize.medium}
            colorVariant={colorVariant}
            data-testid="fileIcon"
          />
        </IconStyling>
        <FileTextContainer>
          <BaseSwitch
            matches={exceedsFileSizeLimit}
            then={<ErrorText type="small" weight="bold" text="Error: File Size is too large" />}
            otherwise={
              <>
                {!uploading && status === UploadStatus.EdsError && (
                  <ErrorText
                    type="small"
                    weight="bold"
                    text="Error: Could Not Upload. Please Try Again."
                  />
                )}
                {uploading && status !== UploadStatus.Complete && (
                  <DisplayText type="small" weight="bold" text="Uploading..." />
                )}
                {status === UploadStatus.Complete && (
                  <DisplayText type="small" weight="bold" text="Upload Complete" />
                )}
                {!uploading &&
                  (status === UploadStatus.Pending || status === UploadStatus.S3Success) && (
                    <DisplayText type="small" text="Pending" weight="bold" />
                  )}
              </>
            }
          />
          {isMobile ? (
            <MobileFileText pt="xs">
              <DisplayText type="small" text={name} data-testid="fileName" />
            </MobileFileText>
          ) : (
            <SABox pt="xs">
              <DisplayText type="small" text={name} data-testid="fileName" />
            </SABox>
          )}
        </FileTextContainer>
      </IconSpacing>
      <RightSideContainer>
        {!uploadedFilesSection && status !== UploadStatus.Complete && (
          <ButtonContainer
            data-testid="removeFile"
            onClick={() => {
              removeFile({file, status, signedResponse, uploading});
            }}
          >
            <CloseSmallIcon />
          </ButtonContainer>
        )}
      </RightSideContainer>
    </FileContent>
  );
};
