import React, {useEffect} from 'react';
import {Dialog, Box, Grid, makeStyles, Button} from '@material-ui/core';
import {Icon} from '../Icon/Icon';
import {useResetUserAtomState, useResetAtomState} from '../../../atoms';

const useStyles = makeStyles(theme => ({
  headerText: {
    fontSize: '24.65px',
    textAlign: 'center',
    fontWeight: 700,
    color: '#F5A524',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  dialogContent: {
    marginTop: '24px',
    marginBottom: '24px',
    color: '#3B3B3B',
    textAlign: 'center',
    justifyContent: 'center',
  },
  continueBtn: {
    color: '#3B3B3B',
    [theme.breakpoints.up('sm')]: {
      marginRight: '-6px',
    },
  },
  downloadBtn: {
    marginRight: '0px',
    backgroundColor: '#4D840B',
    [theme.breakpoints.up('sm')]: {
      marginRight: '20px',
    },
  },
  responsiveButton: {
    fontSize: '14px',
    width: '100%',
    textAlign: 'center',
    '&:first-of-type': {
      marginBottom: '20px',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      fontSize: '17px',
    },
  },
}));

export interface AlertDialogProps {
  displayDialog: boolean;
  searchAgainHandler: (event: any) => void;
  onClickHandler: () => void;
}

export const AlertDialog = (props: AlertDialogProps) => {
  const classes = useStyles();
  const resetUserAtomState = useResetUserAtomState();
  const resetAtomState = useResetAtomState();

  useEffect(() => {
    return () => {
      resetAtomState();
      resetUserAtomState();
    };
  }, []);

  return (
    <Dialog
      open={props.displayDialog}
      fullScreen={false}
      maxWidth={'sm'}
      disableEscapeKeyDown={true}
    >
      <Box mt={3} mb={3} ml={3} mr={3}>
        <Box textAlign="center">
          <Icon name="alertIcon" />
        </Box>
        <Box className={classes.headerText}>Something Went Wrong: Submission Error</Box>
        <Box className={classes.dialogContent}>
          There was an error during the submission of this form to Claims Center. Good news, the
          data entered into the form can be saved by clicking Download File below. It will download
          a text file containing the information entered into the form.
        </Box>
        <Box mt={3} justify-content="stretch">
          <Grid container direction="row" justify="flex-end">
            <Grid item xs={12} sm={7} style={{order: 1, textAlign: 'right'}}>
              <Button
                data-testid="return-to-search-button"
                onClick={props.searchAgainHandler}
                className={`${classes.responsiveButton} ${classes.continueBtn}`}
              >
                RETURN TO POLICY SEARCH
              </Button>
            </Grid>
            <Grid item xs={12} sm={5} style={{order: 2, textAlign: 'right'}}>
              <Box>
                <Button
                  data-testid="download-file-button"
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={props.onClickHandler}
                  className={`${classes.responsiveButton} ${classes.downloadBtn}`}
                >
                  DOWNLOAD FILE
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};
