import React, {createContext, ReactElement, useState} from 'react';
import {useContext} from 'react';
import {Lobs, Policy, Contact} from '../../common/Summary/types';
import {NormalizedGoogleMapsData} from '../WithGoogleMaps/WithGoogleMaps';

export interface BreadCrumbsContextProps {
  breadcrumbs: {
    [waypointName: string]: {
      active: boolean;
      displayName: string;
    };
  };
}

export interface BreadCrumbsContextUpdate {
  updateBreadcrumbState: (waypointName: string, isActive: boolean) => void;
  registerBreadcrumb: (waypointName: string, displayName: string) => void;
}

export const BreadCrumbsContext = createContext<BreadCrumbsContextProps & BreadCrumbsContextUpdate>(
  {
    breadcrumbs: {},
    updateBreadcrumbState: (waypointName: string, isActive: boolean) => {},
    registerBreadcrumb: (waypointName: string, displayName: string) => {},
  }
);

export interface DataProviderStateProps {
  [key: string]: {value: any};
}

export interface DataProviderProps {
  children: (props: any) => void;
}

export const DataProvider = (props: DataProviderProps) => {
  const [data, setData] = useState<DataProviderStateProps | {} | undefined>();

  return <>{props.children({data, setData})}</>;
};

export interface UserPersonaContextProps {
  userPersona?: string;
}

export const UserPersonaContext = React.createContext<UserPersonaContextProps>({});

export interface GooglePlacesContextProps {
  addressPredictions: google.maps.places.AutocompletePrediction[] | undefined;
  updatePredictions: (input: any) => void;
  updatePlaceDetails: (placeId: string) => void;
  normalizedData?: NormalizedGoogleMapsData;
  getPlaceFromQuery: (input: string) => void;
  placeLatLng?: google.maps.LatLngLiteral;
}

export const GooglePlacesContext = React.createContext<GooglePlacesContextProps>({
  addressPredictions: [],
  updatePredictions: (input: any) => {},
  updatePlaceDetails: (placeId: string) => {},
  normalizedData: undefined,
  getPlaceFromQuery: (input: string) => {},
  placeLatLng: undefined,
});

export interface UserSessionContextProps {
  userSession: any;
}

export const UserSessionContext = React.createContext<UserSessionContextProps>({
  userSession: null,
});
export const DraftClaimContext = React.createContext<DraftClaimContextProps>({});

export interface DraftClaimContextProps {
  draftClaimResponse?: DraftClaimResponse;
}

export interface DraftClaimResponse {
  lobs: Lobs;
  contacts: Contact[];
  policy: Policy;
  claimNumber: string;
  lossDate: string;
}

export interface FeatureFlags {
  featureFlags?: any;
}

export const FeatureFlagsContext = React.createContext<FeatureFlags>({});

export interface WithFeatureFlagsContext {
  children: ReactElement;
  featureFlags: any;
}

export const WithFeatureFlagsContext = ({children, featureFlags}: WithFeatureFlagsContext) => {
  return (
    <FeatureFlagsContext.Provider value={{featureFlags}}>{children}</FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => {
  return useContext(FeatureFlagsContext);
};
