import React, {ReactChild} from 'react';
import {DraftClaimContext, DraftClaimContextProps} from '../Providers/Providers';

export interface WithDraftClaimContextProps {
  children: ReactChild;
}

export const WithDraftClaimContext = ({
  draftClaimResponse,
  children,
}: WithDraftClaimContextProps & DraftClaimContextProps) => (
  <DraftClaimContext.Provider value={{draftClaimResponse}}>{children}</DraftClaimContext.Provider>
);
