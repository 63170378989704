import {Box, Grid} from '@material-ui/core';
import React, {useState} from 'react';
import {AreYouTheOwner} from '../AreYouTheOwner/AreYouTheOwner';
import {WereYouTheDriver} from '../WereYouTheDriver/WereYouTheDriver';

export const VehicleOwnerAndDriver = () => {
  const [ownerId, setOwnerId] = useState<string>('');

  return (
    <>
      <Grid item xs={12}>
        <Box pb={4} pt={1}>
          <AreYouTheOwner setVehicleOwnerId={setOwnerId} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box pb={4} pt={1}>
          <WereYouTheDriver ownerId={ownerId} />
        </Box>
      </Grid>
    </>
  );
};
