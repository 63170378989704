import {Typography} from '@material-ui/core';
import React from 'react';
import {capitalize} from '../../../../../utils/utils';
import {PhoneNumber} from '../../types';
import {SummaryField} from '../SummaryField/SummaryField';

interface MultiplePhoneNumbersSummaryProps {
  phoneNumbers: PhoneNumber[];
}

export const MultiplePhoneNumbersSummary = ({phoneNumbers}: MultiplePhoneNumbersSummaryProps) => {
  return (
    <>
      {phoneNumbers.map(singleNumber => {
        return (
          <SummaryField label={`${capitalize(singleNumber.phoneType || '')} Number`}>
            <Typography>{`${singleNumber.phoneNumber}`}</Typography>
          </SummaryField>
        );
      })}
    </>
  );
};
