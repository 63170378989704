import React, {useState} from 'react';
import {Box, Typography, Grid, Divider} from '@material-ui/core';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {
  DynamicContainer,
  DynamicContainerProps,
} from '../../common/DynamicContainer/DynamicContainer';
import {useFormContext, useFieldArray, UseFieldArrayMethods, ArrayField} from 'react-hook-form';
import {BlueContainer} from '../../common/Containers/Containers';
import {
  stateValidation,
  zipCodeValidation,
  emailValidation,
  cityValidation,
  streetAddressValidation,
  OptionalPhoneNumbersSchema,
  lastNameValidation,
  firstNameValidation,
} from '../../../validations';
import * as yup from 'yup';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {SelectTriage} from '../../common/SelectTriage/SelectTriage';
import {YesNoUnk} from '../../common/YesNoUnk/YesNoUnk';
import {makePersonaCheck, personaSchemaSwitch} from '../../../utils/utils';
import {CustomerTypes, Lobs} from '../../../commonTypes';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {ContactInfo} from '../../common/ContactInfo/ContactInfo';
import {ContactSections, FnolFormContact} from '../../common/ContactInfo/types';
import {SABox, SAColumns} from '@saux/design-system-react';
import {
  useRemoveSectionOccurrences,
  useManageContactOccurrences,
  useUserAtomState,
} from '../../../atoms';
import {BaseSwitch} from '../../common/BaseSwitch/BaseSwitch';

const schema = yup.array().of(
  yup.object().shape({
    injuredId: yup.string().required('Please select who was injured'),
    firstName: firstNameValidation().required('First Name is required'),
    lastName: lastNameValidation().required('Last Name is required'),
    autocompleteAddress: yup.string().nullable(),
    manualAddressEntry: yup.string().nullable(),
    address: streetAddressValidation().nullable(),
    city: cityValidation(true),
    state: stateValidation().nullable(),
    zipCode: zipCodeValidation(true),
    emailAddress: emailValidation(true),
    injuryLevel: personaSchemaSwitch({
      is: (value: any) => makePersonaCheck(value, [CustomerTypes.Associate]),
      then: yup.string().required('Please select an injury level'),
      otherwise: yup.string().nullable(),
    }),
    ...OptionalPhoneNumbersSchema,
  })
);

export const AnyoneElseInjuredSchema = {
  additionalInjured: yup.array().when('$acknowledgement', {
    is: (value: boolean | undefined) => value === true,
    then: schema,
  }),
};

export interface AdditionalInjuredProps extends DynamicContainerProps {
  fieldArray: UseFieldArrayMethods<Record<string, any>, 'id'>;
  index: number;
  field: Partial<ArrayField<Record<string, any>, 'id'>>;
}

export const AdditionalInjured = ({fieldArray, index, addController}: AdditionalInjuredProps) => {
  const {register, errors} = useFormContext();
  const {remove, fields} = fieldArray;
  const [injuredId, setInjuredId] = useState<string>('');
  const {removeContactOccurrences} = useManageContactOccurrences();
  const [userAtomState] = useUserAtomState();
  const lob = userAtomState?.gettingStarted?.lob;

  const getHeader = () => {
    const defaultHeader =
      index === 0 ? 'Injured Person Details' : `Injured Person Details ${index + 1}`;
    switch (lob) {
      case Lobs.Auto:
        return defaultHeader;
      case Lobs.Homeowners:
        return 'Who was hurt?';
      default:
        return defaultHeader;
    }
  };

  const onInjuredChange = (contact: FnolFormContact) => {
    setInjuredId(contact?.fnolId || '');
  };

  const removeInjured = () => {
    removeContactOccurrences([{id: injuredId, sections: [ContactSections.AdditionalInjured]}]);
    remove(index);
  };

  return (
    <DynamicContainer
      header={getHeader()}
      displayAddButton={index === fields.length - 1}
      addButtonText="ADD INJURED PERSON"
      addController={addController}
      displayRemoveButton={fields.length !== 1}
      removeButtonText="REMOVE"
      removeController={() => removeInjured()}
    >
      <Box pb={3} pt={2}>
        <ContactInfo
          section={ContactSections.AdditionalInjured}
          parentFieldName={`additionalInjured[${index}]`}
          selectProps={{
            name: 'injuredId',
            label: 'Injured Person',
            id: `${(index || 0) + 1}-injuredId`,
            inputProps: {'aria-label': `${(index || 0) + 1}-Injured Person`},
          }}
          errorsObj={errors?.additionalInjured?.[index]}
          onContactChange={onInjuredChange}
          hiddenFields={{
            email: {render: true, name: 'emailAddress'},
            phoneNumbers: {render: true},
            address: {render: true},
            city: {render: true},
            state: {render: true},
            zipCode: {render: true},
          }}
        />
        <BaseSwitch
          matches={lob === Lobs.Auto}
          then={
            <UserPersonaSwitch
              ifPersonas={[CustomerTypes.Associate]}
              then={
                <SABox py="medium">
                  <SAColumns columns={{xs: [12], sm: [6], md: [4]}}>
                    <SelectTriage
                      label="Injury Level"
                      id="injuryLevel"
                      name={`additionalInjured[${index}].injuryLevel`}
                      error={errors.additionalInjured?.[index]?.hasOwnProperty('injuryLevel')}
                      helperText={errors.additionalInjured?.[index]?.injuryLevel?.message}
                      inputRef={register()}
                    />
                  </SAColumns>
                </SABox>
              }
            />
          }
        />
      </Box>
    </DynamicContainer>
  );
};

export const AnyoneElseInjuredTestSchema = yup.object().shape({
  additionalInjured: schema,
});

export const AnyoneElseInjured = () => {
  const {control} = useFormContext();
  const fieldArray = useFieldArray({
    control,
    name: 'additionalInjured',
  });
  const [hasSelectedYes, setHasSelectedYes] = useState(false);
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType as CustomerTypes;
  const removeAdditionalInjuredOccurrences = useRemoveSectionOccurrences(
    ContactSections.AdditionalInjured
  );

  const addAdditionalInjured = () => {
    const newInjured = {
      injuredId: '',
      firstName: '',
      lastName: '',
      autocompleteAddress: '',
      manualAddressEntry: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      emailAddress: '',
      phoneNumbers: [{phoneNumber: '', phoneType: '', verifiedNumber: 'false'}],
    };

    fieldArray.append(
      userPersona === CustomerTypes.Associate ? {...newInjured, injuryLevel: ''} : {...newInjured},
      false
    );
  };

  const handleChange = (value: string): void => {
    const yesIsSelected = value === 'yes';

    setHasSelectedYes(yesIsSelected);

    if (yesIsSelected) {
      addAdditionalInjured();
    } else {
      fieldArray.remove();
      removeAdditionalInjuredOccurrences();
    }
  };

  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.AnyoneElseInjured}
      displayName={WAYPOINTS[WaypointNames.AnyoneElseInjured].displayName}
    >
      <FormContainer header="Anyone else injured">
        <Box>
          <Box pb={2}>
            <Typography variant="body2" data-testid="anyone-else-injured">
              Was anyone else injured?
            </Typography>
          </Box>
          <Box pb={3}>
            <YesNoUnk name="wasAnyoneElseInjured" onChange={handleChange} />
          </Box>
          {hasSelectedYes && (
            <BlueContainer>
              {fieldArray.fields.map((field, index) => (
                <Box key={field.id}>
                  {index > 0 && (
                    <Grid item xs={12}>
                      <Box pb={2}>
                        <Divider variant="middle" />
                      </Box>
                    </Grid>
                  )}
                  <AdditionalInjured
                    fieldArray={fieldArray}
                    index={index}
                    field={field}
                    addController={addAdditionalInjured}
                  />
                </Box>
              ))}
            </BlueContainer>
          )}
        </Box>
      </FormContainer>
    </RegisterBreadcrumb>
  );
};
