import TagManager from 'react-gtm-module';

export interface BaseSnowplowProps {
  logrocketurl?: string;
  persona?: string;
}
export interface SnowplowClaimNumber {
  claimNumber?: string;
}

export interface SnowplowAssociateClaimInfo {
  name?: string;
  email?: string;
}

export interface RideshareOrDeliveryInfo {
  rideshareOrDelivery?: string;
}

export interface SnowplowQuickEstimateTrigger {
  quickEstimateAccepted?: string;
}

export interface SnowplowLoginTrigger {
  isCompanyPolicy?: boolean;
  payload?: {
    policyNumber?: string | undefined;
    companyName?: string;
    firstName?: string;
    lastName?: string;
    lossDate?: string;
    lossTime?: string;
  };
  data?: {
    //TODO: create a Type for what this data could be
    data?: any;
  };
  isAuthedUser?: boolean;
}

export interface SnowplowCustomerFeedback {
  email?: string;
  customerFeedback?: string;
  customerRating?: string;
}

export interface SnowplowDateOfLossUnk {
  dateOfLossUnknown?: boolean;
}

export interface SnowplowDuplicateClaims {
  policyNumber: string;
  searchingAgain?: boolean | undefined;
  continuingToForm?: boolean | undefined;
  viewingClaim?: boolean | undefined;
  claimNumbers?: string[] | undefined;
  selectedClaim?: string | undefined;
  isAssociate?: boolean | undefined;
  expanedAccordion?: boolean | undefined;
}

export interface SearchForPolicy {
  searchType: string;
  result: any;
}

export interface SnowplowVerifyPhNumber {
  phoneNumber: string;
  isValidMobileNumber: boolean;
  persona: string;
  reporter: string;
}

export interface Epay {
  epay: string;
}

export interface LawsuitClaimProps {
  lawsuitClaim: boolean;
}

export interface StpSummaryScreen {
  policyNumber: string;
  appraisalType: string;
}

export interface StpSummaryUrl {
  stpSummaryUrl: string;
}

export class Snowplow {
  static track = new Snowplow();

  verifiedPhoneNumber({
    phoneNumber,
    isValidMobileNumber,
    persona,
    reporter,
  }: SnowplowVerifyPhNumber & BaseSnowplowProps) {
    TagManager.dataLayer({
      dataLayer: {
        phoneNumber,
        isValidMobileNumber,
        persona,
        reporter,
        event: 'verifiedPhoneNumber',
      },
    });
  }

  quickEstimate({
    quickEstimateAccepted,
    claimNumber,
    logrocketurl,
    persona,
  }: SnowplowQuickEstimateTrigger & SnowplowClaimNumber & BaseSnowplowProps) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'quickEstimate',
        quickEstimateAccepted,
        claimNumber,
        logrocketurl: logrocketurl || 'N/A',
        persona: persona || 'N/A',
      },
    });
  }

  login({
    isCompanyPolicy,
    payload,
    data,
    isAuthedUser,
    logrocketurl,
    persona,
  }: SnowplowLoginTrigger & BaseSnowplowProps) {
    TagManager.dataLayer({
      dataLayer: {
        policyNumber: payload?.policyNumber,
        name:
          isCompanyPolicy && !isAuthedUser
            ? payload?.companyName
            : `${payload?.firstName} ${payload?.lastName}`,
        incidentDate: payload?.lossDate,
        incidentTime: payload?.lossTime,
        isCompany: isCompanyPolicy,
        event: 'login',
        success: data?.data === false ? false : true,
        logrocketurl: logrocketurl || 'N/A',
        persona: persona || 'N/A',
      },
    });
  }

  searchForPolicy({
    searchType,
    result,
    logrocketurl,
    persona,
  }: SearchForPolicy & BaseSnowplowProps) {
    TagManager.dataLayer({
      dataLayer: {
        searchType,
        result,
        event: 'searchForPolicy',
        logrocketurl: logrocketurl || 'N/A',
        persona: persona || 'N/A',
      },
    });
  }

  submitClaim({
    claimNumber,
    logrocketurl,
    persona,
    rideshareOrDelivery,
    epay,
  }: SnowplowClaimNumber & BaseSnowplowProps & RideshareOrDeliveryInfo & Epay) {
    TagManager.dataLayer({
      dataLayer: {
        claimNumber,
        rideshareOrDelivery,
        event: 'submitClaim',
        logrocketurl: logrocketurl || 'N/A',
        persona: persona || 'N/A',
        epay,
      },
    });
  }

  submitClaimWithAssociateInfo({
    claimNumber,
    name,
    email,
    rideshareOrDelivery,
    logrocketurl,
    persona,
    epay,
  }: SnowplowClaimNumber &
    SnowplowAssociateClaimInfo &
    BaseSnowplowProps &
    RideshareOrDeliveryInfo &
    Epay) {
    TagManager.dataLayer({
      dataLayer: {
        claimNumber,
        name,
        email,
        rideshareOrDelivery,
        event: 'submitClaim',
        logrocketurl: logrocketurl || 'N/A',
        persona: persona || 'N/A',
        epay,
      },
    });
  }

  customerFeedback({
    claimNumber,
    email,
    customerFeedback,
    customerRating,
    logrocketurl,
    persona,
  }: SnowplowClaimNumber & SnowplowCustomerFeedback & BaseSnowplowProps) {
    TagManager.dataLayer({
      dataLayer: {
        claimNumber,
        event: 'customerFeedback',
        email,
        customerFeedback,
        customerRating,
        logrocketurl: logrocketurl || 'N/A',
        persona: persona || 'N/A',
      },
    });
  }

  dateOfLossUnknown({
    dateOfLossUnknown,
    logrocketurl,
    persona,
  }: SnowplowDateOfLossUnk & BaseSnowplowProps) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'dateOfLossUnknown',
        dateOfLossUnknown,
        logrocketurl: logrocketurl || 'N/A',
        persona: persona || 'N/A',
      },
    });
  }

  duplicateClaims({
    claimNumbers,
    searchingAgain,
    continuingToForm,
    viewingClaim,
    policyNumber,
    selectedClaim,
    isAssociate,
    expanedAccordion,
    logrocketurl,
    persona,
  }: SnowplowDuplicateClaims & BaseSnowplowProps) {
    TagManager.dataLayer({
      dataLayer: {
        claimNumbers,
        selectedClaim,
        isAssociate: isAssociate ? 'yes' : 'no',
        event: 'duplicateClaims',
        searchingAgain: searchingAgain ? 'yes' : 'no',
        continuingToForm: continuingToForm ? 'yes' : 'no',
        viewingClaim: viewingClaim ? 'yes' : 'no',
        ...(expanedAccordion ? {expanedAccordion: expanedAccordion ? 'yes' : 'no'} : {}),
        policyNumber,
        logrocketurl: logrocketurl || 'N/A',
        persona: persona || 'N/A',
      },
    });
  }

  lawsuitClaim({
    lawsuitClaim,
    claimNumber,
    name,
    logrocketurl,
    persona,
  }: BaseSnowplowProps & SnowplowClaimNumber & SnowplowAssociateClaimInfo & LawsuitClaimProps) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'lawsuitClaim',
        logrocketurl: logrocketurl || 'N/A',
        persona: persona || 'N/A',
        claimNumber,
        name,
        lawsuitClaim,
      },
    });
  }

  stpSummaryScreen({
    persona,
    name,
    email,
    logrocketurl,
    claimNumber,
    policyNumber,
    appraisalType,
  }: BaseSnowplowProps & SnowplowAssociateClaimInfo & SnowplowClaimNumber & StpSummaryScreen) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'StpSummaryScreen',
        logrocketurl: logrocketurl || 'N/A',
        persona: persona || 'N/A',
        name: name || 'N/A',
        email: email || 'N/A',
        claimNumber,
        policyNumber,
        appraisalType,
      },
    });
  }

  stpSummaryUrl({
    persona,
    name,
    email,
    logrocketurl,
    claimNumber,
    policyNumber,
    appraisalType,
    stpSummaryUrl,
  }: BaseSnowplowProps &
    SnowplowAssociateClaimInfo &
    SnowplowClaimNumber &
    StpSummaryScreen &
    StpSummaryUrl) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'StpSummaryUrl',
        logrocketurl: logrocketurl || 'N/A',
        persona: persona || 'N/A',
        name: name || 'N/A',
        email: email || 'N/A',
        claimNumber,
        policyNumber,
        appraisalType,
        stpSummaryUrl,
      },
    });
  }

  noticeOnlyClaim({
    isNoticeOnly,
    claimNumber,
    name,
    email,
    logrocketurl,
    persona,
  }: BaseSnowplowProps &
    SnowplowClaimNumber &
    SnowplowAssociateClaimInfo & {isNoticeOnly: boolean}) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'noticeOnlyClaim',
        logrocketurl: logrocketurl || 'N/A',
        persona: persona || 'N/A',
        claimNumber,
        name,
        email,
        isNoticeOnly,
      },
    });
  }
}
