import React, {useContext} from 'react';
import {BaseSwitch, SwitchProps} from '../../../common/BaseSwitch/BaseSwitch';
import {UserPersonaContext} from '../../../common/Providers/Providers';
import {Option} from '../../../common/DynamicCheckboxList/DynamicCheckboxList';
import {RadioOption} from '../../../common/DynamicRadioList/DynamicRadioList';

export interface IncidentSwitchProps {
  incidentOption: Option | RadioOption;
}

export const IncidentSwitch = (props: SwitchProps & IncidentSwitchProps) => {
  const {userPersona} = useContext(UserPersonaContext);
  const incidentPersonas = props.incidentOption.personas;
  const matchesPersona = () => {
    return incidentPersonas && userPersona ? incidentPersonas.includes(userPersona) : true;
  };

  return <BaseSwitch matches={matchesPersona()} then={props.then} otherwise={props.otherwise} />;
};
