import React from 'react';
import {Grid, Typography, makeStyles} from '@material-ui/core';
import {formatCityStateZip} from '../../../utils/utils';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#041E41',
  },
}));

export interface AddressSummaryProps {
  label: string;
  addressLine1?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  postalCode?: string | undefined;
}

export const AddressSummary = (props: AddressSummaryProps) => {
  const classes = useStyles();

  const hasAddress = () => {
    const {addressLine1, city, state, postalCode} = props;
    return addressLine1 || city || state || postalCode;
  };

  return (
    <>
      {hasAddress() && (
        <Grid item xs={12} sm={6} md={4}>
          <Typography data-testid="driverDetailsAddressLabel" className={classes.label}>
            {props.label}
          </Typography>
          {props?.addressLine1 !== '' && <Typography>{props?.addressLine1}</Typography>}
          <Typography>
            {formatCityStateZip({city: props.city, state: props.state, zipCode: props.postalCode})}
          </Typography>
        </Grid>
      )}
    </>
  );
};
