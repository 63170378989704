import {SABox, SAColumns} from '@saux/design-system-react';
import React, {useState} from 'react';
import {useFormContactAtomState} from '../../../atoms';
import {capitalize, formatCityStateZip} from '../../../utils/utils';
import {ContactInfoField} from './components/ContactInfoField';
import {ContactInfoHeader} from './components/ContactInfoHeader';
import {BaseContactInfoProps, ContactSelect} from './components/ContactSelect';
import {FormContactPhone} from './types';
import {ContactTypes} from '../../../commonTypes';
import {useFormContext} from 'react-hook-form';
import {BaseSwitch} from '../BaseSwitch/BaseSwitch';

interface ContactHiddenField {
  render: boolean;
  name?: string;
}

interface ContactInfoProps extends BaseContactInfoProps {
  parentFieldName: string;
  errorsObj?: any;
  hiddenFields?: {
    contactType?: ContactHiddenField;
    email?: ContactHiddenField;
    phoneNumbers?: ContactHiddenField;
    address?: ContactHiddenField;
    city?: ContactHiddenField;
    state?: ContactHiddenField;
    zipCode?: ContactHiddenField;
  };
}

export const ContactInfo = ({
  parentFieldName,
  errorsObj,
  hiddenFields,
  selectProps,
  section,
  filterCriteria,
  additionalOptions,
  defaultValue,
  onContactChange,
}: ContactInfoProps) => {
  const {register} = useFormContext();
  const [contactId, setContactId] = useState<string>('');
  const [formContactAtomState] = useFormContactAtomState(contactId);
  const hasAddress =
    formContactAtomState?.contactAddress?.address ||
    formContactAtomState?.contactAddress?.city ||
    formContactAtomState?.contactAddress?.state ||
    formContactAtomState?.contactAddress?.zipCode;
  const hasEmail = formContactAtomState?.email;
  const showHeader =
    hasAddress ||
    hasEmail ||
    (formContactAtomState?.phoneNumbers?.[0]?.phoneNumber &&
      formContactAtomState?.phoneNumbers?.[0]?.phoneType);

  const contactTypeName = hiddenFields?.contactType?.name || 'contactType';
  const emailName = hiddenFields?.email?.name || 'email';
  const addressName = hiddenFields?.address?.name || 'address';
  const cityName = hiddenFields?.city?.name || 'city';
  const stateName = hiddenFields?.state?.name || 'state';
  const zipName = hiddenFields?.zipCode?.name || 'zipCode';

  const selectName = `${parentFieldName}.${selectProps.name}`;
  const firstNameFieldName = `${parentFieldName}.firstName`;
  const lastNameFieldName = `${parentFieldName}.lastName`;
  const companyNameFieldName = `${parentFieldName}.companyName`;
  const phoneNumbersFieldName = `${parentFieldName}.phoneNumbers`;
  const contactTypeFieldName = `${parentFieldName}.${contactTypeName}`;
  const emailFieldName = `${parentFieldName}.${emailName}`;
  const addressFieldName = `${parentFieldName}.${addressName}`;
  const cityFieldName = `${parentFieldName}.${cityName}`;
  const stateFieldName = `${parentFieldName}.${stateName}`;
  const zipFieldName = `${parentFieldName}.${zipName}`;

  return (
    <>
      <SABox pb="large">
        <ContactSelect
          section={section}
          setFormContactId={setContactId}
          filterCriteria={filterCriteria}
          additionalOptions={additionalOptions}
          defaultValue={defaultValue}
          onContactChange={onContactChange}
          fieldNames={[
            selectName,
            contactTypeFieldName,
            firstNameFieldName,
            lastNameFieldName,
            companyNameFieldName,
            emailFieldName,
            addressFieldName,
            cityFieldName,
            stateFieldName,
            zipFieldName,
            phoneNumbersFieldName,
          ]}
          selectProps={{
            ...selectProps,
            name: selectName,
            error:
              errorsObj?.hasOwnProperty(selectProps.name) ||
              errorsObj?.hasOwnProperty('firstName') ||
              errorsObj?.hasOwnProperty('lastName') ||
              errorsObj?.hasOwnProperty('companyName') ||
              errorsObj?.hasOwnProperty(contactTypeName) ||
              errorsObj?.hasOwnProperty(emailName) ||
              errorsObj?.hasOwnProperty(addressName) ||
              errorsObj?.hasOwnProperty(cityName) ||
              errorsObj?.hasOwnProperty(stateName) ||
              errorsObj?.hasOwnProperty(zipName) ||
              errorsObj?.phoneNumbers?.[0]?.hasOwnProperty('phoneNumber') ||
              errorsObj?.phoneNumbers?.[0]?.hasOwnProperty('phoneType') ||
              errorsObj?.phoneNumbers?.[1]?.hasOwnProperty('phoneNumber') ||
              errorsObj?.phoneNumbers?.[1]?.hasOwnProperty('phoneType') ||
              errorsObj?.phoneNumbers?.[2]?.hasOwnProperty('phoneNumber') ||
              errorsObj?.phoneNumbers?.[2]?.hasOwnProperty('phoneType'),
            helperText:
              errorsObj?.[selectProps.name || '']?.message ||
              errorsObj?.firstName?.message ||
              errorsObj?.lastName?.message ||
              errorsObj?.companyName?.message ||
              errorsObj?.[contactTypeName]?.message ||
              errorsObj?.[emailName]?.message ||
              errorsObj?.[addressName]?.message ||
              errorsObj?.[cityName]?.message ||
              errorsObj?.[stateName]?.message ||
              errorsObj?.[zipName]?.message ||
              errorsObj?.phoneNumbers?.[0]?.phoneNumber?.message ||
              errorsObj?.phoneNumbers?.[0]?.phoneType?.message ||
              errorsObj?.phoneNumbers?.[1]?.phoneNumber?.message ||
              errorsObj?.phoneNumbers?.[1]?.phoneType?.message ||
              errorsObj?.phoneNumbers?.[2]?.phoneNumber?.message ||
              errorsObj?.phoneNumbers?.[2]?.phoneType?.message,
          }}
        />
      </SABox>
      {showHeader && (
        <SABox pb="medium">
          <ContactInfoHeader />
        </SABox>
      )}
      <SAColumns
        columns={{xs: [12, 12, 12, 12, 12], sm: [6, 6, 6, 6, 6], md: [4, 4, 4, 4, 4]}}
        spacing="medium"
      >
        {formContactAtomState?.phoneNumbers?.map(
          (phone: FormContactPhone) =>
            phone?.phoneNumber &&
            phone?.phoneType && (
              <ContactInfoField
                key={`${phone.phoneNumber}-${phone.phoneType}-${formContactAtomState.fnolId}`}
                header={`${capitalize(phone.phoneType)} Number`}
                data={[phone.phoneNumber]}
              />
            )
        )}
        {hasEmail && (
          <ContactInfoField header="Email Address" data={[formContactAtomState.email]} />
        )}
        {hasAddress && (
          <ContactInfoField
            header="Address"
            data={[
              formContactAtomState?.contactAddress?.address,
              formatCityStateZip({
                city: formContactAtomState?.contactAddress?.city,
                state: formContactAtomState?.contactAddress?.state,
                zipCode: formContactAtomState?.contactAddress?.zipCode,
              }),
            ]}
          />
        )}
      </SAColumns>
      <BaseSwitch
        matches={formContactAtomState.contactType === ContactTypes.Person}
        then={
          <>
            <input
              type="hidden"
              name={firstNameFieldName}
              value={formContactAtomState.firstName}
              ref={register()}
              data-testid={firstNameFieldName}
            />
            <input
              type="hidden"
              name={lastNameFieldName}
              value={formContactAtomState.lastName}
              ref={register()}
              data-testid={lastNameFieldName}
            />
          </>
        }
        otherwise={
          <input
            type="hidden"
            name={companyNameFieldName}
            value={formContactAtomState.companyName}
            ref={register()}
            data-testid={companyNameFieldName}
          />
        }
      />
      {hiddenFields?.contactType?.render && (
        <input
          type="hidden"
          name={contactTypeFieldName}
          value={formContactAtomState.contactType}
          ref={register()}
          data-testid={contactTypeFieldName}
        />
      )}
      {hiddenFields?.email?.render && (
        <input
          type="hidden"
          name={emailFieldName}
          value={formContactAtomState.email}
          ref={register()}
          data-testid={emailFieldName}
        />
      )}
      {hiddenFields?.address?.render && (
        <input
          type="hidden"
          name={addressFieldName}
          value={formContactAtomState.contactAddress?.address}
          ref={register()}
          data-testid={addressFieldName}
        />
      )}
      {hiddenFields?.city?.render && (
        <input
          type="hidden"
          name={cityFieldName}
          value={formContactAtomState.contactAddress?.city}
          ref={register()}
          data-testid={cityFieldName}
        />
      )}
      {hiddenFields?.state?.render && (
        <input
          type="hidden"
          name={stateFieldName}
          value={formContactAtomState.contactAddress?.state}
          ref={register()}
          data-testid={stateFieldName}
        />
      )}
      {hiddenFields?.zipCode?.render && (
        <input
          type="hidden"
          name={zipFieldName}
          value={formContactAtomState.contactAddress?.zipCode}
          ref={register()}
          data-testid={zipFieldName}
        />
      )}
      {hiddenFields?.phoneNumbers?.render &&
        formContactAtomState.phoneNumbers?.map((phone: FormContactPhone, index: number) => (
          <>
            <input
              type="hidden"
              name={`${phoneNumbersFieldName}[${index}].phoneNumber`}
              value={phone.phoneNumber}
              ref={register()}
              data-testid={`${phoneNumbersFieldName}[${index}].phoneNumber`}
            />
            <input
              type="hidden"
              name={`${phoneNumbersFieldName}[${index}].phoneType`}
              value={phone.phoneType}
              ref={register()}
              data-testid={`${phoneNumbersFieldName}[${index}].phoneType`}
            />
            <input
              type="hidden"
              name={`${phoneNumbersFieldName}[${index}].verifiedNumber`}
              value={phone.verifiedNumber}
              ref={register()}
              data-testid={`${phoneNumbersFieldName}[${index}].verifiedNumber`}
            />
          </>
        ))}
    </>
  );
};
