import React, {useEffect} from 'react';
import {PolicySearch} from '../components/PolicySearch/PolicySearch';
import {
  GlassRoadsidePanel,
  ChildRenderProps,
} from '../components/auto/GlassRoadside/GlassRoadsidePanel';
import {Lobs, CustomerTypes} from '../commonTypes';
import {useUserAtomState} from '../atoms';
import {SABox, SACard} from '@saux/design-system-react';
import styled from 'styled-components';

const SABoxCenter = styled(SABox)`
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
`;

const SACCard = styled(SACard)`
  justify-content: center;
  max-width: 750px;
  margin-top: 8vh;
  margin-bottom: 8vh;
  box-shadow: 4px 4px 10px grey !important;
`;
export const PolicySearchPage = () => {
  const [userAtomState] = useUserAtomState();

  const lob = userAtomState?.gettingStarted?.lob;
  const customerType = userAtomState?.gettingStarted?.customerType;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SABoxCenter display="flex">
      <SACCard variant="standard" title="Policy Information">
        {lob === Lobs.Auto && customerType === CustomerTypes.SaCustomer ? (
          <GlassRoadsidePanel data-testid="glassRoadsidePanel">
            {({canContinue, setCanContinue}: ChildRenderProps) =>
              canContinue && <PolicySearch setCanContinue={setCanContinue} />
            }
          </GlassRoadsidePanel>
        ) : (
          <PolicySearch />
        )}
      </SACCard>
    </SABoxCenter>
  );
};
