import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const DamageSpot = () => {
  return (
    <SvgIcon
      width="33px"
      height="33px"
      viewBox="0 0 33 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fontSize="inherit"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Damage-Selector" transform="translate(-571.000000, -71.000000)">
          <g id="Impact-Selectors-Red" transform="translate(64.000000, 71.000000)">
            <g id="Damage-Spot" transform="translate(507.000000, 0.000000)">
              <circle id="Oval" fill="#D0021B" cx="16" cy="16" r="16"></circle>
              <polygon
                id="Combined-Shape"
                fill="#FFFFFF"
                fillRule="nonzero"
                transform="translate(16.235417, 16.311875) rotate(42.000000) translate(-16.235417, -16.311875) "
                points="18.721875 8 18.721875 13.9370536 25.4708333 13.9370536 25.4708333 18.6866964 18.721875 18.6866964 18.721875 24.62375 13.7489583 24.62375 13.7489583 18.6866964 7 18.6866964 7 13.9370536 13.7489583 13.9370536 13.7489583 8"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
