import React from 'react';
import Alert from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/core/styles';
import {Warning} from '../Icon/assets/Warning';

const useStyles = makeStyles(theme => ({
  alertFont: {
    fontSize: 14,
    backgroundColor: 'rgb(255, 255, 255)',
    color: '#041E41',
    padding: '0px',
    margin: '0px',
  },
}));

interface alertInfo {
  showWarning: boolean;
  message: React.ReactElement | string;
}

export interface alertPanelProps {
  alert: alertInfo;
}

export const AlertPanel = (props: alertPanelProps) => {
  const severity = props.alert.showWarning ? 'warning' : 'error';

  const classes = useStyles();
  return (
    <Alert className={classes.alertFont} icon={<Warning />} severity={severity}>
      {props.alert.message}
    </Alert>
  );
};
