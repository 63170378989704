import React from 'react';
import {Box, Typography, Grid} from '@material-ui/core';
import {formatPhone} from '../../../utils/utils';
import {BlueContainer} from '../../common/Containers/Containers';
import {SummaryField} from '../../common/Summary/components/SummaryField/SummaryField';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';
import {InjuryIncident, SubmissionTypes} from '../../common/Summary/types';
import {PdfSection} from '../../common/Summary/components/PdfSection/PdfSection';
import {BaseSwitch} from '../../common/BaseSwitch/BaseSwitch';
import {AddressSummary} from '../VehicleSummary/AddressSummary';
import {SUMMARY_PDF_ID} from '../../pdf/constants';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';

export interface AnyoneElseInjuredSummaryData {
  injuryIncidents: InjuryIncident[];
  submissionType: SubmissionTypes;
}

export const AnyoneElseInjuredSummary = (props: AnyoneElseInjuredSummaryData) => {
  const headerText =
    props.submissionType === SubmissionTypes.Property
      ? 'Injured Person Details'
      : 'Anyone else injured';

  return (
    <Box>
      <RegisterBreadcrumb
        waypointName={WaypointNames.AnyoneElseInjured}
        displayName={WAYPOINTS[WaypointNames.AnyoneElseInjured].displayName}
      >
        <BaseSwitch
          matches={props?.injuryIncidents?.length > 0}
          then={props.injuryIncidents
            .filter(
              x =>
                (props.submissionType === SubmissionTypes.Collision &&
                  x.injuryType === 'additionalInjury') ||
                props.submissionType === SubmissionTypes.Property
            )
            .map((injuryIncident: InjuryIncident, index: number) => (
              <PdfSection className={SUMMARY_PDF_ID}>
                <>
                  {index === 0 && (
                    <>
                      <SummaryHeader headerText={headerText} />
                      <Box py={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <SummaryField label="Was anyone else injured?">
                              <Typography>Yes</Typography>
                            </SummaryField>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                  {index > 0 && <Box pt={3} />}
                  <BlueContainer>
                    <SummaryHeader forBlueContainer headerText="Injured Person Details" />
                    <Box pt={3}>
                      <Grid container spacing={3}>
                        {injuryIncident?.injured?.displayName && (
                          <Grid item xs={12} sm={6} md={4}>
                            <SummaryField label="Name" testId="test-injured-summary-name">
                              <Typography>{injuryIncident.injured.displayName}</Typography>
                            </SummaryField>
                          </Grid>
                        )}
                        {injuryIncident?.injured?.cellNumber && (
                          <Grid item xs={12} sm={6} md={4}>
                            <SummaryField
                              label="Mobile Number"
                              testId="injured-summary-cell-number"
                            >
                              <Typography>
                                {formatPhone(injuryIncident.injured.cellNumber)}
                              </Typography>
                            </SummaryField>
                          </Grid>
                        )}
                        {injuryIncident?.injured?.workNumber && (
                          <Grid item xs={12} sm={6} md={4}>
                            <SummaryField label="Work Number" testId="injured-summary-work-number">
                              <Typography>
                                {formatPhone(injuryIncident.injured.workNumber)}
                              </Typography>
                            </SummaryField>
                          </Grid>
                        )}
                        {injuryIncident?.injured?.homeNumber && (
                          <Grid item xs={12} sm={6} md={4}>
                            <SummaryField label="Home Number" testId="injured-summary-home-number">
                              <Typography>
                                {formatPhone(injuryIncident.injured.homeNumber)}
                              </Typography>
                            </SummaryField>
                          </Grid>
                        )}
                        {injuryIncident?.injured?.emailAddress1 && (
                          <Grid item xs={12} sm={6} md={4}>
                            <SummaryField label="Email Address">
                              <Typography>{injuryIncident.injured.emailAddress1}</Typography>
                            </SummaryField>
                          </Grid>
                        )}
                        <AddressSummary
                          label="Address"
                          addressLine1={injuryIncident?.injured?.primaryAddress?.addressLine1}
                          city={injuryIncident?.injured?.primaryAddress?.city}
                          state={injuryIncident?.injured?.primaryAddress?.state}
                          postalCode={injuryIncident?.injured?.primaryAddress?.postalCode}
                        />
                      </Grid>
                    </Box>
                  </BlueContainer>
                </>
              </PdfSection>
            ))}
          otherwise={
            <PdfSection className={SUMMARY_PDF_ID}>
              <SummaryHeader headerText={headerText} />
              <Box py={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <SummaryField label="Was anyone else injured?">
                      <Typography data-testid="noInjuries">No</Typography>
                    </SummaryField>
                  </Grid>
                </Grid>
              </Box>
            </PdfSection>
          }
        />
      </RegisterBreadcrumb>
    </Box>
  );
};
