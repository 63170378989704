import React, {ChangeEvent, useContext, useEffect, useState, useRef} from 'react';
import Autocomplete, {AutocompleteInputChangeReason} from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import {
  GoogleMapsAutocompleteData,
  NormalizedGoogleMapsData,
} from '../WithGoogleMaps/WithGoogleMaps';
import {SATextField} from '../TextField/TextField';
import './google-maps-autocomplete.css';
import {Controller, useFormContext} from 'react-hook-form';
import {GooglePlacesContext} from '../Providers/Providers';
import {formatAddressParts, scrollToRef} from '../../../utils/utils';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  textField: {
    padding: 0,
  },
}));

interface HiddenAddressFieldNames {
  addressName: string;
  cityName: string;
  stateName: string;
  zipCodeName: string;
  latLngName?: string;
}

export interface GoogleMapsAutocompleteProps {
  // getGoogleMapsAutocompleteResult: (address: string) => Promise<GoogleMapsAutocompleteData[]>;
  // getNormalizedData: (selection: string) => void;
  name?: string;
  label?: string;
  id: string;
  InputLabelProps?: any;
  inputProps?: any;
  inputRef?: any;
  error?: any;
  helperText?: any;
  value?: string;
  defaultValue?: string;
  onChange?: any;
  onBlur?: any;
  autoFocus?: boolean | undefined;
  isCrossSection?: boolean | undefined;
  forFullAddress?: boolean | undefined;
  hiddenFieldNames?: HiddenAddressFieldNames;
  onNormalizedDataChange?: (data: NormalizedGoogleMapsData | undefined) => any;
  defaultNormalizedData?: NormalizedGoogleMapsData;
  getGAData?: boolean | undefined;
  getLatLng?: boolean;
}

export const GoogleMapsAutocomplete = (props: GoogleMapsAutocompleteProps) => {
  const classes = useStyles();
  const [autocompleteValue, setAutocompleteValue] = React.useState<
    string | GoogleMapsAutocompleteData | null
  >('');
  const {control, setValue, register, trigger} = useFormContext();
  const {addressPredictions, updatePredictions, updatePlaceDetails, normalizedData} =
    useContext(GooglePlacesContext);
  const [currentInputValue, setCurrentInputValue] = useState<string>('');

  useEffect(() => {
    if (props.forFullAddress) {
      props.onNormalizedDataChange && props.onNormalizedDataChange(normalizedData);
    }
  }, [normalizedData]);

  const setACValues = (value: string) => {
    setValue(props.name || '', value);
    setCurrentInputValue(value);
    setAutocompleteValue(value);
  };

  const handleAddressChange = (newValue: string | GoogleMapsAutocompleteData | null) => {
    if (newValue?.hasOwnProperty('description')) {
      const value = newValue as GoogleMapsAutocompleteData;
      const description = value.description;
      let modifiedDescription;

      if (!props.forFullAddress) {
        modifiedDescription = description.replace(/,.*/, '');
      } else {
        const lastCommaIndex = description.lastIndexOf(',');
        modifiedDescription = description.slice(0, lastCommaIndex);
      }

      setACValues(modifiedDescription);
      updatePlaceDetails(value.place_id);
      props.onChange && props.onChange(props.getGAData ? value : modifiedDescription);
    } else {
      if (props.forFullAddress) {
        setACValues('');
        props.onChange && props.onChange('');
      } else {
        setValue(props.name || '', newValue);
        props.onChange && props.onChange(props.name || '', newValue);
      }
    }
    trigger(props.name || '');
  };

  const [noPredictions, setNoPredictions] = useState<boolean>(false);

  useEffect(() => {
    setNoPredictions(addressPredictions === null || addressPredictions?.length === 0);
  }, [addressPredictions]);

  const errorRef: React.Ref<any> = useRef(null);

  useEffect(() => {
    if (normalizedData) {
      trigger([
        props.hiddenFieldNames?.addressName,
        props.hiddenFieldNames?.cityName,
        props.hiddenFieldNames?.stateName,
        props.hiddenFieldNames?.zipCodeName,
      ] as string[]);
    }
  }, [normalizedData]);

  useEffect(() => {
    if (props.error) {
      scrollToRef(errorRef);
    }
  }, [props.error]);

  useEffect(() => {
    if (
      props.defaultNormalizedData?.address ||
      props.defaultNormalizedData?.city ||
      props.defaultNormalizedData?.state
    ) {
      const {address, city, state} = props.defaultNormalizedData;

      setACValues(formatAddressParts({addressLine1: address, city, state}));
    }
  }, [props.defaultNormalizedData]);

  return (
    <>
      {props.error && <div ref={errorRef} />}
      <Autocomplete
        id={props.id}
        freeSolo
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.description.replace(/,.*/, '')
        }
        filterOptions={x => {
          return x || [];
        }}
        options={addressPredictions || []}
        autoComplete
        autoSelect
        autoHighlight
        value={autocompleteValue}
        onChange={(event: ChangeEvent<{}>, newValue: string | GoogleMapsAutocompleteData | null) =>
          handleAddressChange(newValue)
        }
        inputValue={currentInputValue}
        onInputChange={(
          event: ChangeEvent<{}>,
          newInputValue: string,
          reason: AutocompleteInputChangeReason
        ) => {
          if (reason === 'input' || reason === 'clear') {
            updatePredictions(newInputValue);
            setCurrentInputValue(newInputValue);
            if (reason === 'clear') {
              setNoPredictions(false);
              setAutocompleteValue(newInputValue);
              setValue(props.name || '', newInputValue);
            }
          }
        }}
        renderInput={params => (
          <Controller
            name={props.name || ''}
            control={control}
            defaultValue={props.defaultValue || ''}
            as={
              <SATextField
                {...params}
                className={classes.textField}
                autoFocus={props.autoFocus}
                data-testid="autocomplete"
                name={props.name}
                label={
                  props.label ||
                  (props.forFullAddress
                    ? 'Street Name/Address, City, State'
                    : 'Street Name/Address')
                }
                InputLabelProps={{
                  ...params.InputLabelProps,
                  ...props.InputLabelProps,
                  'aria-labelledby': props.id,
                }}
                error={props.error || noPredictions}
                helperText={
                  props.helperText ||
                  (noPredictions
                    ? 'If you do not see the desired address, please enter it manually'
                    : props.forFullAddress
                    ? 'A Street / 123 A Street / A Street & G Road, City, State'
                    : 'optional')
                }
                value={props.value}
                inputProps={{
                  autocomplete: 'none',
                  ...params.inputProps,
                  ...props.inputProps,
                }}
                onBlur={props.onBlur}
                onChange={props.onChange}
              />
            }
          />
        )}
        renderOption={option => {
          if (option !== null) {
            const newOption = option as GoogleMapsAutocompleteData;
            if (newOption.hasOwnProperty('structured_formatting')) {
              const matches = newOption?.structured_formatting?.main_text_matched_substrings;

              const parts = parse(
                newOption?.structured_formatting?.main_text || '',
                matches?.map((match: any) => [match.offset, match.offset + match.length]) || []
              );

              return (
                <Grid container alignItems="center">
                  <Grid item>
                    <LocationOnIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    {parts?.map(part => (
                      <span key={part.text} style={{fontWeight: part.highlight ? 700 : 400}}>
                        {part.text}
                      </span>
                    ))}
                    <Typography variant="body2" color="textSecondary">
                      {newOption?.structured_formatting?.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }
          }
        }}
      />
      <>
        {props.forFullAddress && (
          <>
            <input
              type="hidden"
              name={props.hiddenFieldNames?.addressName || ''}
              ref={register()}
              defaultValue={(
                props.defaultNormalizedData?.address ||
                normalizedData?.address ||
                ''
              ).slice(0, 59)}
              data-testid="hiddenStreetAddress"
            />
            <input
              type="hidden"
              name={props.hiddenFieldNames?.cityName || ''}
              ref={register()}
              defaultValue={props.defaultNormalizedData?.city || normalizedData?.city || ''}
              data-testid="hiddenCity"
            />
            <input
              type="hidden"
              name={props.hiddenFieldNames?.stateName || ''}
              ref={register()}
              defaultValue={props.defaultNormalizedData?.state || normalizedData?.state || ''}
              data-testid="hiddenState"
            />
            <input
              type="hidden"
              name={props.hiddenFieldNames?.zipCodeName || ''}
              ref={register()}
              defaultValue={
                props.defaultNormalizedData?.postalCode || normalizedData?.postalCode || ''
              }
              data-testid="hiddenZip"
            />
          </>
        )}
        {props.getLatLng && (props.defaultNormalizedData?.latLng || normalizedData?.latLng) && (
          <input
            type="hidden"
            name={props.hiddenFieldNames?.latLngName || ''}
            ref={register()}
            value={JSON.stringify(props.defaultNormalizedData?.latLng || normalizedData?.latLng)}
            data-testid="hiddenLatLng"
          />
        )}
      </>
    </>
  );
};
