import React, {useEffect} from 'react';
import {ccHealthCheck} from '../../../services';

declare const window: any;

export const MakeHealthCheck = ({children}: any) => {
  const makeCheck = async () => {
    try {
      const response = await ccHealthCheck();
      window.isCCDown = !(response?.data?.data?.CC_L4_HEALTH_STATUS || (false as boolean));
    } catch (error) {
      console.log('health check error');
      console.log(error);
    }
  };

  useEffect(() => {
    makeCheck();
  }, []);

  return <>{children}</>;
};
