export const manageClaimLink =
  'https://myclaimshub.stateauto.com/login-flow?utm_source=Submit+A+Claim&utm_medium=Summary+Page&utm_campaign=SAC+to+MAC&utm_id=Summary+Page+to+MAC';

export const findRepairShopLink =
  'https://www.stateauto.com/FindARepairCenter?utm_source=Submit+A+Claim&utm_medium=Summary+Page&utm_campaign=SAC+to+Repair&utm_id=FindARepairCenter';

export const claimsHubLink = 'http://claimshub.stateauto.com/';

export const stormCenterLink = 'https://www.stateauto.com/storm-center';

export const caPrivacyChoiceStagingLink =
  'https://privacyportaluat.onetrust.com/hosted-webform/consent/7acbfc95-48e8-451b-90d0-b392f6597d85/3fcd7033-3566-4030-ac46-37f202757f2b';

export const caPrivacyChoiceProdLink =
  'https://privacyportal.onetrust.com/hosted-webform/consent/50577d86-8d39-4310-9583-33e6d3da8e17/9b108b70-4ef6-4d93-b7ec-60e41f03b292';

export const privacyPolicyLink =
  'https://www.libertymutualgroup.com/about-lm/corporate-information/privacy-policy';

export const termsLink = 'https://www.stateauto.com/terms';
