import React, {useState, useContext} from 'react';
import {
  IconButton,
  Box,
  makeStyles,
  Typography,
  FormGroup,
  useMediaQuery,
  Grid,
} from '@material-ui/core';
import {Icon} from '../../common/Icon/Icon';
import {useFormContext, useWatch} from 'react-hook-form';
import {Car} from './Car';
import {VehicleDetailsContext} from '../YourVehicleDetails/YourVehicleDetails';
import * as yup from 'yup';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {SATextField} from '../../common/TextField/TextField';
import {SASelect} from '../../common/Select/Select';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {makePersonaCheck, personaSchemaSwitch} from '../../../utils/utils';
import {YesNoUnk} from '../../common/YesNoUnk/YesNoUnk';
import {UserPersonaContext} from '../../common/Providers/Providers';
import {AGENT_AND_ASSOCIATE_PERSONAS, CustomerTypes} from '../../../commonTypes';
import {CheckboxWithLabel} from '../../common/CheckboxWithLabel/CheckboxWithLabel';
import {Incident} from '../../../pages/utils';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      height: '650px',
    },
  },
  vehicleSvg: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: '-260px',
      top: '100px',
      transform: 'rotate(-90deg) scale(.9)',
    },
  },
  leftFront: {
    position: 'absolute',
    top: '70px',
    left: '630px',
    [theme.breakpoints.down('sm')]: {
      top: '10px',
      left: '5px',
    },
  },
  leftFrontPillar: {
    position: 'absolute',
    top: '50px',
    left: '530px',
    [theme.breakpoints.down('sm')]: {
      top: '120px',
      left: '-10px',
    },
  },
  leftTBone: {
    position: 'absolute',
    top: '50px',
    left: '320px',
    [theme.breakpoints.down('sm')]: {
      top: '300px',
      left: '-10px',
    },
  },
  leftQuarterPost: {
    position: 'absolute',
    top: '50px',
    left: '150px',
    [theme.breakpoints.down('sm')]: {
      top: '450px',
      left: '-10px',
    },
  },
  leftRear: {
    position: 'absolute',
    top: '70px',
    left: '60px',
    [theme.breakpoints.down('sm')]: {
      top: '530px',
      left: '15px',
    },
  },
  front: {
    position: 'absolute',
    top: '165px',
    left: '670px',
    [theme.breakpoints.down('sm')]: {
      top: '-15px',
      left: '90px',
    },
  },
  rightFront: {
    position: 'absolute',
    top: '260px',
    left: '630px',
    [theme.breakpoints.down('sm')]: {
      top: '10px',
      left: '175px',
    },
  },
  rightFrontPillar: {
    position: 'absolute',
    top: '280px',
    left: '530px',
    [theme.breakpoints.down('sm')]: {
      top: '120px',
      left: '195px',
      zIndex: '10',
    },
  },
  rightTBone: {
    position: 'absolute',
    top: '280px',
    left: '320px',
    [theme.breakpoints.down('sm')]: {
      top: '300px',
      left: '195px',
      zIndex: '10',
    },
  },
  rightQuarterPost: {
    position: 'absolute',
    top: '280px',
    left: '150px',
    [theme.breakpoints.down('sm')]: {
      top: '450px',
      left: '195px',
    },
  },
  rightRear: {
    position: 'absolute',
    top: '260px',
    left: '60px',
    [theme.breakpoints.down('sm')]: {
      top: '530px',
      left: '170px',
    },
  },
  rear: {
    position: 'absolute',
    top: '165px',
    left: '40px',
    [theme.breakpoints.down('sm')]: {
      top: '550px',
      left: '90px',
    },
  },
  hood: {
    position: 'absolute',
    bottom: '310px',
    right: '130px',
    [theme.breakpoints.down('sm')]: {
      top: '120px',
      left: '95px',
    },
  },
  roof: {
    position: 'absolute',
    bottom: '310px',
    right: '130px',
    [theme.breakpoints.down('sm')]: {
      top: '300px',
      left: '95px',
    },
  },
  trunk: {
    position: 'absolute',
    bottom: '310px',
    right: '130px',
    [theme.breakpoints.down('sm')]: {
      top: '420px',
      left: '95px',
    },
  },
}));

interface DamageButtonProps {
  toggleFn: (event: React.MouseEvent<HTMLButtonElement>) => void;
  inputRef: any;
  isDamage: boolean;
  name: string;
  className: string;
  isDisabled: boolean;
  label: string;
}

export const DamageButton = (props: DamageButtonProps) => {
  return (
    <Box className={props.className}>
      <IconButton
        onClick={props.toggleFn}
        title={props.label}
        style={{fontSize: '40px'}}
        id={props.name}
        disabled={props.isDisabled}
        data-testid={props.label}
      >
        <Icon name={props.isDamage ? 'damageSpot' : 'impactSpot'} />
      </IconButton>
      <input
        type="hidden"
        name={props.name}
        ref={props.inputRef}
        value={JSON.stringify({label: props.label, value: props.isDamage})}
        data-testid={`${props.label}-input`}
      />
    </Box>
  );
};

export const InsuredDamageSelectorSchema = {
  firstImpactPoint: yup.string().when('damages', {
    is: (value: string[]) => {
      return (value || []).filter(x => x.includes('true')).length > 1;
    },
    then: personaSchemaSwitch({
      is: (value: any) =>
        makePersonaCheck(value, [
          CustomerTypes.Guardian,
          CustomerTypes.SaCustomer,
          CustomerTypes.ThirdParty,
          CustomerTypes.Associate,
        ]),
      then: yup.string().required('Please select an impact point').nullable(),
      otherwise: yup.string().nullable(),
    }),
  }),
  anyPriorDamage: personaSchemaSwitch({
    is: (value: any) =>
      makePersonaCheck(value, [
        CustomerTypes.Guardian,
        CustomerTypes.SaCustomer,
        CustomerTypes.ThirdParty,
      ]),
    then: yup.string().required('Please select if the vehicle has prior damage').nullable(),
    otherwise: yup.string().nullable(),
  }),
  priorDamageDescription: yup.string().when('anyPriorDamage', {
    is: (value: string) => {
      return value === 'yes';
    },
    then: personaSchemaSwitch({
      is: (value: any) =>
        makePersonaCheck(value, [
          CustomerTypes.Guardian,
          CustomerTypes.SaCustomer,
          CustomerTypes.ThirdParty,
        ]),
      then: yup.string().required('Description of prior damage is required').nullable(),
      otherwise: yup.string().nullable(),
    }),
  }),
};

export const OtherDamageSelectorSchema = {
  firstImpactPoint: yup.string().when('damages', {
    is: (value: string[]) => {
      return (value || []).filter(x => x.includes('true')).length > 1;
    },
    then: personaSchemaSwitch({
      is: (value: any) => makePersonaCheck(value, [CustomerTypes.Associate]),
      then: yup.string().required('Please select an impact point').nullable(),
      otherwise: yup.string().nullable(),
    }),
  }),
};

export const DamageSelectorTestSchema = yup.object().shape({
  yourVehicles: yup.array(yup.object().shape({...InsuredDamageSelectorSchema})),
});

export const OtherDamageSelectorTestSchema = yup.object().shape({
  otherVehicles: yup.array(yup.object().shape({...OtherDamageSelectorSchema})),
});

export const impactPointLabels: {[key: string]: string} = {
  leftFront: 'Left Front Bumper Area',
  leftFrontPillar: 'Left Front Quarter Panel Area',
  leftTBone: 'Left Side Driver Area',
  leftQuarterPost: 'Left Rear Quarter Panel Area',
  leftRear: 'Left Rear Brake Lights Area',
  front: 'Front Center Bumper Area',
  rightFront: 'Right Front Bumper Area',
  rightFrontPillar: 'Right Front Quarter Panel Area',
  rightTBone: 'Rear Side Passenger Area',
  rightQuarterPost: 'Right Rear Quarter Panel Area',
  rightRear: 'Right Rear Brake Lights Area',
  rear: 'Rear Center Bumper Area',
  undercarriage: 'Undercarriage Area',
};

export const DamageSelector = () => {
  const [damageMap, setDamageMap] = useState<{
    [key: string]: boolean;
  }>({
    leftFront: false,
    leftFrontPillar: false,
    leftTBone: false,
    leftQuarterPost: false,
    leftRear: false,
    front: false,
    hood: false,
    roof: false,
    rightFront: false,
    rightFrontPillar: false,
    rightTBone: false,
    rightQuarterPost: false,
    rightRear: false,
    rear: false,
    trunk: false,
    undercarriage: false,
  });

  const [allOverSelected, setAllOverSelected] = useState(false);
  const [noneSelected, setNoneSelected] = useState(false);
  const [dontKnowSelected, setDontKnowSelected] = useState(false);
  const [undercarriageSelected, setUndercarriageSelected] = useState(false);
  const formMethods = useFormContext();
  const {register, errors, getValues, setValue, trigger, control} = formMethods;
  const classes = useStyles();
  const {fieldIndex, parentFieldName} = useContext(VehicleDetailsContext);

  const {userPersona} = useContext(UserPersonaContext);
  const checkboxRow = useMediaQuery(
    makePersonaCheck(userPersona as CustomerTypes, [
      CustomerTypes.Guardian,
      ...AGENT_AND_ASSOCIATE_PERSONAS,
    ])
      ? '(min-width:600px)'
      : '(min-width:462px)'
  );

  const toggleDamage = (key: string) => {
    const damaged = damageMap[key];

    if (getValues(`${parentFieldName}[${fieldIndex || 0}].firstImpactPoint`) === key) {
      setValue(`${parentFieldName}[${fieldIndex || 0}].firstImpactPoint`, true);
    }

    setDamageMap(prevDamageMap => {
      prevDamageMap[key] = !damaged;
      return {
        ...prevDamageMap,
      };
    });

    trigger(`${parentFieldName}[${fieldIndex}].firstImpactPoint`);
  };

  const handleAllDamageChange = (checked: boolean) => {
    setAllOverSelected(checked);
    setDamageMap(prevDamageMap => {
      for (let key in damageMap) {
        prevDamageMap[key] = checked;
      }
      return {
        ...prevDamageMap,
      };
    });
  };

  const disableCar = (checked: boolean) => {
    if (checked) {
      setUndercarriageSelected(false);
      setDamageMap(prevDamageMap => {
        for (let key in damageMap) {
          prevDamageMap[key] = false;
        }
        return {
          ...prevDamageMap,
        };
      });
    }
  };

  const handleNoneSelected = (checked: boolean) => {
    setNoneSelected(checked);
    disableCar(checked);
  };

  const handleDontKnowSelected = (checked: boolean) => {
    setDontKnowSelected(checked);
    disableCar(checked);
  };

  const isPriorDamage =
    useWatch({control, name: `${parentFieldName}[${fieldIndex}].anyPriorDamage`}) === 'yes';

  const selectedIncidents = (useWatch({control, name: `incidentDetails.incidents`}) as string[])
    ?.map((v: string) => JSON.parse(v))
    ?.map((v: {label: string; value: string}) => v.value);
  const hasWeatherDamage = selectedIncidents?.includes('Other Weather');

  const firstIncident = useWatch({control, name: `incidentDetails.firstIncident`});

  const isWeatherDamageFirstIncident =
    hasWeatherDamage &&
    ((firstIncident &&
      (JSON.parse(firstIncident as string) as Incident).value === 'Other Weather') ||
      selectedIncidents.length === 1);

  const isHailDamage = useWatch({control, name: `incidentDetails.isHailDamage`});

  const showDamageSelector = !(isWeatherDamageFirstIncident && isHailDamage === 'yes');

  const isInsuredVehicle = parentFieldName === 'yourVehicles';

  const isAssociate = (userPersona as CustomerTypes) === CustomerTypes.Associate;

  return (
    <>
      {showDamageSelector && (
        <>
          <Box pb={2}>
            <Typography variant="body1">Select the damaged areas on the vehicle</Typography>
            <Box pb={3} pt={1}>
              <FormGroup row={checkboxRow}>
                <CheckboxWithLabel
                  name={`${parentFieldName}[${fieldIndex}].allOverDamage`}
                  formControlLabelProps={{
                    label: 'All over',
                    disabled: noneSelected || dontKnowSelected,
                  }}
                  checkboxProps={{
                    onChange: (event, checked) => handleAllDamageChange(checked),
                  }}
                />
                <CheckboxWithLabel
                  name={`${parentFieldName}[${fieldIndex}].noDamage`}
                  formControlLabelProps={{
                    disabled: allOverSelected || undercarriageSelected || dontKnowSelected,
                    label: 'None',
                  }}
                  checkboxProps={{
                    onChange: (event, checked) => handleNoneSelected(checked),
                  }}
                />
                <CheckboxWithLabel
                  name={`${parentFieldName}[${fieldIndex}].undercarriageDamage`}
                  formControlLabelProps={{
                    disabled: noneSelected || dontKnowSelected,
                    label: 'Undercarriage',
                  }}
                  checkboxProps={{
                    onChange: (event, checked) => {
                      setUndercarriageSelected(checked);
                      toggleDamage('undercarriage');
                    },
                  }}
                />
                <UserPersonaSwitch
                  ifPersonas={[
                    CustomerTypes.SaAgent,
                    CustomerTypes.Guardian,
                    CustomerTypes.Associate,
                  ]}
                  then={
                    <CheckboxWithLabel
                      name={`${parentFieldName}[${fieldIndex}].unkDamage`}
                      formControlLabelProps={{
                        disabled: allOverSelected || undercarriageSelected || noneSelected,
                        label: "Don't know",
                      }}
                      checkboxProps={{
                        onChange: (event, checked) => handleDontKnowSelected(checked),
                      }}
                    />
                  }
                  otherwise={null}
                />
              </FormGroup>
            </Box>
          </Box>
          {!noneSelected && !dontKnowSelected && (
            <Box className={classes.root}>
              <Car
                className={classes.vehicleSvg}
                index={fieldIndex || 0}
                leftFront={damageMap['leftFront']}
                leftFrontPillar={damageMap['leftFrontPillar']}
                leftTBone={damageMap['leftTBone']}
                leftQuarterPost={damageMap['leftQuarterPost']}
                leftRear={damageMap['leftRear']}
                front={damageMap['front']}
                rightFront={damageMap['rightFront']}
                rightFrontPillar={damageMap['rightFrontPillar']}
                rightTBone={damageMap['rightTBone']}
                rightQuarterPost={damageMap['rightQuarterPost']}
                rightRear={damageMap['rightRear']}
                rear={damageMap['rear']}
              />
              <DamageButton
                toggleFn={() => toggleDamage('leftFront')}
                isDamage={damageMap['leftFront']}
                name={`${parentFieldName}[${fieldIndex}].damages[0]`}
                inputRef={register()}
                label="leftFront"
                className={classes.leftFront}
                isDisabled={noneSelected}
              />
              <DamageButton
                toggleFn={() => toggleDamage('leftFrontPillar')}
                isDamage={damageMap['leftFrontPillar']}
                name={`${parentFieldName}[${fieldIndex}].damages[1]`}
                inputRef={register()}
                label="leftFrontPillar"
                className={classes.leftFrontPillar}
                isDisabled={noneSelected}
              />
              <DamageButton
                toggleFn={() => toggleDamage('leftTBone')}
                isDamage={damageMap['leftTBone']}
                name={`${parentFieldName}[${fieldIndex}].damages[2]`}
                inputRef={register()}
                label="leftTBone"
                className={classes.leftTBone}
                isDisabled={noneSelected}
              />
              <DamageButton
                toggleFn={() => toggleDamage('leftQuarterPost')}
                isDamage={damageMap['leftQuarterPost']}
                name={`${parentFieldName}[${fieldIndex}].damages[3]`}
                inputRef={register()}
                label="leftQuarterPost"
                className={classes.leftQuarterPost}
                isDisabled={noneSelected}
              />
              <DamageButton
                toggleFn={() => toggleDamage('leftRear')}
                isDamage={damageMap['leftRear']}
                name={`${parentFieldName}[${fieldIndex}].damages[4]`}
                inputRef={register()}
                label="leftRear"
                className={classes.leftRear}
                isDisabled={noneSelected}
              />
              <DamageButton
                toggleFn={() => toggleDamage('front')}
                isDamage={damageMap['front']}
                name={`${parentFieldName}[${fieldIndex}].damages[5]`}
                inputRef={register()}
                label="front"
                className={classes.front}
                isDisabled={noneSelected}
              />
              <DamageButton
                toggleFn={() => toggleDamage('rightFront')}
                isDamage={damageMap['rightFront']}
                name={`${parentFieldName}[${fieldIndex}].damages[6]`}
                inputRef={register()}
                label="rightFront"
                className={classes.rightFront}
                isDisabled={noneSelected}
              />
              <DamageButton
                toggleFn={() => toggleDamage('rightFrontPillar')}
                isDamage={damageMap['rightFrontPillar']}
                name={`${parentFieldName}[${fieldIndex}].damages[7]`}
                inputRef={register()}
                label="rightFrontPillar"
                className={classes.rightFrontPillar}
                isDisabled={noneSelected}
              />
              <DamageButton
                toggleFn={() => toggleDamage('rightTBone')}
                isDamage={damageMap['rightTBone']}
                name={`${parentFieldName}[${fieldIndex}].damages[8]`}
                inputRef={register()}
                label="rightTBone"
                className={classes.rightTBone}
                isDisabled={noneSelected}
              />
              <DamageButton
                toggleFn={() => toggleDamage('rightQuarterPost')}
                isDamage={damageMap['rightQuarterPost']}
                name={`${parentFieldName}[${fieldIndex}].damages[9]`}
                inputRef={register()}
                label="rightQuarterPost"
                className={classes.rightQuarterPost}
                isDisabled={noneSelected}
              />
              <DamageButton
                toggleFn={() => toggleDamage('rightRear')}
                isDamage={damageMap['rightRear']}
                name={`${parentFieldName}[${fieldIndex}].damages[10]`}
                inputRef={register()}
                label="rightRear"
                className={classes.rightRear}
                isDisabled={noneSelected}
              />
              <DamageButton
                toggleFn={() => toggleDamage('rear')}
                isDamage={damageMap['rear']}
                name={`${parentFieldName}[${fieldIndex}].damages[11]`}
                inputRef={register()}
                label="rear"
                className={classes.rear}
                isDisabled={noneSelected}
              />
              <input
                type="hidden"
                name={`${parentFieldName}[${fieldIndex}].damages[12]`}
                ref={register()}
                value={JSON.stringify({
                  label: 'undercarriage',
                  value: undercarriageSelected,
                })}
              ></input>
            </Box>
          )}
          {Object.keys(damageMap).filter(
            (key: string) => damageMap[key] && impactPointLabels.hasOwnProperty(key)
          ).length > 1 &&
            ((isInsuredVehicle && !isAssociate) || isAssociate) && (
              <Box pb={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <SASelect
                      name={`${parentFieldName}[${fieldIndex}].firstImpactPoint`}
                      inputRef={register()}
                      label="Select the initial point of impact"
                      id="firstImpactPoint"
                      error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                        'firstImpactPoint'
                      )}
                      helperText={
                        errors?.[parentFieldName || '']?.[fieldIndex || 0]?.firstImpactPoint
                          ?.message
                      }
                    >
                      {Object.keys(damageMap)
                        .filter(key => damageMap[key] && impactPointLabels.hasOwnProperty(key))
                        .map((key: string) => (
                          <option key={key} value={key}>
                            {impactPointLabels[key]}
                          </option>
                        ))}
                    </SASelect>
                  </Grid>
                </Grid>
              </Box>
            )}
        </>
      )}
      {isInsuredVehicle && (
        <Grid container>
          <Grid item xs={12}>
            <Box pt={3} pb={1}>
              <Typography variant="body1">Was there any prior damage to the vehicle?</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box pb={3}>
              <UserPersonaSwitch
                ifPersonas={[CustomerTypes.SaAgent, CustomerTypes.Associate]}
                then={
                  <YesNoUnk
                    name={`${parentFieldName}[${fieldIndex}].anyPriorDamage`}
                    dontKnowTestId="AnyPriorDamagesDontKnow"
                    yesTestId="test-yes-prior-damage"
                    noTestId="test-no-prior-damage"
                  />
                }
                otherwise={
                  <YesNoToggle
                    name={`${parentFieldName}[${fieldIndex}].anyPriorDamage`}
                    hasError={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                      'anyPriorDamage'
                    )}
                    errorMessage={
                      errors?.[parentFieldName || '']?.[fieldIndex || 0]?.anyPriorDamage?.message
                    }
                    testId="test-no-prior-damage"
                    yesButtonTestId="test-yes-prior-damage"
                  />
                }
              />
            </Box>
          </Grid>
          {isPriorDamage && (
            <Grid item xs={12} sm={6}>
              <Box pb={4}>
                <Box pb={1}>
                  <Typography
                    id={`priorDamageDescriptionLabel-${parentFieldName}[${fieldIndex}]`}
                    variant="body1"
                  >
                    Please describe any prior damage:
                  </Typography>
                </Box>
                <SATextField
                  hasWhiteFields
                  autoFocus
                  name={`${parentFieldName}[${fieldIndex}].priorDamageDescription`}
                  inputRef={register()}
                  inputProps={{
                    'data-testid': 'prior-damage-text-area',
                    'aria-labelledby': `priorDamageDescription-${parentFieldName}[${fieldIndex}]`,
                    id: `priorDamageDescription-${parentFieldName}[${fieldIndex}]`,
                    className: 'damageSelectorPriorDamageDescription',
                  }}
                  characterLimit={150}
                  error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.hasOwnProperty(
                    'priorDamageDescription'
                  )}
                  helperText={
                    errors?.[parentFieldName || '']?.[fieldIndex || 0]?.priorDamageDescription
                      ?.message || '150-character limit'
                  }
                  multiline
                  placeholder="Enter description here"
                  hiddenTextAreaLabel="Please describe any prior damage"
                />
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};
