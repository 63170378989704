import {navigate} from '@reach/router';
import React from 'react';
import {Contact} from '../../components/common/Summary/types';
import {PhoneHyperlink} from '../../components/common/PhoneHyperlink/PhoneHyperlink';
import {stripCommas} from '../../utils/utils';
import {WaypointNames} from '../../components/common/RegisterBreadcrumb/RegisterBreadcrumb';
import * as queryString from 'query-string';
import {LOB_ABBREVIATION_TO_LOB} from '../GettingStartedPage';
import Cookies from 'js-cookie';
import {Lobs} from '../../commonTypes';
import {ContactOption, FormContactSnapshot} from '../../components/common/ContactInfo/types';
import {PropertyForms} from '../../components/common/PropertyDamageForm/PropertyDamageForm';
import {ASSOCIATE_POLICY_SEARCH_ROUTE, ROOT_ROUTE} from '../../routes/paths';
import {CustomerTypes} from '../../commonTypes';
import {UpdateDraftClaimContact} from '../../services';

export const CLAIM_ERROR_MESSAGE = () => (
  <p data-testid="claimErrorMessage">
    An issue occurred with the claim setup process. Please contact our CARE team at{' '}
    <PhoneHyperlink />.
  </p>
);

export const INVALID_POLICY_ERROR_MESSAGE = () => (
  <p data-testid="invalidPolicyErrorMessage">
    The selected policy is canceled or ineligible. Please verify that the customer does not have
    another active policy.
  </p>
);

export const navigateToPolicySearchOnError = () => {
  navigateDefaultReplace(ROOT_ROUTE);
};

interface Base {
  address: string | undefined;
  city: string | undefined;
  state: string | undefined;
}

interface NotOwner {
  zipcode: string | undefined;
  isVehicleARental: string | undefined;
  areYouTheOwner: string | undefined;
  ownerId?: string;
  claimantRoleId?: string;
}

interface NotDriver {
  zipcode: string | undefined;
  driverSameAsOwner: string | undefined;
  driverId: string | undefined;
  claimantRoleId?: string;
}

interface Damage {
  label: string | undefined;
  value: boolean | undefined;
}

interface AdditionalVehicle {
  notOwner: Base & NotOwner;
  notDriver: Base & NotDriver;
  firstImpactPoint: string | undefined;
  vehicleDriveable: string | undefined;
  anyPriorDamage: string | undefined;
  additionalPassenger: Passenger[] | undefined;
  vehicleMileage: string | undefined;
  noDriver: boolean | undefined;
  isYours: boolean | undefined;
  damages: string[] | Damage[] | undefined;
  zipCode: string | undefined;
  policyPassengers?: string[];
}

interface Passenger {
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  injured?: string;
  passengerId?: string;
  claimantRoleId?: string;
}

interface GettingStarted {
  address: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zipcode: string | undefined;
}

interface SetDefaultGettingStarted {
  gettingStarted: GettingStarted;
}

interface GeneralDetails {
  streetName: string | undefined;
  zip: string | undefined;
}

interface SetDefaultGeneralDetails {
  generalDetails: GeneralDetails;
}

interface SetParsedIncidentLocationLatLng {
  generalDetails: {latlng?: string};
}

interface IncidentDetails {
  incidents: [Incident] | string[] | undefined;
  firstIncident: Incident | string | undefined;
  otherDetails: string | undefined;
  hasKeys: string | undefined;
  isVehicleBurned: string | undefined;
  isHailDamage: string | undefined;
  isHitStationaryObject: string | undefined;
}

interface SetIncidentDetailsData {
  incidentDetails: IncidentDetails | undefined;
}

interface SetAdditionalInjuredIds {
  additionalInjured?: Array<any>;
}

export interface Incident {
  label: string | undefined;
  value: string | undefined;
  checked: boolean | undefined;
  disabled: boolean | undefined;
  testID: string | undefined;
}

export const getThirdPartyContacts = (
  contactList: FormContactSnapshot[]
): UpdateDraftClaimContact[] => {
  let updateDraftContactList: UpdateDraftClaimContact[] = [];

  for (let i: number = 0; i < contactList.length; i++) {
    const contactSnapshot = contactList[i];

    if (
      !contactSnapshot.policyInfo &&
      contactSnapshot.occurrences.length > 0 &&
      contactSnapshot.email !== undefined &&
      contactSnapshot.phoneNumbers !== undefined &&
      contactSnapshot.contactAddress !== undefined
    ) {
      const {
        value,
        firstName,
        lastName,
        companyName,
        label,
        contactType,
        email,
        phoneNumbers,
        contactAddress,
      } = contactSnapshot;

      updateDraftContactList.push({
        claimantId: value,
        firstName,
        lastName,
        companyName,
        label,
        contactType,
        email,
        phoneNumbers,
        ...contactAddress,
      });
    }
  }

  return updateDraftContactList;
};

export const setContactIdValues = (
  contact: any,
  idAttribute: string,
  contactList: ContactOption[]
) => {
  const matchingOption = contactList.find((co: ContactOption) => co.value === contact[idAttribute]);

  if (matchingOption) {
    if (matchingOption.policyInfo) {
      if (
        matchingOption.policyInfo?.publicID &&
        matchingOption.policyInfo?.publicID !== contact[idAttribute]
      ) {
        contact[idAttribute] = matchingOption.policyInfo.publicID;
      }
    } else {
      contact[idAttribute] = 'other';
      contact.claimantRoleId = matchingOption.value;
    }
  }
};

export const setIncidentDetailsData = (data: SetIncidentDetailsData) => {
  if (data.incidentDetails?.incidents) {
    (data.incidentDetails.incidents as Incident[]) = (data.incidentDetails.incidents as string[])
      .map((v: string) => JSON.parse(v))
      .map((v: {label: string; value: string}) => {
        if (v.value === 'Collision - Hit stationary object') {
          v.value = 'Collision';

          if (data.incidentDetails) {
            data.incidentDetails.isHitStationaryObject = 'yes';
          }
        }

        return v;
      }) as Incident[];
  }

  if (data?.incidentDetails?.firstIncident) {
    data.incidentDetails.firstIncident = JSON.parse(data.incidentDetails.firstIncident as string);

    if (
      (data.incidentDetails.firstIncident as Incident).value === 'Collision - Hit stationary object'
    ) {
      (data.incidentDetails.firstIncident as Incident).value = 'Collision';
    }
  } else if (data.incidentDetails?.incidents) {
    data.incidentDetails.firstIncident = data.incidentDetails.incidents[0];
  }
};

export const setDefaultGeneralDetailsData = (data: SetDefaultGeneralDetails) => {
  data.generalDetails.streetName = data.generalDetails.streetName || '';
  data.generalDetails.zip = data.generalDetails.zip || '';
};

export const setParsedIncidentLocationLatLng = (data: SetParsedIncidentLocationLatLng) => {
  if (data.generalDetails.latlng) {
    data.generalDetails.latlng = JSON.parse(data.generalDetails.latlng);
  }
};

export const setDefaultGettingStartedData = (
  data: SetDefaultGettingStarted,
  contactList: ContactOption[]
) => {
  data.gettingStarted.address = data.gettingStarted.address || '';
  data.gettingStarted.city = data.gettingStarted.city || '';
  data.gettingStarted.state = data.gettingStarted.state || '';
  data.gettingStarted.zipcode = data.gettingStarted.zipcode || '';

  setContactIdValues(data.gettingStarted, 'reporterId', contactList);
};

export const setDefaultVehicleData = (
  data: Array<AdditionalVehicle & Base>,
  isYours: boolean,
  contactList: ContactOption[]
) => {
  data.forEach((additionalVehicle: AdditionalVehicle & Base) => {
    additionalVehicle.isYours = isYours;
    additionalVehicle.notOwner.address = additionalVehicle.notOwner.address || '';
    additionalVehicle.notOwner.city = additionalVehicle.notOwner.city || '';
    additionalVehicle.notOwner.state = additionalVehicle.notOwner.state || '';
    additionalVehicle.notOwner.zipcode = additionalVehicle.notOwner.zipcode || '';
    additionalVehicle.notOwner.isVehicleARental = additionalVehicle.notOwner.isVehicleARental || '';

    setContactIdValues(additionalVehicle.notOwner, 'ownerId', contactList);

    if (!isYours) {
      additionalVehicle.notOwner.areYouTheOwner = additionalVehicle.notOwner.areYouTheOwner || 'no';
    }

    additionalVehicle.notDriver.address = additionalVehicle.notDriver.address || '';
    additionalVehicle.notDriver.city = additionalVehicle.notDriver.city || '';
    additionalVehicle.notDriver.state = additionalVehicle.notDriver.state || '';
    additionalVehicle.notDriver.zipcode = additionalVehicle.notDriver.zipcode || '';
    additionalVehicle.notDriver.driverSameAsOwner =
      additionalVehicle.notDriver.driverSameAsOwner || '';

    setContactIdValues(additionalVehicle.notDriver, 'driverId', contactList);

    additionalVehicle.address = additionalVehicle.address || '';
    additionalVehicle.city = additionalVehicle.city || '';
    additionalVehicle.state = additionalVehicle.state || '';
    additionalVehicle.zipCode = additionalVehicle.zipCode || '';

    additionalVehicle.damages = ((additionalVehicle.damages as string[]) || []).map((v: any) =>
      JSON.parse(v)
    ) as Damage[];

    additionalVehicle.firstImpactPoint = additionalVehicle.firstImpactPoint || '';
    additionalVehicle.vehicleDriveable = additionalVehicle.vehicleDriveable || '';
    additionalVehicle.anyPriorDamage = additionalVehicle.anyPriorDamage || '';

    const confirmedImpactPoints = (additionalVehicle.damages || []).filter(
      (damage: Damage) => damage.value
    );

    if (confirmedImpactPoints.length === 1) {
      additionalVehicle.firstImpactPoint = confirmedImpactPoints[0].label;
    }

    if (additionalVehicle.additionalPassenger) {
      additionalVehicle.additionalPassenger.forEach((passenger: Passenger) => {
        passenger.address = passenger.address || '';
        passenger.city = passenger.city || '';
        passenger.state = passenger.state || '';
        passenger.zipCode = passenger.zipCode || '';
      });
    }

    if (additionalVehicle.vehicleMileage) {
      additionalVehicle.vehicleMileage = stripCommas(additionalVehicle.vehicleMileage);
    }

    additionalVehicle.noDriver =
      additionalVehicle.notDriver.driverId === 'nodriver' ||
      additionalVehicle.notDriver.driverSameAsOwner === 'noDriver';

    if (
      additionalVehicle.notDriver.driverId === 'nodriver' ||
      additionalVehicle.notDriver.driverId === 'unknown'
    ) {
      additionalVehicle.notDriver.driverId = '';
    }
  });
};

export const mapVehiclePassengers = (
  vehicles: Array<any>,
  draftClaimContacts: Array<Contact>,
  contactList: ContactOption[]
) => {
  vehicles.forEach(vehicle => {
    if (vehicle.policyPassengers && vehicle.policyPassengers.length > 0) {
      let mappedPassengers: Passenger[] = [];
      vehicle.policyPassengers
        ?.map((policyPassenger: string) => JSON.parse(policyPassenger))
        ?.filter(
          (policyPassenger: {value: string}) =>
            !['other', 'unknown'].includes(policyPassenger.value)
        )
        .forEach((policyPassenger: {value: string}) => {
          const draftClaimContact = draftClaimContacts.find(
            contact => contact.publicID === policyPassenger.value
          );
          if (draftClaimContact) {
            mappedPassengers.push({
              firstName: draftClaimContact.firstName || '',
              lastName: draftClaimContact.lastName || '',
              injured: vehicle[`${draftClaimContact.publicID}-injured`] || '',
            });
          }
        });

      vehicle.additionalPassenger = [...(vehicle.additionalPassenger || []), ...mappedPassengers];
    }

    if (vehicle.additionalPassenger?.length) {
      vehicle.additionalPassenger.forEach((passenger: any) => {
        setContactIdValues(passenger, 'passengerId', contactList);
      });
    }
  });
};

export const setInsuredMainContactIds = (insuredContact: any, contactList: ContactOption[]) => {
  if (insuredContact) {
    setContactIdValues(insuredContact, 'contactID', contactList);
  }
};

export const propertyDamageFormDefaultValues = (data: any, contactList: ContactOption[]) => {
  const propertyDamageSections = [
    PropertyForms.OtherProperty,
    PropertyForms.YourProperty,
    PropertyForms.AdditionalProperty,
  ];

  propertyDamageSections.forEach(propertySection => {
    if (data[propertySection]) {
      data[propertySection].forEach((propertyIncident: any) => {
        if (!propertyIncident.propertyAddress) {
          propertyIncident.propertyAddress = {};
        }

        if (!propertyIncident.ownerAddress) {
          propertyIncident.ownerAddress = {};
        }

        if (!propertyIncident.ownerAddressSameAsProperty) {
          propertyIncident.ownerAddressSameAsProperty = false;
        }

        setContactIdValues(propertyIncident, 'ownerId', contactList);
      });
    }
  });
};

export const setAdditionalInjuredIds = (
  data: SetAdditionalInjuredIds,
  contactList: ContactOption[]
) => {
  (data.additionalInjured || []).forEach((injured: any) => {
    setContactIdValues(injured, 'injuredId', contactList);
  });
};

export const getSearchParams = () => {
  const params = queryString.parse(window.location.search, {parseBooleans: true});
  const appId = params?.utm_source as string;
  const lobParam = (params?.LOB && (params?.LOB as string).toLocaleUpperCase()) as string;
  const policyNumberParam = params?.PolicyNumber as string;
  const policySymbol = params?.PolicySymbol as string;
  const associate = params?.associate as boolean;
  const authinsured = params?.authinsured as boolean;
  const uuid = params?.uuid as string;

  return {
    appId,
    lob: LOB_ABBREVIATION_TO_LOB[lobParam] as Lobs,
    policyNumberParam,
    policySymbol,
    associate,
    authinsured,
    uuid,
  };
};

export const getPolicyNumber = ({
  policySymbol,
  policyNumberParam,
}: {
  policySymbol: string;
  policyNumberParam: string;
}) => {
  return policySymbol ? `${policySymbol}${policyNumberParam}` : policyNumberParam;
};

export interface HasNeededParams {
  appId: string;
  lob: string;
  policyNumberParam: string;
}

const POLICY_PORTALS = ['agentsite', 'sa360', 'gpa'];

export const hasValidParameters = ({appId, lob, policyNumberParam}: HasNeededParams) => {
  return appId && POLICY_PORTALS.includes(appId.toLocaleLowerCase()) && lob && policyNumberParam;
};

export const isFromCustomer360 = (appId: string) => {
  return ['cs360'].includes(appId.toLowerCase());
};

export interface SessionCookieData {
  path?: string;
  userToken?: string;
  userSession?: string;
  refreshToken?: string;
}

export const setSessionCookieData = ({
  path,
  userToken,
  userSession,
  refreshToken,
}: SessionCookieData) => {
  if (path) {
    Cookies.set('path', path);
  }

  if (userToken) {
    Cookies.set('userToken', userToken);
  }

  if (userSession) {
    Cookies.set('userSession', JSON.stringify(userSession));
  }

  if (refreshToken) {
    Cookies.set('refreshToken', refreshToken);
  }
};

export interface ConditionalWaypoint {
  condition: boolean;
  waypoint: WaypointNames;
  spliceStart: number | 'last';
  spliceDeleteCount?: number;
}

export const insertConditionalBreadcrumbs = (
  waypoints: WaypointNames[],
  newWaypoints: ConditionalWaypoint[]
) => {
  let lastWaypoint: any;

  newWaypoints.forEach(newWaypoint => {
    const {condition, spliceStart, spliceDeleteCount, waypoint} = newWaypoint;
    spliceStart === 'last'
      ? condition && (lastWaypoint = waypoint)
      : condition && waypoints.splice(spliceStart, spliceDeleteCount || 0, waypoint);
  });

  if (lastWaypoint) {
    return [...waypoints, lastWaypoint];
  }

  return waypoints;
};

export const navigateDefaultReplace = (to: string, options?: {state: any; replace?: boolean}) => {
  const state = options?.state;
  const replace = options?.replace;
  navigate(to, {state, replace: !(replace === false)});
};

export const formatPropertyAddress = (string: any) => {
  const formattedWords = string.replace(/[a-zA-Z0-9]+/g, (match: any) => {
    const firstLetter = match.charAt(0).toUpperCase();
    const remainingLetters = match.slice(1).toLowerCase();
    return `${firstLetter}${remainingLetters}`;
  });
  let arrayOfEachWord = formattedWords.split(' ');
  if (arrayOfEachWord.length > 2 && arrayOfEachWord[arrayOfEachWord.length - 2].length === 2) {
    arrayOfEachWord[arrayOfEachWord.length - 2] =
      arrayOfEachWord[arrayOfEachWord.length - 2].toUpperCase();
  }
  return arrayOfEachWord.join(' ');
};

export const fileClaim = (customerType: CustomerTypes) => {
  if (customerType === CustomerTypes.SaAgent) {
    const cookieData = Cookies.get('data');
    if (cookieData) {
      const data = JSON.parse(cookieData);
      const newData = JSON.stringify({
        ...data,
        gettingStarted: {...data.gettingStarted, lob: ''},
      });
      Cookies.set('data', newData);
      navigate(ROOT_ROUTE);
    }
  } else if (customerType === CustomerTypes.Associate) {
    navigate(ASSOCIATE_POLICY_SEARCH_ROUTE);
  }
};
