import React, {useState, useContext} from 'react';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import * as yup from 'yup';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {SATextField} from '../../common/TextField/TextField';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {SASelect} from '../../common/Select/Select';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {YesNoUnk} from '../../common/YesNoUnk/YesNoUnk';
import {personaSchemaSwitch, makePersonaCheck} from '../../../utils/utils';
import {DynamicCheckboxList, Option} from '../../common/DynamicCheckboxList/DynamicCheckboxList';
import {DynamicRadioList, RadioOption} from '../../common/DynamicRadioList/DynamicRadioList';
import {
  AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
  AGENT_AND_ASSOCIATE_PERSONAS,
  CustomerTypes,
  THIRD_PARTY_PERSONAS,
} from '../../../commonTypes';
import {UserPersonaContext} from '../../common/Providers/Providers';
import {RideShareOrDelivery} from '../../common/RideshareOrDelivery/RideshareOrDelivery';
import {useGetReporterAtomState, useRemoveSectionOccurrences} from '../../../atoms';
import {Catastrophe} from '../Catastrophe/Catastrophe';
import {ContactSections} from '../../common/ContactInfo/types';

export interface IncidentDetailsProps {
  setHasPropertyDamage: React.Dispatch<React.SetStateAction<boolean>>;
}

const schema = yup.object().shape({
  incidents: yup.array().min(1, 'Select at least one incident'),
  firstIncident: yup.string().when('incidents', {
    is: (value: string[]) => value.length > 1,
    then: yup.string().required('Select an incident'),
  }),
  otherDetails: yup.string().when('incidents', {
    is: (value: string[]) => (value || []).length >= 0,
    then: yup.string().max(250, 'Character limit exceeded').required('This field is required'),
  }),
  rideshare: yup.string().when(['incidents', '$isCommercialAuto'], {
    is: (value: any, isCommercialAuto: boolean) => !!value && isCommercialAuto,
    then: yup
      .string()
      .oneOf(['yes', 'no'], 'Please select whether the vehicle is used for Rideshare')
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  delivery: yup.string().when(['incidents', '$isCommercialAuto'], {
    is: (value: any, isCommercialAuto: boolean) => !!value && isCommercialAuto,
    then: yup
      .string()
      .oneOf(['yes', 'no'], 'Please select whether the vehicle is used for Delivery')
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  isInsuredBusiness: yup.string().when(['incidents', 'delivery', '$isCommercialAuto'], {
    is: (incidents: any, delivery: string, isCommercialAuto: boolean) =>
      incidents && delivery === 'yes' && isCommercialAuto,
    then: yup.string().required('Please specify if the business is insured').nullable(),
    otherwise: yup.string().nullable(),
  }),
  deliveryBusinessName: yup
    .string()
    .nullable()
    .when(['incidents', 'isInsuredBusiness', '$isCommercialAuto'], {
      is: (incidentsValue: any, isInsuredBusinessValue: string, isCommercialAuto: boolean) =>
        incidentsValue && isInsuredBusinessValue === 'no' && isCommercialAuto,
      then: yup
        .string()
        .max(32, 'Character limit exceeded')
        .required('This field is required')
        .nullable(),
      otherwise: yup.string().nullable(),
    }),
  hasKeys: yup.string().when('incidents', {
    is: (value: string[]) => {
      return (value || []).filter((x: string) => x.includes('Theft')).length === 1;
    },
    then: personaSchemaSwitch({
      is: (value: any) => makePersonaCheck(value, AGENT_AND_ASSOCIATE_PERSONAS),
      then: yup.string().nullable(),
      otherwise: yup
        .string()
        .required('Please indicate if all keys are in your possession')
        .nullable(),
    }),
  }),
  isVehicleBurned: yup.string().when('incidents', {
    is: (value: string[]) => {
      return (value || []).filter((x: string) => x.includes('Theft')).length === 1;
    },
    then: personaSchemaSwitch({
      is: (value: any) => makePersonaCheck(value, AGENT_AND_ASSOCIATE_PERSONAS),
      then: yup.string().nullable(),
      otherwise: yup.string().required('Please indicate if the vehicle was burned').nullable(),
    }),
  }),
  isHailDamage: yup.string().when('incidents', {
    is: (value: string[]) => {
      return (value || []).filter((x: string) => x.includes('Other Weather')).length === 1;
    },
    then: personaSchemaSwitch({
      is: (value: any) => makePersonaCheck(value, AGENT_AND_ASSOCIATE_PERSONAS),
      then: yup.string().nullable(),
      otherwise: yup.string().required('Please indicate if damage was from hail').nullable(),
    }),
  }),
});

export const IncidentDetailsSchema = {
  incidentDetails: yup.object().when('$acknowledgement', {
    is: (value: boolean | undefined) => value === true,
    then: schema,
  }),
};

export const IncidentDetailsTestSchema = yup.object().shape({
  incidentDetails: schema,
});

const useStyles = makeStyles(theme => ({
  fieldset: {
    border: '0 none',
  },
}));

export const IncidentDetails = (props: IncidentDetailsProps) => {
  const {userPersona} = useContext(UserPersonaContext);
  const [incidentOptions, setIncidentOptions] = useState<Option[]>([
    {
      label: 'Damage Due To Animal',
      value: 'Animal',
      checked: false,
      disabled: false,
      testID: 'damageDueToAnimal',
      personas: AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
    },
    {
      label: 'Contact with another vehicle',
      value: 'Collision',
      checked: false,
      disabled: false,
      testID: 'contactWithAnotherVehicle',
    },
    {
      label: 'Flying object hit vehicle',
      value: 'Falling Objects',
      checked: false,
      disabled: false,
      testID: 'flyingObjectHitVehicle',
      personas: AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
    },
    {
      label: 'Hit stationary object',
      value: 'Collision - Hit stationary object',
      checked: false,
      disabled: false,
      testID: 'hitStationaryObject',
      personas: AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
    },
    {
      label: 'Damage Due To Weather',
      value: 'Other Weather',
      checked: false,
      disabled: false,
      testID: 'damageDueToWeather',
      personas: AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
    },
    {
      label: 'Vandalism',
      value: 'Vandalism',
      checked: false,
      disabled: false,
      testID: 'vandalism',
      personas: AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
    },
    {
      label: 'Fire',
      value: 'Fire',
      checked: false,
      disabled: false,
      testID: 'fire',
      personas: AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
    },
    {
      label: 'Theft',
      value: 'Theft',
      checked: false,
      disabled: false,
      testID: 'theft',
      personas: AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
    },
    {
      label: 'Fuel Contamination',
      value: 'Fuel Contamination',
      checked: false,
      disabled: false,
      testID: 'fuelContamination',
      personas: AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
    },
    {
      label: makePersonaCheck(userPersona as CustomerTypes, [CustomerTypes.ThirdParty])
        ? 'Insured Damaged my property (excluding vehicles)'
        : 'Vehicle damaged my property',
      value: 'vehicleDamagedProperty',
      checked: false,
      disabled: false,
      testID: 'vehicleDamagedMyProperty',
      personas: THIRD_PARTY_PERSONAS,
    },
    {
      label: 'Other',
      value: 'Other Liability',
      checked: false,
      disabled: false,
      testID: 'other',
    },
  ]);

  const [selectedIncidents, setSelectedIncidents] = useState<Option[]>([]);
  const isAssociate = userPersona === CustomerTypes.Associate;
  const reporterMetaData = useGetReporterAtomState();
  const displayRideShareOrDelivery =
    userPersona === CustomerTypes.SaCustomer || (isAssociate && reporterMetaData.isPolicyContact);
  const {register, errors, setValue, clearErrors, trigger} = useFormContext();
  const removeYourPropertyOccurrences = useRemoveSectionOccurrences(
    ContactSections.YourPropertyOwner
  );
  const removeYourVehicleOwnerOccurrences = useRemoveSectionOccurrences(
    ContactSections.InsuredVehicleOwner
  );
  const removeYourVehicleDriverOccurrences = useRemoveSectionOccurrences(
    ContactSections.InsuredVehicleDriver
  );
  const removeYourVehiclePassengerOccurrences = useRemoveSectionOccurrences(
    ContactSections.InsuredVehiclePassenger
  );

  function handleIncidentChange(incidentOption: Option, checked: boolean, index: number): void {
    let tmp = Object.create(incidentOptions) as Option[];
    tmp[index].checked = checked;

    const selectedIncidents = tmp.filter(option => option.checked);
    setIncidentOptions(tmp);
    setSelectedIncidents(selectedIncidents);

    if (makePersonaCheck(userPersona as CustomerTypes, THIRD_PARTY_PERSONAS)) {
      props.setHasPropertyDamage(
        selectedIncidents.some(option => option.value === 'vehicleDamagedProperty')
      );
    }

    trigger('incidentDetails.incidents');

    if (selectedIncidents.length === 1) {
      const firstIncident = selectedIncidents[0];
      register({name: 'incidentDetails.firstIncident'});
      setValue(
        'incidentDetails.firstIncident',
        JSON.stringify({
          label: firstIncident.label,
          value: firstIncident.value,
        })
      );
    }

    if (selectedIncidents.length < 2) {
      clearErrors(['incidentDetails.firstIncident']);
    }

    if (selectedIncidents.length >= 2) {
      setValue('incidentDetails.firstIncident', '');
    }

    if (incidentOption.value === 'vehicleDamagedProperty') {
      if (checked) {
        removeYourVehicleOwnerOccurrences();
        removeYourVehicleDriverOccurrences();
        removeYourVehiclePassengerOccurrences();
      } else {
        removeYourPropertyOccurrences();
      }
    }
  }

  const hasSelectedIncident = (incident: string) => {
    return selectedIncidents.map(x => x.value).includes(incident);
  };

  const hasFirstIncident = selectedIncidents.length >= 2;
  const hasDamageDueToAnimal = hasSelectedIncident('Animal');
  const hasTheft = hasSelectedIncident('Theft');
  const hasDamageDueToWeather = hasSelectedIncident('Other Weather');

  const displayOtherDetailsTextArea = selectedIncidents.length > 0;

  const ANIMAL_TYPE_OPTIONS: RadioOption[] = [
    {
      label: 'Domestic (For example, dog, cat, horse and more)',
      value: 'domestic',
      testId: 'domesticAnimal',
    },
    {
      label: 'Wild (For example, bear, deer, raccoon, moose and more)',
      value: 'wild',
      testId: 'wildAnimal',
    },
  ];

  const classes = useStyles();

  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.IncidentDetails}
      displayName={WAYPOINTS[WaypointNames.IncidentDetails].displayName}
    >
      <FormContainer header="Incident Details">
        <Box>
          <fieldset className={classes.fieldset}>
            <legend></legend>
            <DynamicCheckboxList
              options={incidentOptions}
              name="incidentDetails.incidents"
              onOptionsChange={handleIncidentChange}
              errorMessage={errors?.incidentDetails?.incidents?.message}
            />
          </fieldset>
          {displayOtherDetailsTextArea && (
            <Box pb={3}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <SATextField
                      name="incidentDetails.otherDetails"
                      label="Please provide a brief description"
                      id="otherDetails"
                      inputRef={register()}
                      InputLabelProps={{'aria-labelledby': 'otherDetails'}}
                      error={errors?.incidentDetails?.hasOwnProperty('otherDetails')}
                      helperText={
                        errors?.incidentDetails?.otherDetails?.message || '250-character limit'
                      }
                      autoFocus
                      multiline
                      rowsMax={5}
                      showCharacterCount
                      characterLimit={250}
                      inputProps={{className: 'incidentDetailsDescription'}}
                    />
                  </Grid>
                </Grid>
                {isAssociate && <Catastrophe hasDamageDueToWeather={hasDamageDueToWeather} />}
              </Box>
              {displayRideShareOrDelivery && (
                <Box>
                  <RideShareOrDelivery />
                </Box>
              )}
            </Box>
          )}
          {hasDamageDueToAnimal && (
            <Box pb={3}>
              <Box pb={2}>
                <Typography>What type of animal?</Typography>
              </Box>
              <Box pb={3}>
                <fieldset className={classes.fieldset}>
                  <legend></legend>
                  <DynamicRadioList
                    name="incidentDetails.animalType"
                    defaultValue="wild"
                    options={ANIMAL_TYPE_OPTIONS}
                    row
                  />
                </fieldset>
              </Box>
            </Box>
          )}
          {hasTheft && (
            <>
              <Box pb={3}>
                <Box pb={1}>
                  <Typography>Do you have all keys in your possession?</Typography>
                </Box>
                <UserPersonaSwitch
                  ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                  then={
                    <YesNoUnk
                      name="incidentDetails.hasKeys"
                      hasErrors={errors?.incidentDetails?.hasOwnProperty('hasKeys')}
                    />
                  }
                  otherwise={
                    <YesNoToggle
                      name="incidentDetails.hasKeys"
                      hasError={errors?.incidentDetails?.hasOwnProperty('hasKeys')}
                      errorMessage={errors?.incidentDetails?.hasKeys?.message}
                    />
                  }
                />
              </Box>
              <Box pb={3}>
                <Box pb={1}>
                  <Typography>Was the recovered vehicle burned after the theft?</Typography>
                </Box>
                <UserPersonaSwitch
                  ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                  then={
                    <YesNoUnk
                      name="incidentDetails.isVehicleBurned"
                      hasErrors={errors?.incidentDetails?.hasOwnProperty('isVehicleBurned')}
                    />
                  }
                  otherwise={
                    <YesNoToggle
                      name="incidentDetails.isVehicleBurned"
                      hasError={errors?.incidentDetails?.hasOwnProperty('isVehicleBurned')}
                      errorMessage={errors?.incidentDetails?.isVehicleBurned?.message}
                    />
                  }
                />
              </Box>
            </>
          )}
          {hasDamageDueToWeather && (
            <Box pb={3}>
              <Box pb={1}>
                <Typography>Is the damage from hail?</Typography>
              </Box>
              <UserPersonaSwitch
                ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                authFlagCheck={true}
                then={
                  <YesNoUnk
                    name="incidentDetails.isHailDamage"
                    hasErrors={errors?.incidentDetails?.hasOwnProperty('isHailDamage')}
                  />
                }
                otherwise={
                  <YesNoToggle
                    name="incidentDetails.isHailDamage"
                    hasError={errors?.incidentDetails?.hasOwnProperty('isHailDamage')}
                    errorMessage={errors?.incidentDetails?.isHailDamage?.message}
                  />
                }
              />
            </Box>
          )}
          {hasFirstIncident && (
            <Box py={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} sm={6}>
                  <SASelect
                    name="incidentDetails.firstIncident"
                    inputRef={register}
                    label="Which incident occurred first?"
                    id="firstIncident"
                    error={errors?.incidentDetails?.hasOwnProperty('firstIncident')}
                    helperText={errors?.incidentDetails?.firstIncident?.message}
                    defaultValue=""
                  >
                    {incidentOptions
                      .filter(x => x.checked)
                      .map(incidentOption => (
                        <option
                          key={incidentOption.value}
                          value={JSON.stringify({
                            label: incidentOption.label,
                            value: incidentOption.value,
                          })}
                          data-testid={incidentOption.value}
                        >
                          {incidentOption.label}
                        </option>
                      ))}
                  </SASelect>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </FormContainer>
    </RegisterBreadcrumb>
  );
};
