import React, {ReactElement, useCallback, useState} from 'react';
import {BreadCrumbsContext} from '../Providers/Providers';

export interface WithBreadcrumbsContextProps {
  children: ReactElement;
}

export const WithBreadcrumbsContext = (props: WithBreadcrumbsContextProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState({});

  const updateBreadcrumbState = useCallback((waypointName: string, isActive: boolean): void => {
    const activeBreadcrumb = document.querySelector('.--active') as HTMLElement;
    const breadCrumbToActivate = document.querySelector(
      `.breadcrumb.${waypointName}`
    ) as HTMLElement;
    const activeBreadCrumpWaypointName = activeBreadcrumb?.getAttribute('data-bc-id');

    if (activeBreadCrumpWaypointName !== waypointName) {
      const breadCrumbWaypoint: HTMLElement | null = document.getElementById(
        waypointName.toLowerCase()
      );
      breadCrumbToActivate?.classList.add('--active');

      if (breadCrumbToActivate?.style) {
        breadCrumbToActivate.style.color = '#286B86';
      }

      if (breadCrumbWaypoint && breadCrumbWaypoint.scrollIntoView) {
        breadCrumbWaypoint?.scrollIntoView();
      }

      if (activeBreadcrumb && isActive) {
        activeBreadcrumb.classList.remove('--active');
        if (activeBreadcrumb?.style) {
          activeBreadcrumb.style.color = '#041E41';
        }
      }
    }
  }, []);

  const registerBreadcrumb = useCallback((waypointName: string, displayName: string) => {
    setBreadcrumbs((prevState: any) => {
      const tmp: {
        [waypointName: string]: {
          active: boolean;
          displayName: string;
        };
      } = {...prevState};
      tmp[waypointName] = {active: false, displayName};
      return tmp;
    });
  }, []);

  return (
    <BreadCrumbsContext.Provider value={{breadcrumbs, updateBreadcrumbState, registerBreadcrumb}}>
      {props.children}
    </BreadCrumbsContext.Provider>
  );
};
