import {SADatePickerProps} from '../DatePicker/DatePicker';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardTimePicker} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';

export const SATimePicker = (props: SADatePickerProps) => {
  const onChangeHandler = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    props.onChange && props.onChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        name={props.name || ''}
        id={props.id || ''}
        label={props.label}
        placeholder="08:00 AM"
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={onChangeHandler}
        onBlur={props.onBlur}
        mask="__:__ _M"
        inputVariant="outlined"
        keyboardIcon={props.keyboardIcon}
        error={props.error}
        helperText=""
        inputValue={props.inputValue}
        fullWidth
        InputLabelProps={props.InputLabelProps}
        KeyboardButtonProps={{'aria-label': props.id}}
        disabled={props.disabled}
      />
    </MuiPickersUtilsProvider>
  );
};
