import React, {useState} from 'react';
import '../StickyBreadcrumbs/sticky.css';
import styled from 'styled-components';
import {
  SABox,
  SACard,
  SAModal,
  SAText,
  SASelect,
  SATable,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAButton,
} from '@saux/design-system-react';
import {useUserAtomState} from '../../../atoms';
import {grabClaimLob} from '../../../utils/utils';
import {Coverage} from '../Summary/types';
import {ClaimLobs} from '../../../commonTypes';

const SAContainerCard = styled(SACard)`
  width: 100%;
  max-width: 1000px;
  display: flex;
  z-index: 100;
`;
const SAContainerBox = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SAButtonBox = styled(SABox)`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const SAHeadingBox = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

export interface CoverageDetailsProps {
  coverages?: Coverage[];
}

export const CoverageDetails = ({coverages}: CoverageDetailsProps) => {
  const data = coverages?.map((coverage: Coverage) => {
    return [
      coverage.name,
      coverage.deductible?.amount ? `$${coverage.deductible?.amount}` : '-',
      coverage.incidentLimit?.amount ? `$${coverage.incidentLimit?.amount}` : '-',
    ];
  });

  return data ? (
    <SATable
      columns={['Coverage', 'Deductible', 'Limit']}
      data={data}
      data-testid="coverageTable"
      variant="table-to-listview"
    />
  ) : null;
};

export enum AutoCoverageTypes {
  Collision = 'Collision',
  Comprehensive = 'Comprehensive',
  Rental = 'Rental',
  PrimeOfLife = 'Prime of Life',
  EX671 = 'EX671',
  AU1022 = 'AU1022 (OEM)',
  MedicalPayments = 'Medical Payments',
  UnderOrUninsured = 'Under/Uninsured',
}

export enum PropertyCoverageTypes {
  PersonalProperty = 'Homeowner - Personal Property (C)',
  PersonalLiability = 'Homeowners - Personal Liability (E)',
  PersonalLiabilityMedPay = 'Homeowners - Personal Liability (F) - Med Pay',
  Dwelling = 'Homeowners - Dwelling (A)',
  LossOfUse = 'Homeowner - Loss of Use (D)',
  OtherStructure = 'Homeowners - Other Structure (B)',
}

export const AUTO_SECONDARY_COVERAGE_TYPES = [
  AutoCoverageTypes.MedicalPayments,
  AutoCoverageTypes.UnderOrUninsured,
];

export const PROPERTY_PRIMARY_COVERAGE_TYPES = [
  PropertyCoverageTypes.PersonalProperty,
  PropertyCoverageTypes.PersonalLiabilityMedPay,
  PropertyCoverageTypes.PersonalLiability,
  PropertyCoverageTypes.OtherStructure,
  PropertyCoverageTypes.LossOfUse,
  PropertyCoverageTypes.Dwelling,
];

export interface CoverageModalProps {
  closeHandler: () => void;
}

export const CoverageModal = ({closeHandler}: CoverageModalProps) => {
  const [userAtomState] = useUserAtomState();
  const lob: string = grabClaimLob(userAtomState?.draftClaimResponse?.result?.lobs);
  const isHomeowners = lob === ClaimLobs.Homeowners;
  const VehiclesOrLocations = isHomeowners
    ? userAtomState?.draftClaimResponse?.result?.policy?.lobs?.homeowner?.locations
    : userAtomState?.draftClaimResponse?.result?.policy?.lobs?.[lob]?.vehicles;

  const [currentMainCoverages, setCurrentMainCoverages] = useState<Coverage[]>();
  const [currentSecondaryCoverages, setCurrentSecondaryCoverages] = useState<Coverage[]>();
  const tabs = ['Main', 'Secondary'];
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabChanged = (newActiveTabIndex: number) => {
    setActiveTabIndex(newActiveTabIndex);
  };
  const locationOrVehicleString = isHomeowners ? 'Location' : 'Vehicle';

  let saSelectOptions = [{label: `Insured ${locationOrVehicleString}`, value: '-1'}];

  if (VehiclesOrLocations) {
    if (isHomeowners) {
      saSelectOptions.push(
        ...VehiclesOrLocations.map((location: any, index: any) => {
          return {label: location?.locationAddress?.displayName, value: index.toString()};
        })
      );
    } else {
      saSelectOptions.push(
        ...VehiclesOrLocations.map((vehicle: any, index: any) => {
          return {
            label: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
            value: index.toString(),
          };
        })
      );
    }
  }

  const updateCurrentAutoCoverages = (index: number) => {
    if (index !== -1) {
      const currentCoverages = VehiclesOrLocations[index]?.coverages;
      setCurrentMainCoverages(
        currentCoverages?.filter(
          (pvc: Coverage) => !AUTO_SECONDARY_COVERAGE_TYPES.includes(pvc.name as AutoCoverageTypes)
        )
      );
      setCurrentSecondaryCoverages(
        currentCoverages?.filter((pvc: Coverage) =>
          AUTO_SECONDARY_COVERAGE_TYPES.includes(pvc.name as AutoCoverageTypes)
        )
      );
    } else {
      setCurrentMainCoverages(undefined);
      setCurrentSecondaryCoverages(undefined);
    }
  };

  const updateCurrentHOCoverages = (index: number) => {
    if (index !== -1) {
      const currentCoverages = VehiclesOrLocations[index]?.coverages;
      setCurrentMainCoverages(currentCoverages);
    } else {
      setCurrentMainCoverages(undefined);
      setCurrentSecondaryCoverages(undefined);
    }
  };

  return (
    <SAModal onClickHandler={() => {}} zIndex={1000}>
      <SAContainerBox padding={'large'}>
        <SAContainerCard variant="minimal">
          <>
            <SAHeadingBox>
              <SAText type="heading-3" text="COVERAGES" />
              <SAIcon
                icon={SAIcons.x}
                size={SAIconSize.medium}
                colorVariant="dark"
                onClick={closeHandler}
                data-testid="closeIcon"
              />
            </SAHeadingBox>
            <SABox pb={'small'}>
              <SAText
                type="small"
                colorVariant="primary"
                text={`Select ${locationOrVehicleString} listed on policy:`}
              />
            </SABox>
            <SASelect
              label={`Insured ${locationOrVehicleString}`}
              id="coverageSelector"
              data-testid="coverageSelector"
              onChange={(event: any) => {
                isHomeowners
                  ? updateCurrentHOCoverages(event.target.value)
                  : updateCurrentAutoCoverages(event.target.value);
              }}
              options={saSelectOptions}
            />
            {(currentMainCoverages || currentSecondaryCoverages) && (
              <SABox pt={'large'}>
                <SACard
                  variant="standard-tabbed"
                  data-testid="coverageCard"
                  tabs={tabs}
                  activeTabIndex={activeTabIndex}
                  tabChangeEventHandler={tabChanged}
                >
                  <CoverageDetails
                    coverages={activeTabIndex ? currentSecondaryCoverages : currentMainCoverages}
                  />
                </SACard>
              </SABox>
            )}
            <SAButtonBox pt={'medium'}>
              <SAButton
                fullWidth={false}
                label={'CLOSE'}
                textTransform="uppercase"
                variant="link"
                color={'text'}
                onClick={closeHandler}
              />
            </SAButtonBox>
          </>
        </SAContainerCard>
      </SAContainerBox>
    </SAModal>
  );
};
