import {Box, Grid} from '@material-ui/core';
import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {CustomerTypes} from '../../../commonTypes';
import {getAddressSwitchProps} from '../../../utils/utils';
import {AddressSwitch} from '../../common/AddressSwitch/AddressSwitch';
import {BlueContainer} from '../../common/Containers/Containers';
import {MultiplePhoneNumbers} from '../../common/MultiplePhoneNumbers/MultiplePhoneNumbers';
import {useFeatureFlags} from '../../common/Providers/Providers';
import {SAEmail} from '../../common/SAEmail/SAEmail';
import {SATextField} from '../../common/TextField/TextField';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {YesNoUnk} from '../../common/YesNoUnk/YesNoUnk';
import {Header} from './DamageDetails';

export const Contractor = () => {
  const {register, errors} = useFormContext();
  const [contractorSelected, setContractorSelected] = useState<String>();
  const addressSwitchArgs = {
    parentFieldName: 'incidentDetails.contractor',
    errors: errors,
  };
  const {manualAddressProps, autocompleteAddressProps} = getAddressSwitchProps(addressSwitchArgs);
  const {featureFlags} = useFeatureFlags();
  const onChange = (value: String) => {
    setContractorSelected(value);
  };

  return (
    <Grid>
      <Box>
        <Header>Has a Contractor been selected?</Header>
      </Box>
      <Box pb={2}>
        <UserPersonaSwitch
          ifPersonas={[CustomerTypes.SaAgent, CustomerTypes.Associate]}
          then={
            <YesNoUnk
              name="incidentDetails.hasAContractorBeenHired"
              yesTestId="YesAContractorBeenHired"
              dontKnowTestId="UnkHasAContractorBeenHired"
              onChange={onChange}
            />
          }
          otherwise={
            <YesNoToggle
              name="incidentDetails.hasAContractorBeenHired"
              yesButtonTestId="YesAContractorBeenHired"
              testId="NoAContractorBeenHired"
              inputRef={register}
              handleChange={onChange}
            />
          }
        />
      </Box>
      {contractorSelected === 'yes' && featureFlags?.FF_DCX_2710 && (
        <>
          <BlueContainer>
            {/* Header */}
            <Grid item xs={12}>
              <Box fontSize={16} fontWeight="fontWeightBold">
                Contractor Information
              </Box>
            </Grid>
            <Box>
              <>
                <Grid item xs={12} md={6}>
                  <Box pt={1} pb={2}>
                    <SATextField
                      hasWhiteFields
                      id="contractorName"
                      label="Contractor Name"
                      inputRef={register()}
                      name="incidentDetails.contractor.contractorName"
                      characterLimit={30}
                      InputLabelProps={{
                        'aria-labelledby': 'contractorName',
                      }}
                      error={errors?.incidentDetails?.contractor?.hasOwnProperty('contractorName')}
                      helperText={errors?.incidentDetails?.contractor?.contractorName?.message}
                    />
                  </Box>
                </Grid>
              </>
              {/* Multiple Phone Numbers */}
              <Grid item xs={12} md={6}>
                <Box pb={2} pt={1}>
                  <MultiplePhoneNumbers
                    scope="incidentDetails.contractor"
                    id="contractor-phone"
                    errorProperty={errors?.incidentDetails?.contractor?.phoneNumbers}
                    mandatoryFields={[0]}
                  />
                </Box>
              </Grid>

              {/* Address Section */}
              <Grid item xs={12}>
                <Box pt={4} pb={1}>
                  <AddressSwitch
                    manualAddressProps={manualAddressProps}
                    autocompleteAddressProps={autocompleteAddressProps}
                    parentFieldName="incidentDetails.contractor"
                  />
                </Box>
              </Grid>

              {/* Email */}
              <Grid item xs={12} md={8}>
                <SAEmail
                  hasWhiteFields
                  inputRef={register()}
                  id="email-contractor"
                  label="Email"
                  name="incidentDetails.contractor.email"
                  InputLabelProps={{
                    'aria-labelledby': 'email-contractor',
                  }}
                  error={errors?.incidentDetails?.contractor?.hasOwnProperty('email')}
                  helperText={errors?.incidentDetails?.contractor?.email?.message || 'optional'}
                />
              </Grid>
            </Box>
          </BlueContainer>
        </>
      )}
    </Grid>
  );
};
