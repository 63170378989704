import React from 'react';
import {Grid, makeStyles, Hidden} from '@material-ui/core';
import {SAText, SABox, SAButton} from '@saux/design-system-react';

const useStyles = makeStyles(theme => ({
  greenBox: {
    margin: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'RGBA(77,132,11,0.2)',
    backgroundBlendMode: 'lighten',
    borderRadius: '10px',
    [theme.breakpoints.up('md')]: {
      width: '90%',
      flexDirection: 'row',
      margin: 'auto',
    },
  },
  itemOrder: {
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      gap: '1rem',
      marginTop: '15px',
    },
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  centerAlign: {
    textAlign: 'center',
  },

  greenButton: {
    background: '#4D840B',
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '15px !important',
    },
  },
}));

export interface InfoSectionProps {
  buttonText: string;
  description?: string;
  header?: string;
  href?: string;
  onClick?: (event: any) => void;
}

export const InfoSection = (props: InfoSectionProps) => {
  const classes = useStyles();

  return (
    <SABox className={classes.greenBox}>
      <Grid container xs={12} className={classes.itemOrder}>
        <Hidden xsDown>
          <Grid item sm={1}></Grid>
        </Hidden>
        <Grid item xs={12} sm={4} className={classes.centerAlign}>
          <SABox m="medium">
            <SAButton
              type="button"
              label={props.buttonText}
              textTransform="uppercase"
              color="secondary"
              data-testid={`info-section-${props.buttonText}`}
              variant="secondary"
              className={classes.greenButton}
              onClick={props.onClick}
              href={props.href}
            />
          </SABox>
        </Grid>
        <Grid item xs={12} sm={7}>
          <SABox className={classes.contentBox}>
            <>
              <SABox>
                <SAText type="standard" weight="bold" text={props.header || ''} />
              </SABox>
              <SABox>
                <SAText type="small" text={props.description || ''} />
              </SABox>
            </>
          </SABox>
        </Grid>
      </Grid>
    </SABox>
  );
};
