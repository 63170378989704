import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const GreyCheckmark = () => (
  <SvgIcon width="13" height="10" viewBox="0 0 13 10" fill="none">
    <path
      d="M1 5.5L4.16506 8.66726L11.2361 1.59619"
      stroke="#DADADA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
