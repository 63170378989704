import {Box, Button, Container, Paper} from '@material-ui/core';
import Cookies from 'js-cookie';
import React from 'react';
import {useUserAtomState} from '../../../atoms';
import {CustomerTypes} from '../../../commonTypes';
import {claimsHubLink} from '../../../routes/links';
import {trackEvent} from '../../../services/analytics';
import {GeneralDetailsSummary} from '../../auto/GeneralDetailsSummary/GeneralDetailsSummary';
import {FormContainer} from '../FormContainer/FormContainer';
import {Icon} from '../Icon/Icon';
import {GreyBorderedBox} from '../Summary/components/GreyBorderedBox/GreyBorderedBox';
import {StateProps} from '../Summary/types';

interface GenericSuccessProps {
  successState: StateProps;
}

export const GenericSuccess = ({successState}: GenericSuccessProps) => {
  const [userAtomState] = useUserAtomState();
  const persona = userAtomState?.gettingStarted?.customerType || '';
  const policyNumber = Cookies.get('policyNumber');

  return (
    <Box>
      <FormContainer>
        <Paper elevation={3}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mt={7}
          >
            <Box fontSize="120px">
              <Icon name="checkmark" css={{width: '150px', height: '150px'}} />
            </Box>
            <Box fontWeight="bold" fontSize="16px" pb={2}>
              Your claim was filed successfully.
            </Box>
            <Box pb={2}>A State Auto CARE professional will reach out concerning the claim.</Box>
            {persona === CustomerTypes.Associate ? (
              <Box pb={2}>
                The claim was filed but we were unable to get the claim details; please check claim
                center for the claim number and adjuster information.
              </Box>
            ) : (
              <Button
                data-testid="visitClaimsHubButton"
                variant="contained"
                color="secondary"
                href={claimsHubLink}
                target="_blank"
                rel="noopener"
                onClick={() => trackEvent({category: 'Summary Page', label: 'Visit The Claimshub'})}
              >
                TRACK CLAIM
              </Button>
            )}
          </Box>

          <GreyBorderedBox>
            <Container>
              <Box>
                <GeneralDetailsSummary
                  mainContact={successState.mainContact}
                  customerType={successState.customerType}
                  lossLocation={successState.lossLocation}
                  submissionType={successState.submissionType}
                  contacts={successState.contacts}
                  policyNumber={policyNumber}
                  incidentTime={successState?.formData?.generalDetails?.lossTime}
                  lossDate={{
                    dateOfLoss: `${successState.lossDate}`,
                    isDateOfLossKnown:
                      successState?.formData?.generalDetails?.dateOfLossUnknown || false,
                  }}
                  howReported={successState?.formData?.gettingStarted?.howReported}
                  fraudLanguage={successState?.formData?.generalDetails?.fraudLanguage}
                  lawsuitClaim={successState?.formData?.generalDetails?.lawsuitClaim}
                  claimNumber={successState?.claimNumber}
                  lossTimezone={successState?.formData?.generalDetails?.lossTimezone}
                />
              </Box>
            </Container>
          </GreyBorderedBox>
        </Paper>
      </FormContainer>
    </Box>
  );
};
