import React, {useEffect, useState} from 'react';
import {TextFieldProps, TextField, makeStyles, Box, FormHelperText} from '@material-ui/core';

export interface SATextFieldProps {
  hasWhiteFields?: boolean;
  requiredError?: string | undefined;
  showCharacterCount?: boolean;
  characterLimit?: number;
  value?: string;
  hiddenTextAreaLabel?: string;
}

const useStyles = makeStyles((theme: any) => ({
  whiteTextField: {
    background: theme.palette.common.white,
  },
}));

export const SATextField = (props: TextFieldProps & SATextFieldProps) => {
  const classes = useStyles();

  const [characterCount, setCharacterCount] = useState<number>(0);

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.onChange && props.onChange(event);
    props.showCharacterCount && setCharacterCount(event.target.value.length);
  };

  useEffect(() => {
    props.showCharacterCount && props.value && setCharacterCount(props.value.length);
  }, [props.value]);

  useEffect(() => {
    if (props.inputProps?.className && props.multiline) {
      const hiddenTextArea = document.getElementsByClassName(props.inputProps.className).item(1);

      if (hiddenTextArea) {
        if (props.label && typeof props.label === 'string') {
          hiddenTextArea.setAttribute('aria-label', `${props.label}-hidden`);
        } else if (props.hiddenTextAreaLabel) {
          hiddenTextArea.setAttribute('aria-label', props.hiddenTextAreaLabel);
        }
      }
    }
  }, []);

  return (
    <>
      <TextField
        {...props}
        label={props.label}
        id={props.id}
        name={props.name}
        className={props.className}
        type="text"
        variant="outlined"
        fullWidth
        inputRef={props.inputRef}
        InputLabelProps={props.InputLabelProps}
        inputProps={{
          ...props.inputProps,
          maxLength: props.characterLimit,
          className: props.multiline ? props.inputProps?.className : classes.whiteTextField,
        }}
        error={props.error}
        helperText={props.helperText}
        autoComplete={props.autoComplete || 'none'}
        onChange={onChange}
      />
      {props.showCharacterCount && (
        <Box
          position="relative"
          top={props.helperText ? '-20px' : '0px'}
          display="flex"
          justifyContent="flex-end"
        >
          <FormHelperText
            error={props.error}
          >{`${characterCount} / ${props.characterLimit}`}</FormHelperText>
        </Box>
      )}
    </>
  );
};
