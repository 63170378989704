import {Box} from '@material-ui/core';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {SAText} from '@saux/design-system-react';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {BaseSwitch} from '../../BaseSwitch/BaseSwitch';
import {SADatePicker} from '../../DatePicker/DatePicker';
import {DynamicRadioList, RadioOption} from '../../DynamicRadioList/DynamicRadioList';
import {Icon} from '../../Icon/Icon';
import {SATextField} from '../../TextField/TextField';
import {CopartComponentProps, VehicleLocationTypes, VehiclePreferences} from '../types';
import * as yup from 'yup';

export enum DaysToPickUp {
  Today = 'today',
  NextDay = 'nextDay',
  TwoDays = 'twoDays',
  MoreThanTwo = 'moreThanTwoDays',
}

export const PickUpDateSchema = {
  pickUpTarget: yup
    .string()
    .when(['acceptCopart', '$inPossession', '$locationType', 'callSuccess'], {
      is: (
        acceptCopart: string,
        inPossession: boolean,
        locationType: string,
        callSuccess: string
      ) =>
        acceptCopart === 'yes' &&
        ((inPossession === false &&
          locationType !== VehicleLocationTypes.Person &&
          (callSuccess === 'successfulRelease' || callSuccess === 'waiverToRelease')) ||
          inPossession === true ||
          locationType === VehicleLocationTypes.Person),
      then: yup.string(),
    }),
  pickUpDate: yup.date().when('pickUpTarget', {
    is: (value: string) => value === DaysToPickUp.MoreThanTwo,
    then: yup
      .date()
      .min(new Date(), 'The date cannot be a past date')
      .typeError('Must be a valid date')
      .isBusinessDay('The chosen date must be a business day')
      .required('Please select when the vehicle can be picked up'),
  }),
  reasonForDate: yup.string().when('pickUpTarget', {
    is: (value: string) => value === DaysToPickUp.MoreThanTwo,
    then: yup.string().max(65000, 'Character limit exceeded').required('This field is required'),
  }),
};

export const PickUpDateTestSchema = yup.object().shape(PickUpDateSchema);

const buildDateOptions = () => {
  let options: (RadioOption & {date: Date | null})[] = [];
  const today = Date.now();
  const todayDate = new Date(today);
  const dayOfWeek = todayDate.getDay();
  const oneDay = 1000 * 60 * 60 * 24;
  const tomorrow = new Date(today + oneDay);
  const twoDays = new Date(today + oneDay * 2);
  const threeDays = new Date(today + oneDay * 3);
  const fourDays = new Date(today + oneDay * 4);
  const formattedToday = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(today);
  const formattedTomorrow = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(tomorrow);
  const formattedTwoDays = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    weekday: 'long',
  }).format(twoDays);
  const formattedThreeDays = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    weekday: 'long',
  }).format(threeDays);
  const formattedFourDays = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    weekday: 'long',
  }).format(fourDays);
  const todayLabel = `Today, ${formattedToday}`;
  const tomorrowLabel = `Tomorrow, ${formattedTomorrow}`;
  const moreThanTwoOption = {
    label: 'Customer needs more than two business days',
    value: DaysToPickUp.MoreThanTwo,
    date: null,
  };

  switch (dayOfWeek) {
    case 0:
      options.push(
        {
          label: tomorrowLabel,
          value: DaysToPickUp.NextDay,
          date: tomorrow,
        },
        {
          label: formattedTwoDays,
          value: DaysToPickUp.TwoDays,
          date: twoDays,
        },
        moreThanTwoOption
      );
      break;
    case 4:
      options.push(
        {
          label: todayLabel,
          value: DaysToPickUp.Today,
          date: todayDate,
        },
        {
          label: tomorrowLabel,
          value: DaysToPickUp.NextDay,
          date: tomorrow,
        },
        {
          label: formattedFourDays,
          value: DaysToPickUp.TwoDays,
          date: fourDays,
        },
        moreThanTwoOption
      );
      break;
    case 5:
      options.push(
        {
          label: todayLabel,
          value: DaysToPickUp.Today,
          date: todayDate,
        },
        {
          label: formattedThreeDays,
          value: DaysToPickUp.NextDay,
          date: threeDays,
        },
        {
          label: formattedFourDays,
          value: DaysToPickUp.TwoDays,
          date: fourDays,
        },
        moreThanTwoOption
      );
      break;
    case 6:
      options.push(
        {
          label: formattedTwoDays,
          value: DaysToPickUp.NextDay,
          date: twoDays,
        },
        {
          label: formattedThreeDays,
          value: DaysToPickUp.TwoDays,
          date: threeDays,
        },
        moreThanTwoOption
      );
      break;
    default:
      options.push(
        {
          label: todayLabel,
          value: DaysToPickUp.Today,
          date: todayDate,
        },
        {
          label: tomorrowLabel,
          value: DaysToPickUp.NextDay,
          date: tomorrow,
        },
        {
          label: formattedTwoDays,
          value: DaysToPickUp.TwoDays,
          date: twoDays,
        },
        moreThanTwoOption
      );
      break;
  }

  return options;
};

const pickUpOptions = buildDateOptions();

export const PickUpDate = ({dispatch, incident}: CopartComponentProps) => {
  const {register, errors, setValue, trigger} = useFormContext();
  const [moreThanTwo, setMoreThanTwo] = useState<boolean>(false);

  const onRadioChange = (value: any) => {
    setMoreThanTwo(value === DaysToPickUp.MoreThanTwo);

    dispatch &&
      dispatch({
        type: VehiclePreferences.PickUpTarget,
        value,
        id: incident?.publicID,
      });

    const chosenOption = pickUpOptions.find((option: any) => option.value === value);

    dispatch &&
      dispatch({
        type: VehiclePreferences.PickUpDate,
        value: chosenOption?.date,
        id: incident?.publicID,
      });
  };

  const onReasonChange = (e: any) => {
    dispatch &&
      dispatch({
        type: VehiclePreferences.ReasonForDate,
        value: e.target.value,
        id: incident?.publicID,
      });
  };

  const [dateSelected, setDateSelected] = useState<Date | null>(null);

  const dateTimeChangeHandler = (date: Date | null, value?: string | null | undefined) => {
    if (date instanceof Date && date.toString() !== 'Invalid Date') {
      setValue(VehiclePreferences.PickUpDate, date);
      setDateSelected(date);
      trigger(VehiclePreferences.PickUpDate);

      dispatch &&
        dispatch({
          type: VehiclePreferences.PickUpDate,
          value: date,
          id: incident?.publicID,
        });
    }
  };

  useEffect(() => {
    setMoreThanTwo(incident?.copartPreferences.pickUpTarget === DaysToPickUp.MoreThanTwo);
    setDateSelected(incident?.copartPreferences.pickUpDate || null);
  }, [incident?.publicID]);

  return (
    <>
      <Box pt={4}>
        <Box pb={1}>
          <SAText text="When can the vehicle be picked up?" />
        </Box>
        <DynamicRadioList
          options={pickUpOptions}
          name={VehiclePreferences.PickUpTarget}
          onChange={onRadioChange}
          defaultValue={incident?.copartPreferences.pickUpTarget || pickUpOptions[0].value}
          key={`${incident?.publicID}-${VehiclePreferences.PickUpTarget}`}
          hasErrors={errors?.hasOwnProperty(VehiclePreferences.PickUpTarget)}
          errorMessage={errors?.[VehiclePreferences.PickUpTarget]?.message || ''}
        />
      </Box>
      <BaseSwitch
        matches={moreThanTwo}
        then={
          <>
            <Box pt={3} width={{md: '60%'}}>
              <SATextField
                hasWhiteFields
                id={VehiclePreferences.ReasonForDate}
                label="Reason"
                inputRef={register}
                name={VehiclePreferences.ReasonForDate}
                characterLimit={65000}
                InputLabelProps={{
                  'aria-labelledby': VehiclePreferences.ReasonForDate,
                }}
                defaultValue={incident?.copartPreferences.reasonForDate || ''}
                key={`${incident?.publicID}-${VehiclePreferences.ReasonForDate}`}
                onChange={onReasonChange}
                error={errors?.hasOwnProperty(VehiclePreferences.ReasonForDate)}
                helperText={errors?.[VehiclePreferences.ReasonForDate]?.message || ''}
              />
            </Box>
            <Box pt={3} width={{sm: '60%'}}>
              <SADatePicker
                id={VehiclePreferences.PickUpDate}
                label="Next Business Day Vehicle Can Be Picked Up"
                name={VehiclePreferences.PickUpDate}
                value={dateSelected || null}
                InputLabelProps={{'aria-labelledby': VehiclePreferences.PickUpDate}}
                error={errors?.hasOwnProperty(VehiclePreferences.PickUpDate)}
                helperText={errors?.[VehiclePreferences.PickUpDate]?.message || ''}
                keyboardIcon={<Icon name="calendar" />}
                onChange={dateTimeChangeHandler}
                inputRef={register()}
                key={`${incident?.publicID}-${VehiclePreferences.PickUpDate}`}
                shouldDisableDate={(day: MaterialUiPickersDate) =>
                  (day &&
                    (day.getDay() === 0 || day.getDay() === 6 || day.getTime() < Date.now())) ||
                  false
                }
              />
            </Box>
          </>
        }
      />
    </>
  );
};
