export enum NatureOfLoss {
  Animal = 'Animal',
  FallingObject = 'Falling Object',
  NonWeatherRelated = 'Non-Weather Related',
  Water = 'Water',
  Fire = 'Fire',
  Vandalism = 'Vandalism',
  MoldRot = 'Mold/Rot',
  Theft = 'Theft',
  WinterWeather = 'Winter Weather',
  Wind = 'Wind',
  Flood = 'Flood',
  Hail = 'Hail',
  Lightning = 'Lightning',
  NotWeatherRelated = 'Not Weather Related',
  WeatherRelated = 'Weather Related',
  Other = 'Other',
  FallSlipOrTrip = 'Fall, Slip, or Trip',
}

export enum IncidentTypes {
  YourHomeAndStructure = 'Your Home and Other Structures',
  InsuredHomeAndStructure = 'Insured Home and Other Structures',
  YourBelongings = 'Your Personal Belongings',
  InsuredsBelongings = "Insured's Personal Belongings",
  OtherPersonsProperty = "Other Person's Property",
  PersonalInjuries = 'Injuries',
}

export enum PropertyIncidentTypes {
  HomeAndStructure = 'Your Home and other structures',
  YourBelongings = 'your belongings',
  OtherPersonsProperty = "Other person's property",
  PersonalInjuries = 'Personal Injuries',
}

export enum WhatWasDamaged {
  Home = 'Home',
  DetachedGarage = 'Detached Garage',
  OtherBuilding = 'Other Building',
  Fence = 'Fence',
  Pool = 'Pool',
  DockBoatLift = 'Dock/Boat Lift',
  Tree = 'Tree',
  Other = 'Other',
}

export const PROPERTY_MAPPING = {
  [WhatWasDamaged.Home]: 'home',
  [WhatWasDamaged.DetachedGarage]: 'detachedGarage',
  [WhatWasDamaged.OtherBuilding]: 'otherBuilding',
  [WhatWasDamaged.Fence]: 'fence',
  [WhatWasDamaged.Pool]: 'pool',
  [WhatWasDamaged.DockBoatLift]: 'dockBoatLift',
  [WhatWasDamaged.Tree]: 'tree',
  [WhatWasDamaged.Other]: 'other',
};

export const INCIDENT_MAPPING = {
  [IncidentTypes.YourHomeAndStructure]: 'yourHomeOtherStructures',
  [IncidentTypes.InsuredHomeAndStructure]: 'yourHomeOtherStructures',
  [IncidentTypes.OtherPersonsProperty]: 'otherPersonProperty',
  [IncidentTypes.PersonalInjuries]: 'personalInjuries',
  [IncidentTypes.YourBelongings]: 'yourPersonalBelongings',
  [IncidentTypes.InsuredsBelongings]: 'yourPersonalBelongings',
};

const COMMON = {
  [NatureOfLoss.Animal]: 'animal',
  [NatureOfLoss.FallingObject]: 'FallingObject',
  [NatureOfLoss.NonWeatherRelated]: 'other_pd_nwthr_rel_ext',
  [NatureOfLoss.Water]: '16',
  [NatureOfLoss.Fire]: 'fire',
  [NatureOfLoss.Vandalism]: 'vandalism',
  [NatureOfLoss.Theft]: 'theft_ext',
  [NatureOfLoss.MoldRot]: 'MO',
  [NatureOfLoss.WinterWeather]: 'WT',
  [NatureOfLoss.Wind]: 'wind',
  [NatureOfLoss.Flood]: '37',
  [NatureOfLoss.Hail]: 'hail',
  [NatureOfLoss.Lightning]: '33',
  [NatureOfLoss.WeatherRelated]: 'other_pd_wthr_rel_ext',
  [NatureOfLoss.Other]: '41',
};

export const INCIDENT_NATURE_OF_LOSS_MAPPING = {
  [IncidentTypes.YourHomeAndStructure]: {...COMMON},
  [IncidentTypes.InsuredHomeAndStructure]: {...COMMON},
  [IncidentTypes.YourBelongings]: {...COMMON},
  [IncidentTypes.InsuredsBelongings]: {...COMMON},
  [IncidentTypes.OtherPersonsProperty]: {
    [NatureOfLoss.NotWeatherRelated]: 'other_pd_nwthr_rel_ext',
    [NatureOfLoss.WeatherRelated]: 'other_pd_wthr_rel_ext',
    [NatureOfLoss.Other]: '41',
  },
  [IncidentTypes.PersonalInjuries]: {
    [NatureOfLoss.FallSlipOrTrip]: 'fall',
    [NatureOfLoss.Other]: '41',
  },
};
