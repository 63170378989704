import {Box, Grid} from '@material-ui/core';
import React from 'react';
import {FormContainer} from '../FormContainer/FormContainer';
import {SATextField} from '../TextField/TextField';
import * as yup from 'yup';
import {useFormContext} from 'react-hook-form';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../RegisterBreadcrumb/RegisterBreadcrumb';
import {useGetAdditionalNotesAtomState} from '../../../atoms';
import {CustomerTypes} from '../../../commonTypes';
import {UserPersonaSwitch} from '../UserPersonaSwitch/UserPersonaSwitch';

export const AdditionalNotesSchema = {
  additionalNotes: yup.object().when('$acknowledgement', {
    is: (value: boolean | undefined) => value === true,
    then: yup.object().shape({
      notes: yup.string().max(1000, 'Character limit exceeded'),
    }),
  }),
};

export const AdditionalNotesTestSchema = yup.object().shape({
  additionalNotes: yup.object().shape({
    notes: yup.string().max(1000, 'Character limit exceeded'),
  }),
});

export const AdditionalNotes = () => {
  const {register, errors} = useFormContext();
  const getAdditionalNotesAtomState = useGetAdditionalNotesAtomState();

  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.AdditionalNotes}
      displayName={WAYPOINTS[WaypointNames.AdditionalNotes].displayName}
    >
      <UserPersonaSwitch
        ifPersonas={[CustomerTypes.Associate, CustomerTypes.SaAgent]}
        then={
          <input
            type="hidden"
            ref={register()}
            name="additionalNotes.notes"
            value={getAdditionalNotesAtomState?.additionalNotes}
            data-testid="hiddenAdditionalNotes"
          />
        }
        otherwise={
          <FormContainer header="Additional Notes">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box pb={2}>Enter any additional notes if applicable.</Box>
                <SATextField
                  label="Notes"
                  id={`notes`}
                  InputLabelProps={{'aria-labelledby': 'notes'}}
                  name="additionalNotes.notes"
                  inputRef={register}
                  error={errors?.additionalNotes?.hasOwnProperty('notes')}
                  helperText={errors?.additionalNotes?.notes?.message || 'optional'}
                  multiline
                  rowsMax={5}
                  showCharacterCount
                  characterLimit={1000}
                  inputProps={{className: 'additionalNotesTextArea'}}
                />
              </Grid>
            </Grid>
          </FormContainer>
        }
      />
    </RegisterBreadcrumb>
  );
};
