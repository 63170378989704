import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const ImpactSpot = () => {
  return (
    <SvgIcon
      width="40px"
      height="40px"
      viewBox="0 0 33 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fontSize="inherit"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Damage-Selector" transform="translate(-571.000000, -71.000000)">
          <g
            id="Impact-Selectors-Green"
            transform="translate(390.500000, 201.000000) scale(-1, 1) rotate(-90.000000) translate(-390.500000, -201.000000) translate(261.000000, -125.000000)"
          >
            <g id="Impact-Spot" transform="translate(227.000000, 113.000000)">
              <circle
                id="Oval"
                fill="#76AA00"
                cx="15.7002083"
                cy="15.7002083"
                r="15.7002083"
              ></circle>
              <polygon
                id="Combined-Shape"
                fill="#FFFFFF"
                fillRule="nonzero"
                points="18.1866667 7.311875 18.1866667 13.2489286 24.935625 13.2489286 24.935625 17.9985714 18.1866667 17.9985714 18.1866667 23.935625 13.21375 23.935625 13.21375 17.9985714 6.46479167 17.9985714 6.46479167 13.2489286 13.21375 13.2489286 13.21375 7.311875"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
