import {Box, FormGroup, Grid, makeStyles} from '@material-ui/core';
import React, {useEffect} from 'react';
import {FormContainer} from '../FormContainer/FormContainer';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../RegisterBreadcrumb/RegisterBreadcrumb';
import {CheckboxWithLabel} from '../../common/CheckboxWithLabel/CheckboxWithLabel';
import {useFeatureFlags} from '../Providers/Providers';
import {BaseSwitch} from '../BaseSwitch/BaseSwitch';
import {SACheckboxVariants} from '../Checkbox/Checkbox';
import {HowReported} from '../../auto/GettingStarted/GettingStarted';
import {ScriptBox} from '../ScriptBox/ScriptBox';
import {useGetNoticeOnlyAtomState, useGetReporterAtomState, useUserAtomState} from '../../../atoms';
import {CustomerTypes} from '../../../commonTypes';

export enum DisplayMessages {
  CustomerMessage = 'Our claim payments are issued quickly and securely to your bank account via direct deposit. To complete the process, you will need your bank routing number and account number. You should receive a secure link from State Auto shortly.',
  AssociateMessage = 'If you would prefer a check, we can mail one to you. However, it can take up to 7-10 business days if that is acceptable.',
  CustomerOptLabel = "I'd prefer a check is mailed, and understand it can take 7-10 business days",
  AssociateOptLabel = 'The insured would prefer a check is mailed',
}

const useStyles = makeStyles(theme => ({
  insuredCheckbox: {
    paddingLeft: 0,
    paddingTop: '16px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '32px',
    },
  },
}));

interface ClaimPreferencesProps {
  setShowClaimPreferencesWaypoint?: any;
}

export const ClaimPreferences = ({setShowClaimPreferencesWaypoint}: ClaimPreferencesProps) => {
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType as CustomerTypes;
  const reporterMetaData = useGetReporterAtomState();
  const {featureFlags} = useFeatureFlags();
  const isNoticeOnly = useGetNoticeOnlyAtomState();
  const classes = useStyles();
  const isAssociate = userPersona === CustomerTypes.Associate;
  const displayClaimPreferences =
    featureFlags?.FF_DCX_2101 &&
    reporterMetaData.isInsured &&
    (userPersona === CustomerTypes.SaCustomer ||
      (isAssociate &&
        reporterMetaData.isNotAgentLienholder &&
        reporterMetaData.howReported === HowReported.Phone &&
        !isNoticeOnly));

  useEffect(() => {
    setShowClaimPreferencesWaypoint && setShowClaimPreferencesWaypoint(displayClaimPreferences);
  }, [displayClaimPreferences]);

  return (
    <BaseSwitch
      matches={displayClaimPreferences as boolean}
      then={
        <RegisterBreadcrumb
          waypointName={WaypointNames.ClaimPreferences}
          displayName={WAYPOINTS[WaypointNames.ClaimPreferences].displayName}
        >
          <FormContainer header="Claim Preferences">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BaseSwitch
                  matches={isAssociate}
                  then={
                    <Box>
                      <ScriptBox message={DisplayMessages.CustomerMessage} />
                      <Box pb={2} pt={2}>
                        If the customer does not want e-pay or seems hesitant:
                      </Box>
                      <ScriptBox message={DisplayMessages.AssociateMessage} />
                    </Box>
                  }
                  otherwise={<Box>{DisplayMessages.CustomerMessage}</Box>}
                />
                <Box className={classes.insuredCheckbox}>
                  <FormGroup>
                    <CheckboxWithLabel
                      name="claimsPreference.isEpayOptOut"
                      formControlLabelProps={{
                        label: isAssociate
                          ? DisplayMessages.AssociateOptLabel
                          : DisplayMessages.CustomerOptLabel,
                      }}
                      checkboxProps={{
                        id: 'epay-opt-out-checkbox',
                        variant: SACheckboxVariants.CheckShadow,
                      }}
                    />
                  </FormGroup>
                </Box>
              </Grid>
            </Grid>
          </FormContainer>
        </RegisterBreadcrumb>
      }
    />
  );
};
