import React from 'react';
import {Typography, Button, makeStyles, Theme} from '@material-ui/core';
import {SABox} from '@saux/design-system-react';

export interface StyleProps {
  boxHeight?: string;
  tileHeight?: string;
  bgColor?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  greyBox: {
    backgroundColor: ({bgColor}) => {
      return bgColor ? '#F4F6FA' : '';
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '40px',
    [theme.breakpoints.up('md')]: {
      height: ({boxHeight}) => {
        return boxHeight?.length ? boxHeight : '190px';
      },
    },
  },
  smallText: {
    fontSize: '19.2px',
    fontWeight: 'normal',
    color: '#3B3B3B',
    padding: '0px 0px 12px 0px',
  },
  summaryTile: {
    paddingTop: '20px',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: ({tileHeight}) => {
        return tileHeight?.length ? tileHeight : '230px';
      },
    },
  },
  border: {
    [theme.breakpoints.up('md')]: {
      '&:nth-of-type(odd)': {
        borderRight: '1px solid #BFCBDC',
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
}));

export interface SummaryTileProps {
  headerText: string;
  buttonText: string;
  onClick?: (event: any) => void;
  href?: string;
  hideBorder?: boolean;
  bgColor?: boolean;
  boxHeight?: string;
  tileHeight?: string;
  customStyle?: any;
}

export const SummaryTile = ({
  headerText,
  buttonText,
  onClick,
  href,
  bgColor,
  boxHeight,
  tileHeight,
  customStyle,
}: SummaryTileProps) => {
  const classes = useStyles({bgColor, boxHeight, tileHeight});

  return (
    <SABox className={classes.summaryTile}>
      <SABox p="large" ml="large" mr="large" className={classes.greyBox}>
        <Typography className={classes.smallText}>{headerText}</Typography>

        <SABox pt="medium">
          <Button
            style={customStyle}
            type="button"
            color="primary"
            variant="contained"
            onClick={onClick}
            href={href}
          >
            {buttonText}
          </Button>
        </SABox>
      </SABox>
    </SABox>
  );
};
