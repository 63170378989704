import React from 'react';
import {Box, Hidden, makeStyles} from '@material-ui/core';
import {Icon} from '../Icon/Icon';

const useStyles = makeStyles(theme => ({
  blueContainerBox: {
    padding: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      padding: '20px 20px',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  sidebar: {
    borderLeft: '5px solid #189ECC',
    borderRadius: '4px',
  },
  defaultContainerColor: {
    backgroundColor: '#E2F5F9',
  },
  errorContainerColor: {
    backgroundColor: '#F7E5E8',
  },
  errorContainerShadow: {
    boxShadow: '0px 2px 5px rgba(1, 83, 142, 0.2)',
  },
  errorSidebar: {
    borderLeft: '5px solid #B00020',
    borderRadius: '4px',
  },

  iconStyling: {
    alignSelf: 'center',
    flexGrow: 1,
    textAlign: 'center',
    lineHeight: '1px',
    margin: 'auto',
  },

  content: {
    flexDirection: 'column',
    alignSelf: 'center',
    flexGrow: 4,
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      paddingRight: '2rem',
    },
  },
  italicized: {
    fontStyle: 'italic',
  },
  defaultColor: {
    color: '#005C86',
  },
  errorColor: {
    color: '#590010',
  },
}));

export enum contentStyleTypes {
  Italic = 'italic',
}

export enum containerStyleTypes {
  Sidebar = 'sidebar',
}

export enum Variant {
  Default = 'default',
  Error = 'error',
}

interface InformationBubbleBoxProps {
  message?: string;
  contentStyle?: contentStyleTypes;
  containerStyle?: containerStyleTypes;
  children?: JSX.Element;
  name?: string;
  variant?: Variant;
}

export const InformationBubbleBox = ({
  message,
  contentStyle,
  containerStyle,
  children,
  name,
  variant,
}: InformationBubbleBoxProps) => {
  const classes = useStyles();
  let contentClasses = classes.content;
  let iconName = name ? name : 'infoBubble';
  let containerClasses = classes.blueContainerBox;

  if (contentStyle === contentStyleTypes.Italic) {
    contentClasses = `${contentClasses} ${classes.italicized}`;
  }

  if (containerStyle === containerStyleTypes.Sidebar) {
    if (variant === Variant.Error) {
      containerClasses = `${containerClasses} ${classes.errorSidebar}`;
    } else {
      containerClasses = `${containerClasses} ${classes.sidebar}`;
    }
  }

  if (variant === Variant.Error) {
    contentClasses = `${contentClasses} ${classes.errorColor}`;
    containerClasses = `${containerClasses} ${classes.errorContainerColor} ${classes.errorContainerShadow}`;
    iconName = name ? name : 'errorIcon';
  } else {
    contentClasses = `${contentClasses} ${classes.defaultColor}`;
    containerClasses = `${containerClasses} ${classes.defaultContainerColor}`;
  }

  return (
    <Box className={containerClasses}>
      <Hidden xsDown>
        <Box p={1} pl={0} className={classes.iconStyling}>
          <Icon name={iconName} />
        </Box>
      </Hidden>

      {message && (
        <Box className={contentClasses} p={1}>
          {message}
        </Box>
      )}
      {children}
    </Box>
  );
};
