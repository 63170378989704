import React, {CSSProperties} from 'react';
import {withStyles, CheckboxProps, Checkbox} from '@material-ui/core';
import styled from 'styled-components';
import checkmark from '../../common/Icon/assets/GreyCheckmark.svg';

export enum SACheckboxVariants {
  Default = 'default',
  CheckShadow = 'check_shadow',
}

export const VARIANT_COLORS: {[key: string]: string} = {
  [SACheckboxVariants.Default]: '#189ecc',
  [SACheckboxVariants.CheckShadow]: '#0173C6',
};

export const VARIANT_BACKGROUND_IMAGES: {[key: string]: string} = {
  [SACheckboxVariants.Default]: 'none',
  [SACheckboxVariants.CheckShadow]: checkmark,
};

export interface SACheckboxProps {
  variant?: SACheckboxVariants | undefined;
  pointerEvent?: CSSProperties['pointerEvents'] | undefined;
}

export const CheckShadowContainer = styled.div`
  position: relative;
`;

export const SACheckbox = withStyles({
  root: {
    color: (props: CheckboxProps & SACheckboxProps) =>
      VARIANT_COLORS[props?.variant || SACheckboxVariants.Default],
    backgroundImage: (props: CheckboxProps & SACheckboxProps) =>
      `url(${VARIANT_BACKGROUND_IMAGES[props?.variant || SACheckboxVariants.Default]})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '33% 29%',
    backgroundSize: '9px',
    '&$checked': {
      color: (props: CheckboxProps & SACheckboxProps) =>
        VARIANT_COLORS[props?.variant || SACheckboxVariants.Default],
    },
  },
  icon: {
    width: (props: CheckboxProps & SACheckboxProps) =>
      props?.variant || SACheckboxVariants.Default ? 16 : 25,
    height: (props: CheckboxProps & SACheckboxProps) =>
      props?.variant || SACheckboxVariants.Default ? 16 : 25,
  },
  checked: {},
})((props: CheckboxProps & SACheckboxProps) => (
  <Checkbox color="default" {...props} style={{pointerEvents: props.pointerEvent || 'auto'}} />
));
