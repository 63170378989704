import React, {useEffect} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import * as yup from 'yup';
import {ContactTypes} from '../../../commonTypes';
import {
  emailValidation,
  cityValidation,
  stateValidation,
  streetAddressValidation,
  zipCodeValidation,
  OptionalPhoneNumbersSchema,
  propertyDamageDescriptionValidation,
  firstNameValidation,
  lastNameValidation,
} from '../../../validations';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {
  PropertyDamageForm,
  PropertyForms,
} from '../../common/PropertyDamageForm/PropertyDamageForm';

const schema = yup.array().of(
  yup.object().shape({
    ownerId: yup.string().required('Please select the owner of the property'),
    firstName: yup.string().when('radioSelection', {
      is: (value: string) => {
        return value === ContactTypes.Person;
      },
      then: firstNameValidation().required('First Name is required'),
    }),
    lastName: yup.string().when('radioSelection', {
      is: (value: string) => {
        return value === ContactTypes.Person;
      },
      then: lastNameValidation().required('Last Name is required'),
    }),
    companyName: yup.string().when('radioSelection', {
      is: (value: string) => {
        return value === ContactTypes.Company;
      },
      then: yup.string().required('Company Name is required'),
    }),
    propertyTypeSelection: yup.string().required('Property type is required'),
    otherProperty: yup.string().when('propertyTypeSelection', {
      is: (value: string) => {
        return value === 'other';
      },
      then: yup.string().required('Property type is required'),
    }),
    damage: propertyDamageDescriptionValidation().required('Damage description is required'),
    propertyAddress: yup.object().shape({
      autocompleteAddress: yup.string().nullable(),
      manualAddressEntry: yup.string().nullable(),
      address: streetAddressValidation().nullable(),
      city: cityValidation(true),
      state: stateValidation().nullable(),
      zipCode: zipCodeValidation(true),
    }),
    ownerAddress: yup.object().shape({
      autocompleteAddress: yup.string().nullable(),
      manualAddressEntry: yup.string().nullable(),
      address: streetAddressValidation().nullable(),
      city: cityValidation(true),
      state: stateValidation().nullable(),
      zipCode: zipCodeValidation(true),
    }),
    email: emailValidation(true),
    ...OptionalPhoneNumbersSchema,
  })
);

export const OtherPropertyDetailsTestSchema = yup.object().shape({
  otherProperty: schema,
});

export const OtherPropertyDetailsSchema = {
  otherProperty: schema,
};

export const OtherPropertyDetails = () => {
  const {control} = useFormContext();
  const fieldArray = useFieldArray({
    control,
    name: PropertyForms.OtherProperty,
  });

  const addAdditionalProperty = () => {
    const newProperty = {
      propertyTypeSelection: '',
      otherProperty: '',
      damage: '',
      propertyAddress: {
        autocompleteAddress: '',
        manualAddressEntry: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
      },
      ownerId: '',
      radioSelection: ContactTypes.Person,
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      phoneNumbers: [{phoneNumber: '', phoneType: '', verifiedNumber: 'false'}],
      ownerAddressSameAsProperty: '',
      ownerAddress: {
        autocompleteAddress: '',
        manualAddressEntry: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
      },
    };

    fieldArray.append({...newProperty}, false);
  };

  useEffect(() => {
    addAdditionalProperty();
  }, []);

  return (
    <FormContainer header="Other Person's Property Damage">
      <PropertyDamageForm
        formName={PropertyForms.OtherProperty}
        fieldArray={fieldArray}
        addAdditionalProperty={addAdditionalProperty}
      />
    </FormContainer>
  );
};
