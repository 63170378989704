import React from 'react';
import {Typography, makeStyles} from '@material-ui/core';

export interface SummaryHeaderProps {
  headerText: string;
  forBlueContainer?: boolean;
}

export const SummaryHeader = ({headerText, forBlueContainer}: SummaryHeaderProps) => {
  const useStyles = makeStyles(theme => ({
    headerText: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: forBlueContainer ? '#3B3B3B' : theme.palette.primary.main,
    },
  }));

  const classes = useStyles();

  return <Typography className={classes.headerText}>{headerText}</Typography>;
};
