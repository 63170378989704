import React, {useEffect, useState} from 'react';
import {YesNoToggle} from '../../YesNoToggle/YesNoToggle';
import {CopartComponentProps, VehiclePreferences} from '../types';
import * as yup from 'yup';
import {Box} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import {SAText} from '@saux/design-system-react';
import {BaseSwitch} from '../../BaseSwitch/BaseSwitch';
import {DynamicRadioList} from '../../DynamicRadioList/DynamicRadioList';

export const LoanLeaseSchema = {
  isLoanLease: yup.string().when('$vehicleDriveable', {
    is: (value: boolean) => value === false,
    then: yup.string().required('Please select if the vehicle is a loan/lease'),
  }),
  loanOrLease: yup.string().when(['$vehicleDriveable', 'isLoanLease'], {
    is: (vehicleDrivable: boolean, isLoanLease: string) =>
      vehicleDrivable === false && isLoanLease === 'yes',
    then: yup.string().required('Please select whether the vehicle is a loan or lease').nullable(),
  }),
};

export const LoanLeaseTestSchema = yup.object().shape(LoanLeaseSchema);

const options = [
  {
    label: 'Loan',
    value: 'loan',
  },
  {
    label: 'Lease',
    value: 'lease',
  },
];

export const LoanLease = ({dispatch, incident}: CopartComponentProps) => {
  const {errors} = useFormContext();
  const [isLoanLease, setIsLoanLease] = useState<string>('');

  const handleChange = (value: string) => {
    setIsLoanLease(value);

    dispatch &&
      dispatch({
        type: VehiclePreferences.IsLoanLease,
        value,
        id: incident?.publicID,
      });
  };

  const onRadioChange = (value: string) => {
    dispatch &&
      dispatch({
        type: VehiclePreferences.LoanOrLease,
        value,
        id: incident?.publicID,
      });
  };

  useEffect(() => {
    setIsLoanLease(incident?.copartPreferences.isLoanLease || '');
  }, [incident?.publicID]);

  return (
    <>
      <Box pb={1} pt={4}>
        <SAText text="Does a loan/lease on the vehicle exist?" />
      </Box>
      <YesNoToggle
        handleChange={(value: string) => handleChange(value)}
        name={VehiclePreferences.IsLoanLease}
        defaultValue={incident?.copartPreferences.isLoanLease || ''}
        key={`${incident?.publicID}-${VehiclePreferences.IsLoanLease}`}
        hasError={errors?.hasOwnProperty(VehiclePreferences.IsLoanLease)}
        secondaryStyles
        errorMessage={errors?.[VehiclePreferences.IsLoanLease]?.message}
      />

      <BaseSwitch
        matches={isLoanLease === 'yes'}
        then={
          <>
            <Box pt={4}>
              <SAText text="Is the vehicle a loan or lease?" />
            </Box>
            <Box pt={2}>
              <DynamicRadioList
                options={options}
                name={VehiclePreferences.LoanOrLease}
                onChange={onRadioChange}
                defaultValue={incident?.copartPreferences.loanOrLease}
                key={`${incident?.publicID}-${VehiclePreferences.LoanOrLease}`}
                row
                hasErrors={errors?.hasOwnProperty(VehiclePreferences.LoanOrLease)}
                errorMessage={errors?.[VehiclePreferences.LoanOrLease]?.message || ''}
              />
            </Box>
          </>
        }
      />
    </>
  );
};
