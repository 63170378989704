import React from 'react';
import {Grid, Box, Typography} from '@material-ui/core';
import {AssociatePolicySearch} from '../components/PolicySearch/AssociatePolicySearch';
import {BaseSwitch} from '../components/common/BaseSwitch/BaseSwitch';
import {Icon} from '../components/common/Icon/Icon';

interface AssociatePolicySearchPageProps {
  isRegisteredUser?: boolean;
}

export const AssociatePolicySearchPage = (props: AssociatePolicySearchPageProps) => {
  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={8}>
        <Box mt={8} mb={8}>
          <BaseSwitch
            matches={props.isRegisteredUser}
            then={<AssociatePolicySearch />}
            otherwise={
              <Box display="flex" data-testid="access-error-message">
                <Box pr={1}>
                  <Icon name="warning" />
                </Box>
                <Typography>
                  We currently show that you don’t have access to this application, please send an
                  email to{' '}
                  <a href="mailto:digitalclaimsexpproductteam@stateauto.com">
                    digitalclaimsexpproductteam@stateauto.com
                  </a>{' '}
                  to request access.
                </Typography>
              </Box>
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
};
