import {SAText, SAColumns, SABox, SATable, SATableColumn} from '@saux/design-system-react';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import styled from 'styled-components';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {SASelect} from '../../common/Select/Select';
import {Grid} from '@material-ui/core';
import {useCatastropheAtomState} from '../../../atoms';
interface catastropheProps {
  hasDamageDueToWeather: boolean;
}

export const LeftInput = styled.input`
  margin-right: 25px;
  height: 15px;
  width: 15px;
`;

export const Catastrophe = (props: catastropheProps) => {
  const {register, errors} = useFormContext();

  useEffect(() => {
    setToggleYesstate(props.hasDamageDueToWeather);
  }, [props.hasDamageDueToWeather]);

  const handleToggleOnChange = (value: string) => {
    if (value === 'yes') {
      setToggleYesstate(true);
    } else {
      setToggleYesstate(false);
    }
  };

  const columns: SATableColumn[] = [
    {
      align: 'center',
      name: 'CAT Number',
    },
    {
      align: 'center',
      name: 'Description',
    },
    {
      align: 'center',
      name: 'Location',
    },
    {
      align: 'center',
      name: 'Date Range',
    },
  ];

  const [catastropheAtomState] = useCatastropheAtomState();
  const data = catastropheAtomState.map(cats => {
    const {catastrophe_number, description, state, begin_date, end_date} = cats;
    const dateRange = begin_date + ' to ' + end_date;
    const catastrophe = {catastrophe_number, description, state, dateRange};
    const objVals = Object.values(catastrophe);
    return objVals;
  });

  const [selectedState, setSelectedState] = useState('');
  const onRowClick = (event: React.MouseEvent<HTMLElement>, selectedRowIndex: number) => {
    event.preventDefault();
    setSelectedState(data[selectedRowIndex][0]);
  };

  const handleOnChange = (event: ChangeEvent<{name?: string | undefined; value: unknown}>) => {
    setSelectedState(event.target.value as string);
  };

  const selectedCatastrophe = catastropheAtomState.find(
    catastrophe => catastrophe.catastrophe_number === selectedState
  );

  const yesNoDefault: string = props.hasDamageDueToWeather ? 'yes' : 'no';
  const [toggleYesstate, setToggleYesstate] = useState<boolean | undefined>();
  return (
    <>
      {data.length > 0 && (
        <SAColumns columns={[12, 12]}>
          <SABox mb="medium" mt="large">
            <SAText text="Is this claim associated with a CAT?" />
          </SABox>
          <SABox mb="xl">
            <YesNoToggle
              name="isCatastrophe"
              defaultValue={yesNoDefault}
              handleChange={(value: string) => handleToggleOnChange(value)}
            />
          </SABox>
          {toggleYesstate && (
            <SABox mb="xl">
              <SABox mb="medium">
                <SAText type="heading-3" text="Related Open CATs" />
              </SABox>
              <SATable
                columns={columns}
                data={data}
                onRowClick={onRowClick}
                variant="table-to-listview"
              />
              <SABox mt="large">
                <Grid item xs={12} md={4} sm={6}>
                  <SASelect
                    name="incidentDetails.catastrophe"
                    inputRef={register}
                    label="Select Related CAT"
                    id="catastrophe"
                    data-testid="catastropheList"
                    error={errors?.incidentDetails?.hasOwnProperty('catastrophe')}
                    helperText={errors?.incidentDetails?.firstIncident?.message}
                    defaultValue=""
                    value={selectedState}
                    onChange={handleOnChange}
                  >
                    {catastropheAtomState.map(cats => (
                      <option
                        key={cats.catastrophe_number}
                        value={cats.catastrophe_number}
                        data-testid={cats.catastrophe_number}
                      >
                        {`${cats.catastrophe_number} - ${cats.description}`}
                      </option>
                    ))}
                  </SASelect>
                  <input
                    type="hidden"
                    name={`incidentDetails.catastrophe.catNumber`}
                    ref={register()}
                    value={selectedCatastrophe?.catastrophe_number}
                  />
                  <input
                    type="hidden"
                    name={`incidentDetails.catastrophe.description`}
                    ref={register()}
                    value={selectedCatastrophe?.description}
                  />
                  <input
                    type="hidden"
                    name={`incidentDetails.catastrophe.catState`}
                    ref={register()}
                    value={selectedCatastrophe?.state}
                  />
                  <input
                    type="hidden"
                    name={`incidentDetails.catastrophe.beginDate`}
                    ref={register()}
                    value={selectedCatastrophe?.begin_date}
                  />
                  <input
                    type="hidden"
                    name={`incidentDetails.catastrophe.endDate`}
                    ref={register()}
                    value={selectedCatastrophe?.end_date}
                  />
                </Grid>
              </SABox>
            </SABox>
          )}
        </SAColumns>
      )}
    </>
  );
};
