import React from 'react';

export const RoundCheckBox = () => (
  <svg
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.2" cx="62" cy="62" r="62" fill="#65A100" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.9066 62.0024V60.1424C83.9066 58.9024 83.0799 58.0757 81.8399 58.0757C80.5999 58.0757 79.7733 58.9024 79.7733 60.1424V62.0024C79.7733 72.3357 71.5066 80.6024 61.1733 80.6024C50.8399 80.6024 42.5733 72.3357 42.5733 62.0024C42.5733 51.669 50.8399 43.4024 61.1733 43.4024C63.8599 43.4024 66.3399 44.0224 68.8199 45.0557C69.8533 45.469 71.0933 45.0557 71.5066 44.0224C71.9199 42.989 71.5066 41.749 70.4733 41.3357C67.5799 39.889 64.2733 39.269 61.1733 39.269C48.5666 39.269 38.4399 49.3957 38.4399 62.0024C38.4399 74.609 48.5666 84.7357 61.1733 84.7357C73.7799 84.7357 83.9066 74.609 83.9066 62.0024ZM82.4601 41.9554C83.2867 41.1288 84.5267 41.1288 85.3534 41.9554C86.1801 42.7821 86.1801 44.0221 85.3534 44.8488L62.6201 67.5821C62.2067 67.9954 61.5867 68.2021 61.1734 68.2021C60.7601 68.2021 60.1401 67.9954 59.7267 67.5821L53.5267 61.3821C52.7001 60.5554 52.7001 59.3154 53.5267 58.4888C54.3534 57.6621 55.5934 57.6621 56.4201 58.4888L61.1734 63.2421L82.4601 41.9554Z"
      fill="#3B3B3B"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="38"
      y="39"
      width="48"
      height="46"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.9066 62.0024V60.1424C83.9066 58.9024 83.0799 58.0757 81.8399 58.0757C80.5999 58.0757 79.7733 58.9024 79.7733 60.1424V62.0024C79.7733 72.3357 71.5066 80.6024 61.1733 80.6024C50.8399 80.6024 42.5733 72.3357 42.5733 62.0024C42.5733 51.669 50.8399 43.4024 61.1733 43.4024C63.8599 43.4024 66.3399 44.0224 68.8199 45.0557C69.8533 45.469 71.0933 45.0557 71.5066 44.0224C71.9199 42.989 71.5066 41.749 70.4733 41.3357C67.5799 39.889 64.2733 39.269 61.1733 39.269C48.5666 39.269 38.4399 49.3957 38.4399 62.0024C38.4399 74.609 48.5666 84.7357 61.1733 84.7357C73.7799 84.7357 83.9066 74.609 83.9066 62.0024ZM82.4601 41.9554C83.2867 41.1288 84.5267 41.1288 85.3534 41.9554C86.1801 42.7821 86.1801 44.0221 85.3534 44.8488L62.6201 67.5821C62.2067 67.9954 61.5867 68.2021 61.1734 68.2021C60.7601 68.2021 60.1401 67.9954 59.7267 67.5821L53.5267 61.3821C52.7001 60.5554 52.7001 59.3154 53.5267 58.4888C54.3534 57.6621 55.5934 57.6621 56.4201 58.4888L61.1734 63.2421L82.4601 41.9554Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect x="36.3734" y="37.1997" width="49.6" height="49.6" fill="#508D00" />
    </g>
  </svg>
);
