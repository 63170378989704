import {Accordion, AccordionSummary, AccordionDetails, withStyles} from '@material-ui/core';
import React, {ReactElement} from 'react';
import {ExpandMore} from '@material-ui/icons';

export interface SAAccordionProps {
  ariaControls: string;
  id: string;
  summary: Element | ReactElement | string;
  details: Element | ReactElement | string;
  defaultExpanded?: boolean | undefined;
  expanded?: boolean | undefined;
  onChange?: ((event: React.ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const noPaddingOrMargin = {
  padding: '0 !important',
  margin: '0 !important',
};

const UpdatedAccordion = withStyles({
  root: {
    padding: '10px 18px !important',
    minHeight: 'auto !important',
    margin: '0 !important',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto !important',
    },
    background: 'inherit',
  },
  expanded: {
    margin: 0,
    paddingTop: '0 !important',
    background: 'inherit',
    minHeight: 'auto !important',
  },
})(Accordion);

const UpdatedAccordionSummary = withStyles({
  root: {
    ...noPaddingOrMargin,
    background: 'inherit',
    minHeight: 'auto !important',
    '& > div:last-of-type': {
      paddingLeft: '5px',
    },
  },
  content: {
    ...noPaddingOrMargin,
    background: 'inherit',
  },
  expanded: {
    height: 'auto !important',
    display: 'none',
  },
})(AccordionSummary);

export const UpdatedAccordionDetails = withStyles({
  root: {
    ...noPaddingOrMargin,
    background: 'inherit',
  },
})(AccordionDetails);

export const GreenExpandMoreIcon = withStyles({
  root: {
    fill: '#83D000',
    paddingLeft: '5px',
  },
})(ExpandMore);

export const SAAccordion = ({
  ariaControls,
  id,
  summary,
  details,
  defaultExpanded,
  expanded,
  onChange,
}: SAAccordionProps) => (
  <UpdatedAccordion onChange={onChange} expanded={expanded} defaultExpanded={defaultExpanded}>
    <UpdatedAccordionSummary
      expandIcon={<GreenExpandMoreIcon />}
      aria-controls={ariaControls}
      id={id}
    >
      {summary}
    </UpdatedAccordionSummary>
    <UpdatedAccordionDetails>{details}</UpdatedAccordionDetails>
  </UpdatedAccordion>
);
