import {SvgIcon} from '@material-ui/core';
import React from 'react';

export const Checkmark = () => (
  <SvgIcon width="154px" height="154px" viewBox="0 0 154 154" version="1.1" fontSize="inherit">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Screen-Claims-Empty-State" transform="translate(-630.000000, -251.000000)">
        <g id="White-Button-Arrow---Right" transform="translate(632.000000, 253.000000)">
          <circle
            id="Oval"
            stroke="#65A100"
            stroke-width="3"
            fill="#65A100"
            cx="75"
            cy="75"
            r="75"
          ></circle>
          <polygon
            id="Path-5-Copy"
            fill="#FFFFFF"
            fill-rule="nonzero"
            points="112.4302 46 123 56.7015854 63.4441329 117 30 83.1388828 40.5698002 72.4372975 63.441797 95.5926487"
          ></polygon>
        </g>
      </g>
    </g>
  </SvgIcon>
);
