import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import {LogRocket} from './services/logRocket';
import setupLogRocketReact from 'logrocket-react';

Amplify.configure(awsconfig);
LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ENV);
setupLogRocketReact(LogRocket);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// ... above is the instantiation of the client object.

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
