import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {DynamicCheckboxList, Option} from '../../common/DynamicCheckboxList/DynamicCheckboxList';
import {DynamicRadioList} from '../../common/DynamicRadioList/DynamicRadioList';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {YesNoUnk} from '../../common/YesNoUnk/YesNoUnk';
import {CustomerTypes} from '../../../commonTypes';
import {SATextField} from '../../common/TextField/TextField';
import {
  WaterDamageSourceTypes,
  WATER_DAMAGE_SOURCE_OPTIONS,
  IndoorWaterDamageSources,
  WHICH_INDOOR_SOURCE_WAS_WATER_DAMAGED_OPTIONS,
  IndoorWaterDamageHomeAppliances,
  WHICH_INDOOR_HOME_APPLIANCE_WAS_WATER_DAMAGED_OPTIONS,
  IndoorWaterDamageBasementSources,
  WHICH_INDOOR_BASEMENT_SOURCE_WAS_WATER_DAMAGED_OPTIONS,
  OutdoorWaterDamageSources,
  WHICH_OUTDOOR_SOURCE_WAS_WATER_DAMAGED_OPTIONS,
  DamagedAreasForWaterDamage,
  WHICH_DAMAGED_AREAS_WERE_WATER_DAMAGED,
  WHICH_ROOMS_WERE_DAMAGED_ON_THE_MAIN_LEVEL,
  WHICH_ROOMS_WERE_DAMAGED_ON_THE_UPPER_LEVEL,
  WHICH_ROOMS_WERE_DAMAGED_IN_THE_BASEMENT,
  WHICH_DETACHED_STRUCTURES_WERE_DAMAGED,
  serviceInfoTextOptions,
} from './WaterDamageDetailOptions';
import {Contractor} from '../DamageDetails/Contractor';
import {Uninhabitable} from '../Uninhabitable/Uninhabitable';

export const Header = ({children}: {children: React.ReactChild}) => (
  <Box fontSize="16px" pb={3} pt={3}>
    {children}
  </Box>
);

const useStyles = makeStyles(theme => ({
  main: {
    margin: '0px 0px 16px 0px',
  },
  list: {
    margin: '0px 0px 0px 24px',
  },
  bullet: {
    '&::marker': {
      color: theme.palette.secondary.main,
      marginLeft: '24px',
    },
    '&:first-child': {
      marginBottom: '16px',
    },
  },
}));

export const WaterDamageDetails = () => {
  const {register, setValue} = useFormContext();

  const [waterDamageSourceSelection, setWaterDamageSourceSelection] = useState<string>('');
  const [indoorSourceWaterDamagedSelection, setIndoorSourceWaterDamagedSelection] =
    useState<string>('');
  const [outdoorSourceWaterDamagedSelection, setOutdoorSourceWaterDamagedSelection] =
    useState<string>('');
  const [indoorHomeApplianceWaterDamageSelection, setIndoorHomeApplianceWaterDamageSelection] =
    useState<string>('');
  const [indoorBasementSourceWaterDamageSelection, setIndoorBasementSourceWaterDamageSelection] =
    useState<string>('');

  const hasIndoorSourceWaterDamageSelected =
    waterDamageSourceSelection === WaterDamageSourceTypes.IndoorSource;
  const hasOutdoorSourceWaterDamageSelected =
    waterDamageSourceSelection === WaterDamageSourceTypes.OutdoorSource;
  const hasWaterDamageSourceSelected =
    waterDamageSourceSelection === WaterDamageSourceTypes.IndoorSource ||
    waterDamageSourceSelection === WaterDamageSourceTypes.OutdoorSource;
  const hasIndoorSourceOtherUnknownSelected =
    indoorSourceWaterDamagedSelection === IndoorWaterDamageSources.OtherUnknown;
  const hasOutdoorSourceOtherUnknownSelected =
    outdoorSourceWaterDamagedSelection === OutdoorWaterDamageSources.OtherUnknown;
  const hasIndoorSourceHomeApplianceSelected =
    indoorSourceWaterDamagedSelection === IndoorWaterDamageSources.HomeAppliance; //here
  const hasIndoorHomeApplianceOtherUnknownSelected =
    indoorHomeApplianceWaterDamageSelection === IndoorWaterDamageHomeAppliances.OtherUnknown;
  const hasIndoorBasementSourceSelected =
    indoorSourceWaterDamagedSelection === IndoorWaterDamageSources.BasementSource;
  const hasIndoorBasementSourceOtherUnknownSelected =
    indoorBasementSourceWaterDamageSelection === IndoorWaterDamageBasementSources.OtherUnknown;
  const hasIndoorSupplyLineSelected =
    indoorSourceWaterDamagedSelection === IndoorWaterDamageSources.SupplyLine;

  const [wasSupplyLineDamageCausedByPipesFreezing, setWasSupplyLineDamageCausedByPipesFreezing] =
    useState<boolean | null>();
  const [haveRecentRepairsDonePriorToIncident, setHaveRecentRepairsDonePriorToIncident] = useState<
    boolean | null
  >();

  const shouldDisplayRecentRepairDonePriorToIncident =
    hasIndoorSourceWaterDamageSelected ||
    (hasOutdoorSourceWaterDamageSelected &&
      outdoorSourceWaterDamagedSelection === OutdoorWaterDamageSources.HoseSpigotSpout);

  const [whichDamagedAreasWereWaterDamaged, setWhichDamagedAreasWereWaterDamaged] = useState<
    Set<string>
  >(new Set<string>());
  const [whichRoomsDamagedMainLevelWaterDamage, setWhichRoomsDamagedMainLevelWaterDamage] =
    useState<Set<string>>(new Set<string>());
  const [whichRoomsDamagedUpperLevelWaterDamage, setWhichRoomsDamagedUpperLevelWaterDamage] =
    useState<Set<string>>(new Set<string>());
  const [whichRoomsDamagedBasementWaterDamage, setWhichRoomsDamagedBasementWaterDamage] = useState<
    Set<string>
  >(new Set<string>());
  const [
    whichRoomsDamagedDetachedStructureWaterDamage,
    setWhichRoomsDamagedDetachedStructureWaterDamage,
  ] = useState<Set<string>>(new Set<string>());

  const hasDamagedAreasMainLevelSelected = whichDamagedAreasWereWaterDamaged?.has(
    DamagedAreasForWaterDamage.MainLevel
  );
  const hasDamagedAreasUpperLevelSelected = whichDamagedAreasWereWaterDamaged?.has(
    DamagedAreasForWaterDamage.UpperLevel
  );
  const hasDamagedAreasBasementSelected = whichDamagedAreasWereWaterDamaged?.has(
    DamagedAreasForWaterDamage.Basement
  );
  const hasDamagedAreasDetachedStructureSelected = whichDamagedAreasWereWaterDamaged?.has(
    DamagedAreasForWaterDamage.DetachedStructure
  );

  useEffect(() => {
    setValue('incidentDetails.waterDamageSource', '');
  }, []);

  useEffect(() => {
    setIndoorSourceWaterDamagedSelection('');
    setOutdoorSourceWaterDamagedSelection('');
    setIndoorHomeApplianceWaterDamageSelection('');
    setIndoorBasementSourceWaterDamageSelection('');

    setHaveRecentRepairsDonePriorToIncident(false);
  }, [waterDamageSourceSelection]);

  useEffect(() => {
    setIndoorHomeApplianceWaterDamageSelection('');
    setIndoorBasementSourceWaterDamageSelection('');
    setWasSupplyLineDamageCausedByPipesFreezing(false);
  }, [indoorSourceWaterDamagedSelection]);

  useEffect(() => {
    setHaveRecentRepairsDonePriorToIncident(false);
  }, [outdoorSourceWaterDamagedSelection]);

  const handleWaterDamageSourceChange = (value: string) => {
    setWaterDamageSourceSelection(value);
  };

  const handleIndoorSourceWaterDamageChange = (value: string) => {
    setIndoorSourceWaterDamagedSelection(value);
  };

  const handleOutdoorSourceWaterDamageChange = (value: string) => {
    setOutdoorSourceWaterDamagedSelection(value);
  };

  const handleIndoorHomeApplianceWaterDamageChange = (value: string) => {
    setIndoorHomeApplianceWaterDamageSelection(value);
  };

  const handleIndoorBasementSourceWaterDamageChange = (value: string) => {
    setIndoorBasementSourceWaterDamageSelection(value);
  };

  const wasSupplyLineDamageCausedByPipesFreezingHandler = (value: string) => {
    setWasSupplyLineDamageCausedByPipesFreezing(value === 'yes');
  };

  const haveRecentRepairsDonePriorToIncidentHandler = (value: string) => {
    setHaveRecentRepairsDonePriorToIncident(value === 'yes');
  };

  const whichDamagedAreasWereWaterDamagedHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichDamagedAreasWereWaterDamaged?.add(option.label);
    } else {
      whichDamagedAreasWereWaterDamaged?.delete(option.label);
    }

    setWhichDamagedAreasWereWaterDamaged(new Set(whichDamagedAreasWereWaterDamaged));
  };

  const whichRoomsDamagedMainLevelWaterDamageHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsDamagedMainLevelWaterDamage?.add(option.label);
    } else {
      whichRoomsDamagedMainLevelWaterDamage?.delete(option.label);
    }

    setWhichRoomsDamagedMainLevelWaterDamage(new Set(whichRoomsDamagedMainLevelWaterDamage));
  };

  const whichRoomsDamagedUpperLevelWaterDamageHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsDamagedUpperLevelWaterDamage?.add(option.label);
    } else {
      whichRoomsDamagedUpperLevelWaterDamage?.delete(option.label);
    }

    setWhichRoomsDamagedUpperLevelWaterDamage(new Set(whichRoomsDamagedUpperLevelWaterDamage));
  };

  const whichRoomsDamagedBasementWaterDamageHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsDamagedBasementWaterDamage?.add(option.label);
    } else {
      whichRoomsDamagedBasementWaterDamage?.delete(option.label);
    }

    setWhichRoomsDamagedBasementWaterDamage(new Set(whichRoomsDamagedBasementWaterDamage));
  };

  const whichRoomsDamagedDetachedStructureWaterDamageHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsDamagedDetachedStructureWaterDamage?.add(option.label);
    } else {
      whichRoomsDamagedDetachedStructureWaterDamage?.delete(option.label);
    }

    setWhichRoomsDamagedDetachedStructureWaterDamage(
      new Set(whichRoomsDamagedDetachedStructureWaterDamage)
    );
  };

  const getServiceInfoText = () => {
    if (hasIndoorSourceHomeApplianceSelected && haveRecentRepairsDonePriorToIncident) {
      return serviceInfoTextOptions[0];
    } else if (hasIndoorSourceHomeApplianceSelected) {
      return serviceInfoTextOptions[1];
    } else if (haveRecentRepairsDonePriorToIncident) {
      return serviceInfoTextOptions[2];
    }
  };

  const serviceInfoText = getServiceInfoText();
  const classes = useStyles();

  return (
    <FormContainer header="Water Related Damage Details">
      <Box>
        <Header>What was the source of the water damage?</Header>
        <Box pb={3} pt={1}>
          <DynamicRadioList
            name="incidentDetails.waterDamageSource"
            defaultValue=""
            options={WATER_DAMAGE_SOURCE_OPTIONS}
            onChange={handleWaterDamageSourceChange}
          />
        </Box>
        {hasIndoorSourceWaterDamageSelected && (
          <>
            <Header>What was the source of the indoor water damage?</Header>
            <Box>
              <DynamicRadioList
                name="incidentDetails.whichIndoorSourceWaterDamaged"
                defaultValue=""
                options={WHICH_INDOOR_SOURCE_WAS_WATER_DAMAGED_OPTIONS}
                onChange={handleIndoorSourceWaterDamageChange}
              />
            </Box>
            {hasIndoorSourceOtherUnknownSelected && (
              <Grid item xs={12} sm={8}>
                <SATextField
                  name="incidentDetails.indoorSourceWaterDamageOtherUnknownDesc"
                  label="Please provide a brief description"
                  inputRef={register}
                  characterLimit={100}
                  id="indoorSourceOtherUnknownDesc"
                  defaultValue=""
                />
              </Grid>
            )}
            {hasIndoorSourceHomeApplianceSelected && (
              <>
                <Header>Please select the related appliance.</Header>
                <Box>
                  <DynamicRadioList
                    name="incidentDetails.whichIndoorHomeApplianceWaterDamaged"
                    defaultValue=""
                    options={WHICH_INDOOR_HOME_APPLIANCE_WAS_WATER_DAMAGED_OPTIONS}
                    onChange={handleIndoorHomeApplianceWaterDamageChange}
                  />
                </Box>
                {hasIndoorHomeApplianceOtherUnknownSelected && (
                  <>
                    <Grid item xs={12} sm={8}>
                      <SATextField
                        name="incidentDetails.indoorSourceHomeApplainceOtherUnknownDesc"
                        label="Please provide a brief description"
                        inputRef={register}
                        characterLimit={100}
                        id="indoorSourceHomeApplianceOtherUnknownDesc"
                        defaultValue=""
                      />
                    </Grid>
                    <Box pb={3}></Box>
                  </>
                )}
              </>
            )}
            {hasIndoorBasementSourceSelected && (
              <>
                <Header>Please select the basement source.</Header>
                <Box>
                  <DynamicRadioList
                    name="incidentDetails.whichIndoorBasementSourceWaterDamaged"
                    defaultValue=""
                    options={WHICH_INDOOR_BASEMENT_SOURCE_WAS_WATER_DAMAGED_OPTIONS}
                    onChange={handleIndoorBasementSourceWaterDamageChange}
                  />
                </Box>
                {hasIndoorBasementSourceOtherUnknownSelected && (
                  <>
                    <Grid item xs={12} sm={8}>
                      <SATextField
                        name="incidentDetails.indoorBasementSourceOtherUnknownDesc"
                        label="Please provide a brief description"
                        inputRef={register}
                        characterLimit={100}
                        id="indoorBasementSourceOtherUnknownDesc"
                        defaultValue=""
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
            {hasIndoorSupplyLineSelected && (
              <>
                <Header>Was the incident a result of pipes freezing?</Header>
                <Box>
                  <UserPersonaSwitch
                    ifPersonas={[CustomerTypes.SaAgent]}
                    then={
                      <YesNoUnk
                        name="incidentDetails.wasSupplyLineDamageCausedByPipesFreezing"
                        noTestId="NoSupplyLineDamageCausedByPipesFreezing"
                        yesTestId="YesSupplyLineDamageCausedByPipesFreezing"
                        dontKnowTestId="UnkSupplyLineDamageCausedByPipesFreezing"
                        onChange={wasSupplyLineDamageCausedByPipesFreezingHandler}
                      />
                    }
                    otherwise={
                      <YesNoToggle
                        name="incidentDetails.wasSupplyLineDamageCausedByPipesFreezing"
                        yesButtonTestId="YesSupplyLineDamageCausedByPipesFreezing"
                        testId="NoSupplyLineDamageCausedByPipesFreezing"
                        inputRef={register}
                        handleChange={wasSupplyLineDamageCausedByPipesFreezingHandler}
                      />
                    }
                  />
                </Box>
                {wasSupplyLineDamageCausedByPipesFreezing && (
                  <>
                    <Header>Was the house occupied at the time of the incident?</Header>
                    <Box>
                      <UserPersonaSwitch
                        ifPersonas={[CustomerTypes.SaAgent]}
                        then={
                          <YesNoUnk
                            name="incidentDetails.wasHouseOccupiedSupplyLineIncident"
                            noTestId="NoHouseOccupiedSupplyLineIncident"
                            yesTestId="YesHouseOccupiedSupplyLineIncident"
                            dontKnowTestId="UnkHouseOccupiedSupplyLineIncident"
                          />
                        }
                        otherwise={
                          <YesNoToggle
                            name="incidentDetails.wasHouseOccupiedSupplyLineIncident"
                            yesButtonTestId="YesHouseOccupiedSupplyLineIncident"
                            testId="NoHouseOccupiedSupplyLineIncident"
                            inputRef={register}
                          />
                        }
                      />
                    </Box>
                    <Header>Was there power outage prior to the damage?</Header>
                    <Box>
                      <UserPersonaSwitch
                        ifPersonas={[CustomerTypes.SaAgent]}
                        then={
                          <YesNoUnk
                            name="incidentDetails.wasTherePowerOutageSupplyLineIncident"
                            noTestId="NoTherePowerOutageSupplyLineIncident"
                            yesTestId="YesTherePowerOutageSupplyLineIncident"
                            dontKnowTestId="UnkTherePowerOutageSupplyLineIncident"
                          />
                        }
                        otherwise={
                          <YesNoToggle
                            name="incidentDetails.wasTherePowerOutageSupplyLineIncident"
                            yesButtonTestId="YesTherePowerOutageSupplyLineIncident"
                            testId="NoTherePowerOutageSupplyLineIncident"
                            inputRef={register}
                          />
                        }
                      />
                    </Box>
                    <Header>Was the heat maintained in the home?</Header>
                    <Box>
                      <UserPersonaSwitch
                        ifPersonas={[CustomerTypes.SaAgent]}
                        then={
                          <YesNoUnk
                            name="incidentDetails.wasHeatMaintainedHomeSupplyLineIncident"
                            noTestId="NoHeatMaintainedHomeSupplyLineIncident"
                            yesTestId="YesHeatMaintainedHomeSupplyLineIncident"
                            dontKnowTestId="UnkHeatMaintainedHomeSupplyLineIncident"
                          />
                        }
                        otherwise={
                          <YesNoToggle
                            name="incidentDetails.wasHeatMaintainedHomeSupplyLineIncident"
                            yesButtonTestId="YesHeatMaintainedHomeSupplyLineIncident"
                            testId="NoHeatMaintainedHomeSupplyLineIncident"
                            inputRef={register}
                          />
                        }
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </>
        )}
        {hasOutdoorSourceWaterDamageSelected && (
          <>
            <Header>What was the source of the outdoor water damage?</Header>
            <Box>
              <DynamicRadioList
                name="incidentDetails.whichOutdoorSourceWaterDamaged"
                defaultValue=""
                options={WHICH_OUTDOOR_SOURCE_WAS_WATER_DAMAGED_OPTIONS}
                onChange={handleOutdoorSourceWaterDamageChange}
              />
            </Box>
            {hasOutdoorSourceOtherUnknownSelected && (
              <Grid item xs={12} sm={8}>
                <SATextField
                  name="incidentDetails.outdoorSourceWaterDamageOtherUnknownDesc"
                  label="Please provide a brief description"
                  inputRef={register}
                  characterLimit={100}
                  id="outdoorSourceOtherUnknownDesc"
                  defaultValue=""
                />
              </Grid>
            )}
          </>
        )}
        {shouldDisplayRecentRepairDonePriorToIncident && (
          <>
            <Header>Have any recent repairs/service been done prior to this incident?</Header>
            <Box>
              <UserPersonaSwitch
                ifPersonas={[CustomerTypes.SaAgent]}
                then={
                  <YesNoUnk
                    name="incidentDetails.haveRecentRepairsDonePriorToIncident"
                    noTestId="NoRecentRepairsDonePriorToIncident"
                    yesTestId="YesRecentRepairsDonePriorToIncident"
                    dontKnowTestId="UnkRecentRepairsDonePriorToIncident"
                    onChange={haveRecentRepairsDonePriorToIncidentHandler}
                    key={waterDamageSourceSelection}
                  />
                }
                otherwise={
                  <YesNoToggle
                    name="incidentDetails.haveRecentRepairsDonePriorToIncident"
                    yesButtonTestId="YesRecentRepairsDonePriorToIncident"
                    testId="NoRecentRepairsDonePriorToIncident"
                    inputRef={register}
                    handleChange={haveRecentRepairsDonePriorToIncidentHandler}
                    key={waterDamageSourceSelection}
                  />
                }
              />
            </Box>
          </>
        )}
        {serviceInfoText && (
          <Grid item xs={12} sm={8}>
            <Box bgcolor={'#E2F5F9'} p={3} mt={3} mb={1}>
              <Typography variant="body2" className={classes.main}>
                {serviceInfoText.mainText}
              </Typography>
              <ul className={classes.list}>
                <li className={classes.bullet}>{serviceInfoText.firstBullet}</li>
                <li className={classes.bullet}>{serviceInfoText.secondBullet}</li>
              </ul>
            </Box>
          </Grid>
        )}
        {hasWaterDamageSourceSelected && (
          <>
            <Header>What was damaged? (Please select all that apply)</Header>
            <Box>
              <DynamicCheckboxList
                options={WHICH_DAMAGED_AREAS_WERE_WATER_DAMAGED}
                name="incidentDetails.whichDamagedAreasWereWaterDamaged"
                onOptionsChange={whichDamagedAreasWereWaterDamagedHandler}
              />
            </Box>
            {hasDamagedAreasMainLevelSelected && (
              <>
                <Header>Which rooms were damaged on the main level?</Header>
                <Box>
                  <DynamicCheckboxList
                    options={WHICH_ROOMS_WERE_DAMAGED_ON_THE_MAIN_LEVEL}
                    name="incidentDetails.whichRoomsDamagedMainLevelWaterDamage"
                    onOptionsChange={whichRoomsDamagedMainLevelWaterDamageHandler}
                  />
                </Box>
              </>
            )}
            {hasDamagedAreasUpperLevelSelected && (
              <>
                <Header>Which rooms were damaged on the upper level?</Header>
                <Box>
                  <DynamicCheckboxList
                    options={WHICH_ROOMS_WERE_DAMAGED_ON_THE_UPPER_LEVEL}
                    name="incidentDetails.whichRoomsDamagedUpperLevelWaterDamage"
                    onOptionsChange={whichRoomsDamagedUpperLevelWaterDamageHandler}
                  />
                </Box>
              </>
            )}
            {hasDamagedAreasBasementSelected && (
              <>
                <Header>Which rooms were damaged in the basement?</Header>
                <Box>
                  <DynamicCheckboxList
                    options={WHICH_ROOMS_WERE_DAMAGED_IN_THE_BASEMENT}
                    name="incidentDetails.whichRoomsDamagedBasementWaterDamage"
                    onOptionsChange={whichRoomsDamagedBasementWaterDamageHandler}
                  />
                </Box>
              </>
            )}
            {hasDamagedAreasDetachedStructureSelected && (
              <>
                <Header>Which areas were damaged?</Header>
                <Box>
                  <DynamicCheckboxList
                    options={WHICH_DETACHED_STRUCTURES_WERE_DAMAGED}
                    name="incidentDetails.whichRoomsDamagedDetachedStructureWaterDamage"
                    onOptionsChange={whichRoomsDamagedDetachedStructureWaterDamageHandler}
                  />
                </Box>
              </>
            )}
          </>
        )}
        {hasIndoorSourceWaterDamageSelected && <Uninhabitable />}
        <Contractor />
      </Box>
    </FormContainer>
  );
};
