import {Box, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import {SABox, SAText} from '@saux/design-system-react';
import Cookies from 'js-cookie';
import {capitalize} from 'lodash';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useUserAtomState} from '../../../atoms';
import {
  ContactRoles,
  CustomerTypes,
  PERSONAL_HOMEOWNERS_TYPES,
  THIRD_PARTY_PERSONAS,
} from '../../../commonTypes';
import {Snowplow} from '../../../pages/utils/snowplow';
import {findRepairShopLink, manageClaimLink, stormCenterLink} from '../../../routes/links';
import {LogRocket} from '../../../services/logRocket';
import {
  hasSomeContactRoles,
  hasContactRoles,
  showAdjuster,
  formatPhone,
} from '../../../utils/utils';
import {BaseSwitch} from '../../common/BaseSwitch/BaseSwitch';
import {GreenRoundCheckboxAdjustable} from '../../common/Icon/assets/GreenRoundCheckboxAdjustable';
import {HomeIconWithOrangeHalo} from '../../common/Icon/assets/HomeIconWithOrangeHalo';
import {
  IncidentTypes,
  INCIDENT_NATURE_OF_LOSS_MAPPING,
  NatureOfLoss,
} from '../../common/Incidents/Incidents';
import {useFeatureFlags} from '../../common/Providers/Providers';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {NewAdjuster} from '../../common/Summary/components/YourAdjuster/NewAdjuster';
import {Contact, StateProps, SubmissionTypes, Vehicle} from '../../common/Summary/types';
import {WaterDamageSourceTypes} from '../../homeowners/WatreDamageDetails/WaterDamageDetailOptions';

export interface StpSummaryScreen {
  successState: StateProps;
}

const useStyles = makeStyles(theme => ({
  alignedBox: {
    alignItems: 'center',
    display: 'flex',
    marginTop: '30px',
    marginLeft: '18px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
  headerBox: {
    marginLeft: '15px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
  listBox: {
    marginLeft: '18px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '12px',
    },
  },
  emergencyListBox: {
    marginLeft: '100px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '10px',
    },
  },
}));

const GreySAText = styled(SAText)`
  font-style: italic;
  color: #666666;
`;

const StyledBox = styled(SABox)`
  height: 60px;
  width: 60px;
  margin-right: 22px;
`;

const AlignCenterBox = styled(SABox)`
  align-items: center;
  display: flex;
  margin-top: 20px;
`;

// Texts
export const thanksNoteText =
  'Thank you for submitting your claim, the State Auto CARE team will work hard to keep you informed and supported throughout your claims process. ';

export const aboutAdjusterText =
  'The CARE professional listed below is the adjuster assigned to your claim and the best person to contact should you have any questions. ';

export const aboutStateAutoText =
  'Our State Auto CARE team is dedicated to ensuring your claim is processed as quickly and efficiently as possible, and we will keep you informed every step of the way.';

export const waitForEstimateText =
  'Please wait to receive your approved estimate before authorizing any repairs to your vehicle.';

export const thirdPartyNote =
  'Please note, the inspection of your vehicle is not a commitment of coverage or liability, nor is it an authorization of repair. A State Auto representative will be in contact with you to discuss the next steps. We recommend waiting to receive your approved estimate before authorizing any repairs to your vehicle.';

export const staffAppraiserNote =
  'A State Auto Appraiser will be contacting you within the next 1-2 business days regarding your damages.';

export const quickEstimateNote =
  'You will receive a message through your mobile number or email address, with a link to complete a guided inspection. Follow the instructions in the link to submit photos of your vehicle and fast track your claim.';

export const stpEligibleNote =
  'Based on the nature of your claim, it’s possible you may not receive any calls from us. ';

export const aboutCovText =
  'If coverages are not displaying or you believe the coverages displayed above are different than your selected coverages please review your policy documents.';

export const necessaryStepsHeaderText =
  'According to your policy there is a duty to protect your property from further damage, please take reasonable and necessary steps to prevent subsequent damage:';

export const propertyClaimNextStepText =
  'Please note, at this time State Auto is beginning to investigate your claim to determine coverage, and a representative will be in contact with you soon to discuss next steps.';

export const propertyNextStepTexts = [
  'Photograph and document the damages prior to clean up with photos and videos',
  'Retain all invoices and receipts for consideration',
  'Do not complete any permanent repairs until discussing with your Adjuster',
  'Create an inventory of damaged personal belongings if any, include the cost and age of the item',
  'Do not dispose of any ruptured or damaged component(s) that may have caused the loss to occur without discussing with your Adjuster',
];

export const uninhabitableText =
  'If your home is uninhabitable due to the resulting damage and if you need immediate assistance, please call our housing vendor at';

export const uninhabitableVendorNumber = '8669187356';

export const StpSummaryScreen = ({successState}: StpSummaryScreen) => {
  const userPersona: CustomerTypes = successState.customerType as CustomerTypes;
  const [userAtomState, _] = useUserAtomState();
  const {featureFlags} = useFeatureFlags();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  // Persona Checks
  const isReporterIsInsured = successState.contacts?.find(
    (contact: Contact) =>
      hasContactRoles(contact as Contact, [ContactRoles.Insured, ContactRoles.Reporter]) &&
      !hasSomeContactRoles(contact as Contact, [ContactRoles.Agent, ContactRoles.Lienholder])
  );
  const isAssociate = userPersona === CustomerTypes.Associate;
  const isAssociateWithInsuredReporter = isReporterIsInsured && isAssociate;
  const isAgent = userPersona === CustomerTypes.SaAgent;
  const isThirdParty = THIRD_PARTY_PERSONAS.includes(userPersona);
  const isNotThirdParty = !isThirdParty;

  // Get Vehicle Details
  const vehicle = successState?.lobs?.personalAuto?.vehicleIncidents?.[0]?.vehicle;
  const vehicleYear = vehicle?.year;
  const vehicleMake = vehicle?.make?.trim()?.slice(0, 5)?.toLowerCase();
  const vehicleModel = vehicle?.model?.trim()?.slice(0, 5)?.toLowerCase();
  const vehicleVIN = vehicle?.vIN?.toLowerCase();
  const policyVehicles =
    userAtomState.draftClaimResponse?.result?.policy?.lobs?.personalAuto?.vehicles || [];
  const incidentVehicle = policyVehicles.find((pv: Vehicle) => {
    return (
      (`${pv.year}` === `${vehicleYear}` &&
        pv.make?.trim()?.slice(0, 5)?.toLowerCase() === vehicleMake &&
        pv.model?.trim()?.slice(0, 5)?.toLowerCase() === vehicleModel) ||
      pv.publicID === vehicle?.publicID ||
      pv?.vIN?.toLowerCase() === vehicleVIN
    );
  });
  const simplifiedCoverages = incidentVehicle?.simplifiedCoverages;
  const hitStationaryObject = successState?.selectedIncidents?.includes('Hit stationary object');
  const hitAnimal = successState?.selectedIncidents?.includes('Damage Due To Animal');
  const qeOrStaff =
    !!successState?.quickEstimateVehicles?.length || !!successState?.vehiclesToShowStaff?.length;
  const quickEstimateRequested = !!successState?.quickEstimateVehicles?.find(
    qeVehicle => qeVehicle.quickEstimateRequested === 'yes'
  );
  const quickEstimateEligible = !!successState?.quickEstimateVehicles?.length;
  const quickEstimate = quickEstimateEligible && quickEstimateRequested;
  const appraisalType = quickEstimate ? 'Quick Estimate' : 'Staff Appraisal';

  const associateCookieData = Cookies.get('associateCredentials');
  const claimNumber = successState.claimNumber;
  const adjusterDetails = successState.adjusterDetails;

  let name: string | undefined;
  let email: string | undefined;

  if (isAssociate && associateCookieData) {
    const associateCredentials = JSON.parse(associateCookieData);
    name = associateCredentials.name;
    email = associateCredentials.email;
  } else {
    name = successState.mainContact?.displayName;
    email = successState.mainContact?.emailAddress1;
  }

  const stpTracking = {
    userPersona,
    logrocketurl: userAtomState.logrocketurl,
    claimNumber: claimNumber,
    policyNumber: userAtomState.policyNumber || '',
    appraisalType,
    name,
    email,
  };

  useEffect(() => {
    LogRocket.track('STPSummaryScreen', {
      appraisalType: appraisalType,
    });

    Snowplow.track.stpSummaryScreen({
      ...stpTracking,
    });
  }, []);

  const onClick = (e: React.MouseEvent<HTMLElement>, link: string) => {
    e.preventDefault();
    window.open(link);
    Snowplow.track.stpSummaryUrl({
      ...stpTracking,
      stpSummaryUrl: link,
    });
  };

  const linkOpen = (e: React.MouseEvent<HTMLElement>, link: string) => {
    e.preventDefault();
    window.open(link);
  };

  // Conditions To render different Parts of Next Steps Summary Screen
  const property = hitStationaryObject ? 'collision' : hitAnimal ? 'comprehensive' : '';
  const rentalAmountPerDay = simplifiedCoverages?.rental?.[0]?.incidentLimit?.amount || 0;
  const endorsementAmount = simplifiedCoverages?.endorsementRental?.[0]?.incidentLimit?.amount || 0;
  const policyState = userAtomState.draftClaimResponse?.result?.policy?.policyState || '';
  const noDailyRentalStates = ['VA'];
  const noEndorsementStates = ['NC'];
  const isDailyRentalEligible = !noDailyRentalStates.includes(policyState);
  const isEndorsementEligible = !noEndorsementStates.includes(policyState);
  const totalRentalPerDay = rentalAmountPerDay + (isEndorsementEligible ? endorsementAmount : 0);
  const totalRentalPerClaim = totalRentalPerDay * 30;
  const isAutoClaim = successState.submissionType === SubmissionTypes.Collision;
  const isHomeOwnersClaim = successState.submissionType === SubmissionTypes.Property;
  const isStpEligible =
    successState?.stpResult?.AutoSTPClaimProcessEligibilityIndicator === 'Eligible';
  const coverageType = property && simplifiedCoverages?.[property];
  const amount = coverageType && coverageType?.[0]?.deductible?.amount;

  const showDeductiblesAndCoverages =
    qeOrStaff &&
    (isAssociateWithInsuredReporter || (!isAssociate && isNotThirdParty)) &&
    isAutoClaim;
  const showHowInspected = qeOrStaff && !isAgent;
  const showStpLanguage: boolean =
    isStpEligible && featureFlags?.FF_DCX_2767 && !successState.isStpUpdateCallFailed;
  const displayAdjuster = showAdjuster(successState);

  const currentDate = new Date().toISOString().slice(0, 10);
  const formattedDate =
    currentDate.slice(5, 7) + currentDate.slice(8, 10) + currentDate.slice(2, 4);
  let showUninhabitableSection: boolean | undefined = false;

  if (isHomeOwnersClaim) {
    const homeAndOtherStructure =
      INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure];
    const incidentDetails = successState.formData?.incidentDetails;

    const isWeatherRelated =
      incidentDetails?.natureOfLoss === homeAndOtherStructure[NatureOfLoss.WeatherRelated];
    const isWindOrWinter =
      incidentDetails?.lossCause === homeAndOtherStructure[NatureOfLoss.Wind] ||
      incidentDetails?.lossCause === homeAndOtherStructure[NatureOfLoss.WinterWeather];
    const isFireDamage = incidentDetails?.natureOfLoss === homeAndOtherStructure[NatureOfLoss.Fire];
    const isWaterDamageWithIndoorSource =
      incidentDetails?.natureOfLoss === homeAndOtherStructure[NatureOfLoss.Water] &&
      incidentDetails?.waterDamageSource === WaterDamageSourceTypes.IndoorSource;
    showUninhabitableSection =
      incidentDetails?.isPropertyHabitable === 'no' &&
      successState.policy?.policyType === PERSONAL_HOMEOWNERS_TYPES[1] &&
      successState.showUninhabitableSummary &&
      ((isWeatherRelated && isWindOrWinter) || isFireDamage || isWaterDamageWithIndoorSource);
  }

  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.NextSteps}
      displayName={WAYPOINTS[WaypointNames.NextSteps].displayName}
    >
      <SABox>
        <SABox mb="large">
          <Box component={'h1'} color={'#0173C6'}>
            {'Next Steps - What to Expect'}
          </Box>
        </SABox>

        <SABox my="medium">
          <SAText type="standard" text={thanksNoteText} />

          {showStpLanguage ? (
            <SAText type="standard" text={stpEligibleNote} weight="bold" />
          ) : (
            <SAText type="standard" text={' '} />
          )}

          {displayAdjuster && <SAText type="standard" text={aboutAdjusterText} />}

          <SABox my="medium">
            <SAText type="standard" text="You can " />
            <SAText
              type="standard"
              text="manage your claim"
              colorVariant="link"
              onClick={e => onClick(e, manageClaimLink)}
            />
            <SAText
              type="standard"
              text={
                isAutoClaim
                  ? " and access policy details such as your deductible, rental limits and FAQ's from any device online."
                  : " and access real time claim details and FAQ's from any device online."
              }
            />
          </SABox>
        </SABox>

        {showDeductiblesAndCoverages && (
          <>
            {amount && (
              <>
                <SABox mt="large" mb="medium">
                  <SAText
                    type="paragraph"
                    text="What are the deductibles for your policy?"
                    colorVariant="primary-background"
                    weight="bold"
                  />
                </SABox>
                <SABox mb="medium">
                  <SAText type="standard" text={`${capitalize(property)}: `} weight="bold" />
                  <SAText type="standard" text={`$${amount?.toString()}`} />
                </SABox>
              </>
            )}

            {totalRentalPerDay !== 0 && (
              <>
                <SABox mb="medium">
                  <SAText
                    type="paragraph"
                    text="What is my rental coverage?"
                    colorVariant="primary-background"
                    weight="bold"
                  />
                </SABox>
                <SABox mb="small">
                  {isDailyRentalEligible && (
                    <>
                      <SAText type="standard" text="Per Day: " weight="bold" />
                      <SAText type="standard" text={`$${totalRentalPerDay?.toString()}`} />
                    </>
                  )}
                  <SAText type="paragraph" text="" />
                  <SAText type="standard" text="Per Claim: " weight="bold" />
                  <SAText type="standard" text={`$${totalRentalPerClaim?.toString()}`} />
                </SABox>
              </>
            )}

            <SABox mb="large">
              <GreySAText type="small" text={aboutCovText} colorVariant="info-foreground" />
            </SABox>
          </>
        )}

        {showHowInspected && (
          <>
            <SABox my="medium">
              <SAText
                type="paragraph"
                text="How will my vehicle be inspected?"
                colorVariant="primary-background"
                weight="bold"
              />
            </SABox>

            <SABox mb="medium">
              <BaseSwitch
                matches={quickEstimate}
                then={<SAText type="paragraph" text={quickEstimateNote} />}
                otherwise={<SAText type="paragraph" text={staffAppraiserNote} />}
              />

              {isThirdParty ? (
                <SAText type="paragraph" text={thirdPartyNote} weight="bold" />
              ) : (
                <SAText type="paragraph" text={waitForEstimateText} weight="bold" />
              )}
            </SABox>
          </>
        )}

        {isAutoClaim && (
          <SABox mb="medium">
            <SABox mt="large" mb="medium">
              <SAText
                type="paragraph"
                text="Where can you get your vehicle repaired?"
                colorVariant="primary-background"
                weight="bold"
              />
            </SABox>
            <SABox mt="medium" mb="large">
              <SAText
                type="standard"
                text="State Auto will work with the repair facility of your choice. If you would like assistance finding a trusted shop to handle your repairs, you can "
              />
              <SAText
                type="standard"
                text="find a reputable repair facility "
                colorVariant="link"
                onClick={e => onClick(e, findRepairShopLink)}
              />
              <SAText type="standard" text="in your area." />
            </SABox>
            <AlignCenterBox>
              <StyledBox>
                <GreenRoundCheckboxAdjustable />
              </StyledBox>
              <SAText type="standard" text={aboutStateAutoText} />
            </AlignCenterBox>
          </SABox>
        )}

        {isHomeOwnersClaim && (
          <>
            <SABox mt="large" className={classes.headerBox}>
              <SAText type="paragraph" text={necessaryStepsHeaderText} weight="bold" />
            </SABox>

            <SABox className={classes.listBox}>
              <ul className={classes.listBox}>
                {propertyNextStepTexts.map(textData => (
                  <li>
                    <SAText type="paragraph" text={textData} />
                  </li>
                ))}
                <li>
                  <SAText type="standard" text="For additional actions you can take, visit " />
                  <SAText
                    type="standard"
                    colorVariant="link"
                    onClick={e => linkOpen(e, stormCenterLink)}
                    text={stormCenterLink}
                  />
                </li>
              </ul>
            </SABox>

            {showUninhabitableSection && (
              <>
                <SABox className={classes.alignedBox}>
                  {!isMobile && (
                    <StyledBox>
                      <HomeIconWithOrangeHalo />
                    </StyledBox>
                  )}
                  <SABox>
                    <SAText type="standard" text={`${uninhabitableText} `} />
                    <SAText
                      type="standard"
                      colorVariant="link"
                      onClick={e => linkOpen(e, `tel:${uninhabitableVendorNumber}`)}
                      text={formatPhone(uninhabitableVendorNumber)}
                    />
                    <SAText type="standard" text="." />
                  </SABox>
                </SABox>

                <SABox mt={isMobile ? 'medium' : undefined} className={classes.emergencyListBox}>
                  <SAText type="paragraph" text="When calling you will need to do the following:" />
                </SABox>

                <SABox className={classes.emergencyListBox}>
                  <ul style={{marginLeft: '25px'}}>
                    <li>
                      <SAText type="paragraph" text="Select Option 1" weight="bold" />
                    </li>
                    <li>
                      <SAText
                        type="paragraph"
                        text={`Use this reference code: ${claimNumber}.${formattedDate}`}
                        weight="bold"
                      />
                    </li>
                  </ul>
                </SABox>

                <SABox mt="medium" mb="medium">
                  <SAText
                    type="small"
                    text={propertyClaimNextStepText}
                    colorVariant="info-foreground"
                  />
                </SABox>
              </>
            )}
          </>
        )}

        {displayAdjuster && (
          <SABox my="large" mt="large">
            <NewAdjuster claimNumber={claimNumber} adjusterInfo={adjusterDetails} />
          </SABox>
        )}
      </SABox>
    </RegisterBreadcrumb>
  );
};
