import {Box} from '@material-ui/core';
import React from 'react';
import {DynamicCheckboxList} from '../../common/DynamicCheckboxList/DynamicCheckboxList';
import {useFeatureFlags} from '../../common/Providers/Providers';
import {EMERGENCY_SERVICES_OPTIONS} from '../DamageDetails/DamageDetailOptions';
import {Header} from '../DamageDetails/DamageDetails';

export const EmergencyServices = () => {
  const {featureFlags} = useFeatureFlags();

  return featureFlags?.FF_DCX_2713 ? (
    <>
      <Box>
        <Header>
          Are any of the following emergency services needed as a result of the damages?
        </Header>
      </Box>
      <Box>
        <DynamicCheckboxList
          options={EMERGENCY_SERVICES_OPTIONS}
          name="incidentDetails.emergencyServices"
        />
      </Box>
    </>
  ) : (
    <></>
  );
};
