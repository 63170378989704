import React from 'react';
import {Box, Typography, Grid} from '@material-ui/core';
import {SummaryField} from '../../../common/Summary/components/SummaryField/SummaryField';

interface SelectedIncidentsSummaryProps {
  selectedIncidents: string;
  header: string;
}

export const SelectedIncidents = ({selectedIncidents, header}: SelectedIncidentsSummaryProps) => {
  return (
    <Box pt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SummaryField label={header}>
            <Typography>{selectedIncidents || ''}</Typography>
          </SummaryField>
        </Grid>
      </Grid>
    </Box>
  );
};
