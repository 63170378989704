import React from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {DynamicRadioList, RadioOption} from '../../common/DynamicRadioList/DynamicRadioList';
import {PhoneHyperlink} from '../../common/PhoneHyperlink/PhoneHyperlink';

export enum GlassRoadsideTypes {
  RoadsideAssistance = 'roadsideAssitance',
  GlassOnly = 'glassOnly',
  Neither = 'neither',
}

export const GLASS_ROADSIDE_TYPES: GlassRoadsideTypesObj = {
  [GlassRoadsideTypes.RoadsideAssistance]: 'Roadside Assistance',
  [GlassRoadsideTypes.GlassOnly]: 'Glass Only',
  [GlassRoadsideTypes.Neither]: 'Neither',
};

interface GlassRoadsideTypesObj {
  [key: string]: string;
}

interface GlassRoadsideProps {
  glassRoadsideState: (glassRoadsideValue: string) => void;
  defaultValue: string;
}

export const glassOnlyMessage = () => {
  return (
    <span data-testid="glassOnlyError">
      For auto glass only claims, call our State Auto Glass Service at{' '}
      <a href="tel:+18885044527">1-888-504-4527</a> or{' '}
      <a
        href="https://submit.glassclaim.com/SelfService/Default.aspx?ClientTag=55E64CE5-9F01-4523-BAE1-98DDD1DBCF6F"
        target="_blank"
        rel="noopener noreferrer"
      >
        submit your auto glass claim online
      </a>
      .
    </span>
  );
};

export const roadsideAssistanceMessage = () => {
  return (
    <span data-testid="roadsideAssistanceError">
      For Roadside Assistance claims, call our State Auto Claims department at <br />{' '}
      <PhoneHyperlink />.
    </span>
  );
};

const useStyles = makeStyles(theme => ({
  fieldset: {
    border: '0 none',
  },
}));

export const GlassRoadside = (glassRoadsideProps: GlassRoadsideProps) => {
  const handleChange = (value: string) => {
    glassRoadsideProps.glassRoadsideState(value);
  };

  const OPTIONS: RadioOption[] = [
    {
      label: GLASS_ROADSIDE_TYPES[GlassRoadsideTypes.RoadsideAssistance],
      value: GlassRoadsideTypes.RoadsideAssistance,
      testId: 'roadsideAssistance',
    },
    {
      label: GLASS_ROADSIDE_TYPES[GlassRoadsideTypes.GlassOnly],
      value: GlassRoadsideTypes.GlassOnly,
      testId: 'glassOnly',
    },
    {
      label: GLASS_ROADSIDE_TYPES[GlassRoadsideTypes.Neither],
      value: GlassRoadsideTypes.Neither,
      testId: 'glassRoadsideNeither',
    },
  ];

  const classes = useStyles();

  return (
    <Box>
      <fieldset className={classes.fieldset}>
        <legend>
          <Box pb={1}>
            <Typography variant="body1" data-testid="glassroadside">
              Are you filing a Roadside Assistance or Glass Only claim?
            </Typography>
          </Box>
        </legend>
        <Box pb={3} pt={1}>
          <DynamicRadioList
            name="glassRoadsideRadioGroup"
            defaultValue={glassRoadsideProps.defaultValue}
            options={OPTIONS}
            onChange={handleChange}
          />
        </Box>
      </fieldset>
    </Box>
  );
};
