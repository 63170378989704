import React from 'react';
import {Icon} from '../Icon/Icon';
import {makeStyles, Box} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  content: {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
  },
}));

interface IconHeaderProps {
  icon?: string;
  children?: JSX.Element;
}

export const IconHeader = ({icon, children}: IconHeaderProps) => {
  const iconName: string = icon || 'questionBubble';
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box p={1} pl={0}>
        <Icon name={iconName} />
      </Box>

      <Box className={classes.content} p={1} pb={2}>
        {children}
      </Box>
    </Box>
  );
};
