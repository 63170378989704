import React, {useEffect, useState} from 'react';
import './App.css';
import {SAHeader} from './components/common/Header/Header';
import {PolicySearchPage} from './pages/PolicySearchPage';
import {AgentPolicySearchPage} from './pages/AgentPolicySearchPage';
import {
  Router,
  RouteComponentProps,
  globalHistory,
  HistoryListenerParameter,
  navigate,
} from '@reach/router';
import {ThemeProvider, Container, makeStyles} from '@material-ui/core';
import {defaultTheme} from './themes/default.theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import {AutoFormPage} from './pages/AutoForm.page';
import {QuickEstimatePage} from './pages/QuickEstimatePage';
import {ClaimsCenterMaintenancePage} from './pages/MaintenancePage';
import {clearSessionData} from './utils/utils';
import {GettingStartedPage} from './pages/GettingStartedPage';
import {HomeownersFormPage} from './pages/HomeownersForm.page';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';
import {AssociatePolicySearchPage} from './pages/AssociatePolicySearchPage';
import {AuthInsuredPage} from './pages/AuthInsuredPage';
import {AuthenticatedRoute} from './pages/AuthenticatedRoute';
import {Success} from './pages/Success.page';
import {RecoilRoot} from 'recoil';
import {
  AGENT_POLICY_SEARCH_ROUTE,
  ASSOCIATE_POLICY_SEARCH_ROUTE,
  COPART_ROUTE,
  FNOL_AUTO_ROUTE,
  HOMEOWNERS_ROUTE,
  MAINTENANCE_ROUTE,
  POLICY_SEARCH_ROUTE,
  QUICK_ESTIMATE_ROUTE,
  ROOT_ROUTE,
  STAFF_APPRAISER_ROUTE,
  SUMMARY_PAGE_ROUTE,
  AUTH_INSURED_ROUTE,
  COMMERCIAL_AUTO_VEHICLE_SELECTION_ROUTE,
} from './routes/paths';
import {MakeHealthCheck} from './components/common/MakeHealthCheck/MakeHealthCheck';
import {ThemeProvider as SauxThemeProvider} from 'styled-components';
import {SAUX360Theme} from '@saux/design-system-react';
import {CopartPage} from './pages/Copart.page';
import {WithFeatureFlagsContext} from './components/common/Providers/Providers';
import {FeatureFlagObject, retrieveFeatureFlags} from './services';
import {StaffAppraiserPage} from './pages/StaffAppraiser.page';
import {RefreshPageBehavior} from './components/common/RefreshPageBehavior/RefreshPageBehavior';
import {CommercialAutoSelectionPage} from './pages/utils/CommercialAutoSelectionPage';
import {CPRAFooter} from './components/common/Footer/Footer';

TagManager.initialize({gtmId: 'GTM-M5ZLBR9'});
CPRAFooter.initialize();

const ScrollToTop = ({children}: any) => {
  useEffect(() => window.scrollTo(0, 0), []);
  return children;
};

const WithScrollToTop = ({children}: any) => <ScrollToTop>{children}</ScrollToTop>;

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

const RouteContainer = ({children}: any) => {
  const classes = useStyles();

  return (
    <WithScrollToTop>
      <Container disableGutters maxWidth="lg" classes={{root: classes.root}}>
        {children}
      </Container>
    </WithScrollToTop>
  );
};

const GettingStartedPageRoute = (_: RouteComponentProps) => (
  <RouteContainer>
    <MakeHealthCheck>
      <GettingStartedPage />
    </MakeHealthCheck>
  </RouteContainer>
);
const AuthInsuredPageRoute = (_: RouteComponentProps) => (
  <RouteContainer>
    <MakeHealthCheck>
      <AuthInsuredPage />
    </MakeHealthCheck>
  </RouteContainer>
);
const PolicyPage = (_: RouteComponentProps) => (
  <RouteContainer>
    <MakeHealthCheck>
      <PolicySearchPage />
    </MakeHealthCheck>
  </RouteContainer>
);
const AutoPage = (_: RouteComponentProps) => (
  <WithScrollToTop>
    <RefreshPageBehavior>
      <AutoFormPage />
    </RefreshPageBehavior>
  </WithScrollToTop>
);
const AgentPolicyPage = (_: RouteComponentProps) => (
  <RouteContainer>
    <MakeHealthCheck>
      <AgentPolicySearchPage />
    </MakeHealthCheck>
  </RouteContainer>
);
const SuccessPage = (_: RouteComponentProps) => (
  <RouteContainer>
    <Success />
  </RouteContainer>
);
const EstimatePage = (_: RouteComponentProps) => (
  <RouteContainer>
    <RefreshPageBehavior>
      <QuickEstimatePage />
    </RefreshPageBehavior>
  </RouteContainer>
);
const MaintenancePage = (_: RouteComponentProps) => (
  <RouteContainer>
    <ClaimsCenterMaintenancePage />
  </RouteContainer>
);
const HomeownersPage = (_: RouteComponentProps) => (
  <ScrollToTop>
    <RefreshPageBehavior>
      <HomeownersFormPage />
    </RefreshPageBehavior>
  </ScrollToTop>
);
const AssociatePolicyPage = (_: RouteComponentProps) => (
  <RouteContainer>
    <MakeHealthCheck>
      <AuthenticatedRoute redirectUrl={ASSOCIATE_POLICY_SEARCH_ROUTE} path="associate">
        <AssociatePolicySearchPage />
      </AuthenticatedRoute>
    </MakeHealthCheck>
  </RouteContainer>
);

const CopartPreferencePage = (_: RouteComponentProps) => (
  <RouteContainer>
    <RefreshPageBehavior>
      <CopartPage />
    </RefreshPageBehavior>
  </RouteContainer>
);

const StaffAppraiserInfoPage = (_: RouteComponentProps) => (
  <RouteContainer>
    <StaffAppraiserPage />
  </RouteContainer>
);

const CommercialAutoPage = (_: RouteComponentProps) => (
  <RouteContainer>
    <CommercialAutoSelectionPage />
  </RouteContainer>
);

export const onHistoryChange = (history: HistoryListenerParameter) => {
  const {action, location} = history;
  if (action === 'POP' && location.pathname === ROOT_ROUTE) {
    // clear out cookie data
    Cookies.remove('data');
    Cookies.remove('authenticated-redirect');
    clearSessionData();
  }
};

const App = () => {
  const [featureFlags, setFeatureFlags] = useState({});

  const mapFeatureFlags = (featureFlags: FeatureFlagObject[]) => {
    let mappedFF: {[featureFlag: string]: boolean} = {};

    featureFlags.forEach(ff => {
      mappedFF[ff.flag_name] = ff.enabled;
    });

    return mappedFF;
  };

  const getFeatureFlags = async () => {
    try {
      const response = await retrieveFeatureFlags();
      const featureFlags = mapFeatureFlags(response?.data?.data);
      setFeatureFlags(featureFlags);
    } catch {
      navigate(MAINTENANCE_ROUTE);
    }
  };

  useEffect(() => {
    getFeatureFlags();

    return globalHistory.listen(onHistoryChange);
  }, []);

  return (
    <RecoilRoot>
      <WithFeatureFlagsContext featureFlags={featureFlags}>
        <ThemeProvider theme={defaultTheme}>
          <SauxThemeProvider theme={SAUX360Theme}>
            <CssBaseline />
            <SAHeader />
            <Router>
              <GettingStartedPageRoute path={ROOT_ROUTE} />
              <AuthInsuredPageRoute path={AUTH_INSURED_ROUTE} />
              <PolicyPage path={POLICY_SEARCH_ROUTE} />
              <AgentPolicyPage path={AGENT_POLICY_SEARCH_ROUTE} />
              <CommercialAutoPage path={COMMERCIAL_AUTO_VEHICLE_SELECTION_ROUTE} />
              <AutoPage path={FNOL_AUTO_ROUTE} />
              <HomeownersPage path={HOMEOWNERS_ROUTE} />
              <SuccessPage path={SUMMARY_PAGE_ROUTE} />
              <EstimatePage path={QUICK_ESTIMATE_ROUTE} />
              <MaintenancePage path={MAINTENANCE_ROUTE} />
              <AssociatePolicyPage path={ASSOCIATE_POLICY_SEARCH_ROUTE} />
              <CopartPreferencePage path={COPART_ROUTE} />
              <StaffAppraiserInfoPage path={STAFF_APPRAISER_ROUTE} />
            </Router>
          </SauxThemeProvider>
        </ThemeProvider>
      </WithFeatureFlagsContext>
    </RecoilRoot>
  );
};

export default App;
