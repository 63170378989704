import React, {useState} from 'react';
import {useContext} from 'react';
import queryString from 'query-string';
import {
  BreadCrumbsContext,
  BreadCrumbsContextProps,
  BreadCrumbsContextUpdate,
  DraftClaimContext,
  useFeatureFlags,
} from '../components/common/Providers/Providers';
import {Contact} from '../components/common/Summary/types';
import {ContactTypes, CustomerTypes, PhoneTypes} from '../commonTypes';
import {verifyPhoneNumber} from '../services';
import {phoneValidationRegex} from '../validations';
import {Snowplow} from '../pages/utils/snowplow';
import {useGetReporterAtomState, useUserAtomState} from '../atoms';
import {LogRocket} from '../services/logRocket';
import {
  ContactFieldCriteria,
  ContactFields,
  ContactSections,
  CONTACT_REQUIRED_FIELDS,
} from '../components/common/ContactInfo/types';
import {makePersonaCheck} from '../utils/utils';

export const useFeatureFlag = (name: string): {isActive: boolean} => {
  const parsed = queryString.parse(window.location.search);
  return {
    isActive: parsed['feature' + name] === 'true',
  };
};

export const useBreadcrumbContext = (): BreadCrumbsContextProps & BreadCrumbsContextUpdate => {
  const context = useContext(BreadCrumbsContext);
  return context;
};

const defaultValues: {[key: string]: string} = {};
export const useDefaultValues = () => {
  const setDefaultValue = (key: string, value: string) => {
    defaultValues[key] = value;
  };
  const getDefaultValue = (key: string) => {
    return defaultValues[key];
  };

  return {
    setDefaultValue,
    getDefaultValue,
  };
};

export interface UseContactInformationProps {
  acceptableRoles?: string[] | undefined;
  commonInsuredContactRoles: string[];
  defaultContactCriteria: string | undefined;
}

export const useContactInformation = ({
  acceptableRoles,
  commonInsuredContactRoles,
  defaultContactCriteria,
}: UseContactInformationProps) => {
  const {draftClaimResponse} = useContext(DraftClaimContext);
  const contacts = draftClaimResponse?.contacts;

  const insuredNames =
    contacts &&
    contacts?.filter(
      (contact: Contact) =>
        contact?.contactType?.toLocaleLowerCase() === ContactTypes.Person &&
        contact?.contactRoles?.some(
          (contactRole: any) =>
            (acceptableRoles && acceptableRoles.includes(contactRole)) ||
            commonInsuredContactRoles.includes(contactRole)
        )
    );

  const insuredNameList = insuredNames?.map((contact: Contact) => {
    return (
      <option key={contact?.publicID} value={contact?.publicID}>
        {contact?.displayName}
      </option>
    );
  });

  const [insuredContact, setInsuredContact] = useState<Contact | undefined | null>(
    insuredNames?.find(x => x.contactRoles?.includes(defaultContactCriteria || 'insured')) ||
      insuredNames?.[0]
  );

  const [selectedOther, setSelectedOther] = useState<boolean>(
    !(insuredNames && insuredNames?.length > 0)
  );

  return {
    insuredNames,
    insuredNameList,
    insuredContactStateMgt: {insuredContact, setInsuredContact},
    selectedOtherStateMgt: {selectedOther, setSelectedOther},
  };
};

export const useVerifyPhone = () => {
  const {featureFlags} = useFeatureFlags();
  const [userAtomState, _] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType;
  const reporterMetaData = useGetReporterAtomState();

  const shouldVerifyPhone = (phoneNumber: string, phoneType: string, regex?: RegExp) => {
    const regexToTest = regex || phoneValidationRegex;

    return (
      featureFlags?.FF_DCX_2385 && phoneType === PhoneTypes.Mobile && regexToTest.test(phoneNumber)
    );
  };

  const verifyMobileNumber = async (phoneNumber: string) => {
    let isValidMobileNumber: boolean = false;
    const reporter = reporterMetaData.contact
      ? `${reporterMetaData.contact?.firstName} ${reporterMetaData.contact?.lastName}`
      : '';

    try {
      const {data} = await verifyPhoneNumber({phoneNumber});
      if (typeof data.data !== 'boolean') {
        isValidMobileNumber = data.data.type === PhoneTypes.Mobile;
      }
    } catch (error) {
      LogRocket.log('Verify Phone Error:', error);
    }

    Snowplow.track.verifiedPhoneNumber({
      phoneNumber,
      isValidMobileNumber,
      persona: userPersona as string,
      reporter,
    });

    return isValidMobileNumber;
  };

  return {shouldVerifyPhone, verifyMobileNumber};
};

export const useContactValidationSchemaContext = (occurrences: ContactSections[]) => {
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType;

  const determineIfRequired = (key: ContactFields) => {
    return (
      CONTACT_REQUIRED_FIELDS[key].filter(
        (criteria: ContactFieldCriteria) =>
          occurrences.includes(criteria.section) &&
          makePersonaCheck(userPersona as CustomerTypes, criteria.personas)
      ).length > 0
    );
  };

  const addressRequired = determineIfRequired(ContactFields.Address);
  const emailRequired = determineIfRequired(ContactFields.Email);
  const phoneRequired = determineIfRequired(ContactFields.Phone);

  return {addressRequired, emailRequired, phoneRequired};
};
