import React from 'react';

export const QuestionBubble = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill="#E7F4E6" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 42C36.6274 42 42 36.6274 42 30C42 23.3726 36.6274 18 30 18C23.3726 18 18 23.3726 18 30C18 36.6274 23.3726 42 30 42ZM30 45C38.2843 45 45 38.2843 45 30C45 21.7157 38.2843 15 30 15C21.7157 15 15 21.7157 15 30C15 38.2843 21.7157 45 30 45Z"
      fill="#4D840B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.5 37.5C28.5 36.6716 29.1716 36 30 36H30.015C30.8434 36 31.515 36.6716 31.515 37.5C31.515 38.3284 30.8434 39 30.015 39H30C29.1716 39 28.5 38.3284 28.5 37.5Z"
      fill="#4D840B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.3869 24.0367C28.9451 23.7894 27.5351 24.618 27.0497 25.998C26.7748 26.7794 25.9184 27.1901 25.1369 26.9152C24.3554 26.6403 23.9448 25.7839 24.2197 25.0024C25.1906 22.2425 28.0105 20.5852 30.894 21.0798C33.7773 21.5744 35.8836 24.0762 35.8797 27.0016C35.879 29.2971 34.1771 30.813 32.9617 31.6233C32.3083 32.0589 31.6655 32.3792 31.192 32.5897C30.9531 32.6958 30.752 32.7764 30.607 32.8316C30.5344 32.8593 30.4756 32.8807 30.4327 32.8959L30.3806 32.9142L30.3641 32.9198L30.3582 32.9218L30.3559 32.9226C30.3555 32.9227 30.354 32.9232 29.8797 31.5002L30.354 32.9232C29.5681 33.1852 28.7186 32.7604 28.4567 31.9745C28.1949 31.1891 28.6189 30.3403 29.4038 30.0777C29.4039 30.0777 29.4037 30.0777 29.4038 30.0777L29.4018 30.0783C29.4017 30.0784 29.4019 30.0784 29.4038 30.0777L29.4277 30.0693C29.4508 30.0611 29.4886 30.0474 29.5391 30.0281C29.6402 29.9896 29.7906 29.9296 29.9736 29.8482C30.3439 29.6837 30.8261 29.4415 31.2976 29.1271C32.332 28.4375 32.8797 27.7039 32.8797 27.0002L32.8797 26.998C32.8819 25.5351 31.8287 24.284 30.3869 24.0367Z"
      fill="#4D840B"
    />
  </svg>
);
