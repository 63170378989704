import React from 'react';

interface checkboxProps {
  height: string;
  width: string;
}

export const GreenRoundCheckboxAdjustable = () => (
  <svg
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30" cy="30" r="30" fill="#E7F4E6" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.686 19.7582C29.8946 19.2592 32.2053 19.4875 34.2735 20.409C35.0302 20.7462 35.917 20.4061 36.2541 19.6494C36.5913 18.8927 36.2512 18.0059 35.4945 17.6687C32.8354 16.4839 29.8644 16.1904 27.0249 16.8319C24.1853 17.4735 21.6291 19.0158 19.7377 21.2288C17.8462 23.4418 16.7208 26.2069 16.5293 29.1117C16.3377 32.0166 17.0903 34.9056 18.6748 37.3478C20.2593 39.79 22.5908 41.6545 25.3215 42.6634C28.0523 43.6723 31.036 43.7714 33.8277 42.946C36.6194 42.1206 39.0695 40.4148 40.8126 38.0832C42.5557 35.7515 43.4983 32.918 43.5 30.0069V28.9029C43.5 28.0744 42.8284 27.4029 42 27.4029C41.1716 27.4029 40.5 28.0744 40.5 28.9029V30.006C40.4987 32.2703 39.7655 34.4734 38.4098 36.2869C37.0541 38.1004 35.1484 39.4271 32.9771 40.0691C30.8058 40.7111 28.4851 40.634 26.3612 39.8493C24.2373 39.0646 22.4239 37.6144 21.1915 35.7149C19.9591 33.8154 19.3738 31.5685 19.5228 29.3091C19.6717 27.0498 20.5471 24.8992 22.0182 23.1779C23.4893 21.4567 25.4774 20.2572 27.686 19.7582ZM43.0305 22.09C43.6325 21.5208 43.6591 20.5715 43.09 19.9695C42.5208 19.3675 41.5715 19.3409 40.9695 19.91L29.3072 30.9362L26.53 28.3131C25.9277 27.7443 24.9784 27.7714 24.4095 28.3736C23.8407 28.9759 23.8678 29.9252 24.47 30.4941L28.2777 34.0905C28.856 34.6367 29.7602 34.6365 30.3382 34.09L43.0305 22.09Z"
      fill="#4D840B"
    />
  </svg>
);
