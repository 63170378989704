import axios, {AxiosResponse} from 'axios';
import {GoogleMapsResponse} from '../components/common/WithGoogleMaps/WithGoogleMaps';
import {Lobs, Adjuster} from '../components/common/Summary/types';
import {navigate} from '@reach/router';
import Cookies from 'js-cookie';
import {UserData} from '../commonTypes';
import {State} from '../components/common/StateDropdown/StateDropdown';
import {Auth} from 'aws-amplify';
import {setSessionCookieData} from '../pages/utils';
import {MAINTENANCE_ROUTE} from '../routes/paths';
import {QuickEstimateInstance} from '../components/auto/QuickEstimate/QuickEstimate';
import {
  AdditionalFormContactInfo,
  BaseFormContactInfo,
  FormContactAddress,
} from '../components/common/ContactInfo/types';

const BASE_URL = `https://fnol-api-${process.env.REACT_APP_ENV?.toLowerCase()}.${
  process.env.REACT_APP_AWS
}0002.stateauto.com`;

const appEnv = process.env.REACT_APP_AWS === 'msp' ? 'prod' : 'dev';

const FEATURE_FLAG_URL = `https://dcx-featureflags-${appEnv}.${process.env.REACT_APP_AWS}0002.stateauto.com/fnol/featureflags`;

export const PATH = {
  AGENT: 'agent',
  POLICY: 'policy',
  FNOL: 'fnol',
  DCX: 'dcx',
  AUTHINSURED: 'authinsured',
  ASSOCIATE: 'associate',
};

const getBaseType = (base: string) => {
  const path = Cookies.get('path');

  return path || base;
};

interface OptionalHeaderProps {
  includePolicyNumber: boolean;
}

const getOptionalHeaders = ({includePolicyNumber}: OptionalHeaderProps) => {
  return {
    headers: {
      Authorization: Cookies.get('userToken'),
      ...(includePolicyNumber ? {PolicyNumber: Cookies.get('policyNumber')} : {}),
      ...(Cookies.get('path') === PATH.AUTHINSURED ? {uuid: Cookies.get('uuid')} : {}),
    },
  };
};

export const ENDPOINTS = {
  VERIFY_POLICY: 'verifypolicy',
  VERIFY_POLICY_DATALAKE: 'verifypolicydatalake',
  SUBMIT_CLAIM: 'submitclaim',
  AUTOCOMPLETE: 'autocomplete',
  PLACE_DETAILS: 'placedetails',
  GEOCODE: 'geocode',
  SIGNED_URL: 'signedurl',
  EDS_UPLOAD: 'edsupload',
  APPRAISER_ASSIGNMENT: 'appraiserassignment',
  DRAFT_CLAIM: 'draftclaim',
  USER_FEEDBACK: 'userfeedback',
  DUPLICATE_CHECK: 'duplicatecheck',
  USER_AUTH: 'userauthorization',
  INSURED_USER_AUTHORIZATION: 'insureduservalid',
  SEARCH_POLICY: 'searchpolicy',
  FETCH_FRAUD_LANGUAGE: 'fetchfraudlanguage',
  FNOL_FEATURE_FLAGS: 'fnolfeatureflags',
  UPDATE_COPART: 'updatecopart',
  CC_HEALTH_CHECK: 'cchealthcheck',
  CONVERT_FORM_DATA: 'convertformdata',
  VERIFY_PHONE_NUMBER: 'verifyphone',
  SUMMARY_NOTES: 'summarynotes',
  GET_CATASTROPHE: 'getcatastrophes',
  FRAUD_MODEL: 'fraudmodel',
  STP_MODEL: 'stpmodel',
  STP_UPDATE: 'stpupdate',
  INITIATE_MOI: 'initiatemoi',
  UNINHABITABLE_SUMMARY_CHECK: 'uninhabitableSummaryCheck',
  UPDATE_DRAFT_CLAIM: 'updatedraftclaim',
};

export interface Response<T> {
  data: T;
  errors: string[];
  hasErrors: boolean;
}

export interface GoogleMapsAutoCompleteProps {
  address: string;
}

export interface GoogleMapsCompleteDataProps {
  place_id?: string | undefined;
  latlng?: {lat: number; lng: number} | undefined;
}

export interface OtherOptions {
  type: string | null;
}

export const verifyPhoneNumber = (payload: VerifyPhoneNumberPayload) => {
  return axios.post<
    VerifyPhoneNumberPayload,
    AxiosResponse<Response<VerifyPhoneNumberResponse | boolean>>
  >(
    `${BASE_URL}/${getBaseType(PATH.POLICY)}/${ENDPOINTS.VERIFY_PHONE_NUMBER}`,
    JSON.stringify(payload),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export interface VerifyPolicyPayload {
  policyNumber?: string;
  firstName?: string;
  lastName?: string;
  lossDate?: string;
  lossTime?: string;
  companyName?: string;
  vin?: string;
  policySearchType?: string;
  lineOfBusiness?: string;
  policyholderType?: string;
  agentSearch?: boolean;
  associateSearch?: boolean;
  address?: string;
  zip?: string;
  city?: string;
  state?: string;
  lossTimezone?: string;
}

export interface VerifyPolicyResponse {
  data: UserData | boolean | [UserData];
  hasError: boolean;
  errors: string[];
  jwt: string;
}

export interface summaryNotesPayload {
  claimNumber: string;
  additionalSummaryNotes: string;
  publicID: string;
}

export const summaryNotes = (payload: summaryNotesPayload) => {
  return axios.post<summaryNotesPayload, AxiosResponse<Response<any>>>(
    `${BASE_URL}/${PATH.ASSOCIATE}/${ENDPOINTS.SUMMARY_NOTES}`,
    JSON.stringify(payload),
    {
      ...getOptionalHeaders({includePolicyNumber: false}),
    }
  );
};

export interface VerifyPhoneNumberPayload {
  phoneNumber: string;
}

export interface VerifyPhoneNumberResponse {
  type: string;
}
export interface CatastrophePayload {
  lossLocation: string;
  lossDate: string;
}
export interface CatastropheResponse {
  catastrophe_number: string;
  description: string;
  state: string;
  begin_date: string;
  end_date: string;
}

export const getCatastrophe = (payload: CatastrophePayload, options?: OtherOptions) => {
  return axios.post<CatastrophePayload, AxiosResponse<Response<CatastropheResponse[]>>>(
    `${BASE_URL}/${getBaseType(PATH.POLICY)}/${ENDPOINTS.GET_CATASTROPHE}`,
    JSON.stringify(payload),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const verifyPolicy = (payload: VerifyPolicyPayload, options?: OtherOptions) => {
  return axios.post<VerifyPolicyPayload, AxiosResponse<VerifyPolicyResponse>>(
    `${BASE_URL}/${getBaseType(PATH.POLICY)}/${
      options?.type === 'associate' ? ENDPOINTS.SEARCH_POLICY : ENDPOINTS.VERIFY_POLICY
    }`,
    JSON.stringify(payload),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const verifyPolicyDatalake = (payload: VerifyPolicyPayload, options?: OtherOptions) => {
  return axios.post<VerifyPolicyPayload, AxiosResponse<VerifyPolicyResponse>>(
    `${BASE_URL}/${getBaseType(PATH.POLICY)}/${
      options?.type === 'associate' ? ENDPOINTS.SEARCH_POLICY : ENDPOINTS.VERIFY_POLICY_DATALAKE
    }`,
    JSON.stringify(payload),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export interface SubmitClaimResponse {
  claimNumber: string;
  lobs?: Lobs;
  adjusterDetails?: Adjuster;
  mainContact?: any;
  officials?: any;
  witnesses?: any;
  policy?: any;
  publicID?: string;
  lossDate?: string;
  lossCause?: string;
  description?: string;
  contacts?: any[];
  lossLocation?: any;
  adjusterNote?: any[];
  documents?: any[];
  isCATClaim?: boolean;
  reviewLossHistory?: boolean;
  noticeOnly?: boolean;
  howReported?: string;
  triageLevel?: string;
  otherDetails?: any;
  claimPreference?: {
    isEpayOptOut?: string;
  };
}

export const submitClaim = (payload: any) => {
  return axios.post<Response<SubmitClaimResponse>>(
    `${BASE_URL}/${getBaseType(PATH.FNOL)}/auto/${ENDPOINTS.SUBMIT_CLAIM}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const propertySubmitClaim = (payload: any) => {
  return axios.post<Response<SubmitClaimResponse>>(
    `${BASE_URL}/${getBaseType(PATH.FNOL)}/property/${ENDPOINTS.SUBMIT_CLAIM}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const appraiserAssignment = (payload: any) => {
  return axios.post(
    `${BASE_URL}/${getBaseType(PATH.FNOL)}/${ENDPOINTS.APPRAISER_ASSIGNMENT}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const initiateMOI = (payload: any) => {
  return axios.post<Response<SubmitClaimResponse>>(
    `${BASE_URL}/${getBaseType(PATH.POLICY)}/${ENDPOINTS.INITIATE_MOI}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const updateCopart = (payload: any) => {
  return axios.post(
    `${BASE_URL}/${getBaseType(PATH.FNOL)}/${ENDPOINTS.UPDATE_COPART}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const draftClaim = (payload: any) => {
  return axios.post(
    `${BASE_URL}/${getBaseType(PATH.FNOL)}/${ENDPOINTS.DRAFT_CLAIM}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const userFeedback = (payload: any) => {
  return axios.post(
    `${BASE_URL}/${getBaseType(PATH.DCX)}/${ENDPOINTS.USER_FEEDBACK}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export interface DuplicateClaimCheck {
  policyNumber: string;
  lossDate: string;
}

export interface DuplicateClaimCCResponse {
  state: string;
  claimNumber: string;
  lossDate: string;
  description: string;
  reportedDate: string;
  assignedUser: string;
}

export interface DuplicateClaimSuccessResponse {
  data: DuplicateClaimCCResponse[];
}

export interface DuplicateClaimFailureResponse {
  data: boolean;
}

export interface AuthInsuredUserAuthorizationPayload {
  uuid: string;
}

export interface AuthInsuredUserAuthorizationResponse {
  is_user_authorized: boolean;
  product_code: string;
  policy_number: string;
  first_name: string;
  last_name: string;
}

export const duplicateCheck = (
  payload: DuplicateClaimCheck
): Promise<DuplicateClaimSuccessResponse | DuplicateClaimFailureResponse> => {
  return axios.post(
    `${BASE_URL}/${getBaseType(PATH.FNOL)}/${ENDPOINTS.DUPLICATE_CHECK}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const userauthorization = () => {
  return axios.post<any, {data: {data: boolean}}>(
    `${BASE_URL}/${getBaseType(PATH.AGENT)}/${ENDPOINTS.USER_AUTH}`,
    {},
    {
      ...getOptionalHeaders({includePolicyNumber: false}),
    }
  );
};

export const authInsuredUserAuthorization = (payload: AuthInsuredUserAuthorizationPayload) => {
  return axios.post<Response<AuthInsuredUserAuthorizationResponse>>(
    `${BASE_URL}/${PATH.AUTHINSURED}/${ENDPOINTS.INSURED_USER_AUTHORIZATION}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: false}),
    }
  );
};

export interface SignedUrlPayload {
  filename: string;
  claimnumber: string;
}

export interface SignedResponseFields {
  key: string;
  AWSAccessKeyId: string;
  'x-amz-security-token': string;
  policy: string;
  signature: string;
}

export interface SignedResponse {
  url: string;
  fields: SignedResponseFields;
}

export interface SuccessSignedResponse {
  data?: SignedResponse;
}

export const getSignedUrl = (payload: SignedUrlPayload): Promise<SuccessSignedResponse> => {
  return axios.post(
    `${BASE_URL}/${getBaseType(PATH.FNOL)}/${ENDPOINTS.SIGNED_URL}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export interface EDSUploadProps {
  filename: string | string[];
  claimnumber: string;
  related_to?: string | undefined;
}

interface EDSResponse {
  data: {
    message: string;
    hasErrors: boolean;
    errors: any[];
  };
  status: number;
}

export const uploadToEDS = (payload: EDSUploadProps): Promise<EDSResponse> => {
  return axios.post(
    `${BASE_URL}/${getBaseType(PATH.FNOL)}/${ENDPOINTS.EDS_UPLOAD}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

interface S3UploadProps {
  signedUrl: string;
  dataFields: SignedResponseFields;
  file: string | Blob;
  headers?: OtherOptions;
}

export const uploadToS3 = ({signedUrl, dataFields, file}: S3UploadProps): Promise<any> => {
  const formData = new FormData();

  formData.append('key', dataFields.key);
  formData.append('AWSAccessKeyId', dataFields.AWSAccessKeyId);
  formData.append('x-amz-security-token', dataFields['x-amz-security-token']);
  formData.append('policy', dataFields.policy);
  formData.append('signature', dataFields.signature);
  formData.append('file', file);

  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...getOptionalHeaders({includePolicyNumber: true}),
    },
  };

  return axios.post(signedUrl, formData, options);
};

export const googleMapsAutoComplete = (
  payload: GoogleMapsAutoCompleteProps
): Promise<GoogleMapsResponse> => {
  return axios.post(
    `${BASE_URL}/${getBaseType(PATH.FNOL)}/${ENDPOINTS.AUTOCOMPLETE}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const googleMapsCompleteData = (payload: GoogleMapsCompleteDataProps): Promise<any> => {
  return axios.post(
    `${BASE_URL}/${getBaseType(PATH.FNOL)}/${ENDPOINTS.PLACE_DETAILS}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export interface GetGeocodeDataProps {
  geocodeType: string;
  address?: string;
  latlng?: {
    lat: number;
    lng: number;
  };
}

export const getGeocodeData = (payload: GetGeocodeDataProps) => {
  return axios.post(
    `${BASE_URL}/${getBaseType(PATH.FNOL)}/${ENDPOINTS.GEOCODE}`,
    JSON.stringify({...payload}),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const fetchFraudLanguage = () => {
  return axios.post<Response<State[] | boolean>>(
    `${BASE_URL}/${getBaseType(PATH.POLICY)}/${ENDPOINTS.FETCH_FRAUD_LANGUAGE}`,
    {},
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const fetchFeatureFlags = () => {
  return axios.get<Response<any>>(`${BASE_URL}/${ENDPOINTS.FNOL_FEATURE_FLAGS}`, {});
};

export interface FeatureFlagObject {
  application_name: string;
  created_date: string;
  description: string;
  enabled: boolean;
  flag_name: string;
  modified_date: string;
  type: string;
}

export const retrieveFeatureFlags = () => {
  return axios.get<Response<FeatureFlagObject[]>>(FEATURE_FLAG_URL);
};

export interface CCHealthCheckResponse {
  CC_L4_HEALTH_STATUS: boolean;
}

export const ccHealthCheck = () => {
  return axios.get<Response<CCHealthCheckResponse>>(`${BASE_URL}/${ENDPOINTS.CC_HEALTH_CHECK}`, {});
};

export const convertFormData = (payload: any, policyNumber: string) => {
  return axios.post<Response<string | boolean>>(
    `${BASE_URL}/${getBaseType(PATH.FNOL)}/${ENDPOINTS.CONVERT_FORM_DATA}`,
    JSON.stringify({data: payload, policynumber: policyNumber}),
    {
      ...getOptionalHeaders({includePolicyNumber: false}),
    }
  );
};

export interface FraudModelPayload {
  claimNumber: string;
}

interface TriggeredBusinessRule {
  rule_name: string;
  rule_number: number;
}

interface PostFraudCheck {
  claim_number: string;
  final_business_rule_score: number;
  final_model_score: number;
  final_result: string;
  triggered_business_rules: TriggeredBusinessRule[];
}

export interface FraudModelResponse {
  postFraudCheck: PostFraudCheck;
}

export const fraudModel = (payload: FraudModelPayload) => {
  return axios.post<FraudModelPayload, AxiosResponse<Response<FraudModelResponse | boolean>>>(
    `${BASE_URL}/${getBaseType(PATH.POLICY)}/${ENDPOINTS.FRAUD_MODEL}`,
    JSON.stringify(payload),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export interface STPModelPayload {
  data: any;
  postFraudCheck: PostFraudCheck;
  quickEstimates: QuickEstimateInstance[];
}

interface Message {
  name: string;
  category: string;
  message: string[];
}

export interface STPModelResponse {
  AutoSTPClaimCoverageValidityIndicator: string | null;
  AutoSTPClaimExposureBureauCauseOfLossTypeCodeList: string | null;
  AutoSTPClaimFraudValidityIndicator: string | null;
  AutoSTPClaimLiabilityValidityIndicator: string | null;
  AutoSTPClaimOfficialTypeCodeList: string | null;
  AutoSTPClaimPartyRoleList: string | null;
  AutoSTPClaimPaymentEligibilityIndicator: string | null;
  AutoSTPClaimPaymentValidityIndicator: string | null;
  AutoSTPClaimProcessEligibilityIndicator: string | null;
  ClaimExposureBCOLEligibilityIndicator: string | null;
  ClaimPolicyEffectiveIndicator: string | null;
  ClaimPolicyStateCodeAndClaimLossStateCodeMatchIndicator: string | null;
  ClaimVehicleValidDriverList: string | null;
  messages: Message[];
}

export const stpModel = (payload: STPModelPayload) => {
  return axios.post<STPModelPayload, AxiosResponse<Response<STPModelResponse>>>(
    `${BASE_URL}/${getBaseType(PATH.POLICY)}/${ENDPOINTS.STP_MODEL}`,
    JSON.stringify(payload),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export interface StpUpdatePayload {
  publicID?: string;
  claimNumber: string;
  stpFlag: boolean;
  finalBusinessRuleScore: number;
  finalModelScore: number;
  finalResult: string;
}

interface StpAdjuster {
  adjusterDetails: Adjuster;
}

export interface StpUpdateResponse {
  result: StpUpdatePayload & StpAdjuster;
}

export const stpUpdate = (payload: StpUpdatePayload) => {
  return axios.post<StpUpdatePayload, AxiosResponse<Response<StpUpdateResponse>>>(
    `${BASE_URL}/${getBaseType(PATH.POLICY)}/${ENDPOINTS.STP_UPDATE}`,
    JSON.stringify(payload),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export const uninhabitableSummaryCheck = (payload: any) => {
  return axios.post<any, AxiosResponse<Response<boolean>>>(
    `${BASE_URL}/${getBaseType(PATH.POLICY)}/${ENDPOINTS.UNINHABITABLE_SUMMARY_CHECK}`,
    JSON.stringify(payload),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

export interface UpdateDraftClaimContact
  extends BaseFormContactInfo,
    Omit<AdditionalFormContactInfo, 'contactAddress'>,
    FormContactAddress {
  claimantId: string;
  label: string;
}

interface UpdateDraftClaimPayload {
  publicID: string;
  claimNumber: string;
  isRetry: boolean;
  contacts: UpdateDraftClaimContact[];
}

export const updateDraftClaim = (payload: UpdateDraftClaimPayload) => {
  return axios.post<UpdateDraftClaimPayload, AxiosResponse<Response<any>>>(
    `${BASE_URL}/${getBaseType(PATH.POLICY)}/${ENDPOINTS.UPDATE_DRAFT_CLAIM}`,
    JSON.stringify(payload),
    {
      ...getOptionalHeaders({includePolicyNumber: true}),
    }
  );
};

// axios interceptors for error handling

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const {url} = error.config;

    const isVerifySearchError = url.match(ENDPOINTS.VERIFY_POLICY)
      ? url.match(ENDPOINTS.VERIFY_POLICY).length > 0
      : false;
    const isClaimSubmissionError = url.match(ENDPOINTS.SUBMIT_CLAIM)
      ? url.match(ENDPOINTS.SUBMIT_CLAIM).length > 0
      : false;

    if ((isVerifySearchError || isClaimSubmissionError) && error.response.status === 503) {
      navigate(MAINTENANCE_ROUTE);
    }

    return Promise.reject(error);
  }
);

declare const window: any;

axios.interceptors.request.use(
  async request => {
    if (!request.url?.includes(ENDPOINTS.CC_HEALTH_CHECK) && window.isCCDown) {
      navigate(MAINTENANCE_ROUTE);
    }

    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      const getUserToken = () => {
        return {
          getToken: () => Cookies.get('refreshToken'),
        };
      };

      cognitoUser.refreshSession(getUserToken, (err: any, session: any) => {
        setSessionCookieData({
          userToken: session?.idToken?.jwtToken,
          refreshToken: session?.refreshToken?.token,
        });
      });
    } catch (error) {
      console.log(error);
    }

    return Promise.resolve(request);
  },
  error => {
    Promise.reject(error);
  }
);
