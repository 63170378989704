import {SABox, SAText} from '@saux/design-system-react';
import React from 'react';
import {BaseSwitch} from '../../BaseSwitch/BaseSwitch';

interface ContactInfoFieldProps {
  header: string;
  data: string[];
}

export const ContactInfoField = ({header, data}: ContactInfoFieldProps) => {
  return (
    <>
      <SABox pb="xs">
        <SABox>
          <SAText text={`${header}:`} weight="bold" />
        </SABox>
        <SABox>
          {data.map((value: string) => {
            return (
              <BaseSwitch
                key={value}
                matches={!!value}
                then={
                  <SABox>
                    <SAText text={value} />
                  </SABox>
                }
              />
            );
          })}
        </SABox>
      </SABox>
    </>
  );
};
