import React, {ReactElement} from 'react';

interface PdfSectionProps {
  className: string;
  children?: ReactElement | ReactElement[] | string | JSX.Element;
}

export const PdfSection = ({className, children}: PdfSectionProps) => (
  <div className={className}>{children}</div>
);
