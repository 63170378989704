import {SATabBarProps, SABox, SACard} from '@saux/design-system-react';
import React, {ReactElement, useState} from 'react';
import styled from 'styled-components';

export type Panel = ReactElement | HTMLElement | string;

export interface SATabProps {
  panels: Panel[];
  inactiveIndexes?: number[];
  variant: string;
  active?: boolean;
  dataTestId?: string;
}

export const TabPillButton = styled.button`
  border: none;
  font-size: 16px;
  line-height: 21px;
  font-style: normal;

  &:first-of-type {
    margin-right: 26px;
  }

  &.active {
    max-width: 119px;
    min-width: 82px;
    height: 39px;
    background: #0173c6;
    border-radius: 53px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    padding: 9px 18px;
  }

  &.inactive {
    font-weight: normal;
    text-align: center;
    color: #666666;
    background-color: transparent;
  }
`;

const SATabContainer = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

const SATabContainerItem = styled.li`
  display: inline-block;
`;

export const SATab = (props: SATabBarProps & SATabProps) => {
  const [currentActiveIndex, setActiveIndex] = useState<number>(props.activeIndex || 0);
  const variant = props.variant || 'standard-tabbed';

  const onChangeHandler = (activeIndex: number) => {
    setActiveIndex(activeIndex);
    props.onChange && props.onChange(activeIndex);
  };

  return (
    <SABox data-testid={props.dataTestId}>
      {variant === 'standard-tabbed' && (
        <SACard
          variant={props.active ? 'standard-tabbed' : 'simple'}
          tabs={props.itemLabels}
          activeTabIndex={currentActiveIndex}
          title={props.itemLabels[0]}
          tabChangeEventHandler={onChangeHandler}
          removePadding={props.active ? false : true}
        >
          {props.panels.map((panel: Panel, index: number) => (
            <SABox display={index === currentActiveIndex ? 'block' : 'none'}>{panel}</SABox>
          ))}
        </SACard>
      )}
      {variant === 'standard-tagged' && (
        <SABox>
          <SATabContainer>
            {props.itemLabels.map((il: string, index: number) => (
              <SATabContainerItem>
                <TabPillButton
                  type="button"
                  className={currentActiveIndex === index ? `active` : `inactive`}
                  onClick={() => onChangeHandler(index)}
                >
                  {il}
                </TabPillButton>
              </SATabContainerItem>
            ))}
          </SATabContainer>
          {props.panels.map((panel: Panel, index: number) => (
            <SABox display={index === currentActiveIndex ? 'block' : 'none'}>{panel}</SABox>
          ))}
        </SABox>
      )}
    </SABox>
  );
};
