import React, {ReactElement, useCallback, useEffect} from 'react';
import {Waypoint} from 'react-waypoint';
import {useBreadcrumbContext} from '../../../hooks';
import {Box} from '@material-ui/core';

export enum WaypointNames {
  AnyoneElseInjured = 'ANYONE_ELSE_INJURED',
  GettingStarted = 'GETTING_STARTED',
  GeneralDetails = 'GENERAL_DETAILS',
  IncidentDetails = 'INCIDENT_DETAILS',
  PropertyDamageDetails = 'PROPERTY_DAMAGE_DETAILS',
  YourVehicleDetails = 'YOUR_VEHICLE_DETAILS',
  OtherDetails = 'OTHER_DETAILS',
  AdditionalNotes = 'ADDITIONAL_NOTES',
  InsuredContactInformation = 'INSURED_CONTACT_INFORMATION',
  ClaimPreferences = 'CLAIM_PREFERENCES',
  ClaimNumber = 'CLAIM_NUMBER',
  NextSteps = 'NEXT_STEPS',
  InsuredContactDetails = 'INSURED_CONTACT_DETAILS',
  OtherVehicleDetails = 'OTHER_VEHICLE_DETAILS',
  WeatherDamageDetails = 'WEATHER_DAMAGE_DETAILS',
  FireDamageDetails = 'FIRE_DAMAGE_DETAILS',
  WaterDamageDetails = 'WATER_DAMAGE_DETAILS',
}

export interface IWaypoints {
  [waypointName: string]: {
    displayName: string;
  };
}

export const WAYPOINTS: IWaypoints = {
  [WaypointNames.AnyoneElseInjured]: {
    displayName: 'Other Injured',
  },
  [WaypointNames.GettingStarted]: {
    displayName: 'Getting Started',
  },
  [WaypointNames.GeneralDetails]: {
    displayName: 'General Details',
  },
  [WaypointNames.IncidentDetails]: {
    displayName: 'Incident Details',
  },
  [WaypointNames.PropertyDamageDetails]: {
    displayName: 'Property Damage',
  },
  [WaypointNames.YourVehicleDetails]: {
    displayName: 'Vehicle Details',
  },
  [WaypointNames.OtherDetails]: {
    displayName: 'Other Details',
  },
  [WaypointNames.AdditionalNotes]: {
    displayName: 'Additional Notes',
  },
  [WaypointNames.InsuredContactInformation]: {
    displayName: 'Insured Contact Information',
  },
  [WaypointNames.ClaimPreferences]: {
    displayName: 'Claim Preferences',
  },
  [WaypointNames.ClaimNumber]: {
    displayName: 'Claim Number',
  },
  [WaypointNames.NextSteps]: {
    displayName: 'Next Steps',
  },
  [WaypointNames.InsuredContactDetails]: {
    displayName: 'Insured Contact Details',
  },
  [WaypointNames.OtherVehicleDetails]: {
    displayName: 'Other Vehicle Details',
  },
  [WaypointNames.WeatherDamageDetails]: {
    displayName: 'Weather Damage Details',
  },
  [WaypointNames.FireDamageDetails]: {
    displayName: 'Fire Damage Details',
  },
  [WaypointNames.WaterDamageDetails]: {
    displayName: 'Water Damage Details',
  },
};

export interface StickyBreadcrumbsProps {
  children: ReactElement;
  waypointName: string;
  displayName: string;
}

export const RegisterBreadcrumb = (props: StickyBreadcrumbsProps) => {
  const breadcrumbContext = useBreadcrumbContext();
  const {children, waypointName} = props;

  useEffect(() => {
    breadcrumbContext?.registerBreadcrumb(props.waypointName, props.displayName);
  }, []);

  const onEnter = useCallback(
    (args: Waypoint.CallbackArgs) => {
      const {currentPosition} = args;
      breadcrumbContext?.updateBreadcrumbState(waypointName, currentPosition === Waypoint.inside);
    },
    [waypointName]
  );

  return (
    <Waypoint onEnter={onEnter} topOffset={'20%'} bottomOffset={'20%'}>
      <Box id={waypointName}>{children}</Box>
    </Waypoint>
  );
};
