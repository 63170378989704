import React, {ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {
  PassengerDetails,
  PassengerDetailsSchema,
  OtherPassengerDetailsSchema,
} from '../PassengerDetails/PassengerDetails';
import {VehicleDetailsFields} from '../VehicleDetailsFields/VehicleDetailsFields';
import {Grid, Box, Divider, Typography, withStyles, Button} from '@material-ui/core';
import {
  ButtonText,
  DynamicContainer,
  DynamicContainerProps,
  WhiteAddIcon,
} from '../../common/DynamicContainer/DynamicContainer';
import {
  ArrayField,
  useFieldArray,
  UseFieldArrayMethods,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {VehicleOwnerSchema} from '../AreYouTheOwner/AreYouTheOwner';
import {
  noSpecialCharacters,
  vinValidation,
  lettersAndSpacesValidation,
  zipCodeValidation,
  mileageValidation,
  cityValidation,
  streetAddressValidation,
  stateValidation,
} from '../../../validations';
import {
  DamageSelector,
  InsuredDamageSelectorSchema,
  OtherDamageSelectorSchema,
} from '../DamageSelector/DamageSelector';
import {VehicleDriveable, VehicleDriveableSchema} from '../VehicleDriveable/VehicleDriveable';
import {
  RegisterBreadcrumb,
  WAYPOINTS,
  WaypointNames,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import * as yup from 'yup';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {makePersonaCheck, personaSchemaSwitch, validationSchemaSwitch} from '../../../utils/utils';
import {VehicleTypeSwitch} from '../VehicleTypeSwitch/VehicleTypeSwitch';
import {Coverage, Vehicle} from '../../common/Summary/types';
import {
  AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
  ContactTypes,
  CustomerTypes,
} from '../../../commonTypes';
import {CoveragesTab} from './CoveragesTab/CoveragesTab';
import {Memoize} from '../../common/Memoize/Memoize';
import {
  ManageContactOccurrences,
  useManageContactOccurrences,
  useSetVehicleOptionsAtomState,
  useUserAtomState,
} from '../../../atoms';
import {
  OtherVehicleDriverSchema,
  YourVehicleDriverSchema,
} from '../WereYouTheDriver/WereYouTheDriver';
import {VehicleOwnerAndDriver} from '../VehicleOwnerAndDriver/VehicleOwnerAndDriver';
import {ContactSections} from '../../common/ContactInfo/types';

const VehicleLimitText = withStyles(() => ({
  body1: {
    color: '#C74F32',
    fontWeight: 'bold',
  },
}))(Typography);

export const InsuredVehicleDetailsSchema = yup.object().shape({
  vehicleId: personaSchemaSwitch({
    is: (persona: any, flag: boolean) =>
      makePersonaCheck(persona, AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS, flag, true),
    then: yup.string().required('Please select a vehicle'),
  }),
  vehicleYear: personaSchemaSwitch({
    is: (persona: any, flag: boolean) =>
      makePersonaCheck(persona, AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS, flag, true),
    then: yup.string().when('vehicleId', {
      is: 'Other' || '' || undefined,
      then: yup.string().required('Vehicle Year is required').year('Enter a valid year'),
      otherwise: yup.string().nullable(),
    }),
    otherwise: yup.string().required('Vehicle Year is required').year('Enter a valid year'),
  }),
  vehicleMake: personaSchemaSwitch({
    is: (persona: any, flag: boolean) =>
      makePersonaCheck(persona, AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS, flag, true),
    then: yup.string().when('vehicleId', {
      is: 'Other' || '',
      then: noSpecialCharacters('Enter a valid make', true).required('Vehicle Make is required'),
      otherwise: yup.string().nullable(),
    }),
    otherwise: noSpecialCharacters('Enter a valid make', true).required('Vehicle Make is required'),
  }),
  vehicleModel: personaSchemaSwitch({
    is: (persona: any, flag: boolean) =>
      makePersonaCheck(persona, AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS, flag, true),
    then: yup.string().when('vehicleId', {
      is: 'Other' || '',
      then: yup.string().max(40, 'Enter a valid model.').required('Vehicle Model is required'),
      otherwise: yup.string().nullable(),
    }),
    otherwise: yup.string().max(40, 'Enter a valid model.').required('Vehicle Model is required'),
  }),
  vehicleType: yup.string().required('Please select a vehicle type'),
  vehicleVIN: personaSchemaSwitch({
    is: (persona: any, flag: boolean) =>
      makePersonaCheck(persona, AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS, flag, true),
    then: yup.string().when('vehicleId', {
      is: 'Other' || '',
      then: vinValidation('Enter a valid VIN number', true),
      otherwise: yup.string().nullable(),
    }),
    otherwise: vinValidation('Enter a valid VIN number', true),
  }),
  vehicleColor: personaSchemaSwitch({
    is: (value: any) =>
      makePersonaCheck(value, [CustomerTypes.SaCustomer, CustomerTypes.ThirdParty]),
    then: lettersAndSpacesValidation('Enter a valid color', true)
      .max(20, 'Character limit exceeded')
      .required('Vehicle color is required'),
    otherwise: lettersAndSpacesValidation('Enter a valid color', true).max(
      20,
      'Character limit exceeded'
    ),
  }),
  vehicleMileage: personaSchemaSwitch({
    is: (value: any) =>
      makePersonaCheck(value, [CustomerTypes.SaCustomer, CustomerTypes.ThirdParty]),
    then: mileageValidation(true).required('Vehicle Mileage is required'),
    otherwise: mileageValidation(true),
  }),
  ...VehicleOwnerSchema,
  ...YourVehicleDriverSchema,
  ...PassengerDetailsSchema,
  ...VehicleDriveableSchema,
  city: cityValidation(true),
  state: stateValidation().nullable(),
  zipCode: zipCodeValidation(true),
  autocompleteAddress: yup.string().nullable(),
  manualAddressEntry: yup.string().nullable(),
  address: streetAddressValidation().nullable(),
  vehicleDriveable: personaSchemaSwitch({
    is: (value: any) =>
      makePersonaCheck(value, [
        CustomerTypes.Guardian,
        CustomerTypes.SaCustomer,
        CustomerTypes.ThirdParty,
      ]),
    then: yup.string().required('Please select if vehicle is driveable').nullable(),
    otherwise: yup.string().nullable(),
  }),
  airbagsDeployed: yup.string().when('vehicleDriveable', {
    is: (value: string) => {
      return value === 'no';
    },
    then: personaSchemaSwitch({
      is: (value: any) =>
        makePersonaCheck(value, [
          CustomerTypes.Guardian,
          CustomerTypes.SaCustomer,
          CustomerTypes.ThirdParty,
        ]),
      then: yup.string().required('Please select if the airbags deployed').nullable(),
      otherwise: yup.string().nullable(),
    }),
  }),
  doYouHaveARental: validationSchemaSwitch({
    when: 'vehicleDriveable',
    is: (value: string) => {
      return value === 'no';
    },
    then: personaSchemaSwitch({
      is: (value: any) =>
        makePersonaCheck(value, [
          CustomerTypes.Guardian,
          CustomerTypes.SaCustomer,
          CustomerTypes.ThirdParty,
        ]),
      then: yup.string().required('Please select if you already have a rental vehicle').nullable(),
      otherwise: yup.string().nullable(),
    }),
    otherwise: yup.string().nullable(),
  }),
  ...InsuredDamageSelectorSchema,
});

export const OtherVehicleDetailsSchema = yup.object().shape({
  vehicleYear: yup.string().year('Enter a valid year'),
  vehicleMake: noSpecialCharacters('Enter a valid make', true).required('Vehicle Make is required'),
  vehicleModel: yup.string().max(40, 'Enter a valid model.').required('Vehicle Model is required'),
  vehicleType: yup.string(),
  vehicleVIN: vinValidation('Enter a valid VIN number', true),
  vehicleColor: lettersAndSpacesValidation('Enter a valid color', true),
  vehicleMileage: mileageValidation(true),
  ...VehicleOwnerSchema,
  ...OtherVehicleDriverSchema,
  ...OtherPassengerDetailsSchema,
  city: cityValidation(true),
  state: stateValidation().nullable(),
  zipCode: zipCodeValidation(true),
  address: streetAddressValidation().nullable(),
  autocompleteAddress: yup.string().nullable(),
  manualAddressEntry: yup.string().nullable(),
  vehicleDriveable: yup.string().nullable(),
  airbagsDeployed: yup.string().nullable(),
  doYouHaveARental: yup.string().nullable(),
  ...OtherDamageSelectorSchema,
});

export const YourVehicleDetailsSchema = {
  otherVehicles: yup.array().when('$acknowledgement', {
    is: (value: boolean) => {
      return value === true;
    },
    then: yup.array().of(OtherVehicleDetailsSchema),
  }),
  yourVehicles: yup.array().when('$acknowledgement', {
    is: (value: boolean) => {
      return value === true;
    },
    then: yup.array().of(InsuredVehicleDetailsSchema),
  }),
};

export const YourVehicleDetailsTestSchema = yup.object().shape({
  otherVehicles: yup.array().of(OtherVehicleDetailsSchema),
  yourVehicles: yup.array().of(InsuredVehicleDetailsSchema),
});

interface IHeader {
  [key: number]: string;
}

export enum VehicleTypes {
  OWNER = 'owner',
  OTHER = 'other',
}

export interface VehicleDetailsContextProps {
  fieldId?: string;
  fieldIndex?: number;
  ownerType?: VehicleTypes;
  parentFieldName?: string;
  policyVehicleCoverage?: Coverage[];
  setPolicyVehicleCoverage?: (coverage: any) => void;
  vehicleContacts?: React.MutableRefObject<VehicleContactsObject>;
  setVehicleContact?: (key: VehicleContactKeys, id: string) => void;
  removeVehicleContact?: (key: VehicleContactKeys, id?: string, removeAll?: boolean) => void;
  prefillVehicle?: boolean;
}

export const VehicleDetailsContext = React.createContext<VehicleDetailsContextProps>(
  {} as VehicleDetailsContextProps
);

export const useVehicleDetailsContext = () => {
  return React.useContext(VehicleDetailsContext);
};

export interface VehicleDetailsContextProviderProps {
  children: ReactElement;
  value: VehicleDetailsContextProps;
}

export const VehicleDetailsContextProvider = ({
  children,
  value,
}: VehicleDetailsContextProviderProps) => {
  const [policyVehicleCoverage, setPolicyVehicleCoverage] = useState<any | undefined>(
    value?.policyVehicleCoverage
  );

  return (
    <VehicleDetailsContext.Provider
      value={{...value, policyVehicleCoverage, setPolicyVehicleCoverage}}
    >
      {children}
    </VehicleDetailsContext.Provider>
  );
};

export interface VehicleFieldsProps {
  setYourVehicleCoverages?: React.Dispatch<React.SetStateAction<boolean[]>>;
  shouldFocusOnFirstField?: undefined | boolean;
  field: any;
}

export const VehicleFields = (props: VehicleFieldsProps) => {
  const formMethods = useFormContext();
  const {control} = formMethods;
  const isHailDamage = useWatch({control, name: `incidentDetails.isHailDamage`});
  return (
    <Box pt={3}>
      {isHailDamage == 'yes' && (
        <Box pt={3} pb={4}>
          <Typography variant="body1">
            If multiple insured vehicles were involved in this hail loss, please include all
            involved vehicles on this claim.
          </Typography>
        </Box>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Box pb={4} pt={1}>
            <VehicleDetailsFields
              setYourVehicleCoverages={props.setYourVehicleCoverages}
              shouldFocusOnFirstField={props.shouldFocusOnFirstField === false ? false : true}
              field={props.field}
            />
          </Box>
        </Grid>
        <VehicleOwnerAndDriver />
        <Grid item xs={12}>
          <Box pt={1}>
            <PassengerDetails />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box pb={4} pt={1}>
            <VehicleDriveable />
          </Box>
        </Grid>
      </Grid>
      <DamageSelector />
    </Box>
  );
};

export enum VehicleContactKeys {
  Owner = 'ownerId',
  Driver = 'driverId',
  Passengers = 'passengerIds',
}

export interface VehicleContactsObject {
  ownerId: string;
  driverId: string;
  passengerIds: string[];
}

interface AdditionalVehicleProps extends DynamicContainerProps {
  fieldArray: UseFieldArrayMethods<Record<string, any>, 'id'>;
  field: Partial<ArrayField<Record<string, any>, 'id'>>;
  setYourVehicleCoverages: React.Dispatch<React.SetStateAction<boolean[]>>;
  index: number;
  ownerType: VehicleTypes;
  parentFieldName: string;
  policyVehicles?: any;
  prefilledVehicle?: boolean;
}

const AdditionalVehicle = ({
  fieldArray,
  addController,
  index,
  ownerType,
  parentFieldName,
  field,
  setYourVehicleCoverages,
  policyVehicles,
  prefilledVehicle,
}: AdditionalVehicleProps) => {
  const {fields, remove} = fieldArray;
  const isOwner = ownerType === VehicleTypes.OWNER;
  const {removeContactOccurrences} = useManageContactOccurrences();
  const displayRemoveButton = !(isOwner && fields.length === 1);
  const vehicleHeaderObj: IHeader = {
    1: 'Vehicle Details',
    2: 'Second Vehicle',
    3: 'Third Vehicle',
    4: 'Fourth Vehicle',
  };
  const setVehicleOptions = useSetVehicleOptionsAtomState();

  const getAddedVehicleHeader = (index: number): string | JSX.Element => {
    if (index === 0) {
      if (isOwner) {
        return (
          <UserPersonaSwitch
            ifPersonas={[CustomerTypes.SaAgent, CustomerTypes.Guardian, CustomerTypes.Associate]}
            then={`Insured ${vehicleHeaderObj[index + 1]}`}
            otherwise={`Your ${vehicleHeaderObj[index + 1]}`}
          />
        );
      } else if (ownerType === VehicleTypes.OTHER) {
        return `Other ${vehicleHeaderObj[index + 1]}`;
      } else {
        return vehicleHeaderObj[index + 1];
      }
    } else {
      return vehicleHeaderObj[index + 1];
    }
  };

  const vehicleContacts = useRef<VehicleContactsObject>({
    ownerId: '',
    driverId: '',
    passengerIds: [],
  });

  const setVehicleContact = useCallback((key: VehicleContactKeys, id: string) => {
    if (id) {
      if (key === VehicleContactKeys.Passengers) {
        vehicleContacts.current.passengerIds.push(id);
      } else {
        vehicleContacts.current[key] = id;
      }
    }
  }, []);

  const removeVehicleContact = useCallback((key: VehicleContactKeys, id?: string) => {
    if (key === VehicleContactKeys.Passengers) {
      if (id) {
        vehicleContacts.current.passengerIds = [
          ...vehicleContacts.current.passengerIds.filter((passId: string) => passId !== id),
        ];
      } else if (vehicleContacts.current.passengerIds.length > 0) {
        vehicleContacts.current.passengerIds = [];
      }
    } else {
      vehicleContacts.current[key] = '';
    }
  }, []);

  const removeVehicle = () => {
    const sections =
      ownerType === VehicleTypes.OWNER
        ? [
            ContactSections.InsuredVehicleOwner,
            ContactSections.InsuredVehicleDriver,
            ContactSections.InsuredVehiclePassenger,
          ]
        : [
            ContactSections.OtherVehicleOwner,
            ContactSections.OtherVehicleDriver,
            ContactSections.OtherVehiclePassenger,
          ];

    let occurrencesToRemove: ManageContactOccurrences[] = [];

    if (vehicleContacts.current.ownerId) {
      occurrencesToRemove.push({id: vehicleContacts.current.ownerId, sections: [sections[0]]});
    }

    if (vehicleContacts.current.driverId) {
      occurrencesToRemove.push({id: vehicleContacts.current.driverId, sections: [sections[1]]});
    }

    vehicleContacts.current.passengerIds.forEach((id: string) => {
      if (id) {
        occurrencesToRemove.push({id, sections: [sections[2]]});
      }
    });

    if (occurrencesToRemove.length > 0) {
      removeContactOccurrences(occurrencesToRemove);
    }

    setYourVehicleCoverages((prevState: boolean[]) => {
      let newState: boolean[] = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
    setVehicleOptions(vehicleOptions => {
      const vehicleToAdd = policyVehicles?.find((vehicle: Vehicle) => {
        return fieldArray.fields[index].vehicleId === vehicle.publicID;
      });
      return [...vehicleOptions, vehicleToAdd];
    });
    remove(index);
  };

  return (
    <VehicleDetailsContextProvider
      value={{
        fieldId: field.id,
        fieldIndex: index,
        parentFieldName,
        ownerType,
        vehicleContacts,
        setVehicleContact,
        removeVehicleContact,
        prefillVehicle: prefilledVehicle,
      }}
    >
      <DynamicContainer
        header={
          <VehicleTypeSwitch
            ifVehicleTypes={[VehicleTypes.OWNER]}
            then={
              <UserPersonaSwitch
                ifPersonas={[CustomerTypes.Associate]}
                otherwise={getAddedVehicleHeader(index)}
              />
            }
            otherwise={getAddedVehicleHeader(index)}
          />
        }
        displayAddButton={index === fields.length - 1 && index < 3}
        addController={addController}
        addButtonText="ADD ANOTHER VEHICLE"
        displayRemoveButton={displayRemoveButton}
        displayRequiredText={!displayRemoveButton}
        removeButtonText="REMOVE"
        removeController={removeVehicle}
      >
        <UserPersonaSwitch
          ifPersonas={[CustomerTypes.Associate]}
          then={
            <VehicleTypeSwitch
              ifVehicleTypes={[VehicleTypes.OWNER]}
              then={
                <CoveragesTab
                  InsuredVehicleDetails={
                    <VehicleFields
                      setYourVehicleCoverages={setYourVehicleCoverages}
                      shouldFocusOnFirstField={displayRemoveButton}
                      field={field}
                    />
                  }
                />
              }
              otherwise={<VehicleFields field={field} />}
            />
          }
          otherwise={
            <VehicleFields
              setYourVehicleCoverages={setYourVehicleCoverages}
              shouldFocusOnFirstField={displayRemoveButton}
              field={field}
            />
          }
        />
      </DynamicContainer>
    </VehicleDetailsContextProvider>
  );
};

export interface VehicleDetailsProps {
  ownerType: VehicleTypes;
  setHasCompOrColCovs?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const VehicleDetails = ({ownerType, setHasCompOrColCovs}: VehicleDetailsProps) => {
  const formMethods = useFormContext();
  const {control} = formMethods;
  const [userAtomState] = useUserAtomState();
  const isCA =
    !!userAtomState?.draftClaimResponse?.result?.lobs?.commercialAuto &&
    !userAtomState?.policyNumber?.toLowerCase().startsWith('bap') &&
    !!userAtomState?.commercialAutoPage;
  const lob =
    userAtomState?.draftClaimResponse?.result?.lobs?.commercialAuto ||
    userAtomState?.draftClaimResponse?.result?.lobs?.personalAuto;

  const policyVehicles = lob?.vehicles;
  const userPersona = userAtomState?.gettingStarted?.customerType as CustomerTypes;
  const isAuthInsured = userAtomState?.gettingStarted?.isAuthInsured;
  const isOwner = ownerType === VehicleTypes.OWNER;
  const parentFieldName = isOwner ? 'yourVehicles' : 'otherVehicles';
  const [yourVehicleCoverages, setYourVehicleCoverages] = useState<boolean[]>([]);
  const fieldArray = useFieldArray({
    control,
    name: parentFieldName,
  });
  const isAssociateOrInsured = makePersonaCheck(userPersona as CustomerTypes, [
    CustomerTypes.Associate,
    CustomerTypes.SaCustomer,
  ]);
  const isAuthorizedPersona = makePersonaCheck(
    userPersona,
    AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
    isAuthInsured,
    true
  );
  const [prefilledVehicle, setPrefilledVehicle] = useState<boolean>(
    isAuthorizedPersona && isOwner && isCA
  );
  const noVehiclesAdded = fieldArray.fields.length === 0;
  const addFirstVehicle = isOwner && noVehiclesAdded;
  const emptyOtherVehicles = !isOwner && noVehiclesAdded;

  const addAdditionalVehicle = (
    year?: string | number,
    make?: string,
    model?: string,
    vin?: string,
    publicID?: string,
    style?: string
  ) => {
    const additionalVehicle = {
      ...(makePersonaCheck(userPersona, AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS, isAuthInsured, true)
        ? {vehicleId: publicID || ''}
        : {}),
      ...(makePersonaCheck(userPersona, [
        CustomerTypes.Associate,
        CustomerTypes.SaAgent,
        CustomerTypes.Guardian,
      ])
        ? {unkDamage: ''}
        : {}),
      ...(isOwner ? {anyPriorDamage: '', priorDamageDescription: ''} : {}),
      vehicleYear: year || '',
      vehicleMake: make || '',
      vehicleModel: model || '',
      vehicleType: style || '',
      vehicleVIN: vin || '',
      vehicleColor: '',
      vehicleMileage: '',
      notOwner: {
        areYouTheOwner: '',
        ownerId: '',
        radioSelection: ContactTypes.Person,
        firstName: '',
        lastName: '',
        companyName: '',
        isVehicleARental: '',
        insuranceProvider: '',
        policyOrClaimNumber: '',
        autocompleteAddress: '',
        manualAddressEntry: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        email: '',
        phoneNumbers: [{phoneNumber: '', phoneType: '', verifiedNumber: 'false'}],
      },
      notDriver: {
        ...(userPersona === CustomerTypes.Associate && !isOwner ? {injuryLevel: ''} : {}),
        driverId: '',
        driverSameAsOwner: '',
        firstName: '',
        lastName: '',
        insuranceProvider: '',
        policyOrClaimNumber: '',
        autocompleteAddress: '',
        manualAddressEntry: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        email: '',
        phoneNumbers: [{phoneNumber: '', phoneType: '', verifiedNumber: 'false'}],
        wereYouInjured: '',
      },
      wereTheAnyPassengers: null,
      additionalPassenger: [],
      vehicleDriveable: '',
      vehicleDriveableAdditionalInfo: {
        vehicleInCustomerPossession: '',
        vehicleLocationSelection: '',
        companyName: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      },
      autocompleteAddress: '',
      manualAddressEntry: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      airbagsDeployed: '',
      vehicleRolledOver: '',
      vehicleHasEngineWaterDamage: '',
      doYouHaveARental: '',
      allOverDamage: '',
      noDamage: '',
      undercarriageDamage: '',
      damages: [
        '{"label": "leftFront", "value": false}',
        '{"label": "leftFrontPillar", "value": false}',
        '{"label": "leftTBone", "value": false}',
        '{"label": "leftQuarterPost", "value": false}',
        '{"label": "leftRear", "value": false}',
        '{"label": "front", "value": false}',
        '{"label": "rightFront", "value": false}',
        '{"label": "rightFrontPillar", "value": false}',
        '{"label": "rightTBone", "value": false}',
        '{"label": "rightQuarterPost", "value": false}',
        '{"label": "rightRear", "value": false}',
        '{"label": "rear", "value": false}',
        '{"label": "undercarriage", "value": false}',
      ],
      firstImpactPoint: '',
    };

    if (isOwner && isAssociateOrInsured) {
      setYourVehicleCoverages((prevState: boolean[]) => {
        let newState: boolean[] = [...prevState];
        newState.push(false);
        return newState;
      });
    }

    fieldArray.append({...additionalVehicle}, false);
  };

  const onClickAddVehicle = () => {
    addAdditionalVehicle();
    setPrefilledVehicle(false);
  };

  const handleClick = () => {
    addAdditionalVehicle();
  };

  useEffect(() => {
    if (addFirstVehicle) {
      if (
        userAtomState.commercialAutoPage &&
        isAuthorizedPersona &&
        policyVehicles?.length >= 1 &&
        policyVehicles?.length < 5 &&
        isCA
      ) {
        policyVehicles.forEach((vehicle: Vehicle) => {
          addAdditionalVehicle(
            vehicle.year,
            vehicle.make,
            vehicle.model,
            vehicle.vIN,
            vehicle.publicID,
            vehicle.style
          );
        });
      } else {
        addAdditionalVehicle();
      }
    }
  }, []);

  useEffect(() => {
    if (isOwner && isAssociateOrInsured && setHasCompOrColCovs) {
      setHasCompOrColCovs(yourVehicleCoverages.includes(true));
    }
  }, [yourVehicleCoverages]);

  return (
    <Memoize renderOn={[fieldArray.fields, prefilledVehicle]}>
      <RegisterBreadcrumb
        waypointName={WaypointNames.YourVehicleDetails}
        displayName={WAYPOINTS[WaypointNames.YourVehicleDetails].displayName}
      >
        <FormContainer header={emptyOtherVehicles ? 'Other Vehicle Details' : undefined}>
          <>
            {fieldArray.fields.map((field, index) => (
              <Box key={field.id}>
                {index > 0 && (
                  <Grid item xs={12} data-testid={`vehicles-added-${index + 1}`}>
                    <Box pb={3}>
                      <Divider variant="middle" />
                    </Box>
                  </Grid>
                )}
                <AdditionalVehicle
                  fieldArray={fieldArray}
                  field={field}
                  index={index}
                  ownerType={ownerType}
                  parentFieldName={parentFieldName}
                  addController={onClickAddVehicle}
                  setYourVehicleCoverages={setYourVehicleCoverages}
                  policyVehicles={policyVehicles}
                  prefilledVehicle={prefilledVehicle}
                />
              </Box>
            ))}
            {emptyOtherVehicles && (
              <Button
                startIcon={<WhiteAddIcon height={32} width={32} />}
                onClick={handleClick}
                size="large"
                color="secondary"
                variant="contained"
              >
                <ButtonText variant="body1">ADD VEHICLE</ButtonText>
              </Button>
            )}
            <UserPersonaSwitch
              ifPersonas={[CustomerTypes.Associate]}
              otherwise={
                fieldArray.fields.length === 4 && (
                  <Box pb={3}>
                    <VehicleLimitText variant="body1">
                      If there were additional vehicles involved, the Adjuster will reach out to you
                      for the information.
                    </VehicleLimitText>
                  </Box>
                )
              }
            />
          </>
        </FormContainer>
      </RegisterBreadcrumb>
    </Memoize>
  );
};
