import {Box, FormControlLabel, Grid, RadioGroup} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {trackEvent} from '../../../services/analytics';
import {LogRocket} from '../../../services/logRocket';
import {AddressFields, AddressFieldsProps} from '../AddressFields/AddressFields';
import {BaseSwitch} from '../BaseSwitch/BaseSwitch';
import {SAController} from '../Controller/Controller';
import {
  GoogleMapsAutocomplete,
  GoogleMapsAutocompleteProps,
} from '../GoogleMapsAutocomplete/GoogleMapsAutocomplete';
import {SARadio} from '../Radio/Radio';
import {WithGooglePlacesContext} from '../WithGooglePlacesContext/WithGooglePlacesContext';

export interface AddressSwitchProps {
  manualAddressProps: AddressFieldsProps;
  autocompleteAddressProps: GoogleMapsAutocompleteProps;
  onAddressEntryMethodChange?: (event: React.ChangeEvent<{}>, checked: boolean) => any;
  parentFieldName: string;
  defaultManual?: boolean;
  mdWidth?: any;
}

export const AddressSwitch = (props: AddressSwitchProps) => {
  const [useAutocomplete, setUseAutocomplete] = useState<boolean>(true);
  const {register} = useFormContext();

  useEffect(() => {
    setUseAutocomplete(!props.defaultManual);
  }, [props.defaultManual]);

  const onAddressEntryMethodChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    setUseAutocomplete(!checked);
    props.onAddressEntryMethodChange && props.onAddressEntryMethodChange(event, checked);
    trackEvent({category: 'Google Places Autocomplete', label: 'Manual address entry'});
    LogRocket.track('Manual address entry');
  };

  return (
    <BaseSwitch
      matches={useAutocomplete}
      then={
        <Grid container item>
          <Grid item xs={12} md={props?.mdWidth || 10}>
            <Box display="block">
              <WithGooglePlacesContext>
                <GoogleMapsAutocomplete {...props.autocompleteAddressProps} />
              </WithGooglePlacesContext>
              <SAController
                name={`${props.parentFieldName}.manualAddressEntry`}
                defaultValue={props.defaultManual ? 'manualAddress' : ''}
                onChange={onAddressEntryMethodChange}
                key={props.defaultManual ? 'manualAddress' : ''}
              >
                <RadioGroup>
                  <FormControlLabel
                    value="manualAddress"
                    control={<SARadio />}
                    label="Enter address manually"
                    inputRef={register()}
                  />
                </RadioGroup>
              </SAController>
            </Box>
          </Grid>
        </Grid>
      }
      otherwise={
        <WithGooglePlacesContext>
          <AddressFields {...props.manualAddressProps} />
        </WithGooglePlacesContext>
      }
    />
  );
};
