import {
  ALL_PERSONAS,
  ContactRoles,
  ContactTypes,
  CustomerTypes,
  Lobs,
  PhoneTypes,
} from '../../../../commonTypes';
import {Contact} from '../../Summary/types';

export enum ContactSections {
  ReporterAuto = 'ReporterAuto',
  ReporterHome = 'ReporterHome',
  InsuredMainContact = 'InsuredMainContact',
  InsuredVehicleOwner = 'InsuredVehicleOwner',
  OtherVehicleOwner = 'OtherVehicleOwner',
  InsuredVehicleDriver = 'InsuredVehicleDriver',
  OtherVehicleDriver = 'OtherVehicleDriver',
  InsuredVehiclePassenger = 'Passenger',
  OtherVehiclePassenger = 'Passenger',
  AdditionalInjured = 'AdditionalInjured',
  YourPropertyOwner = 'YourPropertyOwner',
  OtherPropertyOwner = 'OtherPropertyOwner',
  Witness = 'Witness',
}

export interface BaseFormContactInfo {
  contactType: ContactTypes;
  firstName?: string;
  lastName?: string;
  companyName?: string;
}

export interface FormContactPhone {
  phoneNumber: string;
  phoneType: PhoneTypes | string;
  verifiedNumber: string;
}

export interface FormContactAddress {
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface FormContactAutocompleteAddress {
  manualAddressEntry?: string;
  autocompleteAddress?: string;
}

export interface AdditionalFormContactInfo<T = {}> {
  email: string;
  phoneNumbers: FormContactPhone[];
  contactAddress: FormContactAddress & T;
}

export interface FormContactPolicyInfo {
  policyInfo?: Contact;
}

export interface ContactOption extends BaseFormContactInfo, FormContactPolicyInfo {
  value: string;
  label: string;
  roles: ContactRoles[];
  occurrences: ContactSections[];
  prefillOccurrences?: ContactSections[];
}

export interface FormContact<T = {}> extends BaseFormContactInfo, AdditionalFormContactInfo<T> {
  fnolId: string;
}

export interface FnolFormContact extends FormContact, FormContactPolicyInfo {}

export interface FormContactSnapshot extends ContactOption {
  email?: string;
  phoneNumbers?: FormContactPhone[];
  contactAddress?: FormContactAddress;
}

export interface CloseContactModalProps {
  modalContactId?: string;
  isNewContact?: boolean;
  isInvalidContact?: boolean;
}

export interface ContactInfoModalProps {
  id?: string;
  section: ContactSections;
  onSave?: (params: CloseContactModalProps) => any;
  onCancel?: (params: CloseContactModalProps) => any;
  defaultIsCompany?: boolean;
  isInvalidContact?: boolean;
}

export enum ContactFields {
  Phone = 'phone',
  Email = 'email',
  Address = 'address',
}

export interface ContactFieldCriteria {
  section: ContactSections;
  personas: CustomerTypes[];
}

export interface RequiredFieldsObj {
  [key: string]: ContactFieldCriteria[];
}

/**
 * Rules for required contact fields. Each field is represented by an array.
 * For each field, represented by its corresponding key, multiple rules can be added,
 * one for each section.
 *
 * If the contact is used in the section specified in the 'section' attribute and
 * the user's persona is included in the array specified in the 'personas' attribute,
 * the field will be required.
 */
export const CONTACT_REQUIRED_FIELDS: RequiredFieldsObj = {
  [ContactFields.Phone]: [
    {
      section: ContactSections.ReporterAuto,
      personas: ALL_PERSONAS,
    },
    {
      section: ContactSections.ReporterHome,
      personas: [CustomerTypes.Guardian, CustomerTypes.SaCustomer, CustomerTypes.ThirdParty],
    },
    {
      section: ContactSections.InsuredMainContact,
      personas: ALL_PERSONAS,
    },
  ],
  [ContactFields.Email]: [
    {
      section: ContactSections.ReporterAuto,
      personas: [CustomerTypes.SaAgent, CustomerTypes.SaCustomer, CustomerTypes.ThirdParty],
    },
    {
      section: ContactSections.ReporterHome,
      personas: [CustomerTypes.SaAgent, CustomerTypes.SaCustomer, CustomerTypes.ThirdParty],
    },
  ],
  [ContactFields.Address]: [],
};

export const PERSON_ONLY_SECTIONS: ContactSections[] = [
  ContactSections.ReporterAuto,
  ContactSections.ReporterHome,
  ContactSections.InsuredMainContact,
  ContactSections.InsuredVehicleDriver,
  ContactSections.OtherVehicleDriver,
  ContactSections.InsuredVehiclePassenger,
  ContactSections.OtherVehiclePassenger,
  ContactSections.AdditionalInjured,
  ContactSections.Witness,
];

export const COMPANY_ONLY_SECTIONS: ContactSections[] = [];

export interface ContactExclusionRules {
  [key: string]: {
    type?: ContactTypes;
    occurrences: ContactSections[];
    roles: {
      [key: string]: ContactRoles[];
    };
  };
}

/**
 * Rules for excluding contacts from a specific section. Each section is represented
 * by an object key.
 *
 * If a contact's type is equal to that specified in the 'type' attribute, or
 * is currently selected in a section specified in the 'occurrences' array, or
 * contains a contact role that exists in the array specified for the current
 * line of business, then the contact will not be available for selection.
 */
export const CONTACT_EXCLUSION_RULES: ContactExclusionRules = {
  [ContactSections.ReporterAuto]: {
    type: ContactTypes.Company,
    occurrences: [],
    roles: {
      [Lobs.Auto]: [],
      [Lobs.Homeowners]: [],
    },
  },
  [ContactSections.ReporterHome]: {
    type: ContactTypes.Company,
    occurrences: [],
    roles: {
      [Lobs.Auto]: [],
      [Lobs.Homeowners]: [],
    },
  },
  [ContactSections.InsuredMainContact]: {
    type: ContactTypes.Company,
    occurrences: [],
    roles: {
      [Lobs.Auto]: [],
      [Lobs.Homeowners]: [],
    },
  },
  [ContactSections.InsuredVehicleOwner]: {
    occurrences: [],
    roles: {
      [Lobs.Auto]: [],
    },
  },
  [ContactSections.OtherVehicleOwner]: {
    occurrences: [],
    roles: {
      [Lobs.Auto]: [],
    },
  },
  [ContactSections.InsuredVehicleDriver]: {
    type: ContactTypes.Company,
    occurrences: [
      ContactSections.InsuredVehicleDriver,
      ContactSections.OtherVehicleDriver,
      ContactSections.InsuredVehiclePassenger,
      ContactSections.OtherVehiclePassenger,
      ContactSections.AdditionalInjured,
    ],
    roles: {
      [Lobs.Auto]: [],
    },
  },
  [ContactSections.OtherVehicleDriver]: {
    type: ContactTypes.Company,
    occurrences: [
      ContactSections.InsuredVehicleDriver,
      ContactSections.OtherVehicleDriver,
      ContactSections.InsuredVehiclePassenger,
      ContactSections.OtherVehiclePassenger,
      ContactSections.AdditionalInjured,
    ],
    roles: {
      [Lobs.Auto]: [],
    },
  },
  [ContactSections.InsuredVehiclePassenger]: {
    type: ContactTypes.Company,
    occurrences: [
      ContactSections.InsuredVehicleDriver,
      ContactSections.OtherVehicleDriver,
      ContactSections.InsuredVehiclePassenger,
      ContactSections.OtherVehiclePassenger,
      ContactSections.AdditionalInjured,
    ],
    roles: {
      [Lobs.Auto]: [],
    },
  },
  [ContactSections.YourPropertyOwner]: {
    occurrences: [],
    roles: {
      [Lobs.Auto]: [],
      [Lobs.Homeowners]: [],
    },
  },
  [ContactSections.OtherPropertyOwner]: {
    occurrences: [],
    roles: {
      [Lobs.Auto]: [],
      [Lobs.Homeowners]: [],
    },
  },
  [ContactSections.AdditionalInjured]: {
    type: ContactTypes.Company,
    occurrences: [
      ContactSections.InsuredVehicleDriver,
      ContactSections.OtherVehicleDriver,
      ContactSections.InsuredVehiclePassenger,
      ContactSections.OtherVehiclePassenger,
      ContactSections.AdditionalInjured,
    ],
    roles: {
      [Lobs.Auto]: [],
      [Lobs.Homeowners]: [],
    },
  },
  [ContactSections.Witness]: {
    type: ContactTypes.Company,
    occurrences: [
      ContactSections.InsuredVehicleDriver,
      ContactSections.OtherVehicleDriver,
      ContactSections.InsuredVehiclePassenger,
      ContactSections.OtherVehiclePassenger,
      ContactSections.AdditionalInjured,
    ],
    roles: {
      [Lobs.Auto]: [],
      [Lobs.Homeowners]: [],
    },
  },
};
