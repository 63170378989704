import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useModalController} from '../../Providers/ModalController';

const ModalOverlayStyled = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(4, 30, 65, 0.6);
  height: 100vh;
  z-index: 200;
  overflow-y: auto;
`;

interface ModalOverlayProps {
  claimNumber: string;
  setData: any;
  setHasBeenClosed: any;
  setIsOpen: any;
}

export const ModalOverlay = ({
  claimNumber,
  setData,
  setHasBeenClosed,
  setIsOpen,
}: ModalOverlayProps) => {
  const {open, Modal} = useModalController();

  useEffect(() => {
    if (open) {
      window.scrollTo(0, 0);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [open]);

  return open ? (
    <ModalOverlayStyled id="ModalOverlayOpen" data-testid="modalOverlay">
      {React.cloneElement(Modal, {claimNumber, setData, setHasBeenClosed})}
    </ModalOverlayStyled>
  ) : (
    <div id="ModalOverlayClosed" />
  );
};
