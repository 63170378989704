import {atom, atomFamily} from 'recoil';

export interface GenerateAtomProps {
  uniqueId: string;
  defaultValue: any;
  effects?: Array<any>;
}

export class AtomFactory {
  static createAtom({uniqueId, defaultValue, effects}: GenerateAtomProps) {
    return atom({key: uniqueId, default: defaultValue, effects_UNSTABLE: effects});
  }

  static createAtomFamily({uniqueId, defaultValue, effects}: GenerateAtomProps) {
    return atomFamily({key: uniqueId, default: defaultValue, effects_UNSTABLE: effects});
  }
}
