import React from 'react';
import {Box, Typography, Grid} from '@material-ui/core';
import {BlueContainer} from '../../common/Containers/Containers';
import {SummaryField} from '../../common/Summary/components/SummaryField/SummaryField';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';
import {FixedPropertyIncident} from '../../common/Summary/types';
import {AddressSummary} from '../VehicleSummary/AddressSummary';
import {PdfSection} from '../../common/Summary/components/PdfSection/PdfSection';
import {BaseSwitch} from '../../common/BaseSwitch/BaseSwitch';
import {SUMMARY_PDF_ID} from '../../pdf/constants';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';

export interface PropertyDamageDetailsSummaryProps {
  fixedPropertyIncidents?: Array<FixedPropertyIncident>;
}

export const PropertyDamageDetailsSummary = (props: PropertyDamageDetailsSummaryProps) => {
  const propertyAndOwnerAddressesMatch = (incident: FixedPropertyIncident) => {
    if (incident.ownerAddressSameAsProperty) {
      return true;
    } else if (
      incident?.propertyAddress &&
      incident?.propertyOwner?.primaryAddress &&
      incident?.propertyAddress.addressLine1 ===
        incident.propertyOwner.primaryAddress.addressLine1 &&
      incident?.propertyAddress.city === incident.propertyOwner.primaryAddress.city &&
      incident?.propertyAddress.state === incident.propertyOwner.primaryAddress.state &&
      incident?.propertyAddress.postalCode === incident.propertyOwner.primaryAddress.postalCode
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box py={3}>
      <RegisterBreadcrumb
        waypointName={WaypointNames.PropertyDamageDetails}
        displayName={WAYPOINTS[WaypointNames.PropertyDamageDetails].displayName}
      >
        <BaseSwitch
          matches={(props?.fixedPropertyIncidents || []).length > 0}
          then={props?.fixedPropertyIncidents?.map(
            (fixedPropertyIncident: FixedPropertyIncident, index: number) => (
              <PdfSection className={SUMMARY_PDF_ID}>
                <>
                  {index === 0 && (
                    <>
                      <SummaryHeader headerText="Property Damage Details" />
                      <Box pt={3}>
                        <SummaryField label="Were any buildings damaged during the incident?">
                          <Typography>Yes</Typography>
                        </SummaryField>
                      </Box>
                    </>
                  )}
                  <Box mt={3}>
                    <BlueContainer py={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <SummaryField label="Who is the owner of the property?">
                            <Typography>
                              {fixedPropertyIncident.propertyOwner.contactType}
                            </Typography>
                          </SummaryField>
                        </Grid>
                        {fixedPropertyIncident?.propertyOwner?.displayName && (
                          <Grid item xs={12} sm={6} md={4}>
                            <SummaryField label="Name">
                              <Typography>
                                {fixedPropertyIncident?.propertyOwner?.displayName}
                              </Typography>
                            </SummaryField>
                          </Grid>
                        )}
                        {fixedPropertyIncident?.propertyOwner?.cellNumber && (
                          <Grid item xs={12} sm={6} md={4}>
                            <SummaryField label="Mobile Number">
                              <Typography>
                                {fixedPropertyIncident.propertyOwner.cellNumber}
                              </Typography>
                            </SummaryField>
                          </Grid>
                        )}
                        {fixedPropertyIncident?.propertyOwner?.workNumber && (
                          <Grid item xs={12} sm={6} md={4}>
                            <SummaryField label="Work Number">
                              <Typography>
                                {fixedPropertyIncident.propertyOwner.workNumber}
                              </Typography>
                            </SummaryField>
                          </Grid>
                        )}
                        {fixedPropertyIncident?.propertyOwner?.homeNumber && (
                          <Grid item xs={12} sm={6} md={4}>
                            <SummaryField label="Home Number">
                              <Typography>
                                {fixedPropertyIncident.propertyOwner.homeNumber}
                              </Typography>
                            </SummaryField>
                          </Grid>
                        )}
                        {fixedPropertyIncident?.propertyOwner?.emailAddress1 && (
                          <Grid item xs={12} sm={6} md={4}>
                            <SummaryField label="Email">
                              <Typography>
                                {fixedPropertyIncident?.propertyOwner?.emailAddress1}
                              </Typography>
                            </SummaryField>
                          </Grid>
                        )}

                        {fixedPropertyIncident?.propertyAddress && (
                          <AddressSummary
                            label="Property Address"
                            addressLine1={fixedPropertyIncident?.propertyAddress.addressLine1}
                            city={fixedPropertyIncident?.propertyAddress.city}
                            state={fixedPropertyIncident?.propertyAddress.state}
                            postalCode={fixedPropertyIncident?.propertyAddress.postalCode}
                          />
                        )}

                        {fixedPropertyIncident?.propertyTypeSelection && (
                          <Grid item xs={12} sm={6} md={4}>
                            <SummaryField label="Property Type">
                              <Typography>
                                {fixedPropertyIncident?.propertyTypeSelection}
                              </Typography>
                            </SummaryField>
                          </Grid>
                        )}
                        {fixedPropertyIncident?.propertyTypeSelection?.toLowerCase() === 'other' &&
                          fixedPropertyIncident?.otherProperty && (
                            <Grid item xs={12} sm={6} md={4}>
                              <SummaryField label="Other Property Type">
                                <Typography>{fixedPropertyIncident?.otherProperty}</Typography>
                              </SummaryField>
                            </Grid>
                          )}

                        {fixedPropertyIncident?.propertyOwner?.primaryAddress && (
                          <>
                            {propertyAndOwnerAddressesMatch(fixedPropertyIncident) ? (
                              <Grid item xs={12} sm={6} md={4}>
                                <SummaryField label="Owner Address">
                                  <Typography>Same as property address</Typography>
                                </SummaryField>
                              </Grid>
                            ) : (
                              <AddressSummary
                                label="Owner Address"
                                addressLine1={
                                  fixedPropertyIncident?.propertyOwner?.primaryAddress.addressLine1
                                }
                                city={fixedPropertyIncident?.propertyOwner?.primaryAddress.city}
                                state={fixedPropertyIncident?.propertyOwner?.primaryAddress.state}
                                postalCode={
                                  fixedPropertyIncident?.propertyOwner?.primaryAddress.postalCode
                                }
                              />
                            )}
                          </>
                        )}

                        {fixedPropertyIncident?.damageDescription && (
                          <Grid item xs={12}>
                            <SummaryField label="Damage Description">
                              <Typography>{fixedPropertyIncident.damageDescription}</Typography>
                            </SummaryField>
                          </Grid>
                        )}
                        {fixedPropertyIncident?.extDamagetxt && (
                          <Grid item xs={12}>
                            <SummaryField label="Damage Description">
                              <Typography>{fixedPropertyIncident.extDamagetxt}</Typography>
                            </SummaryField>
                          </Grid>
                        )}
                      </Grid>
                    </BlueContainer>
                  </Box>
                </>
              </PdfSection>
            )
          )}
          otherwise={
            <PdfSection className={SUMMARY_PDF_ID}>
              <SummaryHeader headerText="Property Damage Details" />
              <Box pt={3}>
                <SummaryField label="Were any buildings damaged during the incident?">
                  <Typography>No</Typography>
                </SummaryField>
              </Box>
            </PdfSection>
          }
        />
      </RegisterBreadcrumb>
    </Box>
  );
};
