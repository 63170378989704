import {FormControlLabel, makeStyles, RadioGroup, FormHelperText, Box} from '@material-ui/core';
import React, {ChangeEvent} from 'react';
import {IncidentSwitch} from '../../auto/IncidentDetails/IncidentSwitch/IncidentSwitch';
import {SAController} from '../Controller/Controller';
import {SARadio} from '../Radio/Radio';

const useStyles = makeStyles({
  root: {
    marginRight: '45px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  radioButton: {
    padding: '9px',
    bottom: '9px',
  },
});

export interface RadioOption {
  label: string;
  value: string;
  testId?: string;
  personas?: string[];
}

interface DynamicRadioListProps {
  name: string;
  options: RadioOption[];
  defaultValue?: string;
  onChange?: (value: string) => void;
  row?: boolean;
  hasErrors?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  width?: string | number;
  mobileWidth?: string | number;
  rootClassName?: string | undefined;
}

export const DynamicRadioList = (props: DynamicRadioListProps) => {
  const {
    name,
    options,
    defaultValue,
    onChange,
    row,
    hasErrors,
    errorMessage,
    width,
    mobileWidth,
    rootClassName,
  } = props;

  const classes = useStyles();

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedRadioValue = e.target.value;
    onChange && onChange(selectedRadioValue);
  };

  return (
    <Box width={{xs: mobileWidth, sm: width}}>
      <SAController defaultValue={defaultValue || null} name={name} onChange={onChangeHandler}>
        <RadioGroup row={row}>
          {options.map((option, index) => {
            return (
              <IncidentSwitch
                key={`${option.label}-${option.value}`}
                incidentOption={option}
                then={
                  <FormControlLabel
                    value={option.value}
                    control={<SARadio classes={{root: classes.radioButton}} />}
                    label={option.label}
                    data-testid={option.testId}
                    classes={{
                      root: rootClassName ? `${classes.root} ${rootClassName}` : classes.root,
                    }}
                  />
                }
              />
            );
          })}
        </RadioGroup>
      </SAController>
      {hasErrors && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Box>
  );
};
