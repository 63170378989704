import React from 'react';
import {Box, Typography, Grid} from '@material-ui/core';
import {SummaryField} from '../../common/Summary/components/SummaryField/SummaryField';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';
import {GreyBorderedBox} from '../../common/Summary/components/GreyBorderedBox/GreyBorderedBox';
import {InsuredContact} from '../../common/Summary/types';
import {formatPhone} from '../../../utils/utils';
import {mapPhoneNumberDetails} from '../../../utils/dataMapper';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';

export interface InsuredContactSummaryProps {
  insuredInformation?: InsuredContact;
}

export const InsuredContactSummary = (props: InsuredContactSummaryProps) => {
  const phoneNumbers = props?.insuredInformation?.phoneNumbers;
  const {cellNumber, workNumber, homeNumber} = mapPhoneNumberDetails(phoneNumbers);
  return (
    <GreyBorderedBox>
      <RegisterBreadcrumb
        waypointName={WaypointNames.InsuredContactDetails}
        displayName={WAYPOINTS[WaypointNames.InsuredContactDetails].displayName}
      >
        <Box>
          <SummaryHeader
            headerText="Insured Contact Details"
            data-testid="insuredContactSummaryHeader"
          />

          <Box pt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <SummaryField label="Name">
                  <Typography data-testid="insuredNameValue">
                    {props?.insuredInformation?.insuredName ||
                      `${props?.insuredInformation?.firstName} ${props?.insuredInformation?.lastName}`}
                  </Typography>
                </SummaryField>
              </Grid>

              {cellNumber && (
                <Grid item xs={12} sm={6} md={4}>
                  <SummaryField label="Mobile Number" testId="insured-contact-cell-number">
                    <Typography data-testid="insured-contact-cell-number-value">
                      {formatPhone(cellNumber)}
                    </Typography>
                  </SummaryField>
                </Grid>
              )}
              {workNumber && (
                <Grid item xs={12} sm={6} md={4}>
                  <SummaryField label="Work Number" testId="insured-contact-work-number">
                    <Typography data-testid="insured-contact-work-number-value">
                      {formatPhone(workNumber)}
                    </Typography>
                  </SummaryField>
                </Grid>
              )}
              {homeNumber && (
                <Grid item xs={12} sm={6} md={4}>
                  <SummaryField label="Home Number" testId="insured-contact-home-number">
                    <Typography data-testid="insured-contact-home-number-value">
                      {formatPhone(homeNumber)}
                    </Typography>
                  </SummaryField>
                </Grid>
              )}

              {props?.insuredInformation?.email && (
                <Grid item xs={12} sm={6} md={4}>
                  <SummaryField label="Email">
                    <Typography data-testid="insuredEmailValue">
                      {props?.insuredInformation?.email}
                    </Typography>
                  </SummaryField>
                </Grid>
              )}

              {props?.insuredInformation?.relationshipToInsured && (
                <Grid item xs={12} sm={6} md={4}>
                  <SummaryField label="Relationship to Insured">
                    <Typography data-testid="insuredRelationshipValue">
                      {props?.insuredInformation?.relationshipToInsured}
                    </Typography>
                  </SummaryField>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </RegisterBreadcrumb>
    </GreyBorderedBox>
  );
};
