import React from 'react';

export const InfoBubble = () => (
  <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Alert/Variant5">
      <path
        id="Vector (Stroke)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
        fill="#189ECC"
      />
      <path
        id="Vector (Stroke)_2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.01 8C13.01 8.55228 12.5623 9 12.01 9L12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7L12.01 7C12.5623 7 13.01 7.44772 13.01 8Z"
        fill="#189ECC"
      />
      <path
        id="Vector (Stroke)_3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 17.5C11.4477 17.5 11 17.0523 11 16.5L11 11.5C11 10.9477 11.4477 10.5 12 10.5C12.5523 10.5 13 10.9477 13 11.5L13 16.5C13 17.0523 12.5523 17.5 12 17.5Z"
        fill="#189ECC"
      />
    </g>
  </svg>
);
