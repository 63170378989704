import React from 'react';

export const ErrorIcon = () => (
  <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.97612 4.61612C10.2105 4.3817 10.5285 4.25 10.86 4.25H19.14C19.4715 4.25 19.7895 4.3817 20.0239 4.61612L25.8839 10.4761C26.1183 10.7105 26.25 11.0285 26.25 11.36V19.64C26.25 19.9715 26.1183 20.2895 25.8839 20.5239L20.0239 26.3839C19.7895 26.6183 19.4715 26.75 19.14 26.75H10.86C10.5285 26.75 10.2105 26.6183 9.97612 26.3839L4.11612 20.5239C3.8817 20.2895 3.75 19.9715 3.75 19.64V11.36C3.75 11.0285 3.8817 10.7105 4.11612 10.4761L9.97612 4.61612ZM11.3778 6.75L6.25 11.8778V19.1222L11.3778 24.25H18.6222L23.75 19.1222V11.8778L18.6222 6.75H11.3778ZM19.6339 10.8661C20.122 11.3543 20.122 12.1457 19.6339 12.6339L16.7678 15.5L19.6339 18.3661C20.122 18.8543 20.122 19.6457 19.6339 20.1339C19.1457 20.622 18.3543 20.622 17.8661 20.1339L15 17.2678L12.1339 20.1339C11.6457 20.622 10.8543 20.622 10.3661 20.1339C9.87796 19.6457 9.87796 18.8543 10.3661 18.3661L13.2322 15.5L10.3661 12.6339C9.87796 12.1457 9.87796 11.3543 10.3661 10.8661C10.8543 10.378 11.6457 10.378 12.1339 10.8661L15 13.7322L17.8661 10.8661C18.3543 10.378 19.1457 10.378 19.6339 10.8661Z"
      fill="#B00020"
    />
  </svg>
);
