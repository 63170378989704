/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export default {
  aws_project_region: `${process.env.REACT_APP_COGNITO_REGION}`,
  aws_cognito_region: `${process.env.REACT_APP_COGNITO_REGION}`,
  aws_user_pools_id: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
  aws_user_pools_web_client_id: `${process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID}`,
  oauth: {
    domain: `${process.env.REACT_APP_COGNITO_DOMAIN}`,
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${process.env.REACT_APP_COGNITO_REDIRECT}`,
    redirectSignOut: `${process.env.REACT_APP_COGNITO_REDIRECT}`,
    responseType: 'code',
  },
  federationTarget: `${process.env.REACT_APP_COGNITO_FEDERATION_TARGET}`,
};
