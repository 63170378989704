import React from 'react';

export const SpeechBubble = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.15088 2.79263C8.18841 2.26857 9.33505 1.99699 10.4974 2.00003L10.5 2.00003H10.9721C12.8006 2.10757 14.5264 2.88235 15.822 4.17797C17.1178 5.4737 17.8926 7.19975 18 9.02843V9.5L18 9.50261C18.003 10.665 17.7315 11.8116 17.2074 12.8491L17.2055 12.8529C16.5829 14.0986 15.6259 15.1463 14.4415 15.8788C13.2571 16.6112 11.8922 16.9995 10.4996 17C10.496 17 10.4923 17 10.4886 17C9.32928 17.0017 8.1858 16.7302 7.15088 16.2074C6.91289 16.0872 6.63674 16.067 6.3838 16.1513L2.58117 17.4189L3.84871 13.6163C3.93303 13.3633 3.91284 13.0872 3.79263 12.8492C3.26857 11.8116 2.99699 10.665 3.00002 9.50264L3.00003 9.50042C3.00057 8.10785 3.3888 6.74293 4.12125 5.55855C4.8537 4.37416 5.90143 3.41709 7.14709 2.79453L7.14709 2.79454L7.15088 2.79263ZM10.5084 19C10.5065 19 10.5046 19 10.5026 19C9.16756 19.0035 7.84881 18.722 6.63398 18.1761L1.31625 19.9487C0.95692 20.0685 0.560753 19.975 0.29292 19.7071C0.025088 19.4393 -0.0684344 19.0431 0.0513438 18.6838L1.82392 13.3661C1.27825 12.1516 0.996711 10.8332 1.00003 9.49857C1.00091 7.73502 1.49266 6.00652 2.42024 4.50661C3.34764 3.00699 4.67407 1.79507 6.25107 1.00647C7.56688 0.34223 9.02068 -0.00256393 10.4946 1.43539e-05C10.4964 4.78792e-06 10.4982 0 10.5 0H11C11.0184 0 11.0367 0.000506282 11.0551 0.00151837M11.0553 0.00152892C13.3854 0.130124 15.5861 1.11363 17.2362 2.76375C18.8864 4.41392 19.8699 6.61477 19.9985 8.94491C19.9995 8.96326 20 8.98163 20 9V9.4989C20.0036 10.9751 19.6588 12.4312 18.9935 13.749C18.2049 15.326 16.993 16.6524 15.4934 17.5798C13.9955 18.5061 12.2695 18.9978 10.5084 19"
      fill="#0173C6"
    />
  </svg>
);
