import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#041E41',
  },
}));

export interface SummaryFieldProps {
  label?: string | undefined;
  children: JSX.Element;
  testId?: string | undefined;
}

export const SummaryField = (props: SummaryFieldProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography data-testid={props.testId} className={classes.label}>
        {props.label}
      </Typography>
      {props.children}
    </>
  );
};
