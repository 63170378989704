import React from 'react';
import {AppBar, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import {SAAlert, SABox, SALogo, SAText} from '@saux/design-system-react';
import {useFeatureFlags} from '../Providers/Providers';
import styled from 'styled-components';
import {SALogos} from '@saux/design-system-react/logo/logo.options';
import {BaseSwitch} from '../BaseSwitch/BaseSwitch';

const useStyles = makeStyles(() => ({
  headerStyles: {
    '& .sa-alert-root': {
      borderRadius: '0px',
    },
  },
}));

const SABoxContainer = styled(SABox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    justify-content: flex-start;
  }
`;

export const SAHeader = () => {
  const {featureFlags} = useFeatureFlags();
  const classes = useStyles();
  const claimsOutage = featureFlags?.FF_DCX_2688;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <AppBar position="static" className={classes.headerStyles}>
      {claimsOutage && (
        <SAAlert severity="warning" color="warning">
          <SAText
            type="standard"
            text="Unfortunately, due to service maintenance, submitting and managing a claim online is currently unavailable. Please try back in 24 hrs to report your claim."
          ></SAText>
        </SAAlert>
      )}
      <SABoxContainer>
        <SABox p={'medium'}>
          <BaseSwitch
            matches={isMobile}
            then={<SALogo name={SALogos.stateAutoLogoBugWhite} />}
            otherwise={<SALogo name={SALogos.stateAutoLogoWhite} />}
          />
        </SABox>
        <SABox pl={'medium'} pr={'medium'}>
          <SAText type="heading-1" colorVariant="primary-foreground">
            Submit a Claim
          </SAText>
        </SABox>
      </SABoxContainer>
    </AppBar>
  );
};
