interface TrackEventProps {
  category: string;
  label: string;
}

export const trackEvent = (props: TrackEventProps) => {
  if (window && (window as any).dataLayer) {
    (window as any).dataLayer.push({
      event: 'trackEvent',
      eventCategory: props.category,
      eventAction: props.label,
      eventLabel: props.label,
    });
  }
};
