import React from 'react';
import {Maintenance} from '../components/Maintenance/Maintenance';
import {Container} from '@material-ui/core';
import {BackButtonBehavior} from '../components/common/BackButtonBehavior/BackButtonBehavior';
import {ROOT_ROUTE} from '../routes/paths';

export const ClaimsCenterMaintenancePage = () => {
  return (
    <BackButtonBehavior route={ROOT_ROUTE}>
      <Container>
        <Maintenance />
      </Container>
    </BackButtonBehavior>
  );
};
