import {useLocation} from '@reach/router';
import React from 'react';
import {AutoIntegrationFlags} from '../commonTypes';
import {BackButtonBehavior} from '../components/common/BackButtonBehavior/BackButtonBehavior';
import {CopartForm} from '../components/common/Copart/CopartForm/CopartForm';
import {VehicleLocationTypes} from '../components/common/Copart/types';
import {StateProps, VehicleIncident} from '../components/common/Summary/types';
import {ROOT_ROUTE} from '../routes/paths';
import {filterVehiclesByEligibility, getVehicleIncidents} from '../utils/utils';

export const CopartPage = () => {
  const location = useLocation();
  const successState = location.state as StateProps;
  const vehicleIncidents = filterVehiclesByEligibility(
    getVehicleIncidents(successState),
    AutoIntegrationFlags.CopartEligible
  ).map((incident: VehicleIncident) => {
    incident.inPossession = !incident.hasOwnProperty('copartAlternateContact');
    incident.locationType = incident.copartAlternateContact?.subtype || VehicleLocationTypes.Person;

    return incident;
  });

  return (
    <BackButtonBehavior shouldDisplayAlert route={ROOT_ROUTE}>
      <CopartForm vehicleIncidents={vehicleIncidents} successState={successState} />
    </BackButtonBehavior>
  );
};
