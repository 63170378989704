import {Typography} from '@material-ui/core';
import {SABox, SAColumns} from '@saux/design-system-react';
import React, {useContext, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useUserAtomState} from '../../../atoms';
import {
  AGENT_AND_ASSOCIATE_PERSONAS,
  CustomerTypes,
  THIRD_PARTY_PERSONAS,
} from '../../../commonTypes';
import {makePersonaCheck, personaSchemaSwitch} from '../../../utils/utils';
import {BaseSwitch} from '../../common/BaseSwitch/BaseSwitch';
import {ContactInfo} from '../../common/ContactInfo/ContactInfo';
import {ContactSections, FnolFormContact} from '../../common/ContactInfo/types';
import {SelectTriage} from '../../common/SelectTriage/SelectTriage';
import {SATextField} from '../../common/TextField/TextField';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {YesNoUnk} from '../../common/YesNoUnk/YesNoUnk';
import {
  VehicleContactKeys,
  VehicleDetailsContext,
  VehicleTypes,
} from '../YourVehicleDetails/YourVehicleDetails';
import * as yup from 'yup';
import {
  cityValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  OptionalPhoneNumbersSchema,
  stateValidation,
  streetAddressValidation,
  zipCodeValidation,
} from '../../../validations';

const noDriverValue = 'nodriver';
const unknownValue = 'unknown';

const VehicleDriverSchema = {
  driverId: yup.string().required('Please select the vehicle driver'),
  driverSameAsOwner: yup.string(),
  firstName: yup.string().when('driverId', {
    is: (value: string) => ![noDriverValue, unknownValue].includes(value),
    then: firstNameValidation().required('First Name is required'),
  }),
  lastName: yup.string().when('driverId', {
    is: (value: string) => ![noDriverValue, unknownValue].includes(value),
    then: lastNameValidation().required('Last Name is required'),
  }),
  insuranceProvider: yup.string(),
  policyOrClaimNumber: yup.string(),
  autocompleteAddress: yup.string().nullable(),
  manualAddressEntry: yup.string().nullable(),
  address: streetAddressValidation().nullable(),
  city: cityValidation(true),
  state: stateValidation().nullable(),
  zipcode: zipCodeValidation(true),
  email: emailValidation(true),
  ...OptionalPhoneNumbersSchema,
};

export const YourVehicleDriverSchema = {
  notDriver: yup.object().shape({
    ...VehicleDriverSchema,
    wereYouInjured: personaSchemaSwitch({
      is: (value: CustomerTypes) => makePersonaCheck(value, AGENT_AND_ASSOCIATE_PERSONAS),
      then: yup.string().nullable(),
      otherwise: yup.string().when('driverId', {
        is: (value: string) => value !== noDriverValue,
        then: yup.string().required('Please select if the driver was injured').nullable(),
        otherwise: yup.string().nullable(),
      }),
    }),
  }),
};

export const OtherVehicleDriverSchema = {
  notDriver: yup.object().shape({
    ...VehicleDriverSchema,
    wereYouInjured: yup.string().nullable(),
    injuryLevel: personaSchemaSwitch({
      is: (value: CustomerTypes) => makePersonaCheck(value, [CustomerTypes.Associate]),
      then: yup.string().when('wereYouInjured', {
        is: (wereYouInjured: string) => wereYouInjured === 'yes',
        then: yup.string().required('Please select an injury level'),
        otherwise: yup.string().nullable(),
      }),
      otherwise: yup.string().nullable(),
    }),
  }),
};

export const WereYouTheDriverTestSchema = yup.object().shape({
  otherVehicles: yup.array().of(
    yup.object().shape({
      ...OtherVehicleDriverSchema,
    })
  ),
  yourVehicles: yup.array().of(
    yup.object().shape({
      ...YourVehicleDriverSchema,
    })
  ),
});

interface WereYouTheDriverProps {
  ownerId: string;
}

export const WereYouTheDriver = ({ownerId}: WereYouTheDriverProps) => {
  const {register, errors} = useFormContext();
  const {fieldIndex, parentFieldName, ownerType, setVehicleContact, removeVehicleContact} =
    useContext(VehicleDetailsContext);
  const [driverId, setDriverId] = useState<string>('');
  const [driverSameAsOwner, setDriverSameAsOwner] = useState<string>('');
  const [showInsuranceFields, setShowInsuranceFields] = useState<boolean>(false);
  const [showInjured, setShowInjured] = useState<boolean>(false);
  const [injuredValue, setInjuredValue] = useState<string | undefined>();
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType;
  const isAgentOrAssociate = makePersonaCheck(
    userPersona as CustomerTypes,
    AGENT_AND_ASSOCIATE_PERSONAS
  );
  const isYourVehicle = ownerType === VehicleTypes.OWNER;
  const isAgentOrAssociateOrOtherVehicle = isAgentOrAssociate || !isYourVehicle;
  const noDriverOption = {value: noDriverValue, label: 'No driver', key: noDriverValue};
  const additionalOptions = isAgentOrAssociateOrOtherVehicle
    ? [noDriverOption, {value: unknownValue, label: 'Unknown driver', key: unknownValue}]
    : [noDriverOption];

  const handleDriverChange = (formContact: FnolFormContact, value?: string) => {
    const id = formContact?.fnolId || '';

    if (id) {
      setVehicleContact && setVehicleContact(VehicleContactKeys.Driver, id);

      const isThirdPartyUser = makePersonaCheck(userPersona as CustomerTypes, THIRD_PARTY_PERSONAS);

      setShowInsuranceFields(
        !isYourVehicle ||
          isThirdPartyUser ||
          (!isThirdPartyUser && isYourVehicle && !formContact.policyInfo)
      );
    } else {
      if (driverId) {
        removeVehicleContact && removeVehicleContact(VehicleContactKeys.Driver);
      }

      setShowInsuranceFields(false);
    }

    setDriverId(id);
    setShowInjured(!!(value && value !== noDriverValue));
    setInjuredValue(undefined);
  };

  const handleInjuredChange = (value: string) => {
    setInjuredValue(value);
  };

  useEffect(() => {
    setDriverSameAsOwner(driverId && ownerId && driverId === ownerId ? 'yes' : 'no');
  }, [ownerId, driverId]);

  return (
    <SABox pb="medium">
      <input
        type="hidden"
        value={driverSameAsOwner}
        name={`${parentFieldName}[${fieldIndex}].notDriver.driverSameAsOwner`}
        ref={register()}
        data-testid="hiddenDriverSameAsOwner"
      />
      <SABox>
        <Typography variant="body1">Who was driving?</Typography>
      </SABox>
      <SABox pt="medium">
        <ContactInfo
          section={
            isYourVehicle
              ? ContactSections.InsuredVehicleDriver
              : ContactSections.OtherVehicleDriver
          }
          parentFieldName={`${parentFieldName}[${fieldIndex}].notDriver`}
          selectProps={{
            name: 'driverId',
            label: 'Vehicle Driver',
            id: `${parentFieldName}-${(fieldIndex || 0) + 1}-driverId`,
            inputProps: {
              'aria-label': `${parentFieldName}-${(fieldIndex || 0) + 1}-Vehicle Driver`,
            },
          }}
          errorsObj={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notDriver}
          onContactChange={handleDriverChange}
          hiddenFields={{
            email: {render: true},
            phoneNumbers: {render: true},
            address: {render: true},
            city: {render: true},
            state: {render: true},
            zipCode: {render: true, name: 'zipcode'},
          }}
          additionalOptions={additionalOptions}
        />
      </SABox>
      {showInsuranceFields && driverSameAsOwner !== 'yes' && (
        <SABox pt="large">
          <SAColumns columns={{xs: [12, 12], sm: [6, 6], md: [4, 4]}} spacing="medium">
            <SATextField
              hasWhiteFields
              inputRef={register()}
              id="insurance-driver"
              label="Insurance Provider"
              name={`${parentFieldName}[${fieldIndex}].notDriver.insuranceProvider`}
              InputLabelProps={{
                'aria-labelledby': 'insurance-driver',
              }}
              error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notDriver?.hasOwnProperty(
                'insuranceProvider'
              )}
              helperText={
                errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notDriver?.insuranceProvider
                  ?.message || 'optional'
              }
            />
            <SATextField
              hasWhiteFields
              inputRef={register()}
              id="policy-number-driver"
              label="Policy/Claim Number"
              name={`${parentFieldName}[${fieldIndex}].notDriver.policyOrClaimNumber`}
              InputLabelProps={{
                'aria-labelledby': 'policy-number-driver',
              }}
              error={errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notDriver?.hasOwnProperty(
                'policyOrClaimNumber'
              )}
              helperText={
                errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notDriver?.policyOrClaimNumber
                  ?.message || 'optional'
              }
            />
          </SAColumns>
        </SABox>
      )}
      {showInjured && (
        <>
          <SABox pt="large">
            <Typography variant="body1">Was the driver injured?</Typography>
          </SABox>
          <SABox pt="medium">
            <BaseSwitch
              matches={isAgentOrAssociateOrOtherVehicle}
              then={
                <YesNoUnk
                  name={`${parentFieldName}[${fieldIndex}].notDriver.wereYouInjured`}
                  onChange={handleInjuredChange}
                  hasErrors={errors?.[parentFieldName || '']?.[
                    fieldIndex || 0
                  ]?.notDriver?.hasOwnProperty('wereYouInjured')}
                  errorMessage={
                    errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notDriver?.wereYouInjured
                      ?.message
                  }
                  defaultValue={injuredValue}
                  key={injuredValue}
                />
              }
              otherwise={
                <YesNoToggle
                  name={`${parentFieldName}[${fieldIndex}].notDriver.wereYouInjured`}
                  handleChange={handleInjuredChange}
                  hasError={errors?.[parentFieldName || '']?.[
                    fieldIndex || 0
                  ]?.notDriver?.hasOwnProperty('wereYouInjured')}
                  defaultValue={injuredValue}
                  errorMessage={
                    errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notDriver?.wereYouInjured
                      ?.message
                  }
                />
              }
            />
          </SABox>
        </>
      )}
      {injuredValue === 'yes' && !isYourVehicle && (
        <UserPersonaSwitch
          ifPersonas={[CustomerTypes.Associate]}
          then={
            <SABox pt="medium">
              <SAColumns columns={{xs: [12], sm: [6], md: [4]}}>
                <SelectTriage
                  label="Injury Level"
                  id="injuryLevel"
                  data-testid="injuryLevel"
                  name={`${parentFieldName}[${fieldIndex}].notDriver.injuryLevel`}
                  error={errors?.[parentFieldName || '']?.[
                    fieldIndex || 0
                  ]?.notDriver?.hasOwnProperty('injuryLevel')}
                  helperText={
                    errors?.[parentFieldName || '']?.[fieldIndex || 0]?.notDriver?.injuryLevel
                      ?.message
                  }
                  defaultValue=""
                  inputRef={register()}
                />
              </SAColumns>
            </SABox>
          }
        />
      )}
    </SABox>
  );
};
