import React from 'react';

export const Save = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 19H19V8.82843L15.1716 5H5V19ZM5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V8.41421C21 8.149 20.8946 7.89464 20.7071 7.70711L16.2929 3.29289C16.1054 3.10536 15.851 3 15.5858 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 15H9V21H7V14C7 13.4477 7.44772 13 8 13H16C16.5523 13 17 13.4477 17 14V21H15V15Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 3C7.55228 3 8 3.44772 8 4V7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H7C6.44772 9 6 8.55228 6 8V4C6 3.44772 6.44772 3 7 3Z"
      fill="white"
    />
  </svg>
);
