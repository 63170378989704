import {Box, makeStyles} from '@material-ui/core';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {BaseSwitch} from '../../BaseSwitch/BaseSwitch';
import {SADatePicker} from '../../DatePicker/DatePicker';
import {Icon} from '../../Icon/Icon';
import {PhoneInput} from '../../PhoneInput/PhoneInput';
import {ScriptBox} from '../../ScriptBox/ScriptBox';
import {SATextField} from '../../TextField/TextField';
import {YesNoToggle} from '../../YesNoToggle/YesNoToggle';
import {CopartComponentProps, VehicleLocationTypes, VehiclePreferences} from '../types';
import * as yup from 'yup';
import {phoneValidation} from '../../../../validations';

const useStyles = makeStyles((theme: any) => ({
  whiteTextField: {
    background: theme.palette.common.white,
  },
}));

export const PersonalBelongingsScriptSchema = {
  keysWithVehicle: yup
    .string()
    .when(['acceptCopart', '$inPossession', '$locationType', 'callSuccess'], {
      is: (
        acceptCopart: string,
        inPossession: boolean,
        locationType: string,
        callSuccess: string
      ) =>
        acceptCopart === 'yes' &&
        ((inPossession === false &&
          locationType !== VehicleLocationTypes.Person &&
          (callSuccess === 'successfulRelease' || callSuccess === 'waiverToRelease')) ||
          inPossession === true ||
          locationType === VehicleLocationTypes.Person),
      then: yup.string().required('Please select if the keys are with the vehicle'),
    }),
  keyContactName: yup.string().when('keysWithVehicle', {
    is: (value: string) => value === 'no',
    then: yup.string().required('Contact Name is required'),
  }),
  keyContactPhoneNumber: yup.string().when('keysWithVehicle', {
    is: (value: string) => value === 'no',
    then: phoneValidation(true).required('Contact Phone Number is required'),
  }),
  keyPickUpDate: yup.date().when('keysWithVehicle', {
    is: (value: string) => value === 'no',
    then: yup
      .date()
      .min(new Date(), 'The date cannot be a past date')
      .typeError('Must be a valid date')
      .isBusinessDay('The chosen date must be a business day')
      .required('Please select when the keys can be handed over'),
  }),
};

export const PersonalBelongingsScriptTestSchema = yup
  .object()
  .shape(PersonalBelongingsScriptSchema);

export const PersonalBelongingsScript = ({dispatch, incident}: CopartComponentProps) => {
  const {register, errors, setValue, trigger} = useFormContext();
  const [keysWithVehicle, setKeysWithVehicle] = useState<string>('');

  const handleChange = (value: string) => {
    setKeysWithVehicle(value);

    dispatch &&
      dispatch({
        type: VehiclePreferences.KeysWithVehicle,
        value,
        id: incident?.publicID,
      });
  };

  const onNameChange = (e: any) => {
    dispatch &&
      dispatch({
        type: VehiclePreferences.KeyContactName,
        value: e.target.value,
        id: incident?.publicID,
      });
  };

  const onPhoneChange = (e: any) => {
    dispatch &&
      dispatch({
        type: VehiclePreferences.KeyContactPhone,
        value: e.target.value,
        id: incident?.publicID,
      });
  };

  const [dateSelected, setDateSelected] = useState<Date | null>(null);

  const dateTimeChangeHandler = (date: Date | null, value?: string | null | undefined) => {
    if (date instanceof Date && date.toString() !== 'Invalid Date') {
      setValue(VehiclePreferences.KeyPickUpDate, date);
      setDateSelected(date);
      trigger(VehiclePreferences.KeyPickUpDate);

      dispatch &&
        dispatch({
          type: VehiclePreferences.KeyPickUpDate,
          value: date,
          id: incident?.publicID,
        });
    }
  };

  useEffect(() => {
    setKeysWithVehicle(incident?.copartPreferences.keysWithVehicle || '');
    setDateSelected(incident?.copartPreferences.pickUpDate || null);
  }, [incident?.publicID]);

  const classes = useStyles();

  return (
    <ScriptBox message="You will need to remove all of your personal belongings from the vehicle, take your license plate and leave all sets of keys with the car (discuss with customer on whether this has been completed).">
      <>
        <Box pt={3}>
          <i>"Are the keys currently with the vehicle?"</i>
        </Box>
        <Box pt={2}>
          <YesNoToggle
            handleChange={(value: string) => handleChange(value)}
            name={VehiclePreferences.KeysWithVehicle}
            defaultValue={incident?.copartPreferences.keysWithVehicle || ''}
            key={`${incident?.publicID}-${VehiclePreferences.KeysWithVehicle}`}
            hasError={errors?.hasOwnProperty(VehiclePreferences.KeysWithVehicle)}
            secondaryStyles
            errorMessage={errors?.[VehiclePreferences.KeysWithVehicle]?.message}
          />
        </Box>
        <BaseSwitch
          matches={keysWithVehicle === 'no'}
          then={
            <>
              <Box pt={4}>
                <i>"Who currently has possession of the keys?"</i>
              </Box>
              <Box pt={2} width={{md: '60%'}}>
                <SATextField
                  hasWhiteFields
                  id={VehiclePreferences.KeyContactName}
                  label="Contact Name"
                  inputRef={register}
                  name={VehiclePreferences.KeyContactName}
                  InputLabelProps={{
                    'aria-labelledby': VehiclePreferences.KeyContactName,
                  }}
                  defaultValue={incident?.copartPreferences.keyContactName || ''}
                  key={`${incident?.publicID}-${VehiclePreferences.KeyContactName}`}
                  onChange={onNameChange}
                  error={errors?.hasOwnProperty(VehiclePreferences.KeyContactName)}
                  helperText={errors?.[VehiclePreferences.KeyContactName]?.message || ''}
                />
              </Box>
              <Box pt={3} width={{md: '60%'}}>
                <PhoneInput
                  id={VehiclePreferences.KeyContactPhone}
                  label="Contact Phone Number"
                  inputRef={register}
                  name={VehiclePreferences.KeyContactPhone}
                  InputLabelProps={{
                    'aria-labelledby': VehiclePreferences.KeyContactPhone,
                  }}
                  defaultValue={(incident?.copartPreferences.keyContactPhoneNumber as string) || ''}
                  key={`${incident?.publicID}-${VehiclePreferences.KeyContactPhone}`}
                  onChange={onPhoneChange}
                  hasError={errors?.hasOwnProperty(VehiclePreferences.KeyContactPhone)}
                  helperText={errors?.[VehiclePreferences.KeyContactPhone]?.message || ''}
                />
              </Box>
              <Box pt={4}>
                <i>"When can the keys be handed over?"</i>
              </Box>
              <Box pt={2} width={{sm: '60%'}}>
                <SADatePicker
                  id={VehiclePreferences.KeyPickUpDate}
                  label="Date For Key Hand Over"
                  name={VehiclePreferences.KeyPickUpDate}
                  value={dateSelected || null}
                  InputLabelProps={{'aria-labelledby': VehiclePreferences.KeyPickUpDate}}
                  error={errors?.hasOwnProperty(VehiclePreferences.KeyPickUpDate)}
                  helperText={errors?.[VehiclePreferences.KeyPickUpDate]?.message || ''}
                  keyboardIcon={<Icon name="calendar" />}
                  onChange={dateTimeChangeHandler}
                  inputRef={register()}
                  key={`${incident?.publicID}-${VehiclePreferences.KeyPickUpDate}`}
                  shouldDisableDate={(day: MaterialUiPickersDate) =>
                    (day &&
                      (day.getDay() === 0 || day.getDay() === 6 || day.getTime() < Date.now())) ||
                    false
                  }
                  inputProps={{className: classes.whiteTextField}}
                />
              </Box>
            </>
          }
        />
      </>
    </ScriptBox>
  );
};
