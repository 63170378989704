import {Box} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {BaseSwitch} from '../../BaseSwitch/BaseSwitch';
import {DynamicRadioList} from '../../DynamicRadioList/DynamicRadioList';
import {SATextField} from '../../TextField/TextField';
import {
  CopartComponentProps,
  VehicleLocationTypes,
  VehiclePreferences,
  VEHICLE_LOCATION_TYPES,
} from '../types';
import {CallBusinessScript} from './CallBusinessScript';
import {PickUpContainer} from './PickUpContainer';
import * as yup from 'yup';

export const CallBusinessContainerSchema = {
  releaseNotes: yup.string().when(['acceptCopart', '$inPossession', '$locationType'], {
    is: (acceptCopart: string, inPossession: boolean, locationType: string) =>
      acceptCopart === 'yes' &&
      inPossession === false &&
      locationType !== VehicleLocationTypes.Person,
    then: yup.string().max(65000, 'Character limit exceeded').required('This field is required'),
  }),
  callSuccess: yup.string().when(['acceptCopart', '$inPossession', '$locationType'], {
    is: (acceptCopart: string, inPossession: boolean, locationType: string) =>
      acceptCopart === 'yes' &&
      inPossession === false &&
      locationType !== VehicleLocationTypes.Person,
    then: yup
      .string()
      .required('Please select if the call to the business was successful')
      .nullable(),
  }),
};

export const CallBusinessContainer = ({dispatch, incident}: CopartComponentProps) => {
  const [callSuccess, setCallSuccess] = useState<string>();
  const {register, errors} = useFormContext();

  const businessType =
    VEHICLE_LOCATION_TYPES[incident?.locationType || VehicleLocationTypes.Company];

  const successOptions = [
    {
      label: `Call to ${businessType} was successful and vehicle is released`,
      value: 'successfulRelease',
    },
    {
      label: `Call to ${businessType} was successful, insured has to sign a waiver to release vehicle`,
      value: 'waiverToRelease',
    },
  ];

  const options = [
    ...successOptions,
    {
      label: `Call to ${businessType} was unsuccessful and vehicle has not been released`,
      value: 'unsuccessful',
    },
  ];

  const onNotesChange = (e: any) => {
    dispatch &&
      dispatch({
        type: VehiclePreferences.ReleaseNotes,
        value: e.target.value,
        id: incident?.publicID,
      });
  };

  const onRadioChange = (value: any) => {
    setCallSuccess(value);

    dispatch &&
      dispatch({
        type: VehiclePreferences.CallSuccess,
        value,
        id: incident?.publicID,
      });
  };

  useEffect(() => {
    setCallSuccess(incident?.copartPreferences.callSuccess || '');
  }, [incident?.publicID]);

  return (
    <>
      <Box pt={3}>
        <CallBusinessScript businessType={businessType} />
      </Box>
      <Box pt={3} width={{md: '60%'}}>
        <SATextField
          hasWhiteFields
          id={VehiclePreferences.ReleaseNotes}
          label="Notes About Release of Vehicle"
          inputRef={register}
          name={VehiclePreferences.ReleaseNotes}
          characterLimit={65000}
          InputLabelProps={{
            'aria-labelledby': VehiclePreferences.ReleaseNotes,
          }}
          defaultValue={incident?.copartPreferences.releaseNotes || ''}
          key={`${incident?.publicID}-${VehiclePreferences.ReleaseNotes}`}
          onChange={onNotesChange}
          error={errors?.hasOwnProperty(VehiclePreferences.ReleaseNotes)}
          helperText={errors?.[VehiclePreferences.ReleaseNotes]?.message || ''}
        />
      </Box>
      <Box pt={4}>
        <DynamicRadioList
          options={options}
          name={VehiclePreferences.CallSuccess}
          onChange={onRadioChange}
          defaultValue={incident?.copartPreferences.callSuccess || ''}
          key={`${incident?.publicID}-${VehiclePreferences.CallSuccess}`}
          hasErrors={errors?.hasOwnProperty(VehiclePreferences.CallSuccess)}
          errorMessage={errors?.[VehiclePreferences.CallSuccess]?.message || ''}
        />
      </Box>
      <BaseSwitch
        matches={successOptions
          .map((option: {label: string; value: any}) => option.value)
          .includes(callSuccess)}
        then={<PickUpContainer dispatch={dispatch} incident={incident} />}
      />
    </>
  );
};
