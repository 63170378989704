import React, {useState} from 'react';
import {SATextField} from '../TextField/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export interface SAEmailProps {
  hasWhiteFields?: boolean;
  requiredError?: string | undefined;
  showCharacterCount?: boolean;
  characterLimit?: number;
  error?: boolean;
  helperText?: string;
  name?: string;
  inputRef?: any;
  autoComplete?: string;
  id: string;
  defaultValue?: string;
  label: string;
  key?: string;
  inputProps?: {};
  handleChangeValue?: Function;
  dataTestid?: string;
  InputLabelProps?: any;
}

export const SAEmail = (props: SAEmailProps) => {
  const [emailOptions, setEmailOptions] = useState<string[]>(['']);
  const handleChange = (event: any) => {
    if (props.handleChangeValue) {
      props?.handleChangeValue(event.target.value);
    }
    event.target.value && !event.target.value.includes('@')
      ? setEmailOptions([
          `${event.target.value}@gmail.com`,
          `${event.target.value}@yahoo.com`,
          `${event.target.value}@hotmail.com`,
          `${event.target.value}@aol.com`,
        ])
      : setEmailOptions([event.target.value]);
  };

  return (
    <Autocomplete
      freeSolo
      id={props.id}
      options={emailOptions}
      defaultValue={props.defaultValue}
      renderInput={params => (
        <SATextField
          {...params}
          hasWhiteFields={props.hasWhiteFields}
          name={props.name}
          onChange={(e: any) => handleChange(e)}
          inputRef={props.inputRef}
          label={props.label}
          InputLabelProps={props.InputLabelProps}
          margin="normal"
          variant="outlined"
          error={props.error}
          helperText={props.helperText}
          key={props?.key || ''}
          id={props.id}
          defaultValue={props.defaultValue}
          autoComplete={props.autoComplete}
          fullWidth
        />
      )}
    />
  );
};
