import React from 'react';

export const HomeIconWithOrangeHalo = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill="#FFEBCE" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 18C24 17.1716 24.6716 16.5 25.5 16.5C26.3284 16.5 27 17.1716 27 18V18.75H37.8116C38.5106 18.75 39.146 19.1557 39.4401 19.7898L43.3608 28.2439C43.5762 28.7084 43.5398 29.2507 43.2642 29.6823C42.9887 30.1138 42.512 30.375 42 30.375V40.5C42.8284 40.5 43.5 41.1716 43.5 42C43.5 42.8284 42.8284 43.5 42 43.5H30H24H18C17.1716 43.5 16.5 42.8284 16.5 42C16.5 41.1716 17.1716 40.5 18 40.5V30.375C17.488 30.375 17.0113 30.1138 16.7358 29.6823C16.4602 29.2507 16.4238 28.7084 16.6392 28.2439L20.5599 19.7898C20.854 19.1557 21.4894 18.75 22.1884 18.75H24V18ZM31.5 40.5H39V30.375H21V40.5H22.5V34.5C22.5 33.6716 23.1716 33 24 33H30C30.8284 33 31.5 33.6716 31.5 34.5V40.5ZM28.5 40.5V36H25.5V40.5H28.5ZM20.3491 27.375H39.6509L37.0422 21.75H22.9578L20.3491 27.375ZM34.5 33C33.6716 33 33 33.6716 33 34.5C33 35.3284 33.6716 36 34.5 36H36C36.8284 36 37.5 35.3284 37.5 34.5C37.5 33.6716 36.8284 33 36 33H34.5Z"
      fill="#CD5234"
    />
  </svg>
);
