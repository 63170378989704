import {
  FormControlLabel,
  makeStyles,
  FormControlLabelProps,
  CheckboxProps,
} from '@material-ui/core';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import {SACheckbox, SACheckboxProps} from '../Checkbox/Checkbox';

const useStyles = makeStyles(theme => ({
  label: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '0px 32px 0px 0px',
    alignItems: 'flex-start',
  },
  checkbox: {
    padding: '0px 9px 9px 0px',
  },
}));

interface CheckboxWithLabelProps {
  formControlLabelProps: Partial<FormControlLabelProps>;
  checkboxProps: CheckboxProps & SACheckboxProps;
  name?: string;
  testId?: string;
}

export const CheckboxWithLabel = ({
  formControlLabelProps,
  checkboxProps,
  name,
  testId,
}: CheckboxWithLabelProps) => {
  const classes = useStyles();
  const {register} = useFormContext();

  return (
    <FormControlLabel
      name={name}
      data-testid={testId}
      label={formControlLabelProps.label || ''}
      labelPlacement="end"
      classes={{
        root: classes.label,
      }}
      {...formControlLabelProps}
      style={{pointerEvents: checkboxProps.pointerEvent ? 'none' : 'auto'}}
      control={
        <SACheckbox {...checkboxProps} classes={{root: classes.checkbox}} inputRef={register()} />
      }
    />
  );
};
