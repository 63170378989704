import {SATable} from '@saux/design-system-react';
import React, {ReactElement} from 'react';
import {SATab} from '../../../common/SATab/SATab';
import {Coverage} from '../../../common/Summary/types';
import {useVehicleDetailsContext} from '../YourVehicleDetails';
import styled, {ThemeProvider} from 'styled-components';
import {SAUX360Theme, SABox} from '@saux/design-system-react';
import {useWatch} from 'react-hook-form';

export interface CoveragesTab {
  InsuredVehicleDetails: ReactElement;
}

export interface CoverageDetailsProps {
  coverages?: Coverage[];
}

export const CoverageDetails = ({coverages}: CoverageDetailsProps) => {
  const data = coverages?.map((coverage: Coverage) => {
    return [
      coverage.name,
      coverage.deductible?.amount ? `$${coverage.deductible?.amount}` : '-',
      coverage.incidentLimit?.amount ? `$${coverage.incidentLimit?.amount}` : '-',
    ];
  });

  return data ? (
    <SATable
      columns={['Coverage', 'Deductible', 'Limit']}
      data={data}
      variant="table-to-listview"
    />
  ) : null;
};

export enum CoverageTypes {
  Collision = 'Collision',
  Comprehensive = 'Comprehensive',
  Rental = 'Rental',
  PrimeOfLife = 'Prime of Life',
  EX671 = 'EX671',
  AU1022 = 'AU1022 (OEM)',
  MedicalPayments = 'Medical Payments',
  UnderOrUninsured = 'Under/Uninsured',
}

export const SECONDARY_COVERAGE_TYPES = [
  CoverageTypes.MedicalPayments,
  CoverageTypes.UnderOrUninsured,
];

const CoveragesTabContainer = styled(SABox)`
  position: relative;
  bottom: 45px;
`;

export const CoveragesTab = ({InsuredVehicleDetails}: CoveragesTab) => {
  const {policyVehicleCoverage, fieldIndex} = useVehicleDetailsContext();

  let main = policyVehicleCoverage?.filter(
    (pvc: Coverage) => !SECONDARY_COVERAGE_TYPES.includes(pvc.name as CoverageTypes)
  );
  let secondary = policyVehicleCoverage?.filter((pvc: Coverage) =>
    SECONDARY_COVERAGE_TYPES.includes(pvc.name as CoverageTypes)
  );

  const rideshareOrDelivery = useWatch({
    name: 'incidentDetails.rideshareOrDelivery',
    defaultValue: '',
  });

  const rideshare = useWatch({
    name: 'incidentDetails.rideshare',
    defaultValue: '',
  });

  const delivery = useWatch({
    name: 'incidentDetails.delivery',
    defaultValue: '',
  });

  let itemLabels = ['Insured Vehicle Details', 'Coverages'];
  let activeIndex = undefined;

  if (rideshareOrDelivery === 'yes') {
    itemLabels = ['Insured Vehicle Details'];
    main = [];
    secondary = [];
  }

  if (rideshare === 'yes' || delivery === 'yes') {
    itemLabels = ['Insured Vehicle Details'];
    main = [];
    secondary = [];
  }

  if (main?.length === 0) {
    main.push(...(['-'] as unknown as Coverage[]));
  }

  if (secondary?.length === 0) {
    secondary.push(...(['-'] as unknown as Coverage[]));
  }

  return (
    <ThemeProvider theme={SAUX360Theme}>
      <CoveragesTabContainer>
        <SATab
          dataTestId={`VehicleTab-${fieldIndex}`}
          itemLabels={itemLabels}
          variant="standard-tabbed"
          active={true}
          activeIndex={activeIndex}
          panels={[
            React.cloneElement(InsuredVehicleDetails),
            <SABox p={'medium'} mt={'medium'}>
              {main && secondary ? (
                <SATab
                  variant="standard-tagged"
                  active
                  itemLabels={['Main', 'Secondary']}
                  panels={[
                    <CoverageDetails coverages={main} />,
                    <CoverageDetails coverages={secondary} />,
                  ]}
                />
              ) : (
                <SABox>
                  <i>
                    There are no coverages to display. Please select an insured vehicle to view its
                    coverages.
                  </i>
                </SABox>
              )}
            </SABox>,
          ]}
        />
      </CoveragesTabContainer>
    </ThemeProvider>
  );
};
