import React from 'react';

interface Props {
  className?: string;
}

export const RightArrow = (props: Props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
  >
    <g
      id="Icons-/-Small-/-Abstract-/-Filled-/-Arrow-Right-Line"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Arrow-Right-Line-Glyph"
        transform="translate(0.000000, 6.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <polygon id="Path" points="13 -4.63974246e-15 20 4 13 8 12.999 5 0 5 0 3 13 3" />
      </g>
    </g>
  </svg>
);
