import React from 'react';
import {SelectProps} from '@material-ui/core';
import {SASelect} from '../Select/Select';

interface SelectTriageProps {
  helperText?: string;
}

export const SelectTriage = (props: SelectProps & SelectTriageProps) => {
  return (
    <SASelect
      {...props}
      id={props.id}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      native
      label={props.label}
      inputProps={{
        ...props.inputProps,
      }}
      inputRef={props.inputRef}
      error={props.error}
      value={props.value}
      defaultValue={props.defaultValue}
    >
      {props.children ? (
        props.children
      ) : (
        <>
          <option value={4}>Minor</option>
          <option value={5}>Moderate</option>
          <option value={6}>HELP</option>
        </>
      )}
    </SASelect>
  );
};
