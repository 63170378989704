import {Box} from '@material-ui/core';
import React, {ReactElement} from 'react';

export const GreyBorderedBox = (props: {
  children: ReactElement;
  hideBorderTop?: boolean | undefined;
}) => (
  <Box
    mt={props.hideBorderTop ? 0 : 3}
    py={3}
    borderColor="grey.200"
    borderTop={props?.hideBorderTop ? undefined : 1}
  >
    {props.children}
  </Box>
);
