import React, {useEffect} from 'react';
import {makeStyles, Box} from '@material-ui/core';
import {useBreadcrumbContext} from '../../../hooks';
import {WaypointNames} from '../RegisterBreadcrumb/RegisterBreadcrumb';
import {Icon} from '../../common/Icon/Icon';

import './sticky.css';
import {BreadcrumbButton} from '../BreadcrumbButton/BreadcrumbButton';

export interface StickyBreadcrumbsState {
  [waypointName: string]: {
    active: boolean;
  };
}

export interface StickyBreadcrumbsProps {
  breadcrumbs: WaypointNames[] | undefined;
}

export const onScroll = (
  breadCrumbs: HTMLElement | null,
  originalPosition: number,
  pageYOffset: number
) => {
  if (pageYOffset >= originalPosition) {
    if (breadCrumbs?.style) {
      breadCrumbs.style.position = 'fixed';
      breadCrumbs.style.top = '0';
    }
  } else {
    if (breadCrumbs?.style) {
      breadCrumbs.style.position = 'relative';
      breadCrumbs.style.top = `${originalPosition}`;
    }
  }
};

export const StickyBreadcrumbs = (props: StickyBreadcrumbsProps) => {
  const breadcrumbContext = useBreadcrumbContext();

  useEffect(() => {
    const breadCrumbs = document.getElementById('breadcrumbs');
    const originalPosition = breadCrumbs?.getBoundingClientRect().top || 0;

    window.addEventListener('scroll', () =>
      onScroll(breadCrumbs, originalPosition, window.pageYOffset)
    );
  }, []);

  const useStyles = makeStyles(theme => ({
    sticky: {
      display: 'flex',
      background: 'white',
      width: '100%',
      height: 48,
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.5)',
      zIndex: 2,
      [theme.breakpoints.down('sm')]: {
        overflowY: 'auto',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flex: 1,
      },
    },
    flexed: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'inherit',
    },
    buttonWidth: {
      textTransform: 'capitalize',
      height: 'inherit',
      [theme.breakpoints.down('sm')]: {
        minWidth: 135,
      },
    },
    space: {
      height: 20,
      width: 20,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 'inherit',
    },
  }));

  const classes = useStyles();

  return (
    <Box className={classes.sticky} id="breadcrumbs">
      {props?.breadcrumbs?.map((waypointName: string, index: number) => {
        const displayName = breadcrumbContext?.breadcrumbs?.[waypointName]?.displayName;
        const isDisabled = !displayName;
        const {flexed, buttonWidth, container} = classes;

        return (
          <>
            <Box className={container}>
              {index > 0 && (
                <Icon
                  name="rightArrow"
                  additionalProps={isDisabled ? {className: '--disabled'} : {}}
                />
              )}
              <Box key={waypointName} className={flexed}>
                <BreadcrumbButton buttonWidth={buttonWidth} waypointName={waypointName} />
              </Box>
            </Box>
          </>
        );
      })}
    </Box>
  );
};
