import {VehicleState} from '../components/common/CommercialAutoSelection/CommercialAutoSelection';

export enum CustomerTypes {
  SaCustomer = 'sacustomer',
  ThirdParty = 'thirdparty',
  Guardian = 'guardian',
  SaAgent = 'saagent',
  Associate = 'associate',
}

export interface CustomerTypesObj {
  [key: string]: string;
}

export const CUSTOMER_TYPES: CustomerTypesObj = {
  [CustomerTypes.SaCustomer]: 'I am a State Auto customer filing a claim',
  [CustomerTypes.ThirdParty]: 'I was involved in an incident with a State Auto customer',
  [CustomerTypes.Guardian]: 'I am filing a claim on behalf of someone else',
  [CustomerTypes.SaAgent]: 'I am a State Auto agent',
};

export const AGENT_AND_ASSOCIATE_PERSONAS = [CustomerTypes.SaAgent, CustomerTypes.Associate];
export const AGENT_AND_CUST_PERSONAS = [CustomerTypes.SaAgent, CustomerTypes.SaCustomer];
export const AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS = [
  CustomerTypes.SaAgent,
  CustomerTypes.Associate,
  CustomerTypes.SaCustomer,
];
export const CUST_AND_THIRD_PARTY = [CustomerTypes.SaCustomer, CustomerTypes.ThirdParty];
export const ASSOCIATE_AND_CUST_PERSONA = [CustomerTypes.Associate, CustomerTypes.SaCustomer];
export const THIRD_PARTY_PERSONAS = [CustomerTypes.Guardian, CustomerTypes.ThirdParty];
export const NOT_THIRD_PARTY = [
  CustomerTypes.SaAgent,
  CustomerTypes.SaCustomer,
  CustomerTypes.Guardian,
  CustomerTypes.Associate,
];
export const ALL_PERSONAS = [
  CustomerTypes.Guardian,
  CustomerTypes.SaAgent,
  CustomerTypes.SaCustomer,
  CustomerTypes.ThirdParty,
  CustomerTypes.Associate,
];

export enum Lobs {
  Auto = 'auto',
  Homeowners = 'homeowners',
  CommercialProperty = 'commercialproperty',
  GeneralLiability = 'general',
  WorkersCompensation = 'workerscomp',
  FarmAndRanch = 'farm',
}

export enum ContactRoles {
  Insured = 'insured',
  Agent = 'agent',
  ExcludedParty = 'excludedparty',
  CoveredParty = 'coveredparty',
  Lienholder = 'lienholder',
  PolicyContactExt = 'policycontact_ext',
  PropertyOwnerExt = 'propertyowner_ext',
  Reporter = 'reporter',
  MainContact = 'maincontact',
  IncidentOwner = 'incidentowner',
  Driver = 'driver',
}

export enum PhoneTypes {
  Mobile = 'mobile',
  Work = 'work',
  Home = 'home',
}

export const ALL_PHONE_TYPES = [PhoneTypes.Mobile, PhoneTypes.Work, PhoneTypes.Home];

export const PERSONAL_AUTO_TYPES = ['PersonalAuto', 'auto_per'];
export const COMMERCIAL_AUTO_TYPES = ['CA7CommAuto', 'auto_comm'];
export const PERSONAL_HOMEOWNERS_TYPES = ['dwellingper_Ext', 'Homeowners'];

export enum ContactTypes {
  Person = 'person',
  Company = 'company',
}

export interface UserData {
  policyNumber: string;
  effectiveDate: string;
  expirationDate: string;
  insured: string;
  address: string;
  policyType: string;
  city: string;
  state: string;
  zip: string;
  policyStatus: string;
  producerCode: string;
  formPath: Lobs;
  isVerified: boolean;
  policySymbol?: string;
  vehicles?: VehicleState[];
  sorting?: {
    number: number;
    street: string;
    type: string;
  };
}

export enum AutoIntegrationFlags {
  QuickEstimateEligible = 'quickEstimateEligible',
  StaffAppraiserEligible = 'staffAppraiserEligible',
  CopartEligible = 'copartAppraiserEligible',
  STPModelEligible = 'stpModelEligible',
}

export enum ClaimLobs {
  PersonalAuto = 'personalAuto',
  CommercialAuto = 'commercialAuto',
  Homeowners = 'homeowners',
}
