import {SAText} from '@saux/design-system-react';
import React from 'react';
import styled from 'styled-components';

export const SABlueText = styled(SAText)`
  color: #0173c6;
`;

export const ContactInfoHeader = () => {
  return <SABlueText type="standard" text="Contact Information:" />;
};
