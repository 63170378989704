import {useEffect, ReactElement} from 'react';
import {ROOT_ROUTE} from '../../../routes/paths';
import {useFeatureFlags} from '../Providers/Providers';
import {useUserAtomState} from '../../../atoms';
import {navigateDefaultReplace} from '../../../pages/utils';

export interface RefreshPageBehaviorProps {
  children: ReactElement;
}

export const RefreshPageBehavior = ({children}: RefreshPageBehaviorProps) => {
  const {featureFlags} = useFeatureFlags();
  const [userAtomState, _] = useUserAtomState();

  const onRefreshButtonEvent = (e: BeforeUnloadEvent) => {
    // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  useEffect(() => {
    if (featureFlags?.FF_DCX_2659) {
      if (userAtomState?.draftClaimResponse === undefined) {
        navigateDefaultReplace(ROOT_ROUTE);
      }

      window.addEventListener('beforeunload', onRefreshButtonEvent);
    }

    return () => {
      window.removeEventListener('beforeunload', onRefreshButtonEvent);
    };
  }, [featureFlags]);

  return children;
};
