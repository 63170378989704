import React, {useState} from 'react';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {useFormContext} from 'react-hook-form';
import {
  phoneValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
} from '../../../validations';
import * as yup from 'yup';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {personaSchemaSwitch} from '../../../utils/utils';
import {SASelect} from '../../common/Select/Select';
import {CustomerTypes} from '../../../commonTypes';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {ContactInfo} from '../../common/ContactInfo/ContactInfo';
import {ContactSections, FnolFormContact} from '../../common/ContactInfo/types';
import {SABox, SAColumns} from '@saux/design-system-react';

export interface InsuredContactInformationProps {
  defaultContactCriteria?: string;
  acceptableRoles?: string[];
}

const schema = yup.object().shape({
  contactID: yup.string().required('Please select an option').nullable(),
  firstName: firstNameValidation().required('First Name is required').nullable(),
  lastName: lastNameValidation().required('Last Name is required').nullable(),
  phoneNumbers: yup.array().of(
    yup.object().shape({
      phoneNumber: yup.lazy((item?: any, options?: any) => {
        if (options.path === 'insuredContactInformation.phoneNumbers[0].phoneNumber') {
          return phoneValidation(true).required('Phone Number is required');
        } else {
          return phoneValidation(true).nullable();
        }
      }),
      phoneType: yup.lazy((item?: any, options?: any) => {
        if (options.parent.phoneNumber !== '') {
          return yup.string().required('Phone Type is required');
        } else {
          return yup.string().nullable();
        }
      }),
    })
  ),
  email: emailValidation(true).nullable(),
  relationshipToInsured: personaSchemaSwitch({
    is: (persona: CustomerTypes) => persona === CustomerTypes.SaAgent,
    then: yup.string().nullable(),
  }),
});

export const InsuredContactInformationSchema = {
  insuredContactInformation: yup.object().when('$showInsuredContact', {
    is: true,
    then: schema,
  }),
};
export const InsuredContactInformationTestSchema = yup.object().shape({
  insuredContactInformation: schema,
});

const relationshipToInsuredList = ['Spouse', 'Child', 'Relative', 'Attorney', 'Parent', 'Other'];

export const InsuredContactInformation = () => {
  const {register, errors} = useFormContext();
  const [showRelationshipToInsured, setShowRelationshipToInsured] = useState<boolean>(false);

  const onInsuredContactChange = (formContact: FnolFormContact) => {
    if (formContact.fnolId) {
      setShowRelationshipToInsured(!formContact.policyInfo);
    } else {
      setShowRelationshipToInsured(false);
    }
  };

  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.InsuredContactInformation}
      displayName={WAYPOINTS[WaypointNames.InsuredContactInformation].displayName}
    >
      <FormContainer header="Insured Main Contact">
        <>
          <SABox pb="medium">
            <ContactInfo
              section={ContactSections.InsuredMainContact}
              parentFieldName="insuredContactInformation"
              selectProps={{
                name: 'contactID',
                label: 'Insured Name',
                id: 'contactID',
                inputProps: {'aria-label': 'Insured Name'},
              }}
              errorsObj={errors?.insuredContactInformation}
              onContactChange={onInsuredContactChange}
              hiddenFields={{
                email: {render: true},
                phoneNumbers: {render: true},
              }}
            />
          </SABox>
          {showRelationshipToInsured && (
            <UserPersonaSwitch
              ifPersonas={[CustomerTypes.SaAgent]}
              then={
                <SABox pb="medium">
                  <SAColumns columns={{xs: [12], sm: [6], md: [4]}}>
                    <SASelect
                      label="Relationship to Insured"
                      id="relationshipToInsured"
                      inputProps={{'aria-label': 'Relationship to Insured'}}
                      name="insuredContactInformation.relationshipToInsured"
                      inputRef={register}
                      key="relationshipToInsured"
                      error={errors?.insuredContactInformation?.hasOwnProperty(
                        'relationshipToInsured'
                      )}
                      helperText={errors?.insuredContactInformation?.relationshipToInsured?.message}
                    >
                      {relationshipToInsuredList.map(name => (
                        <option value={name}>{name}</option>
                      ))}
                    </SASelect>
                  </SAColumns>
                </SABox>
              }
            />
          )}
        </>
      </FormContainer>
    </RegisterBreadcrumb>
  );
};
