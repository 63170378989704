import React from 'react';
import {BlueContainer} from '../../common/Containers/Containers';
import {Box, Typography, Grid} from '@material-ui/core';
import {Contact} from '../../common/Summary/types';
import {formatPhone} from '../../../utils/utils';
import {SummaryField} from '../../common/Summary/components/SummaryField/SummaryField';
import {AddressSummary} from './AddressSummary';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';

export interface PassengerSummaryProps {
  passenger: Contact;
  index: number;
}

export const PassengerSummary = (props: PassengerSummaryProps) => {
  return (
    <BlueContainer>
      <Box pb={3}>
        <SummaryHeader
          forBlueContainer
          headerText={
            props.index > 0 ? `Passenger Details ${props.index + 1}` : 'Passenger Details'
          }
        />
      </Box>
      <Grid container spacing={3}>
        {props?.passenger?.displayName && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Name" testId="passengerSummaryNameLabel">
              <Typography data-testid="passengerSummaryNameValue">
                {props.passenger.displayName}
              </Typography>
            </SummaryField>
          </Grid>
        )}
        {props?.passenger?.primaryAddress && (
          <AddressSummary
            label="Address"
            addressLine1={props?.passenger?.primaryAddress.addressLine1}
            city={props?.passenger?.primaryAddress.city}
            state={props?.passenger?.primaryAddress.state}
            postalCode={props?.passenger?.primaryAddress.postalCode}
          />
        )}
        {props?.passenger?.emailAddress1 && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Email" testId="passengerSummaryEmailLabel">
              <Typography data-testid="passengerSummaryEmailValue">
                {props.passenger.emailAddress1}
              </Typography>
            </SummaryField>
          </Grid>
        )}
        {props?.passenger?.cellNumber && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Mobile Number" testId="passengerSummaryCellPhoneLabel">
              <Typography data-testid="passengerSummaryCellPhoneValue">
                {formatPhone(props.passenger.cellNumber)}
              </Typography>
            </SummaryField>
          </Grid>
        )}
        {props?.passenger?.workNumber && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Work Number" testId="passengerSummaryWorkPhoneLabel">
              <Typography data-testid="passengerSummaryWorkPhoneValue">
                {formatPhone(props.passenger.workNumber)}
              </Typography>
            </SummaryField>
          </Grid>
        )}
        {props?.passenger?.homeNumber && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Home Number" testId="passengerSummaryHomePhoneLabel">
              <Typography data-testid="passengerSummaryHomePhoneValue">
                {formatPhone(props.passenger.homeNumber)}
              </Typography>
            </SummaryField>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <SummaryField label="Injured" testId="passengerSummaryInjuredLabel">
            <Typography data-testid="passengerSummaryInjuredValue">
              {props?.passenger?.injured ? 'Yes' : 'No'}
            </Typography>
          </SummaryField>
        </Grid>
      </Grid>
    </BlueContainer>
  );
};
