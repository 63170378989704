import React, {ReactElement} from 'react';
import {Controller, useFormContext} from 'react-hook-form';

export interface SAControllerProps {
  name: string;
  defaultValue?: any;
  onChange?: any;
  onBlur?: any;
  children: ReactElement;
  rules?: Object;
  onFocus?: () => void;
}

export const SAController = ({
  name,
  defaultValue,
  children,
  onChange,
  onBlur,
  onFocus,
  rules,
}: SAControllerProps) => {
  const {control} = useFormContext();

  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      onFocus={onFocus}
      rules={rules}
      render={(props: any) =>
        React.cloneElement(children, {
          ...props,
          ...(onBlur
            ? {
                onBlur: (e: any, value: any) => {
                  onBlur(e, value);
                  props.onBlur(e, value);
                },
              }
            : {}),
          ...(onChange
            ? {
                onChange: (e: any, value: any) => {
                  onChange(e, value);
                  props.onChange(e, value);
                },
              }
            : {}),
        })
      }
    />
  );
};
