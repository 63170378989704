/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, ReactElement} from 'react';

export interface MemoizeProps {
  children: ReactElement;
  renderOn: Array<any>;
}

export const Memoize = (props: MemoizeProps) => {
  return useMemo(() => <>{props.children}</>, props.renderOn);
};
