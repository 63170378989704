import React from 'react';

export const UserIcon = () => (
  <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5257 25.6306V23.0272C21.5257 20.1515 19.1945 17.8203 16.3188 17.8203H7.20684C4.33118 17.8203 2 20.1515 2 23.0272V25.6306"
      stroke="#0173C6"
      strokeWidth="2.60342"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="12.4138"
      cy="7.20684"
      r="5.20684"
      stroke="#0173C6"
      strokeWidth="2.60342"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.0997 21.7241C25.8988 21.7241 27.7376 21.7241 29.336 21.7241C30.0549 21.7241 30.6377 21.1413 30.6377 20.4224V12.6122L26.7326 8.70703H21.5257C20.8068 8.70703 20.224 9.28983 20.224 10.0087C20.224 12.2567 20.224 13.9731 20.224 15.0971"
      stroke="#0173C6"
      strokeWidth="1.62714"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.7325 8.70605V12.6112H30.6376"
      stroke="#0173C6"
      strokeWidth="1.62714"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.0344 15.8674H22.8275"
      stroke="#0173C6"
      strokeWidth="1.62714"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.0344 18.4678H22.8275"
      stroke="#0173C6"
      strokeWidth="1.62714"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.1292 13.262H23.4783H22.8275"
      stroke="#0173C6"
      strokeWidth="1.62714"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0044 26.2692V21.7384L11.3633 20.6015L12.0044 20.4561L12.6456 20.6015L12.0044 21.7384L12.8758 25.8857L12.0044 26.7188L11.1169 25.8857L12.0044 21.7384"
      stroke="#0173C6"
      strokeWidth="1.30171"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
