import {Box, makeStyles, Grid, Theme} from '@material-ui/core';
import React, {MouseEventHandler} from 'react';
import {Icon} from '../../common/Icon/Icon';
import {UserData} from '../../../commonTypes';
import {nullToEmpty} from '../../../utils/utils';

interface StyleProps {
  selected: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  root: {
    color: 'black',
    backgroundColor: ({selected}) => (selected ? 'white' : '#f4f6fa'),
    borderBottom: '1px solid #dcdde1',
    borderLeft: ({selected}) => (selected ? '5px solid #9424b4' : '5px solid #f4f6fa'),
    '&:hover': {
      borderLeft: '5px solid #9424b4',
    },
  },
  alignIcon: {
    marginTop: '0px',
  },
});

interface PolicySearchResultProps {
  policy?: UserData;
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
}

export const PolicySearchResult = ({policy, selected, onClick}: PolicySearchResultProps) => {
  const classes = useStyles({selected: selected} as StyleProps);

  const POLICY_TYPE_DISPLAY_VALUE: {[key: string]: string} = {
    auto: 'Auto Policy',
    homeowners: 'Home Policy',
  };

  const POLICY_TYPE_MATCHING_ICON: {[key: string]: string} = {
    auto: 'autoPolicy',
    homeowners: 'homePolicy',
  };

  const getPolicyCity = () => {
    let policyCity = nullToEmpty(policy?.city);
    if (policyCity !== '') {
      policyCity = policyCity + ', ';
    }
    return policyCity;
  };

  // Code for formatting the policy numbers
  let policyNumber;
  if (policy?.policySymbol) {
    policyNumber = `${policy?.policySymbol}${policy?.policyNumber}`;
  } else {
    policyNumber = policy?.policyNumber;
  }

  return (
    <Box className={classes.root} onClick={onClick} data-testid="resultBox">
      <Box p={2}>
        <Grid container>
          <Grid container xs={7} direction="column">
            <Grid item xs={10}>
              <Box fontWeight="bold">{nullToEmpty(policy?.insured)}</Box>
              <Box>{nullToEmpty(policy?.address)}</Box>
              <Box>{getPolicyCity() + nullToEmpty(policy?.state)}</Box>
              <Box>{nullToEmpty(policy?.zip)}</Box>
            </Grid>
          </Grid>

          <Grid container xs={5} className={classes.alignIcon}>
            <Grid container justify="flex-end">
              <Grid item xs={4}>
                <Box>
                  <Icon name={POLICY_TYPE_MATCHING_ICON[policy?.formPath || '']} />
                </Box>
              </Grid>
              <Grid item container direction="column" xs={8}>
                <Grid item>
                  <Box fontSize="12px" fontWeight="bold">
                    {policyNumber}
                  </Box>
                </Grid>
                <Grid item>
                  <Box fontSize="11px">{POLICY_TYPE_DISPLAY_VALUE[policy?.formPath || '']}</Box>
                </Grid>
                <Grid item>
                  {policy?.policyStatus && policy?.policyStatus.toLowerCase() === 'canceled' && (
                    <Box fontSize="11px" fontWeight="bold" color="#b0374f">
                      Canceled
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
