import React from 'react';
import {BaseSwitch, SwitchProps} from '../BaseSwitch/BaseSwitch';
import {CustomerTypes} from '../../../commonTypes';
import {makePersonaCheck} from '../../../utils/utils';
import {useUserAtomState} from '../../../atoms';

export interface UserPersonaSwitchProps {
  ifPersonas: CustomerTypes[];
  authFlagCheck?: boolean | false;
}

export const UserPersonaSwitch = (props: SwitchProps & UserPersonaSwitchProps) => {
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType;

  const isAuthInsuredFlag = userAtomState?.gettingStarted?.isAuthInsured;

  const matchedPersona = makePersonaCheck(
    userPersona as CustomerTypes,
    props.ifPersonas,
    isAuthInsuredFlag,
    props.authFlagCheck
  );

  return <BaseSwitch matches={matchedPersona} then={props.then} otherwise={props.otherwise} />;
};
