import React, {useEffect, useState} from 'react';
import {makeStyles, Box} from '@material-ui/core';
import {WaypointNames} from '../RegisterBreadcrumb/RegisterBreadcrumb';

import '../StickyBreadcrumbs/sticky.css';
import styled from 'styled-components';
import {
  SABox,
  SAButton,
  SAIcon,
  SAIcons,
  SATag,
  SAText,
  SATooltip,
} from '@saux/design-system-react';
import {
  useSetAdditionalNotesAtomState,
  useSetAddNotesModalAtomState,
  useUserAtomState,
} from '../../../atoms';
import {CoverageModal} from '../CoverageModal/CoverageModal';
import {CommentsDialog} from '../CommentsDialog/CommentsDialog';
import {ReporterDisplayName} from '../ReporterNameDisplay/ReporterDisplayName';
import {BreadcrumbButton} from '../BreadcrumbButton/BreadcrumbButton';
import {useModalController, ModalStates, ModalTypes} from '../Providers/ModalController';
import {fileClaim} from '../../../pages/utils';
import {CustomerTypes} from '../../../commonTypes';

export interface SidebarProps {
  breadcrumbs?: WaypointNames[] | undefined;
  hideAddNoteButton?: boolean;
  hideCoveragesButton?: boolean;
  showUploadButton?: boolean;
  isSummaryScreen?: boolean;
  showFileANewClaim?: boolean;
}

const useStyles = makeStyles(theme => ({
  sticky: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    justifyContent: 'center',
    zIndex: 2,
    flexDirection: 'column',
    paddingTop: '80px',
    paddingLeft: '50px',
    width: '182px',
  },
  flexed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
  },
  buttonWidth: {
    textTransform: 'capitalize',
    height: 'inherit',
    [theme.breakpoints.down('sm')]: {
      minWidth: 135,
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
  },
}));

const SAInfoBox = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

const SALargeButton = styled(SAButton)`
  height: 45px;
  width: 111px;
`;

export const onScroll = (breadCrumbs: HTMLElement | null, pageYOffset: number) => {
  if (breadCrumbs?.style) {
    if (pageYOffset < 80) {
      breadCrumbs.style.paddingTop = `${80 - pageYOffset}px`;
    } else {
      breadCrumbs.style.paddingTop = '0px';
    }
  }
};

export const Sidebar = (props: SidebarProps) => {
  const [userAtomState] = useUserAtomState();
  const policyInceptionDate = new Date(
    userAtomState?.draftClaimResponse?.result?.policy?.policyInceptionDate || ''
  );
  const lossDate = new Date(userAtomState?.draftClaimResponse?.result?.lossDate || '');
  // converting 30 days into milliseconds
  const within30Days =
    lossDate.getTime() - policyInceptionDate.getTime() <= 30 * 24 * 60 * 60 * 1000;
  const setAdditionalNotesAtomState = useSetAdditionalNotesAtomState();

  const [showCoverageModal, setShowCoverageModal] = useState<boolean>(false);
  const userPersona = userAtomState?.gettingStarted?.customerType as CustomerTypes;
  const isAssociate = userPersona === CustomerTypes.Associate;
  const setAddNoteModalAtomState = useSetAddNotesModalAtomState();

  const {dispatchModal} = useModalController();

  const policyHolder = userAtomState?.draftClaimResponse?.result?.contacts?.find(
    (contact: any) => !!contact.contactRoles.find((role: any) => role === 'insured')
  );

  const onUploadClick = () => {
    dispatchModal &&
      dispatchModal({
        type: ModalStates.On,
        name: ModalTypes.Upload,
      });
  };

  const addNotesHandler = () => {
    setAddNoteModalAtomState({open: true});
  };

  useEffect(() => {
    const breadCrumbs = document.getElementById('breadcrumbs');

    window.addEventListener('scroll', () => onScroll(breadCrumbs, window.pageYOffset));
  }, []);

  const classes = useStyles();

  return (
    <>
      {showCoverageModal && (
        <CoverageModal
          closeHandler={() => {
            setShowCoverageModal(false);
          }}
        />
      )}
      {!props?.isSummaryScreen && (
        <CommentsDialog
          closeHandler={(text: string) => {
            setAdditionalNotesAtomState({additionalNotes: text});
          }}
          isSidebar
        />
      )}
      <Box paddingLeft={'0px'} className={classes.sticky} id="breadcrumbs">
        {policyHolder?.displayName && (
          <SAInfoBox pb="medium">
            <SAText type="small" text="Policy Holder" colorVariant="primary" weight="bold" />
            <SAText type="standard" text={policyHolder?.displayName} alignment="right" />
          </SAInfoBox>
        )}
        {isAssociate && within30Days && (
          <SAInfoBox pb="medium">
            <SATooltip
              wrapperTestId="30Day-test-wrapper"
              testId="30Day-test"
              content="Claim is within 30 days of policy inception"
              variant="light"
              placement="right"
            >
              <SATag
                variant="tertiary"
                label="Within 30 Days"
                startIcon={<SAIcon icon={SAIcons.alert} size="16px" colorVariant="tertiary" />}
                alignment="right"
              />
            </SATooltip>
          </SAInfoBox>
        )}
        {userAtomState?.policyNumber && (
          <SAInfoBox pb="medium">
            <SAText type="small" text="Policy Number" colorVariant="primary" weight="bold" />
            <SAText type="standard" text={userAtomState?.policyNumber} alignment="right" />
          </SAInfoBox>
        )}
        <ReporterDisplayName />
        {!props?.hideCoveragesButton && (
          <SABox pb={'large'}>
            <SAButton
              fullWidth={false}
              label="COVERAGES"
              onClick={() => setShowCoverageModal(true)}
              variant="secondary-small-outline"
            />
          </SABox>
        )}
        {props?.breadcrumbs?.map((waypointName: string) => {
          const {flexed, buttonWidth, container} = classes;
          return (
            <>
              <Box className={container}>
                <Box key={waypointName} className={flexed}>
                  <BreadcrumbButton buttonWidth={buttonWidth} waypointName={waypointName} />
                </Box>
              </Box>
            </>
          );
        })}
        {!props?.hideAddNoteButton && (
          <SABox pt={'large'}>
            <SAButton
              fullWidth={false}
              data-testID="add-note-sidebar"
              label="ADD NOTE"
              onClick={addNotesHandler}
              variant="secondary-small"
            />
          </SABox>
        )}
        {props?.showUploadButton && (
          <SABox pt={'large'}>
            <SALargeButton
              fullWidth={false}
              data-testID="upload-sidebar"
              label="ADD PHOTOS & FILES"
              onClick={onUploadClick}
              variant="secondary-small"
            />
          </SABox>
        )}
        {props?.showFileANewClaim && (
          <SABox pt={'medium'}>
            <SALargeButton
              fullWidth={false}
              data-testID="file-new-claim-sidebar"
              label="FILE A NEW CLAIM"
              onClick={(event: any) => {
                event.preventDefault();
                if (userAtomState?.gettingStarted?.customerType) {
                  fileClaim(userAtomState.gettingStarted.customerType as CustomerTypes);
                }
              }}
              variant="primary-small-outline"
            />
          </SABox>
        )}
      </Box>
    </>
  );
};
