import {navigate} from '@reach/router';
import Cookies from 'js-cookie';
import React, {useEffect, useState} from 'react';
import {GettingStarted, LOB_PROPS} from '../components/auto/GettingStarted/GettingStartedEntry';
import {BaseSwitch} from '../components/common/BaseSwitch/BaseSwitch';
import {Lobs, CustomerTypes} from '../commonTypes';
import {
  getSearchParams,
  getPolicyNumber,
  hasValidParameters,
  setSessionCookieData,
  isFromCustomer360,
} from './utils';
import {SAAuth} from './utils/auth';
import {useUserAtomState} from '../atoms';
import {
  AGENT_POLICY_SEARCH_ROUTE,
  ASSOCIATE_POLICY_SEARCH_ROUTE,
  AUTH_INSURED_ROUTE,
  POLICY_SEARCH_ROUTE,
} from '../routes/paths';
import {LogRocket} from '../services/logRocket';

interface LobAbbrevToLobObj {
  [key: string]: string;
}

export const LOB_ABBREVIATION_TO_LOB: LobAbbrevToLobObj = {
  ['PA']: Lobs.Auto,
  ['CA']: Lobs.Auto,
  ['HO']: Lobs.Homeowners,
  ['DF']: Lobs.Homeowners,
  ['CP']: Lobs.CommercialProperty,
};

export const GettingStartedPage = () => {
  const [userAtomState, setUserAtomState] = useUserAtomState();
  const [canRender, setCanRender] = useState<boolean>(false);

  useEffect(() => {
    LogRocket.log('Current location URL:', window.location.href);
    const {lob, appId, policyNumberParam, policySymbol, associate, authinsured, uuid} =
      getSearchParams();
    const policyNumber = getPolicyNumber({policySymbol, policyNumberParam});

    if (associate) {
      navigate(ASSOCIATE_POLICY_SEARCH_ROUTE);
    } else if (authinsured) {
      if (uuid) {
        Cookies.set('uuid', uuid);
        navigate(AUTH_INSURED_ROUTE);
      } else {
        LogRocket.track('Missing auth insured uuid');
        setCanRender(true);
      }
    } else {
      // try to get a cached user session
      SAAuth.currentSession()
        .then((session: any) => {
          const cookieData = Cookies.get('data');
          const authenticatedRedirect = Cookies.get('authenticated-redirect');

          LogRocket.log('Authenticated redirect:', authenticatedRedirect);
          LogRocket.log('uuid:', Cookies.get('uuid'));

          if (authenticatedRedirect) {
            navigate(authenticatedRedirect);
          } else if (hasValidParameters({appId, lob, policyNumberParam})) {
            setSessionCookieData({
              userSession: JSON.stringify(session),
            });

            if (LOB_PROPS[lob]?.implemented) {
              setSessionCookieData({
                path: 'agent',
                userToken: session?.idToken?.jwtToken,
              });

              setUserAtomState({
                ...userAtomState,
                gettingStarted: {
                  customerType: CustomerTypes.SaAgent,
                  lob,
                },
                policyNumber,
              });

              navigate(AGENT_POLICY_SEARCH_ROUTE);
            } else {
              setCanRender(true);
            }
          } else if (cookieData) {
            const data = JSON.parse(cookieData);

            setSessionCookieData({
              path: 'agent',
              userToken: session?.idToken?.jwtToken,
              userSession: JSON.stringify(session),
            });

            if (
              Cookies.get('after-login') === 'true' &&
              LOB_PROPS[data.gettingStarted?.lob || '']?.implemented
            ) {
              setUserAtomState({
                ...userAtomState,
                ...data,
              });

              Cookies.set('after-login', 'false');

              navigate(AGENT_POLICY_SEARCH_ROUTE);
            } else {
              Cookies.set(
                'data',
                JSON.stringify({...data, gettingStarted: {...data.gettingStarted, lob: ''}})
              );

              setUserAtomState({
                ...userAtomState,
                ...data,
                gettingStarted: {...data.gettingStarted, lob: ''},
                policyNumber: '',
              });

              setCanRender(true);
            }
          } else {
            if (appId && isFromCustomer360(appId)) {
              if (LOB_PROPS[lob]?.implemented) {
                setSessionCookieData({
                  path: 'policy',
                  userToken: session?.idToken?.jwtToken,
                });

                setUserAtomState({
                  gettingStarted: {
                    customerType: CustomerTypes.SaCustomer,
                    lob,
                  },
                  policyNumber,
                });

                navigate(POLICY_SEARCH_ROUTE);
              } else {
                setCanRender(true);
              }
            } else {
              setSessionCookieData({
                userSession: JSON.stringify(session),
              });
              setCanRender(true);
            }
          }
        })
        .catch(err => {
          LogRocket.log('No current session - Getting Started', err);

          if (LOB_PROPS[lob]?.implemented) {
            if (hasValidParameters({appId, lob, policyNumberParam})) {
              Cookies.set(
                'data',
                JSON.stringify({
                  gettingStarted: {
                    customerType: CustomerTypes.SaAgent,
                    lob,
                  },
                  policyNumber,
                })
              );

              SAAuth.signIn().catch(() => {
                setCanRender(true);
              });
            } else if (appId && isFromCustomer360(appId)) {
              setSessionCookieData({
                path: 'policy',
              });

              setUserAtomState({
                gettingStarted: {
                  customerType: CustomerTypes.SaCustomer,
                  lob,
                },
                policyNumber,
              });

              navigate(POLICY_SEARCH_ROUTE);
            } else {
              setCanRender(true);
            }
          } else {
            setCanRender(true);
          }
        });
    }
  }, []);

  return <BaseSwitch matches={canRender} then={<GettingStarted />} otherwise={<div />} />;
};
