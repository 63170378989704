import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';

export interface SADatePickerProps {
  onChange?: (event: any) => any;
  onBlur?: (event: any) => any;
  value?: number | Date | null;
  name?: string;
  label?: string;
  keyboardIcon?: object;
  error?: boolean;
  inputRef?: any;
  defaultValue?: any;
  inputValue?: string;
  id?: string;
  InputLabelProps?: object;
  disabled?: boolean;
  helperText?: string;
  shouldDisableDate?: (day: MaterialUiPickersDate) => boolean;
  inputProps?: any;
}

export const SADatePicker = (props: SADatePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        name={props.name || ''}
        id={props.id || ''}
        label={props.label}
        placeholder="Incident Date"
        value={props.value}
        onChange={props.onChange || (date => {})}
        onBlur={props.onBlur || (date => {})}
        format="MM/dd/yyyy"
        inputVariant="outlined"
        keyboardIcon={props.keyboardIcon}
        error={props.error}
        helperText={props.helperText}
        inputRef={props.inputRef}
        inputValue={props.inputValue}
        InputLabelProps={props.InputLabelProps}
        fullWidth
        disabled={props.disabled}
        shouldDisableDate={props.shouldDisableDate}
        inputProps={props.inputProps}
        KeyboardButtonProps={{'aria-label': props.id}}
      />
    </MuiPickersUtilsProvider>
  );
};
