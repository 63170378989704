import {Box} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import * as yup from 'yup';
import {DynamicCheckboxList, Option} from '../../common/DynamicCheckboxList/DynamicCheckboxList';
import {DynamicRadioList} from '../../common/DynamicRadioList/DynamicRadioList';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {
  FIRE_DAMAGE_DETAILS_OPTIONS,
  ALLOWED_FIRE_DAMAGE_TYPES_AREA_DETAILS,
  WHICH_CONTAINED_AREAS_WERE_FIRE_DAMAGED,
  WHICH_ROOMS_WERE_DAMAGED_ON_THE_MAIN_LEVEL,
  WHICH_ROOMS_WERE_DAMAGED_ON_THE_UPPER_LEVEL,
  WHICH_ROOMS_WERE_DAMAGED_IN_THE_BASEMENT,
  FireDamageContainedAreas,
} from './FireDamageDetailOptions';
import {OPTIONS} from '../DamageDetails/DamageDetailOptions';
import {IncidentTypes, INCIDENT_MAPPING, WhatWasDamaged} from '../../common/Incidents/Incidents';
import {Contractor} from '../DamageDetails/Contractor';
import {Uninhabitable} from '../Uninhabitable/Uninhabitable';
import {EmergencyServices} from '../EmergencyServices.tsx/EmergencyServices';

export const InitialHeader = ({children}: {children: React.ReactChild}) => (
  <Box fontSize="16px" pb={3}>
    {children}
  </Box>
);

export const Header = ({children}: {children: React.ReactChild}) => (
  <Box fontSize="16px" pb={3} pt={4}>
    {children}
  </Box>
);

export const FireDamageDetailsSchema = {
  propertyTypes: yup.array().when(['incidents', 'natureOfLoss'], {
    is: (incidents: string[], natureOfLoss: string) => {
      const parsedIncidents = incidents
        .map((v: string) => JSON.parse(v))
        .map((v: {label: string; value: string}) => {
          return v;
        });

      return (
        natureOfLoss &&
        parsedIncidents
          .map(x => x.value)
          .includes(INCIDENT_MAPPING[IncidentTypes.YourHomeAndStructure])
      );
    },
    then: yup.array().min(1, 'Select at least one property type').nullable(),
  }),
};

export const FireDamageDetails = () => {
  const {getValues, trigger, errors} = useFormContext();

  const [fireDamageDetailsSelection, setFireDamageDetailsSelection] = useState<string>('');

  const [whichAreasHaveSmokeDamageOnly, setWhichAreasHaveSmokeDamageOnly] = useState<Set<string>>(
    new Set<string>()
  );
  const [
    whichRoomsDamagedMainLevelAreaSmokeDamageOnly,
    setWhichRoomsDamagedMainLevelAreaSmokeDamageOnly,
  ] = useState<Set<string>>(new Set<string>());
  const [
    whichRoomsDamagedUpperLevelAreaSmokeDamageOnly,
    setWhichRoomsDamagedUpperLevelAreaSmokeDamageOnly,
  ] = useState<Set<string>>(new Set<string>());
  const [
    whichRoomsDamagedBasementAreaSmokeDamageOnly,
    setWhichRoomsDamagedBasementAreaSmokeDamageOnly,
  ] = useState<Set<string>>(new Set<string>());

  const [whichAreasWereDamagedByFire, setWhichAreasWereDamagedByFire] = useState<Set<string>>(
    new Set<string>()
  );
  const [
    whichRoomsDamagedMainLevelAreaDamagedByFire,
    setWhichRoomsDamagedMainLevelAreaDamagedByFire,
  ] = useState<Set<string>>(new Set<string>());
  const [
    whichRoomsDamagedUpperLevelAreaDamagedByFire,
    setWhichRoomsDamagedUpperLevelAreaDamagedByFire,
  ] = useState<Set<string>>(new Set<string>());
  const [
    whichRoomsDamagedBasementAreaDamagedByFire,
    setWhichRoomsDamagedBasementAreaDamagedByFire,
  ] = useState<Set<string>>(new Set<string>());

  const [propertyTypes, setPropertyTypes] = useState<Set<string>>(new Set<string>());

  useEffect(() => {
    const formValues = getValues();

    [
      [
        whichAreasHaveSmokeDamageOnly,
        setWhichAreasHaveSmokeDamageOnly,
        'whichAreasHaveSmokeDamageOnly',
      ],
      [
        whichRoomsDamagedMainLevelAreaSmokeDamageOnly,
        setWhichRoomsDamagedMainLevelAreaSmokeDamageOnly,
        'whichRoomsDamagedMainLevelAreaSmokeDamageOnly',
      ],
      [
        whichRoomsDamagedUpperLevelAreaSmokeDamageOnly,
        setWhichRoomsDamagedUpperLevelAreaSmokeDamageOnly,
        'whichRoomsDamagedUpperLevelAreaSmokeDamageOnly',
      ],
      [
        whichRoomsDamagedBasementAreaSmokeDamageOnly,
        setWhichRoomsDamagedBasementAreaSmokeDamageOnly,
        'whichRoomsDamagedBasementAreaSmokeDamageOnly',
      ],
      [whichAreasWereDamagedByFire, setWhichAreasWereDamagedByFire, 'whichAreasWereDamagedByFire'],
      [
        whichRoomsDamagedMainLevelAreaDamagedByFire,
        setWhichRoomsDamagedMainLevelAreaDamagedByFire,
        'whichRoomsDamagedMainLevelAreaDamagedByFire',
      ],
      [
        whichRoomsDamagedUpperLevelAreaDamagedByFire,
        setWhichRoomsDamagedUpperLevelAreaDamagedByFire,
        'whichRoomsDamagedUpperLevelAreaDamagedByFire',
      ],
      [
        whichRoomsDamagedBasementAreaDamagedByFire,
        setWhichRoomsDamagedBasementAreaDamagedByFire,
        'whichRoomsDamagedBasementAreaDamagedByFire',
      ],
    ].forEach((task: any) => {
      const [set, setState, formProperty] = task;

      set.clear();
      setState(new Set(set));
      formValues['incidentDetails'][formProperty] = [];
    });
  }, [fireDamageDetailsSelection]);

  const handlePropertyTypesChange = (option: Option, checked: boolean, index: number) => {
    trigger('incidentDetails.propertyTypes');

    if (checked) {
      propertyTypes.add(option.label);
    } else {
      propertyTypes.delete(option.label);
    }

    if (option.label === 'Home') {
      setFireDamageDetailsSelection('');
    }

    setPropertyTypes(new Set(propertyTypes));
  };

  const handleChange = (value: string) => {
    setFireDamageDetailsSelection(value);
  };

  const whichAreasHaveSmokeDamageOnlyHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichAreasHaveSmokeDamageOnly?.add(option.label);
    } else {
      whichAreasHaveSmokeDamageOnly?.delete(option.label);
    }

    setWhichAreasHaveSmokeDamageOnly(new Set(whichAreasHaveSmokeDamageOnly));
  };

  const whichRoomsDamagedMainLevelAreaSmokeDamageOnlyHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsDamagedMainLevelAreaSmokeDamageOnly?.add(option.label);
    } else {
      whichRoomsDamagedMainLevelAreaSmokeDamageOnly?.delete(option.label);
    }

    setWhichRoomsDamagedMainLevelAreaSmokeDamageOnly(
      new Set(whichRoomsDamagedMainLevelAreaSmokeDamageOnly)
    );
  };

  const whichRoomsDamagedUpperLevelAreaSmokeDamageOnlyHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsDamagedUpperLevelAreaSmokeDamageOnly?.add(option.label);
    } else {
      whichRoomsDamagedUpperLevelAreaSmokeDamageOnly?.delete(option.label);
    }

    setWhichRoomsDamagedUpperLevelAreaSmokeDamageOnly(
      new Set(whichRoomsDamagedUpperLevelAreaSmokeDamageOnly)
    );
  };

  const whichRoomsDamagedBasementAreaSmokeDamageOnlyHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsDamagedBasementAreaSmokeDamageOnly?.add(option.label);
    } else {
      whichRoomsDamagedBasementAreaSmokeDamageOnly?.delete(option.label);
    }

    setWhichRoomsDamagedBasementAreaSmokeDamageOnly(
      new Set(whichRoomsDamagedBasementAreaSmokeDamageOnly)
    );
  };

  const whichAreasWereDamagedByFireHandler = (option: Option, checked: boolean, index: number) => {
    if (checked) {
      whichAreasWereDamagedByFire?.add(option.label);
    } else {
      whichAreasWereDamagedByFire?.delete(option.label);
    }

    setWhichAreasWereDamagedByFire(new Set(whichAreasWereDamagedByFire));
  };

  const whichRoomsDamagedMainLevelAreaDamagedByFireHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsDamagedMainLevelAreaDamagedByFire?.add(option.label);
    } else {
      whichRoomsDamagedMainLevelAreaDamagedByFire?.delete(option.label);
    }

    setWhichRoomsDamagedMainLevelAreaDamagedByFire(
      new Set(whichRoomsDamagedMainLevelAreaDamagedByFire)
    );
  };

  const whichRoomsDamagedUpperLevelAreaDamagedByFireHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsDamagedUpperLevelAreaDamagedByFire?.add(option.label);
    } else {
      whichRoomsDamagedUpperLevelAreaDamagedByFire?.delete(option.label);
    }

    setWhichRoomsDamagedUpperLevelAreaDamagedByFire(
      new Set(whichRoomsDamagedUpperLevelAreaDamagedByFire)
    );
  };

  const whichRoomsDamagedBasementAreaDamagedByFireHandler = (
    option: Option,
    checked: boolean,
    index: number
  ) => {
    if (checked) {
      whichRoomsDamagedBasementAreaDamagedByFire?.add(option.label);
    } else {
      whichRoomsDamagedBasementAreaDamagedByFire?.delete(option.label);
    }

    setWhichRoomsDamagedBasementAreaDamagedByFire(
      new Set(whichRoomsDamagedBasementAreaDamagedByFire)
    );
  };

  const hasHomeSelected = propertyTypes.has(WhatWasDamaged.Home);

  const showEmergencyServices =
    propertyTypes.has(WhatWasDamaged.Home) ||
    propertyTypes.has(WhatWasDamaged.DetachedGarage) ||
    propertyTypes.has(WhatWasDamaged.OtherBuilding) ||
    propertyTypes.has(WhatWasDamaged.Other);

  const shouldDisplayDamagedAreas = ALLOWED_FIRE_DAMAGE_TYPES_AREA_DETAILS.includes(
    fireDamageDetailsSelection
  );

  const hasAreasSmokeDamageMainLevelSelected = whichAreasHaveSmokeDamageOnly?.has(
    FireDamageContainedAreas.MainLevel
  );
  const hasAreasSmokeDamageUpperLevelSelected = whichAreasHaveSmokeDamageOnly?.has(
    FireDamageContainedAreas.UpperLevel
  );
  const hasAreasSmokeDamageBasementSelected = whichAreasHaveSmokeDamageOnly?.has(
    FireDamageContainedAreas.Basement
  );

  const hasAreaDamagedByFireMainLevelSelected = whichAreasWereDamagedByFire?.has(
    FireDamageContainedAreas.MainLevel
  );
  const hasAreaDamagedByFireUpperLevelSelected = whichAreasWereDamagedByFire?.has(
    FireDamageContainedAreas.UpperLevel
  );
  const hasAreaDamagedByFireBasementSelected = whichAreasWereDamagedByFire?.has(
    FireDamageContainedAreas.Basement
  );

  return (
    <FormContainer header="Fire Damage Details">
      <Box>
        <InitialHeader>What was damaged?</InitialHeader>
        <Box>
          <DynamicCheckboxList
            options={OPTIONS}
            name="incidentDetails.propertyTypes"
            errorMessage={errors?.incidentDetails?.propertyTypes?.message}
            onOptionsChange={handlePropertyTypesChange}
          />
        </Box>
        {hasHomeSelected && (
          <>
            <Header>What best describes the damages?</Header>
            <Box pb={3} pt={1}>
              <DynamicRadioList
                name="incidentDetails.fireDamageDetailsRadioGroup"
                defaultValue=""
                options={FIRE_DAMAGE_DETAILS_OPTIONS}
                onChange={handleChange}
              />
            </Box>
            {shouldDisplayDamagedAreas && (
              <>
                <Header>What areas have smoke damage only?</Header>
                <Box>
                  <DynamicCheckboxList
                    options={WHICH_CONTAINED_AREAS_WERE_FIRE_DAMAGED}
                    name="incidentDetails.whichAreasHaveSmokeDamageOnly"
                    onOptionsChange={whichAreasHaveSmokeDamageOnlyHandler}
                    key={fireDamageDetailsSelection}
                  />
                </Box>
                {hasAreasSmokeDamageMainLevelSelected && (
                  <>
                    <Header>Which rooms were damaged on the main level?</Header>
                    <Box>
                      <DynamicCheckboxList
                        options={WHICH_ROOMS_WERE_DAMAGED_ON_THE_MAIN_LEVEL}
                        name="incidentDetails.whichRoomsDamagedMainLevelAreaSmokeDamageOnly"
                        onOptionsChange={whichRoomsDamagedMainLevelAreaSmokeDamageOnlyHandler}
                      />
                    </Box>
                  </>
                )}
                {hasAreasSmokeDamageUpperLevelSelected && (
                  <>
                    <Header>Which rooms were damaged on the upper level?</Header>
                    <Box>
                      <DynamicCheckboxList
                        options={WHICH_ROOMS_WERE_DAMAGED_ON_THE_UPPER_LEVEL}
                        name="incidentDetails.whichRoomsDamagedUpperLevelAreaSmokeDamageOnly"
                        onOptionsChange={whichRoomsDamagedUpperLevelAreaSmokeDamageOnlyHandler}
                      />
                    </Box>
                  </>
                )}
                {hasAreasSmokeDamageBasementSelected && (
                  <>
                    <Header>Which rooms were damaged in the basement?</Header>
                    <Box>
                      <DynamicCheckboxList
                        options={WHICH_ROOMS_WERE_DAMAGED_IN_THE_BASEMENT}
                        name="incidentDetails.whichRoomsDamagedBasementAreaSmokeDamageOnly"
                        onOptionsChange={whichRoomsDamagedBasementAreaSmokeDamageOnlyHandler}
                      />
                    </Box>
                  </>
                )}
                <Header>What areas were damaged by fire?</Header>
                <Box>
                  <DynamicCheckboxList
                    options={WHICH_CONTAINED_AREAS_WERE_FIRE_DAMAGED}
                    name="incidentDetails.whichAreasWereDamagedByFire"
                    onOptionsChange={whichAreasWereDamagedByFireHandler}
                    key={fireDamageDetailsSelection}
                  />
                </Box>
                {hasAreaDamagedByFireMainLevelSelected && (
                  <>
                    <Header>Which rooms were damaged on the main level?</Header>
                    <Box>
                      <DynamicCheckboxList
                        options={WHICH_ROOMS_WERE_DAMAGED_ON_THE_MAIN_LEVEL}
                        name="incidentDetails.whichRoomsDamagedMainLevelAreaDamagedByFire"
                        onOptionsChange={whichRoomsDamagedMainLevelAreaDamagedByFireHandler}
                      />
                    </Box>
                  </>
                )}
                {hasAreaDamagedByFireUpperLevelSelected && (
                  <>
                    <Header>Which rooms were damaged on the upper level?</Header>
                    <Box>
                      <DynamicCheckboxList
                        options={WHICH_ROOMS_WERE_DAMAGED_ON_THE_UPPER_LEVEL}
                        name="incidentDetails.whichRoomsDamagedUpperLevelAreaDamagedByFire"
                        onOptionsChange={whichRoomsDamagedUpperLevelAreaDamagedByFireHandler}
                      />
                    </Box>
                  </>
                )}
                {hasAreaDamagedByFireBasementSelected && (
                  <>
                    <Header>Which rooms were damaged in the basement?</Header>
                    <Box>
                      <DynamicCheckboxList
                        options={WHICH_ROOMS_WERE_DAMAGED_IN_THE_BASEMENT}
                        name="incidentDetails.whichRoomsDamagedBasementAreaDamagedByFire"
                        onOptionsChange={whichRoomsDamagedBasementAreaDamagedByFireHandler}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
            <Uninhabitable />
          </>
        )}
        {showEmergencyServices && <EmergencyServices />}
        {hasHomeSelected && <Contractor />}
      </Box>
    </FormContainer>
  );
};
