import {Box} from '@material-ui/core';
import React from 'react';
import {AGENT_AND_ASSOCIATE_PERSONAS} from '../../../commonTypes';
import {register} from '../../../serviceWorker';
import {useFeatureFlags} from '../../common/Providers/Providers';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {YesNoToggle} from '../../common/YesNoToggle/YesNoToggle';
import {YesNoUnk} from '../../common/YesNoUnk/YesNoUnk';
import {Header} from '../DamageDetails/DamageDetails';

export const Uninhabitable = () => {
  const {featureFlags} = useFeatureFlags();

  return featureFlags?.FF_DCX_2621 ? (
    <>
      <Box>
        <Header>Is the house or dwelling still livable due to the resulting damage?</Header>
      </Box>
      <Box>
        <UserPersonaSwitch
          ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
          then={
            <YesNoUnk
              name="incidentDetails.isPropertyHabitable"
              yesTestId="YesisPropertyHabitable"
              dontKnowTestId="UnkisPropertyHabitable"
            />
          }
          otherwise={
            <YesNoToggle
              name="incidentDetails.isPropertyHabitable"
              yesButtonTestId="YesisPropertyHabitable"
              testId="NoisPropertyHabitable"
              inputRef={register}
            />
          }
        />
      </Box>
    </>
  ) : (
    <></>
  );
};
