import React from 'react';
import {Typography, Grid, Box} from '@material-ui/core';
import {BlueContainer} from '../../common/Containers/Containers';
import {formatPhone} from '../../../utils/utils';
import {SummaryField} from '../../common/Summary/components/SummaryField/SummaryField';
import {AddressSummary} from './AddressSummary';
import {Contact} from '../../common/Summary/types';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';

export interface OwnerDetailsProp {
  owner: Contact;
}

export const OwnerDetails = (props: OwnerDetailsProp) => {
  return (
    <BlueContainer>
      <Box pb={3}>
        <SummaryHeader forBlueContainer headerText="Owner Details" />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <SummaryField label="Name">
            <Typography>{props.owner.displayName}</Typography>
          </SummaryField>
        </Grid>
        <AddressSummary
          label="Address"
          addressLine1={props?.owner?.primaryAddress?.addressLine1}
          city={props?.owner?.primaryAddress?.city}
          state={props?.owner?.primaryAddress?.state}
          postalCode={props?.owner?.primaryAddress?.postalCode}
        />
        {props?.owner?.emailAddress1 && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Email Address">
              <Typography>{props.owner.emailAddress1}</Typography>
            </SummaryField>
          </Grid>
        )}
        {props?.owner?.cellNumber && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Mobile Number">
              <Typography>{formatPhone(props.owner.cellNumber)}</Typography>
            </SummaryField>
          </Grid>
        )}
        {props?.owner?.workNumber && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Work Number">
              <Typography>{formatPhone(props.owner.workNumber)}</Typography>
            </SummaryField>
          </Grid>
        )}
        {props?.owner?.homeNumber && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Home Number">
              <Typography>{formatPhone(props.owner.homeNumber)}</Typography>
            </SummaryField>
          </Grid>
        )}

        {props?.owner?.insuranceProvider && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Insurance Provider">
              <Typography>{props.owner.insuranceProvider}</Typography>
            </SummaryField>
          </Grid>
        )}
        {props?.owner?.policyOrClaimNumber && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Policy/Claim Number">
              <Typography>{props.owner.policyOrClaimNumber}</Typography>
            </SummaryField>
          </Grid>
        )}
      </Grid>
    </BlueContainer>
  );
};
