import ScheduleIcon from '@material-ui/icons/Schedule';
import {withStyles} from '@material-ui/core';

export const Clock = withStyles({
  root: {
    color: '#83D000',
  },
})(ScheduleIcon);

export const ClockDisabled = withStyles({
  root: {
    color: 'grey',
  },
})(ScheduleIcon);
