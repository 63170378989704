import React, {useState} from 'react';
import {useLocation} from '@reach/router';
import {StateProps, SubmissionTypes} from '../components/common/Summary/types';
import {BaseSummary} from '../components/common/Summary/BaseSummary';
import {BackButtonBehavior} from '../components/common/BackButtonBehavior/BackButtonBehavior';
import {AutoSuccess} from '../components/auto/AutoSuccess/AutoSuccess';
import {HomeownersSuccess} from '../components/homeowners/HomeownersSuccess/HomeownersSuccess';
import {ROOT_ROUTE} from '../routes/paths';

export const Success = () => {
  const location = useLocation();
  const successState = location.state as StateProps;
  const submissionType = successState?.submissionType;
  const [waypoints, setWaypoints] = useState([]);

  return (
    <BackButtonBehavior shouldDisplayAlert route={ROOT_ROUTE}>
      <BaseSummary successState={successState} additionalwaypoints={waypoints}>
        <>
          {submissionType === SubmissionTypes.Collision && (
            <AutoSuccess successState={successState} setWaypoints={setWaypoints} />
          )}
          {submissionType === SubmissionTypes.Property && (
            <HomeownersSuccess successState={successState} setWaypoints={setWaypoints} />
          )}
        </>
      </BaseSummary>
    </BackButtonBehavior>
  );
};
