import React, {useEffect, createRef, useState, ReactElement} from 'react';
import {
  Grid,
  Box,
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography,
  Hidden,
} from '@material-ui/core';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import {Pdf} from '../../pdf/PDF';
import {getSignedUrl, uploadToS3, uploadToEDS} from '../../../services';
import {trackEvent} from '../../../services/analytics';
import {UserFeedbackDialog} from './components/UserFeedbackDialog/UserFeedbackDialog';
import {WithUserPersonaContext} from '../WithUserPersonaContext/WithUserPersonaContext';
import {BaseSwitch} from '../BaseSwitch/BaseSwitch';
import {GenericSuccess} from '../GenericSuccess/GenericSuccess';
import {Contact, StateProps, SubmissionTypes} from './types';
import {AGENT_AND_ASSOCIATE_PERSONAS, ContactRoles, CustomerTypes} from '../../../commonTypes';
import Cookies from 'js-cookie';
import {NewAdjuster} from './components/YourAdjuster/NewAdjuster';
import {ModalControllerProvider} from '../Providers/ModalController';
import {ModalOverlay} from '../UploadModal/ModalOverlay';
import {UserPersonaSwitch} from '../UserPersonaSwitch/UserPersonaSwitch';
import {FileContent} from '../UploadModal/uploadFileContent';
import {UploadStatus} from '../UploadModal/UploadModal/UploadModal';
import {StagingBox} from '../UploadModal/UploadModal/UploadModal';
import {useResetAtomState, useResetUserAtomState} from '../../../atoms';
import {PdfSection} from './components/PdfSection/PdfSection';
import {SUMMARY_PDF_ID} from '../../pdf/constants';
import {hasContactRoles, hasSome, hasSomeContactRoles, showAdjuster} from '../../../utils/utils';
import {SASpinner} from '@saux/design-system-react';
import styled from 'styled-components';
import {StpSummaryScreen} from '../../auto/StpSummaryScreen/StpSummaryScreen';
import {InfoSection} from '../InfoSection/InfoSection';
import {SuccessSummaryFileUpload} from './components/SummaryHeader/SuccessSummaryFileUpload/SuccessSummaryFileUpload';
import {claimsHubLink} from '../../../routes/links';
import {IncidentTypes} from '../Incidents/Incidents';
import {Sidebar} from '../Sidebar/Sidebar';
import {WithBreadcrumbsContext} from '../WithBreadcrumbContext/WithBreadcrumbContext';
import {WaypointNames} from '../RegisterBreadcrumb/RegisterBreadcrumb';
import {ConditionalWaypoint, fileClaim, insertConditionalBreadcrumbs} from '../../../pages/utils';

const useStyles = makeStyles(theme => ({
  pdfButton: {
    cursor: 'pointer',
  },
  parentElement: {
    width: '100%',
    padding: '40px 200px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 50px',
    },
  },
  parentElementNoPadding: {
    margin: '40px 5px 5px 5px',
    width: '100%',
  },
  greenBox: {
    background: 'RGBA(101,161,0,0.2)',
    backgroundBlendMode: 'lighten',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      width: '90%',
      flexDirection: 'row',
    },
  },
  greenBoxGrid: {
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  childElement: {
    position: 'relative',
    backgroundColor: 'transparent',
    opacity: '100%',
  },
  uploadSection: {
    paddingTop: '0',
    margin: '0 2rem',
  },
  typographyStyles: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
}));

const summaryHeaderColorCode = '#0173C6';

interface BaseSummaryProps {
  successState: StateProps;
  additionalwaypoints?: any;
  children: ReactElement;
}

const Modal = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: white;
`;

export const BaseSummary = ({successState, additionalwaypoints, children}: BaseSummaryProps) => {
  const userPersona: CustomerTypes = successState.customerType as CustomerTypes;
  let ref = createRef();
  const [pdf, setPdf] = useState<any>();
  const claimnumber = successState?.claimNumber;
  const [uploadData, setUploadData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasModalBeenClosed, setHasModalBeenClosed] = useState(false);
  const resetUserAtomState = useResetUserAtomState();
  const resetAtomState = useResetAtomState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {noSsr: true});
  const [pdfDone, setPdfDone] = useState<boolean>(!isMobile);
  const isTimeOutOccured = successState.timeoutOccurred;
  const isNoticeOnly = successState.formData?.noticeOnly;

  // Persona Checks
  const isReporterIsInsured = successState.contacts?.find(
    (contact: Contact) =>
      hasContactRoles(contact as Contact, [ContactRoles.Insured, ContactRoles.Reporter]) &&
      !hasSomeContactRoles(contact as Contact, [ContactRoles.Agent, ContactRoles.Lienholder])
  );
  const isAssociate = userPersona === CustomerTypes.Associate;
  const isAgent = userPersona === CustomerTypes.SaAgent;
  const isCustomer = userPersona === CustomerTypes.SaCustomer;
  const isAssociateWithInsuredReporter = isReporterIsInsured && isAssociate;

  const getReporterPublicID = () => {
    const reporter = successState.contacts?.find(contact =>
      hasContactRoles(contact, [ContactRoles.Reporter])
    );
    let reporterPublicID = 'Claim';

    if (
      successState.contacts?.some(
        contact =>
          reporter?.displayName?.toLowerCase() === contact.displayName?.toLowerCase() &&
          hasSome({
            array: [ContactRoles.IncidentOwner, ContactRoles.Driver],
            arrayToCheck: contact.contactRoles,
          })
      ) &&
      reporter?.publicID
    ) {
      reporterPublicID = `entity.Person#${reporter.publicID}`;
    }

    return reporterPublicID;
  };

  useEffect(() => {
    if (!isTimeOutOccured) {
      const pdfInstance = new Pdf(SUMMARY_PDF_ID, claimnumber, isMobile, setPdfDone);
      setPdf(pdfInstance);
    }
    return () => {
      resetAtomState();
      resetUserAtomState();
    };
  }, []);

  const uploadSummaryPdf = async () => {
    try {
      const filename = `${claimnumber}-Summary.pdf`;
      const pdfData = await pdf.generatePdfData();
      const {data} = await getSignedUrl({filename, claimnumber});
      const related_to = getReporterPublicID();

      if (data) {
        await uploadToS3({
          signedUrl: data.url,
          dataFields: data.fields,
          file: pdfData,
        });
        await uploadToEDS({filename, claimnumber, related_to});
      }
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    if (!isTimeOutOccured && pdf && Cookies.get('claimNumber') !== claimnumber) {
      uploadSummaryPdf();
      Cookies.set('claimNumber', claimnumber);
    }
  }, [pdf, claimnumber]);

  useEffect(() => {
    const docBody = document.querySelector('body');
    if (!isTimeOutOccured && docBody) {
      docBody.style.overflow = pdfDone ? 'auto' : 'hidden';
      docBody.style.position = pdfDone ? 'initial' : 'fixed';
    }
  }, [pdfDone]);

  const classes = useStyles();

  const downloadPDF = () => {
    pdf?.savePdf();
    trackEvent({category: 'Summary Page', label: 'Download PDF'});
  };

  const feedbackData = {
    firstName: successState.mainContact?.firstName || '',
    lastName: successState.mainContact?.lastName || '',
    email: successState.mainContact?.emailAddress1 || '',
    claimNumber: claimnumber,
  };

  const isAutoClaim = successState.submissionType === SubmissionTypes.Collision;
  const isHomeOwnersClaim = successState.submissionType == SubmissionTypes.Property;

  // Conditions to Hide or Show the Next Steps Screen
  const showNextStepsScreen =
    !isNoticeOnly &&
    (isAutoClaim ||
      (isHomeOwnersClaim &&
        (isCustomer || isAssociateWithInsuredReporter) &&
        !!successState.formData?.incidentDetails?.incidents?.find(
          incident =>
            incident.label === IncidentTypes.YourHomeAndStructure ||
            incident.label === IncidentTypes.InsuredHomeAndStructure
        )));
  const showAdjusterInSummaryScreen = !showNextStepsScreen && showAdjuster(successState);

  const baseWaypoints = [WaypointNames.ClaimNumber];

  const conditionalWaypoints: ConditionalWaypoint[] = [
    {
      condition: !!showNextStepsScreen,
      spliceStart: 1,
      waypoint: WaypointNames.NextSteps,
    },
  ];

  const waypoints = insertConditionalBreadcrumbs(baseWaypoints, conditionalWaypoints);

  return (
    <>
      <BaseSwitch
        matches={isTimeOutOccured}
        then={<GenericSuccess successState={successState} />}
        otherwise={
          <>
            {!pdfDone && (
              <Modal>
                <Grid container alignItems="center" style={{height: '80vh'}}>
                  <Grid container justify="flex-end" direction="row" alignItems="center">
                    <Grid item xs={12}>
                      <Typography className={classes.typographyStyles}>
                        Building The Claim Summary
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <SASpinner variant="circular-continuous" />
                    </Grid>
                  </Grid>
                </Grid>
              </Modal>
            )}
            <WithBreadcrumbsContext>
              <WithUserPersonaContext userPersona={successState.customerType}>
                <ModalControllerProvider>
                  <>
                    <ModalOverlay
                      claimNumber={claimnumber}
                      setData={setUploadData}
                      setIsOpen={setIsModalOpen}
                      setHasBeenClosed={setHasModalBeenClosed}
                    />
                    <UserPersonaSwitch
                      ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                      then={
                        <Hidden smDown>
                          <Sidebar
                            breadcrumbs={
                              additionalwaypoints
                                ? [...waypoints, ...additionalwaypoints]
                                : waypoints
                            }
                            hideCoveragesButton={!isAssociate}
                            hideAddNoteButton={!isAssociate}
                            showUploadButton={isAgent}
                            isSummaryScreen
                            showFileANewClaim
                          />
                        </Hidden>
                      }
                    />
                    <Box className={classes.parentElement}>
                      <Grid id={SUMMARY_PDF_ID} container justify="center" innerRef={ref}>
                        <>
                          <Box className={classes.parentElementNoPadding}>
                            <>
                              <Box mb="40px" width="100%">
                                <SuccessSummaryFileUpload
                                  claimNumber={claimnumber}
                                  downloadPDF={downloadPDF}
                                  publicID={successState.publicID}
                                />
                              </Box>

                              <UserPersonaSwitch
                                ifPersonas={AGENT_AND_ASSOCIATE_PERSONAS}
                                then={
                                  <Box mb="40px">
                                    <InfoSection
                                      header="Start your Next Claim from Right Here!"
                                      description="When you’re ready to start a new claim, just click the button."
                                      buttonText="FILE A NEW CLAIM"
                                      onClick={(event: any) => {
                                        event.preventDefault();
                                        if (successState?.customerType) {
                                          fileClaim(successState.customerType as CustomerTypes);
                                        }
                                      }}
                                    />
                                  </Box>
                                }
                                otherwise={
                                  <Box mb="40px">
                                    <InfoSection
                                      header="Stay up to date with our Manage your Claim system!"
                                      description="You'll be able to track the status of your claim, from inspection to final payment."
                                      buttonText="MANAGE YOUR CLAIM"
                                      href={claimsHubLink}
                                      onClick={() =>
                                        trackEvent({
                                          category: 'Summary Page',
                                          label: 'Visit The Claimshub',
                                        })
                                      }
                                    />
                                  </Box>
                                }
                              />

                              {uploadData.length !== 0 && (
                                <Box mb="40px">
                                  <FormContainer header={'Uploaded Files'} fontSize={28}>
                                    <Box>
                                      <StagingBox className={classes.uploadSection}>
                                        {uploadData.map((name: string) => {
                                          return (
                                            <FileContent
                                              name={name}
                                              file={{} as unknown as any}
                                              key={name}
                                              removeFile={() => {}}
                                              signedResponse={null}
                                              uploading={false}
                                              status={UploadStatus.Complete}
                                              uploadedFilesSection={true}
                                            />
                                          );
                                        })}
                                      </StagingBox>
                                    </Box>
                                  </FormContainer>
                                </Box>
                              )}

                              {showNextStepsScreen && (
                                <Box mb="40px">
                                  <FormContainer fontSize={28}>
                                    <PdfSection className={SUMMARY_PDF_ID}>
                                      <StpSummaryScreen successState={successState} />
                                    </PdfSection>
                                  </FormContainer>
                                </Box>
                              )}

                              {!isMobile && (
                                <PdfSection className={'html2pdf__page-break'}>
                                  <div />
                                </PdfSection>
                              )}

                              <Box>
                                <FormContainer
                                  header={'Claim Summary'}
                                  color={summaryHeaderColorCode}
                                  fontSize={28}
                                >
                                  <>
                                    {showAdjusterInSummaryScreen && (
                                      <PdfSection className={SUMMARY_PDF_ID}>
                                        <Box>
                                          <NewAdjuster
                                            claimNumber={claimnumber}
                                            adjusterInfo={successState.adjusterDetails}
                                          />
                                        </Box>
                                      </PdfSection>
                                    )}
                                    {children}
                                  </>
                                </FormContainer>
                              </Box>
                            </>
                          </Box>
                        </>
                      </Grid>
                    </Box>
                  </>
                </ModalControllerProvider>
              </WithUserPersonaContext>
            </WithBreadcrumbsContext>
          </>
        }
      />
      {pdfDone && successState.customerType !== CustomerTypes.Associate && (
        <UserFeedbackDialog
          data={{...feedbackData}}
          isUploadModalOpen={isModalOpen}
          hasModalBeenClosed={hasModalBeenClosed}
        />
      )}
    </>
  );
};
