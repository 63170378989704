import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const Phone = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="13px"
      height="7px"
      viewBox="0 0 13 13"
      version="1.1"
      fontSize="inherit"
    >
      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="1-Property-Exposure-Detail_No_Payments"
          transform="translate(-88.000000, -501.000000)"
          fill="#0173C6"
        >
          <path
            d="M100.708519,511.28239 L98.7008743,509.269995 C98.3010983,508.87071 97.6387888,508.882988 97.2250196,509.297895 L96.2135286,510.31128 C96.1496879,510.275993 96.0835566,510.239159 96.0139394,510.199979 C95.3752838,509.845364 94.5009357,509.359085 93.5810226,508.436538 C92.6584205,507.511844 92.1728928,506.634316 91.817885,505.993664 C91.7805367,505.925785 91.744533,505.860502 91.7091268,505.798314 L92.3880189,505.118831 L92.7216138,504.78408 C93.1361797,504.368425 93.1476332,503.704963 92.7486539,503.30473 L90.7410096,501.291936 C90.3420303,500.892252 89.6795216,500.90433 89.2649557,501.319786 L88.6993036,501.890264 L88.7146911,501.905636 C88.5250116,502.148202 88.3664557,502.427951 88.2483853,502.72976 C88.1396769,503.017145 88.0719521,503.291254 88.0410277,503.565962 C87.7759047,505.768667 88.7802248,507.781661 91.5056534,510.513467 C95.2731985,514.289354 98.3092651,514.003915 98.4403825,513.99004 C98.7255739,513.955951 98.999063,513.887524 99.2768346,513.779517 C99.5752224,513.662527 99.8543884,513.503811 100.096355,513.314151 L100.108606,513.325231 L100.681877,512.762589 C101.095447,512.347133 101.107299,511.683472 100.708519,511.28239 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
};
