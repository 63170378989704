import {navigate} from '@reach/router';
import {useEffect, ReactElement} from 'react';

export interface BackButtonBehaviorProps {
  children: ReactElement;
  route: string;
  shouldDisplayAlert?: boolean | undefined;
}

export const BackButtonBehavior = ({
  children,
  route,
  shouldDisplayAlert,
}: BackButtonBehaviorProps) => {
  const onBackButtonEvent = (e: PopStateEvent) => {
    e.preventDefault();

    if (shouldDisplayAlert) {
      if (
        window.confirm(
          'Your claim has been submitted, would you like to exit before getting additional claim information?'
        )
      ) {
        navigate(route);
      } else {
        window.addEventListener('popstate', onBackButtonEvent, {once: true});
        window.history.pushState(window.history.state, '', window.location.pathname);
      }
    } else {
      navigate(route);
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent, {once: true});
    window.history.pushState(window.history.state, '', window.location.pathname);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  return children;
};
