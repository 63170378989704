import {
  WhatWasDamaged,
  PROPERTY_MAPPING,
  NatureOfLoss,
  INCIDENT_NATURE_OF_LOSS_MAPPING,
  IncidentTypes,
} from '../../common/Incidents/Incidents';
import {NatureOfLossOption} from '../PropertyIncidentDetails/PropertyIncidentDetails';
import {PropertyTypeProps} from './DamageDetails';

export const OPTIONS: Array<PropertyTypeProps> = Object.entries(WhatWasDamaged).map(
  ([key, value]) => ({
    label: value,
    value: PROPERTY_MAPPING[value],
    checked: false,
    disabled: false,
    testID: key,
  })
);
export enum WhereTheHomeWasDamaged {
  Exterior = 'Exterior',
  Interior = 'Interior',
}

export enum DamageToExteriorHome {
  Roof = 'Roof',
  GutterDownspouts = 'Gutter/Downspouts',
  SidingBrickStucco = 'Siding/Brick/Stucco',
  Doors = 'Doors',
  Windows = 'Windows',
  Awnings = 'Awnings',
  Other = 'Other',
}

export enum DamageToInteriorHome {
  FirstFloor = '1st floor',
  SecondFloor = '2nd floor',
  Attic = 'Attic',
  Basement = 'Basement',
  Other = 'Other',
}

export enum DamageToInteriorFirstFloor {
  Bathroom = 'Bathroom',
  Bedroom = 'Bedroom',
  Den = 'Den',
  DiningRoom = 'Dining Room',
  FamilyRoom = 'Family Room',
  Foyer = 'Foyer',
  Garage = 'Garage',
  Kitchen = 'Kitchen',
  Laundry = 'Laundry',
  LivingRoom = 'Living Room',
  UtilityRoom = 'Utility Room',
  Other = 'Other',
}

export enum DamageToInteriorSecondFloor {
  Bathroom = 'Bathroom',
  Bedroom = 'Bedroom',
  Den = 'Den',
  DiningRoom = 'Dining Room',
  FamilyRoom = 'Family Room',
  Kitchen = 'Kitchen',
  Laundry = 'Laundry',
  LivingRoom = 'Living Room',
  UtilityRoom = 'Utility Room',
  Other = 'Other',
}

export enum DamageToInteriorBasement {
  Bathroom = 'Bathroom',
  Bedroom = 'Bedroom',
  CrawlSpace = 'Crawlspace',
  FamilyRoom = 'Family Room',
  Laundry = 'Laundry',
  UtilityRoom = 'Utility Room',
  Other = 'Other',
}

export const WHERE_IS_THE_DAMAGE_TO_THE_HOME_OPTIONS: Array<PropertyTypeProps> = Object.entries(
  WhereTheHomeWasDamaged
).map(([_, value]) => ({
  label: value,
  value: value.toLocaleLowerCase(),
  checked: false,
  disabled: false,
  testID: value,
}));

export enum emergencyServices {
  tarping = 'Tarping for Roof Damage',
  boardUp = 'Board - Up',
}

export const EMERGENCY_SERVICES_OPTIONS: Array<PropertyTypeProps> = Object.entries(
  emergencyServices
).map(([key, value]) => ({
  label: value,
  value: key,
  checked: false,
  disabled: false,
  testID: key,
}));

export const WHAT_WAS_DAMAGED_TO_THE_EXTERIOR_OF_THE_HOME_OPTIONS: Array<PropertyTypeProps> =
  Object.entries(DamageToExteriorHome).map(([_, value]) => ({
    label: value,
    value: value.toLowerCase(),
    checked: false,
    disabled: false,
    testID: value,
  }));

export const WHAT_WAS_DAMAGED_TO_THE_INTERIOR_OF_THE_HOME_OPTIONS: Array<PropertyTypeProps> =
  Object.entries(DamageToInteriorHome).map(([key, value]) => ({
    label: value,
    value: key,
    checked: false,
    disabled: false,
    testID: value,
  }));

export const WHICH_ROOMS_WERE_DAMAGED_ON_THE_FIRST_FLOOR: Array<PropertyTypeProps> = Object.entries(
  DamageToInteriorFirstFloor
).map(([_, value]) => ({
  label: value,
  value: value.toLowerCase(),
  checked: false,
  disabled: false,
  testID: value,
}));

export const WHICH_ROOMS_WERE_DAMAGED_ON_THE_SECOND_FLOOR: Array<PropertyTypeProps> =
  Object.entries(DamageToInteriorSecondFloor).map(([_, value]) => ({
    label: value,
    value: value.toLowerCase(),
    checked: false,
    disabled: false,
    testID: value,
  }));

export const WHICH_ROOMS_WERE_DAMAGED_IN_THE_BASEMENT: Array<PropertyTypeProps> = Object.entries(
  DamageToInteriorBasement
).map(([_, value]) => ({
  label: value,
  value: value.toLowerCase(),
  checked: false,
  disabled: false,
  testID: value,
}));

const YOUR_HOME_AND_STRUCTURE = INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure];

export const WEATHER_RELATED_NATURE_OF_LOSS_OPTIONS: NatureOfLossOption[] = [
  {
    label: NatureOfLoss.WinterWeather,
    value: YOUR_HOME_AND_STRUCTURE[NatureOfLoss.WinterWeather],
  },
  {
    label: NatureOfLoss.Wind,
    value: YOUR_HOME_AND_STRUCTURE[NatureOfLoss.Wind],
  },
  {
    label: NatureOfLoss.Flood,
    value: YOUR_HOME_AND_STRUCTURE[NatureOfLoss.Flood],
  },
  {
    label: NatureOfLoss.Hail,
    value: YOUR_HOME_AND_STRUCTURE[NatureOfLoss.Hail],
  },
  {
    label: NatureOfLoss.Lightning,
    value: YOUR_HOME_AND_STRUCTURE[NatureOfLoss.Lightning],
  },
  {
    label: NatureOfLoss.Other,
    value: YOUR_HOME_AND_STRUCTURE[NatureOfLoss.WeatherRelated],
  },
];
