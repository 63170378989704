import * as yup from 'yup';
import Cookies from 'js-cookie';
import {State} from '../components/common/StateDropdown/StateDropdown';
import {
  LabelValue,
  Contact,
  Address,
  VehicleIncident,
  StateProps,
} from '../components/common/Summary/types';
import jwt_decode from 'jwt-decode';
import {
  AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
  AutoIntegrationFlags,
  ClaimLobs,
  ContactRoles,
  CustomerTypes,
  PhoneTypes,
} from '../commonTypes';
import {NormalizedGoogleMapsData} from '../components/common/WithGoogleMaps/WithGoogleMaps';
import {AddressFieldsProps} from '../components/common/AddressFields/AddressFields';
import {ChangeEvent} from 'react';
import {GoogleMapsAutocompleteProps} from '../components/common/GoogleMapsAutocomplete/GoogleMapsAutocomplete';
import {SubmitClaimResponse} from '../services';
import {DraftClaimResponse} from '../components/common/Providers/Providers';

export const formatPhone = (phone?: string) => {
  if (phone) {
    if (phone[0] === '(') {
      return phone.slice(1, 4) + '-' + phone.slice(6, 9) + '-' + phone.slice(10);
    } else {
      return phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
    }
  }
};

export const formatPhoneToMatchValidation = (phone?: string) => {
  let numberToFormat: string = phone || '';

  if (/^(\(\d{3}\))\s(\d{3})-(\d{4})$/.test(numberToFormat)) {
    return numberToFormat;
  } else if (/^(\(\d{3}\))-(\d{3})-(\d{4})$/.test(numberToFormat)) {
    return numberToFormat.replace('-', ' ');
  } else if (/^\d{10}$/.test(numberToFormat)) {
    return `(${numberToFormat.slice(0, 3)}) ${numberToFormat.slice(3, 6)}-${numberToFormat.slice(
      6
    )}`;
  } else if (/^\d{11}$/.test(numberToFormat)) {
    return `(${numberToFormat.slice(1, 4)}) ${numberToFormat.slice(4, 7)}-${numberToFormat.slice(
      7
    )}`;
  } else if (/^\+\d{11}$/.test(numberToFormat)) {
    return `(${numberToFormat.slice(2, 5)}) ${numberToFormat.slice(5, 8)}-${numberToFormat.slice(
      8
    )}`;
  } else if (/^(\d{3}[\s-.]){2}\d{4}$/.test(numberToFormat)) {
    return `(${numberToFormat.slice(0, 3)}) ${numberToFormat.slice(4, 7)}-${numberToFormat.slice(
      8
    )}`;
  } else if (/^\d[\s-.](\d{3}[\s-.]){2}\d{4}$/.test(numberToFormat)) {
    return `(${numberToFormat.slice(2, 5)}) ${numberToFormat.slice(6, 9)}-${numberToFormat.slice(
      10
    )}`;
  } else if (/^\+\d[\s-.](\d{3}[\s-.]){2}\d{4}$/.test(numberToFormat)) {
    return `(${numberToFormat.slice(3, 6)}) ${numberToFormat.slice(7, 10)}-${numberToFormat.slice(
      11
    )}`;
  } else if (/^\d[\s-](\(\d{3}\))[\s-](\d{3})-(\d{4})$/.test(numberToFormat)) {
    return `(${numberToFormat.slice(3, 6)}) ${numberToFormat.slice(8, 11)}-${numberToFormat.slice(
      12
    )}`;
  } else if (/^\+\d[\s-](\(\d{3}\))[\s-](\d{3})-(\d{4})$/.test(numberToFormat)) {
    return `(${numberToFormat.slice(4, 7)}) ${numberToFormat.slice(9, 12)}-${numberToFormat.slice(
      13
    )}`;
  } else {
    return '';
  }
};

export const scrollToRef = (ref: any) =>
  window.scrollTo({top: ref?.current?.offsetTop - 75, behavior: 'smooth'});

export const assignToUrl = (url: string) => window.location.assign(url);

export const makePersonaCheck = (
  persona: CustomerTypes,
  validCustomerTypes: Array<CustomerTypes>,
  flag?: boolean | undefined,
  isAuthCheckFlag?: boolean | false
): boolean => {
  if (persona === CustomerTypes.SaCustomer && isAuthCheckFlag) {
    return flag === true && validCustomerTypes.includes(persona);
  }
  return validCustomerTypes.includes(persona);
};

export interface PersonaSchemaSwitch {
  is: any;
  then: yup.AnySchema;
  otherwise?: yup.AnySchema;
}

export const personaSchemaSwitch = ({is, then, otherwise}: PersonaSchemaSwitch) => {
  return validationSchemaSwitch({when: ['$userPersona', '$isAuthInsured'], is, then, otherwise});
};

export interface ValidationSchemaSwitch {
  when: string | string[];
  is: any;
  then: yup.AnySchema;
  otherwise?: yup.AnySchema;
}

export const validationSchemaSwitch = ({when, is, then, otherwise}: ValidationSchemaSwitch) => {
  return yup.mixed().when(when, {is, then, otherwise});
};

export interface FFSchemaSwitch {
  is: any;
  then: yup.AnySchema;
  otherwise?: yup.AnySchema;
}

export const featureFlagSchemaSwitch = ({is, then, otherwise}: FFSchemaSwitch) => {
  return validationSchemaSwitch({when: '$featureFlags', is, then, otherwise});
};

export const trimDataValues = (obj: any) => {
  const objKeys = Object.keys(obj);

  objKeys.forEach((key, index) => {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      trimDataValues(obj[key]);
    }
  });
};

export const stripCommas = (val: string) => {
  return val.replace(/,/g, '');
};

export const featureEnabled = (featureFlag: string | undefined) => {
  return featureFlag === 'on';
};

export const getContactPhone = (contact: Contact) => {
  if (contact?.primaryPhoneType === PhoneTypes.Mobile) {
    return contact?.cellNumber;
  } else if (contact?.primaryPhoneType === PhoneTypes.Work) {
    return contact?.workNumber;
  } else if (contact?.primaryPhoneType === PhoneTypes.Home) {
    return contact?.homeNumber;
  } else {
    return '';
  }
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeParts = (str: string) => {
  const parts = str.trim().split(' ');
  let capitalized: string = '';

  for (let i = 0; i < parts.length; i++) {
    const capWord = capitalize(parts[i]);
    capitalized = capitalized + (i === parts.length - 1 ? capWord : capWord + ' ');
  }

  return capitalized;
};

export const clearSessionData = () => {
  Cookies.remove('userSession');
  Cookies.remove('userToken');
};

export const clearAuthenticatedSessionData = () => {
  clearSessionData();
  Cookies.remove('refreshToken');
  Cookies.remove('path');
  Cookies.remove('authenticated-redirect');
  Cookies.remove('policyNumber');
  Cookies.remove('uuid');
};

export const isEmptyArray = (array: any[] | undefined): boolean => {
  return (array && array.length === 0) || array === undefined;
};

export const joinMappedArray = (array: any[] | undefined): string => {
  return (array || []).map((wwd: LabelValue) => wwd.label).join(', ');
};

export interface AddressDefaultValues {
  address?: string;
  city?: string;
  zipCode?: string;
  latLng?: google.maps.LatLngLiteral;
}

export interface GetAddressSwitchPropsArgs {
  parentFieldName: string;
  errors?: any;
  requiredState?: boolean;
  requiredCity?: boolean;
  requiredZipCode?: boolean;
  requiredStreetAddress?: boolean;
  selectedState?: State;
  stateChangeHandler?: (state: State | undefined) => void;
  defaultValues?: AddressDefaultValues;
  onStreetAddressChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onCityChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onZipCodeChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onAutocompleteChange?: (data: NormalizedGoogleMapsData | undefined) => any;
  onStreetAddressBlur?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onCityBlur?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onZipCodeBlur?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  defaultNormalizedData?: NormalizedGoogleMapsData;
  zipName?: string; // Ideally these last two args would not be necessary but this would
  streetName?: string; // require a change to expected key names in MT.
  getLatLng?: boolean;
}

export const getAddressSwitchProps = ({
  parentFieldName,
  errors,
  requiredState,
  requiredCity,
  requiredZipCode,
  requiredStreetAddress,
  selectedState,
  stateChangeHandler,
  defaultValues,
  onStreetAddressChange,
  onCityChange,
  onZipCodeChange,
  onStreetAddressBlur,
  onCityBlur,
  onZipCodeBlur,
  onAutocompleteChange,
  defaultNormalizedData,
  zipName,
  streetName,
  getLatLng,
}: GetAddressSwitchPropsArgs) => {
  const address = streetName || 'address';
  const zipCode = zipName || 'zipCode';
  const addressName = `${parentFieldName}.${address}`;
  const addressId = `${parentFieldName}Address`;
  const cityName = `${parentFieldName}.city`;
  const cityId = `${parentFieldName}City`;
  const stateName = `${parentFieldName}.state`;
  const stateId = `${parentFieldName}State`;
  const zipCodeName = `${parentFieldName}.${zipCode}`;
  const zipCodeId = `${parentFieldName}ZipCode`;
  const autocompleteName = `${parentFieldName}.autocompleteAddress`;
  const autocompleteId = `${parentFieldName}Autocomplete`;
  const latLngName = `${parentFieldName}.latlng`;

  const autocompleteAddressProps: GoogleMapsAutocompleteProps = {
    name: autocompleteName,
    inputProps: {'data-testid': autocompleteId},
    id: autocompleteId,
    InputLabelProps: {'aria-labelledby': autocompleteId},
    forFullAddress: true,
    hiddenFieldNames: {addressName, cityName, stateName, zipCodeName, latLngName},
    error:
      errors?.hasOwnProperty('autocompleteAddress') ||
      errors?.hasOwnProperty(address) ||
      errors?.hasOwnProperty('city') ||
      errors?.hasOwnProperty('state') ||
      errors?.hasOwnProperty(zipCode),
    helperText:
      errors?.autocompleteAddress?.message ||
      errors?.[address]?.message ||
      errors?.city?.message ||
      errors?.state?.message ||
      errors?.[zipCode]?.message,
    onNormalizedDataChange: onAutocompleteChange,
    defaultNormalizedData,
    getLatLng,
  };

  const manualAddressProps: AddressFieldsProps = {
    getLatLng,
    address: {
      id: addressId,
      name: addressName,
      error: errors?.hasOwnProperty(address),
      helperText: errors?.[address]?.message || (requiredStreetAddress ? '' : 'optional'),
      inputProps: {
        'data-testid': addressId,
      },
      autoComplete: 'on',
      defaultValue: defaultValues?.address || '',
      onChange: onStreetAddressChange,
      onBlur: onStreetAddressBlur,
      key: defaultValues?.address || '',
    },
    city: {
      id: cityId,
      name: cityName,
      error: errors?.hasOwnProperty('city'),
      helperText: errors?.city?.message || (requiredCity ? '' : 'optional'),
      inputProps: {
        'data-testid': cityId,
      },
      autoComplete: 'on',
      defaultValue: defaultValues?.city || '',
      onChange: onCityChange,
      onBlur: onCityBlur,
      key: defaultValues?.city || '',
    },
    state: {
      inputProps: {id: stateId},
      name: stateName,
      hasError: errors?.hasOwnProperty('state'),
      helperText: errors?.state?.message || (requiredState ? '' : 'optional'),
      autoComplete: 'on',
      testId: stateId,
      defaultValue: selectedState?.code || '',
      onChange: stateChangeHandler,
      key: selectedState?.code || '',
    },
    zipCode: {
      id: zipCodeId,
      name: zipCodeName,
      error: errors?.hasOwnProperty(zipCode),
      helperText: errors?.[zipCode]?.message || (requiredZipCode ? '' : 'optional'),
      inputProps: {
        'data-testid': zipCodeId,
      },
      autoComplete: 'on',
      defaultValue: defaultValues?.zipCode || '',
      onChange: onZipCodeChange,
      onBlur: onZipCodeBlur,
      key: defaultValues?.zipCode || '',
    },
    latLng: {
      name: latLngName,
      defaultValue: defaultValues?.latLng,
    },
  };

  return {
    autocompleteAddressProps,
    manualAddressProps,
  };
};

export interface AgentTokenInfo {
  'custom:agent-attributes': string;
  email: string;
  exp: number;
}

export interface AuthInsuredTokenInfo {
  family_name: string;
  given_name: string;
  email: string;
  exp: number;
}

export const getDefaultValuesFromToken = (userPersona: any, isAuthInsuredFlag?: boolean) => {
  const userToken = Cookies.get('userToken');
  if (
    userToken &&
    makePersonaCheck(userPersona, AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS, isAuthInsuredFlag, true)
  ) {
    try {
      if (userPersona === CustomerTypes.SaCustomer && isAuthInsuredFlag) {
        const jwtJSON = jwt_decode(userToken) as AuthInsuredTokenInfo;

        if (Date.now() < jwtJSON.exp * 1000) {
          const firstName = jwtJSON?.given_name;
          const lastName = jwtJSON?.family_name;
          const email = jwtJSON?.email;
          return {firstName, lastName, email};
        }
      }

      const jwtJSON = jwt_decode(userToken) as AgentTokenInfo;

      if (Date.now() < jwtJSON.exp * 1000) {
        const agentAttributes = JSON.parse(jwtJSON['custom:agent-attributes'] || '{}');
        const firstName = agentAttributes?.firstName;
        const lastName = agentAttributes?.lastName;
        const email = jwtJSON?.email;

        return {firstName, lastName, email};
      }
    } catch (error) {
      console.log(error);
      return {};
    }
  }
};

export const getDisplayName = (fn: string | undefined, ln: string | undefined) => {
  return fn && ln ? `${fn.trim()} ${ln.trim()}` : '';
};

export const authUserMatchesPolicyContact = (
  userPersona: CustomerTypes,
  displayName: string,
  contact: Contact
) => {
  const lowerCaseDisplayName = displayName.toLowerCase().trim();
  const rolesToMatch =
    userPersona === CustomerTypes.SaAgent
      ? [ContactRoles.Agent]
      : [ContactRoles.Insured, ContactRoles.CoveredParty, ContactRoles.PolicyContactExt];

  return (
    hasSomeContactRoles(contact, rolesToMatch) &&
    lowerCaseDisplayName &&
    lowerCaseDisplayName ===
      `${contact.firstName?.trim()} ${contact.lastName?.trim()}`.toLowerCase()
  );
};

export const getPhonePrefill = (contacts: Contact[] | undefined, userPersona: string) => {
  let phoneNumber: string = '';
  let phoneType: string = '';

  if (contacts && userPersona === CustomerTypes.SaAgent) {
    const agentContacts = contacts.filter((contact: Contact) =>
      contact.contactRoles?.includes(ContactRoles.Agent)
    );

    if (agentContacts.length > 0) {
      phoneNumber = getContactPhone(agentContacts[0]) || '';
      phoneType = agentContacts[0].primaryPhoneType || '';
    }
  }

  return {phoneNumber, phoneType};
};

// This checks if every value we pass in matches every value it's expecting
export const hasContactRoles = (contact: Contact | undefined, contactRoles: ContactRoles[]) => {
  return contactRoles.every(role => contact?.contactRoles?.includes(role));
};

// Checks if the contact has at least one of the designated roles
export const hasSomeContactRoles = (contact: Contact | undefined, contactRoles: ContactRoles[]) => {
  return hasSome({array: contactRoles, arrayToCheck: contact?.contactRoles});
};

export const getUserInfoFromToken = () => {
  const userToken = Cookies.get('userToken');
  if (userToken) {
    try {
      const decodedUserToken = jwt_decode(userToken) as any;
      const firstName = decodedUserToken?.given_name;
      const lastName = decodedUserToken?.family_name;
      return {firstName, lastName};
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  return {};
};

export const normalizeCheckboxData = (checkboxData: any[]) => {
  return checkboxData
    .map((v: string) => JSON.parse(v))
    .map((v: {label: string; value: string}) => {
      return v;
    });
};

export const nullToEmpty = (attributeValue: any) => {
  if (attributeValue !== undefined && attributeValue !== '') {
    return attributeValue;
  }
  return '';
};

export const returnRecentDuplicateClaims = (claims: any[]) => {
  if (claims.length < 10) {
    return claims.reverse();
  } else {
    return claims.splice(claims.length - 10, claims.length).reverse();
  }
};

export const formatAddressParts = ({addressLine1, city, state, postalCode, country}: Address) => {
  let formattedAddress: string = '';
  const parts = [addressLine1 || '', city || '', state || '', postalCode || '', country || ''];

  for (let i: number = 0; i < parts.length; i++) {
    if (parts[i]) {
      formattedAddress = formattedAddress.concat(parts[i]);
      const onStateAndZipExists = i === 2 && parts[3] !== '';

      if (
        !(
          onStateAndZipExists ||
          i === parts.length - 1 ||
          parts.slice(i + 1).filter((x: string) => x !== '').length === 0
        )
      ) {
        formattedAddress = formattedAddress.concat(', ');
      }

      if (onStateAndZipExists) {
        formattedAddress = formattedAddress.concat(' ');
      }
    }
  }

  return formattedAddress;
};

export const getVehicleIncidents = (submitClaimResponse: SubmitClaimResponse) => {
  const lob = submitClaimResponse?.lobs?.personalAuto || submitClaimResponse?.lobs?.commercialAuto;
  return lob?.vehicleIncidents;
};

export const isIntegrationEligible = (
  vehicleIncidents: VehicleIncident[] | undefined,
  service: AutoIntegrationFlags
) => {
  return (vehicleIncidents || []).find((incident: VehicleIncident) => incident[service]);
};

export const filterVehiclesByEligibility = (
  vehicleIncidents: VehicleIncident[] | undefined,
  service: AutoIntegrationFlags,
  exclusive?: boolean
) => {
  return (vehicleIncidents || []).filter((incident: any) =>
    exclusive ? !incident[service] : incident[service]
  );
};

export interface HasSomeProps {
  array: any[];
  arrayToCheck: any[] | undefined;
}

export const hasSome = ({array, arrayToCheck}: HasSomeProps) => {
  return [...array].some(item => arrayToCheck?.includes(item));
};

export const grabClaimLob = (draftResultLob: any) => {
  if (draftResultLob?.hasOwnProperty(ClaimLobs.PersonalAuto)) {
    return ClaimLobs.PersonalAuto;
  } else if (draftResultLob?.hasOwnProperty(ClaimLobs.CommercialAuto)) {
    return ClaimLobs.CommercialAuto;
  } else if (draftResultLob?.hasOwnProperty(ClaimLobs.Homeowners)) {
    return ClaimLobs.Homeowners;
  } else {
    return '' as ClaimLobs;
  }
};

export const formatCityStateZip = (fields: {city?: string; state?: string; zipCode?: string}) => {
  const city = fields.city || '';
  const state = fields.state ? (fields.city ? ', ' + fields.state : fields.state) : '';
  const zipCode = fields.zipCode
    ? fields.city || fields.state
      ? ' ' + fields.zipCode
      : fields.zipCode
    : '';

  return `${city}${state}${zipCode}`;
};

export const getMatchingPolicyContactById = (
  idToFind: string,
  draftClaimResponse: DraftClaimResponse | undefined
) => {
  return draftClaimResponse?.contacts?.find((c: Contact) => c.publicID && c.publicID === idToFind);
};

export const getMatchingPolicyContactByName = (
  draftClaimResponse: DraftClaimResponse | undefined,
  options: {
    firstName?: string;
    lastName?: string;
    companyName?: string;
    rolesToMatch?: ContactRoles[];
    rolesToExclude?: ContactRoles[];
  }
) => {
  if (options.firstName && options.lastName) {
    return draftClaimResponse?.contacts?.find((c: Contact) => {
      let hasAcceptableRoles: boolean = true;

      if (options.rolesToMatch?.length) {
        hasAcceptableRoles = hasSomeContactRoles(c, options.rolesToMatch);
      }

      if (options.rolesToExclude?.length) {
        hasAcceptableRoles = !hasSomeContactRoles(c, options.rolesToExclude);
      }

      return (
        hasAcceptableRoles &&
        `${c.firstName?.toLowerCase().trim()} ${c.lastName?.toLowerCase().trim()}` ===
          `${options.firstName?.toLowerCase().trim()} ${options.lastName?.toLowerCase().trim()}`
      );
    });
  }

  if (options.companyName) {
    return draftClaimResponse?.contacts?.find((c: Contact) => {
      let hasAcceptableRoles: boolean = true;

      if (options.rolesToMatch?.length) {
        hasAcceptableRoles = hasSomeContactRoles(c, options.rolesToMatch);
      }

      if (options.rolesToExclude?.length) {
        hasAcceptableRoles = !hasSomeContactRoles(c, options.rolesToExclude);
      }

      return (
        hasAcceptableRoles &&
        (c.displayName?.toLowerCase().trim() === options.companyName?.toLowerCase().trim() ||
          c.contactName?.toLowerCase().trim() === options.companyName?.toLowerCase().trim())
      );
    });
  }

  return undefined;
};

export const showAdjuster = (successState: StateProps) => {
  const persona: CustomerTypes = successState.customerType as CustomerTypes;
  const isAssociate = persona === CustomerTypes.Associate;
  const isAgent = persona === CustomerTypes.SaAgent;
  const triageLevel: string | undefined = successState?.triageLevel;
  const isLawsuitClaim = successState?.formData?.generalDetails?.lawsuitClaim || false;

  if ((isAssociate || isAgent) && !isLawsuitClaim) {
    return true;
  } else if (!isLawsuitClaim && triageLevel && triageLevel.match(/^ComplexityLevel[0-9]{1,}$/)) {
    return +triageLevel.replace('ComplexityLevel', '') < 4;
  } else {
    return false;
  }
};
