import React from 'react';
import {Box, Typography, Grid} from '@material-ui/core';
import {SummaryField} from '../../common/Summary/components/SummaryField/SummaryField';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';

export interface AdditionalNotesSummaryProps {
  additionalNotes?: string;
}

export const AdditionalNotesSummary = (props: AdditionalNotesSummaryProps) => {
  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.AdditionalNotes}
      displayName={WAYPOINTS[WaypointNames.AdditionalNotes].displayName}
    >
      <Box>
        <SummaryHeader headerText="Additional Notes" />
        <Box pt={3}>
          <Grid>
            <SummaryField>
              <Typography>{props?.additionalNotes}</Typography>
            </SummaryField>
          </Grid>
        </Box>
      </Box>
    </RegisterBreadcrumb>
  );
};
