import React from 'react';

export const NewAdjusterPhone = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8538 13.9478V16.5512C18.8558 17.0399 18.6516 17.5068 18.2915 17.8372C17.9314 18.1675 17.4487 18.3308 16.962 18.2868C14.2916 17.9966 11.7265 17.0841 9.47279 15.6226C7.37602 14.2903 5.59832 12.5126 4.26594 10.4158C2.79933 8.15186 1.88663 5.57429 1.60178 2.89191C1.55793 2.40669 1.72003 1.92539 2.04849 1.56557C2.37695 1.20575 2.84152 1.00054 3.32871 1.00009H5.93214C6.8033 0.991511 7.54582 1.63009 7.66775 2.49271C7.77763 3.32587 7.98142 4.14391 8.27522 4.93125C8.51363 5.56551 8.36114 6.28052 7.8847 6.76233V6.76233C7.23371 7.41332 7.08552 8.42904 7.62737 9.17334C8.47963 10.344 9.50987 11.3742 10.6805 12.2265C11.4248 12.7684 12.4406 12.6202 13.0915 11.9692V11.9692C13.5733 11.4927 14.2884 11.3402 14.9226 11.5787C15.71 11.8725 16.528 12.0762 17.3612 12.1861C18.2338 12.3092 18.8756 13.0668 18.8538 13.9478Z"
        stroke="#0173C6"
        strokeWidth="1.73561"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
