import React, {useContext, useEffect, useState} from 'react';
import {Box, Grid, Hidden, TextFieldProps} from '@material-ui/core';
import {SATextField, SATextFieldProps} from '../TextField/TextField';
import {State, StateDropdown, StateDropdownProps} from '../StateDropdown/StateDropdown';
import {useFormContext} from 'react-hook-form';
import {GooglePlacesContext} from '../Providers/Providers';
import {formatAddressParts} from '../../../utils/utils';

export interface AddressFieldsProps {
  address: SATextFieldProps & TextFieldProps;
  city: SATextFieldProps & TextFieldProps;
  state: StateDropdownProps;
  zipCode: SATextFieldProps & TextFieldProps;
  latLng?: {name: string; defaultValue?: google.maps.LatLngLiteral; key?: string};
  getLatLng?: boolean;
}

export const AddressFields = ({
  address,
  city,
  state,
  zipCode,
  latLng,
  getLatLng,
}: AddressFieldsProps) => {
  const {register} = useFormContext();
  const {getPlaceFromQuery, placeLatLng} = useContext(GooglePlacesContext);
  const [streetAddressInput, setStreetAddressInput] = useState<string>('');
  const [cityInput, setCityInput] = useState<string>('');
  const [stateInput, setStateInput] = useState<string>('');
  const [zipCodeInput, setZipCodeInput] = useState<string>('');

  const onStreetAddressChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (getLatLng) {
      const value = event.target.value;
      setStreetAddressInput(value);
    }
    address.onChange && address.onChange(event);
  };

  const onCityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (getLatLng) {
      const value = event.target.value;
      setCityInput(value);
    }
    city.onChange && city.onChange(event);
  };

  const onStateChange = (value: State | undefined) => {
    if (value?.code && getLatLng) {
      setStateInput(value.code);
    }
    state.onChange && state.onChange(value);
  };

  const onZipCodeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (getLatLng) {
      const value = event.target.value;
      setZipCodeInput(value);
    }
    zipCode.onChange && zipCode.onChange(event);
  };

  useEffect(() => {
    if (getLatLng && (streetAddressInput || cityInput || stateInput || zipCodeInput)) {
      const queryInput = formatAddressParts({
        addressLine1: streetAddressInput,
        city: cityInput,
        state: stateInput,
        postalCode: zipCodeInput,
      });
      getPlaceFromQuery(queryInput);
    }
  }, [streetAddressInput, cityInput, stateInput, zipCodeInput]);

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12} md={6}>
        <SATextField
          {...address}
          label="Street Name/Address"
          InputLabelProps={{
            'aria-labelledby': address.id,
          }}
          inputRef={register()}
          inputProps={{...address.inputProps}}
          onChange={onStreetAddressChange}
          showCharacterCount
          characterLimit={60}
        />
      </Grid>
      <Hidden smDown>
        <Grid item md={4}>
          <Box />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={4}>
        <SATextField
          {...city}
          label="City"
          InputLabelProps={{
            'aria-labelledby': city.id,
          }}
          inputRef={register()}
          onChange={onCityChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StateDropdown {...state} inputRef={register()} onChange={onStateChange} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SATextField
          {...zipCode}
          label="ZIP Code"
          InputLabelProps={{
            'aria-labelledby': zipCode.id,
          }}
          inputRef={register()}
          onChange={onZipCodeChange}
        />
      </Grid>
      {getLatLng && (latLng?.defaultValue || placeLatLng) && (
        <input
          type="hidden"
          name={latLng?.name}
          ref={register()}
          value={JSON.stringify(latLng?.defaultValue || placeLatLng)}
          data-testid="hiddenLatLng"
        />
      )}
    </Grid>
  );
};
