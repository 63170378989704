import React from 'react';
import {Box, Typography} from '@material-ui/core';

interface CheckboxErrorProps {
  errorMessage?: string;
  testId?: string;
}

export const CheckboxError = ({errorMessage, testId}: CheckboxErrorProps) => {
  return (
    <>
      {errorMessage && (
        <Box pb={3}>
          <Typography variant="body1" color="error" data-testid={testId}>
            {errorMessage}
          </Typography>
        </Box>
      )}
    </>
  );
};
