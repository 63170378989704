import React from 'react';
import {Box, Button, Typography, Hidden, Grid, Divider} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import TrashCan from '../../../assets/images/TrashCan.svg';

export interface DynamicContainerProps {
  displayAddButton?: undefined | boolean;
  displayRemoveButton?: undefined | boolean;
  header?: string | JSX.Element;
  children?: JSX.Element;
  addButtonText?: string;
  removeButtonText?: string;
  removeDataTestId?: string;
  disableButton?: boolean;
  addController?: (props: any) => void;
  removeController?: (props: any) => void;
  extraHeaderPadding?: boolean;
  extraHeaderPaddingBottom?: boolean;
  testid?: string;
  displayFieldDivider?: JSX.Element;
  alignAddButton?: string;
  displayRequiredText?: undefined | boolean;
}

export const WhiteAddIcon = withStyles({
  root: {
    fill: '#FFFFFF',
    height: '32px',
    width: '32px',
  },
})(Add);

export const ButtonText = withStyles(theme => ({
  body1: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  body2: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '19px',
    color: theme.palette.error.main,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
}))(Typography);

export const DynamicContainer = (props: DynamicContainerProps) => (
  <Box>
    <Grid container alignItems="center" justify="space-between">
      <Grid item xs={8}>
        {props.header && (
          <Box
            py={props.extraHeaderPadding ? 1 : 0}
            pb={props.extraHeaderPaddingBottom ? 2 : 0}
            fontSize={18}
            fontWeight="fontWeightBold"
            data-testid="DynamicHeader"
          >
            {props.header}
          </Box>
        )}
      </Grid>
      <Grid item>
        {props.displayRemoveButton && (
          <Button
            style={{zIndex: 1}}
            disabled={props.disableButton}
            endIcon={<img alt="Remove" src={TrashCan} width="32px" height="32px" />}
            onClick={props.removeController}
            size="small"
            data-testid={props.removeDataTestId}
          >
            <Hidden xsDown implementation="css">
              <ButtonText variant="body2">{props.removeButtonText}</ButtonText>
            </Hidden>
          </Button>
        )}
        {props.displayRequiredText && (
          <Button style={{zIndex: 1}} disabled={true} size="small">
            <Hidden smUp implementation="css">
              <ButtonText variant="body2" style={{fontSize: '12px', height: '28px'}}>
                {'REQUIRED'}
              </ButtonText>
            </Hidden>
            <Hidden xsDown implementation="css">
              <ButtonText variant="body2" style={{height: '28px'}}>
                {'REQUIRED'}
              </ButtonText>
            </Hidden>
          </Button>
        )}
      </Grid>
    </Grid>
    <Box>{React.Children.only(props.children)}</Box>
    {props.displayFieldDivider && props.displayFieldDivider}

    {(typeof props.displayAddButton === 'undefined' || props.displayAddButton) && (
      <Box>
        {props.alignAddButton === 'right' ? (
          <Grid container justify="flex-end">
            {props.displayRemoveButton && (
              <Grid item xs={12}>
                <Box pb={3}>
                  <Divider variant="middle" />
                </Box>
              </Grid>
            )}
            <Button
              disabled={props.disableButton}
              startIcon={<WhiteAddIcon height={32} width={32} />}
              onClick={props.addController}
              size="large"
              color="secondary"
              variant="contained"
              data-testid={props.testid}
            >
              <ButtonText variant="body1">{props.addButtonText}</ButtonText>
            </Button>
          </Grid>
        ) : (
          <Grid container>
            {props.displayRemoveButton && (
              <Grid item xs={12}>
                <Box pb={3}>
                  <Divider variant="middle" />
                </Box>
              </Grid>
            )}
            <Button
              disabled={props.disableButton}
              startIcon={<WhiteAddIcon height={32} width={32} />}
              onClick={props.addController}
              size="large"
              color="secondary"
              variant="contained"
              data-testid={props.testid}
            >
              <ButtonText variant="body1">{props.addButtonText}</ButtonText>
            </Button>
          </Grid>
        )}
      </Box>
    )}
  </Box>
);
