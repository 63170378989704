import {Box} from '@material-ui/core';
import {SAText} from '@saux/design-system-react';
import React from 'react';

interface IncidentInfoProps {
  text: string;
  values: string[];
}

export const IncidentInfo = ({text, values}: IncidentInfoProps) => {
  return (
    <Box pb={4}>
      <Box pb={1}>
        <SAText text={text} />
      </Box>
      {values.map(
        (value: string, index: number) =>
          value && (
            <Box pl={1} pb={1} key={index}>
              <SAText weight="bold" text={value} />
            </Box>
          )
      )}
    </Box>
  );
};
