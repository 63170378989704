import React from 'react';
import {
  SelectProps,
  Select,
  makeStyles,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  whiteTextField: {
    background: theme.palette.common.white,
  },
}));

export interface SASelectProps {
  helperText?: string;
  omitBlankOption?: boolean;
}

export const SASelect = (props: SelectProps & SASelectProps) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" fullWidth error={props.error}>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <Select
        {...props}
        className={classes.whiteTextField}
        id={props.id}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        native
        label={props.label}
        inputProps={{
          ...props.inputProps,
        }}
        inputRef={props.inputRef}
        error={props.error}
        value={props.value}
        defaultValue={props.defaultValue}
      >
        {!props.omitBlankOption && <option aria-label="None" value="" />}
        {props.children}
      </Select>
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};
