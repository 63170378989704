import {useMediaQuery, useTheme} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {SignedResponse} from '../../../../services';
import {Icon} from '../../Icon/Icon';
import {FileAndStatus, UploadStatus} from './UploadModal';

const DropBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #80b8e2;
  border-radius: 4px;
  margin-top: 30px;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  margin-bottom: 30px;
`;
const CloudBackground = styled.div`
  background-color: #f4f6fa;
  border-radius: 4px;
  padding-top: 30px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  width: 300px;
  margin: auto;
`;
const DropText = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 15px;
`;
const SelectButton = styled.label`
  font-weight: 700;
  font-size: 14px;
  background-color: #0173c6;
  color: #ffffff;
  border-radius: 3px;
  border: 1px solid #0173c6;
  padding-top: 7px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 150px;
`;
export const SelectFiles = styled.input.attrs(props => ({
  accept:
    '.avi, .bmp, .csv, .doc, .docx, .gif, .htm, .html, .jpeg, .jpg, .mdi, .mov, .mp3, .mpg, .pdf, .png, .ppt, .pptx, .rtf, .tif, .tiff, .txt, .wav, .wma, .xls, .xlsx',
}))`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

interface DropProps {
  data: {
    fileList: FileAndStatus[];
  };
  dispatch: React.Dispatch<{
    type: any;
    inDropZone?: any;
    files: FileAndStatus[];
    status?: string | undefined;
    signedResponse?: SignedResponse | undefined;
  }>;
  stagingHandler: Function;
}

export default ({data, dispatch, stagingHandler}: DropProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const drop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    let files: FileAndStatus[] = [];

    for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
      files.push({
        file: e.dataTransfer.files[i],
        status: UploadStatus.Pending,
        signedResponse: null,
        uploading: false,
      });
    }

    const existingFiles = data.fileList.map((f: FileAndStatus) => f.file.name);
    files = files.filter(f => !existingFiles.includes(f.file.name));

    if (files && files.length > 0) {
      dispatch({type: 'ADD_FILE_TO_LIST', files});
      stagingHandler(true);
    }
  };

  const chooseFiles = (e: FileList | null) => {
    let files: FileAndStatus[] = [];

    if (e) {
      for (let i = 0; i < e.length; i += 1) {
        files.push({
          file: e[i],
          status: UploadStatus.Pending,
          signedResponse: null,
          uploading: false,
        });
      }

      const existingFiles = data.fileList.map((f: FileAndStatus) => f.file.name);
      files = files.filter(f => !existingFiles.includes(f.file.name));

      if (files.length > 0) {
        dispatch({type: 'ADD_FILE_TO_LIST', files});
        stagingHandler(true);
      }
    }
  };

  return (
    <DropBox
      onDrop={e => drop(e)}
      onDragOver={e => dragOver(e)}
      onDragLeave={e => dragLeave(e)}
      data-testid="dropBox"
    >
      {isMobile ? (
        <div />
      ) : (
        <>
          <CloudBackground>
            <Icon name="uploadCloud" />
            <DropText>Drag and Drop Files here</DropText>
          </CloudBackground>
          <DropText>or</DropText>
        </>
      )}
      <SelectButton htmlFor="file">
        SELECT FILES
        <SelectFiles
          type="file"
          name="file"
          id="file"
          data-testid="selectFiles"
          multiple
          onChange={e => {
            chooseFiles(e.target.files);
          }}
        />
      </SelectButton>
    </DropBox>
  );
};
