import React, {useEffect, useState} from 'react';
import {
  Dialog,
  Box,
  Typography,
  withStyles,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Loading from '../../../assets/images/Loading.svg';
import CheckIcon from '@material-ui/icons/Check';
import {SAHeader} from '../Header/Header';

const SubmitMessage = withStyles(theme => ({
  body1: {
    fontSize: 20,
    fontWeight: 600,
    color: 'black',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}))(Typography);

export interface SubmissionDialogProps {
  isSubmitting: boolean;
}

export const SubmissionDialog = (props: SubmissionDialogProps) => {
  const submitMessages = [
    "We're uploading your claim details",
    'Generating your claim number',
    'Assigning your claim to an adjuster',
    'Reviewing your claim for next steps',
  ];

  const [submitStatus, setSubmitStatus] = useState('Just a moment');
  const [displayCheck, setDisplayCheck] = useState([false, false, false, false]);

  useEffect(() => {
    let i = 0;
    const timer = setInterval(() => {
      switch (i) {
        case 0:
          setSubmitStatus(submitMessages[i]);
          i++;
          break;
        case 1:
          setDisplayCheck([true, false, false, false]);
          setSubmitStatus(submitMessages[i]);
          i++;
          break;
        case 2:
          setDisplayCheck([true, true, false, false]);
          setSubmitStatus(submitMessages[i]);
          i++;
          break;
        case 3:
          setDisplayCheck([true, true, true, false]);
          setSubmitStatus(submitMessages[i]);
          i++;
          break;
        case 4:
          return () => clearInterval(timer);
      }
    }, 2800);
  }, [props.isSubmitting]);

  const theme = useTheme();
  const modalFullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog open={props.isSubmitting} fullScreen={modalFullScreen}>
      {modalFullScreen && <SAHeader />}
      <Grid container alignItems="center" direction="column">
        <Box
          textAlign="center"
          my={{xs: 4, sm: 8}}
          mx={{xs: 2, sm: 12, md: 16}}
          width={{xs: 294, sm: 328}}
          height={{xs: 274, sm: 280}}
          justifyContent="center"
        >
          <Grid item>
            <Box textAlign="center">
              <SubmitMessage variant="body1">{submitStatus}</SubmitMessage>
            </Box>
          </Grid>
          <Grid item>
            <Box textAlign="center">
              <img alt="Loading" src={Loading} width="200px" height="100px" />
            </Box>
          </Grid>
          <Grid container item alignItems="center">
            <Grid item>
              <Box
                fontWeight={500}
                fontSize={14}
                justifyContent="center"
                ml={{xs: '18px', sm: '30px'}}
                width="100%"
              >
                <Box display="flex" flexDirection="row" flexWrap="nowrap">
                  <Box>
                    <CheckIcon
                      fontSize="small"
                      htmlColor={displayCheck[0] ? '#0173C6' : '#FFFFFF'}
                    />
                  </Box>
                  <Box pb={2} flexWrap="nowrap">
                    {submitMessages[0]}
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" flexWrap="nowrap">
                  <Box>
                    <CheckIcon
                      fontSize="small"
                      htmlColor={displayCheck[1] ? '#0173C6' : '#FFFFFF'}
                    />
                  </Box>
                  <Box pb={2} flexWrap="nowrap">
                    {submitMessages[1]}
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" flexWrap="nowrap">
                  <Box>
                    <CheckIcon
                      fontSize="small"
                      htmlColor={displayCheck[2] ? '#0173C6' : '#FFFFFF'}
                    />
                  </Box>
                  <Box pb={2} flexWrap="nowrap">
                    {submitMessages[2]}
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" flexWrap="nowrap">
                  <Box>
                    <CheckIcon
                      fontSize="small"
                      htmlColor={displayCheck[3] ? '#0173C6' : '#FFFFFF'}
                    />
                  </Box>
                  <Box pb={2} flexWrap="nowrap">
                    {submitMessages[3]}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Dialog>
  );
};
