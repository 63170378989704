import React, {useState, useRef, useContext, useEffect} from 'react';
import {Box, Typography, Grid, FormHelperText, withStyles} from '@material-ui/core';
import {SADatePicker} from '../../common/DatePicker/DatePicker';
import {Icon} from '../../common/Icon/Icon';
import {SATimePicker} from '../../common/TimePicker/TimePicker';
import {useFormContext} from 'react-hook-form';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import * as yup from 'yup';
import {
  RegisterBreadcrumb,
  WaypointNames,
  WAYPOINTS,
} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {
  formatAddressParts,
  getAddressSwitchProps,
  grabClaimLob,
  makePersonaCheck,
} from '../../../utils/utils';
import {State, states} from '../../common/StateDropdown/StateDropdown';
import {GooglePlacesContext, useFeatureFlags} from '../../common/Providers/Providers';
import {
  zipCodeValidation,
  cityValidation,
  streetAddressValidation,
  stateValidation,
} from '../../../validations';
import {trackEvent} from '../../../services/analytics';
import {AddressSwitch} from '../../common/AddressSwitch/AddressSwitch';
import {DynamicRadioList, RadioOption} from '../../common/DynamicRadioList/DynamicRadioList';
import {
  ClaimLobs,
  CustomerTypes,
  AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
} from '../../../commonTypes';
import {CheckboxWithLabel} from '../../common/CheckboxWithLabel/CheckboxWithLabel';
import {CheckboxError} from '../../common/CheckboxError/CheckboxError';
import {Snowplow} from '../../../pages/utils/snowplow';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {fetchFraudLanguage, getCatastrophe} from '../../../services';
import {useSetCatastropheAtomState, useUserAtomState} from '../../../atoms';
import {NormalizedGoogleMapsData} from '../../common/WithGoogleMaps/WithGoogleMaps';
import {LawsuitClaimPrompt} from '../../common/LawsuitClaimPrompt/LawsuitClaimPrompt';
import {formatISO} from 'date-fns';
import {LogRocket} from '../../../services/logRocket';
import {Contact} from '../Summary/types/index';
import {SATextField} from '../TextField/TextField';

const TimePickerHint = withStyles({
  root: {
    marginLeft: 10,
  },
})(FormHelperText);

export interface GeneralDetailsProps {
  lossDate: Date | null;
  lossTime: Date | null;
  lossTimezone?: string | undefined;
  setCanContinue: (value: boolean) => void;
  setAcknowledgementForFormContext: (value: boolean) => void;
  setIsLawsuitClaim: (value: boolean) => void;
}

export const GeneralDetailsSchema = {
  generalDetails: yup.object().shape({
    acknowledgement: yup.boolean().oneOf([true], 'Read and acknowledge the below statement'),
    autocompleteAddress: yup.string().when('addressId', {
      is: (value: string) => value === 'other' || value === undefined,
      then: yup.string().when('manualAddressEntry', {
        is: (value: string) => !(value === 'manualAddress' || value === undefined),
        then: yup.string().required('Please enter the location of the incident').nullable(),
        otherwise: yup.string().nullable(),
      }),
      otherwise: yup.string().nullable(),
    }),
    manualAddressEntry: yup.string().nullable(),
    streetName: streetAddressValidation().nullable(),
    city: yup.string().when('addressId', {
      is: (value: string) => value === 'other' || value === undefined,
      then: cityValidation(true).required('City is required'),
      otherwise: cityValidation(true),
    }),
    state: yup.string().when('addressId', {
      is: (value: string) => value === 'other' || value === undefined,
      then: stateValidation().required('State is required'),
      otherwise: stateValidation().nullable(),
    }),
    zip: zipCodeValidation(true),
    geocode: yup.object().shape({
      lat: yup.number(),
      lng: yup.number(),
    }),
    addressId: yup.string().when('canPrefill', {
      is: (value: string) => value === 'true',
      then: yup.string().required('Please select the location of the incident').nullable(),
    }),
    lawsuitClaim: yup.boolean().nullable(),
  }),
};

export const DOL_UNKNOWN_OPTION = {
  label: 'The selected incident date is approximate. I am uncertain of the exact date.',
  value: 'DOL Unknown',
  checked: false,
  disabled: false,
  'data-testid': 'DOLUnknown',
};

export const GeneralDetailsTestSchema = yup.object().shape({
  ...GeneralDetailsSchema,
});

export const GeneralDetails = (props: GeneralDetailsProps) => {
  const [userAtomState] = useUserAtomState();
  const draftClaimResponse = userAtomState?.draftClaimResponse?.result;
  const userPersona = userAtomState?.gettingStarted?.customerType;
  const isAuthInsuredFlag = userAtomState?.gettingStarted?.isAuthInsured;

  const [selectedState, setSelectedState] = useState<State | undefined>(undefined);
  const [fraudAcknowledged, setFraudAcknowledged] = useState(false);

  const formMethods = useFormContext();
  const {register, errors, setValue} = formMethods;

  const {featureFlags} = useFeatureFlags();

  const lob: string = grabClaimLob(draftClaimResponse?.lobs);
  const isHomeowners = lob === ClaimLobs.Homeowners;

  //getting insured contact to show insured address radio button when not HO
  const firstInsuredContact = draftClaimResponse?.contacts?.find((x: Contact) =>
    x.contactRoles?.includes('insured')
  );

  //Grabbing location from draft claim and formatting auto location to match HO
  const locations = isHomeowners
    ? draftClaimResponse?.policy.lobs?.homeowner?.locations
    : [{locationAddress: firstInsuredContact?.primaryAddress}];
  const insuredAddress =
    locations && locations.length > 0 ? locations[0].locationAddress : undefined;

  const [addressId, setAddressId] = useState('');

  const isAuthUser =
    userPersona &&
    makePersonaCheck(
      userPersona as CustomerTypes,
      AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
      isAuthInsuredFlag,
      true
    );

  const canPrefill = Boolean(
    insuredAddress &&
      insuredAddress.city &&
      insuredAddress.state &&
      insuredAddress.publicID &&
      states.find(x => x.code === insuredAddress?.state)
  );

  //only show prefill address if insuredAddress is available and either HO or logged in user
  const showAddressRadioOption = (canPrefill && (isHomeowners || isAuthUser)) || false;

  const [stateList, setStateList] = useState<State[]>([]);
  const setCatastropheAtomState = useSetCatastropheAtomState();
  const isAssociate = userPersona === CustomerTypes.Associate;
  const {getPlaceFromQuery, placeLatLng} = useContext(GooglePlacesContext);

  const canContinueInForm = () => {
    props.setCanContinue(true);
    register('generalDetails.acknowledgement');
    setValue('generalDetails.acknowledgement', true);
    props.setAcknowledgementForFormContext(true);
  };

  const onStateChange = async (state: string) => {
    if (state === selectedState?.code) {
      props.setAcknowledgementForFormContext(true);
    } else {
      const stateFromDB = stateList.find(x => x.code === state);
      setSelectedState(stateFromDB);

      if (state && props.lossDate && isAssociate && featureFlags.FF_DCX_2153) {
        const lossDateFormatted = formatISO(new Date(props.lossDate));

        const catPayload = {
          lossLocation: state,
          lossDate: lossDateFormatted,
        };
        try {
          const {data} = await getCatastrophe(catPayload);
          if (Array.isArray(data?.data)) {
            setCatastropheAtomState(data.data);
          } else {
            setCatastropheAtomState([]);
          }
        } catch (error) {
          setCatastropheAtomState([]);
          LogRocket.log('Get Catastrophe Error:', error);
        }
      }

      if (stateFromDB) {
        if (!stateFromDB.hasFraudLanguage) {
          canContinueInForm();
        } else {
          setFraudAcknowledged(false);
          setValue('generalDetails.acknowledgement', false);
          props.setAcknowledgementForFormContext(false);
        }
      } else {
        canContinueInForm();
      }
    }
  };

  const stateChangeHandler = (state: State | undefined) => {
    if (state?.code) {
      onStateChange(state.code);
    } else {
      setSelectedState(undefined);
      setFraudAcknowledged(false);
    }
  };

  const onAutocompleteChange = (data: NormalizedGoogleMapsData | undefined) => {
    if (data?.state) {
      onStateChange(data.state);
    } else {
      setSelectedState(undefined);
      setFraudAcknowledged(false);
    }
  };

  const incidentTimeRef = useRef(null);

  const onAddressChange = (value: string) => {
    setAddressId(value);

    if (value && value !== 'other') {
      stateChangeHandler(states.find(x => x.code === insuredAddress?.state));
    } else if (isAssociate) {
      setCatastropheAtomState([]);
    }
  };

  const addressSwitchArgs = {
    parentFieldName: 'generalDetails',
    errors: errors?.generalDetails,
    requiredState: true,
    requiredCity: true,
    selectedState,
    stateChangeHandler,
    onAutocompleteChange,
    zipName: 'zip',
    streetName: 'streetName',
    getLatLng: true,
  };

  const {manualAddressProps, autocompleteAddressProps} = getAddressSwitchProps(addressSwitchArgs);

  const onAddressEntryMethodChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    setSelectedState(undefined);

    if (isAssociate) {
      setCatastropheAtomState([]);
    }
  };

  const ADDRESS_OPTIONS: RadioOption[] = [
    {
      label: (isAuthUser && insuredAddress?.displayName) || 'Insured location',
      value: insuredAddress?.publicID || '',
      testId: 'generalDetailsPrefillAddress',
    },
    {
      label: 'Other',
      value: 'other',
      testId: 'generalDetailsOtherAddress',
    },
  ];

  useEffect(() => {
    if (makePersonaCheck(userPersona as CustomerTypes, [CustomerTypes.Associate])) {
      canContinueInForm();
    } else {
      fetchFraudLanguage()
        .then(response => {
          const {data} = response;

          if (typeof data.data === 'boolean') {
            canContinueInForm();
          } else {
            setStateList(data.data as State[]);
          }
        })
        .catch(_error => {
          canContinueInForm();
        });
    }

    if (showAddressRadioOption) {
      getPlaceFromQuery(
        formatAddressParts({
          addressLine1: insuredAddress?.addressLine1,
          city: insuredAddress?.city,
          state: insuredAddress?.state,
          postalCode: insuredAddress?.postalCode,
        })
      );
    }
  }, []);

  return (
    <RegisterBreadcrumb
      waypointName={WaypointNames.GeneralDetails}
      displayName={WAYPOINTS[WaypointNames.GeneralDetails].displayName}
    >
      <FormContainer header="General Details">
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Box pb={1}>
                <SADatePicker
                  label="Incident Date"
                  name="generalDetails.lossDate"
                  inputRef={register}
                  defaultValue=""
                  disabled
                  value={props.lossDate}
                  keyboardIcon={<Icon name="calendarDisabled" />}
                  id="lossDate"
                  InputLabelProps={{'aria-labelledby': 'lossDate'}}
                />
              </Box>
            </Grid>
            {isHomeowners && (
              <Grid item xs={12}>
                <Box pb={3} width="100%">
                  <CheckboxWithLabel
                    name="generalDetails.dateOfLossUnknown"
                    formControlLabelProps={{
                      onChange: (_, checked) => {
                        trackEvent({
                          category: 'Date Of Loss Unknown',
                          label: `Date of loss is unknown: ${checked}`,
                        });

                        Snowplow.track.dateOfLossUnknown({
                          dateOfLossUnknown: checked,
                          logrocketurl: userAtomState.logrocketurl,
                          persona: userPersona,
                        });
                      },
                      label: DOL_UNKNOWN_OPTION.label,
                    }}
                    checkboxProps={{
                      id: 'dateOfLossUnknown',
                    }}
                  />
                </Box>
              </Grid>
            )}
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6} md={props.lossTimezone ? 3 : 6}>
                <Box>
                  <div ref={incidentTimeRef} />
                  <SATimePicker
                    label="Incident Time"
                    name="generalDetails.lossTime"
                    defaultValue={null}
                    disabled
                    value={props.lossTime}
                    keyboardIcon={<Icon name="clockDisabled" />}
                    InputLabelProps={{
                      'aria-labelledby': 'lossTime',
                      for: 'lossTime',
                    }}
                    id="lossTime"
                  />

                  <TimePickerHint error={errors?.generalDetails?.hasOwnProperty('lossTime')}>
                    {errors?.generalDetails?.lossTime?.message || 'General Estimate'}
                  </TimePickerHint>
                </Box>
              </Grid>
              {props.lossTimezone && (
                <Grid item xs={12} sm={6} md={3}>
                  <Box>
                    <SATextField
                      label="Time Zone"
                      id="lossTimezone"
                      name="generalDetails.lossTimezone"
                      disabled
                      value={props.lossTimezone}
                      SelectProps={{native: true}}
                      InputLabelProps={{
                        'aria-labelledby': 'lossTimezone',
                      }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <Box pb={1}>
                    <Typography variant="body1">
                      Please tell us where the incident occurred
                    </Typography>
                  </Box>
                  <input
                    type="hidden"
                    ref={register}
                    name={'generalDetails.canPrefill'}
                    value={showAddressRadioOption.toString()}
                  />
                  {showAddressRadioOption && (
                    <DynamicRadioList
                      name="generalDetails.addressId"
                      defaultValue=""
                      options={ADDRESS_OPTIONS}
                      onChange={onAddressChange}
                      hasErrors={errors?.generalDetails?.hasOwnProperty('addressId')}
                      errorMessage={errors?.generalDetails?.addressId?.message}
                      data-testid="incidentOccurredRadioList"
                    />
                  )}
                </Grid>
                {(addressId === 'other' || !showAddressRadioOption) && (
                  <AddressSwitch
                    manualAddressProps={manualAddressProps}
                    autocompleteAddressProps={autocompleteAddressProps}
                    parentFieldName="generalDetails"
                    onAddressEntryMethodChange={onAddressEntryMethodChange}
                  />
                )}
                {addressId === insuredAddress?.publicID && (
                  <>
                    <input
                      type="hidden"
                      ref={register}
                      name="generalDetails.streetName"
                      defaultValue={insuredAddress.addressLine1 || ''}
                    />
                    <input
                      type="hidden"
                      ref={register}
                      name="generalDetails.city"
                      defaultValue={insuredAddress.city || ''}
                    />
                    <input
                      type="hidden"
                      ref={register}
                      name="generalDetails.state"
                      defaultValue={insuredAddress.state || ''}
                    />
                    <input
                      type="hidden"
                      ref={register}
                      name="generalDetails.zip"
                      defaultValue={insuredAddress.postalCode || ''}
                    />
                    {placeLatLng && (
                      <input
                        type="hidden"
                        ref={register}
                        name="generalDetails.latlng"
                        value={JSON.stringify(placeLatLng)}
                        data-testid="generalDetailsLatLng"
                      />
                    )}
                  </>
                )}
                <LawsuitClaimPrompt
                  persona={userPersona || ''}
                  logrocketurl={userAtomState?.logrocketurl || ''}
                  setIsLawsuitClaim={props.setIsLawsuitClaim}
                />
              </Grid>
            </Grid>
            <UserPersonaSwitch
              ifPersonas={[CustomerTypes.Associate]}
              otherwise={
                selectedState?.hasFraudLanguage && (
                  <Grid item xs={12}>
                    <Box>
                      <CheckboxError
                        errorMessage={errors?.generalDetails?.acknowledgement?.message}
                      />
                      <CheckboxWithLabel
                        name="generalDetails.acknowledgement"
                        testId="acknowledgement"
                        formControlLabelProps={{
                          onChange: (_, checked) => {
                            setFraudAcknowledged(checked);
                            props.setCanContinue(checked);
                            props.setAcknowledgementForFormContext(checked);
                          },
                          disabled: fraudAcknowledged,
                          label: selectedState.fraudLanguage,
                        }}
                        checkboxProps={{
                          id: 'acknowledgement',
                        }}
                      />
                    </Box>
                  </Grid>
                )
              }
            />
            <input
              name="generalDetails.fraudLanguage"
              ref={register}
              type="hidden"
              value={selectedState?.fraudLanguage || ''}
            />
          </Grid>
        </Box>
      </FormContainer>
    </RegisterBreadcrumb>
  );
};
