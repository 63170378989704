import React from 'react';
import {Calendar, CalendarDisabled} from './assets/Calendar';
import {Clock, ClockDisabled} from './assets/Clock';
import {ImpactSpot} from './assets/ImpactSpot';
import {DamageSpot} from './assets/DamageSpot';
import {RightArrow} from './assets/RightArrow';
import {Email} from './assets/Email';
import {Phone} from './assets/Phone';
import {Download} from './assets/Download';
import {Help} from './assets/Help';
import {Warning} from './assets/Warning';
import {Checkmark} from './assets/Checkmark';
import {AutoPolicy} from './assets/AutoPolicy';
import {HomePolicy} from './assets/HomePolicy';
import {UserIcon} from './assets/UserIcon';
import {NewAdjusterPhone} from './assets/NewAdjusterPhone';
import {NewAdjusterMail} from './assets/NewAdjusterMail';
import {UploadCloud} from './assets/UploadCloud';
import {SpeechBubble} from './assets/SpeechBubble';
import {InfoBubble} from './assets/InfoBubble';
import {GreyCheckmark} from './assets/GreyCheckmark';
import {AlertIcon} from './assets/Alert';
import {RoundCheckBox} from './assets/RoundCheckBox';
import {GreenRoundCheckboxAdjustable} from './assets/GreenRoundCheckboxAdjustable';
import {ErrorIcon} from './assets/Error';
import {QuestionBubble} from './assets/QuestionBubble';
import {Save} from './assets/Save';
import {PrivacyChoice} from './assets/PrivacyChoice';

export interface IconProps {
  name: string;
  additionalProps?: {};
}

export interface IconInterface {
  [key: string]: any;
}

const icons: IconInterface = {
  calendar: <Calendar />,
  calendarDisabled: <CalendarDisabled />,
  clock: <Clock />,
  clockDisabled: <ClockDisabled />,
  impactSpot: <ImpactSpot />,
  damageSpot: <DamageSpot />,
  rightArrow: <RightArrow />,
  email: <Email />,
  phone: <Phone />,
  download: <Download />,
  help: <Help />,
  warning: <Warning />,
  checkmark: <Checkmark />,
  autoPolicy: <AutoPolicy />,
  homePolicy: <HomePolicy />,
  userIcon: <UserIcon />,
  newAdjusterPhone: <NewAdjusterPhone />,
  newAdjusterMail: <NewAdjusterMail />,
  uploadCloud: <UploadCloud />,
  speechBubble: <SpeechBubble />,
  infoBubble: <InfoBubble />,
  greyCheckmark: <GreyCheckmark />,
  alertIcon: <AlertIcon />,
  GreenRoundCheckboxAdjustable: <GreenRoundCheckboxAdjustable />,
  roundCheckbox: <RoundCheckBox />,
  errorIcon: <ErrorIcon />,
  questionBubble: <QuestionBubble />,
  saveIcon: <Save />,
  privacyChoice: <PrivacyChoice />,
};

export const Icon = (props: IconProps) => {
  return React.cloneElement(icons[props.name], {...{ariaHidden: true}, ...props.additionalProps});
};
