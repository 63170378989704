import React, {useState} from 'react';
import {Dialog, makeStyles} from '@material-ui/core';
import {SABox, SAButton, SAColumns} from '@saux/design-system-react';
import styled from 'styled-components';
import Cookies from 'js-cookie';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    color: '#3B3B3B',
    fontSize: '16px',
    marginTop: '15px',
    textAlign: 'left',
    marginLeft: '15px',
    justifyContent: 'center',
    marginRight: '15px',
    marginBottom: '15px',
  },
  responsiveButton: {
    fontSize: '14px',
    width: '100%',
    '&:first-of-type': {
      marginBottom: '15px',
      marginRight: '15px',
      marginLeft: '15px',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      fontSize: '17px',
    },
  },
}));

const SAColumnCenter = styled(SAColumns)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  textalign: center;
  justify: flex-end;
`;
const SABoxButton = styled(SABox)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
`;

export interface BrowserVersionAlertProps {
  setAlertDisplayed: (value: boolean) => void;
}

export const BrowserVersionAlert = (props: BrowserVersionAlertProps) => {
  const classes = useStyles();

  const onConfirmHandler = () => {
    Cookies.set('is-ie-browser', 'true');
    setAlertDisplayed(true);
    props.setAlertDisplayed(true);
  };

  const [alertDisplayed, setAlertDisplayed] = useState<boolean>(false);
  // @ts-ignore
  const isIE = !!window.document['documentMode'];
  const ieBrowserCookie = Cookies.get('is-ie-browser');
  const openDialogCondition = isIE && !alertDisplayed && !ieBrowserCookie;
  if (isIE && ieBrowserCookie) {
    props.setAlertDisplayed(true);
  }

  return (
    <Dialog
      open={openDialogCondition}
      fullScreen={false}
      maxWidth={'sm'}
      disableEscapeKeyDown={true}
    >
      <SABox mt={'xs'} mb={'xs'} ml={'xs'} mr={'xs'}>
        <SABox className={classes.dialogContent}>
          Beginning June 15, 2022, Microsoft will no longer support Internet Explorer 11. Please
          update your browser to Microsoft Edge or Google Chrome to ensure the best experience with
          State Auto applications.
        </SABox>
        <SABox mt={'xs'} justify-content="stretch">
          <SAColumnCenter columns={{xs: [12, 12], sm: [3, 9]}}>
            <SABoxButton>
              <SAButton
                onClick={onConfirmHandler}
                label={'CONFIRM'}
                variant={'primary'}
                className={`${classes.responsiveButton}`}
                style={{width: '100%'}}
              />
            </SABoxButton>
          </SAColumnCenter>
        </SABox>
      </SABox>
    </Dialog>
  );
};
