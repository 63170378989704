import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {TransitionProps} from '@material-ui/core/transitions';
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {SATextField} from '../TextField/TextField';
import {Icon} from '../Icon/Icon';
import styled from 'styled-components';
import {SABox, SAButton, SAText} from '@saux/design-system-react';
import {summaryNotes, summaryNotesPayload} from '../../../services';
import {LogRocket} from '../../../services/logRocket';
import {useAddNotesModalAtomState} from '../../../atoms';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement},
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface CommentsDialogProps {
  claimNumber?: string;
  publicID?: string;
  closeHandler?: (textInput: string) => void;
  isSidebar?: boolean;
}

const SADialogTextField = styled(SATextField)`
  height: 100%;
`;
const SASaveButton = styled(SAButton)`
  height: 40px;
  width: 160px;
`;
const SASaveText = styled(SAText)`
  font-style: italic;
  color: #979797;
  margin-bottom: 16px;
`;

export const CommentsDialog = (props: CommentsDialogProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isNoteSaved, setIsNoteSaved] = useState(false);
  const [hasFailure, setHasFailure] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [textInput, setTextInput] = useState('');
  const characterLimit = 1000;
  const [addNoteModalAtomState, setAddNoteModalAtomState] = useAddNotesModalAtomState();

  const saveNote = async () => {
    const payload: summaryNotesPayload = {
      claimNumber: props?.claimNumber || '',
      additionalSummaryNotes: textInput,
      publicID: props?.publicID || '',
    };

    try {
      const {data} = await summaryNotes(payload);
      if (data.hasErrors) {
        throw new Error(`${data.errors?.[0]}`);
      }
      setIsNoteSaved(true);
      setHasFailure(false);
      setTextInput('');
      setButtonEnabled(false);
    } catch (error) {
      setIsNoteSaved(false);
      setHasFailure(true);
      setButtonEnabled(true);
      LogRocket.log('summary Notes Error:', error);
    }
  };

  const closeModal = () => {
    setAddNoteModalAtomState({open: false});

    if (props?.closeHandler !== undefined) {
      props.closeHandler(textInput);
    }
  };

  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown={true}
      open={addNoteModalAtomState.open}
      TransitionComponent={Transition}
      scroll="paper"
      PaperProps={{
        style: {
          height: '100%',
          position: 'fixed',
          top: 0,
          right: 0,
          width: isMobile ? '100%' : '50%',
          maxWidth: '450px',
          display: 'flex',
        },
      }}
      disableScrollLock={true}
    >
      <DialogTitle>
        <Grid container direction="row" justify="space-between" alignItems="center">
          {props.isSidebar ? 'Additional Notes' : 'Add Note'}
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={closeModal}
            data-testid="close-button"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{flexGrow: 1}}>
        <Box style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
          {isNoteSaved && <SASaveText type="small" text="Previous notes have been saved" />}
          {hasFailure && (
            <SABox pb="medium">
              <SAText type="heading-4" text="Note failed to save" colorVariant="alert" />
              <SAText
                type="small"
                text="Retry option available but may not succeed."
                colorVariant="alert"
              />
            </SABox>
          )}
          <SADialogTextField
            label="Add Note"
            id={`notes`}
            InputLabelProps={{'aria-labelledby': 'notes'}}
            name="notes"
            multiline
            InputProps={{
              style: {
                height: '100%',
                alignItems: 'flex-start',
              },
            }}
            showCharacterCount
            characterLimit={characterLimit}
            size="medium"
            onClick={() => setIsNoteSaved(false)}
            onChange={(event: any) => {
              setHasFailure(false);
              setTextInput(event.target.value);
              setButtonEnabled(!!event.target.value.length);
            }}
            value={textInput}
            inputProps={{className: 'sidebarNotes'}}
          />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginBottom: '3%',
            }}
          >
            <SASaveButton
              fullWidth={false}
              startIcon={<Icon name="saveIcon" />}
              label={hasFailure ? 'retry' : 'save note'}
              data-testid="save-button"
              onClick={props?.isSidebar ? closeModal : saveNote}
              textTransform="uppercase"
              variant="primary-medium"
              disabled={props?.isSidebar ? false : !buttonEnabled}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
