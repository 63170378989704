import React from 'react';
import {ButtonProps} from '@material-ui/core/Button';
import {Box, Button, CircularProgress, makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
  spinner: {
    color: 'grey',
    marginRight: '5px',
  },
});

interface SAButtonProps {
  loading?: boolean;
}

export const SAButton = (props: ButtonProps & SAButtonProps) => {
  const classes = useStyles();
  return (
    <Box pl={props.loading && 3}>
      <Button {...props}>
        {props.loading && <CircularProgress className={classes.spinner} size={14} />}
        {props.children}
      </Button>
    </Box>
  );
};
