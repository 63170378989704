import React, {useState, useEffect, useRef} from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import {Controller, useFormContext} from 'react-hook-form';
import {scrollToRef} from '../../../utils/utils';

const useStyles = makeStyles(theme => ({
  error: {
    borderColor: 'red !important',
  },
  root: {
    height: '43px',
    width: '75px',
    color: '#000',
    borderColor: theme.palette.primary.light,
    borderRadius: '7px',
    backgroundColor: 'white',
    '&$selected': {
      color: '#fff',
      backgroundColor: theme.palette.primary.light,
      '&$disabled': {
        backgroundColor: '#C7CCD8',
      },
    },
    '&$selected:hover': {
      color: '#fff',
      backgroundColor: theme.palette.primary.light,
    },
    '&$disabled': {
      borderColor: '#C7CCD8',
    },
    '&:first-child': {
      borderRadius: '7px 0px 0px 7px',
    },
    '&:last-child': {
      borderRadius: '0px 7px 7px 0px',
    },
  },
  secondary: {
    height: '43px',
    width: '90px',
    color: '#000',
    borderColor: '#077BA7',
    borderRadius: '4px',
    backgroundColor: 'white',
    '&$selected': {
      color: '#fff',
      backgroundColor: '#077BA7',
      '&$disabled': {
        backgroundColor: '#C7CCD8',
      },
    },
    '&$selected:hover': {
      color: '#fff',
      backgroundColor: '#077BA7',
    },
    '&$disabled': {
      borderColor: '#C7CCD8',
    },
    '&:first-child': {
      borderRadius: '4px 0px 0px 4px',
    },
    '&:last-child': {
      borderRadius: '0px 4px 4px 0px',
    },
  },
  selected: {},
  disabled: {},
}));

export interface YesNoToggleProps {
  handleChange?: (value: string) => any;
  name: string;
  inputRef?: any;
  yesButtonTestId?: string;
  testId?: string;
  hasError?: boolean | undefined;
  errorMessage?: string;
  defaultValue?: string;
  disabled?: boolean | undefined;
  secondaryStyles?: boolean;
}

export const YesNoToggle = (props: YesNoToggleProps) => {
  const classes = useStyles();
  const [selection, setSelection] = useState('');

  const {control, setValue, clearErrors} = useFormContext();

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const value = event.currentTarget.value;

    if (value !== selection) {
      clearErrors(props.name);
      setValue(props.name, value);

      setSelection(value);
      props.handleChange && props.handleChange(value);
    }
  };

  const yesNoToggleRef = useRef(null);

  useEffect(() => {
    if (props.defaultValue) {
      setSelection(props.defaultValue);
      setValue(props.name, props.defaultValue);
    } else {
      setSelection('');
      setValue(props.name, '');
    }
  }, [props.defaultValue]);

  const onError = () => {
    scrollToRef(yesNoToggleRef);
  };

  return (
    <ToggleButtonGroup
      value={selection}
      defaultValue={props.defaultValue}
      exclusive
      ref={yesNoToggleRef}
    >
      <Controller
        name={props.name}
        onFocus={onError}
        error={props.hasError}
        control={control}
        defaultValue=""
        as={
          <>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div>
                <ToggleButton
                  value="yes"
                  classes={{
                    root: props.hasError
                      ? props.secondaryStyles
                        ? `${classes.secondary} ${classes.error}`
                        : `${classes.root} ${classes.error}`
                      : props.secondaryStyles
                      ? classes.secondary
                      : classes.root,
                    selected: classes.selected,
                    disabled: classes.disabled,
                  }}
                  selected={selection === 'yes'}
                  data-testid={props.yesButtonTestId}
                  disabled={props.disabled}
                  onClick={onClickHandler}
                >
                  {selection === 'yes' && <CheckIcon data-testid="checkYes" fontSize="small" />}
                  <Typography>&nbsp;YES</Typography>
                </ToggleButton>
                <ToggleButton
                  value="no"
                  classes={{
                    root: props.hasError
                      ? props.secondaryStyles
                        ? `${classes.secondary} ${classes.error}`
                        : `${classes.root} ${classes.error}`
                      : props.secondaryStyles
                      ? classes.secondary
                      : classes.root,
                    selected: classes.selected,
                    disabled: classes.disabled,
                  }}
                  selected={selection === 'no'}
                  data-testid={props.testId}
                  disabled={props.disabled}
                  onClick={onClickHandler}
                >
                  {selection === 'no' && <CheckIcon data-testid="checkNo" fontSize="small" />}
                  <Typography>&nbsp;NO</Typography>
                </ToggleButton>
              </div>
              {props?.errorMessage && (
                <Box py={3}>
                  <Typography variant="body2" color="error">
                    {props?.errorMessage}
                  </Typography>
                </Box>
              )}
            </div>
          </>
        }
      />
    </ToggleButtonGroup>
  );
};
