import React, {useEffect} from 'react';
import {AgentPolicySearch} from '../components/PolicySearch/AgentPolicySearch';
import {Grid, Box} from '@material-ui/core';

export const AgentPolicySearchPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={8}>
        <Box mt={8} mb={8}>
          <AgentPolicySearch />
        </Box>
      </Grid>
    </Grid>
  );
};
