import {Box} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {BaseSwitch} from '../../BaseSwitch/BaseSwitch';
import {ScriptBox} from '../../ScriptBox/ScriptBox';
import {SATextField} from '../../TextField/TextField';
import {YesNoToggle} from '../../YesNoToggle/YesNoToggle';
import {CopartComponentProps, VehiclePreferences} from '../types';
import {AcceptCopartContainer} from './AcceptCopartContainer';
import {AdjusterNextStepsScript} from './AdjusterNextStepsScript';
import * as yup from 'yup';
import {PickUpDateSchema} from './PickUpDate';
import {CallBusinessContainerSchema} from './CallBusinessContainer';
import {LoanLease, LoanLeaseSchema} from './LoanLease';
import {PersonalBelongingsScriptSchema} from './PersonalBelongingsScript';

export const CopartContainerSchema = {
  acceptCopart: yup.string().when('$vehicleDriveable', {
    is: (value: boolean) => value === false,
    then: yup.string().required('Please select if the vehicle should be moved to COPART'),
  }),
  declineCopartNotes: yup.string().when('acceptCopart', {
    is: (value: string) => value === 'no',
    then: yup.string().max(65000, 'Character limit exceeded').required('This field is required'),
  }),
};

export const CopartContainerTestSchema = yup.object().shape({
  ...CopartContainerSchema,
  ...CallBusinessContainerSchema,
  ...PersonalBelongingsScriptSchema,
  ...PickUpDateSchema,
  ...LoanLeaseSchema,
});

export const CopartContainer = ({dispatch, incident}: CopartComponentProps) => {
  const [acceptCopart, setAcceptCopart] = useState<string>('');
  const declineCopart = acceptCopart === 'no';
  const {register, errors} = useFormContext();

  const handleChange = (value: string) => {
    setAcceptCopart(value);

    dispatch &&
      dispatch({
        type: VehiclePreferences.AcceptCopart,
        value,
        id: incident?.publicID,
      });
  };

  const onNotesChange = (e: any) => {
    dispatch &&
      dispatch({
        type: VehiclePreferences.DeclineCopartNotes,
        value: e.target.value,
        id: incident?.publicID,
      });
  };

  useEffect(() => {
    setAcceptCopart(incident?.copartPreferences.acceptCopart || '');
  }, [incident?.publicID]);

  return (
    <>
      <ScriptBox message="Due to the severity of the damage and the year, make and model, your vehicle is coming back as a probable total loss. At this time, we would like to move the vehicle to our storage free facility, called COPART, where they are able to properly inspect the vehicle and assess the value versus the cost of repair. The adjuster will follow up once the inspection is completed and will explain next steps. Are we able to go ahead and move your vehicle to COPART to initiate the inspection process?">
        <>
          <Box pt={3}>
            <YesNoToggle
              handleChange={(value: string) => handleChange(value)}
              name={VehiclePreferences.AcceptCopart}
              yesButtonTestId="yesMoveToCopart"
              testId="noMoveToCopart"
              defaultValue={incident?.copartPreferences.acceptCopart || ''}
              key={`${incident?.publicID}-${VehiclePreferences.AcceptCopart}`}
              hasError={errors?.hasOwnProperty(VehiclePreferences.AcceptCopart)}
              secondaryStyles
              errorMessage={errors?.[VehiclePreferences.AcceptCopart]?.message}
            />
          </Box>
          <BaseSwitch
            matches={declineCopart}
            then={
              <Box pt={3} width={{md: '60%'}}>
                <SATextField
                  hasWhiteFields
                  id={VehiclePreferences.DeclineCopartNotes}
                  label="Notes"
                  inputRef={register}
                  name={VehiclePreferences.DeclineCopartNotes}
                  characterLimit={65000}
                  InputLabelProps={{
                    'aria-labelledby': VehiclePreferences.DeclineCopartNotes,
                  }}
                  defaultValue={incident?.copartPreferences.declineCopartNotes || ''}
                  key={`${incident?.publicID}-${VehiclePreferences.DeclineCopartNotes}`}
                  onChange={onNotesChange}
                  error={errors?.hasOwnProperty(VehiclePreferences.DeclineCopartNotes)}
                  helperText={errors?.[VehiclePreferences.DeclineCopartNotes]?.message || ''}
                />
              </Box>
            }
          />
        </>
      </ScriptBox>
      <BaseSwitch
        matches={acceptCopart === 'yes'}
        then={<AcceptCopartContainer dispatch={dispatch} incident={incident} />}
        otherwise={
          <BaseSwitch
            matches={declineCopart}
            then={
              <Box pt={3}>
                <AdjusterNextStepsScript />
              </Box>
            }
          />
        }
      />
      <LoanLease dispatch={dispatch} incident={incident} />
    </>
  );
};
