import {Box, Grid, Divider} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {BlueContainer} from '../../common/Containers/Containers';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import * as yup from 'yup';
import {
  cityValidation,
  emailValidation,
  stateValidation,
  streetAddressValidation,
  zipCodeValidation,
  OptionalPhoneNumbersSchema,
  firstNameValidation,
  lastNameValidation,
} from '../../../validations';
import {AdditionalInjured} from '../../auto/AnyoneElseInjured/AnyoneElseInjured';

const schema = yup.array().of(
  yup.object().shape({
    injuredId: yup.string().required('Please select who was injured'),
    firstName: firstNameValidation().required('First Name is required'),
    lastName: lastNameValidation().required('Last Name is required'),
    ...OptionalPhoneNumbersSchema,
    autocompleteAddress: yup.string().nullable(),
    manualAddressEntry: yup.string().nullable(),
    address: streetAddressValidation().nullable(),
    city: cityValidation(true).nullable(),
    state: stateValidation().nullable(),
    zipCode: zipCodeValidation(true).nullable(),
    emailAddress: emailValidation(true),
  })
);

export const InjuredPersonDetailsTestSchema = yup.object().shape({
  additionalInjured: schema,
});

export const InjuredPersonDetailsSchema = {
  additionalInjured: schema,
};

export const InjuredPersonDetails = () => {
  const {control} = useFormContext();
  const fieldArray = useFieldArray({
    control,
    name: 'additionalInjured',
  });

  const addAdditionalInjured = () => {
    const newInjured = {
      injuredId: '',
      firstName: '',
      lastName: '',
      autocompleteAddress: '',
      manualAddressEntry: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      emailAddress: '',
      phoneNumbers: [{phoneNumber: '', phoneType: '', verifiedNumber: 'false'}],
    };

    fieldArray.append({...newInjured});
  };

  useEffect(() => {
    addAdditionalInjured();
  }, []);

  return (
    <FormContainer header="Injured Person Details">
      <BlueContainer>
        {fieldArray.fields.map((field, index) => (
          <Box key={field.id}>
            {index > 0 && (
              <Grid item xs={12}>
                <Box pb={2}>
                  <Divider variant="middle" />
                </Box>
              </Grid>
            )}
            <AdditionalInjured
              fieldArray={fieldArray}
              field={field}
              index={index}
              addController={() => addAdditionalInjured()}
              extraHeaderPaddingBottom
            />
          </Box>
        ))}
      </BlueContainer>
    </FormContainer>
  );
};
