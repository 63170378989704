import React, {createRef, useEffect} from 'react';
import {Box, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {scrollToRef} from '../../../utils/utils';
import {Alert, AlertProps} from '@material-ui/lab';
import {PdfSection} from '../Summary/components/PdfSection/PdfSection';
import {SUMMARY_PDF_ID} from '../../pdf/constants';

export interface FormContainerBannerProps {
  display?: boolean;
  message?: string;
}

export interface FormContainerProps {
  header?: string | JSX.Element;
  children: JSX.Element;
  color?: string | undefined;
  fontSize?: number | undefined;
  id?: string | undefined;
  bannerProps?: AlertProps;
  displayBanner?: boolean;
  pdfId?: string;
}

const useStyles = makeStyles(theme => ({
  responsiveBox: {
    padding: 15,
    [theme.breakpoints.up('md')]: {
      padding: '40px 35px',
    },
  },
}));

export const FormContainer = (props: FormContainerProps) => {
  const classes = useStyles();

  const bannerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (bannerRef.current) {
      scrollToRef(bannerRef);
    }
  }, [props.displayBanner]);

  return (
    <Box maxWidth={1200} flexGrow="1" {...(props.id ? {id: props.id} : {})}>
      <Paper elevation={3}>
        <Box className={classes.responsiveBox} id={props.pdfId || ''}>
          {props.displayBanner && (
            <Box pb={3}>
              <div ref={bannerRef} />
              <Alert {...props.bannerProps} />
            </Box>
          )}
          <PdfSection className={props.pdfId || SUMMARY_PDF_ID}>
            {props.header && (
              <Box
                component="h2"
                pb={props.header ? 3 : 0}
                m={0}
                fontSize={props.fontSize ? props.fontSize : 18}
                fontWeight="fontWeightBold"
                color={props.color}
              >
                {props.header}
              </Box>
            )}
          </PdfSection>
          {props.children}
        </Box>
      </Paper>
    </Box>
  );
};
