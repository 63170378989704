import React from 'react';
import {Box, Hidden, makeStyles} from '@material-ui/core';
import {Icon} from '../Icon/Icon';

const useStyles = makeStyles(theme => ({
  blueContainerBox: {
    padding: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#E2F5F9',
    [theme.breakpoints.up('md')]: {
      padding: '20px 20px',
      width: '80%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface ScriptBoxProps {
  message: string;
  children?: JSX.Element;
}

export const ScriptBox = ({message, children}: ScriptBoxProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.blueContainerBox}>
      <Hidden xsDown>
        <Box p={1} pt={0}>
          <Icon name="speechBubble" />
        </Box>
      </Hidden>
      <Box className={classes.content}>
        <Box>
          <b>Communicate to Customer:</b> <i>"{message}"</i>
        </Box>
        {children}
      </Box>
    </Box>
  );
};
