import React from 'react';
import {Box, Hidden} from '@material-ui/core';
import SystemDownDesktopSVG from '../../assets/images/System-Down-Desktop-and-Phone.svg';
import SystemDownMobileSVG from '../../assets/images/System-Down-Phone.svg';
import TwoToneClockSVG from '../../assets/images/clock-twotone.svg';
import {SAColumns, SABox} from '@saux/design-system-react';
import styled from 'styled-components';
import {useFeatureFlags} from '../common/Providers/Providers';

const SAColumnContainer = styled(SAColumns)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SABoxContainer = styled(SABox)`
  margin-top: 56px;
  align-items: center;
`;
const SABoxCenter = styled(SABox)`
  text-align: center;
`;

const SABoxText = styled(SABox)`
  flex-wrap: wrap;
  margin-left: 10px;
`;

const SABoxClock = styled(SABox)`
    margin-right = 16px;
`;

export const Maintenance = () => {
  const {featureFlags} = useFeatureFlags();
  const policyCenterDown = featureFlags?.FF_DCARE_6280;

  return (
    <SAColumnContainer>
      <SABoxContainer>
        <SABoxCenter>
          <Hidden xsDown>
            <img alt="System Down" src={SystemDownDesktopSVG} />
          </Hidden>
        </SABoxCenter>
        <SABoxCenter>
          <Hidden smUp align-self="center">
            <img alt="System Down" src={SystemDownMobileSVG} />
          </Hidden>
        </SABoxCenter>
        <Box mt={4} mb={2} textAlign="center" fontWeight={750} fontSize={{xs: 34, sm: 30}}>
          Something Went Wrong!
        </Box>

        <Box
          textAlign="left"
          fontWeight={550}
          fontSize={{xs: 22, sm: 18}}
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="top"
          maxWidth="500px"
        >
          <SABoxClock mr={2}>
            <img alt="Clock" src={TwoToneClockSVG} />
          </SABoxClock>
          {policyCenterDown ? (
            <SABoxText data-testid={'policyCenterDownError'}>
              State Auto systems are currently unavailable due to necessary maintenance work.
              <br />
            </SABoxText>
          ) : (
            <SABoxText>Sorry, something went wrong on our end. Please check back later.</SABoxText>
          )}{' '}
        </Box>
      </SABoxContainer>

      {policyCenterDown && (
        <SABox marginTop={'30px'} marginBottom={'50px'}>
          <Box textAlign="left" fontWeight={550} fontSize={{xs: 22, sm: 18}} maxWidth="500px">
            <SABoxText data-testid={'policyCenterDownErrorContactText'}>
              Please contact 1-833-SA-HELPS (
              <a style={{textDecoration: 'none'}} href="tel:+18337243577">
                1-833-724-3577
              </a>
              ) and a State Auto representative will be able to help you with a new claim report.
            </SABoxText>
          </Box>
        </SABox>
      )}
    </SAColumnContainer>
  );
};
