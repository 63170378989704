import React from 'react';
import {SAText} from '@saux/design-system-react';
import {privacyPolicyLink, termsLink} from '../../../routes/links';
import styled from 'styled-components';

const onClick = (e: React.MouseEvent<HTMLElement>, link: string) => {
  e.preventDefault();
  window.open(link);
};

const SAStyledText = styled(SAText)`
  font-size: 14px;
  color: inherit;
`;

export class CPRAFooter {
  static initialize = () => {
    const loadScript = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = process.env.REACT_APP_CPRA_URL as string;
      script.id = 'tealium';
      script.async = true;
      script.type = 'text/javascript';
      script.addEventListener('load', () => {
        resolve(true);
      });
      script.addEventListener('error', e => {
        reject(e);
      });
      document.body.appendChild(script);
    });
    loadScript.catch(err => {
      console.error(err);
    });
  };
}

export const SAFooter = () => {
  const year = new Date().getFullYear().toString();
  return (
    <>
      <SAStyledText
        type="standard"
        text={`© ${year} Copyright State Automobile Mutual Insurance Company. All rights reserved. `}
      />
      <SAStyledText
        type="standard"
        colorVariant="link"
        weight="bold"
        text="Privacy Policy. "
        onClick={e => onClick(e, privacyPolicyLink)}
      />
      <SAStyledText
        type="standard"
        colorVariant="link"
        weight="bold"
        text="Terms of Use."
        onClick={e => onClick(e, termsLink)}
      />
    </>
  );
};
