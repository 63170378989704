import React, {useState} from 'react';
import {Grid, Box} from '@material-ui/core';
import {CustomerTypes} from '../../../commonTypes';
import {UserPersonaSwitch} from '../../common/UserPersonaSwitch/UserPersonaSwitch';
import {CheckboxWithLabel} from '../../common/CheckboxWithLabel/CheckboxWithLabel';
import {
  InformationBubbleBox,
  containerStyleTypes,
} from '../../common/InformationBubbleBox/InformationBubbleBox';
import {getUserInfoFromToken} from '../../../utils/utils';
import {Snowplow} from '../../../pages/utils/snowplow';
import {useFeatureFlags} from '../Providers/Providers';

export interface LawsuitClaimPromptProps {
  persona: string;
  logrocketurl: string;
  setIsLawsuitClaim: (value: boolean) => void;
}

export const LawsuitClaimPrompt = ({
  persona,
  logrocketurl,
  setIsLawsuitClaim,
}: LawsuitClaimPromptProps) => {
  const {featureFlags} = useFeatureFlags();
  const [lawsuitClaim, setLawsuitClaim] = useState(false);

  const onChange = (_event: any, checked: any) => {
    const {firstName, lastName} = getUserInfoFromToken();
    Snowplow.track.lawsuitClaim({
      lawsuitClaim: checked,
      name: `${firstName} ${lastName}`,
      logrocketurl,
      persona,
    });
    setLawsuitClaim(checked);
    setIsLawsuitClaim(checked);
  };

  return (
    <>
      {featureFlags?.FF_DCX_2360 && (
        <UserPersonaSwitch
          ifPersonas={[CustomerTypes.Associate]}
          then={
            <Grid item xs={12}>
              <Box pt={2}>
                <CheckboxWithLabel
                  name="generalDetails.lawsuitClaim"
                  formControlLabelProps={{
                    disabled: false,
                    label: 'This claim is associated with a lawsuit',
                  }}
                  checkboxProps={{
                    id: 'lawsuit-claim',
                    onChange: (event, checked) => onChange(event, checked),
                    pointerEvent: 'stroke',
                  }}
                />
              </Box>
              {lawsuitClaim && (
                <Box pt={4}>
                  <InformationBubbleBox
                    containerStyle={containerStyleTypes.Sidebar}
                    message="Please email all documents related to the lawsuit (summons, complaints, etc) along with the claim number to claims@stateauto.com"
                  />
                </Box>
              )}
            </Grid>
          }
        />
      )}
    </>
  );
};
