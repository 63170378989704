import React, {useState} from 'react';
import {Box, makeStyles, Button} from '@material-ui/core';
import {
  GlassRoadsideTypes,
  glassOnlyMessage,
  roadsideAssistanceMessage,
  GlassRoadside,
} from './GlassRoadside';
import {FormProvider, useForm} from 'react-hook-form';
import {navigate} from '@reach/router';

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: '#C74F32',
    fontWeight: 'bold',
  },
  textAlignEnd: {
    textAlign: 'end',
  },
  container: {
    [theme.breakpoints.up('md')]: {
      minWidth: '700px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '500px',
    },
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  btnPrevious: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '30px',
    },
  },
}));

export interface GlassRoadsidePanelProps {
  children?: (props: ChildRenderProps) => void;
}

export interface ChildRenderProps {
  canContinue: boolean;
  setCanContinue: React.Dispatch<React.SetStateAction<boolean>>;
}

interface GlassRoadsidePanelForm {
  glassRoadsideRadioGroup: string;
}

export const GlassRoadsidePanel = (props: GlassRoadsidePanelProps) => {
  const [glassRoadsideState, setglassRoadsideState] = useState<string>();
  const handleGlassRoadsideChange = (glassRoadsideValue: string) => {
    setglassRoadsideState(glassRoadsideValue);
    if (glassRoadsideValue === GlassRoadsideTypes.GlassOnly) {
      setGlassRoadsideError(glassOnlyMessage);
    } else if (glassRoadsideValue === GlassRoadsideTypes.RoadsideAssistance) {
      setGlassRoadsideError(roadsideAssistanceMessage);
    } else if (glassRoadsideValue === GlassRoadsideTypes.Neither) {
      setGlassRoadsideError(null);
    }
  };

  const [canContinue, setCanContinue] = useState<boolean>(false);
  const onClick = () => {
    setCanContinue(true);
  };

  const onPreviousClicked = () => {
    navigate('/');
  };

  const [glassRoadsideError, setGlassRoadsideError] = useState<
    string | React.ReactElement | undefined | null
  >();

  const classes = useStyles();

  const formMethods = useForm<GlassRoadsidePanelForm>();

  return (
    <Box className={classes.container}>
      {!canContinue && (
        <Box display="flex" flexDirection="column">
          <FormProvider {...formMethods}>
            <GlassRoadside glassRoadsideState={handleGlassRoadsideChange} defaultValue="" />
          </FormProvider>
          {glassRoadsideError && <Box className={classes.errorMessage}>{glassRoadsideError}</Box>}
          <Box className={classes.ctaContainer} mt={8}>
            <Button
              onClick={onPreviousClicked}
              data-testid="previous-button"
              tabIndex={1}
              className={classes.btnPrevious}
            >
              Previous
            </Button>
            <Button
              onClick={onClick}
              disabled={glassRoadsideState !== GlassRoadsideTypes.Neither}
              data-testid="continue-button"
              variant="contained"
              color="secondary"
              type="submit"
            >
              Continue
            </Button>
          </Box>
        </Box>
      )}
      {props.children && props.children({canContinue, setCanContinue})}
    </Box>
  );
};
