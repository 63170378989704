import React, {createRef, useEffect, useState, useRef} from 'react';
import * as yup from 'yup';
import {formatISO, format} from 'date-fns';
import {useMediaQuery} from '@material-ui/core';
import {
  BoxProps,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SASpinner,
  SAText,
  SATooltip,
  SAUX360Theme,
} from '@saux/design-system-react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useForm, FormProvider, Controller} from 'react-hook-form';
import {AlertPanel} from '../common/AlertPanel/AlertPanel';
import {Icon} from '../common/Icon/Icon';
import {
  draftClaim,
  duplicateCheck,
  verifyPolicy,
  verifyPolicyDatalake,
  VerifyPolicyPayload,
  VerifyPolicyResponse,
} from '../../services';
import {SADatePicker} from '../common/DatePicker/DatePicker';
import {SATimePicker} from '../common/TimePicker/TimePicker';
import {SATextField} from '../common/TextField/TextField';
import {yupResolver} from '@hookform/resolvers';
import {LogRocket} from '../../services/logRocket';
import {returnRecentDuplicateClaims, scrollToRef} from '../../utils/utils';
import {PolicyNumberSchema, LOB_PROPS} from '../auto/GettingStarted/GettingStartedEntry';
import {v4 as uuidv4} from 'uuid';
import Cookies from 'js-cookie';
import {PhoneHyperlink} from '../common/PhoneHyperlink/PhoneHyperlink';
import {PersonCompanyRadios} from '../common/PersonCompanyRadios/PersonCompanyRadios';
import {ContactTypes, CustomerTypes} from '../../commonTypes';
import {Snowplow} from '../../pages/utils/snowplow';
import {DuplicateClaimDialog} from '../common/DuplicateClaimDialog/DuplicateClaimDialog';
import {useUserAtomState} from '../../atoms';
import {navigateDefaultReplace} from '../../pages/utils';
import {SAColumns, SABox} from '@saux/design-system-react';
import {LossDateAndTimeSchema} from '../../validations';
import {useFeatureFlags} from '../common/Providers/Providers';
import styled, {css} from 'styled-components';
import {AxiosResponse} from 'axios';
import {MAINTENANCE_ROUTE} from '../../routes/paths';
import {navigate} from '@reach/router';

export interface PolicySearchProps {
  setCanContinue?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface PolicySearchForm {
  policyNumber: string;
  firstName: string;
  lastName: string;
  lossDate: number | Date;
  lossTime: number | Date;
  companyName: string;
  policyType: string;
}

const SpinnerContainer = styled.div`
  margin-right: 10px;
  svg:last-child {
    margin-right: 0;
  }
`;

const spinner = (
  <SpinnerContainer>
    <SASpinner variant="circular-continuous" color={SAUX360Theme.colors.blueGray50} size="20px" />
  </SpinnerContainer>
);

interface ISACBox {
  maxWidth?: string;
  height?: string;
  width?: string;
  justifyContent?: string;
  flexDirection?: string;
}

const SACBox = styled(SABox)<BoxProps & ISACBox>`
  ${(props: ISACBox) => {
    return css`
      max-width: ${props.maxWidth};
      height: ${props.height};
      width: ${props.width};
      flex-direction: ${props.flexDirection ? props.flexDirection : 'row'};
      justify-content: ${props.justifyContent ? props.justifyContent : 'flex-start'};
    `;
  }}
`;

const useStyles = makeStyles(theme => ({
  tooltipContainer: {
    position: 'relative',
  },
  tooltipIcon: {
    position: 'absolute',
    marginLeft: 15,
    marginTop: 5,
    left: '100%',
    top: 0,
    fill: theme.palette.primary.light,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      margin: 0,
      left: 0,
    },
  },
  errorMessage: {
    color: 'red',
  },
  responsiveContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      width: 'auto',
    },
  },
  fieldset: {
    border: '0 none',
  },
}));

export const PolicySearchSchema = yup.object().shape({
  policyNumber: PolicyNumberSchema,
  ...LossDateAndTimeSchema,
  firstName: yup.string().when('policyType', {
    is: (value: string) => value === 'person',
    then: yup.string().required('Enter a valid first name'),
  }),
  lastName: yup.string().when('policyType', {
    is: (value: string) => value === 'person',
    then: yup.string().required('Enter a valid last name'),
  }),
  companyName: yup.string().when('policyType', {
    is: (value: string) => value === 'company',
    then: yup.string().required('Enter a valid company name'),
  }),
});

const uuid = uuidv4();

export const PolicySearch = (props: PolicySearchProps) => {
  const [userAtomState, setUserAtomState] = useUserAtomState();

  const lob = userAtomState?.gettingStarted?.lob || '';
  const customerType = userAtomState?.gettingStarted?.customerType;
  const policyNumber = userAtomState?.policyNumber;

  const [hasDuplicateClaims, setHasDuplicateClaims] = useState(false);
  const {featureFlags} = useFeatureFlags();

  const responseForVerifyPolicy = (
    response: AxiosResponse<VerifyPolicyResponse>,
    payload: VerifyPolicyPayload,
    formData: PolicySearchForm
  ) => {
    const {data, headers} = response;
    const {policyNumber} = formData;
    const userToken = Cookies.get('userToken');
    Snowplow.track.login({
      payload: {
        firstName: payload.firstName as string,
        lastName: payload.lastName as string,
        lossDate: payload.lossDate as string,
        lossTime: payload.lossTime as string,
        policyNumber: payload.policyNumber as string,
        companyName: payload.companyName as string,
      },
      isCompanyPolicy,
      data,
      logrocketurl: userAtomState?.logrocketurl,
      persona: userAtomState?.gettingStarted?.customerType,
    });
    const policyCenterDown = featureFlags?.FF_DCARE_6280;

    if (data.data) {
      LogRocket.identify(payload.policyNumber, {
        name: isCompanyPolicy ? payload.companyName : `${payload.firstName} ${payload.lastName}`,
      });

      if (!userToken) {
        Cookies.set('userToken', headers?.['x-amzn-remapped-authorization'] || data?.jwt);
      }

      Cookies.set('policyNumber', policyNumber);

      duplicateCheck({policyNumber, lossDate: payload.lossDate || ''})
        .then(({data}: any) => {
          if (data.data instanceof Array && data.data.length > 0) {
            setHasDuplicateClaims(true);
            setDuplicateClaimData(returnRecentDuplicateClaims(data.data));
          } else {
            continueToForm();
          }
        })
        .catch(() => {
          setHasDuplicateClaims(false);
          setIsSubmitting(false);
        });
    } else {
      if (policyCenterDown) {
        navigate(MAINTENANCE_ROUTE);
      } else {
        setPolicySearchError(
          userAtomState?.gettingStarted?.customerType === CustomerTypes.SaCustomer ? (
            <span data-testid="policyMatchError" id="PolicyMatchError">
              Please double-check that the insured name and the policy number are an exact match to
              how it appears on your policy documents.
            </span>
          ) : (
            <span data-testid="policyMatchError" id="policyMatchError">
              We couldn't match the policy information you entered. Please double-check your search
              criteria and try again, or contact our claims team at <PhoneHyperlink />.
            </span>
          )
        );
        setIsSubmitting(false);
      }
    }
  };

  const catchForVerifyPolicy = (error: any) => {
    if (error?.response?.status === 406) {
      setPolicySearchError(getPolicyTypeErrorMessage());
    } else {
      setPolicySearchError(
        <span data-testid="errorMessage" id="genericPolicyError">
          Something went wrong. Please contact our claims team at <PhoneHyperlink />.
        </span>
      );
    }
    setIsSubmitting(false);
    setHasDuplicateClaims(false);
  };

  const formMethods = useForm<PolicySearchForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    resolver: yupResolver(PolicySearchSchema),
    defaultValues: {
      lossDate: 0,
      policyType: 'person',
    },
    context: {
      lob: lob,
    },
  });

  const {register, errors, handleSubmit, formState, setValue, trigger, watch, getValues, control} =
    formMethods;

  const [dateSelected, setDateSelected] = useState<Date | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [policySearchError, setPolicySearchError] = useState<
    string | React.ReactElement | undefined | null
  >();
  const [duplicateClaimData, setDuplicateClaimData] = useState<any>([{}]);

  const isCompanyPolicy = watch('policyType') === 'company';

  const getPolicyTypeErrorMessage = () => {
    return <span id="policyTypeError">{LOB_PROPS[lob].policyTypeErrorMessage}</span>;
  };

  const claimsOutage = featureFlags?.FF_DCX_2688;

  const onSubmit = async (data: PolicySearchForm) => {
    setIsSubmitting(true);
    setPolicySearchError(null);

    const payload: VerifyPolicyPayload = {};
    const lossDateFormatted = formatISO(new Date(data.lossDate));
    const lossTimeFormatted = format(data.lossTime, 'hh:mm a');

    payload.policyNumber = data.policyNumber.replace(/\s+/g, '');
    payload.lossDate = lossDateFormatted;
    payload.lossTime = lossTimeFormatted;

    if (data.firstName !== '') {
      payload.firstName = data.firstName;
    }

    if (data.lastName !== '') {
      payload.lastName = data.lastName;
    }

    if (data.companyName !== '') {
      payload.companyName = data.companyName;
    }

    payload.policyholderType = data.policyType;

    payload.lineOfBusiness = lob;
    payload.agentSearch = customerType === CustomerTypes.SaAgent;
    try {
      const response = featureFlags?.FF_DCX_2486
        ? await verifyPolicyDatalake(payload)
        : await verifyPolicy(payload);
      const returnedData = response.data;
      if (featureFlags?.FF_DCX_2486 && !returnedData.data) {
        throw new Error('Datalake could not verify policy, trying CC!');
      }
      responseForVerifyPolicy(response, payload, data);
    } catch (error) {
      if (featureFlags?.FF_DCX_2486) {
        try {
          const response = await verifyPolicy(payload);
          responseForVerifyPolicy(response, payload, data);
        } catch (error) {
          catchForVerifyPolicy(error);
        }
      } else {
        catchForVerifyPolicy(error);
      }
    }
  };

  const continueToForm = () => {
    const {policyNumber, lossDate, lossTime} = getValues();
    draftClaim({
      lossDate: formatISO(new Date(lossDate)),
      lossTime: format(lossTime, 'hh:mm a'),
      policyNumber,
      uuid,
    })
      .then(response => {
        const {data} = response;

        setUserAtomState({
          ...userAtomState,
          lossDate: lossDate,
          lossTime: lossTime,
          policyNumber: policyNumber?.replace(/\s+/g, ''),
          draftClaimResponse: data.data.draftClaimResponse,
          uuid: data.data.uuid,
        });

        navigateDefaultReplace(LOB_PROPS[lob].formPath);
      })
      .catch(_ => {
        setPolicySearchError(
          <span>
            An issue occurred with the claim setup process. Please contact our CARE team at
            <PhoneHyperlink />
          </span>
        );
        setIsSubmitting(true);
        setHasDuplicateClaims(false);
      });
  };

  const dateTimeChangeHandler = (date: Date | null, value?: string | null | undefined) => {
    if (date instanceof Date && date.toString() !== 'Invalid Date') {
      setValue('lossDate', date);
      setDateSelected(date);
      trigger('lossDate');
    }
  };

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const errorPanelRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (errorPanelRef.current) {
      scrollToRef(errorPanelRef);
    }
  }, [policySearchError]);

  const onPrevious = () => {
    props.setCanContinue && props.setCanContinue(false);
  };

  const onEscapeKeyDown = () => {
    props.setCanContinue && props.setCanContinue(false);
    setIsSubmitting(false);
    setHasDuplicateClaims(false);
  };

  const incidentTimeRef = useRef(null);
  const onError = () => {
    scrollToRef(incidentTimeRef);
  };

  useEffect(() => {
    LogRocket.getSessionURL((sessionURL: string) => {
      setUserAtomState({...userAtomState, logrocketurl: sessionURL});
    });
  }, []);

  return (
    <SABox>
      {policySearchError && (
        <SAColumns columns={{xs: [12]}}>
          <SACBox
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            height="100%"
            width="100%"
            marginBottom="small"
          >
            <SABox>
              <AlertPanel
                alert={{
                  message: policySearchError,
                  showWarning: false,
                }}
                data-testid="error-panel"
              />
            </SABox>
          </SACBox>
        </SAColumns>
      )}
      <SABox>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
          <SAColumns columns={{xs: [12, 12, 12, 12, 12, 12, 12], sm: [8], md: [6]}}>
            <SACBox
              pb={'medium'}
              display="flex"
              flexDirection="row"
              className={classes.tooltipContainer}
              maxWidth={'350px'}
            >
              <SATextField
                autoComplete="on"
                name="policyNumber"
                label="State Auto Policy Number"
                id="policyNumber"
                InputLabelProps={{'aria-labelledby': 'policyNumber'}}
                error={errors.hasOwnProperty('policyNumber')}
                helperText={errors?.policyNumber?.message || LOB_PROPS[lob]?.policyNumberHelperText}
                inputRef={register()}
                autoFocus
                defaultValue={policyNumber || ''}
              />
              <SATooltip
                wrapperTestId="title-test-wrapper"
                testId="title-test"
                content={
                  <>
                    If you are missing information contact CARE support at <PhoneHyperlink /> for
                    further assistance in setting up your claim.
                  </>
                }
                variant="light"
                placement="auto"
              >
                <SABox marginTop="medium" marginLeft="small">
                  <SAIcon icon={SAIcons.help} size={SAIconSize.medium} colorVariant="dark" />
                </SABox>
              </SATooltip>
            </SACBox>
            <SACBox pb={'large'} maxWidth={'350px'}>
              <SACBox>
                <SADatePicker
                  id="lossDate"
                  label="Incident Date"
                  name="lossDate"
                  value={dateSelected}
                  InputLabelProps={{'aria-labelledby': 'lossDate'}}
                  error={errors.hasOwnProperty('lossDate')}
                  helperText={errors.lossDate?.message}
                  keyboardIcon={<Icon name="calendar" />}
                  onChange={dateTimeChangeHandler}
                  inputRef={register()}
                />
              </SACBox>
            </SACBox>
            <SACBox pb={'medium'} maxWidth={'350px'}>
              <div ref={incidentTimeRef} />
              <Controller
                name="lossTime"
                defaultValue={null}
                control={control}
                onFocus={onError}
                render={({value, onChange, onBlur}) => (
                  <SATimePicker
                    label="Incident Time"
                    disabled={false}
                    onChange={(date: Date) => {
                      onChange(date);
                      trigger('lossTime');
                    }}
                    onBlur={() => {
                      trigger('lossTime');
                    }}
                    value={value}
                    keyboardIcon={<Icon name="clock" />}
                    error={errors.hasOwnProperty('lossTime')}
                    InputLabelProps={{
                      'aria-labelledby': 'lossTime',
                      for: 'lossTime',
                    }}
                    id="lossTime"
                  />
                )}
              />

              <SAText
                colorVariant={errors.hasOwnProperty('lossTime') ? 'alert' : 'default'}
                type="small"
                text={errors?.lossTime?.message || 'General Estimate'}
                weight="normal"
              />
            </SACBox>
            <fieldset className={classes.fieldset}>
              <SACBox pb={'medium'} pt={'xs'} maxWidth={'350px'}>
                <legend>
                  <SAText
                    type="standard"
                    text="Enter the State Auto policyholder's name or company name"
                    weight="normal"
                  />
                </legend>
              </SACBox>
              <FormProvider {...formMethods}>
                <PersonCompanyRadios name="policyType" defaultValue={ContactTypes.Person} />
              </FormProvider>
            </fieldset>
            {!isCompanyPolicy ? (
              <>
                <SACBox pb={'large'} pt={'xs'} maxWidth={'350px'}>
                  <SATextField
                    autoComplete="on"
                    label="First Name"
                    name="firstName"
                    inputRef={register}
                    error={errors.hasOwnProperty('firstName')}
                    id="firstName"
                    InputLabelProps={{'aria-labelledby': 'firstName'}}
                    helperText={errors?.firstName?.message}
                  />
                </SACBox>
                <SACBox pb={'large'} maxWidth={'350px'}>
                  <SATextField
                    autoComplete="on"
                    label="Last Name"
                    name="lastName"
                    inputRef={register}
                    error={errors.hasOwnProperty('lastName')}
                    id="lastName"
                    InputLabelProps={{'aria-labelledby': 'lastName'}}
                    helperText={
                      errors.hasOwnProperty('lastName')
                        ? errors?.lastName?.message || 'Enter a valid last name'
                        : ''
                    }
                    onChange={() => trigger()}
                  />
                </SACBox>
              </>
            ) : (
              <SAColumns spacing="large">
                <SACBox pb={'xxl'} pt={'xs'} maxWidth={'350px'}>
                  <SATextField
                    autoComplete="on"
                    label="Company Name"
                    name="companyName"
                    inputRef={register()}
                    error={errors.hasOwnProperty('companyName')}
                    helperText={errors?.companyName?.message}
                    id="companyName"
                    InputLabelProps={{'aria-labelledby': 'companyName'}}
                    onChange={() => trigger()}
                  />
                </SACBox>
              </SAColumns>
            )}
          </SAColumns>
          <SACBox mt={8} display="flex" justifyContent="flex-end">
            <SACBox className={classes.responsiveContainer}>
              {lob === 'auto' && customerType === 'sacustomer' && (
                <SAButton
                  fullWidth={false}
                  data-testid="previous-button"
                  label="Previous"
                  onClick={onPrevious}
                  variant="link-medium"
                  color="black"
                  style={{width: '100%'}}
                />
              )}

              <SAButton
                disabled={!formState.isValid || isSubmitting || claimsOutage}
                data-testid="continue-button"
                startIcon={isSubmitting ? spinner : undefined}
                label={'Continue'}
                variant="medium"
                color="secondary"
                type="submit"
                style={{width: '100%'}}
              />
            </SACBox>
          </SACBox>
        </form>
      </SABox>
      <DuplicateClaimDialog
        open={hasDuplicateClaims}
        continueToForm={continueToForm}
        onEscapeKeyDown={onEscapeKeyDown}
        data={{
          insuredName: '',
          incidentDate: getValues()?.lossDate,
          policyNumber: getValues()?.policyNumber,
          policyType: lob,
          claims: [...duplicateClaimData],
        }}
      />
    </SABox>
  );
};
