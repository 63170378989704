import {Box, Container, Grid, makeStyles} from '@material-ui/core';
import React, {useMemo, useReducer, useState} from 'react';
import {capitalize, getContactPhone} from '../../../../utils/utils';
import {AppFormContainer} from '../../Containers/Containers';
import {FormContainer} from '../../FormContainer/FormContainer';
import {Icon} from '../../Icon/Icon';
import {Memoize} from '../../Memoize/Memoize';
import {VehicleIncident} from '../../Summary/types';
import {CopartContainer, CopartContainerSchema} from '../components/CopartContainer';
import {IncidentInfo} from '../components/IncidentInfo';
import {LoanLeaseSchema} from '../components/LoanLease';
import {DaysToPickUp, PickUpDateSchema} from '../components/PickUpDate';
import {VehicleCopartProps, VehicleLocationTypes, VEHICLE_LOCATION_TYPES} from '../types';
import * as yup from 'yup';
import {CallBusinessContainerSchema} from '../components/CallBusinessContainer';
import {PersonalBelongingsScriptSchema} from '../components/PersonalBelongingsScript';
import {updateCopart} from '../../../../services';
import {SUMMARY_PAGE_ROUTE} from '../../../../routes/paths';
import {mapData} from '../../../../utils/dataMapper';
import {useUserAtomState} from '../../../../atoms';
import {navigateDefaultReplace} from '../../../../pages/utils';
import {SAText} from '@saux/design-system-react';
import {LogRocket} from '../../../../services/logRocket';

const useStyles = makeStyles(theme => ({
  headerBox: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const schema = yup.object().shape({
  ...CopartContainerSchema,
  ...CallBusinessContainerSchema,
  ...PersonalBelongingsScriptSchema,
  ...PickUpDateSchema,
  ...LoanLeaseSchema,
});

interface CopartProps {
  vehicleIncidents: VehicleIncident[];
  successState: any;
}

const updatePreference = (
  attr: string,
  preferences: (VehicleCopartProps & VehicleIncident)[],
  value: any,
  incidentId: string
): (VehicleCopartProps & VehicleIncident)[] => {
  const updatedPreferences = preferences.map((incident: any) => {
    if (incident.publicID === incidentId) {
      incident.copartPreferences[attr] = value;
    }

    return incident as VehicleCopartProps & VehicleIncident;
  });

  return updatedPreferences;
};

const reducer = (
  state: {vehiclePreferences: (VehicleCopartProps & VehicleIncident)[]},
  action: {type: string; value: any; id: string}
) => {
  return {
    ...state,
    vehiclePreferences: updatePreference(
      action.type,
      state.vehiclePreferences,
      action.value,
      action.id
    ),
  };
};

const init = (vehicleIncidents: any) => {
  return {
    vehiclePreferences: vehicleIncidents.map((incident: any) => {
      incident.copartPreferences = {pickUpTarget: DaysToPickUp.Today};

      return incident;
    }),
  };
};

export const CopartForm = ({vehicleIncidents, successState}: CopartProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [incidentId] = useState<string | undefined>(vehicleIncidents[0]?.publicID);
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, vehicleIncidents, init);
  const [userAtomState, _] = useUserAtomState();
  const draftResponse = userAtomState?.draftClaimResponse;

  const selectedIncident = useMemo(() => {
    return state.vehiclePreferences.find(
      (incident: VehicleCopartProps & VehicleIncident) => incident.publicID === incidentId
    );
  }, [incidentId]);

  const contact = selectedIncident?.copartAlternateContact || selectedIncident?.owner;

  const onSubmit = async (_formData: any) => {
    setIsSubmitting(true);

    try {
      await updateCopart({
        claimNumber: successState.claimNumber,
        publicID: successState.publicID,
        data: successState,
        ...state,
      });
    } catch (error) {
      LogRocket.log('Copart Update Error:', error);
    }

    navigateDefaultReplace(SUMMARY_PAGE_ROUTE, {
      state: mapData(successState, {...successState.formData, ...state}, draftResponse),
    });
  };

  return (
    <Memoize renderOn={[incidentId, isSubmitting]}>
      <>
        <AppFormContainer
          submitControl={onSubmit}
          submitButtonLabel="Submit"
          isSubmitting={isSubmitting}
          hideBreadcrumbs
          context={{
            vehicleDriveable: selectedIncident?.vehicleDriveable,
            inPossession: selectedIncident?.inPossession,
            locationType: selectedIncident?.locationType,
          }}
          validationSchema={schema}
        >
          <Grid container justify="center">
            <Container>
              <Box mt={10} mb={4}>
                <FormContainer
                  header={
                    <Box>
                      <Box pb={2}>
                        <SAText
                          weight="bold"
                          type="heading-1"
                          text={`${selectedIncident?.vehicle?.year || ''} ${
                            selectedIncident?.vehicle?.make || ''
                          } ${selectedIncident?.vehicle?.model || ''}`}
                        />
                      </Box>
                      {selectedIncident?.vehicle?.vIN && (
                        <Box className={classes.headerBox} data-testid={'VIN-number'}>
                          <Box>{`VIN: ${selectedIncident?.vehicle?.vIN}`}</Box>
                        </Box>
                      )}
                      <Box className={classes.headerBox} pt={2}>
                        <Icon name="warning" />
                        <Box pl={2}>Possible Total Loss</Box>
                      </Box>
                    </Box>
                  }
                >
                  <>
                    <IncidentInfo text="Is the vehicle drivable?" values={['No']} />
                    <IncidentInfo
                      text="Is the vehicle currently in the customer's possession?"
                      values={[selectedIncident?.inPossession ? 'Yes' : 'No']}
                    />
                    <IncidentInfo
                      text="Vehicle Current Location:"
                      values={[
                        capitalize(
                          VEHICLE_LOCATION_TYPES[
                            selectedIncident?.locationType || VehicleLocationTypes.Person
                          ]
                        ),
                        contact?.displayName || '',
                        (contact && getContactPhone(contact)) || '',
                        selectedIncident?.locationAddress?.displayName || '',
                      ]}
                    />
                    <CopartContainer dispatch={dispatch} incident={selectedIncident} />
                  </>
                </FormContainer>
              </Box>
            </Container>
          </Grid>
        </AppFormContainer>
      </>
    </Memoize>
  );
};
