import React, {useEffect} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {FormContainer} from '../../common/FormContainer/FormContainer';
import * as yup from 'yup';
import {
  cityValidation,
  stateValidation,
  OptionalPhoneNumbersSchema,
  streetAddressValidation,
  zipCodeValidation,
  propertyDamageDescriptionValidation,
  emailValidation,
} from '../../../validations';
import {makePersonaCheck, personaSchemaSwitch} from '../../../utils/utils';
import {ContactTypes, CustomerTypes} from '../../../commonTypes';
import {
  PropertyDamageForm,
  PropertyForms,
} from '../../common/PropertyDamageForm/PropertyDamageForm';
import {useUserAtomState} from '../../../atoms';

const schema = yup.array().of(
  yup.object().shape({
    ownerId: personaSchemaSwitch({
      is: (value: any) => makePersonaCheck(value, [CustomerTypes.Guardian]),
      then: yup.string().required('Please select the owner of the property'),
    }),
    firstName: personaSchemaSwitch({
      is: (value: any) => makePersonaCheck(value, [CustomerTypes.Guardian]),
      then: yup.string().when('radioSelection', {
        is: (value: string) => {
          return value === ContactTypes.Person;
        },
        then: yup.string().required('First Name is required'),
      }),
    }),
    lastName: personaSchemaSwitch({
      is: (value: any) => makePersonaCheck(value, [CustomerTypes.Guardian]),
      then: yup.string().when('radioSelection', {
        is: (value: string) => {
          return value === ContactTypes.Person;
        },
        then: yup.string().required('Last Name is required'),
      }),
    }),
    companyName: personaSchemaSwitch({
      is: (value: any) => makePersonaCheck(value, [CustomerTypes.Guardian]),
      then: yup.string().when('radioSelection', {
        is: (value: string) => {
          return value === ContactTypes.Company;
        },
        then: yup.string().required('Company Name is required'),
      }),
    }),
    propertyTypeSelection: yup.string().required('Property type is required'),
    otherProperty: yup.string().when('propertyTypeSelection', {
      is: (value: string) => {
        return value === 'other';
      },
      then: yup.string().required('Property type is required'),
    }),
    damage: propertyDamageDescriptionValidation().required('Damage description is required'),
    propertyAddress: yup.object().shape({
      autocompleteAddress: personaSchemaSwitch({
        is: (value: any) => makePersonaCheck(value, [CustomerTypes.ThirdParty]),
        then: yup.string().when('manualAddressEntry', {
          is: (value: string) => !(value === 'manualAddress' || value === undefined),
          then: yup.string().required('Address is required').nullable(),
          otherwise: yup.string().nullable(),
        }),
        otherwise: yup.string().nullable(),
      }),
      manualAddressEntry: yup.string().nullable(),
      address: personaSchemaSwitch({
        is: (value: any) => makePersonaCheck(value, [CustomerTypes.ThirdParty]),
        then: streetAddressValidation().required('Address is required').nullable(),
        otherwise: streetAddressValidation().nullable(),
      }),
      city: personaSchemaSwitch({
        is: (value: any) => makePersonaCheck(value, [CustomerTypes.ThirdParty]),
        then: cityValidation(true).required('City is required'),
        otherwise: cityValidation(true).nullable(),
      }),
      state: personaSchemaSwitch({
        is: (value: any) => makePersonaCheck(value, [CustomerTypes.ThirdParty]),
        then: stateValidation().required('State is required'),
        otherwise: stateValidation().nullable(),
      }),
      zipCode: personaSchemaSwitch({
        is: (value: any) => makePersonaCheck(value, [CustomerTypes.ThirdParty]),
        then: zipCodeValidation(true).required('ZIP Code is required').nullable(),
        otherwise: zipCodeValidation(true).nullable(),
      }),
    }),
    ownerAddress: personaSchemaSwitch({
      is: (value: any) => makePersonaCheck(value, [CustomerTypes.Guardian]),
      then: yup.object().shape({
        autocompleteAddress: yup.string().nullable(),
        manualAddressEntry: yup.string().nullable(),
        address: streetAddressValidation().nullable(),
        city: cityValidation(true),
        state: stateValidation().nullable(),
        zipCode: zipCodeValidation(true),
      }),
    }),
    email: personaSchemaSwitch({
      is: (value: any) => makePersonaCheck(value, [CustomerTypes.Guardian]),
      then: emailValidation(true).nullable(),
    }),
    ...OptionalPhoneNumbersSchema,
  })
);

export const YourPropertyDetailsSchema = {
  yourProperty: yup.array().when('$acknowledgement', {
    is: (value: boolean | undefined) => value === true,
    then: schema,
  }),
};

export const YourPropertyDetailsTestSchema = yup.object().shape({
  yourProperty: schema,
});

export const YourPropertyDetails = () => {
  const {control} = useFormContext();
  const fieldArray = useFieldArray({
    control,
    name: PropertyForms.YourProperty,
  });
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType;

  const addAdditionalProperty = () => {
    const newProperty = {
      propertyTypeSelection: '',
      otherProperty: '',
      damage: '',
      propertyAddress: {
        autocompleteAddress: '',
        manualAddressEntry: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
      },
    };

    fieldArray.append(
      userPersona === CustomerTypes.ThirdParty
        ? {...newProperty}
        : {
            ...newProperty,
            ownerId: '',
            radioSelection: ContactTypes.Person,
            firstName: '',
            lastName: '',
            companyName: '',
            email: '',
            phoneNumbers: [{phoneNumber: '', phoneType: '', verifiedNumber: 'false'}],
            ownerAddressSameAsProperty: '',
            ownerAddress: {
              autocompleteAddress: '',
              manualAddressEntry: '',
              address: '',
              city: '',
              state: '',
              zipCode: '',
            },
          },
      false
    );
  };

  useEffect(() => {
    addAdditionalProperty();
  }, []);

  return (
    <FormContainer header="Your Property">
      <PropertyDamageForm
        formName={PropertyForms.YourProperty}
        fieldArray={fieldArray}
        addAdditionalProperty={addAdditionalProperty}
      />
    </FormContainer>
  );
};
