import {Box} from '@material-ui/core';
import React from 'react';
import {CopartComponentProps} from '../types';
import {PersonalBelongingsScript} from './PersonalBelongingsScript';
import {PickUpDate} from './PickUpDate';

export const PickUpContainer = ({dispatch, incident}: CopartComponentProps) => {
  return (
    <>
      <Box pt={3}>
        <PersonalBelongingsScript dispatch={dispatch} incident={incident} />
      </Box>
      <PickUpDate dispatch={dispatch} incident={incident} />
    </>
  );
};
