import {useLocation} from '@reach/router';
import React from 'react';
import {StaffAppraiser} from '../components/auto/StaffAppraiser/StaffAppraiser';
import {BackButtonBehavior} from '../components/common/BackButtonBehavior/BackButtonBehavior';
import {StateProps} from '../components/common/Summary/types';
import {ROOT_ROUTE} from '../routes/paths';

export const StaffAppraiserPage = () => {
  const location = useLocation();
  const successState = location.state as StateProps;

  return (
    <BackButtonBehavior shouldDisplayAlert route={ROOT_ROUTE}>
      <StaffAppraiser successState={successState} />
    </BackButtonBehavior>
  );
};
