import React from 'react';
import {formatPhone} from '../../../utils/utils';
import {Box, Typography, Grid} from '@material-ui/core';
import {BlueContainer} from '../../common/Containers/Containers';
import {Contact} from '../../common/Summary/types';
import {AddressSummary} from './AddressSummary';
import {SummaryField} from '../../common/Summary/components/SummaryField/SummaryField';
import {SummaryHeader} from '../../common/Summary/components/SummaryHeader/SummaryHeader';

export interface DriverDetailsProps {
  driver: Contact;
}

export const DriverDetails = (props: DriverDetailsProps) => {
  return (
    <BlueContainer>
      <Box pb={3}>
        <SummaryHeader forBlueContainer headerText="Driver Details" />
      </Box>
      <Grid container spacing={3}>
        {props?.driver?.displayName && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Name" testId="driverDetailsNameLabel">
              <Typography data-testid="driverDetailsNameValue">
                {props.driver.displayName}
              </Typography>
            </SummaryField>
          </Grid>
        )}
        <AddressSummary
          label="Address"
          addressLine1={props?.driver?.primaryAddress?.addressLine1}
          city={props?.driver?.primaryAddress?.city}
          state={props?.driver?.primaryAddress?.state}
          postalCode={props?.driver?.primaryAddress?.postalCode}
        />
        {props?.driver?.cellNumber && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Mobile Number" testId="driverDetailsCellPhoneLabel">
              <Typography>{formatPhone(props.driver.cellNumber)}</Typography>
            </SummaryField>
          </Grid>
        )}
        {props?.driver?.workNumber && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Work Number" testId="driverDetailsWorkPhoneLabel">
              <Typography>{formatPhone(props.driver.workNumber)}</Typography>
            </SummaryField>
          </Grid>
        )}
        {props?.driver?.homeNumber && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Home Number" testId="driverDetailsHomePhoneLabel">
              <Typography>{formatPhone(props.driver.homeNumber)}</Typography>
            </SummaryField>
          </Grid>
        )}
        {props?.driver?.insuranceProvider && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Insurance Provider">
              <Typography>{props.driver.insuranceProvider}</Typography>
            </SummaryField>
          </Grid>
        )}
        {props?.driver?.policyOrClaimNumber && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Insurance Policy Number">
              <Typography>{props.driver.policyOrClaimNumber}</Typography>
            </SummaryField>
          </Grid>
        )}
        {(props?.driver?.injured === true || props?.driver?.injured === false) && (
          <Grid item xs={12} sm={6} md={4}>
            <SummaryField label="Injured" testId="driverDetailsInjuredLabel">
              <Typography data-testid="driverDetailsInjuredValue">
                {props?.driver?.injured ? 'Yes' : 'No'}
              </Typography>
            </SummaryField>
          </Grid>
        )}
      </Grid>
    </BlueContainer>
  );
};
